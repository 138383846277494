import { Modal } from './Modal';

export class ModalItem {
  public resolve: any;
  public reject: any;
  public promise: Promise<Modal>;
  constructor(public modal: Modal) {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
