import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import mermaid from 'mermaid';
import * as _ from 'lodash';

@Component({
  selector: 'app-state-diagram',
  templateUrl: './state-diagram.component.html',
  styleUrls: ['./state-diagram.component.sass']
})
export class StateDiagramComponent {
  public get value() {
    return this._value;
  }
  @Input() public set value(value) {
    if (!_.isEqual(this._value, value)) {
      this._value = value;
      this.updateView();
    }
  }
  public get highlight() {
    return this._highlight;
  }
  @Input() public set highlight(value) {
    if (!_.isEqual(this._highlight, value)) {
      this._highlight = value;
      this.updateView();
    }
  }
  @Input() public clickables = [];
  @Output() public clickEvent = new EventEmitter();
  @ViewChild('mermaid', {static: true}) mermaid;
  public options = ['start', 'done'];
  public mermaidElement;
  public i18nResources: {[params: string]: {id: string; value: string}} = {};

  private _highlight = null;
  private _value = null;

  constructor() {
  }

  setOptionsI18n(options) {
    options.forEach(option => this.i18nResources[option.id] = option);
    this.updateView();
  }

  async updateView() {
    if (this.value == null) {
      return;
    }
    await new Promise(r => setTimeout(r, 1));
    const usedClickables = [];
    let graph = 'graph TB \n';
    let counter = 0;
    const stateMap = {};
    const getStateId = (state) => {
      if (stateMap[state] == null) {
        stateMap[state] = counter++;
      }
      return stateMap[state];
    };
    for (const state in this.value) {
      if (state == null || state === 'start' || state === 'done') {
        continue;
      }
      const data = this.value[state];
      let isUsed = false;
      if (typeof data === 'string') {
        graph += `${getStateId(state)}(${state})-->${getStateId(data)}(${data})\n`;
        isUsed = true;
      } else {
        for (const edge in data) {
          if (data[edge] != null && data[edge] !== '') {
            graph += `${getStateId(state)}(${state})-->|${edge}|${getStateId(data[edge])}(${data[edge]})\n`;
            isUsed = true;
          }
        }
      }
    }

    for (const state in stateMap) {
      if (this.clickables != null && this.clickables.includes(state)) {
        usedClickables.push(state);
      }
    }
    for (const state in stateMap) {
      if (this.highlight != null && this.highlight.includes(state)) {
        graph += `\n style ${getStateId(state)} fill:#eee,stroke:#e55039,stroke-width:4px,cursor:pointer \n`;
      }
    }
    if (this.value.start != null) {
      const state = this.i18nResources.start.value || 'start';
      graph += `style start fill:#999,stroke:#999,stroke-width:4px\n`;
      graph += `start((${state}))-->${getStateId(this.value.start)}(${this.value.start})\n`;
    }
    if (this.value.done != null) {
      const state = this.i18nResources.done.value || 'done';
      graph += `style done fill:#999,stroke:#999,stroke-width:4px\n`;
      graph += `${getStateId(this.value.done)}(${this.value.done})-->done((${state}))\n`;
    }
    this.mermaidElement = (this.mermaid.nativeElement as HTMLElement);
    mermaid.initialize({
      securityLevel: 'loose',
      flowchart: {
        htmlLabels: false
      },
      theme: 'neutral'
    });
    mermaid.render('graphDiv', graph, (svgCode, bindFunctions) => {
      this.mermaidElement.innerHTML = svgCode;
    });

    if (usedClickables != null && usedClickables.length > 0) {
      for (const state of usedClickables) {
        const node = $(`g[id="${getStateId(state)}"]`);
        if (node != null) {
          node.css({cursor: 'pointer'});
          $(node).on('click', (() => {
            this.clickEvent.emit(state);
          }).bind(this));
        }
      }
    }
  }
}
