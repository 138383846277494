import { Injectable } from '@angular/core';
import { ViolationContractCounter } from '../models/ViolationContractCounter';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ViolationContractCounterService extends BaseApiService<ViolationContractCounter> {
  get cacheKey(): StorageKey {
    return StorageKey.violationContractCounterServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/report/violationContractCounter`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  protected parse(obj) {
    return ViolationContractCounter.parse(obj);
  }

  protected unparse(obj) {
    return ViolationContractCounter.unparse(obj);
  }

}
