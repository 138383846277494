import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Pagination, User } from '../models';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseApiService<User> {
  get cacheKey(): StorageKey {
    return StorageKey.userServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    if (operation === 'reinvite') {
      return `${environment.apiUrl}/users/${id}/reinvite`;
    }
    let url = `${environment.apiUrl}/users`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  reinvite(user: User): Promise<User> {
    return this.http.post(
      this.getUrl('reinvite', null, user.id),
      { verificationUrl: window.location.origin + '/verification' })
      .pipe(
        catchError(this.handleError),
        map(this.unparse)
    ).toPromise();
  }

  removeImage(id, file): Promise<any> {
    let url = `${environment.apiUrl}/users/${id}/deleteFiles`;
    const body = file;

    return this.http.put(url, body).pipe(
      catchError(this.handleError),
      map(this.unparse)
    ).toPromise();
  }

  list(params: {[params: string]: string}): Promise<Pagination<User>> {
    if (params['contractId[in]']) {
      params.contractsGroup = params['contractId[in]'];
    }
    return super.list(this.removeContractId(params));
  }

  protected unparse(obj) {
    return User.unparse(obj);
  }

  protected parse(obj) {
    return User.parse(obj);
  }
}
