<div class="toggle-panel card mt-3" [ngClass]="{'hasError': hasError}">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col" (click)="toggle()">
        <h5 class="mb-0" *ngIf="lane && lane.id; else elseTitle">
          {{contract[lane.contractId] && contract[lane.contractId].code }} -
          {{contract[lane.contractId] && contract[lane.contractId].name }} |
          Faixa: {{ lane && lane.code}} (Ponto: {{ spot && spot.code }})
        </h5>

        <ng-template #elseTitle>
          <h5 class="my-2" i18n>Contrato | Código da Faixa</h5>
        </ng-template>
      </div>

      <div class="col-auto d-flex justify-content-end align-items-start">
        <div class="d-flex align-items-center" *ngIf="haveRightMenu; else deleteBlock">
          <div [ngSwitch]="lane.operationMode">
            <i i18n-title class="material-icons text-success mt-1 mr-3" *ngSwitchCase="'enabled'" data-toggle="tooltip"
              data-placement="top" title="Ativo">fiber_manual_record</i>
            <i i18n-title class="material-icons text-secondary mt-1 mr-3" *ngSwitchCase="'test'" data-toggle="tooltip"
              data-placement="top" title="Teste">fiber_manual_record</i>
            <i i18n-title class="material-icons text-secondary mt-1 mr-3" *ngSwitchCase="'disabled'"
              data-toggle="tooltip" data-placement="top" title="Inativo">trip_origin</i>
          </div>

          <div class="dropdown" *appPermission="['UpdateLane','DeleteLane']; contracts lane.contractId">
            <button class="btn text-secondary btn-icon-block" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" *ngIf="lane.id  "><i class="material-icons">more_horiz</i></button>

            <div class="dropdown-menu dropdown-menu-right">
              <div *ngIf="haveEditOption">
                <span *ngIf="!isEdit; else elseItem">
                  <a i18n class="dropdown-item" (click)="isEdit=true"
                    *appPermission="'UpdateLane'; contracts lane.contractId">Editar Faixa</a>
                </span>
                <ng-template #elseItem>
                  <a i18n class="dropdown-item" (click)="isEdit=false">Listar</a>
                </ng-template>
              </div>
              <a i18n class="dropdown-item" (click)="openViolationConfigModal()"
                *appPermission="'UpdateLane'; contracts lane.contractId">Configurar Infrações</a>
              <a i18n class="dropdown-item" (click)="openDeleteModal()"
                *appPermission="'DeleteLane'; contracts lane.contractId">Remover Faixa</a>
            </div>
          </div>
        </div>

        <ng-template #deleteBlock>
          <button class="btn btn-fade-secondary btn-icon mr-3" (click)="removeLaneEvent()">
            <i class="material-icons">delete</i> Remover
          </button>
        </ng-template>

        <button class="btn btn-secondary btn-icon-block" (click)="toggle()" *ngIf="isShowing; else elseBlock">
          <i class="material-icons">unfold_less</i>
        </button>

        <ng-template #elseBlock>
          <button class="btn text-secondary btn-icon-block" (click)="toggle()">
            <i class="material-icons">unfold_more</i>
          </button>
        </ng-template>
      </div>
    </div>
  </div>

  <div #panel [ngClass]="{'toggle-content': true, closed: !isShowing}">
    <form [formGroup]="laneForm">
      <div class="card-body">
        <div class="row">
          <div *ngIf="haveNewLane" class="col-md-3 col-12">
            <app-input i18n-label i18n-placeholder type="{{haveNewLane ? 'select' : 'text'}}" [isLabel]="!isEdit"
              [list]="contracts" selectValue="id" selectText="prettyName" label="Contrato" formControlName="contractId"
              inputRequired="true" [inputDisabled]="!haveNewLane" [textValue]="contract[spot.contractId] ? contract[spot.contractId].prettyName : spot.contractId"
              placeholder="Selecione um contrato">
            </app-input>
          </div>
          <div *ngIf="isEdit" class="col-md-2 col-12">
            <app-input i18n-label type="text" label="Código da Faixa" formControlName="code" [textValue]="lane.code"
              inputRequired=true>
            </app-input>
          </div>
          <div class="col-md-3 col-12">
            <app-input i18n-label type="text" label="Número da Faixa" [isLabel]="!isEdit" formControlName="number"
              [textValue]="lane.number" inputRequired=true>
            </app-input>
          </div>
          <div class="col-md-2 col-12">
            <label class="text-secondary" i18n>Entre Faixas:</label>
            <div class="form-group mt-1">
              <mat-checkbox formControlName="isBetweenLane" [checked]="lane.isBetweenLane" color="primary">
              </mat-checkbox>
            </div>
          </div>
          <div [ngClass]="{'col-md-4 col-12': !haveNewLane, 'col-md-2 col-12': haveNewLane}">
            <app-input i18n-label type="text" [isLabel]="!isEdit" [textValue]="lane.clientCode"
              formControlName="clientCode" label="Código do Cliente">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-12">
            <app-input i18n-label type="text" [isLabel]="!isEdit" [textValue]="lane.direction"
              formControlName="direction" label="Sentido" inputRequired="true">
            </app-input>
          </div>
          <div class="col-md-3">
            <app-enum-i18n [input]="restrictionZones"></app-enum-i18n>
            <app-input type="select" [list]="restrictionZones" label="Zona de Restrição" formControlName="restrictionZones"
              [textValue]="restrictionZoneDescription" selectText="description" selectValue="code"
              placeholder="Selecione uma Zona de Restrição" [isLabel]="!isEdit" [ngClass]="{'setVisible':!isEnabled}">
            </app-input>
          </div>
          <div class="col-md-5">
            <app-enum-i18n [input]="operationModes" (output)="setOperationModesI18n($event)"></app-enum-i18n>
            <app-input i18n-placeholder="@@LaneListOperationModePlaceholder" i18n-label="@@LaneListOperationModeLabel"
              type="select" [list]="operationModesI18n" label="Modo de Operação" formControlName="operationMode"
              [textValue]="currentOperationType" selectText="value" selectValue="id"
              placeholder="Selecione o modo de operação" [isLabel]="!isEdit" inputRequired=true>
            </app-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <label i18n class="text-secondary">Período de Operação:</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="d-flex" *ngIf="isEdit; else labelStartsAt">
                  <div class="input-group-prepend"><span class="input-group-text" i18n>Inicial</span></div>
                  <input class="form-control" type="date" formControlName="startsAt"
                    [min]="minDate"
                    [max]="maxDateStart">
                </div>
                <ng-template #labelStartsAt>
                  <div class="d-flex">
                    <div class="input-group-prepend"><span class="input-group-text" i18n>Inicial</span></div>
                    <span class="input-group-text rounded-0 bg-white">{{ getDate(lane.contractClientCodes && lane.contractClientCodes[0] && lane.contractClientCodes[0].startsAt || '-') }}</span>
                  </div>
                </ng-template>
              </div>
              <div class="col-md-6">
                <div class="d-flex" *ngIf="isEdit; else labelEndsAt">
                  <div class="input-group-prepend"><span class="input-group-text" i18n>Final</span></div>
                  <input class="form-control" type="date" formControlName="endsAt"
                    [min]="minDate"
                    [max]="maxDateStart">
                </div>
                <ng-template #labelEndsAt>
                  <div class="d-flex">
                    <div class="input-group-prepend"><span class="input-group-text" i18n>Final</span></div>
                    <span class="input-group-text rounded-0 bg-white">{{ getDate(lane.contractClientCodes && lane.contractClientCodes[0] && lane.contractClientCodes[0].endsAt || '-') }}</span>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="col-md-6 pl-3">
            <div class="row no-gutters">
              <label i18n class="text-secondary">Velocidade da Via: <span class="text-danger"
                  *ngIf="isEdit">*</span></label>
            </div>
            <div class="row">
              <div class="col pl-1 pr-0">
                <div class="input-group input-group--adjust">
                  <div class="input-group-prepend"><span class="input-group-text" i18n>Leve</span></div>
                  <div *ngIf="isEdit; else labelBlockRegular">
                    <app-input i18n-label type="text" formControlName="regularVehicle"
                      [textValue]="lane.speeds && lane.speeds.regularVehicles" class="rounded-0"></app-input>
                  </div>

                  <ng-template #labelBlockRegular>
                    <span class="input-group-text rounded-0 bg-white">{{ lane.speeds && lane.speeds.regularVehicles }}</span>
                  </ng-template>

                  <div class="input-group-append"><span class="input-group-text" i18n>Km/h</span></div>
                </div>
              </div>

              <div class="col pl-2">
                <div class="input-group input-group--adjust">
                  <div class="input-group-prepend"> <span class="input-group-text" i18n>Pesado</span></div>
                  <div *ngIf="isEdit; else labelBlockHeavy">
                    <app-input type="text" formControlName="heavyVehicle"
                      [textValue]="lane.speeds && lane.speeds.heavyVehicles" class="rounded-0"></app-input>
                  </div>

                  <ng-template #labelBlockHeavy>
                    <span class="input-group-text rounded-0 bg-white">{{ lane.speeds && lane.speeds.heavyVehicles }}</span>
                  </ng-template>

                  <div class="input-group-append"><span class="input-group-text" i18n>Km/h</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" *ngIf="haveSpotFields && !isEdit">
            <label i18n class="text-secondary">Código do Ponto:</label>
            <div class="mr-2 mb-2">{{spot.code}}</div>
          </div>
          <div class="col-md-9" *ngIf="haveSpotFields && !isEdit">
            <div i18n class="text-secondary">Descrição do Ponto:</div>
            <div class="mr-2 mb-2">{{spot.description}}</div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="isEdit && haveSpotFields" class="col-md-12">
            <app-input i18n-label type="select" [list]="spotsByContracts[laneForm.get('contractId').value]"
              label="Ponto" formControlName="spotId" selectText="prettyName" selectValue="id"
              placeholder="Selecione o Ponto" i18n-placeholder inputRequired=true>
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-input i18n-label="@@LaneListClientDescriptionLabel"
              i18n-placeholder="@@LaneListClientDescriptionPlaceholder" type="text" label="Descrição do Cliente"
              [isLabel]="!isEdit" formControlName="clientDescription" [textValue]="lane.clientDescription">
            </app-input>
          </div>
        </div>
      </div>

      <div class="card-footer" *ngIf="haveButtons && isEdit">
        <div class="row">
          <div class="col d-flex justify-content-end">
            <button type="button" (click)="cancel()" class="btn btn-outline-secondary mr-3">
              <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
            </button>

            <button (click)="submit()" class="btn btn-primary" [ngClass]="{disabled: !isFormValid}"
              [disabled]="!isFormValid">
              <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
