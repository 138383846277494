/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Lot } from '../models/Lot';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { Pagination } from '../models';
import { StorageKey } from './storage.service';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LotService  extends BaseApiService<Lot> {
  get cacheKey(): StorageKey {
    return StorageKey.lotServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/lots`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    if (operation === 'lotClose') {
      url = url + '/' + operation;
    }
    return url;
  }

  public closeLot(model: Lot): Promise<Lot> {
    const url = `${environment.apiUrl}/lots/${encodeURIComponent(model.id)}`;
    return this.updateBase(model, url);
  }

  regenerateLot(idLot: string) {
    let url = `${environment.apiUrl}/lots`;
    url = (url + '/' + encodeURIComponent(idLot)) + '/regenerate';
    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(url, null, { headers: header })
      .pipe(
        catchError(this.handleError)
      ).toPromise();
  }

  protected parse(obj) {
    return Lot.parse(obj);
  }

  protected unparse(obj) {
    return Lot.unparse(obj);
  }

  private updateBase(model: Lot, url): Promise<Lot> {
    return this.http.put(url, this.parse(model)).pipe(
      catchError(this.handleError),
      map(m => this.setCache(m.id, m))
    ).toPromise();
  }
}
