import { Component, OnInit, EmbeddedViewRef } from '@angular/core';
import { ComponentModal, MessageModal, Modal, ModalItem, SelectModal } from 'src/app/core/models';
import { CanceledModalError, ClosedModalError } from 'src/app/core/errors';
import { ModalService } from 'src/app/core/services';
import { TreeviewI18n, TreeviewI18nDefault, TreeviewItem } from 'ngx-treeview';
import { Router } from '@angular/router';

@Component({
  providers: [
    {
      provide: TreeviewI18n, useValue: Object.assign(new TreeviewI18nDefault(), {
        getFilterNoItemsFoundText: (): string => 'Nenhum registro encontrado',
        getAllCheckboxText: (): string => 'Todos'
      })
    }
  ],
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass']
})
export class ModalComponent implements OnInit {
  public modals: Array<ModalItem> = [];

  constructor(private router: Router, private modalService: ModalService) {
    router.events.subscribe(change => {
      while (this.modals.length > 0) {
        this.onClose();
      }
    });
  }

  ngOnInit() {
    this.modalService.setComponent(this);
    this.modalService.getEvents().subscribe((item: ModalItem) => {
      this.modals.push(item);
    });
    this.modalService.getComands().subscribe((command: string) => {
      switch (command) {
        case 'submit':
          this.onSubmit();
          break;
        case 'cancel':
          this.onCancel();
          break;
        case 'close':
          this.onClose();
          break;
      }
    });
  }

  onClose() {
    const item = this.modals.pop();
    if (this.modals.length > 0) {
      const error = new ClosedModalError(item.modal);
      item.reject(error);
    }
  }

  onCancel() {
    const item = this.modals.pop();
    item.reject(new CanceledModalError(item.modal));
  }

  async onSubmit() {
    const item = this.modals[this.modals.length - 1];
    if (item?.modal instanceof ComponentModal) { // if it is a component modal it need to be validate
      const modal = item.modal as ComponentModal;
      if (modal.component.instance.isValid) {
        modal.component.instance.isSubmiting = true;
        if (this.modals.length === 1) {
          try {
            await modal.component.instance.onAllSubmited(); // if its the last modal we need to save
            this.modals.pop();
            item.resolve(item.modal);
          } catch {}
        } else {
          item.resolve(item.modal);
          this.modals.pop();
        }
        modal.component.instance.isSubmiting = false;
      }
    } else { // if it is a message modal it always just closes
      this.modals.pop();
      item?.resolve(item.modal);
    }
  }

  isOpen(type) {
    for (const item of this.modals) {
      if (item.modal instanceof ComponentModal) {
        const modal = item.modal as ComponentModal;
        if (modal.type === type) {
          return true;
        }
      }
    }
    return false;
  }

  selectChange(modal: SelectModal, event: any[]) {
    if (modal.single) {
      const newValue = [];
      for (const id of event) {
        if (!modal.values.includes(id)) {
          newValue.push(id);
          break;
        }
      }
      modal.values = newValue;
      for (const spot of modal.items) {
        spot.checked = false;
        if (spot.children != null) {
          for (const lane of spot.children) {
            lane.checked = modal.values.includes(lane.value);
            if (lane.checked) {
              spot.checked = undefined;
            }
          }
        }
      }
    } else {
      modal.values = event;
    }
  }

  singleSelection(tree: TreeviewItem[], values: any[]) {
    let resp = false;
    for (const item of tree) {
      item.checked = false;
      if (item.children != null) {
        item.checked = this.singleSelection(item.children, values);
      } else {
        item.checked = values.includes(item.value);
        if (item.checked) {
          resp = undefined;
        }
      }
    }
    return resp;
  }

  isSelectModal(modal: Modal) {
    return modal instanceof SelectModal;
  }
  isMessageModal(modal: Modal) {
    return modal instanceof MessageModal;
  }
  isComponentModal(modal: Modal) {
    return modal instanceof ComponentModal;
  }
  modalIsValid(modal: Modal) {
    return (modal as ComponentModal).component.instance.isValid;
  }
}
