import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService, EquipmentModelService, CalibrationService, ContractService, LaneService, SpotService } from 'src/app/core/services';
import { Equipment, EquipmentModel, Calibration, ComponentModal, Spot, Lane, Contract } from 'src/app/core/models';
import * as moment from 'moment';
import { ClosedModalError } from 'src/app/core/errors';
import { ViewFileModalComponent } from 'src/app/shared/view-file-modal/view-file-modal.component';
import { getCalibrationFileName } from '../../../core/utils/getCalibrationFileName';

@Component({
  selector: 'app-equipment-view-modal',
  templateUrl: './equipment-view-modal.component.html',
  styleUrls: ['./equipment-view-modal.component.sass']
})
export class EquipmentViewModalComponent extends BaseModal implements OnInit {
  @ViewChild('carousel', { static: true }) carousel;
  isValid: boolean;
  lastState: any;
  public equipment: Equipment;
  public calibrations: Array<Calibration>;
  public shownCalibration: Calibration = new Calibration();
  public equipmentModel: EquipmentModel = new EquipmentModel();
  public models = [];
  public spotsAll = [];
  public shownCalibrations;
  public metrologicCalibrations: Array<Calibration> = [];
  public nonMetrologicCalibrations: Array<Calibration> = [];
  public contract: Contract;
  laneCodes = [];
  spot: Spot;
  public spots: Array<Spot> = [];
  public lanes: Array<Lane> = [];
  public getCalibrationFileName = getCalibrationFileName;
  public isLoading: boolean;

  constructor(
    modalService: ModalService,
    private calibrationService: CalibrationService,
    private equipmentModelService: EquipmentModelService,
    private contractService: ContractService,
    private laneService: LaneService,
    private spotService: SpotService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.isLoading = true;
    this.equipment = this.initialState as Equipment || new Equipment();
    this.contractService.getById(this.equipment.contractId).then(contract => {
      this.contract = contract;
    });
    if (this.equipment.spotIds && this.equipment.spotIds != null) {
      this.equipment.spotIds.forEach(spotId => {
        this.spotService.getById(spotId).then(spot => {
          this.spots.push(spot);
        });
      });
    }
    if (this.equipment.laneIds && this.equipment.laneIds.length > 0) {
      this.equipment.laneIds.forEach(laneId => {
        this.laneService.getById(laneId).then(lane => {
          this.lanes.push(lane);
        });
      });
    }
    this.spotService.getAll()
      .then(spots => {
        for (const spot of spots) {
          this.spotsAll[spot.id] = spot;
        }
      });

    this.equipmentModelService.getAll()
      .then(equipmentModels => {
        for (const equipmentModel of equipmentModels) {
          this.models[equipmentModel.id] = equipmentModel;
        }
        this.equipmentModel = equipmentModels.find( e => e.id === this.equipment.equipmentModelId);
      });
    this.calibrationService.listByEquipment(this.equipment.id).then(calibrations => {
      const now = new Date().toISOString();
      this.calibrations = calibrations.filter(calibration => calibration.id === this.equipment.metrologicCalibrationId ||
          calibration.id === this.equipment.nonMetrologicCalibrationId ||
          (calibration.startsAt <= now && calibration.endsAt >= now) || calibration.startsAt >= now);
      this.calibrations.forEach(calibration => {
        if (calibration.type === 'metrologic') {
          if (calibration.startsAt <= now && calibration.endsAt >= now) {
            this.metrologicCalibrations.unshift(calibration);
          } else {
            this.metrologicCalibrations.push(calibration);
          }
        } else {
          if (calibration.startsAt <= now && calibration.endsAt >= now) {
            this.nonMetrologicCalibrations.unshift(calibration);
          } else {
            this.nonMetrologicCalibrations.push(calibration);
          }
        }
      });
      if (this.metrologicCalibrations.length > 0) {
        this.shownCalibrations = this.metrologicCalibrations;
      } else {
        this.shownCalibrations = this.nonMetrologicCalibrations;
      }
    }).finally(() => this.isLoading = false) ;
  }

  getFileName(document) {
    const result = document && document.match(/(metrologic\/)([^\?]+)/) || '';
    return result !== '' ? result[2] : result;
  }

  async onAllSubmited(): Promise<any> {
  }

  copyInputToken(inputElement): void {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  dateFormater(date: Date, format): string {
    if (date == null) {
      return null;
    }
    const formatedDate = moment(date).format(format);
    return formatedDate;
  }

  openViewFileModal(file, files) {
    if (file != null) {
      this.modalService.show(new ComponentModal(ViewFileModalComponent,
        { files, shownFile: file }))
        .catch(err => {
          if (err instanceof ClosedModalError) {
            const modalError = err as ClosedModalError;
          }
        });
    }
  }

  getSpotCode(spotId){
    const spot = this.spots.find(e => e.id === spotId);
    return spot ? spot.code : '';
  }

  previous(index) {
    this.shownCalibration = this.shownCalibrations[index - 1];
    $(this.carousel.nativeElement).carousel('prev');
  }

  next(index) {
    this.shownCalibration = this.shownCalibrations[index + 1];
    $(this.carousel.nativeElement).carousel('next');
  }
}
