import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { AlertItem, AlertType, Contract, Lane, MessageModal, Spot, StatusStorage, StorageLocation } from 'src/app/core/models';
import {
  AlertService, ContractService, LaneService, ModalService,
  PreProcessSettingsService,
  ReasonService,
  SpotService,
  StatusStorageService, StorageKey, StorageLocationService,
  StorageService
} from 'src/app/core/services';
import { ReportOcrService } from 'src/app/core/services/reportOcr.service';
@Component({
  selector: 'app-ocr-report',
  templateUrl: './ocr-report.component.html',
  styleUrls: ['./ocr-report.component.sass']
})
export class OcrReportComponent implements OnInit {
  public periodForm: FormGroup;
  public ocrReportForm: FormGroup;
  public timeLoad: any = '';
  public storageLocations = [];
  public statusStorages = [];
  public statusStoragesById = {};
  public contractId = null;
  public params = {};
  public noRegister = false;
  public isValid = false;
  public contract = new Contract();
  public promise: Promise<any>;
  public process = 'utilization';
  public reportOcrs = [];
  public reportOcr: any = {};
  public reportOcrByContract: any = {};
  public reportOcrBySpot: any = {};
  public reportOcrByLane: any = {};
  public spotsSelected: any = [];
  public laneIdsSelected: any = [];

  public typeReport = [
    {
      id: 1,
      name: 'reportOCrDetail',
      value: 'Aproveitamento Detalhado'
    },
    {
      id: 2,
      name: 'reportOcrChangePlate',
      value: 'Aproveitamento por Caracter da Placa'
    },
    {
      id: 3,
      name: 'reportOCrPeriod',
      value: 'Aproveitamento por Período'
    }
  ];
  public lanesPromise: Promise<Array<Lane>>;
  public spotsPromise: Promise<Array<Spot>>;
  public spotById: { [params: string]: Spot } = {};
  public lanes = [];
  public spots = [];
  public lanesBySpots: Array<Lane> = [];
  public reasons = [];
  public typeUnfold = 'more';
  public typeUnfoldTable = 'less';
  public typeUnfoldReportByPeriod = 'less';
  public typeUnfoldReportByChangePlate = 'less';
  public typeUnfoldReportByContract = 'less';
  public typeUnfoldReportBySpot = {};
  public typeUnfoldReportByLane = {};

  public storageByDate = {};
  public datesByPeriods = [];
  public periodFilter = '';
  public showReportByPeriod = false;
  public showReportByChangePlate = false;
  public allowedViewViolationsOCRFailed = false;

  private dateValues = {
    endHour: null,
    endDate: null,
    startDate: null,
    startHour: null,
    startHourNocturne: null,
    endHourNocturne: null,
    startHourDaytime: null,
    endHourDayTime: null
  };

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public storageLocationsService: StorageLocationService,
    public statusStorageService: StatusStorageService,
    public activatedRoute: ActivatedRoute,
    public contractService: ContractService,
    public reportOcrService: ReportOcrService,
    public modalService: ModalService,
    public storageService: StorageService,
    public laneService: LaneService,
    public spotService: SpotService,
    public reasonService: ReasonService,
    public preProcessService: PreProcessSettingsService,
  ) { }

  ngOnInit() {
    this.contractId = this.activatedRoute.snapshot.params.contractId;
    this.lanesPromise = this.laneService.getAll();
    this.spotService.list({ contractId: this.contractId }).then(res => {
      this.spots = res.result;
      this.laneService.list({ contractId: this.contractId }).then(resLanes => {
        this.lanes = resLanes.result;
        this.setLanes(this.spots.map(s => s.id));
      });
    });
    this.reasonService.list({ contractId: this.contractId, order: 'code', 'enabled[bool]': 'true' }).then(resReasons => {
      this.reasons = resReasons.result.sort((a, b): any => Number(a.code) - Number(b.code));
    });

    this.preProcessService.getById(this.contractId).then((preProcess) => {
      const allowedViewViolationsOCRFailed = preProcess?.allowedViewViolationsOCRFailed;
      const groupIds = preProcess?.userGroupIds || [];
      const currentUser = this.storageService.get(StorageKey.currentUser);
      if (currentUser && currentUser.superUser === true) {
        this.allowedViewViolationsOCRFailed = true;
      } else if (allowedViewViolationsOCRFailed && groupIds.length > 0) {
        if (currentUser && currentUser.groupIds.some(g => groupIds.includes(g))) {
          this.allowedViewViolationsOCRFailed = true;
        }
      }
    });

    this.contractService.getById(this.contractId).then(contract => {
      this.contract = contract;
    });
    this.params = {
      contractId: this.contractId,
      order: 'updatedAt',
      sortAsc: 'false',
      'typeReportId[in]': `[1, 2, 3]`
    };
    this.statusStorageService.getAll().then(resStatus => {
      this.statusStorages = resStatus;
      this.statusStorages.map(statusStorage => {
        this.statusStoragesById[statusStorage.id] = statusStorage;
      });
    });
    this.createForm();
  }

  setLocations(list) {
    this.storageLocations = list;
    this.datesByPeriods = [];
    this.storageLocations.map(storageLocation => {
      const nameStorage = storageLocation.name.split('.').slice(0, -1).join('.');
      const resultRegex = nameStorage.match(/\d+/);
      const periodReport = nameStorage.substring(resultRegex.index, storageLocation.name.length);
      const splitPeriodReport = periodReport.split('_');
      if (!this.datesByPeriods.includes(periodReport)) {
        this.datesByPeriods.push(periodReport);
      }
      const format = 'DD/MM/YYYY HH:mm:ss';
      const periodStart = moment(`${splitPeriodReport[0]}T${splitPeriodReport[1]}`).format(format);
      const periodEnd = moment(`${splitPeriodReport[2]}T${splitPeriodReport[3]}`).format(format);
      const period = `${periodStart} a ${periodEnd}`;

      if (this.storageByDate[periodReport] == null) {
        this.storageByDate[periodReport] = {};
      }
      let nameReport = '';
      switch (storageLocation.typeReportId) {
        case 1:
          nameReport = 'reportOCrDetail';
          break;
        case 2:
          nameReport = 'reportOcrChangePlate';
          break;
          case 3:
          nameReport = 'reportOCrPeriod';
          break;
        default:
          nameReport = null;
      }
      if (this.storageByDate[periodReport][nameReport] == null) {
        this.storageByDate[periodReport][nameReport] = {};
      }
      this.storageByDate[periodReport].period = period;
      this.storageByDate[periodReport][nameReport] = {
        statusStorageId: storageLocation.statusStorageId,
        modifiedAt: storageLocation.modifiedAt,
        key: storageLocation.key
      };
    });
  }


  createForm(): void {
    const hourRegex = new RegExp(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]$/);
    this.ocrReportForm = this.formBuilder.group({
      startDate: ['', [Validators.required, this.validateDate]],
      startHour: ['00:00:00', [Validators.required, this.validateDate,
      Validators.pattern(hourRegex)]],
      startHourDaytime: ['06:00:00', [Validators.required, Validators.pattern(hourRegex)]],
      endHourDayTime: ['17:59:59', [Validators.required, Validators.pattern(hourRegex)]],
      startHourNocturne: ['18:00:00', [Validators.required, Validators.pattern(hourRegex)]],
      endHourNocturne: ['05:59:59', [Validators.required, Validators.pattern(hourRegex)]],
      endDate: ['', [Validators.required, this.validateDate]],
      endHour: ['23:59:59', [Validators.required, this.validateDate, Validators.pattern(hourRegex)]],
      reportTypeIds: [[], [Validators.required]],
      spotIds: [[]],
      laneIds: [[]],
      reasonCodes: [[]],
      checkOcrNull: [false],
      checkConsiderReasons: [false]
    });
    this.ocrReportForm.valueChanges.subscribe(res => {
      this.isValid = this.ocrReportForm.valid;
      if (this.ocrReportForm.get('startDate').value !== '' && this.ocrReportForm.get('endDate').value) {
        const format = 'DD/MM/YYYY HH:mm:ss';

        const periodStart = moment
        (`${this.ocrReportForm.get('startDate').value?.trim()}T${this.ocrReportForm.get('startHour').value?.trim()}`).format(format);

        const periodEnd = moment
        (`${this.ocrReportForm.get('endDate').value?.trim()}T${this.ocrReportForm.get('endHour').value?.trim()}`).format(format);
        this.periodFilter = `${periodStart} à ${periodEnd}`;
      }
    });
    this.ocrReportForm.get('checkConsiderReasons').valueChanges.subscribe(value => {
      if (value === false) {
        this.ocrReportForm.patchValue({
          reasonCodes: []
        });
      }
    });
  }

  validateDate = () => {
    if (this.ocrReportForm == null) {
      return null;
    }
    const dateControls: any = {};
    const newDateValues = {};
    let hasChange = false;
    for (const key of Object.keys(this.dateValues)) {
      dateControls[key] = this.ocrReportForm.get(key);
      newDateValues[key] = dateControls[key].value;
      if (newDateValues[key] !== this.dateValues[key]) {
        hasChange = true;
      }
    }
    this.dateValues = Object.assign(newDateValues);
    if (hasChange) {
      for (const key of Object.keys(dateControls)) {
        dateControls[key].updateValueAndValidity();
      }
    }
    const startDate = moment(dateControls.startDate.value + ' ' + dateControls.startHour.value);
    const endDate = moment(dateControls.endDate.value + ' ' + dateControls.endHour.value);
    const failedSizeYear = startDate.year().toString().length > 4 || endDate.year().toString().length > 4;
    if (((dateControls.endDate.value + dateControls.endHour.value) <
      (dateControls.startDate.value + dateControls.startHour.value)) ||
      (endDate.diff(startDate, 'days') < 0 || failedSizeYear)) {
      return {
        invalidPeriod: 'Filtro de período inicial e final inválido'
      };
    } else {
      return null;
    }
  };

  getPercentage = (value: number, total: number) => {
    if (!value || !total) {
      return 0;
    }
    return Math.round((value / total) * 100);
  };

  async onAllSubmited() {
    if (!this.isValid || this.promise != null) {
      return this.promise;
    }
    if (this.ocrReportForm.get('reportTypeIds').value.length === 0) {
      this.ocrReportForm.get('reportTypeIds').setValue([1, 2, 3]);
    }
    const currentUser = this.storageService.get(StorageKey.currentUser);
    const reportTypeIds = this.ocrReportForm.get('reportTypeIds').value;
    const spotIds = this.ocrReportForm.get('spotIds').value;
    const laneIds = this.ocrReportForm.get('laneIds').value;
    let startDate = `${this.ocrReportForm.get('startDate').value} ${this.ocrReportForm.get('startHour').value}`;
    startDate = moment.tz(startDate, this.contract.timezone).format();
    let endDate = `${this.ocrReportForm.get('endDate').value} ${this.ocrReportForm.get('endHour').value}`;
    endDate = moment.tz(endDate, this.contract.timezone).format();
    const reasonCodes = this.ocrReportForm.get('reasonCodes').value;
    const hasPlateOcrNull = this.ocrReportForm.get('checkOcrNull').value;
    const startDateDayTime = this.ocrReportForm.get('startHourDaytime').value;
    const endHourDayTime = this.ocrReportForm.get('endHourDayTime').value;
    const startDateNocturne = this.ocrReportForm.get('startHourNocturne').value;
    const endHourNocturne = this.ocrReportForm.get('endHourNocturne').value;

    const data: any = {
      contractId: this.contractId,
      startDate,
      endDate,
      reportTypeIds,
      laneIds,
      spotIds,
      reasonCodes,
      hasPlateOcrNull,
      startDateDayTime,
      endHourDayTime,
      startDateNocturne,
      endHourNocturne
    };
    if (laneIds.length === 0) {
      delete data.laneIds;
    }
    if (spotIds.length === 0) {
      delete data.spotIds;
    }
    if (reasonCodes.length === 0) {
      delete data.reasonCodes;
    }

    await this.modalService.show(new MessageModal('Gerar Arquivos CSV(s)',
      `Será enviado um email para ${currentUser.email} quando finalizar
      a geração do arquivo(s) CSV(s). Acompanhe a coluna Situação da listagem abaixo`, false));

    this.promise = this.reportOcrService.utilization(data)
      .then((res) => {
        this.reportOcrs = res.query;
        const resultByContract = this.reportOcrs.reduce((accum, current) => {
          Object.entries(current).forEach(([key, value]) => {
            if (!['contractId', 'spotId', 'laneId'].includes(key)) {
              accum[key] = (Number(accum[key]) + Number(value)) || Number(value);
            }
          });
          return {
            ...accum
          };
        }, {});
        this.reportOcrByContract = resultByContract;
        this.reportOcrByContract = this.sumPercentage(this.reportOcrByContract);

        const spotIdsReportOcr = Object.values(
          this.reportOcrs.reduce((acc, current) => {
              if (!acc.length) {
                acc = [];
              }
              if (!acc.includes(current.spotId)) {
                acc.push(current.spotId);
              }
              return acc;
          }, {})
        );
        this.spotsSelected = this.spots.filter((s) => spotIdsReportOcr.includes(s.id));
        this.spotsSelected.map(s => {
          this.typeUnfoldReportBySpot[s.id] = 'less';
        });
        this.laneIdsSelected.map(laneId => {
          this.typeUnfoldReportByLane[laneId] = 'less';
        });

        spotIdsReportOcr.map((spotId: any) => {
          const reportsBySpot = this.reportOcrs.filter(r => r.spotId === spotId);
          if (!this.reportOcrBySpot[spotId]) {
            const resultBySpot = reportsBySpot.reduce((accum, current) => {
              Object.entries(current).forEach(([key, value]) => {
                if (!['contractId', 'spotId'].includes(key)) {
                  accum[key] = (Number(accum[key]) + Number(value)) || Number(value);
                } else if (!['laneId'].includes(key)) {
                  accum[key] = value;
                }
              });
              return {
                ...accum
              };
            }, {});
            this.reportOcrBySpot[spotId] = resultBySpot;
            this.reportOcrBySpot[spotId] = this.sumPercentage(this.reportOcrBySpot[spotId]);

            const spot = this.spots.find(s => s.id === spotId);
            spot.laneIds.map(laneId => {
              if (!this.laneIdsSelected.includes(laneId)) {
                this.laneIdsSelected.push(laneId);
              }
              const reportsByLane = this.reportOcrs.filter(r => r.spotId === spotId && r.laneId === laneId);
              if (!this.reportOcrByLane[laneId]) {
                const resultByLane = reportsByLane.reduce((accum, current) => {
                  Object.entries(current).forEach(([key, value]) => {
                    if (!['contractId', 'spotId', 'laneId'].includes(key)) {
                      accum[key] = (Number(accum[key]) + Number(value)) || Number(value);
                    } else {
                      accum[key] = value;
                    }
                    if (key === 'laneId') {
                      accum.laneCode = this.lanes.find(l => l.id === value).code;
                      accum.laneDescription = this.lanes.find(l => l.id === value).laneDescription;
                    }
                  });
                  return {
                    ...accum
                  };
                }, {});
                this.reportOcrByLane[laneId] = resultByLane;
                this.typeUnfoldReportByLane[laneId] = 'less';
                this.reportOcrByLane[laneId] = this.sumPercentage(this.reportOcrByLane[laneId]);
              }
            });
          }
        });
        this.storageLocationsService.getAll(this.params).then(resStorages => {
          this.storageLocations = resStorages;
          if (this.ocrReportForm.get('reportTypeIds').value.includes(3)) {
            this.showReportByPeriod = true;
          }
          if (this.ocrReportForm.get('reportTypeIds').value.includes(2)) {
            this.showReportByChangePlate = true;
          }
          this.datesByPeriods = [];
          this.storageLocations.map(storageLocation => {
            const nameStorage = storageLocation.name.split('.').slice(0, -1).join('.');
            const resultRegex = nameStorage.match(/\d+/);
            const periodReport = nameStorage.substring(resultRegex.index, storageLocation.name.length);
            const splitPeriodReport = periodReport.split('_');
            if (!this.datesByPeriods.includes(periodReport)) {
              this.datesByPeriods.push(periodReport);
            }
            const format = 'DD/MM/YYYY HH:mm:ss';
            const periodStart = moment(`${splitPeriodReport[0]}T${splitPeriodReport[1]}`).format(format);
            const periodEnd = moment(`${splitPeriodReport[2]}T${splitPeriodReport[3]}`).format(format);
            const period = `${periodStart} a ${periodEnd}`;

            if (this.storageByDate[periodReport] == null) {
              this.storageByDate[periodReport] = {};
            }
            let nameReport = '';
            switch (storageLocation.typeReportId) {
              case 1:
                nameReport = 'reportOCrDetail';
                break;
              case 2:
                nameReport = 'reportOcrChangePlate';
                break;
              case 3:
                nameReport = 'reportOCrPeriod';
                break;
              default:
                nameReport = null;
            }
            if (this.storageByDate[periodReport][nameReport] == null) {
              this.storageByDate[periodReport][nameReport] = {};
            }
            this.storageByDate[periodReport].period = period;
            this.storageByDate[periodReport][nameReport] = {
              statusStorageId: storageLocation.statusStorageId,
              modifiedAt: storageLocation.modifiedAt,
              key: storageLocation.key
            };
          });
        });
        this.alertService.show(
          new AlertItem('OcrReportSaved', AlertType.success)
        );
      }).catch(error => {
        this.alertService.show(new AlertItem('OcrReportSaveError', AlertType.danger));
        throw error;
      }).finally(() => {
        this.promise = null;
    });
    await this.promise;
    this.promise = null;
  }

  handleItems(e) {
    this.storageLocations = this.storageLocations.concat(e);
    if (this.storageLocations.length === 0) {
      this.noRegister = true;
    }
    const isLoadMinutesStorage = this.storageLocations.find(storageL => [1, 2, 4].includes(storageL.statusStorageId));
    if (isLoadMinutesStorage) {
      this.minutestoLoadStorage();
    }
  }

  minutestoLoadStorage() {
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    this.timeLoad = moment(new Date()).set(
      {
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }).format('HH:mm:ss');
    (this.repeat)();
  }
  repeat = () => {
    if (this.timeLoad === '00:03:00') {
      this.timeLoad = moment(new Date()).set(
        {
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).format('HH:mm:ss');

      window.location.reload();
    }
    setTimeout(() => {
      this.timeLoad = moment(this.timeLoad, 'HH:mm:ss').add(1, 'seconds').format('HH:mm:ss');
      this.repeat();
    }, 1000);
  };

  redefineFilter() {
    this.createForm();
  }

  changeMenu(process) {
    this.process = process;
  }

  setLanes(spotIds) {
    if (spotIds.length === 0) {
      this.ocrReportForm.patchValue({ laneIds: [] });
    }
    this.lanesBySpots = [];
    if (spotIds && spotIds.length > 0) {
      this.lanesBySpots = this.lanes.filter(lane => spotIds.includes(lane.spotId));
    }
  }

  unfoldMoreOrLess() {
    this.typeUnfold = this.typeUnfold === 'more' ? 'less' : 'more';
    setTimeout(() =>
      document
        .getElementById(`userIdHistoryUnfold`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  unfoldMoreOrLessTable() {
    this.typeUnfoldTable = this.typeUnfoldTable === 'more' ? 'less' : 'more';
    setTimeout(() =>
      document
        .getElementById(`userIdHistoryUnfoldTable`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  unfoldMoreOrLessReportByPeriod() {
    this.typeUnfoldReportByPeriod = this.typeUnfoldReportByPeriod === 'more' ? 'less' : 'more';
    setTimeout(() =>
      document
        .getElementById(`userIdHistoryUnfoldReportByPeriod`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);

    if (this.typeUnfoldReportByPeriod === 'less') {
      this.typeUnfoldReportByContract = 'less';
      this.spotsSelected.map(s => {
        this.typeUnfoldReportBySpot[s.id] = 'less';
      });
      this.laneIdsSelected.map(laneId => {
        this.typeUnfoldReportByLane[laneId] = 'less';
      });
    }
  }

  unfoldMoreOrLessReportByContract(contractId) {
    this.typeUnfoldReportByContract = this.typeUnfoldReportByContract === 'more' ? 'less' : 'more';
    setTimeout(() =>
      document
        .getElementById(`userIdHistoryUnfoldReportByContract-${contractId}`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  unfoldMoreOrLessReportBySpot(spotId) {
    this.typeUnfoldReportBySpot[spotId] = this.typeUnfoldReportBySpot[spotId] === 'more' ? 'less' : 'more';
    setTimeout(() =>
      document
        .getElementById(`userIdHistoryUnfoldReportBySpot-${spotId}`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  unfoldMoreOrLessReportByLane(laneId) {
    this.typeUnfoldReportByLane[laneId] = this.typeUnfoldReportByLane[laneId] === 'more' ? 'less' : 'more';
    setTimeout(() =>
      document
        .getElementById(`userIdHistoryUnfoldReportByLane-${laneId}`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  unfoldMoreOrLessReportByChangePlate() {
    this.typeUnfoldReportByChangePlate = this.typeUnfoldReportByChangePlate === 'more' ? 'less' : 'more';
    setTimeout(() =>
      document
        .getElementById(`userIdHistoryUnfoldReportByChangePlate`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  sumPercentage(reportOcr) {
    let percentage1 = this.getPercentage(reportOcr.totalPlateTypingDaytime, reportOcr.totalCount);
    let percentage2 = this.getPercentage(reportOcr.totalPlateTypingNocturnal, reportOcr.totalCount);
    reportOcr.totalPlateTypingPercentage = percentage1 + percentage2;
    reportOcr.totalPlateTyping = reportOcr.totalPlateTyping ||
    ((reportOcr.totalPlateTypingDaytime || 0) + (reportOcr.totalPlateTypingNocturnal || 0));

    percentage1 = this.getPercentage(reportOcr.totalTecnicalDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalTecnicalNocturnal, reportOcr.totalCount);
    reportOcr.totalTecnicalPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.totalNonTecnicalDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalNonTecnicalNocturnal, reportOcr.totalCount);
    reportOcr.totalNonTecnicalPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.totalPlateReadByOCRDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalPlateReadByOCRNocturnal, reportOcr.totalCount);
    reportOcr.totalPlateReadByOCRPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.totalPlateNotReadByOCRDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalPlateNotReadByOCRNocturnal, reportOcr.totalCount);
    reportOcr.totalPlateNotReadByOCRPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.totalPlateEqualsOCRTypingDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalPlateEqualsOCRTypingNocturnal, reportOcr.totalCount);
    reportOcr.totalPlateEqualsOCRTypingPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.totalPlateDifferentOCRTypingDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalPlateDifferentOCRTypingNocturnal, reportOcr.totalCount);
    reportOcr.totalPlateDifferentOCRTypingPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.totalLettersEqualsDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalLettersEqualsNocturnal, reportOcr.totalCount);
    reportOcr.totalLettersEqualsPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.totalLettersDifferentDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalLettersDifferentNocturnal, reportOcr.totalCount);
    reportOcr.totalLettersDifferentPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.totalLettersNumbersDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalLettersNumbersNocturnal, reportOcr.totalCount);
    reportOcr.totalLettersNumbersPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.totalLettersNumbersDifferentDaytime, reportOcr.totalCount);
    percentage2 = this.getPercentage(reportOcr.totalLettersNumbersDifferentNocturnal, reportOcr.totalCount);
    reportOcr.totalLettersNumbersDifferentPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.positionPlate1CountOkDaytime, reportOcr.plateTypingTotalCount);
    percentage2 = this.getPercentage(reportOcr.positionPlate1CountOkNocturnal, reportOcr.plateTypingTotalCount);
    reportOcr.positionPlate1TotalCountOkPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.positionPlate2CountOkDaytime, reportOcr.plateTypingTotalCount);
    percentage2 = this.getPercentage(reportOcr.positionPlate2CountOkNocturnal, reportOcr.plateTypingTotalCount);
    reportOcr.positionPlate2TotalCountOkPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.positionPlate3CountOkDaytime, reportOcr.plateTypingTotalCount);
    percentage2 = this.getPercentage(reportOcr.positionPlate3CountOkNocturnal, reportOcr.plateTypingTotalCount);
    reportOcr.positionPlate3TotalCountOkPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.positionPlate4CountOkDaytime, reportOcr.plateTypingTotalCount);
    percentage2 = this.getPercentage(reportOcr.positionPlate4CountOkNocturnal, reportOcr.plateTypingTotalCount);
    reportOcr.positionPlate4TotalCountOkPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.positionPlate5CountOkDaytime, reportOcr.plateTypingTotalCount);
    percentage2 = this.getPercentage(reportOcr.positionPlate5CountOkNocturnal, reportOcr.plateTypingTotalCount);
    reportOcr.positionPlate5TotalCountOkPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.positionPlate6CountOkDaytime, reportOcr.plateTypingTotalCount);
    percentage2 = this.getPercentage(reportOcr.positionPlate6CountOkNocturnal, reportOcr.plateTypingTotalCount);
    reportOcr.positionPlate6TotalCountOkPercentage = percentage1 + percentage2;

    percentage1 = this.getPercentage(reportOcr.positionPlate7CountOkDaytime, reportOcr.plateTypingTotalCount);
    percentage2 = this.getPercentage(reportOcr.positionPlate7CountOkNocturnal, reportOcr.plateTypingTotalCount);
    reportOcr.positionPlate7TotalCountOkPercentage = percentage1 + percentage2;
    return reportOcr;
  }
}
