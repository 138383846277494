import { Component, OnInit } from '@angular/core';
import { Contract, ModalityVehicle, Regulation, VehicleWhiteList } from 'src/app/core/models';
import { RestrictionZones } from 'src/app/core/models/RestrictionZones';
import { ContractService, ModalityVehicleService, ModalService, RegulationService, RestrictionZoneService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-vehicle-white-list-view-modal',
  templateUrl: './vehicle-white-list-view-modal.component.html',
  styleUrls: ['./vehicle-white-list-view-modal.component.sass']
})
export class VehicleWhiteListViewModalComponent extends BaseModal implements OnInit {
  isValid: boolean;
  lastState: any;
  public vehicleWhiteList: VehicleWhiteList;
  public contract: Contract;
  public regulations: Array<Regulation> = [];
  public modalityVehicles: Array<ModalityVehicle> = [];
  public modalityVehiclesById: Array<ModalityVehicle> = [];
  public restrictionZones: Array<RestrictionZones> = [];
  public restrictionZonesById: Array<RestrictionZones> = [];

  constructor(
    modalService: ModalService,
    private contractService: ContractService,
    private regulationService: RegulationService,
    private modalityVehicleService: ModalityVehicleService,
    private restrictionZoneService: RestrictionZoneService

  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.vehicleWhiteList = this.initialState as VehicleWhiteList || new VehicleWhiteList();
    this.contractService.getById(this.vehicleWhiteList.contractId).then(contract => {
      this.contract = contract;
      this.regulationService.getAll({ regionId: contract.regionId }).then(regulations => {
        this.regulations = regulations;
      });
      this.modalityVehicleService
        .getAll({ regionId: contract.regionId }).then(modalityVehicles => {
          this.modalityVehicles = modalityVehicles;
          modalityVehicles.forEach(modalityVehicle => {
            this.modalityVehiclesById[modalityVehicle.id] = modalityVehicle;
          });
        })
        .catch(() => { });
      this.restrictionZoneService.getAll({ contractId: contract.id }).then(restrictionZones => {
        this.restrictionZones = restrictionZones;
        restrictionZones.forEach(restrictionZone => {
          this.restrictionZonesById[restrictionZone.id] = restrictionZone;
        });
    });
    });
  }

  onAllSubmited(): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
