<div class="card-header">
  <h5 i18n class="my-2 font-weight-bold">Auditoria</h5>
</div>

<div class="card-body">
  <p i18n class="my-2 font-weight-bold">Auditar por Amostragem</p>
  <form [formGroup]="form">
    <div class="row mt-3 mb-1">
      <div class="mb-2 col-12">
        <!-- <mat-checkbox color="primary" formControlName="allowAuditInvalidateLot">
          <span i18n>Permitir ao usuário reprovar um lote.</span>
          <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top" appTooltip="Caso esta opção esteja selecionada, o sistema
              exibirá uma mensagem na tela de auditoria e possibilitará
              ao usuário confirmar ou não a reprovação de um lote, caso a
              quantidade máxima de infrações recusadas for atingida.">help</i>
        </mat-checkbox> -->
      </div>
      <div class="mb-2 col-12">
        <div class="row" class="align-text">
          <label i18n>Selecione o NQA (Nível de Qualidade Aceitável) para auditoria por amostragem: <i i18n-appTooltip
              class="material-icons icon-sm text-secondary" data-placement="top" appTooltip="O nível de critério de aceite é definido de acordo com a NBR 5426
                para o plano de amostragem simples">help</i><small class="text-danger">*</small></label>
          <select formControlName="acceptableQualityLevel">
            <option value="1.5">1.5</option>
            <option value="2.5">2.5</option>
          </select>
        </div>
      </div>
      <!-- <div class="mb-2 col-12">
        <app-input i18n-label i18n-placeholder placeholder="Selecione um motivo de invalidação"
        label="Selecione o motivo de invalidação que será aplicado para placas cadastradas na lista branca"
        type="select" selectText="prettyName" selectValue="id" [list]=""
        formControlName="reasonId">
      </app-input>
      </div> -->
      <!-- <div class="mb-2 col-12">
        <mat-checkbox color="primary" formControlName="">
          <span i18n>Caso ocorra alteração nas imagens durante o processamento as originais também devem ser exportadas</span>
          <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top"
            appTooltip="Todas as infrações reprovadas serão contabilizadas, porém, somente os registros invalidados com os motivos adicionados nesta configuração serão considerados para reprovar.">help</i>
        </mat-checkbox>
      </div>
      <div class="mb-2 col-12">
        <mat-checkbox color="primary" formControlName="">
          <span i18n>Permitir edição nos dados do veículo</span>
          <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top"
            appTooltip="Após a pesquisa dos dados veiculares, os campos selecionados ficarão habilitados possibilitando alteração.">help</i>
        </mat-checkbox>
      </div> -->

      <!-- <div class="mb-2 col-12">
        <label i18n>Selecione as informações relacionadas ao veículo que devem ser consideradas de preenchimento
          obrigatório:
          <i i18n-appTooltip
            class="material-icons icon-sm text-secondary" data-placement="top"
            appTooltip="Após a pesquisa dos dados veiculares, os campos selecionados ficarão habilitados possibilitando alteração.">help
          </i>
        </label>
        <div class="row">
          <div class="col-6 col-sm-4 col-lg-3">
            <mat-checkbox i18n color="primary" formControlName="allRequiredFields">Todos</mat-checkbox>
          </div>
          <app-enum-i18n [input]="vehicleRequiredFields" (output)="vehicleRequiredFieldsI18n=$event"></app-enum-i18n>
          <div class="col-6 col-sm-4 col-lg-3" *ngFor="let field of vehicleRequiredFieldsI18n">
            <mat-checkbox color="primary" [formControlName]="field.id" i18n>{{field.value}}</mat-checkbox>
          </div>
        </div>
      </div> -->
    </div>
  </form>
</div>

<hr>

<div class="card-body">
  <div class="row mb-3">
    <div class="col-lg-9">
      <p i18n class="my-2 font-weight-bold">Configurações para reprovar lotes
        <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top" appTooltip="Todas as infrações reprovadas serão contabilizadas, porém, somente os registros invalidados de acordo com as configurações abaixo serão consideradas para reprovar um lote.">help</i></p>
    </div>
    <div class="col-lg-3">
      <button class="btn btn-primary" i18n (click)="addFieldsToLotRefused()">Adicionar Novo</button>
    </div>
  </div>
  <div class="box-card card" *ngFor="let setting of fieldsToLotRefused">
    <button class="btn-close" (click)="removeFieldsToLotRefused(setting)"><i i18n-appTooltip class="material-icons icon-sm text-secondary">close</i></button>
      <div class="card-body">
        <p i18n>Campos que devem ser considerados (se alterados), para reprovar um lote:</p>
        <mat-checkbox color="primary" i18n *ngIf="setting.violationStatus === 'valid'"
          (click)="setFieldsToLotRefused(setting.id, {violationStatus: setting.violationStatus})"
          [checked]="true">Status da Infração</mat-checkbox>
          <mat-checkbox color="primary" i18n *ngIf="setting.violationStatus !== 'valid'"
          (click)="setFieldsToLotRefused(setting.id, {violationStatus: ''})">Status da Infração</mat-checkbox>
        <div class="row mb-2">
          <div class="col-4 pt-2">
            <mat-checkbox color="primary" i18n *ngIf="setting.reasonIds.length > 0" [checked]="true"
              (click)="setFieldsToLotRefused(setting.id, {reasonsEnabled: true})">Motivo de Invalidação</mat-checkbox>
            <mat-checkbox color="primary" i18n *ngIf="setting.reasonIds.length === 0 && setting.reasonsEnabled" [checked]="setting.reasonsEnabled"
              (click)="setFieldsToLotRefused(setting.id, {reasonsEnabled: true})">Motivo de Invalidação</mat-checkbox>
            <mat-checkbox color="primary" i18n *ngIf="setting.reasonIds.length === 0 && !setting.reasonsEnabled"
              (click)="setFieldsToLotRefused(setting.id, {reasonsEnabled: true})">Motivo de Invalidação</mat-checkbox>
          </div>
          <div class="col-8" *ngIf="setting.reasonsEnabled || setting.reasonIds.length > 0 || setting.reasonsEnabled && setting.reasonIds.length === 0">
            <div class="select-wrapper">
              <div class="form-control select-box-button" (click)="selectBox(setting)">
                <p>
                  <span *ngIf="setting.reasonIds.length === 0">Selecione um motivo de invalidação</span>
                  <span class="viewItem" *ngFor="let r of setting.reasonIds">{{ reasonsById[r]?.prettyName }}</span>
                </p>
                <div class="card select-box" [ngClass]="{active: setting.boxOpen}">
                  <div class="card-body">
                    <mat-checkbox i18n color="primary" *ngIf="setting.reasonIds.length === reasons?.length"
                      (click)="setFieldsToLotRefused(setting.id, {reasonId: '!all'})" [checked]="true">
                        Todos
                    </mat-checkbox>
                    <mat-checkbox i18n color="primary" *ngIf="setting.reasonIds.length !== reasons?.length"
                      (click)="setFieldsToLotRefused(setting.id, {reasonId: 'all'})" [checked]="false">
                        Todos
                    </mat-checkbox>
                    <div *ngFor="let reason of reasons">
                      <mat-checkbox i18n color="primary" *ngIf="setting.reasonIds.includes(reason.id)"
                      (click)="setFieldsToLotRefused(setting.id, {reasonId: reason.id})" [checked]="true">
                        {{ reason.prettyName }}
                      </mat-checkbox>
                      <mat-checkbox i18n color="primary" *ngIf="!setting.reasonIds.includes(reason.id)"
                      (click)="setFieldsToLotRefused(setting.id, {reasonId: reason.id})">
                        {{ reason.prettyName }}
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mat-checkbox color="primary" i18n *ngIf="setting.vehicleFields.length > 0" [checked]="true"
          (click)="setFieldsToLotRefused(setting.id, {vehicleFieldsEnabled: true})">Dados Veiculares</mat-checkbox>
        <mat-checkbox color="primary" i18n *ngIf="setting.vehicleFields.length === 0 && setting.vehicleFieldsEnabled" [checked]="setting.vehicleFieldsEnabled"
          (click)="setFieldsToLotRefused(setting.id, {vehicleFieldsEnabled: true})">Dados Veiculares</mat-checkbox>
        <mat-checkbox color="primary" i18n *ngIf="setting.vehicleFields.length === 0 && !setting.vehicleFieldsEnabled"
          (click)="setFieldsToLotRefused(setting.id, {vehicleFieldsEnabled: true})">Dados Veiculares</mat-checkbox>
        <div class="row" *ngIf="setting.vehicleFieldsEnabled || setting.vehicleFields.length > 0 || setting.vehicleFieldsEnabled && setting.vehicleFields.length === 0">
          <div class="col-6 col-sm-4 col-lg-3">
            <mat-checkbox i18n color="primary"
              *ngIf="setting.vehicleFields.length === 7"
              (click)="setFieldsToLotRefused(setting.id, {vehicleFields: '!all'})"
              [checked]="true">Todos</mat-checkbox>
            <mat-checkbox i18n color="primary"
              *ngIf="setting.vehicleFields.length !== 7"
              (click)="setFieldsToLotRefused(setting.id, {vehicleFields: 'all'})"
              [checked]="false">Todos</mat-checkbox>
          </div>
          <app-enum-i18n [input]="vehicleRequiredFields" (output)="vehicleRequiredFieldsI18n=$event"></app-enum-i18n>
          <div class="col-6 col-sm-4 col-lg-3" *ngFor="let field of vehicleRequiredFieldsI18n">
            <mat-checkbox color="primary" i18n *ngIf="!setting.vehicleFields.includes(field.id) && field.value"
            (click)="setFieldsToLotRefused(setting.id, {vehicleFields: field.id})">{{field.value}}</mat-checkbox>

            <mat-checkbox color="primary" i18n *ngIf="setting.vehicleFields.includes(field.id) && field.value"
            (click)="setFieldsToLotRefused(setting.id, {vehicleFields: field.id})" [checked]="true">{{field.value}}</mat-checkbox>
          </div>
        </div>
      </div>
  </div>
</div>
