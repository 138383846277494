import { Component, OnInit } from '@angular/core';
import { ModalContent } from 'src/app/core/interface';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService } from 'src/app/core/services';


@Component({
  selector: 'app-shortcut-keys-modal',
  templateUrl: './shortcut-keys-modal.component.html',
  styleUrls: ['./shortcut-keys-modal.component.sass']
})
export class ShortcutKeysModalComponent extends BaseModal implements OnInit, ModalContent {
  public isValid = false;

  constructor(
    public modalService: ModalService
  ) {
    super(modalService);
   }

  ngOnInit() {
  }

  get lastState(): any {
    return null;
  }

  public async onAllSubmited() {}
}
