/* eslint-disable @typescript-eslint/member-ordering */
/// <reference types='@types/node'/>
import { Component, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import Jimp from 'jimp';
import { ComponentModal, FileType, PreProcessSettings } from 'src/app/core/models';
import { ImgEditModalComponent } from 'src/app/pages/pre-process/img-edit-modal/img-edit-modal.component';
import { ModalService } from 'src/app/core/services';
import { getImageLimits, getProportion } from 'src/app/core/utils/imageHandler';
import { ViewFileModalComponent } from '../view-file-modal/view-file-modal.component';
import { ViewVideoModalComponent } from '../view-video-modal/view-video-modal.component';
import _ from 'lodash';

@Component({
  selector: 'app-img-edit',
  templateUrl: './img-edit.component.html',
  styleUrls: ['./img-edit.component.sass']
})
export class ImgEditComponent implements AfterViewInit {
  @ViewChild('imgContainer', { static: false, read: ElementRef }) imgContainer: ElementRef;
  @ViewChild('img', { static: false, read: ElementRef }) img: ElementRef;
  @ViewChild('slider', { static: true, read: ElementRef }) slider: ElementRef;
  @ViewChild('navigation', { static: true, read: ElementRef }) navigation: ElementRef;
  @ViewChild('item', { static: false }) item: ElementRef;
  @ViewChild('carousel', { static: false }) carousel;

  @Output() outputValues = new EventEmitter();
  @Output() outputRestoreImage = new EventEmitter();
  @Input() currentType = '';
  @Input() rightColumn = false;
  @Input() isModal = false;
  @Input() isSingleView = false;
  @Input() isDoubleView = false;
  @Input() isAjustedSize = false;
  @Input() operation = '';
  @Input() isBlurEnabled = false;
  @Input() isClipEnabled = false;
  @Input() modifedBuffers = {};
  @Input() violationStatus: string;
  @Input() hideButton = false;
  @Input() preProcessSettings: PreProcessSettings;
  @Input() set files(files) {
    this.resetComponentValues();
    this._files = files;

    if (files != null && files.length > 0) {
      let file = files.find(f => f.type === 'zoom');
      if (file == null) {
        file = files[0];
      }
      this.loadImageBuffer();
      this.currentType = file.type;
    }
  }
  public slideConfig = { slidesToShow: 1, slidesToScroll: 1 };
  public element: HTMLElement;
  public imageBuffer;
  public lastImage;
  public selectedBlurSquare = 0;
  public _files: Array<{ src: string; type: string; targeHeight: number; targePosition: string; urlDecrypted: string; urlCurrent: string;
    urlEncrypted?: string; }>;
  public dragCoordinates: { [key: string]: Array<{ x: number; y: number; width: number; height: number }>} = {};
  public limits: { minX: number; minY: number; maxX: number; maxY: number } =
    { minX: 0, minY: 0, maxX: 0, maxY: 0 };
  public proportion = 1;
  public originalBuffers = {};
  public isLimitsLoaded = false;
  public firstLoad = true;
  public hideImageEdit = false;
  public loadImageEdit = false;
  public enabledImageEdit: boolean;
  public disabledPlateZoom: boolean;

  public get coordinates(): { [key: string]: Array<{ x: number; y: number; width: number; height: number }>} {
    const map = {};
    for (const key in this.dragCoordinates) {
      if (!this.dragCoordinates.hasOwnProperty(key) || this.dragCoordinates[key] == null) {
        continue;
      }
      map[key] = this.dragCoordinates[key].map(c => ({
          x: Math.round((c.x - this.limits.minX) / this.proportion),
          y: Math.round((c.y - this.limits.minY ) / this.proportion),
          width: Math.round(c.width / this.proportion),
          height: Math.round(c.height / this.proportion)
        }));
    }
    return map;
  }

  public set coordinates(value: { [key: string]: Array<{ x: number; y: number; width: number; height: number }>}) {
    this.dragCoordinates = {};
    for (const key in value) {
      if (!value.hasOwnProperty(key) || value[key] == null) {
        continue;
      }
      this.dragCoordinates[key] = value[key].map(c => ({
          x: Math.round(c.x * this.proportion + this.limits.minX),
          y: Math.round(c.y * this.proportion + this.limits.minY),
          width: Math.round(c.width * this.proportion),
          height: Math.round(c.height * this.proportion)
        }));
    }
  }

  public additionalImages = Object.keys(FileType);
  public additionalImagesI18n = [];

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  // tslint:disable-next-line:adjacent-overload-signatures
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get files() {
    return this._files;
  }

  private _imagesSettings: {[type: string]: {
    blur: { positions: [{width: number; height: number; x: number; y: number}]};
    contrast: number; brightness: number; grayscale: boolean; blackWhite: boolean;
    negative: boolean; color: [];} | any;
  } = {};

  @Input() set imagesSettings(value) {
    if (value == null) {
      this._imagesSettings = { [this.currentType]: {} };
    } else {
      this._imagesSettings = value;
    }
  }

  get imagesSettings() {
    return this._imagesSettings;
  }

  public isShowType = false;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event) {
    switch (event.key) {
      case '*':
        this.updateContrast(0.1);
        break;
      case '/':
        this.updateContrast(-0.1);
        break;
      case '+':
        this.updateBrightness(0.1);
        break;
      case '-':
        this.updateBrightness(-0.1);
        break;
    }
  }

  constructor(
    private modalService: ModalService,
  ) {
    this.violationStatus = '';
    this.loadImageEdit = true;
  }

  ngAfterViewInit(): void {
    if (this.carousel && this.carousel.nativeElement) {
      $(this.carousel.nativeElement).on('slide.bs.carousel', (e) => {
        this.currentType = this.files[e.to].type;
      });
    }
    if (localStorage.getItem('disabledPlateZoom')) {
      localStorage.removeItem('disabledPlateZoom');
      this.disabledPlateZoom = false;
    }
  }

  plateZoom() {
    if (localStorage.getItem('disabledPlateZoom')) {
      this.disabledPlateZoom = false;
      localStorage.removeItem('disabledPlateZoom');
    } else {
      this.disabledPlateZoom = true;
      localStorage.setItem('disabledPlateZoom', 'true');
      document.getElementById('img-magnifier-glass').style.display = 'none';
    }
  }

  async loadImageBuffer() {
    try {
      const file = this.files && this.files.find(f => f.type === this.currentType);
      this.isShowType = false;
      if (file == null || file.src == null) {
        return;
      }

      let jimp;
      if (this.originalBuffers[this.currentType] == null) {
        jimp = await Jimp.read(file.src);
        const buffer = await jimp.getBase64Async(Jimp.MIME_JPEG);
        this.originalBuffers[this.currentType] = buffer;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async applyImgChanges() {
    try {
      await this.loadImageBuffer();
      const buffer = this.originalBuffers[this.currentType];

      if (buffer == null) {
         return;
      }

      if ((this.imagesSettings && this.imagesSettings[this.currentType]) == null) {
        return;
      }
      const {brightness, contrast, grayscale, blackWhite, negative, color} = this.imagesSettings[this.currentType];
      let {blur, restore} = this.imagesSettings[this.currentType];
      const coordinates = this.coordinates[this.currentType];
      if (!brightness && !contrast && !grayscale && !blackWhite && !negative && !color && !restore &&
        (blur == null || blur.positions == null || blur.positions.length === 0)) {
        return;
      }
      let image = await Jimp.read(buffer);


      if (restore) {
        const file = this.files && this.files.find(f => f.type === this.currentType);

        image = await Jimp.read(file.urlDecrypted);
        if (this.operation === 'blur') {
          this.dragCoordinates[this.currentType] = [];
          this.updateBlurSettings();
        }

        if (blur && blur.positions.length === 0) {
          delete blur.positions;
          blur = undefined;
          delete this.imagesSettings[this.currentType].blur;
        }
        restore = false;
      }

      if (brightness != null && brightness !== 0) {
        image = image.brightness(brightness);
      }
      if (contrast != null && contrast !== 0) {
        image = image.contrast(contrast);
      }
      if (grayscale === true) {
        image = image.grayscale();
      }
      if (blackWhite === true) {
        image = image.grayscale().contrast(1);
      }
      if (negative === true) {
        image = image.invert();
      }
      if (color != null) {
        image = image.color(color);
      }
      if (blur != null && blur.positions != null && blur.positions.length > 0) {
        if (this.operation !== 'blur') { // skip setting blur if is the modal for performance issues
          image = this.applyBlur(image);
        }
      }
      this.modifedBuffers[this.currentType] = await image.getBase64Async(Jimp.MIME_JPEG);
    } catch (err) {
      console.error(err);
    }
  }


  applyBlur(image: Jimp): Jimp {
    const settings = this.imagesSettings && this.imagesSettings[this.currentType];
    if (settings == null || settings.blur == null || settings.blur.positions == null) {
      return;
    }
    const positions = settings.blur.positions;
    for (const position of positions) {
      const pixelSize = Math.min(image.getWidth() / 20, image.getHeight() / 20, position.width / 10, position.height / 10);
      const blur = image
        .clone()
        .crop(position.x, position.y, position.width, position.height)
        .blur(100)
        .pixelate(pixelSize);

      image = image.blit(blur, position.x, position.y);
    }
    return image;
  }

  onLoad() {
    if (this.firstLoad) {
      this.loadImageEdit = false;
      if (this.getValue(this.preProcessSettings, 'imageEdit.hideImageEdit')) {
        this.hideImageEdit = true;
      }

      if(this.violationStatus === 'valid') {
        this.enabledImageEdit = this.preProcessSettings.violationSteps?.verifyValid?.changesPermited;
      } else if(this.violationStatus === 'invalid') {
        this.enabledImageEdit = this.preProcessSettings.violationSteps?.verifyInvalid?.changesPermited;
      } else {
        this.enabledImageEdit = true;
      }

      this.getNewImgProportion();
      if (this.operation === 'blur') {
        if (this.imagesSettings != null &&
            this.imagesSettings[this.currentType] != null &&
            this.imagesSettings[this.currentType].blur != null &&
            this.imagesSettings[this.currentType].blur.positions != null) {
          this.coordinates = { // needs to be set this way because its a calculated property
            [this.currentType]: this.imagesSettings[this.currentType].blur.positions
          };
          this.applyImgChanges(); // needs to recalculate image without blur for consistency sake
        }
        this.addPosition();
      }
    }
     this.firstLoad = false;
  }

  getNewImgProportion() {
    if (this.imgContainer != null) {
      const limits = getImageLimits(this.imgContainer.nativeElement);
      this.proportion = getProportion(this.imgContainer.nativeElement);

      const file = this.files && this.files.find(f => f.type === this.currentType);
      if (file && file.targeHeight != null && file.targePosition != null) {
        if (file.targePosition === 'bottom') {
          limits.maxY = limits.maxY - file.targeHeight;
        } else if (file.targePosition === 'top') {
          limits.minY = limits.minY + file.targeHeight;
        }
      }
      this.limits = limits;
      this.isLimitsLoaded = true;
    }
  }

  async updateBrightness(value) {
    const type = this.currentType;
    if (this.imagesSettings[type] == null) {
      this.imagesSettings[type] = {
        brightness: 0
      };
    }
    if (this.imagesSettings[type].brightness == null) {
      this.imagesSettings[type].brightness = 0;
    }
    if (this.imagesSettings[type].brightness + value > 1 || this.imagesSettings[type].brightness + value < -1) {
      return;
    }
    this.imagesSettings[type].brightness = this.imagesSettings[type].brightness + value;
    this.applyImgChanges();
    this.outputValues.emit(this.imagesSettings);
  }

  async updateContrast(value) {
    const type = this.currentType;
    if (this.imagesSettings[type] == null) {
      this.imagesSettings[type] = {
        contrast: 0
      };
    }
    if (this.imagesSettings[type].contrast == null) {
      this.imagesSettings[type].contrast = 0;
    }
    if (this.imagesSettings[type].contrast + value > 1 || this.imagesSettings[type].contrast + value < -1) {
      return;
    }
    this.imagesSettings[type].contrast = this.imagesSettings[type].contrast + value;
    this.applyImgChanges();
    this.outputValues.emit(this.imagesSettings);
  }

  async updateGrayscale(buffer = null) {
    const type = this.currentType;
    if (this.imagesSettings[type] == null) {
      this.imagesSettings[type] = {};
    }
    this.imagesSettings[type].grayscale = true;
    this.applyImgChanges();
    this.outputValues.emit(this.imagesSettings);
    return buffer;
  }

  async updateBlackWhite(buffer = null) {
    const type = this.currentType;
    if (this.imagesSettings[type] == null) {
      this.imagesSettings[type] = {};
    }
    this.imagesSettings[type].blackWhite = true;
    this.applyImgChanges();
    this.outputValues.emit(this.imagesSettings);
    return buffer;
  }

  async updateNegative(buffer = null) {
    const type = this.currentType;
    if (this.imagesSettings[type] == null) {
      this.imagesSettings[type] = {};
    }
    this.imagesSettings[type].negative = true;
    this.applyImgChanges();
    this.outputValues.emit(this.imagesSettings);
    return buffer;
  }

  async updateRangeColor(color, value) {
    value = Number(value);
    const type = this.currentType;
    if (this.imagesSettings[type] == null) {
      this.imagesSettings[type] = {};
    }
    if (this.imagesSettings[type].color !== undefined) {
      if (this.imagesSettings[type].color.find(prop => prop.apply === color) !== undefined) {
        this.imagesSettings[type].color.find(prop => prop.apply === color).params = [value];
      } else {
        this.imagesSettings[type].color.push({ apply: color, params: [value] });
      }
    } else {
      this.imagesSettings[type].color = [{ apply: color, params: [value] }];
    }
    this.applyImgChanges();
    this.outputValues.emit(this.imagesSettings);
  }

  previous() {
    if (this.carousel != null) {
      $(this.carousel.nativeElement).carousel('prev');
      this.isShowType = true;
    }
  }

  next() {
    if (this.carousel != null) {
      $(this.carousel.nativeElement).carousel('next');
      this.isShowType = true;
    }
  }

  async openEditImageModal(file, operation) {
    if (this.isModal === true) {
      this.operation = operation;
      return;
    }

    const buffer = this.modifedBuffers[file.type];
    if (buffer != null) {
      file.src = buffer;
    }
    const files = [file];
    this.currentType = file.type;
    const imgSettingsCopy = JSON.parse(JSON.stringify(this.imagesSettings)); // deepcopy

    if (imgSettingsCopy && imgSettingsCopy[this.currentType] && imgSettingsCopy[this.currentType].restore) {

      imgSettingsCopy[this.currentType].restore = undefined;
    }

    const modifedBuffersCopy = Object.assign({}, this.modifedBuffers); // shallow copy
    await this.modalService.show(new ComponentModal(ImgEditModalComponent,
      { files, operation,
        type: this.currentType,
        isBlurEnabled: this.isBlurEnabled,
        hideButton: this.hideButton,
        imagesSettings: imgSettingsCopy,
        modifedBuffers: modifedBuffersCopy,
        isClipEnabled: this.isClipEnabled
      }))
      .then(async data => {
        const modal = data as any;

        const imageState = modal && modal.component && modal.component.instance && modal.component.instance &&
        modal.component.instance.lastImageState;
        const type = this.currentType;
        if (imageState[type] != null) {
          if (this.imagesSettings[type] == null) {
            this.imagesSettings[type] = {};
          }
          if (imageState[type].blur && imageState[type].blur.positions) {
            this.imagesSettings[type].blur = imageState[type].blur;
          } else {
            delete this.imagesSettings[type].blur;
          }

          const contrast = imageState[type].contrast;
          const brightness = imageState[type].brightness;
          const grayscale = imageState[type].grayscale;
          const restore = imageState[type].restore;
          let imgChanged = false;
          if (restore) {
            const blur = this.imagesSettings[type].blur;
            this.imagesSettings[type] = {};
            this.imagesSettings[type].blur = blur;
            this.imagesSettings[type].restore = true;

            imgChanged = true;
          } else {
            this.imagesSettings[type].restore = undefined;
          }
          if (this.imagesSettings[type].blur != null) {
            imgChanged = true;
          }
          if (typeof contrast === 'number' && this.imagesSettings[type].contrast !== contrast) {
            this.imagesSettings[type].contrast = contrast;
            imgChanged = true;
          }
          if (typeof brightness === 'number' && this.imagesSettings[type].contrast !== brightness) {
            this.imagesSettings[type].brightness = brightness;
            imgChanged = true;
          }
          if (grayscale === true && this.imagesSettings[type].grayscale !== grayscale) {
            this.imagesSettings[type].grayscale = true;
            imgChanged = true;
          }

          if (imgChanged) {
            this.applyImgChanges();
          }
          this.outputValues.emit(this.imagesSettings);
        }
      }).catch(console.error);
  }

  addPosition() {
    if (this.dragCoordinates == null) {
      this.dragCoordinates = {};
    }
    if (this.dragCoordinates[this.currentType] == null) {
      this.dragCoordinates[this.currentType] = [];
    }
    const coordinate = {
      width: 100,
      height: 50,
      x: 0,
      y: 0
    };
    this.dragCoordinates[this.currentType].push(coordinate);
    this.selectedBlurSquare = this.dragCoordinates[this.currentType].length - 1;
    this.updateBlurSettings();
    this.onResize();
  }

  restoreImage() {
    this.imagesSettings[this.currentType] = {};
    this.coordinates[this.currentType] = [];

    if (this.operation === 'blur') {
      this.imagesSettings[this.currentType].restore = true;
      this.modifedBuffers = {};
    }
    this.outputRestoreImage.emit(this.currentType);

    this.applyImgChanges();
  }

  dragEndUpdate(coordinates, i) {
    if (coordinates == null) {
      return;
    }
    this.dragCoordinates[this.currentType][i] = coordinates;
    this.updateBlurSettings();
  }

  removeSquare(index) {
    this.dragCoordinates[this.currentType].splice(index, 1);
    this.updateBlurSettings();
  }

  updateBlurSettings() {
    if (this.imagesSettings[this.currentType] == null) {
      this.imagesSettings[this.currentType] = {};
    }
    this.imagesSettings[this.currentType].blur = {
      positions: this.coordinates[this.currentType]
    };
    this.outputValues.emit(this.imagesSettings);
  }

  onResize() {
    const copy = this.coordinates; // since this is a calculated property, we dont need to duplicate, since the reference is lost anyway
    this.getNewImgProportion();
    // set coordinates again to force the dragCoordinates to be recalculated
    this.coordinates = copy;
  }

  resetComponentValues() {
    this.currentType = '';
    this.limits = { minX: 0, minY: 0, maxX: 0, maxY: 0 };
    this.proportion = 1;
    this.modifedBuffers = {};
    this.originalBuffers = {};
    this.selectedBlurSquare = 0;
    this.imagesSettings = {};
  }

  openViewFileModal(file, type) {
    if (this.operation !== '') {
      return;
    }

    const videos = this.files.filter((video) => !this.isVideo(video));

    const buffer = this.modifedBuffers[type];
    const files = videos.map(f =>
      buffer != null && f.type === type ? f.src = buffer : f.src
    );

    this.modalService.show(new ComponentModal(ViewFileModalComponent,
      {files, shownFile: buffer ? buffer : file  } )).catch(() => {});
  }

  getExtension(url): string {
    if (url != null) {
      const match = url.match(/\.[^.?]+(?=\?|$)/);
      if (match != null) {
        url = match[0];
      }
      return url;
    }
    return '';
  }

  videoExist() {
    return this.files.some((file) => this.isVideo(file));
  }

  isVideo(file) {
    const fileUrl = file.urlCurrent || file.urlDecrypted;
    const extension = this.getExtension(fileUrl);
    const videoTypes = ['.mpg', '.mp4', '.ogg'];
    return videoTypes.includes(extension);
  }

  isVideoSupported(file) {
    const fileUrl = file.urlCurrent || file.urlDecrypted;
    const extension = this.getExtension(fileUrl);
    const videoTypes = ['.mp4', '.ogg'];
    return videoTypes.includes(extension);
  }

  openViewVideoModal() {
    if (this.operation !== '') {
      return;
    }

    this.modalService.show(new ComponentModal(ViewVideoModalComponent,
      { files: this.files.filter((video) => this.isVideo(video)) } ))
      .catch(() => {});
  }

  setAdditionalImagesI18n(value) {
    this.additionalImagesI18n = value;
  }

  getType(type = '') {
    if (this.additionalImagesI18n.length === 0 || !type.startsWith('additional')) {
      return type;
    }
    const valueType = this.additionalImagesI18n.find(t => t.id === type);
    if (valueType != null) {
      return valueType.value;
    }
  }

  getValue(model, attr, value = '') {
    let arr = [];
    if (typeof attr === 'string') {
      arr = attr.split('.');
    } else {
      arr = attr;
    }
    if (arr.length > 0 && model) {
      if (model[arr[0]] == null) {
        return value;
      } else {
        return this.getValue(model[arr[0]], arr.slice(1), value);
      }
    } else {
      return model;
    }
  }
  magnify() {
    if (!localStorage.getItem('disabledPlateZoom')) {
      const zoom = 3;
      let glass = null;
      const img = this.img.nativeElement;
      if(document.getElementById('img-magnifier-glass')) {
        glass = document.getElementById('img-magnifier-glass');
        document.getElementById('img-magnifier-glass').style.display = 'block';
      } else {
        glass = document.createElement('DIV');
        glass.setAttribute('id', 'img-magnifier-glass');
        glass.setAttribute('class', 'img-magnifier-glass');
      }
      img.parentElement.insertBefore(glass, img);
      glass.style.backgroundImage = `url('${img.src}')`;
      glass.style.backgroundRepeat = 'no-repeat';
      glass.style.backgroundSize = `${img.width * zoom}px ${img.height * zoom}px`;
      const bw = 3;
      const w = glass.offsetWidth / 2;
      const h = glass.offsetHeight / 2;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
      glass.addEventListener('mousemove', moveMagnifier);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
      img.addEventListener('mousemove', moveMagnifier);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
      glass.addEventListener('touchmove', moveMagnifier);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
      img.addEventListener('touchmove', moveMagnifier);
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      function moveMagnifier(e) {
        let x; let y;
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
        const pos = getCursorPos(e);
        x = pos.x;
        y = pos.y;
        if (x > img.width - w / zoom) {
          x = img.width - w / zoom;
        }
        if (x < w / zoom) {
          x = w / zoom;
        }
        if (y > img.height - h / zoom) {
          y = img.height - h / zoom;
        }
        if (y < h / zoom) {
          y = h / zoom;
        }
        let positionY = y;
        if (positionY <= 125) {
          positionY = 125;
        }
        glass.style.left = `${(x + 50) - w}px`;
        glass.style.top = `${(positionY - 50) - h}px`;
        glass.style.backgroundPosition = `-${(x * zoom - w + bw)}px -${(y * zoom - h + bw)}px`;
      }
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      function getCursorPos(e) {
        let x = 0;
        let y = 0;
        e = e || window.event;
        const a = img.getBoundingClientRect();
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x, y };
      }
    }
  }
}
