import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ComponentModal, Contract, Modules } from 'src/app/core/models';
import { ContractService, ModalService } from 'src/app/core/services';
import { EquipmentModelPanelComponent } from '../equipment-model-panel/equipment-model-panel.component';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.sass']
})
export class ContractListComponent {
  public contracts: Contract[] = [];
  public params = {};
  public searchValue = '';
  public noRegister = false;
  public modules = Object.values(Modules);
  public modulesI18n = [];

  permissions = [];
  public user: any = {};

  private searchChanged: Subject<string> = new Subject<string>();

  constructor(
    public contractService: ContractService,
    private modalService: ModalService,
  ) {
    this.searchChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  handleSearch() {
    this.noRegister = false;
    this.contracts = [];
    const search = this.searchValue;
    const filterObj = {};
    if (search != null && search !== '') {
      filterObj['name[contains,or]'] = `${search}`;
      filterObj['code[contains,or]'] = `${search}`;
    }
    this.params = filterObj;
  }

  getModules(module: any[]) {
    const modulesContract = [];
    for (const i of module) {
      if (this.modulesI18n.length > 0) {
        modulesContract.push(this.modulesI18n.find(mod => mod.id === i).value);
      }
    }
    return modulesContract.join(', ');
  }

  searchKey(text: string) {
    this.searchChanged.next(text);
  }

  handleItems(e) {
    this.contracts = this.contracts.concat(e);
    if (this.contracts.length === 0) {
      this.noRegister = true;
    }
  }

  openEquipmentModelPanel(contract) {
    this.modalService.show(new ComponentModal(EquipmentModelPanelComponent, contract))
      .then(() => this.handleSearch())
      .catch(() => { });
  }

  getCompanies(companyies, ownerCompanyId){
    let companyNames = [];
    if (ownerCompanyId != null && ownerCompanyId !== '' && companyies != null) {
      companyNames = companyies.filter(company => company.id !== ownerCompanyId).map(c => c.name);
    }
    return companyNames.join(', ');
  }
}
