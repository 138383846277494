<form class="pt-3 pb-3" *ngIf="isTokenValid" [formGroup]="changePasswordForm" (submit)="setPassword()">
  <h3 class="mb-3" i18n>Criar senha</h3>

  <div *ngIf="!forgotPassword">
    <app-input type="text" formControlName="name" label="Nome" inputRequired=true></app-input>
  </div>

  <app-input type="password" formControlName="password" label="Informe sua senha" inputRequired=true></app-input>

  <app-input type="password" formControlName="passwordConfirmation" label="Confirme sua senha" inputRequired=true></app-input>

  <div class="regex pb-2">
    <p class="mb-0" [ngClass]="{ 'text-success': minCharacterValid, 'text-danger': !minCharacterValid }" i18n>- Mínimo seis caracteres</p>
    <p class="mb-0" [ngClass]="{ 'text-success': hasNumber, 'text-danger': !hasNumber }" i18n>- Ao menos um número</p>
    <p class="mb-0" [ngClass]="{ 'text-success': uppercaseCharacter, 'text-danger': !uppercaseCharacter }" i18n>- Ao menos uma letra maiúscula</p>
    <p class="mb-0" [ngClass]="{ 'text-success': specialCharacter, 'text-danger': !specialCharacter }" i18n>- Ao menos um caractere especial (Ex: ./#*)</p>
  </div>

  <div class="form-group">
    <div class="form-group form-check" *ngIf="!forgotPassword" [ngSwitch]="theme">
      <input type="checkbox" class="form-check-input" formControlName="privateTerm">
      <label class="form-check-label" i18n>Eu aceito o
          <a *ngSwitchDefault href="https://s3.amazonaws.com/docs.ecs.app.br/ecs-policy/Minuta_-_Termo_de_uso_Engebras.pdf" target="_blank"><b class="text-primary pointer">Termo de Uso de Política de Privacidade</b></a>
          <a *ngSwitchCase="'gtots'" href="https://s3.amazonaws.com/docs.ecs.app.br/ecs-policy/Minuta_-_Termo_de_uso_GTO.pdf" target="_blank"><b class="text-primary pointer">Termo de Uso de Política de Privacidade</b></a>
          <span *ngSwitchDefault class="modal-title"> do ECS.</span>
          <span *ngSwitchCase="'gtots'" class="modal-title"> do Gtots.</span>
        </label>
    </div>
  </div>

  <button type="submit" class="btn btn-primary btn-block" [disabled]="!isValid" i18n>
    <span *ngIf="!loading; else Loading">Confirmar senha</span>

      <ng-template #Loading>
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </ng-template>
  </button>
</form>
