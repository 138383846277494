import { BaseApiModel } from './BaseApiModel';
import { Vehicle } from './Vehicle';

export class SearchPlate extends BaseApiModel {
  public vehicle: Vehicle;
  public vehicleWhiteList: {
    id: string;
    contractId: string;
    plate: string;
    recordNumber: string;
    startsAt: string;
    endsAt: string;
    regulationId: string;
  };
}
