import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.sass']
})
export class BreadcrumbsComponent {
  public toBeTranslated = [];
  public allLevels = [];
  public translatedLevels;

  private _levels: string[] = [];
  @Input() set levels(value) {
    this._levels = value;
    this.toBeTranslated = [];
    setTimeout(() => {this.toBeTranslated = value;  }, 1);
  }
  get levels() {
    return this._levels;
  }

  constructor() { }

  getLink(index): string {
    const link = '/' + this.levels.slice(0, index).join('/');
    return link;
  }

  setTranslated(value) {
    this.translatedLevels = value.map(v => ({
      id: v.id,
      value: v.value?.trim() || v.id
    }));
  }
}
