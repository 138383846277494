import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractsRoutingModule } from './contracts-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EquipmentModelPanelComponent } from './equipment-model-panel/equipment-model-panel.component';
import { ConfigGeneralModelPanelComponent } from './config-general-model-panel/config-general-model-panel.component';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    ContractListComponent,
    EquipmentModelPanelComponent,
    ConfigGeneralModelPanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    ContractsRoutingModule
  ],
  entryComponents: [
    EquipmentModelPanelComponent,
    ConfigGeneralModelPanelComponent
  ]
})
export class ContractsModule { }
