import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { PreProcessSettings } from 'src/app/core/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as mermaid from 'mermaid';

// eslint-disable-next-line no-shadow
enum LotSteps {
  internalAudit = 'internalAudit',
  revision = 'revision',
  externalAudit = 'externalAudit',
  waitReturn = 'waitReturn',
  serialNumberGeneration = 'serialNumberGeneration',
  export = 'export',
  done = 'done'
}

@Component({
  selector: 'app-lot-steps',
  templateUrl: './lot-steps.component.html',
  styleUrls: ['./lot-steps.component.sass']
})
export class LotStepsComponent implements OnInit {
  @Input() preProcessSettings: PreProcessSettings = new PreProcessSettings();
  @Output() nextStepCommand = new EventEmitter();
  @Output() isValid = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  @ViewChild('mermaid', { static: true }) mermaid;
  public mermaidElement;
  public forms: Array<FormGroup> = [];
  public lotStepsI18n = [];
  public lotSteps = Object.values(LotSteps);
  public graphBody: Array<{
    step: string; name: string; acceptedValue: string; acceptedId: string; refusedValue: string; refusedId: string;
    acceptedIntersection: string; refusedIntersection: string;
  }> = [];
  public options = ['accepted', 'refused', 'start', 'done', 'lotAttribution'];
  public optionsI18n = [];
  public i18nResources: { [params: string]: { id: string; value: string } } = {};
  public graphValue = {};
  public isConsiderExport = false;
  get _isValid() {
    let isValid = true;
    if (this.forms && this.forms.length > 0) {
      isValid = this.forms.every(form => form.valid);
    }
    return isValid;
  }

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
  }

  createForm(params = { step: '', accepted: '', refused: '' }) {
    const form = this.formBuilder.group({
      step: [params.step, [this.noRepeatStepValidator, Validators.required]],
      accepted: [params.accepted, Validators.required],
      refused: [params.refused]
    });
    form.get('step').valueChanges.subscribe(value => {
      if (value === 'internalAudit') {
        form.patchValue({ refused: '' });
        form.get('refused').setValidators([Validators.required]);
        form.get('refused').setErrors(Validators.required);
      } else {
        form.get('refused').clearValidators();
      }
      this.isValid.emit(this._isValid);
    });
    return form;
  }

  setFormSteps() {
    const stepsUsed = [];
    const stepsPassed = [];
    let accepted = this.forms[0].get('accepted').value;
    let refused = this.forms[0].get('refused').value;
    if (accepted != null && accepted !== '' && !stepsUsed.includes(accepted) && accepted !== 'done') {
      stepsUsed.push(accepted);
    }
    if (refused != null && refused !== '' && !stepsUsed.includes(refused) && refused !== 'done') {
      stepsUsed.push(refused);
    }
    while (stepsUsed.length > stepsPassed.length) {
      const step = stepsUsed[stepsPassed.length];
      stepsPassed.push(step);
      const form = this.forms.find(f => f.get('step').value === step);
      if (form == null) {
        continue;
      }
      accepted = form.get('accepted').value;
      refused = form.get('refused').value;
      if (accepted != null && accepted !== '' && (accepted !== 'done') && !stepsUsed.includes(accepted)) {
        stepsUsed.push(accepted);
      }
      if (refused != null && refused !== '' && (accepted !== 'done') && !stepsUsed.includes(refused)) {
        stepsUsed.push(refused);
      }
    }
    const extraSteps = [];
    for (let i = 0; i < this.forms.length; i++) {
      if (i !== 0 && !stepsUsed.includes(this.forms[i].get('step').value)) {
        extraSteps.push(i);
      }
    }
    if (this._isValid === false) {
      for (let i = extraSteps.length - 1; i >= 0; i--) {
        this.removeStep(extraSteps[i]);
      }
    }
    const stepsMissing = stepsUsed.filter(s => !this.forms.some(f => f.get('step').value === s));
    for (const step of stepsMissing) {
      if (step !== 'done') {
        this.addNewStep(step);
      }
    }
  }

  addNewStep(step) {
    const form = this.createForm();
    if (step != null) {
      form.get('step').setValue(step);
    }
    this.forms.push(form);
    this.isValid.emit(this._isValid);
  }

  async updateGraph(index, hasToUpdateView = true) {
    this.setFormSteps();
    this.updateView();
    this.outputValue.emit(this.getOutputValue());
    this.isConsiderExport  = true;
  }

  updateView() {
    const outputValue = this.getOutputValue();
    const graph = outputValue.lotSteps;
    graph.start = outputValue.violationSteps.lotAttribution.lotStep;
    const waitReturn = outputValue.lotSteps.waitReturn && outputValue.lotSteps.waitReturn.nextStep;
    graph.done = waitReturn === 'done'  ? 'waitReturn' : 'export';
    if (graph.start == null) {
      delete graph.done;
    }
    if (graph.done === 'export') {
      delete graph.export;
    } else {
      delete graph.waitReturn;
    }


    // translate the graph to be shown properly
    const graphI18n = {};
    const acceptedI18n = (this.i18nResources.accepted && this.i18nResources.accepted.value) || 'accepted';
    const refusedI18n = (this.i18nResources.refused && this.i18nResources.refused.value) || 'refused';
    for (const state in graph) {
      if (state === 'start' || state === 'done') {
        const value = graph[state];
        if (value != null && value !== '') {
          graphI18n[state] = (this.i18nResources[value] && this.i18nResources[value].value) || value;
        }
      } else if (state != null && graph[state] != null) {
        const stateI18n = (this.i18nResources[state] && this.i18nResources[state].value) || state;
        const node = graph[state];
        let nodeI18n = {};
        if (node.accepted != null && node.accepted !== '') {
          nodeI18n[acceptedI18n] = (this.i18nResources[node.accepted] && this.i18nResources[node.accepted].value) || node.accepted;
        }
        if (node.refused != null && node.refused !== '') {
          nodeI18n[refusedI18n] = (this.i18nResources[node.refused] && this.i18nResources[node.refused].value) || node.refused;
        }
        if (node.nextStep != null && node.nextStep !== '') {
          nodeI18n = (this.i18nResources[node.nextStep] && this.i18nResources[node.nextStep].value) || node.nextStep;
        }
        graphI18n[stateI18n] = nodeI18n;
      }
    }
    this.graphValue = graphI18n;
  }

  noRepeatStepValidator = (stepControl) => {
    const step = stepControl.value;
    if (step === '' || step === null) {
      return null;
    }

    for (const form of this.forms) {
      const control = form.get('step');
      if (control !== stepControl && form.get('step').value === step) {
        return { duplicatedStep: 'This step has already been selected' };
      }
    }
    return null;
  };

  removeStep(index) {
    this.forms.splice(index, 1);
    this.graphBody.splice(index, 1);
    this.updateView();
    this.isValid.emit(this._isValid);
    if (this._isValid === true) {
      this.outputValue.emit(this.getOutputValue());
    }
  }

  async initialFlow() {
    if (this == null) {
      return;
    }
    const firstLotStep = this.preProcessSettings != null && this.preProcessSettings.violationSteps &&
      this.preProcessSettings.violationSteps.lotAttribution && this.preProcessSettings.violationSteps.lotAttribution.lotStep;
    if (firstLotStep == null) {
      this.forms.push(
        this.createForm({
          step: 'lotAttribution',
          accepted: 'export',
          refused: ''
        })
      );
      this.forms.push(
        this.createForm({
          step: 'export',
          accepted: 'done',
          refused: ''
        })
      );
    } else {
      this.forms.push(
        this.createForm({
          step: 'lotAttribution',
          accepted: firstLotStep,
          refused: ''
        })
      );
      Object.keys(this.preProcessSettings.lotSteps).forEach(step => {
        const accepted = this.preProcessSettings.lotSteps[step].accepted
          || this.preProcessSettings.lotSteps[step].nextStep || '';
        const refused = this.preProcessSettings.lotSteps[step].refused || '';
        if (accepted === '' && refused === '') {
          return;
        }
        this.forms.push(
          this.createForm({
            step,
            accepted,
            refused
          })
        );
      });
    }
    Object.keys(this.forms).forEach(i => {
      this.updateGraph(i, false);
    });
    this.updateView();
    this.isValid.emit(this._isValid);
  }

  getOutputValue() {
    this.isValid.emit(this._isValid);
    const lotSteps: any = {};
    let firstLotStep;
    if (this.forms.length > 0) {
      this.forms.forEach((form, index) => {
        const step = form.get('step').value;
        const accepted = form.get('accepted').value;
        const refused = form.get('refused').value;
        if (index === 0) {
          firstLotStep = accepted;
          return;
        }
        if (step === 'done' || step === 'start') {
          return;
        }
        lotSteps[step] = {};
        if (step === 'internalAudit') {
          lotSteps[step].accepted = accepted;
          lotSteps[step].refused = refused;
        } else {
          lotSteps[step].nextStep = accepted;
        }
      });
    } else {
      firstLotStep = 'export';
    }

    Object.values(LotSteps).forEach(step => {
      if (!lotSteps[step]) {
        lotSteps[step] = undefined;
      }
    });

    const paramsValues = {
      lotSteps,
      violationSteps: {
        lotAttribution: {
          lotStep: firstLotStep
        }
      }
    };
    return paramsValues;
  }

  setLotStepsI18n(lotSteps) {
    this.lotStepsI18n = lotSteps;
    lotSteps.forEach(lotStep => this.i18nResources[lotStep.id] = lotStep);
    if (this.optionsI18n.length > 0) {
      this.initialFlow();
    }
  }

  setOptionsI18n(options) {
    this.optionsI18n = options;
    options.forEach(option => this.i18nResources[option.id] = option);
    if (this.lotStepsI18n.length > 0) {
      this.initialFlow();
    }
  }
}
