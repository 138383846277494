import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import * as _ from 'lodash';

import { Regulation, Spot, Lane, Reason } from 'src/app/core/models';
import { ContractService, ViolationReportService } from 'src/app/core/services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-violations-report-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.sass']
})
export class ViolationsReportViewComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public contractId: string;
  public contractCode: string;
  public contractName: string;
  public regulations: Regulation[] = [];
  public contractViolations: any = {};
  public contractDataViolationsSub: Subscription;
  public contractViolationsSub: Subscription;
  public filterSub: Subscription;
  public violationsFormat: any[] = [];
  public spots: Spot[] = [];
  public violations: any[] = [];
  public noSpotViolations: any[] = [];
  public lanes: Array<Lane> = [];
  public reasons: Array<Reason> = [];
  public items: any[] = [];
  public noViolations: boolean;
  public allCollapse: boolean;
  public filterParam: any = { spotsIds: [] = [], laneIds: [] = [], regulationsIds: [] = [], situationsIds: [] = [], reasonsIds: [] = []};

  constructor(
    private violationReportService: ViolationReportService,
    private route: ActivatedRoute,
    private router: Router,
    private contractService: ContractService
  ) { }

  ngOnInit() {
    this.loading = false;

    this.contractDataViolationsSub = this.violationReportService.getContractDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.contractId = data.contractId;
        this.contractService.getById(this.contractId).then(contract => {
          this.contractCode = contract.code;
        });
        this.contractName = data.contractName;
        this.regulations = data.regulations;
        this.lanes = data.lanes;
        this.spots = data.spots;
        this.reasons = data.reasons;
      });

    this.contractViolationsSub = this.violationReportService.getContractViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0), debounceTime(1000))
      .subscribe((data: any) => {
        this.loading = false;

        if (this.spots && this.spots.length > 0) {
          this.setViolations(data);
          this.loading = true;
        }
      });

    this.filterSub = this.violationReportService.getFilterParamsViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0), debounceTime(1000))
      .subscribe((data: any) => this.filterParam = data);
  }

  setViolations(data) {
    this.violations = [];
    const contract: any = {};
    contract.spots = [];
    contract.regulations = {};
    contract.regulations.items = this.getResult(data, 'regulation');
    contract.situations = this.getResult(data, 'type');
    contract.total = contract.situations.reduce((acc, curr) => acc + curr.total, 0);

    contract.regulations.collapse = false;
    contract.collapse = false;
    contract.reasons = {};
    contract.reasons.collapse = false;

    if (this.haveInvalid(contract.situations)) {
      contract.reasons.items = this.getResult(data, 'situation');
    }
    const spotsWithViolations = _.chain(data).uniqBy('spotId').map('spotId').value();
    for (const spot of spotsWithViolations) {
      if (spot == null) {
        continue;
      }
      const objSpot: any = {};
      const spotInfo = this.spots.filter(item => item.id === spot).shift();
      const spotViolations = data.filter(item => item.spotId === spot);

      objSpot.code = spotInfo.code;
      objSpot.id = spotInfo.id;
      objSpot.description = (spotInfo.description || '');
      objSpot.lanes = [];
      objSpot.regulations = {};
      objSpot.regulations.items = this.getResult(spotViolations, 'regulation');
      objSpot.situations = this.getResult(spotViolations, 'type');
      objSpot.total = objSpot.situations.reduce((acc, curr) => acc + curr.total, 0);

      objSpot.regulations.collapse = false;
      objSpot.collapse = false;
      objSpot.reasons = {};
      objSpot.reasons.collapse = false;

      if (this.haveInvalid(objSpot.situations)) {
        objSpot.reasons.items = this.getResult(spotViolations, 'situation');
      }

      const lanesFromSpot = data.filter(item => item.spotId === spot);
      const lanesWithViolations = _.chain(lanesFromSpot).uniqBy('laneId').map('laneId').value();
      for (const lane of lanesWithViolations) {
        const objLane: any = {};
        const laneInfo = this.lanes.filter(item => item.id === lane).shift();
        const lanesViolations = lanesFromSpot.filter(item => item.laneId === lane);

        objLane.code = laneInfo ? laneInfo.code : lane.split('#').pop();
        objLane.id = lane;
        if (laneInfo.prettyNames) {
          objLane.name = laneInfo ? (laneInfo.prettyNames[0] || null) : 'no-register';
        }

        objLane.regulations = {};
        objLane.regulations.items = this.getResult(lanesViolations, 'regulation');
        objLane.situations = this.getResult(lanesViolations, 'type');
        objLane.total = objLane.situations.reduce((acc, curr) => acc + curr.total, 0);

        objLane.regulations.collapse = false;
        objLane.collapse = false;
        objLane.reasons = {};
        objLane.reasons.collapse = false;

        if (this.haveInvalid(objLane.situations)) {
          objLane.reasons.items = this.getResult(lanesViolations, 'situation');
        }

        objSpot.lanes.push(objLane);
      }

      contract.spots.push(objSpot);
    }

    contract.spots.sort((a, b) => b.total - a.total);
    this.violations.push(contract);
  }

  getResult(fromData: any[], byValue: string) {
    const listValues = _.chain(fromData).filter(byValue).uniqBy(byValue).map(byValue).value();
    return listValues.map(item => {
      const objResult: any = {};
      objResult.id = item;
      const listViolations = fromData.filter(itemFilter => itemFilter[byValue] === item);
      if (byValue === 'regulation') {
        objResult.code = item.split('#').pop();
        objResult.situations = this.getResult(listViolations, 'type');

        if (this.haveInvalid(objResult.situations)) {
          objResult.reasons = {};
          objResult.reasons.collapse = false;
          objResult.reasons.items = this.getResult(listViolations, 'situation');
        }
      }
      objResult.total = listViolations.reduce((acc, curr) => acc + curr.value, 0);
      return objResult;
    }).sort((a,b) => b.total - a.total);
  }

  haveInvalid = (fromData) => fromData.some(item => item.id === 'invalid' && item.total > 0);

  toggleCollapse(item: { collapse: boolean }, local: any = null, parent: any = null) {
    if (local) { parent[local].collapse = false; }
    item.collapse = !item.collapse;
  }

  toggleAllCollapse() {
    if (!this.allCollapse) {
      this.allCollapse = true;
      this.violations[0].spots.map(item => item.collapse = true);
    } else {
      this.allCollapse = false;
      this.violations[0].spots.map(item => item.collapse = false);
    }
  }

  getRegulationInfo(regulation: string) {
    if (this.regulations.length > 0) {
      const regulationInfo = this.regulations.filter(item => item.code === regulation).shift();
      return regulationInfo.description;
    }
  }

  getReasonInfo(reason: string) {
    if (this.reasons.length > 0) {
      const reasonInfo = this.reasons.find(item => item.code === reason);
      return reasonInfo != null ? `${reasonInfo.code} - ${reasonInfo.name}` : reason;
    }
  }

  getPercentage = (value: number, total: number) => ((value / total) * 100).toFixed(2);

  getValueByType(data: any = {}, type: string) {
    const value = data.situations.find(item => item.id === type);
    return value ? value.total : 0;
  }

  showRow(data: any = {}, type: string) {
    const value = this.getValueByType(data, type);
    return value > 0 ? `${value}/${this.getPercentage(value, data.total)}%` : 0;
  }

  showPercentage(data: any = {}, type: string) {
    const value = this.getValueByType(data, type);
    return value > 0 ? `${this.getPercentage(value, data.total)}%` : 0;
  }

  filterNavigate(target, situation = null, regulation = null, spot = null, lane = null, reason = null) {
    if (situation) {
      this.filterParam.situationsIds = new Array(situation);
    }
    if (spot) {
      if (this.filterParam.spotsIds == null) {
        this.filterParam.spotsIds = [];
      }
      this.filterParam.spotsIds.push(spot);
    }
    if (lane) {
      if (this.filterParam.laneIds == null) {
        this.filterParam.laneIds = [];
      }
      this.filterParam.laneIds.push(lane);
    }
    if (regulation) {
      const regulationInfo = this.regulations.find(r => r.code === regulation);
      this.filterParam.regulationsIds.push(regulationInfo.id);
    }
    if (reason) {
      if (!this.filterParam.situationsIds.includes('invalid')) { this.filterParam.situationsIds = new Array('invalid'); }
      const reasonInfo = this.reasons.filter(item => item.code === reason).shift();
      this.filterParam.reasonsIds.push(reasonInfo.id);
    }

    if (target === 'details') {
      this.violationReportService.emitFilterParamsDetailsViolationReport(this.filterParam);
      this.router.navigate(['by-date/all'], { relativeTo: this.route });
    } else {
      this.violationReportService.emitFilterParamsViolationReport(this.filterParam);
      this.router.navigate(['by-date'], { relativeTo: this.route });
    }
  }

  ngOnDestroy() {
    if (this.contractDataViolationsSub) {
      this.contractDataViolationsSub.unsubscribe();
      this.contractViolationsSub.unsubscribe();
    }
  }
}
