import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalibrationsListComponent } from './calibrations-list/calibrations-list.component';
import { DashboardComponent } from 'src/app/core/layouts/dashboard/dashboard.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: '', component: CalibrationsListComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalibrationsRoutingModule { }
