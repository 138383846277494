/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class TokenService {

  private baseUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  verifyToken(token: string) {
    const url = `${this.baseUrl}/users/verifyToken`;
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
    return this.http.get(url, { headers: header });
  }
}
