import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import * as moment from 'moment';

import { ProductionReportService, SessionService } from 'src/app/core/services';

@Component({
  selector: 'app-production-report-contract',
  templateUrl: './contract.component.html'
})
export class ProductionReportContractComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public contract: any;
  public dataProduction: any;
  public contractId: number;
  public stepId;
  public contractSub: Subscription;
  public filterSub: Subscription;
  public periodSub: Subscription;
  public filterParams: any = {};
  public periodParams: any = {};
  public contractName: string;
  public routeSub: Subscription;
  public routeLocale = '../';
  public isFiltered: boolean;

  constructor(
    private productionReportService: ProductionReportService,
    private sessionService: SessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.contractId = Number(this.activatedRoute.snapshot.params.contractId);

    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof ActivationStart) {
        if (event.snapshot.params.stepId) {
          this.routeLocale = `../${this.contractId}`;
        } else {
          this.routeLocale = '../';
        }
      }
    });

    this.contractSub = this.productionReportService.getDataProduction()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((dataProduction: any) => {
        this.dataProduction = dataProduction;
        this.contract = dataProduction
          .contractsProduction
          .filter(contract => contract.id === this.contractId)
          .shift();

        this.productionReportService.emitContractsProduction(this.contract);

        this.loading = true;
      });

    this.filterSub = this.productionReportService.getFilterProduction()
      .pipe(skip(1))
      .subscribe((dataFilter: any) => {
        this.filterParams = dataFilter;
        this.filterParams.usersIds = dataFilter.usersIds && dataFilter.usersIds.map(Number);

        if (Object.keys(this.periodParams).length === 0) {
          const now = new Date().toISOString();
          this.periodParams['startsAt[gte]'] = moment(now).utc().subtract(7, 'days').format();
          this.periodParams['modifiedAt[lte, or]'] = moment(now).utc().format();
        }
        this.isFiltered = true;
        this.createList();
      });

    this.periodSub = this.productionReportService.getPeriodProduction()
      .pipe(skip(1))
      .subscribe((dataPeriod: any) => {
        this.periodParams = dataPeriod;
        const dataContract = this.dataProduction
          .contracts
          .filter(contract => contract.id === this.contractId)
          .shift();
        if (Object.keys(this.filterParams).length === 0) {
          const valuesUsersGroupsIds = dataContract && dataContract.groups.map(group => group.id);
          const valuesUsersIds = this.contract.users.map(user => user.id);
          const valuesRegulationsIds = this.dataProduction.regulations.map(regulation => regulation.id);
          const valueStepsIds = dataContract && dataContract.steps;

          this.filterParams = {
            usersGroupsIds: valuesUsersGroupsIds,
            usersIds: valuesUsersIds,
            regulationsIds: valuesRegulationsIds,
            stepsIds: valueStepsIds
          };
        }

        this.createList();
      });
  }

  createList() {
    this.periodParams['contractId[in]'] = `[${this.contractId}]`;
    const stepsValues = ['typing', 'verifyInvalid', 'verifyValid', 'validate'];
    this.periodParams['step[in]'] = `[${stepsValues.join()}]`;
    const dataContract = this.dataProduction
      .contracts
      .filter(contract => contract.id === this.contractId)
      .shift();

    this.sessionService.getAll(this.periodParams).then(sessions => {
      const objContract: any = {};
      objContract.id = dataContract && dataContract.id;
      objContract.name = dataContract && dataContract.contractName;
      objContract.steps = this.filterParams.stepsIds;
      objContract.collapse = false;
      objContract.totalWork = 0;
      objContract.timeWorked = 0;
      objContract.regulations = [];
      objContract.timezone = dataContract && dataContract.timezone;

      objContract.groups = [];
      if (dataContract) {
        dataContract.groups.map(group => {
          if (this.filterParams.usersGroupsIds.includes(group.id)) {
            objContract.groups.push(group);
          }
        });
      }

      objContract.users = [];

      let usersList = sessions.map(item => item.userId).filter((value, index, self) => self.indexOf(value) === index);

      let usersContract;
      if (dataContract && dataContract.groups) {
        usersContract = dataContract.groups
          .map(group => group.users)
          .flat()
          .reduce((list, user) => {
            if (!list.includes(user)) { list.push(user); }
            return list;
          }, []);

        usersList = usersContract.concat(usersList).reduce((list, user) => {
          if (!list.includes(user)) { list.push(user); }
          return list;
        }, []);
      }

      if (this.isFiltered) {
        usersList = usersList.filter(item => this.filterParams.usersIds.includes(item));
      }

      for (const userId of usersList) {
        const objUser: any = {};
        const userSessions = sessions.filter(item => item.userId === userId);
        if (userSessions.length === 0) {
          continue;
        }
        objUser.id = userId;
        const userInfo = this.dataProduction.users.find(item => item.id === userId);
        objUser.name = (userInfo && userInfo.name) || userId;
        objUser.collapse = false;
        objUser.timeWorked = 0;
        objUser.totalWork = 0;
        objUser.superUser = (userInfo && userInfo.superUser);
        objUser.noInfo = userInfo === undefined ? true : false;
        objUser.noGroup = usersContract && !usersContract.includes(userId);
        if (this.filterParams.stepsIds) {
          this.filterParams.stepsIds.map(step => {
            if (dataContract && dataContract.steps.includes(step)) {
              const stepValid = step;
              const sessionFromStep = userSessions.filter(item => item.step === stepValid);
              objUser[step] = this.getSessionsSize(sessionFromStep, step);
            }
          });
        }

        objUser.dates = [];

        const datesWithSessions = userSessions.reduce((dates, session) => {
          const startsAt = dataContract && dataContract.timezone && moment.tz(session.startsAt, dataContract.timezone).format();
          const date = startsAt && startsAt.split('T')[0];

          if (date && !dates.includes(date)) { dates.push(date); }
          return dates.sort().reverse();
        }, []);

        for (const date of datesWithSessions) {
          const objDate: any = {};
          const dateSessions = userSessions.filter(session => {
            const startsAt = dataContract && dataContract.timezone && moment.tz(session.startsAt, dataContract.timezone).format();
            return startsAt.split('T')[0] === date;
          });

          objDate.date = date;
          objDate.timeWorked = 0;
          objDate.totalWork = 0;
          objDate.collapse = false;
          objDate.timeWorked = dateSessions.reduce(this.getTimeWorked, 0);

          this.filterParams.stepsIds.map(step => {
            if (dataContract && dataContract.steps.includes(step)) {
              const stepValid = step;
              const sessionFromStep = dateSessions.filter(item => item.step === stepValid);
              objDate[step] = this.getSessionsSize(sessionFromStep, step);
            }
          });

          objDate.regulations = [];
          for (const regulation of this.dataProduction.regulations) {
            const objRegulation: any = {};
            const regulationsSessions = dateSessions.filter(session => session[regulation.id] ||
              session['invalid#' + regulation.id] || session['valid#' + regulation.id]);

            if (this.filterParams.regulationsIds.includes(regulation.id)) {
              objRegulation.code = regulation.code;
              objRegulation.id = regulation.id;
              objRegulation.totalWork = 0;

              this.filterParams.stepsIds.map(step => {
                const stepValid = step;
                const sessionFromStep = regulationsSessions.filter(item => item.step === stepValid);
                objRegulation[step] = this.getSessionsSize(sessionFromStep, step, regulation.id);
                objDate.totalWork += objRegulation[step];
                objRegulation.totalWork += objRegulation[step];
              });

              if (!objContract.regulations.find(item => item.id === regulation.id)) {
                const regulationData = {
                  id: regulation.id,
                  code: regulation.code,
                  description: regulation.description,
                  prettyName: regulation.prettyName
                };
                objContract.regulations.push(regulationData);
              }

              if (objRegulation.totalWork > 0) {
                objDate.regulations.push(objRegulation);
              }
            }
          }

          if (objDate.totalWork > 0) {
            objUser.totalWork += objDate.totalWork;
            objUser.timeWorked += objDate.timeWorked;
            objUser.dates.push(objDate);
          }
        }

        if (objUser.superUser === false) {
          objContract.totalWork += objUser.totalWork;
          objContract.timeWorked += objUser.timeWorked;
          objContract.users.push(objUser);
        }
      }

      if (this.filterParams.stepsIds) {
        this.filterParams.stepsIds.map(step => {
          if (dataContract.steps.includes(step)) {
            objContract[step] = objContract.users.reduce((acc, cur) => acc + cur[step], 0);
          }
        });
      }

      objContract.users.sort((a, b) => (a.totalWork > b.totalWork) ? -1 : 1);
      this.contract = objContract;

      this.productionReportService.emitContractsProduction(this.contract);
    });
  }

  getTimeWorked(acc, curr) {
    return acc + (Math.abs(Date.parse(curr.modifiedAt) - Date.parse(curr.startsAt)));
  }

  getSessionsSize(sessions, stepList, regulation = null) {
    const allRegulations = sessions.map((session) => {
      const { contractId, startsAt, createdAt, id, modifiedAt, userId, step, ...regulations } = session;
      const getValidRegulations = (reg) => stepList === 'verifyInvalid' ? reg.split('#')[0] === 'invalid' : reg.split('#')[0] !== 'invalid';

      let listRegulations;
      if (regulation) {
        const regulationsSource = Object.keys(regulations).filter(reg => ['invalid', 'valid'].includes(reg.split('#')[0]) ? Number(reg.split('#')[1]) === regulation : Number(reg) === regulation);
        listRegulations = regulationsSource.filter(getValidRegulations);
      } else {
        listRegulations = Object.keys(regulations).filter(getValidRegulations);
      }

      let total = 0;
      listRegulations.forEach(item => total += regulations[item]);

      return total;
    });

    return allRegulations.reduce((acc, curr) => acc + curr, 0);
  }

  ngOnDestroy() {
    this.contractSub.unsubscribe();
    this.filterSub.unsubscribe();
    this.periodSub.unsubscribe();
  }
}
