import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Contract, PreProcessSettings, Reason, User, UserGroup, VehicleDataSearchTypes, VehicleRequiredFields } from 'src/app/core/models';
import { Regulation } from 'src/app/core/models/Regulation';
import { ReasonService } from 'src/app/core/services/reason.service';
import { ActivatedRoute } from '@angular/router';
import { ContractService, UserGroupService } from 'src/app/core/services';

@Component({
  selector: 'app-pre-process-config-general',
  templateUrl: './config-general.component.html',
  styleUrls: ['./config-general.component.sass']
})
export class ConfigGeneralComponent implements OnInit, OnDestroy {
  @Input() preProcessSettings: PreProcessSettings = new PreProcessSettings();
  @Output() nextStepCommand = new EventEmitter();
  @Output() isValid = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  public form: FormGroup;
  public contractId: string;
  public regulations: Array<Regulation> = [];
  public vehicleDataSearchTypes = Object.values(VehicleDataSearchTypes);
  public vehicleDataSearchTypesI18n: Array<{ id: string; value: string }> = [];
  public vehicleRequiredFields = Object.values(VehicleRequiredFields);
  public vehicleRequiredFieldsI18n: Array<{ id: string; value: string }> = [];
  public violationSteps: Array<string> = [];
  public violationStepsI18n: Array<{ id: string; value: string }> = [];
  public isAllRequiredFieldsSelected = false;
  public isShowVehicleDataSource = false;
  public isSkipTypingStep = false;
  public isShowDataEditing = false;
  public isAlertOddSpeed = false;
  public isAddOcrPlate = false;
  public allowInputNotFoundVehicle = null;
  public plateNotFoundReasonIdDisabled = false;
  public currentContract: Contract;
  public invalidateExpiredViolation = false;
  public reasons: Array<Reason> = [];
  public currentUser: User;
  public userGroups: Array<UserGroup>;
  private _subscriptions = [];

  constructor(
    private reasonService: ReasonService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private contractService: ContractService,
    private userGroupService: UserGroupService
  ) { }

  ngOnInit() {
    this.contractId = this.activatedRoute.snapshot.params.contractId;
    this.contractService.getAll({ code: this.contractId }).then(res => {
      this.currentContract = res.shift();
    });
    this.allowInputNotFoundVehicle = this.preProcessSettings.allowInputNotFoundVehicle || false;
    if (this.preProcessSettings && this.preProcessSettings.violationSteps != null) {
      Object.keys(this.preProcessSettings.violationSteps).forEach(step => {
        if (['firstStep', 'triage', 'typing', 'verifyValid'].includes(step)) {
          return;
        }
        this.violationSteps.push(step);
      });
    }
    const contractFilter = { contractId: this.contractId, order: 'code', 'enabled[bool]': 'true' };
    this.reasonService.getAll(contractFilter).then(data => {
      this.reasons = data.sort((a, b): any => Number(a.code) - Number(b.code));
    });
    this.userGroupService.list({ contractId: this.contractId }).then(res => {
      this.userGroups = res.result;
    });
    this.createForm();
    this.isValid.emit(this.form.valid);
    this.invalidateExpiredViolation = this.getValue(this.preProcessSettings, 'violationExpired.invalidateExpiredViolation');
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  createForm() {
    const requiredFields = this.getValue(this.preProcessSettings, 'requiredFields');
    const vehicleDataSearch = this.getValue(this.preProcessSettings, 'vehicleDataSearch') || 'disabled';
    const allowInputNotFoundVehicle = this.getValue(this.preProcessSettings, 'allowInputNotFoundVehicle') || false;
    this.form = this.formBuilder.group({
      vehicleDataSearch: [vehicleDataSearch, Validators.required],
      whitelistReasonId: [this.getValue(this.preProcessSettings, 'whitelistReasonId')],
      quarantineMaxPeriod: [this.getValue(this.preProcessSettings, 'quarantine.maxPeriod'),
      [Validators.min(1), Validators.max(60), Validators.pattern('^[0-9]*$')]],
      quarantineReasonId: [parseInt(this.getValue(this.preProcessSettings, 'quarantine.reasonId'), 10), Validators.required],
      quarantineValidateOddSpeed: [this.getValue(this.preProcessSettings, 'quarantine.validateOddSpeed')],
      verifyNoMetrologicalCalibrationValidity: [this.getValue(this.preProcessSettings, 'verifyNoMetrologicalCalibrationValidity')],
      typingOcrSkip: [{
        value: this.getValue(this.preProcessSettings, 'violationSteps.typing.ocrSkip'),
        disabled: !this.hasSomeStep(['typing'], 'violationSteps')
      }],
      changesPermited: [{
        value: this.getValue(this.preProcessSettings, 'violationSteps.verifyInvalid.changesPermited') ||
          this.getValue(this.preProcessSettings, 'violationSteps.verifyValid.changesPermited'),
        disabled: !this.hasSomeStep(['verifyValid', 'verifyInvalid'], 'violationSteps')
      }],
      violationAgeAlert: [this.getValue(this.preProcessSettings, 'violationAgeAlert') || 10,
      [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
      lotAgeAlert: [this.getValue(this.preProcessSettings, 'lotAgeAlert') || 10,
      [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
      changeBrandModelForClientBrand: [
        this.getValue(this.preProcessSettings, 'changeBrandModelForClientBrand') || false
      ],
      allowInputNotFoundVehicle: [{
        value: this.getValue(this.preProcessSettings, 'allowInputNotFoundVehicle') || false,
        disabled: vehicleDataSearch === 'disabled'
      }, Validators.required],
      exportOriginalFiles: [this.getValue(this.preProcessSettings, 'exportOriginalFiles') || false],
      enabledAlertOddSpeed:[this.getValue(this.preProcessSettings, 'enabledAlertOddSpeed') ||  this.isAlertOddSpeed],
      vehicleInternalData: [this.getValue(this.preProcessSettings, 'allowInputNotFoundVehicle') || false],
      failBatch: [this.getValue(this.preProcessSettings, 'audit.allowAuditInvalidateLot') || false],
      plateNotFoundReasonId: [this.getValue(this.preProcessSettings, 'plateNotFoundReasonId')],
      addOcrPlate:[this.getValue(this.preProcessSettings, 'addOcrPlate') ||  this.isAddOcrPlate],
      enabledViolationStandBy: [this.getValue(this.preProcessSettings, 'enabledViolationStandBy') || false],
      maxPeriodViolationStandBy: [this.getValue(this.preProcessSettings, 'maxPeriodViolationStandBy') || ''],
      setPercentageOddSpeed: [this.getValue(this.preProcessSettings, 'percentageOddSpeed') || 100],
      enabledReportOCR: [this.getValue(this.preProcessSettings, 'enabledReportOCR') || false],
      allowedViewViolationsOCRFailed: [this.getValue(this.preProcessSettings, 'allowedViewViolationsOCRFailed') || false],
      userGroupIds:[this.getValue(this.preProcessSettings, 'userGroupIds')],
      invalidateExpiredViolation: [this.getValue(this.preProcessSettings, 'violationExpired.invalidateExpiredViolation') || false],
      violationExpiredMaxPeriod: [this.getValue(this.preProcessSettings, 'violationExpired.maxPeriod') || ''],
      violationExpiredReasonId: [this.getValue(this.preProcessSettings, 'violationExpired.reasonId') || ''],
      enabledWhiteList:[this.getValue(this.preProcessSettings, 'enabledWhiteList')]
    });
    this.form.get('invalidateExpiredViolation').valueChanges.subscribe(value => {
      this.invalidateExpiredViolation = value;
      if (value) {
        this.form.get('violationExpiredMaxPeriod').setValidators(Validators.required);
        this.form.get('violationExpiredReasonId').setValidators(Validators.required);
        this.form.get('violationExpiredMaxPeriod').updateValueAndValidity();
        this.form.get('violationExpiredMaxPeriod').patchValue(1);
        this.isValid.emit(this.form.valid);
      } else {
        this.form.get('violationExpiredMaxPeriod').removeValidators(Validators.required);
        this.form.get('violationExpiredReasonId').removeValidators(Validators.required);
        this.form.get('violationExpiredMaxPeriod').patchValue('');
        this.form.get('violationExpiredReasonId').patchValue('');
        this.isValid.emit(this.form.valid);
      }
    });
    this.form.get('violationExpiredMaxPeriod').valueChanges.subscribe(value => {
      if (value < 1) {
        this.form.get('violationExpiredMaxPeriod').patchValue(1);
      }
    });
    this.form.get('enabledViolationStandBy').valueChanges.subscribe(value => {
      if (!value) {
        this.form.get('maxPeriodViolationStandBy').patchValue('');
      }
      if (value) {
        this.form.get('maxPeriodViolationStandBy').patchValue(2);
      }
    });
    this.form.get('maxPeriodViolationStandBy').valueChanges.subscribe(value => {
      if (value <= 0 && this.form.get('maxPeriodViolationStandBy').value) {
        this.form.get('maxPeriodViolationStandBy').patchValue(1);
      }
      if (value === 0) {
        this.form.get('maxPeriodViolationStandBy').patchValue(1);
      }
    });
    this.form.get('enabledWhiteList').valueChanges.subscribe(value => {
      const whitelistReasonId = this.form.get('whitelistReasonId');
      if (value === true) {
        whitelistReasonId.setValidators(Validators.required);
        whitelistReasonId.setErrors({ incorrect: true });
        whitelistReasonId.updateValueAndValidity();
      } else {
        whitelistReasonId.setValue('');
        whitelistReasonId.clearValidators();
        whitelistReasonId.updateValueAndValidity();
      }
    });
    this.form.get('allowedViewViolationsOCRFailed').valueChanges.subscribe(value => {
      const userGroupIds = this.form.get('userGroupIds');
      if (value === true) {
        userGroupIds.setValidators(Validators.required);
        userGroupIds.setErrors({ incorrect: true });
        userGroupIds.updateValueAndValidity();
      } else {
        userGroupIds.setValue('');
        userGroupIds.clearValidators();
        userGroupIds.updateValueAndValidity();
        userGroupIds.patchValue([]);
      }
    });
    this.form.get('enabledReportOCR').valueChanges.subscribe(value => {
      const allowedViewViolationsOCRFailed = this.form.get('allowedViewViolationsOCRFailed');
      if (value === false) {
        allowedViewViolationsOCRFailed.patchValue(false);
      }
    });


    this.form.addControl('allRequiredFields', new FormControl('', []));
    for (const field of this.vehicleRequiredFields) {
      this.form.addControl(field, new FormControl(requiredFields.includes(field), []));
      this.form.get(field).valueChanges.subscribe(() => {
        this.isAllRequiredFieldsSelected = this.vehicleRequiredFields.every(f => this.form.get(f).value);
        this.form.get('allRequiredFields').setValue(this.isAllRequiredFieldsSelected);
      });
    }
    this.form.get('vehicleDataSearch').valueChanges.subscribe(value => {
      const control = this.form.get('allowInputNotFoundVehicle');
      if (value && value !== 'disabled') {
        control.enable();
        this.plateNotFoundReasonIdDisabled = false;
      } else {
        control.disable();
        this.form.patchValue({
          allowInputNotFoundVehicle: false
        });
      }
      if (value && value === 'disabled') {
        this.plateNotFoundReasonIdDisabled = true;
      }
    });

    if (vehicleDataSearch === 'disabled') {
      this.plateNotFoundReasonIdDisabled = true;
    }

    this.form.get('allowInputNotFoundVehicle').valueChanges.subscribe(value => {
      this.allowInputNotFoundVehicle = value;
    });

    if (requiredFields.length > 0) {
      this.form.get('allRequiredFields').setValue(this.vehicleRequiredFields.length === requiredFields.length);
    }

    this.form.get('allRequiredFields').valueChanges.subscribe(value => {
      if (this.isAllRequiredFieldsSelected === value) {
        return;
      }
      for (const field of this.vehicleRequiredFields) {
        this.form.controls[field].setValue(value);
      }
    });

    this.form.get('quarantineMaxPeriod').valueChanges.subscribe(value => this.customQuarantineValidator());
    this.form.get('quarantineReasonId').valueChanges.subscribe(value => this.customQuarantineValidator());
    this.form.get('quarantineValidateOddSpeed').valueChanges.subscribe(value => this.customQuarantineValidator());

    this.form.valueChanges.subscribe(() => {
      this.isValid.emit(this.form.valid);
      if (this.form.valid === true) {
        this.outputValue.emit(this.getOutputValue());
      }
    });
    if (this.hasSomeStep(['typing', 'doubleTyping', 'validate', 'verifyValid', 'verifyInvalid'], 'violationSteps') ||
      this.hasSomeStep(['internalAudit'], 'lotSteps')) {
      this.isShowVehicleDataSource = true;
      this.isAlertOddSpeed = true;
    }
    if (this.hasSomeStep(['typing', 'doubleTyping'], 'violationSteps')) {
      this.isSkipTypingStep = true;
    }
    if (this.hasSomeStep(['verifyValid', 'verifyInvalid'], 'violationSteps')) {
      this.isShowDataEditing = true;
    }
  }

  customQuarantineValidator() {
    const controls = {} as any;
    const validControls = [];
    const invalidControls = [];

    controls.quarantineMaxPeriod = this.form.get('quarantineMaxPeriod');
    controls.quarantineReasonId = this.form.get('quarantineReasonId');
    controls.quarantineValidateOddSpeed = this.form.get('quarantineValidateOddSpeed');

    for (const key of Object.keys(controls)) {
      const value = controls[key].value;
      if (value != null && value !== '' && value !== 0) {
        validControls.push(key);
      } else {
        invalidControls.push(key);
      }
    }

    if (validControls.length > 0) {
      invalidControls.forEach(controlName => {
        controls[controlName].setErrors({ quarantineFormError: 'insert max period and reason, or neither' });
      });
      validControls.forEach(controlName => controls[controlName].updateValueAndValidity({ onlySelf: true, emitEvent: false }));
    } else {
      invalidControls.forEach(controlName => controls[controlName].updateValueAndValidity({ onlySelf: true, emitEvent: false }));
    }

    Object.values(controls).forEach((control: FormControl) => control.markAsTouched());
  }

  nextStep(nextStep) {
    if (this.form.valid === true) {
      this.nextStepCommand.emit(nextStep);
    }
  }

  getOutputValue() {
    const quarantine = {
      maxPeriod: this.form.get('quarantineMaxPeriod').value && parseInt(this.form.get('quarantineMaxPeriod').value, 10),
      reasonId: parseInt(this.form.get('quarantineReasonId').value, 10),
      validateOddSpeed: this.form.get('quarantineValidateOddSpeed').value
    };
    const ocrSkip = this.form.get('typingOcrSkip').value || false;
    const changesPermited = this.form.get('changesPermited').value || false;
    const config = {
      verifyNoMetrologicalCalibrationValidity: this.form.get('verifyNoMetrologicalCalibrationValidity').value || false,
      vehicleDataSearch: this.form.get('vehicleDataSearch').value || undefined,
      whitelistReasonId: this.form.get('whitelistReasonId').value || undefined,
      quarantine,
      violationSteps: {
        typing: {
          ocrSkip
        },
        verifyValid: {
          changesPermited
        },
        verifyInvalid: {
          changesPermited
        }
      },
      audit: {
        allowAuditInvalidateLot: this.form.get('failBatch').value,
      },
      requiredFields: this.vehicleRequiredFields.filter(requiredField => this.form.get(requiredField).value),
      violationAgeAlert: this.form.get('violationAgeAlert').value && parseInt(this.form.get('violationAgeAlert').value, 10),
      lotAgeAlert: this.form.get('lotAgeAlert').value && parseInt(this.form.get('lotAgeAlert').value, 10),
      allowInputNotFoundVehicle: this.form.get('allowInputNotFoundVehicle').value || false,
      changeBrandModelForClientBrand: this.form.get('changeBrandModelForClientBrand').value || false,
      exportOriginalFiles: this.form.get('exportOriginalFiles').value || false,
      enabledAlertOddSpeed: this.form.get('enabledAlertOddSpeed').value,
      plateNotFoundReasonId: this.form.get('plateNotFoundReasonId').value,
      addOcrPlate: this.form.get('addOcrPlate').value,
      enabledViolationStandBy: this.form.get('enabledViolationStandBy').value,
      maxPeriodViolationStandBy: this.form.get('maxPeriodViolationStandBy').value,
      percentageOddSpeed: this.form.get('setPercentageOddSpeed').value || 100,
      enabledReportOCR: this.form.get('enabledReportOCR').value,
      allowedViewViolationsOCRFailed: this.form.get('allowedViewViolationsOCRFailed').value,
      userGroupIds: this.form.get('userGroupIds').value,
      violationExpired: {
        invalidateExpiredViolation: this.form.get('invalidateExpiredViolation').value,
        maxPeriod: Number(this.form.get('violationExpiredMaxPeriod').value),
        reasonId: Number(this.form.get('violationExpiredReasonId').value)
      },
      enabledWhiteList: this.form.get('enabledWhiteList').value
    };
    if (!this.hasSomeStep(['typing'], 'violationSteps')) {
      delete config.violationSteps.typing;
    }
    if (!this.hasSomeStep(['verifyValid'], 'violationSteps')) {
      delete config.violationSteps.verifyValid;
    }
    if (!this.hasSomeStep(['verifyInvalid'], 'violationSteps')) {
      delete config.violationSteps.verifyInvalid;
    }
    if (changesPermited == null) {
      delete config.violationSteps.verifyValid;
      delete config.violationSteps.verifyInvalid;
    }
    if (!quarantine.maxPeriod || !quarantine.reasonId) {
      delete config.quarantine;
    }
    if (!config.enabledWhiteList) {
      config.whitelistReasonId = undefined;
    }
    if (!config.enabledReportOCR) {
      config.allowedViewViolationsOCRFailed = false;
      config.userGroupIds = [];
    }
    return config;
  }

  getValue(model, attr, value = '') {
    let arr = [];
    if (typeof attr === 'string') {
      arr = attr.split('.');
    } else {
      arr = attr;
    }
    if (arr.length > 0) {
      if (model[arr[0]] == null) {
        return value;
      } else {
        return this.getValue(model[arr[0]], arr.slice(1), value);
      }
    } else {
      return model;
    }
  }

  hasSomeStep(steps = [], target) {
    if (steps.length === 0 || this.preProcessSettings == null || this.preProcessSettings[target] == null) {
      return false;
    } else {
      return steps.some(s => this.preProcessSettings[target][s] != null);
    }
  }
}
