import { Injectable } from '@angular/core';
import { ReleaseNotes } from '../models/ReleaseNotes';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService extends BaseApiService<ReleaseNotes> {

  get cacheKey(): StorageKey {
    return StorageKey.releaseNotesServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/releaseNotes`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  protected parse(obj) {
    return ReleaseNotes.parse(obj);
  }

  protected unparse(obj) {
    return ReleaseNotes.unparse(obj);
  }

}
