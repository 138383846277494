import { StorageService, StorageKey } from '../services';
import { User, UserGroup } from '../models';

export const updateLocalStorage = async (user: User, storageService: StorageService, userGroups: Array<UserGroup>) => {
  storageService.set(StorageKey.currentUser, user);
  const currentUser = storageService.get(StorageKey.currentUser);
  const currentPermissions = [];
  const currentContracts = [];
  currentUser.groupIds = [];
  const userGroupUser = userGroups.filter(userGrp => userGrp.userIds.indexOf(currentUser.id) >= 0);
  storageService.remove(StorageKey.currentContracts);
  storageService.remove(StorageKey.currentPermissions);
  storageService.remove(StorageKey.currentUser);
  userGroupUser.forEach(userGrp => {
    if (currentUser.groupIds.indexOf(userGrp.id) < 0) {
      currentUser.groupIds.push(userGrp.id);
    }
    if (currentPermissions.find(p => p.contractId === userGrp.contractId)) {
      userGrp.permissions.forEach(permission => {
        currentPermissions.find(pCurrent => {
          if (pCurrent.contractId === userGrp.contractId && pCurrent.actionIds.indexOf(permission) < 0) {
            pCurrent.actionIds.push(permission);
          }
        });
      });
    } else {
      currentPermissions.push({
        contractId: userGrp.contractId,
        actionIds: userGrp.permissions
      });
    }
    if (currentContracts.indexOf(userGrp.contractId) < 0) {
      currentContracts.push(userGrp.contractId);
    }
  });
  storageService.set(StorageKey.currentContracts, currentContracts);
  storageService.set(StorageKey.currentUser, currentUser);
  storageService.set(StorageKey.currentPermissions, currentPermissions);
};
