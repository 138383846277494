<div class="filter mb-4">
  <div class="filter__header" (click)="toggleCollapse()">
    <h5 i18n>Filtrar relatório</h5>

    <button class="btn btn-sm btn-icon-block"
      [ngClass]="{ 'btn-outline-secondary': !collapse, 'btn-secondary': collapse }">
      <i class="material-icons" *ngIf="collapse">unfold_more</i>
      <i class="material-icons" *ngIf="!collapse">unfold_less</i>
    </button>
  </div>

  <div [class.collapsed]="collapse">
    <form class="form-horizontal" [formGroup]="filterForm" *ngIf="loading; else loadingBlock">
      <div class="filter__area">
        <div class="filter__block">
          <label i18n>Grupos de usuários:</label>
          <app-multiselect type="inline" [list]="userGroups" formControlName="usersGroupsIds" selectValue="id" selectText="name"
            itemsOnList="0" buttonLabel="Grupos de usuários" charactersOnItem="10">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Usuários:</label>
          <app-multiselect type="inline" [list]="users" formControlName="usersIds" selectValue="id" selectText="name"
            itemsOnList="0" buttonLabel="Usuários" charactersOnItem="10" [inputDisabled]="setUserGroup">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Enquadramentos:</label>
          <app-multiselect type="inline" [list]="regulations" formControlName="regulationsIds" selectValue="id"
            selectText="prettyName" itemsOnList="0" buttonLabel="Enquadramentos" charactersOnItem="10">
          </app-multiselect>
        </div>

        <div class="filter__block" *ngIf="!hideSteps">
          <label i18n>Etapas:</label>
          <app-multiselect type="inline" [list]="steps" formControlName="stepsIds" selectValue="id" selectText="value"
            itemsOnList="0" buttonLabel="Etapas">
          </app-multiselect>
        </div>
      </div>

      <div class="filter__button">
        <button type="submit" class="btn btn-outline-secondary mr-3" i18n (click)="redefineFilter()">Redefinir
          filtro</button>
        <button type="submit" class="btn"  [ngClass]="{ 'btn-primary': true, disabled: !isValid }"
        i18n (click)="submitFilter()">Aplicar filtro</button>
      </div>
    </form>

    <ng-template #loadingBlock>
      <div class="loading-page">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
