import { Modal } from './Modal';

export class MessageModal extends Modal {
    constructor(
        public title: string,
        public message: string,
        public confirmation: boolean = false,
    ) {
        super();
    }
}
