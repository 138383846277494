import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CftvFiles } from '../models';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UploadPreViolationFileService extends BaseApiService<CftvFiles> {
  get cacheKey(): StorageKey {
    return StorageKey.uploadPreViolationFileServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/preViolations/upload`;
    return url;
  }

  uploadFile(model: CftvFiles) {
    const req = this.formDataParser(model);
    const url = `${environment.apiUrl}/preViolations/upload`;
    return this.http.post<CftvFiles>(url, req).pipe().toPromise();
  }

  protected parse(obj) {
    return CftvFiles.parse(obj);
  }

  protected unparse(obj) {
    return CftvFiles.unparse(obj);
  }
}
