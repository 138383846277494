/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { TreeviewItem } from 'ngx-treeview';
import { ModalContent } from 'src/app/core/interface';
import { AlertItem, AlertType, ComponentModal, Lane, SelectModal } from 'src/app/core/models';
import { AlertService, ClassificationService, ContractService, LaneService, ModalService } from 'src/app/core/services';
import { RegulationService } from 'src/app/core/services/regulation.service';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { LaneSelectWeekdaysComponent } from 'src/app/modals/lane-select-weekdays/lane-select-weekdays.component';
import { LaneSelectComponent } from 'src/app/modals/lane-select/lane-select.component';
import * as moment from 'moment';

@Component({
  selector: 'app-violation-config-modal',
  templateUrl: './violation-config-modal.component.html',
  styleUrls: ['./violation-config-modal.component.sass']
})
export class ViolationConfigModalComponent extends BaseModal implements OnInit, ModalContent, OnDestroy {
  @Input() initialState;
  public lane: Lane;
  public fileTypes = ['pan', 'pan2', 'zoom', 'zoom2', 'video', 'video2'];
  public weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  public regulations = [];
  public plates = [{ plate: '0' }, { plate: '1' }, { plate: '2' }, { plate: '3' },
  { plate: '4' }, { plate: '5' }, { plate: '6' }, { plate: '7' }, { plate: '8' }, { plate: '9' }];
  public weekForms: Array<{ [params: string]: Array<FormGroup> }> = [];
  public violationForms: Array<{ name: string; form: FormGroup }> = [];
  public selected = 0;
  public hourRegex = new RegExp(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/);
  public _subs = [];
  public isFileTypePristine = false;
  public promise: Promise<any>;
  public classifications: any[] = [];

  public get isValid() {
    for (const item of this.violationForms) {
      if (!item.form.valid || !this.validateFileTypes()) {
        return false;
      }
    }
    return true;
  }

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private regulationService: RegulationService,
    private classificationService: ClassificationService,
    private laneService: LaneService,
    modalService: ModalService,
    private contractService: ContractService
  ) {
    super(modalService);
  }

  async ngOnInit() {
    const lane = this.initialState as Lane;
    if (lane == null) {
      this.modalService.close();
    } else if (lane.id && lane.contractId) {
      this.lane = await this.laneService.getById(lane.id);
      this.contractService.getById(this.lane.contractId).then(contract => {
        this.regulationService.getAll({ regionId: contract.regionId }).then(regulations => {
          this.regulations = regulations;
        });
        this.classificationService.getAll({ regionId: contract.regionId }).then(result => {
          this.classifications = result;
        });
      });
      this.editForm(this.lane);
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  get lastState() {
    return this.getNewLane(this.lane);
  }

  editForm(lane: Lane) {
    let forms;
    this.violationForms = [];
    this.weekForms = [];
    if (lane != null && lane.extraData != null && lane.extraData._laneConfig != null) {
      for (const i in lane.extraData._laneConfig) {
        if (lane.extraData._laneConfig.hasOwnProperty(i)) {
          const regulations = lane.extraData._laneConfig[i];
          forms = this.createForm(lane, regulations, i);
          this.violationForms.push({
            name: i,
            form: this.formBuilder.group(forms.form)
          });
          this.weekForms.push(forms.weekForm);
        }
      }
    }
    if (this.violationForms.length === 0) {
      forms = this.createForm(lane);
      this.violationForms.push({
        name: '',
        form: this.formBuilder.group(forms.form)
      });
      this.weekForms.push(forms.weekForm);
    }
    this.violationForms.forEach(form => {
      this._subs.push(form.form.get('name').valueChanges.subscribe(value => {
        form.name = value;
      }));
    });
  }

  editDate(dateTime) {
    if (dateTime && dateTime.indexOf('T') !== -1) {
      const date = dateTime.split('T')[0];
      return date;
    }
  }

  private createForm(lane: Lane, regulations = null, name = '') {
    const form: any = {
      regulationIds: [regulations == null ? [] : regulations,
      [Validators.required, Validators.minLength(1), this.duplicateRegulationValidator()]],
      name: [name, [Validators.required, this.duplicateNameValidator()]],
      startsAt: [''],
      endsAt: [''],
      classificationCodes: [[]]
    };
    const regulation = regulations && regulations[0];
    let config = null;
    if (regulation != null) {
      for (const violation of lane.enabledViolations) {
        if (regulation === violation.regulationId) {
          config = violation;
          if (violation.startsAt && violation.startsAt !== '') {
            form.startsAt = [this.editDate(violation.startsAt)];
          }
          if (violation.endsAt && violation.endsAt !== '') {
            form.endsAt = [this.editDate(violation.endsAt)];
          }
          if (violation.classificationCodes && violation.classificationCodes.length > 0) {
            form.classificationCodes = [violation.classificationCodes];
          }

          break;
        }
      }
    }
    this.fileTypes.forEach(f => {
      form[f + 'Checkbox'] = [config == null ? false : config.requiredFiles.includes(f),
      [Validators.required]];
    });
    const weekForm = {};
    this.weekdays.forEach((d, i) => {
      const periods = config && config.periods.filter(p => p.weekdays.includes(i));
      if (periods != null && periods.length > 0) {
        form[d + 'Enabled'] = [true, [Validators.required]];
        form[d + 'Fullday'] = [periods.length === 1 &&
          periods[0].startTime === '00:00' &&
          periods[0].endTime === '23:59', [Validators.required]];
        form[d + 'Plate'] = [periods[0].plateRegex == null ?
          [] :
          periods[0].plateRegex.slice(1, -2).split('')]; // converts regex of the last digits to array
        weekForm[d] = periods.map(p => this.formBuilder.group({
          start: [p.startTime, [Validators.required, Validators.pattern(this.hourRegex)]],
          end: [p.endTime, [Validators.required, Validators.pattern(this.hourRegex)]]
        }));
      } else {
        form[d + 'Enabled'] = [false, [Validators.required]];
        form[d + 'Fullday'] = [true, [Validators.required]];
        form[d + 'Plate'] = [[]];
        weekForm[d] = [this.formBuilder.group({
          start: ['00:00', [Validators.required, Validators.pattern(this.hourRegex)]],
          end: ['23:59', [Validators.required, Validators.pattern(this.hourRegex)]]
        })];
      }
    });
    return {
      form,
      weekForm
    };
  }

  validateFileTypes() {
    if (this.violationForms == null || this.violationForms.length === 0) {
      return null;
    }
    const form = this.violationForms[this.selected].form;
    let returnFileType = false;
    this.fileTypes.forEach(f => {
      const control = form.get(f + 'Checkbox');
      if (control.value) {
        returnFileType = true;
      }
      if (!control.pristine) {
        this.isFileTypePristine = true;
      }
    });
    return returnFileType;
  }

  async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    this.promise = this.laneService.update(this.getNewLane(this.lane)).then(res => {
      this.alertService.show(new AlertItem('LaneConfiged', AlertType.success));
      return res;
    }).catch(error => {
      this.alertService.show(new AlertItem('LaneConfigError', AlertType.danger));
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }

  duplicateNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      for (const item of this.violationForms) {
        const itemControl = item.form.get('name');
        if (control.value === item.name && control !== itemControl) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          return { DuplicateName: { value: control.value } };
        }
      }
      return null;
    };
  }

  duplicateRegulationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      for (const item of this.violationForms) {
        const itemControl = item.form.get('regulationIds');
        if (control !== itemControl) {
          for (const regulation of control.value) {
            if (itemControl.value.includes(regulation)) {
              return { duplicateRegulation: regulation.split('#')[1] };
            }
          }
        }
      }
      return null;
    };
  }

  getNewLane(lane: Lane) {
    const newLane = Lane.create(lane);
    const config = {};
    const enabled = [];
    this.violationForms.forEach((item, i) => {
      const weekForm = this.weekForms[i];
      const regulations = item.form.get('regulationIds').value.map(r => this.regulations.find(a => a.id === r).id);
      const name = item.form.get('name').value;
      const classificationCodes = item.form.get('classificationCodes').value;
      config[name] = regulations;
      for (const regulation of regulations) {
        try {
          const laneConfigEnabled: any = {
            regulationId: regulation,
            classificationCodes,
            requiredFiles: this.fileTypes.filter(f => item.form.get(f + 'Checkbox').value), // filter the selected files
            periods: this.weekdays
              .filter(d => item.form.get(d + 'Enabled').value) // filter enabled day then iterate over periods selected times
              .reduce((acc, day) => {
                const dayInt = this.weekdays.indexOf(day);
                const selectedPlates = item.form.get(day + 'Plate').value;
                let plateRegex;
                if (selectedPlates.length > 0 && selectedPlates.length < 10) {
                  plateRegex = '[' + selectedPlates.join('') + ']$';
                }
                if (item.form.get(day + 'Fullday').value) {
                  acc.push({
                    startTime: '00:00',
                    endTime: '23:59',
                    weekdays: [dayInt],
                    plateRegex
                  });
                } else {
                  for (const period of weekForm[day]) {
                    const startSplit = period.get('start').value.split(':');
                    const endTime = period.get('end').value.split(':');
                    acc.push({
                      startTime: startSplit[0] + ':' + startSplit[1],
                      endTime: endTime[0] + ':' + endTime[1],
                      weekdays: [dayInt],
                      plateRegex
                    });
                  }
                }
                return acc;
              }, [])
          };
          const startsAt = item.form.get('startsAt').value;
          const endsAt = item.form.get('endsAt').value;
          if (startsAt && startsAt !== '') {
            laneConfigEnabled.startsAt = moment(new Date(item.form.get('startsAt').value).toISOString()).utc();
          }
          if (endsAt && endsAt !== '') {
            laneConfigEnabled.endsAt = moment(new Date(item.form.get('endsAt').value).toISOString()).utc();
          }
          enabled.push(laneConfigEnabled);
        } catch (err) {
          console.error(err);
          throw err;
        }
      }
    });
    if (newLane.extraData == null) {
      newLane.extraData = {};
    }
    newLane.extraData._laneConfig = config;
    newLane.enabledViolations = enabled;
    this.lane = newLane;
    return newLane;
  }

  onSubmit() {
    if (this.isValid) {
      return this.modalService.submit();
    }
  }

  onClose() {
    this.modalService.close();
  }

  addTime(day) {
    this.weekForms[this.selected][day].push(this.formBuilder.group({
      start: ['00:00', [Validators.required, Validators.pattern(this.hourRegex)]],
      end: ['23:59', [Validators.required, Validators.pattern(this.hourRegex)]]
    }));
  }

  removeTime(day, i) {
    this.weekForms[this.selected][day].splice(i, 1);
  }

  deleteTab() {
    const index = this.selected;
    this.selected = this.selected !== 0 ? this.selected - 1 : this.selected;
    this.weekForms.splice(index, 1);
    this.violationForms.splice(index, 1);
  }

  addTab() {
    if (!this.isValid) {
      return;
    }
    const forms = this.createForm(this.lane, null, '');
    forms.form = this.formBuilder.group(forms.form);
    this.violationForms.push({
      name: '',
      form: forms.form
    });
    this.weekForms.push(forms.weekForm);
    this.selected = this.weekForms.length - 1;
    this._subs.push(forms.form.get('name').valueChanges.subscribe(value => {
      this.violationForms.forEach(violationForm => {
        if (violationForm.form === forms.form) {
          violationForm.name = value;
        }
      });
    }));
  }

  openCopyTime(originDay) {
    this.modalService
      .show(new ComponentModal(LaneSelectWeekdaysComponent,
        this.weekdays))
      .catch(() => null)
      .then(async d => {
        const days = d && d.component && d.component.instance && d.component.instance.values;
        if (days != null && days.length > 0) {
          for (const day of days) {
            if (day === originDay) {
              continue;
            }
            const value = this.violationForms[this.selected].form.get(originDay + 'Fullday').value;
            this.violationForms[this.selected].form.get(day + 'Fullday').setValue(value);
            this.violationForms[this.selected].form.get(day + 'Enabled').setValue(true);
            this.weekForms[this.selected][day] = [];
            for (const form of this.weekForms[this.selected][originDay]) {
              this.weekForms[this.selected][day].push(this.formBuilder.group({
                start: [form.get('start').value, [Validators.required]],
                end: [form.get('end').value, [Validators.required]]
              }));
            }
          }
        }
      });
  }

  openCopyWeek() {
    this.modalService
      .show(new SelectModal('Replicar as configurações de horário em:',
        this.violationForms
          .filter((v, i) => i !== this.selected)
          .map(d => new TreeviewItem({ value: d.name, text: d.name, checked: false })), false, true))
      .catch(() => null).then(async d => {
        const tabs = d && d.values;
        if (tabs  ) {
          for (const name of tabs) {
            let destination;
            for (const i in this.violationForms) {
              if (this.violationForms[i].name === name) {
                destination = i;
                break;
              }
            }
            for (const day of this.weekdays) {
              this.violationForms[destination].form.get(day + 'Fullday').setValue(
                this.violationForms[this.selected].form.get(day + 'Fullday').value);
              this.violationForms[destination].form.get(day + 'Enabled').setValue(
                this.violationForms[this.selected].form.get(day + 'Enabled').value);
              this.weekForms[destination][day] = [];
              for (const form of this.weekForms[this.selected][day]) {
                this.weekForms[destination][day].push(this.formBuilder.group({
                  start: [form.get('start').value, [Validators.required]],
                  end: [form.get('end').value, [Validators.required]]
                }));
              }
            }
          }
        }
      });
  }

  openCopyFrom() {
    this.modalService.show(new ComponentModal(LaneSelectComponent, {
      isSingle: true, laneId: this.lane.id, contractId: this.lane.contractId
    }))
      .catch(() => null).then(async d => {
        const laneId = d && d.component && d.component.instance && d.component.instance.values[0];
        if (laneId != null) {
          const lane = await this.laneService.getById(laneId);
          this.editForm(lane);
        }
      });
  }

  openCopyTo() {
    this.modalService.show(new ComponentModal(LaneSelectComponent, {
      isSingle: false, laneId: this.lane.id, contractId: this.lane.contractId
    }))
      .catch(() => null).then(d => {
        const laneIds = d && d.component && d.component.instance && d.component.instance.values;
        if (laneIds != null) {
          return Promise.all(laneIds.map(laneId => this.laneService.getById(laneId).then(lane =>
            this.laneService.update(this.getNewLane(lane)).then(res => {
              this.alertService.show(new AlertItem(`LaneSaved`, AlertType.success));
              return res;
            }).catch(error => {
              this.alertService.show(new AlertItem(`LaneSaveError`, AlertType.danger));
              throw error;
            }))));
        }
      });
  }
}
