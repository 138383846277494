import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VehicleWhiteList, Pagination } from '../models';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleWhiteListService extends BaseApiService<VehicleWhiteList> {
  get cacheKey(): StorageKey {
    return StorageKey.vehicleWhiteListServiceCache;
  }
  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/vehicleWhiteLists`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  list(params: {[params: string]: string}): Promise<Pagination<VehicleWhiteList>> {
    return super.list(this.removeContractId(params));
  }

  protected unparse(obj) {
    return VehicleWhiteList.unparse(obj);
  }

  protected parse(obj) {
    return VehicleWhiteList.parse(obj);
  }
}
