<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 i18n class="modal-title">Detalhes da Aferição</h5>
      <button type="button" (click)="onCancel()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mb-4">
        <div class="col-3">
          <label class="text-secondary" i18n>Data</label>
          <p>{{ calibration.date | moment: 'L' }}</p>
        </div>
        <div class="col-3">
          <label class="text-secondary" i18n>Tipo</label>
          <p>{ calibration.type, select, metrologic {Metrológica} non-metrologic {Não Metrológica}}</p>
        </div>
        <div class="col-6" *ngIf="calibration.type==='metrologic'">
          <label class="text-secondary" i18n>Velocidade Aferida
            <i i18n-appTooltip class="material-icons icon-sm text-secondary"
            data-placement="top" appTooltip="É a velocidade medida no momento da aferição.">info</i></label>
          <p>{{ calibration.speed }} <span i18n *ngIf="calibration.speed">km/h</span></p>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-3">
          <label class="text-secondary" i18n>Nº Do Lacre</label>
          <p>{{ calibration.sealNumber || '-' }}</p>
        </div>
        <div class="col-3 ">
          <label class="text-secondary" i18n>Nº Do Laudo</label>
          <p>{{ calibration.appraisal?.number || '-' }}</p>
        </div>
        <div class="col-4">
          <label class="text-secondary" i18n>Nº Do Processo
            <i i18n-appTooltip class="material-icons icon-sm text-secondary"
            data-placement="top" appTooltip="Número do processo junto ao órgão regulamentador.">info</i>
          </label>
          <p>{{ calibration.processNumber || '-' }}</p>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-3">
          <label class="text-secondary" i18n>Órgão Aferidor</label>
          <p>{{ calibration.agency || '-'}}</p>
        </div>
        <div class="col-3">
          <label class="text-secondary" i18n>Nº Série do Equipamento</label>
          <p>{{ equipment?.serialNumber || '-'}}</p>
        </div>
        <div class="col-3">
          <label class="text-secondary" i18n>Modelo</label>
          <p>{{ equipmentModel?.manufacturerId }} - {{ equipmentModel?.name}}</p>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12 col-md-6">
          <fieldset>
            <legend i18n>Vigência</legend>
            <div class="row">
              <div class="col-6">
                <label class="text-secondary">Início</label>
                <p>{{ calibration.startsAt | moment: 'L'}} {{ calibration.startsAt | moment: 'LTS'}}</p>
              </div>
              <div class="col-6">
                <label class="text-secondary">Fim</label>
                <p>{{ calibration.endsAt | moment: 'L'}} {{ calibration.endsAt | moment: 'LTS'}}</p>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-12 col-md-6">
          <fieldset>
            <legend i18n>Técnico</legend>
            <div class="row">
              <div class="col-6">
                <label class="text-secondary">Nome</label>
                <p> {{ calibration.technician?.name || '-' }}</p>
              </div>
              <div class="col-6">
                <label class="text-secondary">Matrícula</label>
                <p> {{ calibration.technician?.registration || '-' }}</p>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12" *ngIf="extraDataKeys.length > 0">
          <div class="col-3" *ngFor="let key of extraDataKeys">
            <label class="text-secondary">{{ key }}</label>
            <p>{{ extraDataKeys[key]}}</p>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-3">
          <div class="row">
            <label class="col-12 text-secondary">Laudo</label>
            <div  (click)="openFile(calibration?.appraisal?.document)"
              *ngIf="calibration?.appraisal?.document; else noDocument" class="col-6 pointer" data-toggle="tooltip"
              title="{{ getCalibrationFileName(calibration?.appraisal?.document) }}">
              <app-thumbnail [source]="calibration?.appraisal?.document" *ngIf="calibration?.appraisal?.document.indexOf('.pdf') !== 72"></app-thumbnail>
              <img src="/assets/images/attachments.png" width="50px" height="50px" *ngIf="calibration?.appraisal?.document.indexOf('.pdf') === 72" />
            </div>
            <ng-template #noDocument>
              <div class="col-12">-</div>
            </ng-template>
          </div>
        </div>
        <div class="col-9 d-flex">
          <div class="row">
            <label class="col-12 text-secondary">Arquivos Complementares</label>
            <div *ngFor="let file of calibration.extraFiles" class="col-2 pointer"  data-toggle="tooltip"
            title="{{ getCalibrationFileName(file) }}">
              <app-thumbnail [source]="file"></app-thumbnail>
            </div>
            <div class="col-12" *ngIf="calibration?.extraFiles?.length === 0">
              -
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
