import { Injectable } from '@angular/core';
import { SerialNumberConfig } from '../models';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SerialNumberConfigService extends BaseApiService<SerialNumberConfig> {
  get cacheKey(): StorageKey {
    return StorageKey.serialNumberConfigServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/serialNumberConfigs`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  protected unparse(obj) {
    return SerialNumberConfig.unparse(obj);
  }

  protected parse(obj) {
    return SerialNumberConfig.parse(obj);
  }
}
