import { BaseApiModel } from './BaseApiModel';

export class Calibration extends BaseApiModel {
  public type: string;
  public speed: number;
  public equipmentId: string;
  public contractId: string;
  public appraisal: {
    // eslint-disable-next-line id-blacklist
    number: string;
    document: string;
  };
  public extraFiles: Array<string>;
  public agency: string;
  public sealNumber: string;
  public version: number;
  public extraData: any;
  public createdAt: Date;
  public modifiedAt: Date;
  public date: string;
  public endsAt: string;
  public startsAt: string;
  public technician: {
    name: string;
    registration: string;
  };
  public processNumber: string;
  public enabled: boolean;
}

// eslint-disable-next-line no-shadow
export enum TypeCalibration {
  metrologic = 'metrologic',
  nonMetrologic = 'non-metrologic',
}
