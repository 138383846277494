import { BaseApiModel } from './BaseApiModel';

export class Vehicle extends BaseApiModel {
  public contractId: string;
  public plate: string;
  public brandModelId: string;
  public brandModelCode: string;
  public brandModel: string;
  public colorId: string;
  public colorCode: string;
  public classificationId: string;
  public classificationCode: string;
  public categoryId: string;
  public categoryCode: string;
  public typeId: string;
  public typeCode: string;
  public cityId: string;
  public cityCode: string;
  public year: number;
  public modelYear: number;
  public chassis: string;
  public renavam: string;
  public createdAt: Date;
  public modifiedAt: Date;
  public deletedAt: Date;
  public city: string;
  public uf: string;
}
