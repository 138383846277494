import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TriageComponent } from './triage/triage.component';
import { PreProcessRoutingModule } from './pre-process-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReasonModalComponent } from './reason-modal/reason-modal.component';
import { PreProcessDashboardComponent } from './dashboard/dashboard.component';
import { AutoLotConfigComponent } from './auto-lot-config/auto-lot-config.component';
import { ConfigGeneralComponent } from './config-general/config-general.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ConfigWizardComponent } from './config-wizard/config-wizard.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AvailableContractsComponent } from '../pre-process/available-contracts/available-contracts.component';
import { ConfigEditImageComponent } from './config-edit-image/config-edit-image.component';
import { ConfigLotManualComponent } from './config-lot-manual/config-lot-manual.component';
import { CommentPanelComponent } from './comment-panel/comment-panel.component';
import { HistoryPanelComponent } from './history-panel/history-panel.component';
import { SearchPlatePanelComponent } from './serach-plate-panel/serach-plate-panel.component';
import { ConfigViolationLimitComponent } from './config-violation-limit/config-violation-limit.component';
import { ImgEditModalComponent } from './img-edit-modal/img-edit-modal.component';
import { LotStepsComponent } from './lot-steps/lot-steps.component';
import { ConfigViolationStepsComponent } from './config-violation-steps/config-violation-steps.component';
import { CoreModule } from 'src/app/core/core.module';
import { LotManualComponent } from './lot-manual/lot-manual.component';
import { ProcessViolationComponent } from './process-violation/process-violation.component';
import { ViolationStepViewModalComponent } from './reports/violation-step/violation-step-view-modal/violation-step-view-modal.component';
import { LotListComponent } from './lots/lot-list/lot-list.component';
import { ViolationStepListComponent } from './reports/violation-step/violation-step-list/violation-step-list.component';
import { QuarantineReasonDetailComponent } from './reports/quarantine/quarantine-reason-detail/quarantine-reason-detail.component';
import {
  QuarantineDetailComponent
} from './reports/quarantine/quarantine-detail/quarantine-detail.component';
import { QuarantineListComponent } from './reports/quarantine/quarantine-list/quarantine-list.component';
import { LotStepListComponent } from './reports/lot-step/lot-step-list/lot-step-list.component';
import { LotStepViewComponent } from './reports/lot-step/lot-step-view/lot-step-view.component';
import { SerialNumberConfigCreateModalComponent } from './serial-number-config/create-modal/create-modal.component';
import { SerialNumberConfigListComponent } from './serial-number-config/list/list.component';
import { ProductionReportComponent } from './reports/production/production.component';
import { ProductionReportListComponent } from './reports/production/list/list.component';
import { ProductionReportContractComponent } from './reports/production/contract/contract.component';
import { ProductionReportContractViewComponent } from './reports/production/contract/view/contract-view.component';
import { ProductionReportStepsComponent } from './reports/production/contract/steps/steps.component';
import { ProductionReportFilterComponent } from './reports/production/contract/filter/filter.component';
import { ProductionReportPeriodComponent } from './reports/production/contract/period/period.component';
import { FilterProcessViolationComponent } from './filter-process-violation/filter-process-violation.component';
import { ViewModalComponent } from './serial-number-config/view-modal/view-modal.component';
import { ProcessAuditComponent } from './process-audit/process-audit.component';
import { AuditLotsComponent } from './audit-lots/audit-lots.component';
import { ConfigAuditComponent } from './config-audit/config-audit.component';

@NgModule({
  declarations: [
    PreProcessDashboardComponent,
    TriageComponent,
    ReasonModalComponent,
    AutoLotConfigComponent,
    ConfigWizardComponent,
    ConfigGeneralComponent,
    AvailableContractsComponent,
    ConfigEditImageComponent,
    ConfigLotManualComponent,
    CommentPanelComponent,
    HistoryPanelComponent,
    SearchPlatePanelComponent,
    ConfigViolationLimitComponent,
    ImgEditModalComponent,
    LotStepsComponent,
    ConfigViolationStepsComponent,
    ProcessViolationComponent,
    LotManualComponent,
    ViolationStepViewModalComponent,
    LotListComponent,
    ViolationStepListComponent,
    QuarantineReasonDetailComponent,
    QuarantineDetailComponent,
    QuarantineListComponent,
    LotStepListComponent,
    LotStepViewComponent,
    SerialNumberConfigListComponent,
    SerialNumberConfigCreateModalComponent,
    ProductionReportComponent,
    ProductionReportListComponent,
    ProductionReportContractComponent,
    ProductionReportContractViewComponent,
    ProductionReportPeriodComponent,
    ProductionReportFilterComponent,
    ProductionReportStepsComponent,
    FilterProcessViolationComponent,
    ViewModalComponent,
    ProcessAuditComponent,
    AuditLotsComponent,
    ConfigAuditComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    PreProcessRoutingModule
  ],
  entryComponents: [
    ReasonModalComponent,
    ConfigGeneralComponent,
    AutoLotConfigComponent,
    ConfigAuditComponent,
    ImgEditModalComponent,
    LotStepsComponent,
    CommentPanelComponent,
    HistoryPanelComponent,
    SearchPlatePanelComponent,
    ViolationStepViewModalComponent,
    LotStepViewComponent,
    SerialNumberConfigCreateModalComponent
  ]
})
export class PreProcessModule { }
