/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, Injectable } from '@angular/core';
import { ModalContent } from '../interface';
import { ModalService } from '../services';

@Injectable()
export abstract class BaseModal implements ModalContent, AfterViewInit {
  public isRendering = true;
  public isSubmiting = false;
  protected initialState;
  abstract get isValid(): boolean;
  abstract get lastState();
  abstract onAllSubmited(): Promise<any>;
  constructor(protected modalService: ModalService) {}
  onClose() {
    this.modalService.close();
  }
  onCancel() {
    this.modalService.cancel();
  }
  onSubmit() {
    this.modalService.submit();
  }
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isRendering = false;
    }, 1);
  }
}
