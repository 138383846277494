import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertItem, AlertType } from 'src/app/core/models/AlertItem';
import { AlertService, PasswordService } from 'src/app/core/services';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPassword: FormGroup;
  loading = false;
  forgotPasswordInvalid = false;
  forgotPasswordValid = false;
  token = '';
  userId = '';

  constructor(
    private formBuilder: FormBuilder,
    private passwordService: PasswordService,
    private alertService: AlertService
    ) { }

    ngOnInit() {
      this.createForm();
    }

    createForm(): void {
      this.forgotPassword = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
      });
    }

    passwordForgot() {
      this.loading = true;
      const email = this.forgotPassword.get('email').value;
      const passwordUrl = window.location.origin + '/setPassword';
      const verificationUrl = window.location.origin + '/verification';

      this.passwordService.passwordForgot(email, passwordUrl, verificationUrl)
      .subscribe(
        res => {
          this.alertService.show(new AlertItem('RecoveryLinkSent', AlertType.success));
          this.loading = false;
        },
        error => {
          this.alertService.show(new AlertItem('EmailNotFound', AlertType.danger));
          this.loading = false;
        }
      );
    }

}
