<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h3 class="modal-title container" i18n>Invalidar registros</h3>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <app-input i18n-label type="select" [(ngModel)]="reasonId" inputRequired=true label="motivo"
          [list]="reasons" selectValue="id" selectText="prettyName" i18n-label
          i18n-placeholder placeholder="Selecione o motivo de invalidação">
        </app-input>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="onCancel()" class="btn btn-outline-secondary" i18n>Cancelar</button>
      <button (click)="onSubmit()" [ngClass]="{btn: true, 'btn-danger': true, disabled: !isValid}" i18n>Invalidar</button>
    </div>
  </div>
</div>
