<div class="invisible" #items>
  <div *ngFor="let item of input" [title]="item">
    {
      item, select,
      local {Local}
      collector {Coletora}
      arterial {Arterial}
      expressway {Expressa}
      highway {Rodovia}
      enabled {Habilitado}
      test {Teste}
      disabled {Desabilitado}
      internal {Interno}
      colorCode {Cor}
      typeCode {Tipo}
      cityCode {Município}
      plate {Placa}
      classificationCode {Classificação}
      year {Ano Veículo}
      renavam {Renavam}
      brandModelCode {Marca/Modelo}
      categoryCode {Categoria}
      modelYear {Ano/Modelo}
      chassis {Chassis}
      firstStep {Etapa Inicial}
      typing {Digitação}
      doubleTyping {Segunda digitação}
      verifyValid {Verificação de válidas}
      verifyInvalid {Verificação de inválidas}
      lotAttribution {Atribuir ao lote}
      serialNumberGeneration {Geração de Série}
      validate {Validação}
      verify {Verificação}
      audit {Auditoria}
      waitEvaluation { Aguardando Avaliação}
      internalAudit {Auditoria Interna}
      revision {Revisão}
      externalAudit {Auditoria Externa}
      waitReturn {Aguardando Retorno}
      accepted {Aceito}
      refused {Recusado}
      done {Concluído}
      start {Início}
      export {Exportação}
      invalid {Invalidadas}
      valid {Validadas}
      spot {Ponto}
      violations {Infração}
      traffic {Passagem}
      surveillance {Vigilância}
      status {Status}
      backup {Backup}
      preProcess {Pré-Processamento}
      pan {Imagem Panorâmica 1}
      pan2 {Imagem Panorâmica 2}
      video {Vídeo 1}
      video2 {Vídeo 2}
      zoom {Imagem Zoom 1}
      zoom2 {Imagem Zoom 2}
      additional {adicional}
      additional2 {adicional 2}
      rml {rml}
      contract {Contrato}
      supportGroup {Grupo de Pontos}
      lane {Faixa}
      laneTesting {Faixa em Teste}
      laneDisabled {Faixa Inativa}
      wrongRegulation {Enquadramento Inválido}
      calibrationFailure {Aferição Inválida}
      laneRegulationNotEnabled {Velocidade Inválida}
      waitingFiles {Aguardando Arquivos}
      exemptPeriod {Período Isento}
      whiteList {Lista Branca}
      total {Total}
      lot {Lote}
      violationType {Tipo de Infrações}
      situation {Situação}
      violationsAmount {Qtd de Infrações}
      violationPeriod {Período das Infrações}
      modifiedAt {Última Modificação}
      new {Novo}
      open {Aberto}
      closed {Fechado}
      exported {Enviado}
      error {Erro}
      all {Todas}
      type {Tipo}
      effectivePeriod { Período de Vigência }
      serialNumber { Série do Equipamento }
      sealNumber { Número do Lacre }
      appraisalNumber { Número do Laudo }
      expirationDays { Dias para Vencimento }
      actions { Ações }
      metrologic {Metrológica}
      nonMetrologic {Não Metrológica}
      testingLane {Faixa em Teste}
      spotMissing {Ponto Inexistente}
      disabledLane {Faixa Inativa}
      invalidLaneNumber {Número da Faixa Inválido}
      invalidEquipmentSerialNumber {Equipamento Inválido}
      invalidSpeed {Velocidade Inválida}
      oddSpeed {Velocidade Discrepante}
      disabledRegulation {Enquadramento Não Habilitado}
      invalidWeekDay {Dia da Semana Inválido}
      invalidDayPeriod {Período da Infração Inválido}
      invalidVehicleClassification {Classificação do Veículo Não Habilitada}
      violationLimit {Limite de Infrações}
      invalidRegulationPeriod {Período do Enquadramento Inativo}
      invalidAppraisalDate {Data da Aferição Inválida}
      expiredAppraisal {Aferição Vencida}
      missingAppraisalDocument {Arquivo (Laudo) Pendente}
      invalidAppraisalNumber {Nº do Laudo Inválido}
      SPP-Base Interna {SPP-Base Interna}
      SPP-COPOM {SPP-COPOM}
      SPP-ANTT {SPP-ANTT}
      SPP-MJSP {SPP-MJSP}
      SPP-SERPRO {SPP-SERPRO}
      byDay {Diário}
      byTwoDays {A cada 2 dias}
      byWeek {Semanal}
      minutes {Minutos}
      current {Vigente}
      calibrationDate {Data da Aferição}
      triage {Triagem}
      dashboard {Dashboard}
      pre-process {Pré-Processamento}
      process {Processamento}
      inProcess {Em Processamento}
      available-contracts {Contratos Disponíveis}
      equipments {Equipamentos}
      Equipment {Equipamento}
      spots {Pontos}
      lanes {Faixas}
      Lane {Faixa}
      users {Usuários}
      User {Usuário}
      user-groups {Grupos de Usuários}
      calibrations {Aferiçōes}
      settings {Configuraçōes}
      quarantine {Quarentena}
      lot-number {Número do lote}
      lot-list {Listagem de Lotes}
      lot-step {Mapa de Etapas}
      violation-step {Mapa de Etapas}
      violations-report {Relatório de infração}
      contracts {Contratos}
      exempt-periods {Período Isento}
      period {Período}
      time {Tempo}
      amount {Quantidade}
      Sunday {Domingo}
      Monday {Segunda-feira}
      Tuesday {Terça-feira}
      Wednesday {Quarta-feira}
      Thursday {Quinta-feira}
      Friday {Sexta-Feira}
      Saturday {Sábado}
      identifier {Identificador}
      initials {Sigla}
      verifyingDigit {DV}
      startNumber {Nº Inicial}
      endNumber {Nº Final}
      lastUsed {Último Utilizado}
      regulation {Enquadramento}
      equipmentModel {Modelo Do Equipmento}
      serial-number-config {Gerenciamento de Séries}
      monday {Domingo}
      sunday {Segunda-Feira}
      tuesday {Terça-Feira}
      wednesday {Quarta-Feira}
      tursday {Quinta-Feira}
      friday {Sexta-Feira}
      saturday {Sábado}
      production-report { Relatório de Produção }
      byRegulation { Por Enquadramento }
      violations-view { Consulta de infração }
      View {Visualização}
      Create {Criação}
      Delete {Deleção}
      Update {Atualização}
      Activity {Atividade}
      BrandModel {Marca Modelo}
      Calibration {Aferição}
      Category {Categoria}
      Color {Cor}
      Company {Compania}
      Contract {Contrato}
      PreProcessSettings {Pre-Processamento}
      Classification {Classificação}
      EquipmentModel {Modelo de Equipamento}
      ExemptPeriod {Período Isento}
      Reason {Motivos de Invalidação}
      Spot {Ponto}
      ImportSettings {Configuração de Importação}
      ImportFile {Importação de Arquivos}
      User {Usuário}
      UserPermission {Permissão de Usuário}
      Region {Região}
      City {Cidade}
      Vehicle {Veículos}
      VehicleWhiteList {Lista Branca de Veículos}
      Lot {Lote}
      Manufacturer {Fabricante}
      State {Estados}
      UserGroup {Grupo de Usuários}
      Activities {Atividades}
      Login {Login}
      Violation {Infração}
      activities {Atividades}
      Unlock {Desbloqueio}
      Regulation {Enquadramento}
      Type {Tipo}
      laneFailure {Ponto/Faixa Inválida}
      laneNumber {Número da Faixa}
      validateLane {Validar faixa}
      validateCalibration {Validar aferição}
      validateSpeed {Validar velocidade}
      validateFiles {Validar arquivo}
      validateExemptPeriod {Período isento}
      validateViolationLimit {Limite de infração}
      filter {Filtro}
      vehicle {Veículos}
      vehicleWhiteList {Lista Branca}
      import-file {Importação}
      prefix{Sigla}
      end{Fim}
      sizePattern{Padrão de tamanho}
      equipmentModelIds{Modelo de Equipamentos}
      regulationIds{Enquadramentos}
      spotIds{Pontos}
      currentNumber{Número Corrente}
      step{Etapa}
      classification{Classificação Veícular}
      ocr{Ocr}
      trafficLight{Semáforo}
      fixed {Fixo}
      speedBump {Lombada Eletrônica}
      portable {Portátil}
      map {Mapa}
      special {Especial}
      priority {Prioridade}
      review {Revisão}
      samplingPlan {Plano de Amostragem}
      violationTotal {Qtd de Infrações Total}
      violationSample {Qtd de Infrações Amostra}
      violationAudited {Qtd de Infrações Auditado}
      violationDisapproved {Qtd de Infrações Reprovado}
      violationPending {Qtd de Infrações Pendente}
      responsibleAuditUser {Auditor Responsável}
      blockedLot {Lote Bloqueado}
      lowPriority {Baixa Prioridade}
      mediumPriority {Média Prioridade}
      highPriority {Alta Prioridade}
      disabledEquipment {Equipamento desabilitado}
      invalidSerialNumber {N° de série inválido}
      vehicleWhiteLists {Veículo isento}
    }
  </div>
</div>
