<div class="navigation_block" *ngIf="violations && violations.length > 0">
  <div>
    <button class="btn btn-sm btn-icon mr-md-5 mr-sm-auto" href="javascript:void(0)" (click)="backToFilter()">
      <i class="material-icons" i18n-title data-toggle="tooltip" data-placement="top"
        title="Filtrar infrações">wysiwyg</i>
      <span i18n>Filtrar</span>
    </button>
    <button class="btn btn-sm btn-icon btn-icon-right" href="javascript:void(0)" (click)="firstViolation()"
      [ngClass]="{ disabled: violation === violations[0] }">
      <i class="material-icons material-symbols-outlined">keyboard_double_arrow_left</i>
    </button>
    <button class="btn btn-sm btn-icon" href="javascript:void(0)" (click)="prevViolation()"
      [ngClass]="{ disabled: violation === violations[0] }">
      <i class="material-icons">arrow_back_ios</i>
      <span i18n>Anterior</span>
    </button>
    <button class="btn btn-sm btn-icon btn-icon-right" href="javascript:void(0)" (click)="nextViolation()"
      [ngClass]="{ disabled: violation === violations[violations.length -1] }">
      <span i18n>Próximo</span>
      <i class="material-icons">arrow_forward_ios</i>
    </button>
    <button class="btn btn-sm btn-icon btn-icon-right" href="javascript:void(0)" (click)="lastViolation()"
      [ngClass]="{ disabled: violation === violations[violations.length -1] }">
      <i class="material-icons material-symbols-outlined">keyboard_double_arrow_right</i>
    </button>
  </div>

  <div>
    <button class="btn btn-sm btn-icon-block" href="javascript:void(0)" [ngClass]="{ 'active': !isDouble }"
      (click)="setDouble(false)" i18n-title data-toggle="tooltip" data-placement="bottom" title="Layout Simples">
      <i class="material-icons">vertical_split</i>
    </button>
    <button class="btn btn-sm btn-icon-block" href="javascript:void(0)" [ngClass]="{ 'active': isDouble }"
      (click)="setDouble(true)" i18n-title data-toggle="tooltip" data-placement="bottom" title="Layout Duplo">
      <i class="material-icons">view_stream</i>
    </button>
  </div>

  <div class="d-flex align-items-center">
    <button class="btn btn-sm btn-icon mr-2" open-panel (click)="openSearchPlatePanel()" *ngIf="preProcessSettings.vehicleDataSearch">
      <i class="material-icons"><span class="material-icons">
          manage_search
        </span></i> Consultar Placa
    </button>

    <button class="btn btn-sm btn-icon text-info mr-2" open-panel (click)="openCommentPanel()">
      <i class="material-icons">comment</i> {{(violation && violation.notes && violation.notes.length) || 0}}
      &nbsp;<span i18n>Comentários</span>
    </button>

    <div *ngIf="violation && violation.status && violation.status !== ''; else noStatus">
      <div [ngSwitch]="violation.status">
        <span *ngSwitchCase="'valid'" class="badge badge-success btn-sm btn-icon">
          <i class="material-icons">check_circle</i>
          {violation.status, select, valid{Validado}}
        </span>
        <span *ngSwitchCase="'invalid'" class="badge badge-danger btn-sm btn-icon">
          <i class="material-icons">cancel</i>
          {violation.status, select, invalid{Invalidado}}
        </span>
      </div>
    </div>

    <ng-template #noStatus>
      <div class="btn btn-sm btn-icon btn-text">
        <i class="material-icons">access_time</i> <span i18n>Aguardando Avaliação</span>
      </div>
    </ng-template>
  </div>
</div>

<div class="mt-3" *ngIf="violations && violations.length > 0 && violationLoadError === false; else noViolationBlock">
  <div class="container-fluid mb-4">
    <div class="row my-2">
      <div class="col-md-12 mt-2">
        <div class="card-lot">
          <div class="row no-gutters mb-2 justify-content-between violation-data">
            <div class="item">
              <p class="card-label" i18n>Situação</p>
              <p class="card-label-secondary">{{ lotData?.situation || 'Aguardando Avaliação' }}</p>
            </div>
            <div class="item">
              <p class="card-label" i18n>Lote</p>
              <p class="card-label-secondary">{{ lotData?.lotNumber }}</p>
            </div>
            <div class="item">
              <p class="card-label" i18n>Total de Infrações</p>
              <p class="card-label-secondary">{{ lotData?.violationsAmount }}</p>
            </div>
            <div class="item">
              <p class="card-label" i18n>Qtd. Amostra</p>
              <p class="card-label-secondary">{{ lotData?.targetPercentage || '-' }}</p>
            </div>
            <div class="item">
              <p class="card-label" i18n>Qtd. Inconsistentes</p>
              <p class="card-label-secondary">{{ lotData?.audits?.refusedViolations || '-' }}</p>
            </div>
            <!--
            exibir esta coluna caso o lote já tenha sido auditado (if)

            <div class="item">
              <p class="card-label" i18n>Auditado em DD/MM/AAAA às HH:MM:SS</p>
              <p class="card-label-secondary">Matricula - userName</p>
            </div>
            -->
            <div class="item">
              <p class="card-label" i18n>Máxima Permitida</p>
              <p class="card-label-secondary">0000</p>
            </div>
            <div class="item pl-2">
              <p class="card-label" i18n>Qtd. Auditado</p>
              <p class="card-label-secondary">{{ violationIdsProcessed.length }}</p>
            </div>
            <div class="item pl-4">
              <p class="card-label" i18n>Analisado em DD/MM/AAAA às HH:MM:SS</p>
              <p class="card-label-secondary">{{ usersById[violation?.auditAuthorId]?.registryNumber || '-' }} - {{ usersById[violation?.auditAuthorId]?.name || '-' }}</p>
            </div>
            <div class="item" *ngIf="allowEditAudit">
              <p class="card-label" i18n>Sendo auditado por</p>
              <p class="card-label-secondary">{{ currentUser?.registryNumber || '-' }} - {{ currentUser?.name || '-' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters mt-1">
      <div [ngClass]="{ 'pr-sm-3': true, 'col-sm-7': !isDouble, 'col-sm-6': isDouble }">
        <app-img-edit [files]="files" [violationStatus]="violation.status" [preProcessSettings]="preProcessSettings"
          (outputValues)="getImageEdit($event, 'update')" [isSingleView]=true
          (outputRestoreImage)="restoreImage($event)" [isBlurEnabled]="isBlurEnabled" [isClipEnabled]="isClipEnabled">
        </app-img-edit>
        <div class="card card-secondary card-violation card-sm mt-1">
          <div class="card-body pt-1 pb-1" [ngClass]="{ 'pl-1 pr-1': isDouble }">
            <div class="row no-gutters justify-content-between violation-data">
              <div class="item">
                <p class="card-label" i18n>Enquadramento</p>
                <p class="card-label-secondary" data-toggle="tooltip"
                  title="{{regulationByIds && regulationByIds[violation.regulationId] &&
                  regulationByIds[violation.regulationId].description}}">
                  {{regulationByIds && regulationByIds[violation.regulationId] &&
                  regulationByIds[violation.regulationId].code}}
                </p>
              </div>
              <div class="item">
                <p class="card-label" i18n>Data</p>
                <p class="card-label-secondary">{{ violation.date | moment: 'L'}}</p>
              </div>
              <div class="item">
                <p class="card-label" i18n>Hora</p>
                <p class="card-label-secondary">{{ violation.date | moment: 'LTS'}}</p>
              </div>
              <div class="item" *ngIf="!isDouble">
                <p class="card-label" i18n>Faixa</p>
                <p class="card-label-secondary">
                  {{ violation.laneCode }} &nbsp;
                  <span *ngIf="violation.laneNumber  " i18n>(Fx</span>
                  <span *ngIf="violation.laneNumber  ">&nbsp;{{ violation.laneNumber }})</span>
                </p>
              </div>
              <div class="item">
                <p class="card-label" i18n>Vel.Permitida</p>
                <p class="card-label-secondary" *ngIf="violation.speedLimit">
                  {{ violation.speedLimit }}&nbsp;
                  <span i18n>Km/h</span>
                </p>
                <p class="card-label-secondary" *ngIf="!violation.speedLimit">-</p>
              </div>
              <div class="item">
                <p class="card-label" i18n>Vel.Registrada</p>
                <p class="card-label-secondary" *ngIf="violation.speed">
                  {{ violation.speed }}&nbsp;
                  <span i18n>Km/h</span>
                </p>
                <p class="card-label-secondary" *ngIf="!violation.speed">-</p>
              </div>
              <div class="item" [ngClass]="{'np mt-2': isDouble}">
                <p class="card-label" i18n>Vel.Considerada</p>
                <p class="card-label-secondary" *ngIf="violation.calculatedSpeed">
                  {{ violation.calculatedSpeed }}&nbsp;
                  <span i18n>Km/h</span>
                </p>
                <p class="card-label-secondary" *ngIf="!violation.calculatedSpeed">-</p>
              </div>
              <div class="item np mt-2">
                <p class="card-label" i18n>Local</p>
                <p class="card-label-secondary">{{ violation.address && violation.address.street }}</p>
              </div>
              <div class="item mt-2" *ngIf="isDouble">
                <p class="card-label" i18n>Faixa</p>
                <p class="card-label-secondary">
                  {{ violation.laneCode }} &nbsp;
                  <span *ngIf="violation.laneNumber  " i18n>(Fx</span>
                  <span *ngIf="violation.laneNumber  ">&nbsp;{{ violation.laneNumber }})</span>
                </p>
              </div>
              <div class="item mt-2" *ngIf="isDouble">
                <p class="card-label" i18n>N° do Registro</p>
                <p class="card-label-secondary">{{ violation?.equipmentViolationCounter || '-' }}</p>
              </div>
              <div class="item mt-2" [ngClass]="{'np': isDouble}">
                <p class="card-label" i18n>Identificação da Infração</p>
                <p class="card-label-secondary">{{ violation.passId }}</p>
              </div>
              <div class="item mt-2 pl-0">
                <p class="card-label" i18n>Série Equipamento</p>
                <p class="card-label-secondary">{{ violation.equipment && violation.equipment.serialNumber }}</p>
              </div>
              <div class="item mt-2" *ngIf="!isDouble">
                <p class="card-label" i18n>N° do Registro</p>
                <p class="card-label-secondary">{{ violation?.equipmentViolationCounter || '-' }}</p>
              </div>
              <div class="item mt-2">
                <p class="card-label" i18n>Código do Cliente</p>
                <p class="card-label-secondary">{{ violation?.equipmentClientCode || '-' }}</p>
              </div>
              <div class="item mt-2" [ngClass]="{'np': isDouble}">
                <p class="card-label" i18n>Data de aferição</p>
                <p class="card-label-secondary">{{ violation?.equipment?.calibration?.date | moment: 'L'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col mt-xs-3 mt-sm-0" [ngClass]="{ 'col-sm-5': !isDouble, 'col-sm-6': isDouble}">
        <app-img-edit [files]="secondFiles" *ngIf="isDouble" rightColumn=true [isDoubleView]=true
          (outputValues)="getImageEdit($event, 'update')" (outputRestoreImage)="restoreImage($event)"
          [isBlurEnabled]="isBlurEnabled" [isClipEnabled]="isClipEnabled"
          [isAjustedSize]="hasSearch && formGroup.get('cityCode').value">
        </app-img-edit>
        <form [formGroup]="formGroup" (submit)="validate()">
          <div class="card card-bordered form-block mb-2" [ngClass]="{ 'form-block--sm': isDouble }">
            <div class="card-body">
              <div [ngClass]="{'col-12 col-sm-6 col-md-6': !isDouble, 'col-6 col-sm-5 col-md-3 order-1': isDouble}"
                #plateInputElement>
                <app-input i18n-label type="text" label="Placa" formControlName="plate" class="form-control-special"
                  [ngClass]="{'form-active': violationProcessed, 'form-inactive': !violationProcessed}"
                  [uppercase]="true" [isLarge]="true" (ngModelChange)="loadVehicle($event, violation.step)"
                  [isSmall]="isDouble" [promise]="plateSearchPromise" (change)="editEvent('plate')"
                  [inputDisabled]="plateSearchFoundFields.includes('plate') || !this.requiredFields.includes('plate') || violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)">
                </app-input>
              </div>
              <div
                [ngClass]="{'col-12 col-sm-6 col-md-3 col-lg-3': !isDouble, 'col-6 col-sm-4 col-md-3 order-2': isDouble}">
                <app-input i18n-label type="native-select" label="Cor" formControlName="colorCode" [list]="colors"
                  selectText="description" [isSmall]="isDouble" (change)="editEvent('colorCode')" selectValue="code"
                  placeholder="Selecione" [inputDisabled]="plateSearchFoundFields.includes('colorCode')
                || !this.requiredFields.includes('colorCode') || violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)">
                </app-input>
              </div>
              <div
                [ngClass]="{'col-12 col-sm-6 col-md-3 col-lg-3': !isDouble, 'col-6 col-sm-3 col-md-2 order-3': isDouble}">
                <app-input i18n-label type="text" label="Ano" formControlName="year" (change)="editEvent('year')"
                  [isSmall]="isDouble" [inputDisabled]="plateSearchFoundFields.includes('year') || !this.requiredFields.includes('year') ||
                violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)">
                </app-input>
              </div>
              <div [ngClass]="{'col-12 col-sm-8 col-md-6': !isDouble, 'col-6 col-sm-6 col-md-4 order-4': isDouble}"
              *ngIf="!cetModels">
                <app-input i18n-label type="native-select" label="Marca/Modelo" placeholder="Selecione"
                  (change)="editEvent('brandModelCode')" formControlName="brandModelCode" [list]="brandModels"
                  selectText="description" [isSmall]="isDouble" selectValue="code" [inputDisabled]="plateSearchFoundFields.includes('brandModelCode')
                || !this.requiredFields.includes('brandModelCode') || violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)">
                </app-input>
              </div>
              <div [ngClass]="{'col-12 col-sm-8 col-md-6': !isDouble, 'col-6 col-sm-6 col-md-4 order-4': isDouble}"
              *ngIf="cetModels && !brandModelsClient">
                <app-input i18n-label type="native-select" label="Marca/Modelo" placeholder="Selecione"
                  (change)="editEvent('brandModelCode')" formControlName="brandModelCode" [list]="brandInternalModels"
                  selectText="description" [isSmall]="isDouble" selectValue="code" [inputDisabled]="plateSearchFoundFields.includes('brandModelCode')
                || !this.requiredFields.includes('brandModelCode') || violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)">
                </app-input>
              </div>
              <div class="col-12" [ngClass]="{'col-md-6': !isDouble, 'col-sm-6 col-md-4 order-4': isDouble}">
                <app-input i18n-label type="native-select" label="Categoria" placeholder="Selecione"
                  formControlName="categoryCode" [list]="categories" selectText="description" [isSmall]="isDouble"
                  selectValue="code" [inputDisabled]="plateSearchFoundFields.includes('categoryCode') || !this.requiredFields.includes('categoryCode') ||
                violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)" (change)="editEvent('categoryCode')">
                </app-input>
              </div>
              <div class="col-sm-6" [ngClass]="{'col-12 col-md-6': !isDouble, 'col-6 col-md-4 order-6': isDouble}">
                <app-input i18n-label type="native-select" label="Classificação" placeholder="Selecione"
                  formControlName="classificationCode" [list]="classifications" selectText="description"
                  [isSmall]="isDouble" selectValue="code" [inputDisabled]="plateSearchFoundFields.includes('classificationCode')
                || !this.requiredFields.includes('classificationCode') || violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)"
                  (change)="editEvent('classificationCode')">
                </app-input>
              </div>
              <div class="col-sm-6" [ngClass]="{'col-12 col-md-6': !isDouble, 'col-6 col-md-4 order-6': isDouble}">
                <app-input i18n-label type="native-select" label="Tipo" formControlName="typeCode"
                  placeholder="Selecione" [isSmall]="isDouble" [list]="types" selectText="description"
                  selectValue="code" [inputDisabled]="plateSearchFoundFields.includes('typeCode')
                || !this.requiredFields.includes('typeCode') || violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)"
                  (change)="editEvent('typeCode')">
                </app-input>
              </div>
              <ng-container *ngIf="hasSearch && formGroup.get('cityCode').value">
                <div [ngClass]="{'col-12 col-sm-8': !isDouble, 'col-6 col-sm-4 order-8': isDouble}">
                  <app-input i18n-label i18n-placeholder type="native-select" [list]="regions" label="País"
                    formControlName="regionId" selectText="name" selectValue="id" [isSmall]="isDouble"
                    (ngModelChange)="loadStates($event)" [inputDisabled]="plateSearchFoundFields.includes('regionId') ||
                  !this.requiredFields.includes('regionId') || violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)"
                    (change)="editEvent('regionId')">
                  </app-input>
                </div>
                <div [ngClass]="{'col-12 col-sm-4': !isDouble, 'col-6 col-sm-3 order-9': isDouble}">
                  <app-input i18n-label type="native-select" label="UF" formControlName="stateId" [isSmall]="isDouble"
                    (ngModelChange)="loadCities($event)" [list]="showingStates" selectText="abbreviation"
                    selectValue="id" [inputDisabled]="plateSearchFoundFields.includes('stateId') || !this.requiredFields.includes('stateId')
                  || violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)" (change)="editEvent('stateId')">
                  </app-input>
                </div>
                <div class="col-12" [ngClass]="{'col-sm-12': !isDouble, 'col-sm-5 order-10': isDouble}">
                  <app-input i18n-label i18-placeholder type="native-select" [list]="showingCities" label="Município"
                    formControlName="cityCode" selectValue="code" selectText="name" [isSmall]="isDouble"
                    [inputDisabled]="plateSearchFoundFields.includes('cityCode') || !this.requiredFields.includes('cityCode') ||
                  violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id)" (change)="editEvent('cityCode')">
                  </app-input>
                </div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="allowEditAudit; else readReasonOnly">
            <div class="row d-flex justify-content-between align-items-center form-reason-row">
              <div class="col-sm-12 col-md-8" [ngClass]="{'col-md-8': !formEdited, 'col-md-12': formEdited}">
                <form [formGroup]="formGroup" class="reason-form" (submit)="refused()">
                  <select autofocus [disabled]="(violationProcessed || !changesPermited || violationIdsProcessed.includes(violation.id))" (change)="editEvent('reasonCode')"
                    class="form-control mr-2" formControlName="reasonCode" #reasonInput>
                    <option i18n value="">00 - Nenhuma inconsistência</option>
                    <option *ngFor="let reason of reasons" [value]="reason.code">{{ reason.code }} - {{ reason.name }}
                    </option>
                  </select>
                  <button *ngIf="!formEdited" class="btn btn-danger"
                    [disabled]="!validationPlate || !violation.step === 'done' || violationIdsProcessed.includes(violation.id)">
                    <span *ngIf="refuseLoading === false; else loadingBlock" i18n>Recusar</span>
                    <ng-template #loadingBlock>
                      <div class="loading-spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                      </div>
                    </ng-template>
                  </button>
                  <button *ngIf="formEdited" class="btn btn-block btn-primary"
                    [disabled]="!validationPlate || !violation.step === 'done' || violationIdsProcessed.includes(violation.id)">
                    <span *ngIf="refuseLoading === false; else loadingBlock" i18n>Confirmar alteração</span>
                    <ng-template #loadingBlock>
                      <div class="loading-spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                      </div>
                    </ng-template>
                  </button>
                </form>
              </div>
              <div *ngIf="!formEdited" class="col-sm-12 col-md-4 mt-xs-3 mt-md-0">
                <button class="btn btn-primary btn-block" [disabled]="!verifyValidation || violationProcessed || violationIdsProcessed.includes(violation.id)">
                  <span *ngIf="acceptLoading === false && isLoadListViolation === false; else loadingBlock"
                    i18n>Confirmar</span>
                  <ng-template #loadingBlock>
                    <div class="loading-spinner">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>
                    </div>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
          <ng-template #readReasonOnly>
            <form [formGroup]="formGroup" class="reason-form">
              <select autofocus disabled="true" class="form-control mr-2" formControlName="reasonCode" #reasonInput>
                <option i18n value="">00 - Nenhuma inconsistência</option>
                <option *ngFor="let reason of reasons" [value]="reason.code">{{ reason.code }} - {{ reason.name }}</option>
              </select>
            </form>
          </ng-template>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #noViolationBlock>
  <section>
    <div class="noViolation" *ngIf="loadPromise === null; else loadingBlock">
      <i class="material-icons">sentiment_satisfied_alt</i>
      <p i18n>Não há infrações a serem processadas</p>
      <button type="button" class="btn mt-2" [ngClass]="{ 'btn-secondary': true }" i18n (click)="backToFilter()">Filtrar
        Novamente</button>
    </div>
    <ng-template #loadingBlock>
      <div class="noViolation">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </ng-template>
  </section>
</ng-template>
