/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
import { BaseApiModel } from './BaseApiModel';

export class Activity extends BaseApiModel {
  public date: Date;
  public userId: string;
  public content: any;
  public modifiedAt: Date;
  public resourceId: string;
  public contractId: string;
  public createdAt: Date;
  public actionId: string;
}

export enum Actions {
  view = 'View',
  create = 'Create',
  update = 'Update',
  delete = 'Delete',
  Unlock = 'Unlock',
  Login = 'Login'
}


export enum Models {
  Activity = 'Activity',
  BrandModel = 'BrandModel',
  Calibration = 'Calibration',
  Category = 'Category',
  Color = 'Color',
  Company = 'Company',
  Contract = 'Contract',
  PreProcessSettings = 'PreProcessSettings',
  Classification = 'Classification',
  Equipment = 'Equipment',
  EquipmentModel = 'EquipmentModel',
  ExemptPeriod = 'ExemptPeriod',
  Reason = 'Reason',
  Spot = 'Spot',
  ImportSettings = 'ImportSettings',
  ImportFile = 'ImportFile',
  User = 'User',
  UserPermission = 'UserPermission',
  Region = 'Region',
  Regulation = 'Regulation',
  Lane = 'Lane',
  Type = 'Type',
  City = 'City',
  Vehicle = 'Vehicle',
  VehicleWhiteList = 'VehicleWhiteList',
  Lot = 'Lot',
  Violation = 'Violation',
  Manufacturer = 'Manufacturer',
  State = 'State',
  UserGroup = 'UserGroup'
}
