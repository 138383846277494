import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { ClosedModalError } from 'src/app/core/errors';
import { Company, ComponentModal, User, UserGroup, Contract } from 'src/app/core/models';
import { AlertItem, AlertType } from 'src/app/core/models/AlertItem';
import {
  AlertService, CompanyService, ModalService,
  StorageKey, StorageService, UserGroupService,
  UserService,
  ContractService,
  ContractGlobalService
} from 'src/app/core/services';
import { UserCreateComponent } from 'src/app/pages/users/user-create/user-create.component';
import { UserViewComponent } from 'src/app/pages/users/user-view/user-view.component';
import { UsersEditModalComponent } from 'src/app/pages/users/users-edit-modal/users-edit-modal.component';
import { hasPermission } from 'src/app/core/utils/permission';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.sass']
})

export class UsersListComponent implements OnInit {
  dropMenuActive = false;
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public modalState;
  public dateTest: any;
  public companies: Array<Company> = [];
  public userGroups: Array<UserGroup> = [];
  public userGroupList: { [params: string]: any } = {};
  public userGroupContract = [];
  public superUser = false;
  public verified = false;
  register = false;
  searchForm: FormGroup;
  users = [];
  public params: any = {};
  modelChanged: Subject<string> = new Subject<string>();
  contractsSubscription: Subscription;
  public companyList: { [params: string]: any } = {};
  public contracts = [];

  constructor(
    public userService: UserService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private companyService: CompanyService,
    private userGroupService: UserGroupService,
    private alertService: AlertService,
    private contractService: ContractService,
    private storageService: StorageService,
    private contractsGlobalService: ContractGlobalService,
    private router: Router
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
    this.contractsSubscription = this.contractsGlobalService.contractEvent
      .subscribe(contractCodes => {
        if (contractCodes.length > 0) {
          if (!hasPermission('ViewUser', this.storageService, contractCodes)) {
            this.router.navigate(['/']);
          }
        }
      });
  }

  ngOnInit() {
    const user = this.storageService.get(StorageKey.currentUser);
    this.contracts = this.storageService.get(StorageKey.currentContracts) || [];
    if (user != null) {
      this.superUser = user.superUser;
    }
    if (!this.superUser) {
      this.params.contractsCompany = `${this.contracts}`;
      this.params['groupIds[in]'] = `[${user?.groupIds.join(',')}]`;
    }
    this.createSearchForm();
    this.companyService.getAll().then(res => {
      this.companies = res;
      this.companies.forEach(company => {
        this.companyList[company.id] = company;
      });
    });
    this.setUserGroup();
  }

  activeMenu() {
    this.dropMenuActive = true;
  }

  disableMenu() {
    this.dropMenuActive = false;
  }

  createSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      search: [''],
      company: [''],
      verified: [''],
      groupIds: [[]]
    });
  }

  hasPermissionContract(user) {
    if (this.superUser) {
      return;
    }
    if (hasPermission('ViewUser', this.storageService, this.contracts)) {
      return;
    }
    return true;
  }

  handleSearch() {
    this.users = [];
    const user = this.storageService.get(StorageKey.currentUser);
    const search = this.searchForm.get('search').value;
    const filterObj: any = {};
    const groupIds = this.searchForm.get('groupIds').value;
    const verified = this.searchForm.get('verified').value;

    if (search != null && search !== '') {
      filterObj['role[contains,or]'] = `${search}`;
      filterObj['companyId[contains,or]'] = `${search}`;
      filterObj['email[contains,or]'] = `${search.toLowerCase()}`;
      filterObj['name[contains,or]'] = `${search.toLowerCase()}`;
    }
    if (verified !== '') {
      filterObj[`verified[bool]`] = verified;
    }
    if (groupIds.length > 0) {
      filterObj['groupIds[in]'] = `[${groupIds.join(',')}]`;
    }
    if (this.searchForm.get('company').value.length > 0) {
      filterObj['companyId[in]'] = `[${this.searchForm.get('company').value.join(',')}]`;
    }

    this.params = filterObj;
    if (!this.superUser) {
      this.params.contractsCompany = `${this.contracts}`;
      this.params['groupIds[in]'] = `[${user?.groupIds.join(',')}]`;
    }
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  openCreate() {
    this.modalService.show(new ComponentModal(UserCreateComponent, this.modalState)).then(data => {
      const modal = data as any;
      const user = modal && modal.component && modal.component.instance && modal.component.instance &&
        modal.component.instance.user;

      if (user != null) {
        this.users.push(user);
      }
    }).catch(err => {
      if (err instanceof ClosedModalError) {
        const modalError = err as ClosedModalError;
        this.modalState = (modalError.modal as ComponentModal).component.instance.lastState;
      } else {
        this.modalState = null;
      }
    });
  }

  openUserEdit(user: User) {
    this.modalService.show(new ComponentModal(UsersEditModalComponent, user)).then(data => {
      const modal = data as any;
      const savedUser = modal && modal.component && modal.component.instance && modal.component.instance &&
        modal.component.instance.user;
      if (user != null) {
        for (const i in this.users) {
          if (this.users[i].id === savedUser.id) {
            this.users[i] = savedUser;
            this.loadUserGroup(savedUser);
          }
        }
      }
    }).catch(err => {
      this.handleSearch();
      if (err instanceof ClosedModalError) {
        const modalError = err as ClosedModalError;
        this.modalState = (modalError.modal as ComponentModal).component.instance.lastState;
      } else {
        this.modalState = null;
      }
    });
  }

  openUserView(user: User) {
    this.modalService.show(new ComponentModal(UserViewComponent, user))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  deleteUser(user) {
    const mustDelete = confirm('Deseja realmente excluir este item?');
    if (mustDelete) {
      this.userService.delete(user.id).then(
        () => {
          this.deleteEvent.emit(user.id);
          this.alertService.show(new AlertItem('UserDeleted', AlertType.danger));
        },
        () => this.alertService.show(new AlertItem('UserDeleteError', AlertType.danger))
      );
    }
  }

  sendReinvite(user: User) {
    this.userService.reinvite(user).then(res => {
      this.alertService.show(new AlertItem('EmailSent', AlertType.success));
    });
  }

  setUsers(list) {
    list.forEach((user: User) => {
      this.loadUserGroup(user);
    });
    this.users = list;
  }

  setUserGroup() {
    this.userGroupService.getAll().then(res => {
      res.forEach(userGroupId => {
        this.contractService.getById(userGroupId.contractId).then(contract => {
          this.userGroupContract.push({
            id: userGroupId.id, prettyName: userGroupId.name + ` (${contract.code} - ${contract.name})`
          });
        });
      });
    });
  }

  loadUserGroup(user: User){
    for(const userGroupId of user.groupIds){
      this.userGroupService.getById(userGroupId).then(userGroup => {
        if (this.userGroupList[userGroup.id + user.email] == null) {
          this.contractService.getById(userGroup.contractId).then(contract => {
            this.userGroupList[userGroup.id + user.email] = {
              id: userGroup.id,
              prettyName: userGroup.name + ` (${contract.code} - ${contract.name})`
            };
          });
        };
      });
    }
  }

  async enableOrDisableUser(user: User) {
    let message = '';
    if (user.enabled) {
      user.enabled = false;
      message = 'UserEnabled';
    } else {
      user.enabled = true;
      message = 'UserDisabled';
    }
    if (!user.name) {
      delete user.name;
    }
    if (user?.files?.length > 0) {
      user.files = user.files.map(f => f.file);
    }
    await this.userService.update(user).then(res => {
      this.alertService.show(new AlertItem(message, AlertType.success));
    }).catch(err => {
      this.alertService.show(new AlertItem('UserSaveError', AlertType.danger));
    });
  }
}
