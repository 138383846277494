<div class="modal-dialog modal-dialog-centered modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <b class="modal-title container" i18n>Informações do Lote</b>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
              aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body" *ngIf="loading; else loadingBlock" #container>
      <div class="container">
        <div class="capture-block">
          <div class="row">
            <div class="col">
              <smal i18n>Lote</smal><br/>
              <small>{{lot.name}}</small>
            </div>
            <div class="col">
              <small i18n>Status</small><br/>
              <small *ngIf="lot.status==='closed'" i18n>Fechado</small>
              <small *ngIf="lot.status==='new'" i18n>Novo</small>
              <small *ngIf="lot.status==='open'" i18n>Aberto</small>
              <small *ngIf="lot.status==='process'" i18n>Processar</small>
              <small *ngIf="lot.status==='audit'" i18n>Auditoria</small>
              <small *ngIf="lot.status==='done'" i18n>Concluído</small>
              <small *ngIf="lot.status==='error'" i18n>Erro</small>
              <small *ngIf="!lot.status" i18n>-</small>
            </div>
            <div class="col">
              <small i18n>Tipo</small><br/>
              <small> {{ sourcesById[lot.source] }} </small>
            </div>
            <div class="col">
              <small i18n>Última Modificação</small><br/>
              <small> {{lot.modifiedAt | moment: 'L LTS' }} </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loadingBlock>
      <div class="loading-page">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
