import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Violation, Regulation, ComponentModal, QuarantineViolationsProcess,
  AlertItem, AlertType, VehicleWhiteList, ExemptPeriod, Lane, Manufacturer, Classification, Activity,
  Calibration
} from 'src/app/core/models';
import { ViolationService } from 'src/app/core/services/violation.service';
import { RegulationService } from 'src/app/core/services/regulation.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { QuarantineStatus, Quarantine } from 'src/app/core/models/Quarantine';
import {
  QuarantineService, ModalService, AlertService, QuarantineProcessService,
  CalibrationService, VehicleWhiteListService, ExemptPeriodService, LaneService, ContractService,
  ManufacturerService, EquipmentService, ClassificationService, ActivityService
} from 'src/app/core/services';
import * as moment from 'moment';
import {
  QuaratineActionsMessageModalComponent
} from 'src/app/modals/quaratine-actions-message-modal/quaratine-actions-message-modal.component';
import { validateDate } from 'src/app/core/utils/validateDate';
import { DetailsViolationComponent } from 'src/app/modals/details-violation/details.component';

@Component({
  selector: 'app-quarantine-detail',
  templateUrl: './quarantine-detail.component.html',
  styleUrls: ['./quarantine-detail.component.sass']
})
export class QuarantineDetailComponent implements OnInit {
  public violations: Array<Violation> = [];
  public quarantine: Quarantine = new Quarantine();
  public violationsSelected: {
    [key: string]: {
      contractId: string;
      isChecked: boolean;
    };
  } = {};
  public quarantineProcess = {};
  public params: any = {};
  public regulationsMap: Array<Regulation> = [];
  public checkViolationForm: FormGroup;
  public contractId = '';
  public lanes: Lane[] = [];
  public quarantineReason = null;
  public quarantineId = '';
  public quarantineStatusI18n = [];
  public columnsToShow = [];
  public quarantineStatus = Object.values(QuarantineStatus);
  public status = 'quarantine';
  public days = ['monday', 'sunday', 'tuesday', 'wednesday', 'tursday', 'friday', 'saturday'];
  public daysI18n = [];
  public enabledViolations = [];
  public isCheckedAll = false;
  public isChecked = false;
  public isQuarantineProcess = false;
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public promise: Promise<any>;
  public quarantineViolationsProcess: QuarantineViolationsProcess = new QuarantineViolationsProcess();
  public regulation: Regulation;
  public settingByregulationId: { [id: number]: any } = {};
  public resources = ['metrologic', 'non-Metrologic'];
  public translatedResources = [];
  public calibrationsByViolationId: { [violationId: string]: any[] } = {};
  public calibrationsById: { [id: number]: Calibration } = {};
  public step: string;
  public whiteListByViolationId: { [violationId: string]: VehicleWhiteList } = {};
  public exemptPeriodByViolationId: { [violationId: string]: ExemptPeriod } = {};
  public manufacturerByIds: { [manufacturerId: string]: Manufacturer } = {};
  public periodFilter;
  public searchForm: FormGroup;
  public defaultFilter = null;
  public limits: { [params: string]: any } = {};
  public startDate;
  public endDate;
  public isLoading: boolean;
  public isLoadingDate: boolean;
  public equipmentSerialNumberById: any[] = [];
  public classifications: Classification[] = [];
  public classificationByCode: { [code: string]: Classification } = {};
  public violationPeriodsByRegulationId: any = {};
  public lane = new Lane();
  public activity = new Activity();

  get isValid() {
    return this.searchForm.valid;
  }

  constructor(
    private laneService: LaneService,
    private route: ActivatedRoute,
    public violationService: ViolationService,
    private regulationService: RegulationService,
    private router: Router,
    private quarantineService: QuarantineService,
    private alertService: AlertService,
    private quarantineProcessService: QuarantineProcessService,
    private calibrationService: CalibrationService,
    private vehicleWhiteListService: VehicleWhiteListService,
    private exemptPeriodService: ExemptPeriodService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private contractService: ContractService,
    private manufacturerService: ManufacturerService,
    private equipmentService: EquipmentService,
    private classificationService: ClassificationService,
    private activityService: ActivityService
  ) { }

  async ngOnInit() {
    this.isLoading = true;
    const now = new Date().toISOString();
    this.startDate = moment(now).subtract(60, 'days').utc().format('YYYY-MM-DD');
    this.endDate = moment(now).utc().format('YYYY-MM-DD');

    this.contractId = this.route.snapshot.params.contractId;
    this.contractService.getById(this.contractId).then(contract => {
      this.regulationService.getAll({ regionId: contract.regionId }).then(regulations => {
        regulations.forEach(regulation => {
          this.regulationsMap[regulation.id] = regulation;
        });
      });
    });
    this.createSearchForm();
    const routeCurrent = this.route.snapshot.url[2] || { path: '' };
    if (routeCurrent != null && routeCurrent.path != null) {
      this.quarantineReason = routeCurrent.path;
    }
    this.lanes = await this.laneService.getAll();

    this.quarantineId = this.route.snapshot.params.quarantineId;
    await this.loadQuarantine(this.quarantineId);
    await this.getClassifications();

    let additionalColumns;
    if (this.quarantineReason != null) {
      switch (this.quarantineReason) {
        case 'invalidWeekDay':
          this.step = 'validateLane';
          additionalColumns = [
            'plate',
            'lane',
            'operationPeriod',
            'invalidWeekDay'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'invalidDayPeriod':
          this.step = 'validateLane';
          additionalColumns = [
            'plate',
            'lane',
            'invalidDayPeriod'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'disabledRegulation':
          this.step = 'validateLane';
          additionalColumns = [
            'plate'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'missingAppraisalDocument':
          this.step = 'validateCalibration';
          additionalColumns = [
            'serialNumber',
            'calibrationType',
            'calibrationNumber',
            'calibrationDate'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'invalidAppraisalNumber':
          this.step = 'validateCalibration';
          additionalColumns = [
            'serialNumber',
            'calibrationType',
            'appraisalNumberComparison'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'invalidAppraisalDate':
          this.step = 'validateCalibration';
          additionalColumns = [
            'serialNumber',
            'calibrationType',
            'appraisalDateComparison'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'expiredAppraisal':
          this.step = 'validateCalibration';
          additionalColumns = [
            'serialNumber',
            'calibrationType',
            'calibrationEndsAt'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'waitingFiles':
          this.step = 'validateFiles';
          additionalColumns = [
            'zoom',
            'zoom2',
            'pan',
            'pan2',
            'video',
            'video2',
            'additional'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'testingLane':
          this.step = 'validateLane';
          additionalColumns = [
            'serialNumber',
            'equipmentModel',
            'test'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'disabledLane':
          this.step = 'validateLane';
          additionalColumns = [
            'serialNumber',
            'equipmentModel',
            'disabled'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'invalidLaneNumber':
          this.step = 'validateLane';
          additionalColumns = [
            'serialNumber',
            'laneNumber'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'spotMissing':
          this.step = 'validateLane';
          additionalColumns = [
            'serialNumber'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'invalidSpeed':
          this.step = 'validateSpeed';
          additionalColumns = [
            'vehicleType',
            'speedLimit',
            'laneSpeed',
            'calculatedSpeed',
            'speed'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'oddSpeed':
          this.step = 'validateSpeed';
          additionalColumns = [
            'discrepantVehicleType',
            'discrepantSpeedLimit',
            'discrepantCalculatedSpeed',
            'speed'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'whiteList':
          this.step = 'validateVehicleWhiteList';
          additionalColumns = [
            'plate',
            'whiteListRecordNumber',
            'whiteListDate'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'exemptPeriod':
          this.step = 'validateExemptPeriod';
          additionalColumns = [
            'exemptPeriodReason',
            'exemptPeriodDate'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'invalidEquipmentSerialNumber':
          this.step = 'validateEquipment';
          additionalColumns = [
            'equipmentSerialNumber'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'disabledEquipment':
          this.step = 'validateEquipment';
          additionalColumns = [
            'equipmentSerialNumber'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'invalidRegulationPeriod':
          this.step = 'validateLane';
          additionalColumns = [
            'operationPeriod'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          this.quarantine.enabledViolations.map(item => {
            this.settingByregulationId[item.regulationId] = item;
          });
          break;
        case 'invalidVehicleClassification':
          this.step = 'validateLane';
          additionalColumns = [
            'vehicleClassification'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'violationLimit':
          this.step = 'validateViolationLimit';
          this.status = 'linked';
          this.quarantineReason = 'violationLimit';
          additionalColumns = [
            'violationLimitvehicleClassification',
            'violationLimitPeriod'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'missingTarge':
          this.step = 'validateFiles';
          additionalColumns = [
            'serialNumber',
            'files'
          ];
          this.columnsToShow = this.columnsToShow.concat(additionalColumns);
          break;
        case 'doubleViolation':
            this.step = 'validateDoubleViolation';
            additionalColumns = [];
            this.columnsToShow = this.columnsToShow.concat(additionalColumns);
            break;
      }
    }
    this.handleSearch();
    this.getEquipmentSerialNumber();
    this.isLoading = false;
  }

  async loadQuarantine(id) {
    this.lane = await this.laneService.getById(id);
    const paramsActvities: any = {
      resourceId: this.lane.id,
      actionId: 'UpdateLane',
      'date[gte]': this.lane.modifiedAt
    };
    this.activityService.list(paramsActvities).then(res => {
      res.result.map(r => {
        if (['disabled', 'test'].includes(r?.content?.operationMode?.new)) {
          this.activity = r;
        }
      });
    });
    await this.quarantineService.list({ contractId: this.lane.contractId, code: this.lane.code }).then(res => {
      const quarantine = res.result[0];
      if (this.quarantine.report) {
        delete this.quarantine.report;
      }
      if (quarantine && quarantine.code) {
        this.params.laneCode = [quarantine.code];
        this.quarantine = quarantine;
        this.quarantine.enabledViolations.map(regulation => {
          this.settingByregulationId[regulation.regulationId] = regulation;
        });
      }
    });
    this.quarantine.enabledViolations.map(period => {
      this.violationPeriodsByRegulationId[period.regulationId] = period;
    });
  }

  async getEquipmentSerialNumber() {
    this.equipmentSerialNumberById = [];
    this.quarantine.equipmentIds.map(async id => {
      await this.equipmentService.getById(id).then(res => {
        this.equipmentSerialNumberById[this.quarantine.code] = res.serialNumber;
      });
    });
  }

  setViolations(list) {
    this.isCheckedAll = false;
    this.violationsSelected = {};
    this.violations = list; //.filter(v => v.id === 616859);
    if (this.violations.length > 0) {
      this.violations.forEach(async violation => {
        this.violationsSelected[violation.id] = {
          contractId: violation.contractId,
          isChecked: false
        };
        if (this.step === 'validateCalibration') {
          this.getCalibrations(violation);
          if (violation?.equipment?.calibration?.id) {
            this.getCalibrationById(violation);
          }
        } else if (this.step === 'validateVehicleWhiteList') {
          this.whiteListByViolationId[violation.id] =
            await this.vehicleWhiteListService.getById(violation.vehicleWhiteListId);
        } else if (this.step === 'validateExemptPeriod') {
          this.exemptPeriodByViolationId[violation.id] =
            await this.exemptPeriodService.getById(violation.exemptPeriodId);
        }
        const manufacturerId = violation.equipment && violation.equipment.manufacturerId;
        if (manufacturerId != null) {
          await this.manufacturerService.getById(manufacturerId)
            .then(manufacturer => {
              if (this.manufacturerByIds[manufacturerId] == null) {
                this.manufacturerByIds[manufacturerId] = manufacturer;
              }
            });
        }
        this.getLaneConfig(violation);
      });
    }
  }

  getClassificationCode(regulationId) {
    return this.quarantine.enabledViolations.find(x => x.regulationId === regulationId)?.classificationCode;
  }

  async getClassifications() {
    await this.contractService.getById(this.contractId).then(contract => {
      this.classificationService.getAll({ regionId: contract.regionId }).then(res => {
        this.classifications = res;
        res.map(classification => {
          this.classificationByCode[classification.code] = classification;
        });
      });
    });
  }

  selectViolation(checked, id) {
    this.isChecked = checked;
    this.violationsSelected[id].isChecked = checked;
    this.isCheckedAll = Object.values(this.violationsSelected)
      .every(v => v.isChecked === true);

    this.isChecked = Object.values(this.violationsSelected)
      .some(v => v.isChecked === true);
  }

  allClick(checked) {
    this.isCheckedAll = checked;
    for (const i in this.violationsSelected) {
      if (this.violationsSelected[i] != null) {
        this.violationsSelected[i].isChecked = this.isCheckedAll;
        this.isChecked = checked;
      }
    }
  }

  back() {
    this.router.navigate([`/pre-process/quarantine/${this.contractId}`]);
  }

  sumTotalQuarantines() {
    let total = 0;
    if (this.quarantine != null && this.quarantine.report != null) {
      if (this.quarantine.report[this.quarantineReason] != null) {
        if (this.quarantine.report.disabledRegulation != null) {
          total = this.quarantine.report[this.quarantineReason];
        }
      }
    }
    return total;
  }

  getStatusQuarantine() {
    if (this.quarantineStatusI18n.length === 0) {
      return;
    }
    return this.quarantineStatusI18n.find(q => q.id === this.quarantineReason).value;
  }

  getLaneConfig(violation: Violation) {
    if (this.quarantine != null && this.quarantine.enabledViolations != null) {
      const laneViolation = this.quarantine.enabledViolations.find(q => q.regulationId === violation.regulationId);
      if (laneViolation != null && laneViolation.periods != null) {
        if (this.columnsToShow.includes('invalidDayPeriod')) {
          const day = this.getDay(violation.date);
          if (this.enabledViolations[day] == null) {
            this.enabledViolations[day] = [];
            this.enabledViolations[day] = laneViolation.periods.filter(d => d.weekdays[0] === day);
          }
        } else if (this.columnsToShow.includes('invalidWeekDay') &&
          (violation && violation.vehicle && violation.vehicle.plateOCR)) {
          const plate = violation.vehicle.plateOCR;
          if (this.enabledViolations[plate.slice(-1)] == null) {
            const enabledViolationsFound = laneViolation.periods.filter(p => {
              if (p.plateRegex != null && plate.match(p.plateRegex) != null) {
                return p;
              }
            });
            if (enabledViolationsFound.length > 0) {
              this.enabledViolations[plate.slice(-1)] = enabledViolationsFound;
            }
          }
        }
      }
    }
  }

  getDay(date) {
    return moment(date).day();
  }

  openQuarantineModalAction(typeProcess) {
    const violationsChecked = this.getViolatioSelected(typeProcess);
    this.modalService.show(new ComponentModal(QuaratineActionsMessageModalComponent, violationsChecked))
      .then(data => {
        this.isQuarantineProcess = true;
        this.quarantineViolationsProcess = {
          reasonId: 0,
          violationIds: violationsChecked.violationIds.map(Number),
          contractId: Number(violationsChecked.contractId)
        };
        if (typeProcess === 'invalidate') {
          typeProcess = 'invalidateViolation';
          const modal = data as any;
          const reasonId = modal && modal.component && modal.component.instance &&
            modal.component.instance.reasonId;
          this.quarantineViolationsProcess.reasonId = reasonId;
        }
        const totalViolationsOld = parseInt(document.getElementById('totalViolationsQuarantine').innerHTML, 10);
        const contQuarantine = totalViolationsOld - this.quarantineViolationsProcess.violationIds.length;
        this.processSelectedQuarantines(typeProcess);
        document.getElementById('totalViolationsQuarantine').innerHTML = String(contQuarantine);
        this.isChecked = false;
      }).catch(err => { });
  }

  processSelectedQuarantines(routeName) {
    this.promise = this.quarantineProcessService.quarantineProcess(routeName, this.quarantineViolationsProcess)
      .then(async () => {
        for (const violationId of this.quarantineViolationsProcess.violationIds) {
          this.deleteEvent.emit(violationId);
        }
        await this.loadQuarantine(this.quarantineId);

        switch (routeName) {
          case 'invalidateViolation':
            this.alertService.show(new AlertItem('QuarantineProcessViolationsInvalidate', AlertType.success));
            break;
          case 'nextStep':
            this.alertService.show(new AlertItem('QuarantineProcessViolationsNextStep', AlertType.success));
            break;
          case 'reprocess':
            this.alertService.show(new AlertItem('QuarantineProcessViolationsReprocess', AlertType.success));
            break;
        }
      }).catch((err) => {
        this.alertService.show(new AlertItem('QuarantineProcessViolationsError', AlertType.danger));
      }).finally(() => {
        this.promise = null;
        this.isQuarantineProcess = false;
      });
    return this.promise;
  }

  getViolatioSelected(process) {
    const violationIds = [];
    for (const i in this.violationsSelected) {
      if (this.violationsSelected[i] != null && this.violationsSelected[i].isChecked) {
        violationIds.push(i);
      }
    }
    const violationsChecked = {
      violationIds,
      contractId: this.contractId,
      process,
      quarantineReason: this.quarantineReason
    };
    return violationsChecked;
  }

  getCalibrationType(violation: Violation) {
    if (this.regulationsMap == null) {
      return '';
    }
    const regulation = this.regulationsMap[violation.regulationId];
    const type = regulation.isMetrological ||
    [74550, 74710, 74630].includes(Number(violation.regulationId)) ? 'metrologic' : 'non-Metrologic';
    return this.translatedResources.find(r => r.id === type);
  }

  async getCalibrations(violation: Violation) {
    this.isLoadingDate = true;
    const equipmentId = violation.equipment.id;
    this.calibrationsByViolationId[violation.id] = [];
    const params = {
      ['type']: this.getCalibrationType(violation).id
    };
    await this.equipmentService.getById(equipmentId).then(async (equipment) => {
      if (violation.equipment.calibration.type === 'metrologic') {
        await this.calibrationService.getById(equipment.metrologicCalibrationId).then((calibration) => {
          if (calibration) {
            this.calibrationsById[violation.equipment.serialNumber + violation.equipment.calibration.type] = calibration;
          }
        });
      } else {
        await this.calibrationService.getById(equipment.nonMetrologicCalibrationId).then((calibration) => {
          if (calibration) {
            this.calibrationsById[violation.equipment.serialNumber + violation.equipment.calibration.type] = calibration;
          }
        });
      }
    }).catch(async err => {
      this.calibrationsByViolationId[violation.id] = null;
    });
    this.isLoadingDate = false;
  }

  async getCalibrationById(violation: any) {
    await this.calibrationService.getById(violation.equipment.calibration.id).then(calibration => {
      this.calibrationsById[violation.equipment.calibration.date + violation.equipment.calibration.type] = calibration;
    });
  }

  verifyRequiredFiles(typeFile, violation) {
    const enabledViolations = this.quarantine.enabledViolations
      .find(enabledViolation => enabledViolation.regulationId === violation.regulationId);
    if (enabledViolations == null || !enabledViolations.requiredFiles.includes(typeFile)) {
      return;
    }
    const violationFile = violation.files.find(file => file.type === typeFile);
    if (violationFile == null) {
      return false;
    }
    return true;
  }

  getLaneSpeed(violation: Violation) {
    if (violation.vehicle == null || this.quarantine == null) {
      return '';
    }
    const vehicleSize = violation.vehicle.size < 4 ? 'initialRegular' : 'initialHeavy';
    const limit = this.quarantine.speeds.regulationLimits.find(l => l.regulationId === violation.regulationId);
    this.limits = {
      initialSpeed: vehicleSize === 'initialRegular' ? limit.initialRegular : limit.initialHeavy,
      finalSpeed: vehicleSize === 'initialRegular' ? limit.finalRegular : limit.finalHeavy
    };
    return limit[vehicleSize] != null ? limit[vehicleSize] : limit.initialRegular;
  }

  getSpeedLimit(violation: Violation) {
    this.getLaneSpeed(violation);
    if (violation.vehicle && violation.vehicle.size && violation.vehicle.size >= 4) {
      return this.quarantine.speeds.heavyVehicles;
    }
    return this.quarantine.speeds.regularVehicles;
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      endsAt: [''],
      startsAt: ['']
    });
    this.searchForm.valueChanges.subscribe(controls => {
      if (controls.endsAt != null || controls.startsAt != null) {
        this.periodFilter = null;
      }
    });
    const startsAt = this.searchForm.get('startsAt');
    const endsAt = this.searchForm.get('endsAt');

    startsAt.setValue(this.startDate);
    endsAt.setValue(this.endDate);

    startsAt.setValidators(validateDate(endsAt, false));
    endsAt.setValidators(validateDate(startsAt, true));
  }

  fillPeriod(value) {
    if (this.periodFilter === value) {
      this.periodFilter = null;
    } else {
      const format = 'YYYY-MM-DD';
      this.periodFilter = value;
      const date = this.periodFilter.split(' ');
      this.searchForm.patchValue({
        startsAt: moment().subtract(date[0], date[1]).format(format),
        endsAt: moment().format(format)
      }, { emitEvent: false });
    }
  }

  handleSearch() {
    if (!this.searchForm.valid) {
      return null;
    }
    this.violations = [];
    if (this.defaultFilter == null) {
      this.defaultFilter = {
        contractId: this.contractId,
        step: this.step,
        status: this.status,
        laneCode: [this.quarantine.code],
        quarantineReason: this.quarantineReason,
        order: 'date'
      };
    };

    const params = {};
    const startsAt = this.searchForm.get('startsAt').value || null;
    const endsAt = this.searchForm.get('endsAt').value || null;
    if ((startsAt != null && startsAt !== '') && (endsAt != null && endsAt !== '')) {
      params['date[gte]'] = moment(startsAt).utc().format();
      params['date[lte]'] = moment(endsAt + ' 23:59:59').utc().format();
    } else {
      params['date[gte]'] = this.startDate;
    }
    this.params = Object.assign(params, this.defaultFilter);
  }

  getLanePlateDigit(plateDigits) {
    return plateDigits.replace(/[^-a-zA-Z0-9]+/g, '').split('').toString();
  }

  async showDetails(violation) {
    await this.modalService.show(new ComponentModal(DetailsViolationComponent, { violation }))
      .catch((error) => console.log(error));
  }
}
