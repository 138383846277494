import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReasonService, ModalService, PreProcessSettingsService } from 'src/app/core/services';
import { Reason } from 'src/app/core/models';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalContent } from 'src/app/core/interface';
import { ViolationService } from 'src/app/core/services/violation.service';

@Component({
  selector: 'app-quaratine-actions-message-modal',
  templateUrl: './quaratine-actions-message-modal.component.html',
  styleUrls: ['./quaratine-actions-message-modal.component.sass']
})
export class QuaratineActionsMessageModalComponent extends BaseModal implements OnInit, ModalContent {
  public form: FormGroup;
  public reasons: Array<Reason> = [];
  public violationCount = 0;
  public process = null;
  public reasonId;
  public quarantineReason = '';
  public isLoading: boolean;

  get isValid(): boolean {
    if (this.process === 'invalidate') {
      return this.form.valid;
    } else {
      return true;
    }
  }

  constructor(
    public modalService: ModalService,
    private formBuilder: FormBuilder,
    private reasonService: ReasonService,
    private preProcessSettingsService: PreProcessSettingsService,
    public violationService: ViolationService
  ) {
    super(modalService);
  }

  async ngOnInit() {
    this.isLoading = true;
    const quarantineViolations = this.initialState || null;
    const contractId = quarantineViolations && quarantineViolations.contractId;
    const violationIds = quarantineViolations && quarantineViolations.violationIds || [];
    this.quarantineReason = quarantineViolations && quarantineViolations.quarantineReason || '';
    this.process = quarantineViolations && quarantineViolations.process;
    this.violationCount = violationIds.length;
    this.createForm();
    if (this.process === 'invalidate' && contractId != null) {
      await this.loadReasons(contractId, this.quarantineReason);
    }
    this.isLoading = false;
  }

  async loadReasons(contractId, quarantineReason) {
    await this.reasonService.getAll({
      contractId,
      'enabled[bool]': 'true'
    }).then(data => {
      this.reasons =  data.sort((a, b): any => Number(a.code) - Number(b.code));
      if (quarantineReason === 'whiteList') {
        this.preProcessSettingsService.getById(contractId).then(preProcessSettings => {
          if (preProcessSettings.whitelistReasonId != null) {
            this.form.patchValue({
              reasonId: preProcessSettings.whitelistReasonId
            });
          }
        });
      }
      if (quarantineReason === 'exemptPeriod') {
        if (this.violationCount === 0) {
          return;
        }
        if (this.violationCount === 1) {
          this.violationService.getById(this.initialState.violationIds[0])
            .then(result => {
              const reason = this.reasons.find(item => item.code === result.reason.code);
              this.form.patchValue({
                reasonId: reason.id
              });
            });
        } else {
          const violationsReasonsCodes = [];
          let itemsProcessed = 0;
          this.initialState.violationIds.forEach(violationId => {
            this.violationService.getById(violationId)
              .then(result => {
                itemsProcessed++;
                if (violationsReasonsCodes.length === 0) {
                  violationsReasonsCodes.push(result.reason.code);
                } else {
                  const check = violationsReasonsCodes.findIndex(item => item === result.reason.code);
                  if (check === -1) {
                    violationsReasonsCodes.push(result.reason.code);
                  }
                }
                if (itemsProcessed === this.initialState.violationIds.length) {
                  if (violationsReasonsCodes.length === 1) {
                    const reason = this.reasons.find(item => item.code === result.reason.code);
                    this.form.patchValue({
                      reasonId: reason.id
                    });
                  }
                }
              });
          });
        }
      }
    });
  }

  get lastState(): any { return null; }

  async onAllSubmited(): Promise<any> {
    this.reasonId = Number(this.form.get('reasonId').value);
  }

  createForm() {
    this.form = this.formBuilder.group({
      reasonId: ['', Validators.required]
    });
  }
}
