<span *ngIf="!loading; else loadingBlock">
  {{text}}
</span>
<ng-template #loadingBlock>
  <div class="loading-spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</ng-template>
