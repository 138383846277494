import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-img-glass',
  templateUrl: './img-glass.component.html',
  styleUrls: ['./img-glass.component.sass']
})
export class ImgGlassComponent implements AfterViewInit {
  @ViewChild('img', { static: false, read: ElementRef }) img: ElementRef;
  @Input() set file(file) {
    this._file = file;
  }
  public _file: string;
  constructor() { }

  ngAfterViewInit(): void {

  }

  magnify() {
    const zoom = 3;
    let glass = null;
    const img = this.img.nativeElement;
    if(document.getElementById('img-magnifier-glass')) {
      glass = document.getElementById('img-magnifier-glass');
      document.getElementById('img-magnifier-glass').style.display = 'block';
    } else {
      glass = document.createElement('DIV');
      glass.setAttribute('id', 'img-magnifier-glass');
      glass.setAttribute('class', 'img-magnifier-glass');
    }
    img.parentElement.insertBefore(glass, img);
    glass.style.backgroundImage = `url('${img.src}')`;
    glass.style.backgroundRepeat = 'no-repeat';
    glass.style.backgroundSize = `${img.width * zoom}px ${img.height * zoom}px`;
    const bw = 3;
    const w = glass.offsetWidth / 2;
    const h = glass.offsetHeight / 2;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
    glass.addEventListener('mousemove', moveMagnifier);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
    img.addEventListener('mousemove', moveMagnifier);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
    glass.addEventListener('touchmove', moveMagnifier);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
    img.addEventListener('touchmove', moveMagnifier);
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    function moveMagnifier(e) {
      let x; let y;
      e.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
      const pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;
      }
      glass.style.left = `${x - w}px`;
      glass.style.top = `${y - h}px`;
      glass.style.backgroundPosition = `-${(x * zoom - w + bw)}px -${(y * zoom - h + bw)}px`;
    }
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    function getCursorPos(e) {
      let x = 0;
      let y = 0;
      e = e || window.event;
      const a = img.getBoundingClientRect();
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x, y };
    }
  }

}
