/* eslint-disable id-blacklist */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  AlertItem,
  AlertType,
  ComponentModal,
  Lane,
  MessageModal,
  OperationModes,
  Contract,
  Spot
} from 'src/app/core/models';
import {
  AlertService, LaneService, ModalService, SpotService,
  ContractService, StorageKey, StorageService, ContractGlobalService
} from 'src/app/core/services';
import { RestrictionZoneService } from 'src/app/core/services/restrictionZone.service';
import { ViolationConfigModalComponent } from 'src/app/pages/lanes/violation-config-modal/violation-config-modal.component';
import { BasePanel } from '../base-panel/base-panel';
import * as moment from 'moment';

@Component({
  selector: 'app-lane-panel',
  templateUrl: './lane-panel.component.html',
  styleUrls: ['./lane-panel.component.sass']
})
export class LanePanelComponent extends BasePanel implements OnInit, OnDestroy {
  @Input() public laneId: string = null;
  @ViewChild('panel', { static: true }) panel: ElementRef;
  @Input() public spot: any = {};
  @Output() public afterSave = new EventEmitter<{ laneId: string; deleted: boolean; error: Error; source: any }>();
  @Output() public removeLaneCommand = new EventEmitter<{ laneId: string; deleted: boolean; error: Error; source: any }>();
  @Output() public afterLoad = new EventEmitter<{ lane: Lane }>();
  @Input() saveLane: Observable<{ spotId: string; source: any }>;
  @Input() showError: Observable<any>;
  @Input() toggleLane: Observable<any>;
  @Input() public haveSpotFields = false;
  @Input() public haveRightMenu = false;
  @Input() public haveButtons = false;
  @Input() public haveEditOption = false;
  @Input() public haveNewLane = false;
  @Output() public formChange = new Subject<{ laneId: string; valid: boolean }>();
  @Input() set isEdit(value) {
    if (value == null) {
      value = false;
    }
    this._isEdit = value;
    this.showEditLane();
  }
  get isEdit() {
    return this._isEdit;
  }
  public lane: Lane = new Lane();
  public hasError = false;
  public laneForm: FormGroup;
  public operationModes = Object.keys(OperationModes);
  public restrictionZones: any[] = [];
  public restrictionZoneDescription = '';
  public isFormValid = false;
  public operationModesI18n = [];
  public currentOperationType = '';
  public contract = [];
  public promise: Promise<any>;
  public spotsByContracts: {[key: string]: Array<Spot>} = {};
  public contracts: Array<Contract> = [];
  public contractGlobal = null;

  public startDateValue;
  public endDateValue;
  public maxDate;
  public maxDateStart;
  public minDate;
  public laneCLientCode: string;
  public laneClientStartsAt: string;
  public laneCLientEndsAt: string;

  private _isEdit = false;
  private _subscriptions = [];

  constructor(
    private modalService: ModalService,
    private laneService: LaneService,
    private spotService: SpotService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private contractService: ContractService,
    private storageService: StorageService,
    private contractGlobalService: ContractGlobalService,
    private restrictionZoneService: RestrictionZoneService
  ) {
    super();
    if (this.contractGlobalService.contracts != null) {
      if (this.contractGlobalService.contracts.length === 1) {
        this.contractGlobal = this.contractGlobalService.contracts[0];
      }
    }
  }
  ngOnInit() {
    super.ngOnInit();
    this.setStartAndEndDate();
    this.lane.speeds = {
      regularVehicles: undefined,
      heavyVehicles: undefined,
      regulationLimits: []
    };
    if (this.laneId != null) {
      this.laneService.getById(this.laneId).then(lane => {
        this.lane = lane;
        this.afterLoad.emit({ lane });
        if (this.haveSpotFields) {
          this.spotService.getById(this.lane.spotId).then(spot => {
            this.spot = spot;
          });
        }
        this.setFormValues();
      });
    } else {
      this.toggle();
    }
    if (this.toggleLane != null) {
      this._subscriptions.push(this.toggleLane.subscribe(isShowing => {
        if (isShowing !== this.isShowing) {
          this.toggle();
        }
      }));
    }
    if (this.saveLane != null) {
      this._subscriptions.push(this.saveLane.subscribe(async event => {
        this.spot = await this.spotService.getById(event.spotId);
        this.laneForm.patchValue({ spotId: this.spot.id });
        this.submit(event.source);
      }));
    }

    const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
    const contractPermissions = currentPermissions && currentPermissions.filter(permissions => {
      if (permissions.actionIds.includes('CreateSpot')) {
        return permissions.contractId;
      }
    });
    const user = this.storageService.get(StorageKey.currentUser);
    this.contractService.getAll().then(contracts => {
      if (user.superUser) {
        this.contracts = contracts;
      } else {
        this.contracts = contracts.filter(contract => contractPermissions.find(c => c.contractId === contract.id));
      }
      this.spotService.getAll().then(spots => {
        contracts.forEach(contract => {
          const spotsByContract = spots.filter(spot => spot.contractId === contract.id);
          this.contract[contract.id] = contract;
          this.spotsByContracts[contract.id] = spotsByContract;
        });
      });
    });
    this.createForm();
  }

  setStartAndEndDate() {
    const now = new Date().toISOString();
    const startDateDefault = moment(now).utc().subtract(7, 'days');
    const endDateDefault = moment(now);
    const minDateDefault = moment(now).subtract(365, 'days');
    this.startDateValue = startDateDefault;
    this.endDateValue = endDateDefault;
    this.minDate = minDateDefault;
    this.maxDate = moment(now);
    this.maxDateStart = moment(now);
  }

  ngOnDestroy() {
    this._subscriptions.map(subscription => subscription.unsubscribe());
  }

  createForm() {
    this.setRestrictionZones();
    this.laneForm = this.formBuilder.group({
      code: [this.lane.code, Validators.required],
      number: [this.lane.number, Validators.compose([Validators.required, Validators.pattern(/^[0-9]*$/)])],
      isBetweenLane: new FormControl({ value: this.lane.isBetweenLane, disabled: !this.isEdit }),
      direction: [this.lane.direction, Validators.required],
      clientCode: [this.lane.clientCode],
      operationMode: [this.lane.operationMode || '', Validators.required],
      restrictionZones: [this.lane.restrictionZoneCode || ''],
      heavyVehicle: [this.lane && this.lane.speeds && this.lane.speeds.heavyVehicles,
        [Validators.pattern(/^[0-9]*$/), Validators.min(1)]],
      regularVehicle: [this.lane && this.lane.speeds && this.lane.speeds.regularVehicles,
        Validators.compose([Validators.required, Validators.pattern(/^[0-9]*$/), Validators.min(1)])],
      clientDescription: [this.lane.clientDescription || ''],
      spotId: [this.lane.spotId],
      contractId: [this.lane.contractId || this.contractGlobal],
      startsAt: [this.laneClientStartsAt || ''],
      endsAt: [this.laneCLientEndsAt || '']
    });
    this.laneForm.valueChanges.subscribe(() => {
      this.isFormValid = this.laneForm.valid;
      this.formChange.next({ laneId: this.laneId, valid: this.laneForm.valid });
      this.hasError = false;
    });
    if (this.spot == null) {
      const spotIdControl = this.laneForm.get('spotId');
      spotIdControl.setValidators([Validators.required]);
    }
    this.laneForm.get('contractId').valueChanges.subscribe(async (value) => {
      const contractId = this.laneForm.get('contractId').value;
      if (value !== contractId) {
        this.laneForm.patchValue({ spotId: null });
      }
      await this.contractService.getById(this.contractGlobal || contractId).then(res => {
        const now = new Date().toISOString();
        if (res.client) {
          const clientCode = res.client.find(c => c.endsAt > this.startDateValue);
          this.laneCLientCode = clientCode.code;
          this.laneClientStartsAt = clientCode.startsAt;
          this.laneCLientEndsAt = clientCode.endsAt;
          this.laneForm.patchValue({
            startsAt: [this.editDate(this.laneClientStartsAt) || ''],
            endsAt: [this.editDate(this.laneCLientEndsAt) || '']
          });
        }
      });
    });
  }

  setFormValues() {
    this.setRestrictionZones();
    const valueOperationType = this.operationModesI18n.find(operationMode => operationMode.id === this.lane.operationMode);
    if (valueOperationType != null) {
      this.currentOperationType = valueOperationType.value;
    }
    this.laneForm.patchValue({
      code: this.lane.code,
      number: this.lane.number,
      isBetweenLane: this.lane.isBetweenLane,
      direction: this.lane.direction,
      clientCode: this.lane.clientCode,
      operationMode: this.lane.operationMode || '',
      heavyVehicle: this.lane && this.lane.speeds && this.lane.speeds.heavyVehicles,
      regularVehicle: this.lane && this.lane.speeds && this.lane.speeds.regularVehicles,
      clientDescription: this.lane && this.lane.clientDescription || '',
      spotId: this.lane && this.lane.spotId,
      contractId: this.lane && this.lane.contractId || this.contractGlobal,
      restrictionZones: this.lane.restrictionZoneCode || '-',
      startsAt: [this.editDate(this.lane && this.lane.contractClientCodes &&
        this.lane.contractClientCodes[0] &&
        this.lane.contractClientCodes[0].startsAt) || ''],
      endsAt: [this.editDate(this.lane &&
        this.lane.contractClientCodes &&
        this.lane.contractClientCodes[0] &&
        this.lane.contractClientCodes[0].endsAt) || '']
    });
  }

  async submit(source: any = null) {
    if (this.promise != null) {
      return this.promise;
    }
    if (this.laneForm.pristine) {
      if (this.saveLane == null) {
        this.isEdit = false;
        this.alertService.show(new AlertItem('LaneSaved', AlertType.success));
      }
      this.afterSave.emit({ laneId: this.lane.id, deleted: false, error: null, source });
      return;
    }
    const lane = Object.assign(new Lane(), this.lane);
    lane.code = this.laneForm.get('code').value;
    const clientCode = this.laneForm.get('clientCode').value || undefined;
    if (clientCode == null) {
      delete lane.clientCode;
    } else {
      lane.clientCode = clientCode;
    }
    lane.number = this.laneForm.get('number').value && parseInt(this.laneForm.get('number').value, 10);
    lane.isBetweenLane = this.laneForm.get('isBetweenLane').value;
    if (lane.speeds == null) {
      lane.speeds = {
        regularVehicles: null,
        heavyVehicles: null,
        regulationLimits: []
      };
    }
    const heavyVehicles = this.laneForm.get('heavyVehicle').value || undefined;
    if (heavyVehicles != null) {
      lane.speeds.heavyVehicles = parseInt(heavyVehicles, 10);
    } else if (lane.speeds != null) {
      delete lane.speeds.heavyVehicles;
    }
    const regularVehicles = parseInt(this.laneForm.get('regularVehicle').value, 10) || undefined;
    if (regularVehicles != null) {
      lane.speeds.regularVehicles = regularVehicles;
    } else if (lane.speeds != null) {
      delete lane.speeds.regularVehicles;
    }
    if (lane.enabledViolations == null) {
      lane.enabledViolations = [];
    }
    lane.direction = this.laneForm.get('direction').value;
    lane.contractId = this.spot.contractId || this.laneForm.get('contractId').value;
    lane.spotId = this.laneForm.get('spotId').value;
    this.spotService.getById(lane.spotId).then(spot => {
      this.spot = spot;
    });
    lane.operationMode = this.laneForm.get('operationMode').value;
    lane.restrictionZoneCode = this.laneForm.get('restrictionZones').value;
    lane.clientDescription = this.laneForm.get('clientDescription').value || '';
    if (this.laneForm.get('startsAt').value !== '' && this.laneForm.get('endsAt').value !== '') {
      if (!Array.isArray(this.laneForm.get('startsAt').value) && !Array.isArray(this.laneForm.get('endsAt').value)) {
        lane.contractClientCodes = [{
          code: await this.getClientCode(lane.contractId),
          startsAt: moment(new Date(this.laneForm.get('startsAt').value).toISOString()).utc(),
          endsAt: moment(new Date(this.laneForm.get('endsAt').value).toISOString()).utc()
        }];
      } else {
        lane.contractClientCodes = [];
      }
    }else {
      lane.contractClientCodes = [];
    }
    lane.modifiedAt = this.lane.modifiedAt;
    if (this.lane.id != null) {
      lane.id = this.lane.id;
      this.promise = this.laneService.update(lane)
        .then(res => {
          this.setFormValues();
          if (lane.spotId !== this.lane.spotId) {
            this.storageService.remove(StorageKey.spotServiceCacheAll);
          }
          if (this.saveLane == null) {
            this.isEdit = false;
            this.alertService.show(new AlertItem('LaneSaved', AlertType.success));
          } else {
            this.afterSave.emit({ laneId: this.lane.id, deleted: false, error: null, source });
          }
        }).catch(error => {
          this.laneForm.controls.code.setErrors({ incorrect: true });
          this.hasError = true;
          this.afterSave.emit({ laneId: this.lane.id, deleted: false, error, source });
          if (error.status === 409) {
            this.laneForm.get('code').setErrors({ incorrect: true });
            this.alertService.show(new AlertItem('LaneSaveConflict', AlertType.danger));
          } else {
            this.alertService.show(new AlertItem('LaneSaveError', AlertType.danger));
          }
          throw error;
        }).finally(() => {
          this.promise = null;
        });
    } else {
      lane.equipmentIds = [];
      this.promise = this.laneService.create(lane)
        .then(res => {
          this.lane = res;
          this.afterSave.emit({ laneId: this.lane.id, deleted: false, error: null, source });
          this.haveNewLane = false;
          this.isEdit = false;
          if (lane.spotId !== this.lane.spotId) {
            this.storageService.remove(StorageKey.spotServiceCacheAll);
          }
          this.alertService.show(new AlertItem('LaneSaved', AlertType.success));
        }).catch(error => {
          this.hasError = true;
          this.afterSave.emit({ laneId: this.lane.id, deleted: false, error, source });
          if (error.status === 409) {
            this.laneForm.get('code').setErrors({ incorrect: true });
            this.alertService.show(new AlertItem('LaneSaveConflict', AlertType.danger));
          } else {
            this.alertService.show(new AlertItem('LaneSaveError', AlertType.danger));
          }
        }).finally(() => {
          this.promise = null;
        });
    }
  }

  showEditLane() {
    if (this.laneForm == null) {
      return;
    }
    this.setFormValues();
    if (!this.isShowing) {
      this.toggle();
    }
    if (this.isEdit || this.haveNewLane) {
      this.laneForm.get('isBetweenLane').enable();
    } else {
      this.laneForm.get('isBetweenLane').disable();
    }
  }

  cancel() {
    if (this.promise != null) {
      return this.promise;
    }
    this.isEdit = false;
    if (this.haveNewLane) {
      this.removeLaneEvent();
      this.haveNewLane = false;
    }
    this.setFormValues();
    this.laneForm.get('isBetweenLane').disable();
  }

  removeLaneEvent() {
    if (this.saveLane != null || this.haveNewLane) {
      this.removeLaneCommand.emit({ laneId: this.laneId, deleted: true, error: null, source: null });
    }
  }

  delete() {
    this.laneService.delete(this.lane.id)
      .then(res => {
        this.afterSave.emit({ laneId: this.laneId, deleted: true, error: null, source: null });
        this.alertService.show(new AlertItem('LaneDeleted', AlertType.success));
        return res;
      })
      .catch(error => {
        this.alertService.show(new AlertItem('LaneDeleteError', AlertType.danger));
      });
  }

  openDeleteModal() {
    this.modalService.show(new MessageModal('Remover Faixa', 'Deseja remover esta faixa?', true))
      .then(() => {
        this.delete();
      }).catch(err => { });
  }

  openViolationConfigModal() {
    this.modalService.show(new ComponentModal(ViolationConfigModalComponent, this.lane))
      .then(data => {
        const modal = data as any;
        const savedLane = modal && modal.component.instance &&
          modal.component.instance.lane;

        this.lane = savedLane;
      })
      .catch(err => { });
  }

  setOperationModesI18n(value) {
    value.map(v => {
      switch (v.id) {
        case 'enabled':
          v.value = 'Ativa';
        break;
        case 'disabled':
          v.value = 'Inativa';
        break;
        case 'test':
          v.value = 'Em Teste';
        break;
      }
    });
    this.operationModesI18n = value;
    if (value != null) {
      const valueOperationType = this.operationModesI18n.find(operationMode => operationMode.id === this.lane.operationMode);
      if (valueOperationType != null) {
        this.currentOperationType = valueOperationType?.value;
      }
    }
  }

  async setRestrictionZones() {
    await this.restrictionZoneService.getAll().then(res => {
      this.restrictionZones = res;
      if (this.lane.restrictionZoneCode !== undefined) {
        const restrictionZone = res.find(zone => zone.code === this.lane.restrictionZoneCode);
        this.restrictionZoneDescription = restrictionZone?.description;
      }
    });
  }

  getDate(dateTime) {
    if (dateTime.indexOf('T') !== -1) {
      const date = dateTime.split('T')[0];
      return moment(date).utc().format('DD/MM/YYYY');
    }else {
      return '--/--/----';
    }
  }

  editDate(dateTime) {
    if (dateTime && dateTime.indexOf('T') !== -1) {
      const date = dateTime.split('T')[0];
      return date;
    }
  }

  async getClientCode(contractId) {
    let clientCode = '';
    await this.contractService.getById(contractId).then(res => {
      if (res.client) {
        clientCode = res.client.find(c => c.endsAt > this.startDateValue).code;
      }
    });
    return clientCode;
  }
}
