import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Quarantine, QuarantineStatus } from 'src/app/core/models/Quarantine';
import { NavigationExtras, Router } from '@angular/router';
import { ContractService, SpotService } from 'src/app/core/services';
import { Spot } from 'src/app/core/models';

@Component({
  selector: 'app-quarantine-header',
  templateUrl: './quarantine-header.component.html',
  styleUrls: ['./quarantine-header.component.sass']
})
export class QuarantineHeaderComponent implements OnInit {
  @Input() quarantine: Quarantine;
  @Input() layer;
  @Output() outLayer = new EventEmitter();
  public calibrationSteps = ['invalidAppraisalNumber', 'missingAppraisalDocument',
    'invalidAppraisalDate', 'expiredAppraisal'];
  public regulationSteps = [
    'invalidWeekDay',
    'disabledRegulation',
    'invalidDayPeriod',
    'invalidVehicleClassification',
    'invalidRegulationPeriod'
  ];
  public laneSteps = ['disabledLane', 'testingLane', 'invalidLaneNumber'];
  public equipmentSteps = ['disabledEquipment', 'invalidEquipmentSerialNumber'];
  public speedSteps = ['invalidSpeed', 'oddSpeed'];

  public columns = [
    'contract',
    'spot',
    'lane',
    'total'
  ];
  public columnsToShow = [];
  public quarantineStatusI18n = [];
  public quarantineStatus = Object.values(QuarantineStatus);
  public spotById: { [params: string]: Spot } = null;
  public spotCode = null;
  public contractCode = null;

  constructor(
    public router: Router,
    private spotService: SpotService,
    private contractService: ContractService
  ) { }

  ngOnInit() {
  }

  getStatusQuarantine(typQuarantine) {
    if (this.quarantineStatusI18n.length === 0 || typQuarantine == null || typQuarantine === '') {
      return;
    }
    return this.quarantineStatusI18n.find(q => q.id === typQuarantine)?.value;
  }

  getCode() {
    if (this.spotCode == null || this.contractCode == null) {
      this.spotService.getById(this.quarantine.spotId).then(spot => {
        this.spotCode = spot.code;
      });
      if (this.contractCode == null) {
        this.contractService.getById(this.quarantine.contractId).then(contract => {
          this.contractCode = contract.code;
        });
      }
    }
  }

  back() {
    if (localStorage.getItem('lastLayer') && localStorage.getItem('lastQuarantineId')) {
      localStorage.removeItem('lastLayer');
      localStorage.removeItem('lastQuarantineId');
    }
    if (this.layer === 'wrongRegulation' || this.layer === 'calibrationFailure' ||
    this.layer === 'laneFailure' || this.layer === 'equipmentFailure' || this.layer === 'speedFailure') {
      this.outLayer.emit('root');
    } else {
      const step = this.getQuarantineStep();
      const navigationExtras: NavigationExtras = {
        queryParams: {
          module: step,
          quarantineId: this.quarantine.id
        }
      };
      this.router.navigate([`pre-process/quarantine/${this.quarantine.contractId}`], navigationExtras);
    }
  }

  getQuarantineStep() {
    if (this.calibrationSteps.includes(this.layer)) {
      return 'calibrationFailure';
    } else if (this.regulationSteps.includes(this.layer)) {
      return 'wrongRegulation';
    } else if (this.laneSteps.includes(this.layer)) {
      return 'laneFailure';
    } else if (this.equipmentSteps.includes(this.layer)) {
      return 'equipmentFailure';
    } else if (this.speedSteps.includes(this.layer)) {
      return 'speedFailure';
    }
  }

  sumTotalQuarantines() {
    let total = 0;
    let quarantinesToSum = [];
    if (this.quarantine != null && this.quarantine.report != null) {
      this.getCode();
      switch (this.layer) {
        case 'wrongRegulation':
          quarantinesToSum = this.regulationSteps;
          break;
        case 'invalidRegulationPeriod':
          quarantinesToSum = ['invalidRegulationPeriod'];
          break;
        case 'invalidVehicleClassification':
          quarantinesToSum = ['invalidVehicleClassification'];
          break;
        case 'disabledRegulation':
          quarantinesToSum = ['disabledRegulation'];
          break;
        case 'invalidWeekDay':
          quarantinesToSum = ['invalidWeekDay'];
          break;
        case 'invalidDayPeriod':
          quarantinesToSum = ['invalidDayPeriod'];
          break;
        case 'expiredAppraisal':
          quarantinesToSum = ['expiredAppraisal'];
          break;
        case 'missingAppraisalDocument':
          quarantinesToSum = ['missingAppraisalDocument'];
          break;
        case 'invalidAppraisalNumber':
          quarantinesToSum = ['invalidAppraisalNumber'];
          break;
        case 'invalidAppraisalDate':
          quarantinesToSum = ['invalidAppraisalDate'];
          break;
        case 'calibrationFailure':
          quarantinesToSum = this.calibrationSteps;
          break;
        case 'waitingFiles':
          quarantinesToSum = ['waitingFiles'];
          break;
        case 'testingLane':
          quarantinesToSum = ['testingLane'];
          break;
        case 'spotMissing':
          quarantinesToSum = ['spotMissing'];
          break;
        case 'disabledLane':
          quarantinesToSum = ['disabledLane'];
          break;
        case 'invalidSpeed':
          quarantinesToSum = ['invalidSpeed'];
          break;
        case 'oddSpeed':
          quarantinesToSum = ['oddSpeed'];
          break;
        case 'whiteList':
          quarantinesToSum = ['whiteList'];
          break;
        case 'exemptPeriod':
          quarantinesToSum = ['exemptPeriod'];
          break;
        case 'invalidLaneNumber':
          quarantinesToSum = ['invalidLaneNumber'];
          break;
        case 'invalidEquipmentSerialNumber':
          quarantinesToSum = ['invalidEquipmentSerialNumber'];
          break;
        case 'disabledEquipment':
          quarantinesToSum = ['disabledEquipment'];
          break;
        case 'laneFailure':
          quarantinesToSum = this.laneSteps;
          break;
        case 'invalidRegulationPeriod':
          quarantinesToSum = ['invalidRegulationPeriod'];
          break;
        case 'violationLimit':
          quarantinesToSum = ['violationLimit'];
          break;
          case 'missingTarge':
            quarantinesToSum = ['missingTarge'];
            break;
        case 'equipmentFailure':
          quarantinesToSum = this.equipmentSteps;
          break;
        case 'speedFailure':
          quarantinesToSum = this.speedSteps;
          break;
      }
    }
    for (const key of quarantinesToSum) {
      if (this.quarantine.report[key] != null) {
        total += this.quarantine.report[key] || 0;
      }
    }
    return total;
  }
}
