<h3 i18n="@@Login" class="mb-3">Acesse sua conta</h3>
<form [formGroup]="loginForm" (submit)="login()" autocomplete="on">

  <div class="form-group">
    <label i18n>E-mail:</label>
    <input type="email" ng-model="email" formControlName="email" name="email" class="form-control" autocomplete="on"
      required>
    <div *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)"
      class="text-danger align-text-required">
      <small *ngIf="loginForm.controls['email'].errors.required" i18n>Este campo é requerido.</small>
    </div>
  </div>

  <div class="row d-flex align-items-center">
    <div class="col form-group">
      <label i18n>Senha:</label>
      <app-input i18n-label="@@LoginLabelPassword" class="mt-3" type="password" formControlName="password"
        (click)="disableError()"></app-input>
    </div>

    <div class="col mb-2">
      <a class="link-forgot" i18n title="Clique aqui para recuperação de senha"
        [routerLink]="['/forgot-password']" i18n>Esqueceu sua senha?</a>
    </div>
  </div>

  <div class="form-group">
    <mat-checkbox i18n formControlName="rememberMe" color="primary">Manter-me conectado</mat-checkbox>
  </div>

  <div class="mt-5">
    <button type="submit" class="btn btn-primary btn-block btn-lg" [disabled]="loginForm.invalid">
      <span *ngIf="!loading; else Loading" i18n>Acessar conta</span>

      <ng-template #Loading>
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </ng-template>
    </button>
  </div>
</form>
