<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 i18n class="modal-title">Detalhe do Lote</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <div class="table-responsive table-hover">
        <app-enum-i18n [input]="columns" (output)="columnsI18n=$event"></app-enum-i18n>
        <table class="table bg-white">
          <thead>
            <tr>
              <th class="no-border"></th>
              <th class="no-border text-center" *ngFor="let column of tableColumns" i18n>{{ column.stepName }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td i18n class="no-border"><strong>Data da infração mais antiga</strong></td>
              <td *ngFor="let column of tableColumns" class="text-center">
                <span *ngIf="column.dateOldest; else noDate">{{ column.dateOldest | moment: 'l' }}</span>
                <ng-template #noDate>---</ng-template>
              </td>
            </tr>
            <tr>
              <td i18n class="no-border"><strong>Data da infração mais recente</strong></td>
              <td *ngFor="let column of tableColumns" class="text-center">
                <span *ngIf="column.dateNewest; else noDate">{{ column.dateNewest | moment: 'l' }}</span>
                <ng-template #noDate>---</ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
