<div class="card-header">
  <h5 i18n class="my-2 font-weight-bold">Lote Manual</h5>
</div>

<div class="card-body">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col">
        <app-input i18n-label i18n-toolTipMsg type="text" label="Padrão de Nome" formControlName="name"
          toolTipMsg="Expressão regular que define o padrão do nome do lote" inputRequired=true>
        </app-input>
      </div>
      <div class="col">
        <app-input i18n-label i18n-toolTipMsg type="text" formControlName="path" label="Padrão de Caminho"
          inputRequired=true
          toolTipMsg="Expressão regular que define o padrão de caminho a serem disponibilizados os lotes gerados">
        </app-input>
      </div>
    </div>
  </form>
</div>
