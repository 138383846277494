/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import { BaseApiModel } from './BaseApiModel';

export class ImportFiles extends BaseApiModel {
  public contractId: string;
  public mode: Modes;
  public type: TypesImportFiles;
  public fileName: string;
  public fileSize: string;
  public status: Status;
  public uploadUrl: any;
  public createdAt: Date;
  public file: any;
}

export enum Status {
  uploading = 'uploading',
  processing = 'processing',
  completed = 'completed',
  error = 'error'
}

export enum Modes {
  override = 'override',
  incremental = 'incremental'
}

export enum TypesImportFiles {
  vehicle = 'vehicle',
  vehicleWhiteList = 'vehicleWhiteList',
  audit = 'audit'
}
