<div class="modal-dialog modal-dialog-centered modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Adicionar usuário</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="modal-filter-search">
      <form [formGroup]="searchForm">
        <input type="text" class="form-control form-search" formControlName="search"
          placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">
      </form>
    </div>

    <div class="modal-table">
      <form [formGroup]="checkedUsersForm">
        <div class="list-group" #container>
          <div class="list-group-item list-group-item-action d-flex align-items-center" *ngFor="let user of users">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" type="checkbox" id="{{user.id}}" [formControlName]="user.id">
              <label class="custom-control-label" for="{{user.id}}"></label>
            </div>

            <div [ngClass]="{listUserOpacity: user.checked}" class="ml-2 mr-3">
              <app-avatar [user]='user' class="avatar-header"></app-avatar>
            </div>

            <div [ngClass]="{listUserOpacity: user.checked}">
              <h5 class="mb-0">{{user.name}}</h5>
              <small class="text-secondary">{{user.email}}</small>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="loading-page">
      <div class="mt-3">
        <app-infinite-scroll (items)="setUsers($event)" [params]="params" [service]="userService"
        [container]="container"></app-infinite-scroll>
      </div>
    </div>
    <div class="modal-footer justify-content-end">
      <div>
        <button (click)="onCancel()" class="btn btn-outline-secondary mr-2">
          <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
        </button>

        <button (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">
          <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
        </button>
      </div>
    </div>
  </div>
</div>
