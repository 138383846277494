<div (click)='click($event)'>
  <div class="small-header">
    <div class="small-header__menu-handle" [ngClass]="{'menu-handle--show': isMenuOpen}" (click)="toogleMenu()" #menuButton>
      <i class="handle"></i>
    </div>

    <div class="small-header__content">
      <nav class="small-header__breadcrumb">
        <ul>
          <li class="align-breadcrumb" aria-current="page">
            <app-breadcrumbs [levels]="breadcrumbs"></app-breadcrumbs>
          </li>
        </ul>
      </nav>
      <div class="small-header__account">
        <div class="dropdown">
          <a href="#" class="dropdown-toggle" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" i18n>Olá, {{user.name}}</a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
            <a class="dropdown-item" [routerLink]="['/users/myProfile']" routerLinkActive="router-link-active" i18n>
              Meu perfil</a>
            <a class="dropdown-item" (click)="logout()" i18n>Sair</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>

<app-go-top></app-go-top>
<app-menu [(isOpen)]="isMenuOpen" #menu></app-menu>
