import { Modal } from './Modal';
import { ModalContent } from 'src/app/core/interface';
import { ComponentRef } from '@angular/core';

export class ComponentModal extends Modal {
    public component: ComponentRef<ModalContent>;

    constructor(
        public type,
        public initialState = null
    ) {
        super();
    }
    public setComponent(value) {
        this.component = value;
    }
}
