<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>{{ modalTitle }}</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="modal-filter-search">
      <input type="text" class="form-control form-search" [(ngModel)]="searchValue"
             placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off" i18n-placeholder>
    </div>

    <div class="modal-table">
      <div class="col-12" *ngIf="!loaded; else loadForm">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>

      <ng-template #loadForm>
        <div class="list-group" *ngIf="type !== 'radio'">
          <div class="list-group-item list-group-item-action d-flex align-items-center list-group-item--select">
            <mat-checkbox color="primary" (change)="setAll($event.checked)" [checked]="allComplete">
              <span *ngIf="!allChecked; else noSelected" i18n>Selecionar todos</span>
              <ng-template #noSelected>
                <span i18n>Remover todos</span>
              </ng-template>
            </mat-checkbox>
          </div>
        </div>

        <form [formGroup]="form">
          <div class="list-group list-group--results" *ngIf="type !== 'radio'">
            <div class="list-group-item list-group-item-action"
                 *ngFor="let item of list"
                 [ngClass]="item.filtered ? 'list-group-item--show' : 'list-group-item--hide'">

                <mat-checkbox i18n [formControlName]="item[selectValue]"
                  (change)="checkAll()" color="primary">
                  <ng-container *ngIf="item[selectText] && item[selectText].length <= 70">{{ item[selectText] }}</ng-container>
                  <ng-container *ngIf="item[selectText] && item[selectText].length >= 71">
                    <span i18n-appTooltip [appTooltip]="item[selectText] && item[selectText]">{{ item[selectText] | slice:0:70 }}...</span>
                  </ng-container>
                </mat-checkbox>
            </div>
          </div>

          <div class="list-group list-group--results" *ngIf="type === 'radio'">
            <mat-radio-group
              formControlName="selectRadioValues"
              color="primary"
              aria-label="Select an option">
              <mat-radio-button *ngFor="let item of list" [value]="item.id" i18n
                class="list-group-item list-group-item-action">{{ (item[selectText] && item[selectText]) || '' }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </form>
      </ng-template>
    </div>

    <div class="modal-footer justify-content-end">
      <button (click)="onCancel()" class="btn btn-outline-secondary mr-2" i18n>Cancelar</button>
      <button (click)="onSubmit()" [ngClass]="{ btn: true, 'btn-primary': true, disabled: !isValid }" i18n>Selecionar</button>
    </div>

  </div>
</div>
