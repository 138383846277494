import { BaseApiModel } from './BaseApiModel';

export class BrandInternal extends BaseApiModel {
  public code: string;
  public description: string;
  public contractId: number;

  get prettyName() {
    return `${this.code} - ${(this.description)}`;
  }
}
