import { Component, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';

@Component({
  selector: 'app-go-top',
  templateUrl: './go-top.component.html',
  styleUrls: ['./go-top.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GoTopComponent implements OnInit {
  windowIsScrolled: boolean;

  constructor() {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset > 100) {
         this.windowIsScrolled = true;
    } else {
       this.windowIsScrolled = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit() {}

}
