import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';

import { ModalService } from 'src/app/core/services';
import { ComponentModal } from 'src/app/core/models';
import { ViolationReportService } from 'src/app/core/services';
import { SelectPeriodComponent } from 'src/app/modals/select-period/select-period.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-violations-report-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.sass']
})
export class ViolationsReportPeriodComponent implements OnInit, OnDestroy {
  public modalPeriodSelected: any = {};
  public contractViolationsSub: Subscription;
  public startDate;
  public endDate;
  public timezone;

  constructor(
    private modalService: ModalService,
    private violationReportService: ViolationReportService
  ) { }

  ngOnInit() {
    const now = new Date().toISOString();
    this.startDate = moment(now).subtract(7, 'days').utc().format();
    this.endDate = moment(now).utc().format();

    this.contractViolationsSub = this.violationReportService.getContractDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.timezone = data.timezone;
      });
  }

  async selectModalPeriod() {
    const now = new Date().toISOString();
    await this.modalService.show(new ComponentModal(SelectPeriodComponent, {
      startDate: this.startDate,
      endDate: this.endDate,
      rangeDates: [[7, 'days'], [15, 'days'], [1, 'month'], [2, 'month']],
      minDate: moment(now).subtract(60, 'days')
    }))
      .catch(() => { })
      .then(data => {
        if (data == null) {
          return;
        }
        const modal = data as any;
        this.modalPeriodSelected = modal && modal.component
          && modal.component.instance
          && modal.component.instance.periodSelected;

        this.emitNewParams();
      });
  }

  emitNewParams() {
    this.startDate = moment(this.modalPeriodSelected.startDate).tz(this.timezone).format();
    this.endDate = moment(this.modalPeriodSelected.endDate).tz(this.timezone).format();

    const periodParams = {
      startDate: this.startDate,
      endDate: this.endDate
    };

    localStorage.setItem('periodViolationReport', JSON.stringify({
      startDate: this.modalPeriodSelected.startDate,
      endDate: this.modalPeriodSelected.endDate
    }));
    this.violationReportService.emitPeriodViolationReport(periodParams);
  }

  ngOnDestroy() {
    this.violationReportService.emitPeriodViolationReport({});
    this.contractViolationsSub.unsubscribe();
  }

}
