import { Component, OnInit, ViewChild, Input, HostListener } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService, StorageService, StorageKey, UserService, AlertService } from 'src/app/core/services';
import { User, Violation, AlertItem, AlertType } from 'src/app/core/models';
import { Subject } from 'rxjs';
import { ViolationService } from 'src/app/core/services/violation.service';

@Component({
  selector: 'app-comment-panel',
  templateUrl: './comment-panel.component.html',
  styleUrls: ['./comment-panel.component.sass']
})
export class CommentPanelComponent extends BaseModal implements OnInit {
  @ViewChild('comment', { static: true }) comment;
  @Input() violation: Violation;
  notes = [];
  noteForm: FormGroup;
  public user: any = {};
  public userById: { [params: string]: User } = {};
  public heigth = 0;
  public scrollDown = 0;
  textAreaTyping: Subject<string> = new Subject<string>();
  limitWordsTyped = 450;
  amountWordsTyped = 0;

  get isValid() {
    return this.noteForm.valid;
  }

  constructor(
    modalService: ModalService,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private userService: UserService,
    private violationService: ViolationService,
    private alertService: AlertService
  ) {
    super(modalService);
    this.textAreaTyping.subscribe(() => {
      this.amountWordsTyped = this.limitWordsTyped - this.noteForm.get('comment').value.length;
    });
  }

  searchKey(text: string) {
    this.textAreaTyping.next(text);
  }
  createForm(): void {
    this.amountWordsTyped = this.limitWordsTyped;
    this.noteForm = this.formBuilder.group({
      comment: ['', [Validators.required, Validators.maxLength(this.limitWordsTyped)]]
    });
  }

  setScrollHeigth() {
    this.scrollDown = this.comment.nativeElement.scrollHeight;
  }

  ngOnInit() {
    this.violation = this.initialState as Violation || new Violation();
    this.setScrollHeigth();

    this.createForm();
    this.user = this.storageService.get(StorageKey.currentUser);
    if (this.violation.notes != null && this.violation.notes.length > 0) {
      this.violation.notes.forEach(note => {
        this.updateNotes(note);
      });
    }
    if (this.violation.notes == null) {
      this.violation.notes = [];
    }
  }

  onAllSubmited(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  saveNotes() {
    const newComment = this.noteForm.get('comment').value;
    const newNote = {
      comment: newComment,
      dateTime: new Date(),
      userId: this.user.id
    };
    this.violation.notes.push(newNote);
    this.violationService.update(this.violation)
      .then(res => {
        this.alertService.show(new AlertItem('ViolationSaved', AlertType.success));
      })
      .catch(error => {
        this.alertService.show(new AlertItem('ViolationSaveError', AlertType.danger));
      });
    this.updateNotes(newNote);
  }

  updateNotes(newComment) {
    this.userService.getById(newComment.userId).then(async res => {
      if (this.userById[newComment.userId] == null) {
        this.userById[newComment.userId] = res;
      }
      this.notes.push(newComment);
    });
    this.createForm();
    this.setScrollHeigth();
  }

  get lastState(): any {
    return null;
  }
}
