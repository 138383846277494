import { BaseApiModel } from './BaseApiModel';

export class BrandModel extends BaseApiModel {
  public code: string;
  public brandModel: string;
  public brandModelCode: string;
  public description: string;
  public regionId: string;
  public createdAt: string;
  public modifiedAt: string;
  public deletedAt: string;
}
