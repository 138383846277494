import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company, User, UserGroup } from 'src/app/core/models';
import { AlertItem, AlertType } from 'src/app/core/models/AlertItem';
import {
  AlertService, CompanyService, ModalService,
  UserGroupService, UserService, StorageService, StorageKey, ContractService, ContractGlobalService
} from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { updateLocalStorage } from 'src/app/core/utils/updateLocalStorage';
import { AvatarService } from 'src/app/core/services/avatar.service';
import { validateCpf } from 'src/app/core/utils/validateCpf';


@Component({
  selector: 'app-users-edit-modal',
  templateUrl: './users-edit-modal.component.html',
  styleUrls: ['./users-edit-modal.component.sass']
})
export class UsersEditModalComponent extends BaseModal implements OnInit {
  @Input() initialState;
  public userEditForm: FormGroup;
  public userGroups: Array<UserGroup>;
  public userGroupPrettyName: { [params: string]: any } = {};
  public companies: Array<Company> = [];
  public userGroupsDropdownSettings = {};
  public companiesDropdownSettings = {};
  public isValid = false;
  public user: any = {};
  public currentUser: User;
  public promise: Promise<any>;

  constructor(
    private formBuilder: FormBuilder,
    private userGroupService: UserGroupService,
    private companyService: CompanyService,
    private userService: UserService,
    private alertService: AlertService,
    private storageService: StorageService,
    private contractService: ContractService,
    private contractsGlobalService: ContractGlobalService,
    modalService: ModalService,
    private avatarService: AvatarService
  ) {
    super(modalService);
  }

  ngOnInit() {
    if (this.initialState != null) {
      this.user = this.initialState;
    }
    this.editForm();
    this.currentUser = this.storageService.get(StorageKey.currentUser);

    this.companyService.getAll().then(list => {
      this.companies = list;
      this.loadUserGroups(this.user.companyId);
    });
    if (this.initialState != null) {
      const keys = Object.keys(this.initialState);
      for (const key of keys) {
        const form = this.userEditForm.get(key);
        if (form) {
          form.setValue(this.initialState[key]);
        }
      }
    }
  }

  get lastState() {
    const state = {};
    const keys = Object.keys(this.userEditForm.controls);
    for (const key of keys) {
      state[key] = this.userEditForm.get(key).value;
    }
    return state;
  }

  editForm() {
    this.userEditForm = this.formBuilder.group({
      name: [''],
      cpf: [''],
      email: ['', [Validators.required, Validators.email]],
      companyId: ['', [Validators.required]],
      avatar: [''],
      superUser: [false],
      role: [''],
      registryNumber: [''],
      groupIds: [[]]
    });
    if (this.user.verified) {
      this.userEditForm.get('name').setValidators([Validators.required, Validators.pattern('^([A-Za-zÀ-ÿ.]+( )?)+$')]);
    }
    this.userEditForm.valueChanges.subscribe(() => {
      this.isValid = this.userEditForm.valid;
    });
  }

  public async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }

    const cpf = this.userEditForm.get('cpf').value;
    if(cpf && !validateCpf(cpf)){
      return this.alertService.show(new AlertItem('InvalidCpf', AlertType.danger));
    }

    const user = {
      cpf: cpf.replace(/\D+/g, ''),
      id: this.user.id,
      name: this.userEditForm.get('name').value,
      email: this.userEditForm.get('email').value,
      registryNumber: this.userEditForm.get('registryNumber').value,
      companyId: this.userEditForm.get('companyId').value,
      role: this.userEditForm.get('role').value,
      avatar: this.userEditForm.get('avatar').value,
      superUser: this.userEditForm.get('superUser').value,
      groupIds: this.userEditForm.get('groupIds').value,
      verified: this.user.verified ? true : false,
      modifiedAt: this.user.modifiedAt
    };
    if (user.name == null) {
      delete user.name;
    }
    this.promise = this.userService.update(User.create(user)).then(res => {
      this.user = res;
      if (this.user.id === this.currentUser.id) {
        this.userGroupService.getAll().then(data => {
          updateLocalStorage(this.user, this.storageService, data);
          this.avatarService.updateMyProfile(res);
        });
      }

      this.alertService.show(new AlertItem('UserSaved', AlertType.success));
      return res;
    }).catch(error => {
      this.alertService.show(new AlertItem('UserSaveError', AlertType.danger));
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }

  onClose() {
    this.userEditForm.reset();
    this.modalService.close();
  }

  loadUserGroups(companyId: string) {
    if (this.currentUser.superUser) {
      const filterObj = this.getFilter(companyId);
      if (filterObj == null) {
        return;
      }
      this.userGroupService.getAll(filterObj).then(userGroups => {
        if(this.contractsGlobalService.contracts?.length > 0){
          userGroups = userGroups.filter(
              ug => this.contractsGlobalService.contracts.some(ct => ct === ug.contractId)
          );
        }
        this.userGroups = userGroups;
      });
    } else {
      this.user.groupIds.forEach(groupId => {
        this.userGroupService.getById(groupId).then(async userGroup => {
          if (this.userGroupPrettyName[userGroup.id] == null) {
            const contract = await this.contractService.getById(userGroup.contractId);
            this.userGroupPrettyName[userGroup.id] = userGroup.name + ` (${contract.code} - ${contract.name})`;
          }
        });
      });
    }
  }

  getFilter(companyId: string) {
    if (companyId == null) {
      return null;
    }
    const company = this.companies.find(c => c.id === companyId);
    if (company == null) {
      return null;
    }
    const contractIds = company.contractIds;
    return {
      'contractId[in]': `[${contractIds.join(',')}]`
    };
  }
}
