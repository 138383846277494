/* eslint-disable no-use-before-define */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { }

  set(key: StorageKey | string, value: any) {
    if (this.cacheKeep.includes(key)) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  get(key: StorageKey | string) {
    if (this.cacheKeep.includes(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
  }

  remove(key: StorageKey | string) {
    localStorage.removeItem(key);
  }

  get cacheKeep(): string[] {
    return [
      'currentUser',
      'currentContracts',
      'currentPermissions',
      'refreshToken',
      'accessToken',
      'filterTarget',
      'currentContractFilter',
      'preProcessSettingsCache',
      'cities',
      'lastReleaseNote',
    ];
  }
}

// eslint-disable-next-line no-shadow
export enum StorageKey {
  currentUser = 'currentUser',
  currentContracts = 'currentContracts',
  currentPermissions = 'currentPermissions',
  refreshToken = 'refreshToken',
  accessToken = 'accessToken',
  filterTarget = 'filterTarget',
  currentContractFilter = 'currentContractFilter',
  preProcessSettingsCache = 'preProcessSettingsCache',
  cities = 'cities',
  lastReleaseNote = 'lastReleaseNote',

  // service cache
  brandModelServiceCache = 'brandModelServiceCache',
  brandModelServiceCacheDate = 'brandModelServiceCacheDate',
  brandModelServiceCacheAll = 'brandModelServiceCacheAll',
  brandModelServiceCacheDateAll = 'brandModelServiceCacheDateAll',
  calibrationServiceCache = 'calibrationServiceCache',
  calibrationServiceCacheDate = 'calibrationServiceCacheDate',
  calibrationServiceCacheAll = 'calibrationServiceCacheAll',
  calibrationServiceCacheDateAll = 'calibrationServiceCacheDateAll',
  categoryServiceCache = 'categoryServiceCache',
  categoryServiceCacheDate = 'categoryServiceCacheDate',
  categoryServiceCacheAll = 'categoryServiceCacheAll',
  categoryServiceCacheDateAll = 'categoryServiceCacheDateAll',
  cityServiceCache = 'cityServiceCache',
  cityServiceCacheDate = 'cityServiceCacheDate',
  cityServiceCacheAll = 'cityServiceCacheAll',
  cityServiceCacheDateAll = 'cityServiceCacheDateAll',
  classificationServiceCache = 'classificationServiceCache',
  classificationServiceCacheDate = 'classificationServiceCacheDate',
  classificationServiceCacheAll = 'classificationServiceCacheAll',
  classificationServiceCacheDateAll = 'classificationServiceCacheDateAll',
  colorServiceCache = 'colorServiceCache',
  colorServiceCacheDate = 'colorServiceCacheDate',
  colorServiceCacheAll = 'colorServiceCacheAll',
  colorServiceCacheDateAll = 'colorServiceCacheDateAll',
  companyServiceCache = 'companyServiceCache',
  companyServiceCacheDate = 'companyServiceCacheDate',
  companyServiceCacheAll = 'companyServiceCacheAll',
  companyServiceCacheDateAll = 'companyServiceCacheDateAll',
  contractServiceCache = 'contractServiceCache',
  contractServiceCacheDate = 'contractServiceCacheDate',
  contractServiceCacheAll = 'contractServiceCacheAll',
  contractServiceCacheDateAll = 'contractServiceCacheDateAll',
  equipmentModelServiceCache = 'equipmentModelServiceCache',
  equipmentModelServiceCacheDate = 'equipmentModelServiceCacheDate',
  equipmentModelServiceCacheAll = 'equipmentModelServiceCacheAll',
  equipmentModelServiceCacheDateAll = 'equipmentModelServiceCacheDateAll',
  equipmentServiceCache = 'equipmentServiceCache',
  equipmentServiceCacheDate = 'equipmentServiceCacheDate',
  equipmentServiceCacheAll = 'equipmentServiceCacheAll',
  equipmentServiceCacheDateAll = 'equipmentServiceCacheDateAll',
  exemptPeriodServiceCache = 'exemptPeriodServiceCache',
  exemptPeriodServiceCacheDate = 'exemptPeriodServiceCacheDate',
  exemptPeriodServiceCacheAll = 'exemptPeriodServiceCacheAll',
  exemptPeriodServiceCacheDateAll = 'exemptPeriodServiceCacheDateAll',
  importFileServiceCache = 'importFileServiceCache',
  importFileServiceCacheDate = 'importFileServiceCacheDate',
  importFileServiceCacheAll = 'importFileServiceCacheAll',
  importFileServiceCacheDateAll = 'importFileServiceCacheDateAll',
  laneServiceCache = 'laneServiceCache',
  laneServiceCacheDate = 'laneServiceCacheDate',
  laneServiceCacheAll = 'laneServiceCacheAll',
  laneServiceCacheDateAll = 'laneServiceCacheDateAll',
  lotServiceCache = 'lotServiceCache',
  lotServiceCacheDate = 'lotServiceCacheDate',
  lotServiceCacheAll = 'lotServiceCacheAll',
  lotServiceCacheDateAll = 'lotServiceCacheDateAll',
  manuFacturerServiceCache = 'manuFacturerServiceCache',
  manuFacturerServiceCacheDate = 'manuFacturerServiceCacheDate',
  manuFacturerServiceCacheAll = 'manuFacturerServiceCacheAll',
  manuFacturerServiceCacheDateAll = 'manuFacturerServiceCacheDateAll',
  plateServiceCache = 'plateServiceCache',
  plateServiceCacheDate = 'plateServiceCacheDate',
  plateServiceCacheAll = 'plateServiceCacheAll',
  plateServiceCacheDateAll = 'plateServiceCacheDateAll',
  preProcessSettingsServiceCache = 'preProcessSettingsServiceCache',
  preProcessSettingsServiceCacheDate = 'preProcessSettingsServiceCacheDate',
  preProcessSettingsServiceCacheAll = 'preProcessSettingsServiceCacheAll',
  preProcessSettingsServiceCacheDateAll = 'preProcessSettingsServiceCacheDateAll',
  quarantineServiceCache = 'quarantineServiceCache',
  quarantineServiceCacheDate = 'quarantineServiceCacheDate',
  quarantineServiceCacheAll = 'quarantineServiceCacheAll',
  quarantineServiceCacheDateAll = 'quarantineServiceCacheDateAll',
  reasonServiceCache = 'reasonServiceCache',
  reasonServiceCacheDate = 'reasonServiceCacheDate',
  reasonServiceCacheAll = 'reasonServiceCacheAll',
  reasonServiceCacheDateAll = 'reasonServiceCacheDateAll',
  regionServiceCache = 'regionServiceCache',
  regionServiceCacheDate = 'regionServiceCacheDate',
  regionServiceCacheAll = 'regionServiceCacheAll',
  regionServiceCacheDateAll = 'regionServiceCacheDateAll',
  regulationServiceCache = 'regulationServiceCache',
  regulationServiceCacheDate = 'regulationServiceCacheDate',
  regulationServiceCacheAll = 'regulationServiceCacheAll',
  regulationServiceCacheDateAll = 'regulationServiceCacheDateAll',
  releaseNotesServiceCache = 'releaseNotesServiceCache',
  releaseNotesServiceCacheDate = 'releaseNotesServiceCacheDate',
  releaseNotesServiceCacheAll = 'releaseNotesServiceCacheAll',
  releaseNotesServiceCacheDateAll = 'releaseNotesServiceCacheDateAll',
  sessionServiceCache = 'sessionServiceCache',
  sessionServiceCacheDate = 'sessionServiceCacheDate',
  sessionServiceCacheAll = 'sessionServiceCacheAll',
  sessionServiceCacheDateAll = 'sessionServiceCacheDateAll',
  spotGroupServiceCache = 'spotGroupServiceCache',
  spotGroupServiceCacheDate = 'spotGroupServiceCacheDate',
  spotGroupServiceCacheAll = 'spotGroupServiceCacheAll',
  spotGroupServiceCacheDateAll = 'spotGroupServiceCacheDateAll',
  spotServiceCache = 'spotServiceCache',
  spotServiceCacheDate = 'spotServiceCacheDate',
  spotServiceCacheAll = 'spotServiceCacheAll',
  spotServiceCacheDateAll = 'spotServiceCacheDateAll',
  stateServiceCache = 'stateServiceCache',
  stateServiceCacheDate = 'stateServiceCacheDate',
  stateServiceCacheAll = 'stateServiceCacheAll',
  stateServiceCacheDateAll = 'stateServiceCacheDateAll',
  tokenServiceCache = 'tokenServiceCache',
  tokenServiceCacheDate = 'tokenServiceCacheDate',
  tokenServiceCacheAll = 'tokenServiceCacheAll',
  tokenServiceCacheDateAll = 'tokenServiceCacheDateAll',
  typeServiceCache = 'typeServiceCache',
  typeServiceCacheDate = 'typeServiceCacheDate',
  typeServiceCacheAll = 'typeServiceCacheAll',
  typeServiceCacheDateAll = 'typeServiceCacheDateAll',
  userGroupServiceCache = 'userGroupServiceCache',
  userGroupServiceCacheDate = 'userGroupServiceCacheDate',
  userGroupServiceCacheAll = 'userGroupServiceCacheAll',
  userGroupServiceCacheDateAll = 'userGroupServiceCacheDateAll',
  userServiceCache = 'userServiceCache',
  userServiceCacheDate = 'userServiceCacheDate',
  userServiceCacheAll = 'userServiceCacheAll',
  userServiceCacheDateAll = 'userServiceCacheDateAll',
  vehicleServiceCache = 'vehicleServiceCache',
  vehicleServiceCacheDate = 'vehicleServiceCacheDate',
  vehicleServiceCacheAll = 'vehicleServiceCacheAll',
  vehicleServiceCacheDateAll = 'vehicleServiceCacheDateAll',
  vehicleWhiteListServiceCache = 'vehicleWhiteListServiceCache',
  vehicleWhiteListServiceCacheDate = 'vehicleWhiteListServiceCacheDate',
  vehicleWhiteListServiceCacheAll = 'vehicleWhiteListServiceCacheAll',
  vehicleWhiteListServiceCacheDateAll = 'vehicleWhiteListServiceCacheDateAll',
  violationReportServiceCache = 'violationReportServiceCache',
  violationReportServiceCacheDate = 'violationReportServiceCacheDate',
  violationReportServiceCacheAll = 'violationReportServiceCacheAll',
  violationReportServiceCacheDateAll = 'violationReportServiceCacheDateAll',
  violationServiceCache = 'violationServiceCache',
  violationServiceCacheDate = 'violationServiceCacheDate',
  violationServiceCacheAll = 'violationServiceCacheAll',
  violationServiceCacheDateAll = 'violationServiceCacheDateAll',
  violationStepReportServiceCache = 'violationStepReportServiceCache',
  violationStepReportServiceCacheDate = 'violationStepReportServiceCacheDate',
  violationStepReportServiceCacheAll = 'violationStepReportServiceCacheAll',
  violationStepReportServiceCacheDateAll = 'violationStepReportServiceCacheDateAll',
  lotStepReportServiceCache = 'lotStepReportServiceCache',
  lotStepReportServiceCacheDate = 'lotStepReportServiceCacheDate',
  lotStepReportServiceCacheAll = 'lotStepReportServiceCacheAll',
  lotStepReportServiceCacheDateAll = 'lotStepReportServiceCacheDateAll',
  recordsNumberByListageCache = 'recordsNumberByListageCache',
  serialNumberConfigServiceCache = 'serialNumberConfigServiceCacheDate',
  serialNumberConfigServiceCacheDate = 'serialNumberConfigServiceCacheDate',
  serialNumberConfigServiceCacheAll = 'serialNumberConfigServiceCacheAll',
  serialNumberConfigServiceCacheDateAll = 'serialNumberConfigServiceCacheDateAll',
  activityServiceCache = 'activityServiceCache',
  activityServiceCacheDate = 'activityServiceCacheDate',
  activityServiceCacheAll = 'activityServiceCacheAll',
  activityServiceCacheDateAll = 'activityServiceCacheDateAll',
  violationContractCounterServiceCache = 'violationContractCounterServiceCache',
  violationContractCounterServiceCacheDate = 'violationContractCounterServiceCacheDate',
  violationContractCounterServiceCacheAll = 'violationContractCounterServiceCacheAll',
  violationContractCounterServiceCacheDateAll = 'violationContractCounterServiceCacheDateAll',
  visumVersion = 'visumVersion',
  quarentineRecountServiceCache = 'quarentineRecountServiceCache',
  violationSourceServiceCache = 'violationSourceServiceCache',
  violationReportCounterServiceCache = 'violationReportCounterServiceCache',
  cftvVideoServiceCache = 'cftvVideoServiceCache',
  preViolationService = 'preViolationService',
  uploadPreViolationFileServiceCache = 'uploadPreViolationFileServiceCache',
  samplingsServiceCache = 'samplingsServiceCache',
  monitoringSettingsServiceCache = 'monitoringSettingsServiceCache',
  storageLocationServiceCache = 'storageLocationServiceCache',
  storageLocationServiceCacheDate = 'storageLocationServiceCacheDate',
  storageLocationServiceCacheAll = 'storageLocationServiceCacheAll',
  storageLocationServiceCacheDateAll = 'storageLocationServiceCacheDateAll',
  statusStorageServiceCache = 'statusStorageServiceCache',
  statusStorageServiceCacheDate = 'statusStorageServiceCacheDate',
  statusStorageServiceCacheAll = 'statusStorageServiceCacheAll',
  statusStorageServiceCacheDateAll = 'statusStorageServiceCacheDateAll'
}
