import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Region } from 'src/app/core/models/Region';
import { RegionService } from 'src/app/core/services/region.service';
import { State } from 'src/app/core/models/State';
import { City } from 'src/app/core/models/City';
import { StateService } from 'src/app/core/services/state.service';
import { CityService } from 'src/app/core/services/city.service';
import { StorageService, StorageKey, ContractService } from 'src/app/core/services';
import { SpotService } from 'src/app/core/services/spot.service';
import { RoadType } from 'src/app/core/models';

@Component({
  selector: 'app-spots-list',
  templateUrl: './spots-list.component.html',
  styleUrls: ['./spots-list.component.sass']
})
export class SpotsListComponent implements OnInit {
  public spots: Array<{spotId: string; isOpen: boolean}> = [];
  public regions: Array<Region> = [];
  public states: Array<State>;
  public cities: Array<City>;
  public searchForm: FormGroup;
  public dropMenuActive = false;
  public showCities = false;
  public params: any = {};
  public modelChanged: Subject<string> = new Subject<string>();
  public roadTypes = Object.values(RoadType);
  public roadTypesI18n = [];
  public contracts: any;
  public contractsDropdownSettings = {};
  public isAllOpen = false;
  public noRegister = false;
  private panelCommands: Subject<boolean> = new Subject<boolean>();

  constructor(
    public spotService: SpotService,
    private regionService: RegionService,
    private stateService: StateService,
    private cityService: CityService,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private contractService: ContractService
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
    .subscribe(() => {
      this.handleSearch();
    });
   }

  ngOnInit() {
    const contractStorage = this.contracts = this.storageService.get(StorageKey.currentContracts);
    const user = this.storageService.get(StorageKey.currentUser);
    this.contractService.getAll().then(data => {
      if (user.superUser) {
        this.contracts = data;
      } else {
        this.contracts = data.filter(contract => contractStorage.indexOf(contract.id) >= 0);
      }
    });
    this.contracts = (this.storageService.get(StorageKey.currentContracts) || []).map(c => ({id: c}));
    this.regionService.getAll({name: 'Brasil'}).then(regions => {
      this.regions = regions;
      this.stateService.getAll({regionId: regions[0].id}).then(states => {
        this.states = states;
      }).catch(error => {
        console.log(error);
      });
    }).catch(error => {
      console.log(error);
    });
    this.createSearchForm();
  }

  createSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      search: [''],
      region: ['Brasil'],
      contracts: [],
      state: [''],
      roadType: [''],
      city: ['']
    });
  }

  handleSearch() {
    this.spots = [];
    const search = this.searchForm.get('search').value;
    const filterObj: any = {};
    const region = this.searchForm.get('region').value;
    const city = this.searchForm.get('city').value;
    const state = this.searchForm.get('state').value;
    const roadType = this.searchForm.get('roadType').value;
    const contractIds = [];
    this.noRegister = false;

    if (search != null && search !== '') {
      filterObj['code[contains,or]'] = `${search}`;
      filterObj['description[contains,or]'] = `${search}`;
    }
    if (this.searchForm.get('contracts').value != null) {
      contractIds.push(this.searchForm.get('contracts').value);
      filterObj['contractId[any]'] = `[${contractIds.join(',')}]`;
    }
    if (region != null && region !== '') {
      const regionId = this.regions.find(r => r.name === region).id;
      filterObj.regionId = `${regionId}`;
    }
    if (city != null && city !== '') {
      filterObj.cityId = `${city}`;
    }
    if (state != null && state !== '') {
      filterObj.stateId = `${state}`;
    }
    if (roadType != null && roadType !== '') {
      filterObj[`roadType[in]`] = `[${roadType.join(',')}]`;
    }
    this.params = filterObj;
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  activeMenu() {
    this.dropMenuActive = true;
  }

  loadCities(stateId: any) {
    if (stateId === '') {
      return;
    }
    this.cityService.getAll({regionId: this.regions[0].id, stateId }).then(res => {
      this.cities = _.sortBy(res, 'name');
      this.showCities = true;
    }).catch(error => {
      console.log(error);
    });
  }

  loadStates(currentRegion: string) {
    const regionId = this.regions.find(r => r.name === currentRegion).id;
    this.stateService.getAll({ regionId }).then(res => {
      this.states = res;
      this.showCities = false;
    }).catch(error => {
      console.log(error);
    });
  }

  disableMenu() {
    this.dropMenuActive = false;
  }

  newSpot() {
    this.spots.unshift({spotId: null, isOpen: true});
  }

  refreshSpots(event, index) {
    if (event == null) {
      return;
    }
    if (event.deleted) {
      this.spots.splice(index, 1);
      return;
    }
    const spotId = event.spotId;
    this.spots.unshift(spotId);
  }

  toggleAllSpots() {
    this.isAllOpen = !this.isAllOpen;
    this.panelCommands.next(this.isAllOpen);
  }

  setSpots(spots) {
    spots.forEach(spot => {
      if (!this.spots.find(s => s.spotId === spot.id)) {
        this.spots.push({spotId: spot.id, isOpen: false});
      }
    });
    const hasItems = this.spots.filter(spot => spot.spotId != null);
    if (hasItems.length === 0) {
      this.noRegister = true;
    }
  }
}
