import { Component, OnInit, Input } from '@angular/core';
import { Quarantine, QuarantineStatus } from 'src/app/core/models/Quarantine';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quarantine-reason-detail',
  templateUrl: './quarantine-reason-detail.component.html',
  styleUrls: ['./quarantine-reason-detail.component.sass']
})
export class QuarantineReasonDetailComponent implements OnInit {
  @Input() quarantine: Quarantine;
  @Input() quarantineName;
  public columns = [];
  public quarantineStatusI18n = [];
  public quarantineStatus = Object.values(QuarantineStatus);

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    switch (this.quarantineName) {
      case 'wrongRegulation':
        this.columns = [
          'disabledRegulation',
          'invalidVehicleClassification',
          'invalidRegulationPeriod',
          'invalidWeekDay',
          'invalidDayPeriod'
        ];
        break;
      case 'calibrationFailure':
        this.columns = [
          'invalidAppraisalDate',
          'missingAppraisalDocument',
          'invalidAppraisalNumber',
          'expiredAppraisal'
        ];
        break;
      case 'laneFailure':
        this.columns = [
          'spotMissing',
          'disabledLane',
          'testingLane',
          'invalidLaneNumber'
        ];
        break;
      case 'invalidSpeed':
        this.columns = [
          'oddSpeed',
          'invalidSpeed'
        ];
        break;
      case 'invalidEquipmentSerialNumber':
        this.columns = [
          'disabledEquipment',
          'invalidEquipmentSerialNumber'
        ];
        break;
    }
  }

  getStatusQuarantine(typQuarantine) {
    if (this.quarantineStatusI18n.length === 0) {
      return;
    }
    if (this.quarantineStatusI18n.find(q => q.id === typQuarantine).value === ' Equipamento Inválido ') {
      return 'Nº de Série Inválido';
    }
    return this.quarantineStatusI18n.find(q => q.id === typQuarantine).value;
  }

  sumTotalQuarantines() {
    let total = 0;
    if (this.columns == null || this.quarantine == null) {
      return total;
    }
    this.columns.forEach(column => {
      if (this.quarantine.report[column] != null) {
        total += this.quarantine.report[column];
      }
    });
    return total;
  }

  navigateQuarantineDetail(quarantineReason) {
    this.router.navigate([`pre-process/quarantine/${this.quarantine.contractId}/${quarantineReason}/${this.quarantine.id}`]);
  }
}
