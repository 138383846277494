import { PreProcessSettings } from 'src/app/core/models';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { getImageLimits, getProportion } from 'src/app/core/utils/imageHandler';

@Component({
  selector: 'app-pre-process-config-edit-image',
  templateUrl: './config-edit-image.component.html',
  styleUrls: ['./config-edit-image.component.sass']
})
export class ConfigEditImageComponent implements OnInit {
  @ViewChild('imgContainer', { static: false, read: ElementRef }) imgContainer: ElementRef;
  @Input() preProcessSettings: PreProcessSettings = new PreProcessSettings();
  @Output() nextStepCommand = new EventEmitter();
  @Output() isValid = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  public formGroup: FormGroup;
  public coordinates: {x: number; y: number; width: number; height: number} =
    {x: 0, y: 0, width: 0, height: 0};
  public x;
  public y;
  public width;
  public height;
  public hasClip = false;
  public limits: { minX: number; minY: number; maxX: number; maxY: number } =
    { minX: 0, minY: 0, maxX: 0, maxY: 0 };
  public img = new Image();
  public proportion = 1;

  constructor(private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.x = 0;
    this.y = 0;
    this.width = 100;
    this.height = 50;
    this.createForm();
    if (this.hasClip === true) {
      this.loadImage();
    }
    this.isValid.emit(this.formGroup.valid);
    this.outputValue.emit(this.getOutputValue());
  }

  updateCoordinates({x, y, width, height}) {
    this.coordinates = Object.assign(this.coordinates, {x, y, width, height});
    this.formGroup.patchValue({
      x: Math.round((x - this.limits.minX) / this.proportion),
      y: Math.round((y - this.limits.minY ) / this.proportion),
      width: Math.round(width / this.proportion),
      height: Math.round(height / this.proportion)
    });
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      clip: [this.getValue(this.preProcessSettings, 'imageEdit.clip') || false, Validators.required],
      blur: [this.getValue(this.preProcessSettings, 'imageEdit.blur') || false, Validators.required],
      x: [this.getValue(this.preProcessSettings, 'imageEdit.clipPosition.x') || this.x, Validators.required],
      y: [this.getValue(this.preProcessSettings, 'imageEdit.clipPosition.y') || this.y, Validators.required],
      width: [this.getValue(this.preProcessSettings, 'imageEdit.clipPosition.width') || this.width, Validators.required],
      height: [this.getValue(this.preProcessSettings, 'imageEdit.clipPosition.height') || this.height, Validators.required],
      hideImageEditParam:[this.getValue(this.preProcessSettings, 'imageEdit.hideImageEdit') || false]
    });

    this.coordinates = {
      x: this.formGroup.get('x').value,
      y: this.formGroup.get('y').value,
      width: this.formGroup.get('width').value,
      height: this.formGroup.get('height').value
    };

    this.formGroup.valueChanges.subscribe(() => {
      this.isValid.emit(this.formGroup.valid);
      if (this.formGroup.valid === true) {
        this.outputValue.emit(this.getOutputValue());
      }
    });
    const clipControl = this.formGroup.get('clip');
    this.hasClip = clipControl.value || false;
    clipControl.valueChanges.subscribe(value => {
      this.hasClip = value;
      if (value === true) {
        this.loadImage();
      }
    });
  }

  nextStep(nextStep) {
    if (this.formGroup.valid === true) {
      this.nextStepCommand.emit(nextStep);
    }
  }

  getOutputValue() {
    const imageEdit = {
      clip: this.formGroup.get('clip').value || false,
      blur: this.formGroup.get('blur').value || false,
      hideImageEdit: this.formGroup.get('hideImageEditParam').value || false ,
      clipPosition: {
        x: Math.round(this.formGroup.get('x').value),
        y: Math.round(this.formGroup.get('y').value),
        width: Math.round(this.formGroup.get('width').value),
        height: Math.round(this.formGroup.get('height').value),
      }
    };
    return {
      imageEdit
    };
  }

  getValue(model, attr, value = '') {
    let arr = [];
    if (typeof attr === 'string') {
      arr = attr.split('.');
    } else {
      arr = attr;
    }
    if (arr.length > 0) {
      if (model[arr[0]] == null) {
        return value;
      } else {
        return this.getValue(model[arr[0]], arr.slice(1), value);
      }
    } else {
      return model;
    }
  }

  loadImage() {
    this.img = new Image();
    this.img.src = 'assets/images/transito_example.jpg';
    this.img.addEventListener('load', this.onLoadImg.bind(this));
  }

  onLoadImg(event) {
    this.getNewImgProportion({height: event.target.height, width: event.target.width});
  }

  getNewImgProportion(img) {
    if (this.imgContainer != null) {
      this.limits = getImageLimits(this.imgContainer.nativeElement);
      this.proportion = getProportion(this.imgContainer.nativeElement);

      const coordinates = {
        x: (this.formGroup.get('x').value * this.proportion) + this.limits.minX,
        y: (this.formGroup.get('y').value * this.proportion) + this.limits.minY,
        width: this.formGroup.get('width').value * this.proportion,
        height: this.formGroup.get('height').value * this.proportion
      };

      this.coordinates = Object.assign({}, this.coordinates, coordinates);
    }
  }

  onResize(event) {
    this.getNewImgProportion(this.img);
  }
}
