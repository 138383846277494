import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.sass']
})
export class LoadingComponent implements OnInit {
  @Input() set promise(value: Promise<any>) {
    if (value != null) {
      this.loading = true;
      value
      .finally(() => {
        this.loading = false;
      });
    }
  }
  @Input() set load(value: boolean) {
    if (value != null) {
      this.loading = value;
    }
  }
  @Input() text: string;
  public loading: boolean;

  constructor() { }

  ngOnInit() {
  }
}
