<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="carousel slide" #carousel data-pause="true" data-ride="carousel">
            <div class="carousel-inner flex-center-vertical">
              <div class="carousel-item" [ngClass]="{ 'active' : file === shownFile}" *ngFor="let file of files">
                <img [src]="file | sanitizer" alt="First slide" *ngIf="!isDocument(file); else document">
                <ng-template #document>
                  <embed [src]="shownFile | sanitizer" width="100%" height="700px" [type]="getMime(file)">
                </ng-template>
              </div>
            </div>
            <a class="carousel-control-prev" href="javascript:void(0)" (click)="previous()" role="button"
              data-slide="prev" *ngIf="files && files.length > 1">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="javascript:void(0)" (click)="next()" role="button"
              data-slide="next" *ngIf="files && files.length > 1">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
