<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h3 class="modal-title container">
          <span i18n>Detalhes da Série</span>
        </h3>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="loading-page" *ngIf="isLoading">
      <div class="loading-spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

    <div class="modal-body" *ngIf="!isLoading">
      <div class="row row-item justify-content-between mb-2">
        <div class="item">
          <p i18n>Identificador:</p>
          <p>{{ modalData?.identifier || '-' }}</p>
        </div>
        <div class="item">
          <p i18n>Sigla:</p>
          <p>{{ modalData?.prefix || '-' }}</p>
        </div>
        <div class="item">
          <p i18n>Dígito Verificador:</p>
          <p>{{ modalData?.verifyingDigit || '-' }}</p>
        </div>
        <div class="item">
          <p i18n>N° Inicial:</p>
          <p>{{ modalData?.range?.start || '-' }}</p>
        </div>
        <div class="item">
          <p i18n>N° FInal:</p>
          <p>{{ modalData?.range?.end || '-' }}</p>
        </div>
        <div class="item">
          <p i18n>Último Utilizado:</p>
          <p>{{ modalData?.currentNumber || modalData?.range?.start }}</p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-4">
          <p i18n>Modelo do Equipamento:</p>
          <div class="tag-wrapper" *ngIf="modalData?.equipmentModelIds.length > 0">
            <div class="item-tag" *ngFor="let equipmentModelId of modalData?.equipmentModelIds">
              <span>
                {{ equipmentModelsById[equipmentModelId]?.pretty }}
              </span>
            </div>
          </div>
          <div *ngIf="modalData?.equipmentModelIds.length === 0">-</div>
        </div>
        <div class="col-8">
          <p i18n>Enquadramento:</p>
          <div class="tag-wrapper" *ngIf="modalData?.regulationIds.length > 0">
            <div class="item-tag" *ngFor="let regulationId of modalData.regulationIds">
              <span>
                {{ regulationsById[regulationId]?.code }} - {{ regulationsById[regulationId]?.shortDescription }}
              </span>
            </div>
          </div>
          <div *ngIf="modalData?.regulationIds.length === 0">-</div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <p>Faixas:</p>
          <div class="tag-wrapper" *ngIf="modalData?.laneIds.length > 0">
            <div class="item-tag" *ngFor="let laneId of modalData.laneIds">
              <span class="item">{{ laneById[laneId]?.prettyNames }}</span>
            </div>
          </div>
          <div *ngIf="modalData?.laneIds.length === 0">-</div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-4">
          <p i18n>Situação da Infração:</p>
          <div class="tag-wrapper" *ngIf="modalData?.violationStatus">
            <div class="item-tag" *ngFor="let status of modalData?.violationStatus">
              <p class="mb-0" [ngSwitch]="status">
                <span *ngSwitchCase="'invalid'">
                  Inválida
                </span>
                <span *ngSwitchCase="'valid'">
                  Válida
                </span>
              </p>
            </div>
          </div>
          <div *ngIf="!modalData?.violationStatus">-</div>
        </div>
        <div class="col-8" *ngIf="modalData?.reasonIds.length > 0">
          <p i18n>Motivo de Invalidação:</p>
          <div class="tag-wrapper">
            <div class="item-tag" *ngFor="let reasonId of modalData.reasonIds">
              <span>
                {{ reasonById[reasonId]?.code }} - {{ reasonById[reasonId]?.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <p i18n>Padrão de Nome:</p>
          <div class="tag-wrapper">
            <div class="item-tag item-name" *ngFor="let name of namePattern">
              <div [ngSwitch]="name">
                <span *ngSwitchCase="'identifier'">Identificador</span>
                <span *ngSwitchCase="'prefix'">Sigla</span>
                <span *ngSwitchCase="'verifyingDigit'">DV</span>
                <span *ngSwitchCase="'currentNumber'">N° Corrente</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <p i18n>Modelo de Série:</p>
          <div class="tag-wrapper">
            <div class="name-serie">
              <span>
                {{ serieExample }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer"></div>
  </div>
</div>
