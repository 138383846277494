import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalItem, Modal, ComponentModal } from 'src/app/core/models';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private events = new Subject<ModalItem>();
  private commands = new Subject<string>();
  private component: ModalComponent;

  constructor() { }

  setComponent(component: ModalComponent) {
    this.component = component;
  }

  isOpen(type) {
    return this.component.isOpen(type);
  }

  getEvents(): Observable<any> {
    return this.events.asObservable();
  }

  getComands(): Observable<any> {
    return this.commands.asObservable();
  }

  show(modal: Modal) {
    const item = new ModalItem(modal);
    this.events.next(item);
    return item.promise;
  }

  close() {
    this.commands.next('close');
  }
  cancel() {
    this.commands.next('cancel');
  }
  submit() {
    this.commands.next('submit');
  }
}
