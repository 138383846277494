<div class="toggle-panel">
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col" (click)="toggle()">
          <h5 class="mb-0" *ngIf="spot.id; else elseTitleSpot">
            {{ contractByIds[spot.contractId] && contractByIds[spot.contractId].code }} -
            {{ contractByIds[spot.contractId] && contractByIds[spot.contractId].name }} |
            {{spot.code}}
          </h5>

          <ng-template #elseTitleSpot>
            <h5 class="my-2" i18n>Contrato | Código do Ponto</h5>
          </ng-template>

          <p *ngIf="!isShowing" class="mb-0 text-secondary">
            <small><span>{{ spot?.description }}</span></small>
          </p>
        </div>

        <div class="col-auto d-flex justify-content-end align-items-start" *ngIf="!isNewSpot">
          <div class="dropdown">
            <button class="btn text-secondary btn-icon-block" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              *appPermission="['UpdateSpot','DeleteSpot']; contracts spot.contractId"><i class="material-icons">more_horiz</i></button>

            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <a i18n *ngIf="isEdit; else elseEditBlock" class="dropdown-item" (click)="editSpot()">Cancelar Edição</a>
              <ng-template #elseEditBlock>
                <a i18n class="dropdown-item" (click)="editSpot()" *appPermission="'UpdateSpot'; contracts spot.contractId">Editar Ponto</a>
              </ng-template>
              <div *appPermission="'DeleteSpot'; contracts spot.contractId">
                <div class="dropdown-divider" *appPermission="'DeleteSpot';hide: false; contracts spot.contractId"></div>
                <a i18n class="dropdown-item" (click)="openDeleteModal()">Remover Ponto</a>
              </div>
            </div>
          </div>

          <button class="btn btn-secondary btn-icon-block" (click)="toggle()" *ngIf="isShowing; else elseBlock">
            <i class="material-icons">unfold_less</i>
          </button>

          <ng-template #elseBlock>
            <button class="btn text-secondary btn-icon-block" (click)="toggle()">
              <i class="material-icons">unfold_more</i>
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <div [ngClass]="{'toggle-content': true, closed: !isShowing}" #panel>
      <form [formGroup]="spotForm" (ngSubmit)="submit()">
        <div class="card-body">
          <div *ngIf="isEdit || isNewSpot; else elseBlockContent">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-3">
                <app-input i18n-label i18n-placeholder type="{{isNewSpot ? 'select' : 'text'}}" [isLabel]="!isEdit"
                  [list]="contracts" selectValue="id" selectText="prettyName" label="Contrato"
                  (ngModelChange)="setRegion(contractId)" formControlName="contractId" inputRequired="true"
                  [inputDisabled]="!isNewSpot" placeholder="Selecione um contrato">
                </app-input>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <app-input i18n-label type="text" label="Código do Ponto" formControlName="code" [textValue]="spot.code"
                  inputRequired="true" [isLabel]="!isEdit">
                </app-input>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <app-input i18n-label type="select" label="Fuso Horário" formControlName="timezone"
                  [textValue]="spot.timezone" inputRequired="true" [list]="allTimezones" [isLabel]="!isEdit"
                  placeholder="Selecione o fuso horário" selectText="value" selectValue="value">
                </app-input>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <app-input i18n-label type="number" label="Altura Máxima da Via (cm)"
                  formControlName="maxHeight" [textValue]="spot.maxHeight" [isLabel]="!isEdit" placeholder="0"
                  maxlength="4" ng-model="spot.maxHeight" ng-pattern="/([0-9]})/" step="1">
                </app-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <app-input i18n-label type="text" label="Descrição" formControlName="description"
                  [textValue]="spot.decription" [isLabel]="!isEdit" inputRequired="true">
                </app-input>
              </div>
            </div>
          </div>

          <ng-template #elseBlockContent>
            <div class="row mb-4">
              <div class="col-6">
                <label class="text-secondary" i18n>Descrição:</label>
                <div>{{spot.description}}</div>
              </div>
              <div class="col-3">
                <label class="text-secondary" i18n>Fuso Horário:</label>
                <div>{{spot.timezone}}</div>
              </div>
              <div class="col-3">
                <label class="text-secondary" i18n>Altura Máxima da via:</label>
                <div *ngIf="spot.maxHeight !== 'undefined' && spot.maxHeight > '0'">{{spot.maxHeight}} cm</div>
              </div>
            </div>
          </ng-template>

          <div class="row">
            <div class="col col-md-7 d-flex align-items-center justify-content-between">
              <div class="w-100">
                <app-input i18n-label type="text" label="Endereço" formControlName="street"
                  [textValue]="spot.address && spot.address.street" (change)="clearLatLng()"
                  [isLabel]="!isEdit" inputRequired="true">
                </app-input>
              </div>

              <button (click)="openMapModal()" type="button" *ngIf="isEdit" class="btn btn-icon-block btn-fade-info ml-2 mt-2">
                <i class="material-icons">add_location_alt</i>
              </button>
            </div>

            <div class="col col-md-2 d-flex align-items-center justify-content-between">
              <app-input i18n-label type="text"
                label="{{ isEdit || isNewSpot ? 'Nº/KM' : spot.address && spot.address.isKm ? 'KM' : 'Nº' }}"
                formControlName="addressNumber" [textValue]="spot.address && spot.address.number" [isLabel]="!isEdit">
              </app-input>
              <div *ngIf="isEdit || isNewSpot" class="ml-4 mt-1">
                <mat-checkbox formControlName="isKm">KM</mat-checkbox>
              </div>
            </div>

            <div class="col col-md-3">
              <app-enum-i18n [input]="roadTypes" (output)="setRoadTypeI18n($event)"></app-enum-i18n>
              <app-input i18n-placeholder="@@SpotListRoadTypePlaceholder" i18n-label="@@SpotListRoadTypeLabel"
                type="select" [list]="roadTypesI18n" label="Tipo da Via" formControlName="roadType"
                [textValue]="currentRoadType" selectText="value" selectValue="id" placeholder="Selecione um tipo de via"
                [isLabel]="!isEdit" inputRequired="true">
              </app-input>
            </div>
          </div>

          <div class="row">
            <div class="col-6 col-md-3">
              <app-input i18n-label type="text" label="Latitude" formControlName="latitude" [isLabel]="!isEdit"
                [textValue]="spot.coordinates && spot.coordinates.latitude" [inputDisabled]="true">
              </app-input>
            </div>
            <div class="col-6 col-md-3">
              <app-input i18n-label type="text" label="Longitude" formControlName="longitude"
                [textValue]="spot.coordinates && spot.coordinates.longitude" [isLabel]="!isEdit" [inputDisabled]="true">
              </app-input>
            </div>
            <div class="col-12 col-md-6">
              <app-input i18n-label type="text" label="Ponto de Referência" formControlName="landmarks"
                [textValue]="spot.address && spot.address.landmarks" [isLabel]="!isEdit">
              </app-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-12">
              <app-input ti18n-label ype="text" label="Cep" formControlName="zipCode"
                [textValue]="spot.address && spot.address.zipCode" [isLabel]="!isEdit">
              </app-input>
            </div>
            <div class="col-md-3 col-12">
              <app-input i18n-label type="text" label="País" formControlName="region" [textValue]="region && region.name"
                [isLabel]="!isEdit" [inputDisabled]="isNewSpot || isEdit">
              </app-input>
            </div>
            <div class="col-md-3 col-12">
              <app-input i18n-label i18n-placeholder type="{{isEdit ? 'select' : 'text'}}" [isLabel]="!isEdit"
                [list]="states" label="Estado" selectText="abbreviation" selectValue="id"
                (ngModelChange)="loadCities($event)" formControlName="state" placeholder="Selecione um estado"
                [textValue]="state && state.abbreviation" inputRequired="true">
              </app-input>
            </div>
            <div class="col-md-3 col-12">
              <app-input i18n-label i18n-placeholder type="{{isEdit ? 'select' : 'text'}}" [isLabel]="!isEdit"
                [list]="cities.currentCities" label="Cidade" selectText="name" selectValue="id" formControlName="city"
                [textValue]="spot.cityName" inputRequired="true" placeholder="Selecione uma cidade">
              </app-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-input i18n-label type="text" label="Código do cliente" formControlName="clientCode"
                [textValue]="spot.clientCode" [isLabel]="!isEdit">
              </app-input>
            </div>
          </div>


          <div class="card-divider"></div>

          <div class="row d-flex align-items-center">
            <div class="col">
              <h5 class="mt-1" i18n>Faixas ({{spot.laneIds && spot.laneIds.length}})</h5>
            </div>

            <div class="col-auto ml-auto" *ngIf="isEdit || isNewSpot">
              <button class="btn btn-icon btn-fade-info" type="button" (click)="newLane()">
                <i class="material-icons">add_road</i> Adicionar faixa
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-12 lanes-in-spot">
              <div *ngFor="let lane of lanes; index as i">
                <app-lane-panel [laneId]="lane.laneId" [spot]="spot" (afterSave)="refreshLane($event, i)"
                  [saveLane]="saveLaneEvent.asObservable()" [isShowing]="lane.laneId === null" [isEdit]="isEdit"
                  (formChange)="validateForm($event, i)" (afterLoad)="loadLane($event, i)" [haveRightMenu]="!isEdit"
                  [haveEditOption]="false" (removeLaneCommand)="refreshLane($event, i)">
                </app-lane-panel>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="isEdit || isNewSpot">
          <div class="row">
            <div class="col d-flex justify-content-end">
              <button type="button" (click)="cancel()" class="btn btn-outline-secondary mr-2">
                <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
              </button>

              <button type="submit" class="btn btn-primary" [ngClass]="{disabled: !isFormValid}"
                [disabled]="!isFormValid">
                <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
