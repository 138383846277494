<div class="container">
  <div class="page-title page-title--columns">
    <div class="page-title__left"><h2 i18n>Usuários</h2></div>
    <div class="page-title__right" *appPermission="'CreateUser'">
      <button class="btn btn-fade-primary"
        title="Cadastrar novo usuário" (click)="openCreate()" i18n>Novo Usuário</button>
    </div>
  </div>

  <div class="mb-4">
    <form [formGroup]="searchForm" (submit)="handleSearch()">
      <div class="input-group">
        <input i18n-placeholder type="text" class="form-control form-search"
        formControlName="search" placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">

        <div class="input-group-append">
          <div class="filter-dropdown">
            <button type="button" class="btn btn-outline-secondary btn-icon filter-dropdown__handle" i18n>
              <i class="material-icons">filter_alt</i> Filtrar</button>

            <div class="filter-dropdown__content">
                <div class="form-group">
                  <app-input i18n-placeholder type="multiselect" [list]="companies" label="Empresa"
                    formControlName="company" selectText="name" selectValue="id" selectShowLimit="2"
                    placeholder="Selecione empresa(s)" i18n-placeholder i18n-label></app-input>
                </div>

                <div class="form-group">
                  <app-input i18n-placeholder type="multiselect" [list]="userGroupContract" label="Grupos de Usuários"
                    formControlName="groupIds" selectText="prettyName" selectValue="id" selectShowLimit="2"
                    placeholder="Selecione grupo(s)" i18n-placeholder i18n-label></app-input>
                </div>

                <div class="form-group">
                  <label i18n>Situação</label>
                  <select type="text" tabindex="4" class="form-control" formControlName="verified" required>
                    <option i18n value="" selected>Todas</option>
                    <option i18n value="false" selected>Pendente</option>
                    <option i18n value="true" selected>Confirmado</option>
                  </select>
                </div>

              <div class="filter-dropdown__footer">
                <button i18n type="submit" class="btn btn-info btn-block" (click)="disableMenu()"
                  data-dismiss="modal">Aplicar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="mt-5 mb-3">
    <div class="table-responsive table-hover table-block">
      <table class="table bg-white">
        <thead>
          <tr>
            <th i18n class="text-center">Usuário</th>
            <th i18n class="text-center">Empresa</th>
            <th i18n class="text-center">Grupo de usuário</th>
            <th i18n class="text-center">Situação</th>
            <th i18n class="text-center">Habilitado</th>
            <th *appPermission="['UpdateUser']" i18n class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td (click)="openUserView(user)">
              <div class="d-flex">
                <div class="mr-4">
                  <app-avatar [user]="user" class="avatar-header"></app-avatar>
                </div>
                <div>
                  <p *ngIf="user.name" class="h5 m-0">{{user.name}}</p>
                  <p *ngIf="user.role" class="text-secondary m-0">{{user.role}}</p>
                  <p *ngIf="user.email" class="text-secondary mt-1 m-0">{{user.email}}</p>
                </div>
              </div>
            </td>
            <td (click)="openUserView(user)" class="text-center font-weight-bold">{{companyList && companyList[user.companyId] && companyList[user.companyId].name}}</td>
            <td (click)="openUserView(user)">
              <div *ngFor="let value of user.groupIds" style="display: inline;">
                <div *ngIf="userGroupList[value  + user.email]" class="badge badge-fade-info mr-1">
                  {{ userGroupList[value + user.email].prettyName }}
                </div>
              </div>
            </td>
            <td (click)="openUserView(user)" class="text-center">
              <span i18n *ngIf="user.verified; else elseBlock">Confirmado</span>
              <ng-template #elseBlock><span class="text-muted" i18n>Pendente</span></ng-template>
            </td>
            <td *appPermission="['UpdateUser']" class="text-center">
              <div class="custom-control custom-switch">
                <div class="form-group mt-1">
                  <label class="custom-control-label" [ngClass]="{checked: user?.enabled}">
                    <input type="checkbox" class="form-control custom-control-input" (change)="enableOrDisableUser(user)">
                  </label>
                </div>
              </div>
            </td>
            <td *appPermission="['UpdateUser']" class="text-center">
              <div>
                <button type="button" class="btn text-secondary btn-icon-block"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  [attr.hidden]="hasPermissionContract(user)">
                  <i class="material-icons">more_horiz</i>
                </button>
                <div class="dropdown-menu" aria-labelledby="btnGroupDrop4">
                  <a *ngIf="!user.verified" class="dropdown-item" (click)="sendReinvite(user)" i18n>Reenviar convite</a>
                  <a class="dropdown-item" (click)="openUserEdit(user)" i18n>Editar Usuário</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <app-pagination (items)="setUsers($event)" [params]="params" [deleteEvent]="deleteEvent" [service]="userService"></app-pagination>
</div>
