import { BaseApiModel } from './BaseApiModel';

export class User extends BaseApiModel {
    public name: string;
    public email: string;
    public cpf?: string;
    public password: string;
    public registryNumber: number;
    public companyId: any;
    public role: string;
    public avatar: string;
    public superUser: boolean;
    public groupIds: any[];
    public rememberMe: boolean;
    public verified: boolean;
    public description: string;
    public modifiedAt: Date;
    public createdAt: Date;
    public verificationUrl: string; // used only to create user
    public prettyNames: any[];
    public enabled: boolean;
    public extraData: any;
    public files: any[];
    public authorId: number;
}
