import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClosedModalError } from 'src/app/core/errors';
import {
  Contract, Lot, StatusLot, Situation, TypeLot, User, AlertItem,
  AlertType, ComponentModal, PreProcessSettings, Samplings
} from 'src/app/core/models';
import {
  AlertService, ContractService, LotService, ModalService, UserGroupService,
  UserService, PreProcessSettingsService, SamplingsService, StorageKey, StorageService
} from 'src/app/core/services';
import { LotAuditDetailsComponent } from 'src/app/modals/lot-audit-details/lot-audit-details.component';
import { LoteAuditInfoComponent } from 'src/app/modals/lote-audit-info/lote-audit-info.component';
@Component({
  selector: 'app-audit-lots',
  templateUrl: './audit-lots.component.html',
  styleUrls: ['./audit-lots.component.sass']
})

export class AuditLotsComponent implements OnInit {
  public lots: Array<Lot> = [];
  public columns = [
    'special',
    'priority',
    'status',
    'lot',
    'situation',
    'review',
    'samplingPlan',
    'violationTotal',
    'violationSample',
    'violationAudited',
    'violationDisapproved',
    'violationPending',
    'responsibleAuditUser',
    'blockedLot',
    'actions'
  ];
  public columnsToShow = [];
  public params: any = {};
  public statusLot = Object.keys({
    process: 'process',
    audited: 'audit',
    avaiable: 'closed',
  });
  public statusLotI18n = [];
  public priorities = [
    'low',
    'medium',
    'high'
  ];
  public prioritiesI18n = [];
  public priority = 'low';
  public situation = Object.keys(Situation);
  public situationI18n = [];
  public situationById = {};
  public users: Array<User> = [];
  public usersById: { [params: string]: User } = {};
  public userGroups = [];
  public statusI18n: any;
  public typeI18n: any;
  public typeLot = Object.keys(TypeLot);
  public typeLotI18n = [];
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public contract: Contract = new Contract();
  public modelChanged: Subject<string> = new Subject<string>();
  public searchForm: FormGroup;
  public contractId;
  public isLoading: boolean;
  public preProcessSettings: PreProcessSettings;
  public isNqa: number;
  public isLotAmount: number;
  public userEditForm: FormGroup;
  public currentPermissions = [];
  public user: User;
  public changesPermited = false;
  public samplings: any[] = [];
  public isAuditLot = false;
  public isManageLot = false;

  constructor(
    public lotService: LotService,
    public activateRoute: ActivatedRoute,
    private alertService: AlertService,
    private contractService: ContractService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private userGroupService: UserGroupService,
    private preProcessSettingsService: PreProcessSettingsService,
    private samplingsService: SamplingsService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  async ngOnInit() {
    this.isLoading = true;
    this.createForm();
    this.contractId = Number(this.activateRoute.snapshot.params.contractId);
    this.columnsToShow = this.columns.slice(0);
    this.params = {
      contractId: `${this.contractId}`,
      step: 'internalAudit',
      order: 'oldestViolationAt,newestViolationAt',
      'violationsAmount[gt]': 0
    };
    this.user = this.storageService.get(StorageKey.currentUser);
    if (!this.user.superUser) {
      const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
      this.isAuditLot = currentPermissions.filter(f => f.actionIds.indexOf('AuditViolation') >= 0
        && f.contractId === this.contractId).length > 0;
     this.isManageLot = currentPermissions.filter(f => f.actionIds.indexOf('ManageLot') >= 0
        && f.contractId === this.contractId).length > 0;

      this.currentPermissions = currentPermissions &&
        currentPermissions.filter(p =>
          (p.actionIds.indexOf('ManageLot') >= 0 || p.actionIds.indexOf('AuditViolation') >= 0 ||
            p.actionIds.indexOf('ReviewLot') >= 0) && p.contractId === this.contractId);

      if (this.isAuditLot && !this.isManageLot) {
        this.params['targetAuditorId[in]'] = `[${this.user.id}, 0]`;
      } else {
        this.changesPermited = true;
      }
    } else {
      this.changesPermited = true;
      this.isAuditLot = true;
      this.isManageLot = true;
    }

    this.statusI18n = { process: 'Processando', closed: 'Disponível', done: 'Auditado' };
    this.typeI18n = { valid: 'Válidas', invalid: 'Inválidas', all: 'Válidas / Inválidas' };
    this.contractService.getById(this.contractId).then(contract => this.contract = contract);
    await this.createSearchForm();
    await this.userGroupService.list({ contractId: this.contractId }).then(res => {
      this.userGroups = res.result;
    });
    await this.userService.getAll({ 'groupIds[in]': `[${this.userGroups.map(group => group.id).join()}]` }).then(res => {
      res.map(user => {
        if (user.name) {
          this.users.push(user);
          this.usersById[user.id] = user;
        }
      });
    });
    await this.preProcessSettingsService.getById(this.contractId).then(async (res) => {
      this.preProcessSettings = res;
      if (this.preProcessSettings?.audit?.enabledSamplingPlan === true) {
        this.isNqa = res?.audit?.acceptableQualityLevel || 0;
        await this.samplingsService.getSamplings({ acceptableQualityLevel: `${this.isNqa}` }).subscribe(async (res: any[]) => {
          this.samplings = res;
        });
      }
    });
    this.situationI18n.map(item => {
      this.situationById[item.id] = item;
    });
  }

  createForm() {
    this.userEditForm = this.formBuilder.group({
      name: ['']
    });
  }

  loadingEvent(valueEmitted) {
    this.isLoading = valueEmitted;
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: [''],
      type: [''],
      status: [''],
      situation: [''],
      priority: [''],
      user: []
    });
  }


  searchKey(text: string) {
    this.modelChanged.next(text);
  }
  handleSearch() {
    const filterObj: any = {};
    const search = this.searchForm.get('search').value;
    const type = this.searchForm.get('type').value;
    const situation = this.searchForm.get('situation').value;
    const status = this.searchForm.get('status').value;
    const user = this.searchForm.get('user').value;
    const priority = this.searchForm.get('priority').value;
    this.params = {
      contractId: `${this.contractId}`,
      step: 'internalAudit',
      source: 'internalAudit'
    }

    if (search != null && search !== '') {
      filterObj['name[contains,or]'] = `${search}`;
    }
    if (situation !== '' && situation.length > 0) {
      delete this.params.step;
      filterObj['situation[contains,in]'] = `[${situation}]`;
    }
    if (type !== '' && type.length > 0) {
      filterObj['type[contains,in]'] = `[${type}]`;
    }
    if (user !== '' && user?.length > 0) {
      filterObj['targetAuditorId[in]'] = `[${user}]`;
    }
    if (priority !== '' && priority.length > 0) {
      filterObj['priority[in]'] = `[${priority}]`;
    }
    filterObj.sortAsc = 'false';
    if (status !== '' && status.length > 0) {
      if (status.length === 3) {
        delete this.params.step;
        filterObj.source = 'internalAudit'
      } else if (status.length === 2) {
        delete this.params.step;
        if (!status.includes('avaiable')) {
          filterObj['status[in]'] = `[process, done]`;
        }
        if (!status.includes('process')) {
          filterObj['status[in]'] = `[closed, process, done]`;
        }
        if (!status.includes('audited')) {
          filterObj['status[in]'] = `[closed, process]`;
        }
      } else {
        switch (status[0]) {
          case 'audited':
            delete this.params.step;
            filterObj.source = 'internalAudit';
            filterObj.status = 'done';
            break;
          case 'avaiable':
            filterObj.step = 'internalAudit'
            filterObj.source = 'internalAudit';
            filterObj.status = 'closed';
            break;
          case 'process':
            filterObj.step = 'internalAudit'
            filterObj.source = 'internalAudit';
            filterObj.status = 'process';
            break;
        }
      }
    }
    this.params = {
      ...this.params,
      ...filterObj
    };
  }
  async setSpecial(lot: Lot) {
    const data: any = lot;
    let message = '';
    if (data.isSpecial) {
      data.isSpecial = false;
      message = 'AuditLotNotSpecial';
    } else {
      data.isSpecial = true;
      message = 'AuditLotSpecial';
    }
    if (!this.situation.includes(data.situation)) {
      delete data.situation;
    }
    await this.lotService.update(data).then(res => {
      this.alertService.show(
        new AlertItem(message, AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('AuditLotSpecialError', AlertType.danger)
      );
      data.isSpecial = false;
    });
  }

  async setActive(lot: Lot) {
    let message = '';
    if (lot.enabled) {
      lot.enabled = false;
      message = 'DisabledLot';
    } else {
      lot.enabled = true;
      message = 'EnabledLot';
    }
    if (!this.situation.includes(lot.situation)) {
      delete lot.situation;
    }
    this.lotService.update(lot).then(res => {
      this.alertService.show(
        new AlertItem(message, AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('ErrorEditLot', AlertType.danger)
      );
      lot.enabled = !lot.enabled;
    });
  }

  openAuditView(id: number) {
    this.modalService
      .show(new ComponentModal(LoteAuditInfoComponent, { lotId: id }))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  async setValue(lot: Lot, event: any) {
    this.isLotAmount = lot.violationsAmount;
    if (event.target.value === 'simple') {
      const findLevel = this.samplings.find(s => this.isLotAmount >= s.minAmountViolations && this.isLotAmount <= s.maxAmountViolations);
      lot.totalSampling = findLevel.amountSampling;
    } else {
      lot.totalSampling = 0;
    }
  }

  redirectToViolations(lotId: number, allowEditAudit: boolean, lot: Lot) {
    if (allowEditAudit) {
      lot.status = 'process';
      this.lockLotForAuditor(lot);
      localStorage.removeItem('internalAuditView');
    } else {
      localStorage.setItem('internalAuditView', 'true');
    }
    this.router.navigateByUrl('pre-process/internalAudit/' + this.contractId + '/' + lotId, {
      state: { allowEditAudit }
    });
  }

  setPrioritiesI18n(priorities) {
    this.prioritiesI18n = priorities;
  }

  setLots(list) {
    this.lots = list;
  }

  getPriority(lot) {
    return lot.priority || this.priority;
  }
  async setPriority(lot, priority) {
    const data = lot;
    data.priority = priority;
    let message = '';
    switch (priority) {
      case 'low':
        message = 'AuditLotPriorityLow';
        break;
      case 'medium':
        message = 'AuditLotPriorityMedium';
        break;
      case 'high':
        message = 'AuditLotPriorityHigh';
        break;
    }
    if (!this.situation.includes(data.situation)) {
      delete data.situation;
    }
    await this.lotService.update(data).then(res => {
      this.alertService.show(
        new AlertItem(message, AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('AuditLotPriorityError', AlertType.danger)
      );
    });
  }

  setResponsibleUser(event: any, lot: Lot) {
    const data: any = lot;
    data.targetAuditorId = Number(event.target.value) || 0;
    if (data.lockUserId > 0) {
      data.lockUserId = 0;
    }
    if (data.priority === '') {
      delete data.priority;
    }
    if (!this.situation.includes(data.situation)) {
      delete data.situation;
    }
    this.lotService.update(data, false).then(res => {
      if (res.targetAuditorId === Number(event.target.value)) {
        this.alertService.show(
          new AlertItem('SetUserInAuditLot', AlertType.success)
        );
      }
    }).catch(err => {
      if (err.status === 403) {
        this.alertService.show(
          new AlertItem('AuditLotUserWithoutPermission', AlertType.danger)
        );
      }
    });
  }

  setBlockedLot(lot: Lot, block = false) {
    const data: any = lot;
    let message = '';
    const now = new Date();
    if (data.targetAuditorId === 0) {
      data.targetAuditorId = this.user.id;
    }
    if (data.lockUserId === data.targetAuditorId && (['process', 'closed'].includes(data.status))) {
      data.lockUserId = 0;
      data.lockDate = new Date(null);
      localStorage.removeItem('auditLockUser');
      message = 'AuditLotUnlocked';
    } else
      if (data.targetAuditorId > 0) {
        data.lockUserId = data.targetAuditorId;
        data.lockDate = now;
        localStorage.setItem('auditLockUser', 'true');
        message = 'AuditLotLocked';
      } else {
        this.alertService.show(
          new AlertItem('SelectAuditLotUser', AlertType.danger)
        );
        return;
      }
    if (data.priority === '') {
      delete data.priority;
    }
    if (!this.situation.includes(data.situation)) {
      delete data.situation;
    }
    this.lotService.update(data).then(res => {
      this.alertService.show(
        new AlertItem(message, AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('AuditLotLockedError', AlertType.danger)
      );
      localStorage.removeItem('auditLockUser');
    });
  }

  lockLotForAuditor(lot) {
    const data = lot;
    if (!data.targetAuditorId || data.targetAuditorId === 0) {
      data.targetAuditorId = this.user.id;
    }
    data.lockUserId = data.targetAuditorId;
    data.lockDate = new Date();
    if (!this.situation.includes(data.situation)) {
      delete data.situation;
    }
    this.lotService.update(data).then(res => {
      localStorage.setItem('auditLockUser', 'true');
      this.alertService.show(
        new AlertItem('AuditLotLocked', AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('AuditLotLockedError', AlertType.danger)
      );
      localStorage.removeItem('auditLockUser');
    });
  }

  openLotDetail(lot: Lot) {
    this.modalService.show(new ComponentModal(LotAuditDetailsComponent, lot));
  }

  sumTotAudits(lot) {
    let tot = 0
    if (lot.audits?.length > 0) {
      tot = lot.audits.reduce((sum , { totalAudited }) => sum + totalAudited, 0 )
    }
    return !isNaN(tot) ? tot : 0;
  }
  sumTotAuditRefuseds(lot) {
    let tot = 0
    if (lot.audits?.length > 0) {
      tot = lot.audits.reduce((sum , { refusedAmount }) => sum + refusedAmount, 0 )
    }
    return !isNaN(tot) ? tot : 0;
  }

}
