<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-12 col-md-12">
      <form>
        <div class="form-row">
          <div class="col-12 col-md-4 mb-2">
            <div class="form-group">
              <input i18n-placeholder type="text" class="form-control search-placeholder" placeholder="&#xf002;  Buscar registro" autocomplete="off">
            </div>
          </div>
          <div class="col-12 col-md-4 mb-2">
            <div class="dropdown">
              <button type="button" class="btn btn-secondary custom-class-btn" i18n><i class="material-icons">filter</i>
                Filtrar</button>
              <div class="dropdown-content dropdown-layout">
                <div class="modal-footer">
                  <button i18n type="submit" class="btn btn-primary"
                    data-dismiss="modal">Aplicar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
