<div class="modal-dialog modal-dialog-centered modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <h5 i18n class="modal-title">Trocar senha</h5>
      <button type="button" (click)="onCancel()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <app-input i18n-label type="password" label="Senha antiga" formControlName="oldPassword" inputRequired=true></app-input>
        <app-input i18n-label type="password" label="Nova senha" formControlName="password" inputRequired=true></app-input>
        <app-input i18n-label type="password" label="Confirmar nova senha" formControlName="passwordConfirmation" inputRequired=true></app-input>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="onCancel()" class="btn btn-outline-secondary">
        <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
      </button>
      <button (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
