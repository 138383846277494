import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ViolationService, ModalService, ViolationReportService, ViewViolationService
} from 'src/app/core/services';
import * as moment from 'moment';
import { Lane, Reason, Regulation, Spot, Violation, ComponentModal, Category } from 'src/app/core/models';
import { DetailsViolationComponent } from 'src/app/modals/details-violation/details.component';
import {LoteInfoComponent} from 'src/app/modals/lote-info/lote-info.component';

@Component({
  selector: 'app-violations-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.sass']
})
export class ViolationsReportDetailsComponent implements OnInit, OnDestroy {
  public contractId: any = {};
  public dateId: string;
  public laneId: string;
  public laneCode: any = [];
  public spotInfo: string;
  public laneInfo: string;
  public hideLane: boolean;
  public hideSpot: boolean;
  public loading: boolean;
  public contractDataViolationsSub: Subscription;
  public filterViewViolationsSub: Subscription;
  public filteredParamsViolationsSub: Subscription;
  public violations: Violation[] = [];
  public lanes: Lane[] = [];
  public spots: Spot[] = [];
  public regulations: Regulation[] = [];
  public reasons: Reason[] = [];
  public categories: Category[] = [];
  public params: any = {};
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public violationsSteps: any[] = ['validateLane', 'validateCalibration', 'validateSpeed', 'validateFiles',
    'validateExemptPeriod', 'validateViolationLimit', 'verifyValid', 'verifyInvalid', 'triage', 'typing', 'verify',
    'validate', 'lotAttribution', 'internalAudit', 'revision', 'filter', 'done', 'quarantine'];
  public violationsStepsResource: any[] = [];
  public periodViolationsSub: Subscription;
  public startDate;
  public endDate;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    public violationService: ViolationService,
    private violationReportService: ViolationReportService,
    private viewViolationService: ViewViolationService,
  ) { }

  async ngOnInit() {
    if (this.activatedRoute.snapshot.parent) {
      this.contractId = this.activatedRoute.snapshot.parent.params.contractId;
    }

    this.dateId = this.activatedRoute.snapshot.params.dateId;

    this.periodViolationsSub = this.violationReportService.getPeriodViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((dataPeriod: any) => {
        this.startDate = dataPeriod.startDate;
        this.endDate = dataPeriod.endDate;
      });

    this.params = {};
    this.params.contractId = this.contractId;

    const now = new Date().toISOString();
    const endHour = { hour: 23, minutes: 59, seconds: 59 };
    this.endDate = moment(this.endDate).set(endHour).format();
    if (this.startDate && this.endDate) {
      this.params['date[gte]'] = this.startDate;
      this.params['date[lte]'] = this.endDate;
    } else {
      this.params['date[gte]'] = moment(now).subtract(1, 'month').utc().format();
      this.params['date[lte]'] = moment(now).utc().format();
    }

    this.contractDataViolationsSub = this.violationReportService.getContractDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.regulations = data.regulations;
        this.lanes = data.lanes;
        this.spots = data.spots;
        this.reasons = data.reasons;
        this.loading = true;
      });

    this.filteredParamsViolationsSub = this.violationReportService.getFilterParamsDetailsViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        if (data.spotsIds && data.spotsIds.length > 0) {
          const lanesWithSpot = this.lanes.filter(lane => data.spotsIds.includes(lane.spotId)).map(lane => lane.code);
          if (lanesWithSpot.length > 0) {
            this.params['laneCode[in]'] = `[${lanesWithSpot.join()}]`;
          }
        }

        if (data.laneIds && data.laneIds.length > 0) {
          const laneIds =  data.laneIds.map(Number);
          const lanesCode = this.lanes.filter(lane => laneIds.includes(lane.id)).map(lane => lane.code);
          this.params['laneCode[in]'] = `[${lanesCode.join()}]`;
        }

        if (data.regulationsIds && data.regulationsIds.length > 0) {
          this.params['regulationId[in]'] = `[${data.regulationsIds.join()}]`;
        }

        if (data.situationsIds && data.situationsIds.length > 0) {
          if (data.situationsIds.length === 1 && data.situationsIds.includes('inProcess')) {
            this.params['step[neq,and]'] = 'done';
            this.params['step[neq]'] = 'lotAttribution';
          }

          if (data.situationsIds.length > 1 && data.situationsIds.includes('inProcess')) {
            this.params['status[in]'] = `[invalid,valid]`;
          }

          if (!data.situationsIds.includes('inProcess')) {
            this.params['status[in]'] = `[${data.situationsIds.join()}]`;
          }
        }
        this.params.order = 'date';

        if (data.reasonsIds && data.reasonsIds.length > 0 && data.situationsIds.includes('invalid')
          && data.reasonsIds.length < this.reasons.length) {
          const reasonsCode = this.reasons.filter(reason => data.reasonsIds.includes(reason.id)).map(reason => reason.code);
          this.params['reason.code'] = reasonsCode;
        }
        if (data.date != null) {
          delete this.params['date[gte]'];
          delete this.params['date[lte]'];
          const dateStart = `${data.date}T00:00:00`;
          const dateEnd = `${data.date}T23:59:59`;
          this.params['date[gte]'] = moment(dateStart).utc().format();
          this.params['date[lte]'] = moment(dateEnd).utc().format();
        }
      });
  }

  setViolations(list) {
    this.violations = list;

    if (this.regulations.length > 0) {
      this.loading = true;
    }

    this.violations.map(violation => {
      this.laneCode.push(violation.laneCode);
    });
    this.getSpotInfo(this.laneCode);
    this.getLaneInfo(this.laneCode);
  }

  getRegulationCode(regulation) {
    return this.regulations.filter(item => item.id === regulation).map(item => item.code).shift();
  }

  getRegulationInfo(regulation) {
    if (this.regulations.length > 0) {
      const regulationInfo = this.regulations.filter(item => item.id === regulation).shift();
      return regulationInfo.description;
    }
  }

  getAuthorInfo(violation) {
    let item;
    if (violation) {
      if (this.getNormalizedHistory(violation)[0].id === 'lotAttribution') {
        item = this.getNormalizedHistory(violation)[1];
      } else {
        item = this.getNormalizedHistory(violation).shift();
      }
    }
    return item?.authorName || null;
  }

  getHistories(violation) {
    return this.getNormalizedHistory(violation);
  }

  getNormalizedHistory(violationsHistory: []) {
    const resultNormalized = [];
    Object.entries(violationsHistory || []).map(item => {
      const id: string = item[0];
      const data: any = item[1];

      if (Array.isArray(data)) {
        data.map(itemInside => {
          const itemObj: any = {};
          const stepName = this.violationsStepsResource.filter(step => step.id === id).shift();
          itemObj.id = id;
          itemObj.title = stepName ? stepName.value : null;
          itemObj.date = itemInside.date;
          if (itemInside.authorName) {
            itemObj.authorName = itemInside.authorName;
          }
          resultNormalized.push(itemObj);
        });
      } else {
        const itemObj: any = {};
        itemObj.id = id;
        itemObj.title = this.violationsStepsResource.filter(step => step.id === id).shift().value;
        itemObj.date = data.date;
        if (data.authorName) {
          itemObj.authorName = data.authorName;
        }
        resultNormalized.push(itemObj);
      }
    });

    resultNormalized.sort((a, b) => +new Date(b.date) - +new Date(a.date));
    return resultNormalized;
  }

  async showDetails(violation) {
    const data = {
      regulations: this.regulations,
      lanes: this.lanes,
      categories: this.categories
    };

    this.viewViolationService.emitDataViewContractViolation(data);

    await this.modalService.show(new ComponentModal(DetailsViolationComponent, { violation }))
      .catch((error) => console.log(error));
  }

  getSpotInfo(lane) {
    const spots = [];
    const spotsInfo = [];
    lane.map(laneItem => {
      spots.push(this.lanes.filter(item => item.code === laneItem).shift());
    });
    if (spots.length > 0) {
      spots.map(spotItem => {
        spotsInfo.push(this.spots.filter(item => item.laneIds.includes(spotItem.id)).shift());
      });
      this.spotInfo = `${spotsInfo[0].code} - ${spotsInfo[0].description}`;
      spotsInfo.map(item => {
        if (item.id !== spotsInfo[0].id) {
          this.hideSpot = true;
        }
      });
    }
  }

  getLaneInfo(lane) {
    const laneInfo = lane[0] && this.lanes.filter(item => item.code === lane[0])
      .map(item => item && item.prettyNames && item.prettyNames[0]).shift();

    if (laneInfo) {
      lane.map(item => {
        if (item !== lane[0]) {
          this.hideLane = true;
        }
      });
      this.laneInfo = laneInfo;
    }
  }

  ngOnDestroy() {
    if (this.contractDataViolationsSub) {
      this.contractDataViolationsSub.unsubscribe();
      this.filteredParamsViolationsSub.unsubscribe();
    }
  }

  setResources(value) {
    this.violationsStepsResource = value;
  }
  async showHistory(lotId) {
    await this.modalService.show(new ComponentModal(LoteInfoComponent, { lotId }))
      .catch((error) => console.log(error));
  }
}
