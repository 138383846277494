  <div class="page-title d-flex align-items-start">
    <div>
      <p class="page-title__link-back"><a routerLink="/violations" i18n>Infrações</a></p>
      <h2 i18n>Relatório de infrações</h2>
    </div>
  </div>

  <ng-container *ngIf="loading; else loadingBlock">
    <div class="d-flex mb-4">
      <!-- <div class="d-flex mr-3">
        <button class="btn btn-fade-secondary btn-icon-block mr-2"><i class="material-icons">view_comfy</i></button>
        <button class="btn btn-fade-secondary btn-icon-block"><i class="material-icons">dehaze</i></button>
      </div> -->

      <div class="w-100">
        <input type="text" class="form-control form-search" [(ngModel)]="searchValue"
        placeholder="Buscar registro" (ngModelChange)="searchKey($event)">
      </div>
    </div>

    <div class="table-flex mb-4">
      <div class="table-flex__header">
        <p class="w-40" i18n>Contratos</p>
        <p class="w-35" i18n>Enquadramento</p>
        <p class="w-20" i18n>Quantidade <br />de registros <br />
          <span i18n-appTooltip appTooltip="Todo o período" class="d-inline-flex align-items-center">
            {{ getTotal() }}
            <i class="material-icons text-light icon-sm ml-2">info</i>
          </span>
        </p>
        <p class="w-5">
          <button class="btn btn-sm btn-icon-block"
            i18n-appTooltip appTooltip="Expandir/Retrair todos"
            [ngClass]="{ 'btn-outline-secondary': !allCollapse, 'btn-secondary': allCollapse }"
            (click)="toggleallCollapse()">
              <i class="material-icons" *ngIf="allCollapse">unfold_less</i>
              <i class="material-icons" *ngIf="!allCollapse">unfold_more</i>
            </button>
        </p>
      </div>

      <div class="table-flex__item" *ngFor="let contract of items; let i = index" [class.collapsed]="contract.collapse">
        <div class="table-flex__row" [ngClass]="{'table-flex__row--no-result': contract.total === 0}">
          <p class="w-40"><a routerLink="/violations/violations-report/{{ contract.id }}">{{ contract.code }} - {{ contract.name }}</a></p>
          <p class="w-35"><span *ngIf="!contract.collapse" i18n>Todos</span></p>
          <p class="w-20"><strong>{{ contract.total }}</strong></p>
          <p class="w-5">
            <button class="btn btn-sm btn-icon-block"
            [ngClass]="{ 'btn-outline-secondary': !contract.collapse, 'btn-secondary': contract.collapse }"
            (click)="toggleCollapse(contract)" *ngIf="contract.total > 0">
              <i class="material-icons" *ngIf="contract.collapse">unfold_less</i>
              <i class="material-icons" *ngIf="!contract.collapse">unfold_more</i>
            </button>
          </p>
        </div>

        <div class="table-flex__item collapse" *ngFor="let regulation of contract.regulations">
          <div class="table-flex__row">
            <p class="w-40"></p>
            <p class="w-35 d-flex align-items-center justify-content-center">
              <span i18n-appTooltip [appTooltip]="regulationsById && regulationsById[regulation.id].description" class="d-inline-flex align-items-center">
                <i class="material-icons text-light mr-2">info</i>
                {{ regulation.code }}
              </span>
            </p>
            <p class="w-20">{{ regulation.qtd }}</p>
            <p class="w-5"></p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loadingBlock>
    <div class="loading-page">
      <div class="loading-spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </ng-template>

  <app-pagination-simple *ngIf="loading" class="mt-2" (items)="setItems($event)"
  [data]="contractsViolations" [deleteEvent]="deleteEvent"></app-pagination-simple>
