import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreProcessSettings, Regulation, ComponentModal, Steps } from 'src/app/core/models';
import { PreProcessSettingsService, ContractService, ModalService, ViolationStepReportService } from 'src/app/core/services';
import { RegulationService } from 'src/app/core/services/regulation.service';
import { ClosedModalError } from 'src/app/core/errors';
import { ChartItem } from 'src/app/core/models/ChartItem';
import { ChartType } from 'src/app/core/enums/ChartType';
import { ViolationStepViewModalComponent } from '../violation-step-view-modal/violation-step-view-modal.component';

@Component({
  selector: 'app-violation-step-list',
  templateUrl: './violation-step-list.component.html',
  styleUrls: ['./violation-step-list.component.sass']
})
export class ViolationStepListComponent implements OnInit {
  public resourcesToBeTranslated = Object.values(Steps);
  public violationStepsDefault = Object.values(Steps);
  public resources = [];
  public steps: string[] = [];
  public preProcessSettings: PreProcessSettings;
  public columns = [];
  public preProcessSteps = [];
  public regulationByIds: { [params: string]: Regulation } = {};
  public violationSteps: any = [];
  public regulations: Array<Regulation> = [];
  public chartItems: ChartItem[] = [];
  public tableColumns: any = [];
  public tableRows: any = [];
  public barType = ChartType.bar;
  public pieType = ChartType.pie;
  public loading = null;
  public total: any = 0;

  constructor(
    private router: Router,
    private preProcessSettingsService: PreProcessSettingsService,
    private activatedRoute: ActivatedRoute,
    private regulationService: RegulationService,
    private violationStepReportService: ViolationStepReportService,
    private contractService: ContractService,
    private modalService: ModalService) { }

  ngOnInit() {
    const contractId = this.activatedRoute.snapshot.params.contractId;
    this.loading = this.preProcessSettingsService.getById(contractId)
      .then(async result => {
        this.preProcessSettings = result;
        if (this.preProcessSettings.violationAgeAlert == null) {
          this.preProcessSettings.violationAgeAlert = 10;
        }
        if (this.preProcessSettings.violationStepOrder != null) {
          this.columns = this.preProcessSettings.violationStepOrder;
        } else {
          this.columns = Object.keys(this.preProcessSettings.violationSteps);
          this.columns.splice(this.columns.indexOf('firstStep'), 1);
          const order = this.columns.reduce(
            (accumulator, currentValue) => ({
              ...accumulator,
              [currentValue]: currentValue,
            }),
            {}
          );
          this.columns = this.violationStepsDefault.map(key => order[key]).filter((column) => column !== undefined);
        }
        await this.contractService.getById(contractId).then(async contract => {
          await this.regulationService.getAll({ regionId: contract.regionId }).then(async regulations => {
            this.regulations = regulations;
            await this.violationStepReportService.list({ contractId }).then(res => {
              this.violationSteps = res.result.filter(e => Number(e.regulationId) > 0);
              this.violationSteps.forEach(async violationStep => {
                await this.regulationService.getById(violationStep.regulationId).then(regulation => {
                  if (this.regulationByIds[violationStep.regulationId] == null) {
                    this.regulationByIds[violationStep.regulationId] = regulation;
                  }
                });
              });
              setTimeout(() => {
                this.loadChart();
                this.loadColumnStep();
                this.loadRowViolations();
                this.showAllValues();
              }, 20);
            });
          });
        });
      }).finally(() => {
        this.loading = null;
      });
  }

  routerLotStep() {
    const contractId = this.activatedRoute.snapshot.params.contractId;
    this.router.navigate([`/pre-process/lot-step/${contractId}`]);
  }

  accountStep(violationSteps) {
    violationSteps.forEach(violationStep => {
      const item = violationStep;
      const obj: any = {};
      obj.violationAgeAlert = false;
      Object.keys(item).forEach(key => {
        if (this.columns.includes(key)) {
          let result = 0;
          Object.keys(violationStep[key]).forEach(date => {
            if (obj[key] == null) {
              obj[key] = {};
              obj.violationDates = [];
            }
            result += (violationStep[key][date]);
            obj.violationDates.push(date);
            if (this.dateExpired(date)) {
              obj.violationAgeAlert = true;
            }
          });
          obj[key].count = result;
        } else {
          obj[key] = violationStep[key];
        }
      });
      this.preProcessSteps.push(obj);
    });
  }

  sumLineViolationSteps(violationStep) {
    let total = 0;
    Object.keys(violationStep).forEach(key => {
      if (violationStep[key].count != null) {
        total += violationStep[key].count;
      }
    });
    return total;
  }

  sumColumnsViolationSteps(violationSteps, step = null) {
    let total = 0;
    if (step != null) {
      violationSteps.forEach(violationStep => {
        if (violationStep[step] != null && violationStep[step].count != null) {
          total += violationStep[step].count;
        }
      });
    } else {
      violationSteps.forEach(violationStep => {
        Object.keys(violationStep).forEach(key => {
          if (violationStep[key].count != null) {
            total += violationStep[key].count;
          }
        });
      });
    }
    return total;
  }

  dateExpired(violationDate) {
    if (this.preProcessSettings == null) {
      return false;
    }
    const olddestDate = new Date(violationDate);
    const dateNow = new Date();
    const diffc = dateNow.getTime() - olddestDate.getTime();
    const days = Math.round(Math.abs(diffc / (1000 * 60 * 60 * 24)));
    return this.preProcessSettings.violationAgeAlert != null && days > this.preProcessSettings.violationAgeAlert;
  }

  openViolationStepView(regulationId) {
    const violationSteps = this.violationSteps.filter(stepCounter => stepCounter.regulationId === regulationId);

    const columnStep = [];

    this.columns.map((step, index) => {
      columnStep[index] = {};
      columnStep[index].step = step;
      columnStep[index].stepName = (this.resources.find(r => r.id === step)).value;
    });

    this.modalService.show(new ComponentModal(ViolationStepViewModalComponent, { violationSteps, regulationId, columnStep }))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  loadChart() {
    const reducer = (acc, curr) => acc + curr;
    const items: ChartItem[] = [];
    this.violationSteps.forEach(item => {
      const label = this.regulations.find(e => {
        const id  = e.id + '';
        const regulationId = item.regulationId + '';
        return id === regulationId;
      });
      const values = {};
      this.columns.forEach(step => {
        const key = this.resources.find(r => r.id === step);
        if (item[step] != null) {
          const { ...itemByStep } = item[step];
          delete itemByStep.oldestViolationAt;
          delete itemByStep.newestViolationAt;
          const itemValues = Object.values(itemByStep);
          if (itemValues.length > 0) {
            const value = itemValues.reduce(reducer);
            values[key.value] = value;
          }
        } else {
          values[key.value] = 0;
        }
      });
      items.push(new ChartItem(label.code, values));
    });
    this.chartItems = items;
  }

  loadColumnStep() {
    const reducer = (acc, curr) => acc + curr;
    const tableColumns = [];
    const columnStep = {};

    this.columns.map((step, index) => {
      columnStep[index] = {};
      columnStep[index].step = step;
      columnStep[index].stepName = (this.resources.find(r => r.id === step)).value;
      columnStep[index].violations = [];
      this.total = 0;

      this.violationSteps.map((item, i) => {
        const key = this.regulations.find(e => {
          const id  = e.id + '';
          const regulationId = item.regulationId + '';
          return id === regulationId;
        }).code;
        if (item[step] != null) {
          const {...itemByStep} = item[step];
          delete itemByStep.oldestViolationAt;
          delete itemByStep.newestViolationAt;
          const itemValues = Object.values(itemByStep);
          if (itemValues.length > 0) {
            const value = itemValues.reduce(reducer);
            columnStep[index].violations[i] = { violation: key, value };
            this.total += value;
          }
        } else {
          columnStep[index].violations[i] = { violation: key, value: 0 };
        }
      });

      columnStep[index].violationsTotal = this.total;
      tableColumns.push(columnStep[index]);
    });

    this.tableColumns = tableColumns;
  }

  loadRowViolations() {
    const reducer = (acc, curr) => acc + curr;
    const tableRows = [];
    let violationAgeAlert = false;

    this.violationSteps.map(violation => {
      this.total = 0;

      this.columns.map(step => {
        if (violation[step] != null) {
          const {...itemByStep} = violation[step];
          delete itemByStep.oldestViolationAt;
          delete itemByStep.newestViolationAt;
          const itemValues = Object.values(itemByStep);
          if (itemValues.length > 0) {
            const values = itemValues.reduce(reducer);
            this.total += values;
            const date = Object.keys(violation[step]);
            if (this.dateExpired(date)) {
              violationAgeAlert = true;
            }
          }
        }
      });
      const key = this.regulations.find(e => {
        const id  = e.id + '';
        const regulationId = violation.regulationId + '';
        return id === regulationId;
      }).code;
      tableRows.push({
        id: violation.regulationId,
        violation: key,
        total: this.total,
        ageAlert: violationAgeAlert
      });
    });

    this.tableRows = tableRows;
  }

  showAllValues() {
    this.total = 0;
    this.tableColumns.map(item => {
      this.total += item.violationsTotal;
    });

    return this.total;
  }

  setResources(value) {
    this.resources = value;
  }

  onResize() {
    this.loadChart();
  }
}
