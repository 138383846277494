<div class="modal-dialog modal-full modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n><strong>Detalhes do Lote {{ initialState?.name }}</strong></h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body pt-0">
      <div class="loading-page" *ngIf="loading">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <div class="mt-3" *ngIf="!loading">
        <div class="row">
          <div class="col-md-12">
            <div class="wrapper">
              <div class="title-content">
                <div class="row">
                  <div class="col"><p i18n>Data de cometimento</p></div>
                  <div class="col"><p i18n>Quantidade</p></div>
                  <div class="col"><p i18n>Válidas</p></div>
                  <div class="col"><p i18n>Inválidas</p></div>
                  <div class="col"><p i18n>Observações</p></div>
                </div>
              </div>
              <div class="content-data">
                <div class="item" *ngFor="let data of violationsDataPaginated[currentPage]">
                  <div class="item-button" (click)="openLotDetail(data)" [ngClass]="{ 'active': data?.isOpen }">
                    <div class="row">
                      <div class="col"><p i18n>{{ data?.date | moment: 'L' }}</p></div>
                      <div class="col"><p i18n>{{ data?.violations.length }}</p></div>
                      <div class="col"><p i18n>{{ data?.valids }}</p></div>
                      <div class="col"><p i18n>{{ data?.invalids }}</p></div>
                      <div class="col pr-5">
                        <button class="btn btn-secondary" (click)="openExtraData(initialState)" *ngIf="initialState?.extraData" i18n>Visualizar</button>
                        <span *ngIf="!initialState?.extraData">-</span>
                      </div>
                    </div>
                  </div>
                  <div class="item-content" [@openPanel]="getStatePanel(data?.isOpen)">
                    <div class="row pb-1em">
                      <div class="col passId"><p i18n>Id da infração</p></div>
                      <div class="col"><p i18n>Data de cometimento</p></div>
                      <div class="col"><p i18n>Data de recebimento</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.triage"><p i18n>Triado em...</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.typing"><p i18n>Digitado em...</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.verifyValid"><p i18n>Verificado em...</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.validate"><p i18n>Validado em...</p></div>
                      <div class="col" *ngIf="isAudit"><p i18n>Auditado em...</p></div>
                    </div>
                    <div class="row data" *ngFor="let violation of data?.violations">
                      <div class="col passId"><p i18n>{{ violation?.passId }}</p></div>
                      <div class="col"><p i18n>{{ violation?.date | moment: 'L' }} {{ violation?.date | moment: 'LTS' }}</p></div>
                      <div class="col"><p i18n>{{ convertDate(violation?.createdAt, violation.timezone) | moment: 'L' }} {{ convertDate(violation?.createdAt, violation.timezone) | moment: 'LTS' }}</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.triage">
                        <p i18n *ngIf="violation?.history?.triage">{{ convertDate(violation?.history?.triage[0]?.date, violation.timezone) | moment: 'L' }} {{ convertDate(violation?.history?.triage[0]?.date, violation.timezone) | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.triage">--/--/----</p>
                      </div> <!-- Triado em... -->
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.typing">
                        <p i18n *ngIf="violation?.history?.typing">{{ convertDate(violation?.history?.typing[0]?.date, violation.timezone) | moment: 'L' }} {{ convertDate(violation?.history?.typing[0]?.date, violation.timezone) | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.typing">--/--/----</p>
                      </div> <!-- Digitado em... -->
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.verifyValid">
                        <p i18n *ngIf="violation?.history?.verifyValid">{{ convertDate(violation?.history?.verifyValid[0]?.date, violation.timezone) | moment: 'L' }} {{ convertDate(violation?.history?.verifyValid[0]?.date, violation.timezone) | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.verifyValid">--/--/----</p>
                      </div> <!-- Verificado em... -->
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.validate">
                        <p i18n *ngIf="violation?.history?.validate">{{ convertDate(violation?.history?.validate[0]?.date, violation.timezone) | moment: 'L' }} {{ convertDate(violation?.history?.validate[0]?.date, violation.timezone) | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.validate">--/--/----</p>
                      </div> <!-- Validado em... -->
                      <div class="col" *ngIf="isAudit">
                        <p i18n *ngIf="violation?.history?.audits">{{ convertDate(violation?.history?.audits[0]?.date, violation.timezone) | moment: 'L' }} {{ convertDate(violation?.history?.audits[0]?.date, violation.timezone) | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.audits">--/--/----</p>
                      </div>  <!-- Auditado em... -->
                    </div>
                  </div>
                </div>
                <div *ngIf="violationsData.length === 0">
                  <hr>
                  <p class="text-center text-secondary" i18n>Não há infrações registradas...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 d-flex justify-content-center">
            <div class="pagination" *ngIf="violationIds !== undefined">
              <button (click)="setPage(0)" class="page-item"><i class="material-icons">navigate_before</i></button>
              <button *ngFor="let page of violationsDataPaginated; let i= index" (click)="setPage(i)" class="page-item" [ngClass]="{ 'active': currentPage === i }">{{ i + 1 }}</button>
              <button (click)="setPage(violationsDataPaginated.length -1)" class="page-item"><i class="material-icons">navigate_next</i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
