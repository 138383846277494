import { BaseApiModel } from './BaseApiModel';

export class City extends BaseApiModel {
    public name: string;
    public code: string;
    public regionId: string;
    public stateId: string;
    public modifiedAt: Date;
    public createdAt: Date;
    public deletedAt: Date;
}
