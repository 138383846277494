import { BaseApiModel } from './BaseApiModel';

export class Region extends BaseApiModel {
    public name: string;
    public countryCode: string;
    public plateRegex: string;
    public modifiedAt: Date;
    public createdAt: Date;
    public deletedAt: Date;
}
