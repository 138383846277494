import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModalityVehicle } from '../models/ModalityVehicle';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class ModalityVehicleService extends BaseApiService<ModalityVehicle> {
  get cacheKey(): StorageKey {
    return StorageKey.contractServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    switch (operation) {
      case 'list':
        let regionId = params.regionId;
        if (regionId == null) {
          regionId = 'Brasil';
        }
        return `${environment.apiUrl}/regions/${encodeURIComponent(regionId)}/modalityVehicles`;
      default:
        let url = `${environment.apiUrl}/modalityVehicles`;
        if (id != null) {
          url = url + '/' + encodeURIComponent(id);
        }
        return url;
    }
  }

  protected unparse(obj) {
    return ModalityVehicle.unparse(obj);
  }

  protected parse(obj) {
    return ModalityVehicle.parse(obj);
  }
}
