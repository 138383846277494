import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService, PreProcessSettingsService, ViolationService } from 'src/app/core/services';
import { ComponentModal, PreProcessSettings, Violation } from 'src/app/core/models';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as moment from 'moment-timezone';
import { LotExtraDataComponent } from '../lot-extraData/lot-extraData.component';

@Component({
  selector: 'app-lot-details',
  templateUrl: './lot-details.component.html',
  styleUrls: ['./lot-details.component.sass'],
  animations: [
    trigger('openPanel', [
      state('isOpen', style({
        height: 300,
        opacity: 1
      })),
      state('closed', style({
        height: 0,
        opacity: 0,
        paddingTop: 0,
        paddingBottom: 0
      })),
      transition('closed => isOpen', animate('500ms ease-out')),
      transition('isOpen => closed', animate('500ms ease-out'))
    ])
  ]
})
export class LotDetailsComponent extends BaseModal implements OnInit {
  @Input() initialState;
  isValid: boolean;
  lastState: any;
  public loading: boolean;
  public preProcessSettings: PreProcessSettings;
  public violations: Array<Violation>;
  public violationsData: Array<{
    date: any;
    violations: Array<Violation>;
    valids: number;
    invalids: number;
    isOpen: boolean;
  }> = [];
  public isAudit = false;
  public violationIds = [];
  public currentPage = 0;
  public violationsDataPaginated: any[];

  constructor(
    modalService: ModalService,
    private violationService: ViolationService,
    private preProcessSettingsService: PreProcessSettingsService
  ) {
    super(modalService);
   }

  async ngOnInit() {
    this.loading = true;
    await this.getPreProcessSettings();
    await this.getViolations();
    await this.setViolationsByDate();
    this.loading = false;
  }

  async getPreProcessSettings() {
    await this.preProcessSettingsService.getById(this.initialState.contractId).then(res => {
      this.preProcessSettings = res;
      const lotSteps = Object.keys(this.preProcessSettings.lotSteps);
      this.isAudit = lotSteps.find(l => ['externalAudit', 'internalAudit'].includes(l)) != null ? true : false;
    });
  }

  async getViolations() {
    this.loading = true;
    const property = ['externalAudit', 'internalAudit'].includes(this.initialState.source) ? 'lotIdAudit' : 'lotId';
    await this.violationService.getAll({
      [property]: this.initialState.id,
      order: 'date'
    }).then(res => {
      this.violations = res;
      this.loading = false;
    });
  }

  async setViolationsByDate() {
    this.violations.map(violation => {
      const violationDate = violation.date.toString().split('T')[0];
      if (this.violationsData.filter(a => a.date === violationDate).length > 0) {
        this.violationsData.filter(a => a.date === violationDate)[0]?.violations.push(violation);
      } else {
        this.violationsData.push({
          date: violationDate,
          violations: [violation],
          valids: 0,
          invalids: 0,
          isOpen: false
        });
      }
    });
    this.violationsData.map(item => {
      item.valids = item.violations.filter(a  => a.status === 'valid').length;
      item.invalids = item.violations.filter(a  => a.status === 'invalid').length;
    });
    this.violationsDataPaginated = this.paginate(this.violationsData, 5);
  }

  async onAllSubmited(): Promise<any> {
  }

  openLotDetail(item) {
    if (this.violationsData.filter(a => a === item).shift().isOpen) {
      this.violationsData.filter(a => a === item).shift().isOpen = false;
    } else {
      this.violationsData.map(a => a.isOpen = false);
      this.violationsData.filter(a => a === item).shift().isOpen = true;
    }
  }

  getStatePanel(data: boolean) {
    return data ? 'isOpen' : 'closed';
  }

  convertDate(date, timezone) {
    return moment(date).tz(timezone).utc().format();
  }

  openExtraData(data) {
    this.modalService.show(new ComponentModal(LotExtraDataComponent, data));
  }

  paginate(items, max) {
    if (items !== undefined) {
      return items.reduce((accumulator, item, index) => {
        const group = Math.floor(index / max);
        accumulator[group] = [...(accumulator[group] || []), item];
        return accumulator;
      }, []);
    }
  }

  setPage(index: number) {
    this.currentPage = index;
  }
}
