import { BaseApiModel } from './BaseApiModel';

export class SpotGroup extends BaseApiModel {
    public name: string;
    public contractId: string;
    public enabled: string;
    public spotIds: Array<string>;
    public modifiedAt: Date;
    public createdAt: Date;
    public deletedAt: Date;
}
