import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClosedModalError } from 'src/app/core/errors';
import {
  AlertItem, AlertType, ComponentModal, Equipment, MessageModal, Spot, Lane, Calibration
} from 'src/app/core/models';
import {
  AlertService, EquipmentModelService, EquipmentService, ManufacturerService,
  ModalService, SpotService, LaneService, ContractService
} from 'src/app/core/services';
import {
  EquipmentViewModalComponent
} from '../equipment-view-modal/equipment-view-modal.component';
import {
  EquipmentsCreateOrUpdateCalibrationComponent
} from '../equipments-create-or-update-calibration/equipments-create-or-update-calibration.component';
import {
  EquipmentsCreateOrUpdateModalComponent
} from '../equipments-create-or-update-modal/equipments-create-modal.component';

@Component({
  selector: 'app-equipments-list',
  templateUrl: './equipments-list.component.html',
  styleUrls: ['./equipments-list.component.sass']
})
export class EquipmentsListComponent implements OnInit {
  public contracts = [];
  public equipments = [];
  public modalState;
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public modelChanged: Subject<string> = new Subject<string>();
  public searchForm: FormGroup;
  public dropMenuActive = false;
  // eslint-disable-next-line @typescript-eslint/ban-types
  public params = {};
  public modelEquipment: { [params: string]: Array<Equipment> } = {};
  public equipmentsModels = [];
  public equipmentsModelsAll = [];
  public manufacturers = [];
  public lanes = [];
  public modelsEquipment: { [params: string]: Array<Equipment> } = {};
  public models = [];
  public equipment: Equipment = new Equipment();
  public spotById: { [params: string]: Spot } = {};
  lanesPromise: Promise<Array<Lane>>;
  spotsPromise: Promise<Array<Spot>>;
  public equipmentModelIds = [];


  constructor(
    private formBuilder: FormBuilder,
    public equipmentService: EquipmentService,
    public equipmentModelService: EquipmentModelService,
    public manufacturerService: ManufacturerService,
    public spotService: SpotService,
    private modalService: ModalService,
    private alertService: AlertService,
    private laneService: LaneService,
    public contractService: ContractService
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  ngOnInit() {
    this.manufacturerService.getAll().then(res => {
      this.manufacturers = res;
    });
    this.equipmentModelService.getAll().then(resp => {
      this.equipmentsModelsAll = resp;
    });
    this.lanesPromise = this.laneService.getAll();
    this.spotsPromise = this.spotService.getAll();
    this.equipmentService.getAll().then(res => {
      this.equipmentModelIds = res.filter((v, i, a) =>
        a.findIndex(equipment => equipment.equipmentModelId === v.equipmentModelId) === i)
        .map(v => v.equipmentModelId);
    });
    this.createSearchForm();
  }

  handleSearch() {
    this.equipments = [];
    const filterObj = {};
    const search = this.searchForm.get('search').value;
    const manufacturerId = this.searchForm.get('manufacturerId').value;
    const isEnabled = this.searchForm.get('isEnabled').value;

    if (search != null && search !== '') {
      filterObj['serialNumber[contains,or]'] = `${search}`;
      filterObj['clientCode[contains,or]'] = `${search}`;
      filterObj['contractId[contains,or]'] = `${search}`;
      filterObj['equipmentModelId[contains,or]'] = `${search}`;
    }

    if (isEnabled !== '') {
      filterObj[`enabled[bool]`] = isEnabled;
    }
    if (manufacturerId.length > 0) {
      let equipmentModelIds = this
        .equipmentsModelsAll
        .filter(
          equipmentModel => manufacturerId.includes(equipmentModel.manufacturerId) && this.equipmentModelIds.includes(equipmentModel.id)
        )
        .map(e => e.id);

      const modelIds = this.searchForm.get('equipmentModelId').value || [];
      if (modelIds.length > 0) {
        equipmentModelIds = equipmentModelIds.filter(id => modelIds.includes(id));
      }
      filterObj['equipmentModelId[contains, in]'] = `[${equipmentModelIds}]`;
    }
    this.params = filterObj;
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  activateMenu() {
    this.dropMenuActive = true;
  }

  disableMenu() {
    this.dropMenuActive = false;
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: [''],
      type: [''],
      equipmentModelId: [''],
      manufacturerId: [''],
      isEnabled: ['']
    });
    this.searchForm.get('manufacturerId').valueChanges.subscribe(value => {
      if (value != null) {
        this.equipmentsModels = [];
        const equipmentModelIds = this.equipmentsModelsAll
          .filter(equipamentModel => value.includes(equipamentModel.manufacturerId))
          .map(e => e.id);
        equipmentModelIds.forEach(equipmentModelId => {
          this.equipmentModelService
            .getById(equipmentModelId)
            .then(equipmentModel => {
              this.equipmentsModels.push({
                id: equipmentModel.id,
                name: equipmentModel.name
              });
            });
        });
      }
    });
  };

  setSpots(spotIds) {
    spotIds.forEach(spotId => {
      if (this.spotById[spotId] == null) {
        this.spotsPromise.then(() => {
          this.spotService.getById(spotId).then(spot => {
            this.spotById[spotId] = spot;
          });
        });
      }
    });
  }

  getManufacturerName(equipment: Equipment) {
    const model = this.models[equipment.equipmentModelId] || {};
    const manufacturer = this.manufacturers.find(m => m.id === model.manufacturerId);
    return manufacturer ? manufacturer.name : '';
  }

  setEquipments(list) {
    this.equipments = [];
    list.forEach((equipment: Equipment) => {
      this.modelEquipment[equipment.equipmentModelId] = [];
      if (equipment.enabled == null) {
        equipment.enabled = false;
      }
      if (equipment.spotIds != null) {
        this.setSpots(equipment.spotIds);
      }
      this.equipmentModelService.getById(equipment.equipmentModelId).then(equipmentModel => {
        this.models[equipment.equipmentModelId] = equipmentModel;
      });
      this.contractService.getById(equipment.contractId).then(contract => {
        this.contracts[contract.id] = contract;
      });
      this.setLanes(equipment.laneIds);
      this.equipments.push(equipment);
    });
  }

  setLanes(laneIds) {
    if (laneIds != null) {
      laneIds.forEach(laneId => {
        if (!this.lanes.includes(laneId)) {
          this.lanesPromise.then(() => {
            this.laneService.getById(laneId).then(result => {
              this.lanes[laneId] = result;
            });
          });
        }
      });
    }
  }

  openCreateOrUpdateEquipment(equipment = {}) {
    this.modalService.show(new ComponentModal(EquipmentsCreateOrUpdateModalComponent, equipment))
      .then(data => {
        const modal = data as any;
        const savedEquipment = modal?.component?.instance?.equipment;
        const i = this.equipments.findIndex(e => e.id === savedEquipment?.id);
        if (i > -1) {
          this.equipments[i] = savedEquipment;
          return this.setEquipments(this.equipments);
        }
        this.handleSearch();
      }).catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
          this.modalState = (modalError.modal as ComponentModal).component.instance.lastState;
        } else {
          this.modalState = null;
        }
      });
  }

  openDeleteModal(equipment: Equipment) {
    this.modalService.show(new MessageModal('Remover Equipamento', 'Deseja remover este equipamento?', true))
      .then(() => {
        this.delete(equipment);
      })
      .catch(err => {
        if (err.error && err.error.type === 'equipmentNotDelete') {
          this.alertService.show(new AlertItem('ModelNotDelete', AlertType.danger, false, 5000));
        } else {
          this.alertService.show(new AlertItem('EquipmentDeleteError', AlertType.danger));
        }
      });
  }

  delete(equipment: Equipment) {
    this.equipmentService.delete(equipment.id)
      .then(res => {
        this.deleteEvent.emit(equipment.id);
        this.alertService.show(new AlertItem('EquipmentDeleted', AlertType.success));
      })
      .catch(err => {
        if (err.error && err.error.type === 'equipmentNotDelete') {
          this.alertService.show(new AlertItem('ModelNotDelete', AlertType.danger, false, 5000));
        } else {
          this.alertService.show(new AlertItem('EquipmentDeleteError', AlertType.danger));
        }
      });
  }

  openEquipmentView(equipment: Equipment) {
    this.modalService.show(new ComponentModal(EquipmentViewModalComponent, equipment))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  updateEquipmentEdit(equipment: Equipment) {
    return this.equipmentService.update(Equipment.create(equipment))
      .then(res => {
        this.equipment = res;
        this.alertService.show(new AlertItem('EquipmentSaved', AlertType.success));
        return res;
      }).catch(error => {
        this.alertService.show(new AlertItem('EquipmentSaveError', AlertType.danger));
      });
  }

  openCreateCalibration(equipment) {
    const calibration = new Calibration();
    calibration.equipmentId = equipment.id;
    calibration.contractId = equipment.contractId;
    this.modalService.show(new ComponentModal(EquipmentsCreateOrUpdateCalibrationComponent, calibration))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
          this.modalState = (modalError.modal as ComponentModal).component.instance.lastState;
        } else {
          this.modalState = null;
        }
      });
  }

  disabledEquipmentModelId() {
    const manufacturerId = this.searchForm.get('manufacturerId').value || [];
    return manufacturerId.length === 0;
  }
}
