import { UserService } from './../../core/services/user.service';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { AlertItem, AlertType, Activity, User, Actions, Models, Contract } from 'src/app/core/models';
import { StorageKey, StorageService, ActivityService, ContractService, ContractGlobalService, ViolationService } from 'src/app/core/services';
import { AlertService } from 'src/app/core/services/alert.service';
import * as moment from 'moment';
interface ActivityResponseData {
  next?: string;
  previous?: string;
  result: Activity[];
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  public userName = '';
  public activities?: Activity[] = null;
  public users = [];
  public usersById: { [params: string]: User } = {};
  public actions = Object.values(Actions);
  public actionsI18n = [];
  public models = Object.values(Models);
  public modelsI18n = [];
  public step = 0;
  public isloading: boolean;
  public contracts: Array<Contract>;

  constructor(
    private alertService: AlertService,
    public activityService: ActivityService,
    public userService: UserService,
    public contractService: ContractService,
    public contractGlobalService: ContractGlobalService,
    @Inject(LOCALE_ID) protected localeId: string,
    private storageService: StorageService,
    private violationService: ViolationService
  ) {}

  async ngOnInit() {
    await this.getCurrentContracts();
    await this.userService.getAll();
    const user = this.storageService.get(StorageKey.currentUser);
    this.userName = user.name;
    await this.loadUserService();
    this.violationService.unlock(user.id, []);
  }
  setStep(index: number) {
    if (index === 1) {
      this.loadActivityService();
    }
    this.step = index;
  }
  click() {
    this.alertService.show(new AlertItem('ModuleUnavailable', AlertType.info));
  }
  parseActionLabel(actionId: string) {
    const actionWithModelLalbelRegexp = /([A-Z][a-z]*)([A-Z][a-zA-Z]*)/;
    let messageResult = '';
    if (!actionWithModelLalbelRegexp.test(actionId)) {
      const { value: actionValue } = this.actionsI18n.find((e) => e.id === actionId);
      messageResult = actionValue || '';
    } else {
      const [, action, model] = actionId.match(actionWithModelLalbelRegexp);
      const { value: modelValue } = this.modelsI18n.find((e) => e.id === model);
      const { value: actionValue } = this.actionsI18n.find((e) => e.id === action);
      messageResult = [actionValue, modelValue].join(' ') || '';
    }

    return messageResult;
  }
  async loadActivityService() {
    this.isloading = true;
    await this.loadUserService().then(() => {
      const now = new Date().toISOString();
      const start = moment(now).subtract(7, 'days').format();
      const end = moment(now).utc().format();
      const currentContracts = this.contractGlobalService.contracts || this.storageService.get(StorageKey.currentContracts);
      const user = this.storageService.get(StorageKey.currentUser);
      this.activities = [];
      const userIds = this.users.map(u => u.id);
      const params: any = {
        limit: `4`,
        'date[gte]': start,
        'date[lte]': end,
        'userId[in]': `[${userIds}]`,
        order: 'date',
        sortAsc: false
      };
      const contractIds = this.contractGlobalService.contracts || this.storageService.get(StorageKey.currentContracts);
      if (contractIds.length === 1) {
        params.contractId = contractIds[0];
      } else {
        params['contractId[in]'] = `[${contractIds}]`;
      }
      this.activityService.list(params).then((res) => {
        if (res.result.length > 0) {
          res.result.forEach(activity => {
            const userActivity = this.usersById[activity.userId];
            user.groupIds.map(group => {
              if (userActivity?.groupIds.includes(group) && !userActivity.superUser) {
                if (this.activities.find(a => a.id === activity.id) == null) {
                  this.activities.push(activity);
                }
              }
            });
          });
        }
        this.isloading = false;
      });
    });
  }
  async loadUserService() {
    await this.userService.list({
      limit: '100',
      'superUser[bool]': 'false'
    }).then(res => {
      this.users = res.result.filter((user) => user.name != null);
      this.users.map((user) => {
        this.usersById[user.id] = user;
      });
    });
  }
  async getCurrentContracts() {
    if (localStorage.getItem('currentContracts')) {
      await this.contractService.getAll({ 'id[in]': localStorage.getItem('currentContracts') }).then(res => {
        this.contracts = res;
      });
      this.getEcsSpy();
    }
  }
  getEcsSpy() {
    let monitoring = false;
    if (this.contracts !== undefined) {
      this.contracts.map(contract => {
        if (contract.modules.filter(a => a === 'monitoring')) {
          monitoring = true;
        }
      });
      return monitoring;
    }
  }
}
