import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';
const plyr = require('plyr');

@Component({
  selector: 'app-view-video-modal',
  templateUrl: './view-video-modal.component.html',
  styleUrls: ['./view-video-modal.component.sass']
})
export class ViewVideoModalComponent extends BaseModal implements OnInit, AfterViewInit {
  isValid: boolean;
  lastState: any;
  public files;
  private videoTypes = ['video', 'video2'];

  constructor(
    public sanitizer: DomSanitizer,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.files = this.initialState && this.initialState.files || [];
  }

  getExtension(url): string {
    if (url != null) {
      const match = url.match(/\.[^.?]+(?=\?|$)/);
      if (match != null) {
        url = match[0];
      }
      return url;
    }
    return '';
  }

  isVideo(file) {
    const fileUrl = file.urlCurrent || file.urlDecrypted;
    const extension = this.getExtension(fileUrl);
    const videoTypes = ['.mpg', '.mp4', '.ogg'];
    return videoTypes.includes(extension);
  }

  initPlyr(files = []) {
    if (this.files) {
      this.files.forEach(file => {
        if (this.isVideo(file.src)) {
          const player = new plyr(`#modal-${file.type}`, {
            controls: ['play-large', 'progress', 'current-time', 'pip', 'fullscreen']
          });
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.initPlyr();
  }

  isVideoSupported(file) {
    const fileUrl = file.urlCurrent || file.urlDecrypted;
    const extension = this.getExtension(fileUrl);
    const videoTypes = ['.mp4', '.ogg'];
    return videoTypes.includes(extension);
  }

  async onAllSubmited(): Promise<any> {
  }

}
