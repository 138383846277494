import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertItem, AlertType } from 'src/app/core/models/AlertItem';
import { AlertService, AuthenticationService, StorageKey, StorageService } from 'src/app/core/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  loginInvalid = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private router: Router,
    private storageService: StorageService
    ) { }

  ngOnInit() {
    this.createForm();
    if (this.storageService.get(StorageKey.currentUser) != null) {
      this.router.navigate(['']);
    }
  }

  disableError() {
    this.loginInvalid = false;
  }

  createForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      rememberMe: [false, [Validators.required]]
    });
  }

  login() {
    this.loading = true;
    const email = this.loginForm.get('email').value;
    const password = this.loginForm.get('password').value;
    const rememberMe = this.loginForm.get('rememberMe').value;

    this.authService.login(email, password, rememberMe)
      .then(() => {
        this.loading = false;
        this.router.navigate(['/']);
      }).catch(error => {
        this.loading = false;
        this.loginInvalid = true;
        this.alertService.show(new AlertItem(error.message === 'Acesso negado' ? 'AccessDenied' : 'InvalidLogin', AlertType.danger));
      });
  }
}
