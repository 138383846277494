<div class="container">
  <div class="page-title page-title--columns">
    <div class="page-title__left"><h2 i18n>Gerenciar Série</h2></div>
    <div class="page-title__right" *appPermission="['CreateSerialNumberConfig']">
      <button class="btn btn-fade-primary" (click)="openCreateSerialNumberConfigModal()" i18n>Nova Série</button>
    </div>
  </div>

  <div class="mb-4">
    <form [formGroup]="searchForm" (submit)="handleSearch($event)">
      <div class="input-group">
        <input type="text" class="form-control form-search" formControlName="search" i18n-placeholder
          placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">

        <div class="input-group-append">
          <div class="filter-dropdown">
            <button type="button" class="btn btn-outline-secondary btn-icon filter-dropdown__handle" i18n>
              <i class="material-icons">filter_alt</i> Filtrar</button>

            <div class="filter-dropdown__content">
              <div class="form-group">
                <app-input type="multiselect" [list]="equipmentModels" label="Modelo Equipamento"
                  formControlName="equipmentModelIds" selectShowLimit=1 selectText="pretty" selectValue="id" placeholder="Todos"
                  i18n-placeholder i18n-label>
                </app-input>
              </div>

              <div class="form-group">
                <app-input type="multiselect" [list]="regulations" label="Enquadramentos" formControlName="regulationIds"
                  selectText="prettyName" selectValue="id" selectShowLimit=1 placeholder="Todos" i18n-placeholder i18n-label>
                </app-input>
              </div>

              <div class="form-group">
                <label i18n>Situação</label>
                <select type="text" tabindex="4" class="form-control" formControlName="violationStatusSearch" placeholder="Todos">
                  <option i18n value="" selected>Todos</option>
                  <option i18n value="invalid" selected>Invalidas</option>
                  <option i18n value="valid" selected>Validas</option>
                </select>
              </div>

              <div class="filter-dropdown__footer">
                <button i18n type="submit" class="btn btn-info btn-block" data-dismiss="modal">Aplicar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-dropdown ml-3">
          <button class="btn btn-fade-secondary btn-icon">
            <i class="material-icons">view_column</i>
            <span i18n>Exibir Colunas</span>
          </button>
          <app-column-filter [items]="columns" (output)="columnsToShow=$event"></app-column-filter>
        </div>
      </div>
    </form>
  </div>

  <div class="mt-5 mb-3">
    <div class="table-responsive table-hover table-block table-block--align-middle">
      <table class="table bg-white">
        <thead>
          <tr>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('enabled')}" class="text-center"></th>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('identifier')}" class="text-center">ID</th>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('initials')}" class="text-center">Sigla</th>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('verifyingDigit')}" class="text-center">DV</th>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('regulation')}" class="text-center">Enquadramento</th>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('lane')}" class="text-center">Faixa</th>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('startNumber')}" class="text-center">Situação</th>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('startNumber')}" class="text-center">Número</th>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('lastUsed')}" class="text-center">Último Utilizado</th>
            <th i18n [ngClass]="{'d-none': !columnsToShow.includes('actions')}"
              *appPermission="['UpdateSerialNumberConfig', 'DeleteSerialNumberConfig']" class="text-center">Açōes</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let config of serialNumberConfigs; index as i">
            <td class="text-center">
              <div class="custom-control custom-switch float-left mt-2 position-checked">
                <div class="form-group">
                  <label class="custom-control-label" [ngClass]="{checked:config.enabled}">
                    <input type="checkbox" class="form-control custom-control-input"
                    [(ngModel)]="config.enabled"
                    (change)="updateEnabled(config)">
                  </label>
                </div>
              </div>
            </td>
            <td class="text-center" (click)="openModalInfo(config)">
              <span *ngIf="config?.identifier; else noItens">{{config.identifier}}</span>
            </td>
            <td class="text-center" (click)="openModalInfo(config)">
              <span *ngIf="config?.prefix; else noItens">{{config.prefix}}</span>
            </td>
            <td class="text-center" (click)="openModalInfo(config)">
              <span *ngIf="config?.verifyingDigit; else noItens">{{config.verifyingDigit}}</span>
            </td>
            <td class="text-center" (click)="openModalInfo(config)">
              <div *ngIf="config?.regulationIds?.length > 0; else noItens">
                <span *ngFor="let regulationId of config.regulationIds.slice(0,6)"
                  class="badge badge-info mr-1" i18n-appTooltip
                  appTooltip="{{ regulationsById[regulationId]?.prettyName }}">
                    {{ regulationsById[regulationId]?.code }}...
                </span>
                <a *ngIf="config?.regulationIds?.length > 6" (click)="showMoreRegulations=i"
                    class="badge badge-link badge-fade-primary"
                    [ngClass]="{'d-none': showMoreRegulations === i }" i18n-appTooltip
                    appTooltip="Mostrar + {{ config?.regulationIds?.length - 3 }}">...</a>
              </div>
              <div *ngIf="config?.regulationIds?.length > 3" class="d-none" [ngClass]="{'d-block': showMoreRegulations === i }">
                <span *ngFor="let regulationId of config.regulationIds.slice(6)"
                  class="badge badge-info mr-1" i18n-appTooltip
                  appTooltip="{{ regulationsById[regulationId]?.prettyName }}">
                    {{ regulationsById[regulationId]?.code }}...
                </span>
                <a *ngIf="config?.regulationIds?.length > 6" (click)="showMoreRegulations=''"
                  class="badge badge-link badge-fade-secondary" i18n-appTooltip
                  appTooltip="Mostrar menos">&times;</a>
              </div>
            </td>
            <td class="text-center" (click)="openModalInfo(config)">
              <div *ngIf="config.laneIds && config?.laneIds?.length > 0; else noItens">
                <span *ngFor="let laneId of config.laneIds.slice(0,4); index as i">
                  <span class="badge badge-info mr-1" i18n-appTooltip
                  appTooltip="{{laneService && laneService.cache && laneService.cache[laneId]?.clientDescription || spotsById[laneById[laneId]?.spotId]?.description}}">
                    {{ laneById && laneById[laneId]?.prettyNames }}
                  </span>
                </span>
                <a *ngIf="config?.laneIds?.length > 4" (click)="showMoreLane=i"
                  class="badge badge-link badge-fade-primary"
                  [ngClass]="{'d-none': showMoreLane === i }" i18n-appTooltip
                  appTooltip="Mostrar + {{ config?.laneIds?.length - 3 }}">...</a>
              </div>

              <div *ngIf="config?.laneIds?.length > 3" class="d-none" [ngClass]="{'d-block': showMoreLane === i }">
                <span *ngFor="let laneId of config.laneIds.slice(4)"
                  class="badge badge-info mr-1"
                  appTooltip="{{laneService && laneService.cache && laneService.cache[laneId]?.clientDescription || spotsById[laneById[laneId]?.spotId]?.description}}">
                  {{ laneById && laneById[laneId]?.prettyNames }}...
                </span>
                <a *ngIf="config?.laneIds?.length > 3" (click)="showMoreLane=''"
                  class="badge badge-link badge-fade-secondary" i18n-appTooltip
                  appTooltip="Mostrar menos">&times;</a>
              </div>
            </td>
            <td class="text-center" (click)="openModalInfo(config)">
              <p class="text-secondary mb-0" *ngIf="config?.violationStatus.length === 0">–</p>
              <div *ngFor="let status of config?.violationStatus">
                <div [ngSwitch]="status">
                  <span *ngSwitchCase="'invalid'" i18n>Inválidas</span>
                  <span *ngSwitchCase="'valid'" i18n>Válidas</span>
                </div>
              </div>
            </td>
            <td [ngClass]="{'d-none': !columnsToShow.includes('startNumber') && !columnsToShow.includes('endNumber')}" class="text-center" (click)="openModalInfo(config)">{{config?.range?.start}} - {{config?.range?.end}}</td>
            <td [ngClass]="{'d-none': !columnsToShow.includes('lastUsed')}" class="text-center" (click)="openModalInfo(config)">{{config?.currentNumber || config?.range?.start}}</td>
            <td *appPermission="['UpdateSerialNumberConfig', 'DeleteSerialNumberConfig','DeleteSerialNumberConfig']" class="text-center">
              <div class="dropdown">
                <button class="btn btn-link text-secondary" type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" *appPermission="['UpdateSerialNumberConfig']"><i class="material-icons">more_horiz</i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <span *appPermission="['UpdateSerialNumberConfig']"><a class="dropdown-item" (click)="openCreateSerialNumberConfigModal(i, 'edit')" i18n>Editar Série</a></span>
                  <span *appPermission="['DeleteSerialNumberConfig']"><a class="dropdown-item" *ngIf="!isStarted(config)" (click)="openDeleteModal(config)" i18n>Remover Série</a></span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-pagination (items)="setData($event)" [params]="params" [deleteEvent]="deleteEvent"
    [service]="serialNumberConfigService">
  </app-pagination>
</div>

<ng-template #noItens i18n>
  <p class="text-secondary mb-0">–</p>
</ng-template>
