import { BaseApiModel } from './BaseApiModel';

export class ViolationSource extends BaseApiModel {
  public name: string;
  public abbreviation: string;
  public equipmentModelIds: number[];
  public regulationIds: number[];
  public modifiedAt: string;
  public deletedAt: string;
  public contractId: number;

  public get prettyName() {
    return `${this.abbreviation} - ${this.name}`;
}
}
