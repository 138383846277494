<h3 i18n="@@ForgotTitle" class="mb-5">Esqueci minha senha</h3>

<div class="text-danger text-center">
  <p><i class="material-icons">error</i></p>
  <p i18n>O link selecionado para verificação da conta não é válido ou já foi usado.</p>
</div>

<div class="mt-5">
  <button [routerLink]="['']" class="btn btn-fade-secondary btn-icon" i18n>
    <i class="material-icons">subdirectory_arrow_left</i>
    Voltar ao login</button>
</div>
