import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Contract, Pagination } from '../models';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class ContractService extends BaseApiService<Contract> {
  get cacheKey(): StorageKey {
    return StorageKey.contractServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/contracts`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  list(params: {[params: string]: string}): Promise<Pagination<Contract>> {
    const regex = /^contractId((?=\[.*\]|$))/;
    Object.keys(params).forEach(key => {
      const match = key.match(regex);
      if (match != null) {
        params['code' + match[1]] = params[key];
        delete params[key];
      }
    });
    if (typeof params === 'object') {
      params['status[neq]'] = 'closed';
    }
    return super.list(params);
  }

  protected unparse(obj) {
    return Contract.unparse(obj);
  }

  protected parse(obj) {
    return Contract.parse(obj);
  }
}
