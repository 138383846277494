import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalContent } from 'src/app/core/interface';
import { AlertItem, AlertType, Contract, Equipment, Spot, Lane, User, EquipmentModel } from 'src/app/core/models';
import {
  AlertService, ContractService, EquipmentService, ModalService,
  SpotService, ContractGlobalService, LaneService, StorageService, StorageKey, EquipmentModelService
} from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { uniq } from 'lodash';

@Component({
  selector: 'app-equipments-create-modal',
  templateUrl: './equipments-create-modal.component.html',
  styleUrls: ['./equipments-create-modal.component.sass']
})
export class EquipmentsCreateOrUpdateModalComponent extends BaseModal implements OnInit, ModalContent {
  @Input() initialState;
  public equipmentForm: FormGroup;
  public contractsPromise: Promise<Contract[]>;
  public contracts: Contract[] = [];
  public equipments: Equipment[] = [];
  public contractSelected: Contract;
  public equipmentModels: Array<{ id: string; name: string; type: string }>;
  public lanes: Array<Lane> = [];
  public lanesBySpots: Array<Lane> = [];
  public isValid = false;
  public lanesDropdownSettings = {};
  public spots: Array<Spot> = [];
  public allSpots: Array<Spot> = [];
  public equipment: Equipment = new Equipment();
  public contractGlobal = null;
  public currentPermissions = [];
  public user: User;
  public promise: Promise<any>;
  public displayCftvFields = false;
  public isViewPassword = true;
  public submitLaneIds: Array<number> = [];

  constructor(
    private formBuilder: FormBuilder,
    private equipmentService: EquipmentService,
    private equipmentModelService: EquipmentModelService,
    private contractService: ContractService,
    private spotService: SpotService,
    private alertService: AlertService,
    public modalService: ModalService,
    public laneService: LaneService,
    private contractsGlobalService: ContractGlobalService,
    private storageService: StorageService
  ) {
    super(modalService);
    if (this.contractsGlobalService.contracts != null) {
      this.contractGlobal = this.contractsGlobalService.contracts.length === 1 ? this.contractsGlobalService.contracts[0] : null;
    }
  }

  ngOnInit() {
    const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
    this.currentPermissions = currentPermissions &&
      currentPermissions.filter(contractPermission =>
        contractPermission.actionIds.indexOf('CreateEquipment') >= 0 || contractPermission.actionIds.indexOf('UpdateEquipment') >= 0);
    this.user = this.storageService.get(StorageKey.currentUser);

    this.equipment = this.initialState as Equipment || new Equipment();
    this.contractsPromise = this.contractService.getAll({ 'status[neq]': 'closed' }).then(res => {
      if (this.user.superUser) {
        this.contracts = res;
      } else {
        this.contracts = res.filter(contract => this.currentPermissions.find(contractPermissions =>
          contractPermissions.contractId === contract.id));
      }
      if (this.equipment.contractId == null) {
        this.equipment.contractId = this.contractGlobal;
      }

      return this.equipmentModelService
        .getAll()
        .then(equipmentModels => {
          this.contracts.forEach((contract) => {
            contract.equipmentModelIds = equipmentModels
            .filter( (eqm) => eqm.contractIds.some(id => Number(contract.id) === id))
            .map(e => e.id);
          });
          return this.contracts;
        });
    });
    this.contractsPromise.then(contracts => {
      if (this.equipment.equipmentModelId != null) {
        this.contractSelected = contracts.find(contract => contract.id === this.equipment.contractId);
        this.equipmentModels = [];
        this.contractSelected?.equipmentModelIds.forEach(async id => {
          const data = await this.equipmentModelService.getById(id);
          this.equipmentModels.push({ id: data.id, name: data.name, type: data.type });
        });
      }
      if (this.contractGlobal != null) {
        this.filterContract(this.contractGlobal);
      }
    });
    this.lanesDropdownSettings = {
      placeholder: 'Selecionar',
      searchPlaceholderText: 'Buscar',
      singleSelection: false,
      idField: 'id',
      textField: 'id',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    const contractId = this.equipment.contractId || this.contractGlobal;
    this.loadSpots(contractId);

    this.createForm();

    if (this.initialState != null) {
      const keys = Object.keys(this.initialState);
      for (const key of keys) {
        const form = this.equipmentForm.get(key);
        if (form) {
          form.setValue(this.initialState[key]);
        }
      }
    }
  }

  async loadSpots(contractId) {
    if (contractId != null) {
      this.spotService.list({ contractId }).then(resSpot => {
        const spots = resSpot.result;
        this.laneService.list({ contractId }).then(res => {
          this.lanes = res.result;
          this.allSpots = spots;
          this.spots = this.allSpots.filter(spot => spot.contractId === contractId);
          this.setLanes(this.equipment.spotIds);
        });
      });
    }
  }

  get lastState() {
    const state = {};
    const keys = Object.keys(this.equipmentForm.controls);
    for (const key of keys) {
      state[key] = this.equipmentForm.get(key).value;
    }
    return state;
  }

  createForm(): void {
    this.equipmentForm = this.formBuilder.group({
      contractId: [this.equipment.contractId || this.contractGlobal, [Validators.required]],
      laneIds: [this.equipment.laneIds || []],
      equipmentModelId: [this.equipment.equipmentModelId || '', Validators.required],
      serialNumber: [this.equipment.serialNumber || '', Validators.required],
      clientCode: [this.equipment.clientCode || ''],
      ocr: [this.equipment.features && this.equipment.features.ocr && this.equipment.features.ocr.enabled],
      spotIds: [this.equipment.spotIds],
      classification: [this.equipment.features && this.equipment.features.classification
        && this.equipment.features.classification.enabled],
      trafficLight: [this.equipment.features && this.equipment.features.trafficLight &&
        this.equipment.features.trafficLight.enabled || false],
      enabled: [this.equipment.enabled || false],
      name: [this.equipment?.cftv?.name || ''],
      url: [this.equipment?.cftv?.url || ''],
      port: [this.equipment?.cftv?.port || null],
      user: [this.equipment?.cftv?.user || ''],
      password: [this.equipment?.cftv?.password || '']
    });
    this.submitLaneIds = this.equipmentForm.get('laneIds').value;
    this.equipmentForm.get('laneIds').valueChanges.subscribe(value => {
      this.submitLaneIds = value;
    });
    this.equipmentForm.valueChanges.subscribe(() => {
      this.isValid = this.equipmentForm.valid;
    });
    this.displayRtspFields();
  }

  public async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    const spotIds = this.equipmentForm.get('spotIds').value || [];
    if (spotIds.length === 0) {
      this.equipmentForm.patchValue({ laneIds: [] });
    }
    const laneIds = this.submitLaneIds;
    const enabled = this.equipmentForm.get('enabled').value || false;
    const data: any = {
      id: this.equipment.id,
      contractId: this.equipmentForm.get('contractId').value,
      serialNumber: this.equipmentForm.get('serialNumber').value,
      clientCode: this.equipmentForm.get('clientCode').value,
      equipmentModelId: this.equipmentForm.get('equipmentModelId').value,
      features: {
        ocr: { enabled: this.equipmentForm.get('ocr').value || false },
        classification: { enabled: this.equipmentForm.get('classification').value || false },
        trafficLight: { enabled: this.equipmentForm.get('trafficLight').value || false }
      },
      enabled,
      spotIds,
      laneIds,
      modifiedAt: this.equipment.modifiedAt
    };
    const eqModel = this.equipmentModels.filter(item => item.id === data.equipmentModelId)[0];
    if (eqModel.type === 'cftv') {
      data.cftv = {
        name: this.equipmentForm.get('name').value || '',
        url: this.equipmentForm.get('url').value || '',
        port: Number(this.equipmentForm.get('port').value) || null,
        user: this.equipmentForm.get('user').value || '',
        password: this.equipmentForm.get('password').value || ''
      };
    } else if (eqModel.type !== 'cftv' && data.cftv) {
      delete data.cftv;
    }
    const equipment = Equipment.create(data);
    if (equipment.id != null) {
      this.promise = this.equipmentService.update(equipment);
    } else {
      this.promise = this.equipmentService.create(equipment);
    }
    this.promise.then(res => {
      this.equipment = res;
      if (this.equipment.cftv) {
        this.displayCftvFields = true;
      }
      this.equipment.laneIds = data.laneIds || [];
      this.equipment.spotIds = data.spotIds || [];
      this.alertService.show(new AlertItem('EquipmentSaved', AlertType.success));
      return res;
    }).catch(error => {
      if (error.status === 409) {
        this.alertService.show(new AlertItem('EquipmentSaveConflict', AlertType.danger));
      } else {
        this.alertService.show(new AlertItem('EquipmentSaveError', AlertType.danger));
      }
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }

  async filterContract(contractId: any) {
    this.spots = this.allSpots.filter(spot => spot.contractId === contractId);
    if (this.spots.length === 0) {
      await this.loadSpots(contractId);
    } else {
      this.spots = this.allSpots.filter(spot => spot.contractId === contractId);
    }
    this.contractSelected = await this.contractsPromise.then(res => res.find(contract => contract.id === contractId));
    if (this.contractSelected && this.contractSelected.equipmentModelIds && this.contractSelected.equipmentModelIds.length > 0) {
      this.equipmentModels = [];
      this.contractSelected.equipmentModelIds.forEach(async id => {
        const data = await this.equipmentModelService.getById(id);
        this.equipmentModels.push({ id: data.id, name: data.name, type: data.type });
      });
    } else {
      this.equipmentModels = [];
    }
  }

  setLanes(spotIds) {
    if (spotIds && spotIds.length > 0) {
      this.lanesBySpots = this.lanes.filter(lane => spotIds.includes(lane.spotId));
      let laneIds = this.equipment.laneIds;
      const laneIdsVerified = [];
      spotIds.map(spotId => {
        this.lanesBySpots.map(lane => {
          if (lane.spotId === spotId && laneIds.includes(lane.id)) {
            laneIdsVerified.push(lane.id);
          }
        });
      });
      if (this.lanesBySpots.length === 0) {
        laneIds = [];
      }
      this.equipmentForm.patchValue({ laneIds: laneIdsVerified });
    }
  }
  displayRtspFieldsUpdate() {
    if(this.equipment.cftv) {
      this.displayCftvFields = true;
      this.equipmentForm.get('name').setValidators(Validators.required);
      this.equipmentForm.get('url').setValidators(Validators.required);
      this.equipmentForm.get('port').setValidators(Validators.required);
      this.equipmentForm.get('user').setValidators(Validators.required);
      this.equipmentForm.get('password').setValidators(Validators.required);
    }
  }
  displayRtspFields() {
    if(this.equipment.id) {
      this.displayRtspFieldsUpdate();
      return;
    }
    this.equipmentForm.get('equipmentModelId').valueChanges.subscribe(equipmentModelId => {
      const eqModel = this.equipmentModels.filter(item => item.id === equipmentModelId)[0];
      if (eqModel.type === 'cftv') {
        this.displayCftvFields = true;
        this.equipmentForm.get('name').setValidators(Validators.required);
        this.equipmentForm.get('url').setValidators(Validators.required);
        this.equipmentForm.get('port').setValidators(Validators.required);
        this.equipmentForm.get('user').setValidators(Validators.required);
        this.equipmentForm.get('password').setValidators(Validators.required);
      } else {
        this.displayCftvFields = false;
        this.equipmentForm.get('name').clearValidators();
        this.equipmentForm.get('url').clearValidators();
        this.equipmentForm.get('port').clearValidators();
        this.equipmentForm.get('user').clearValidators();
        this.equipmentForm.get('password').clearValidators();
        this.equipmentForm.get('name').setValue('');
        this.equipmentForm.get('url').setValue('');
        this.equipmentForm.get('port').setValue(null);
        this.equipmentForm.get('user').setValue('');
        this.equipmentForm.get('password').setValue('');
      }
    });
  }

  setViewPassword() {
    this.isViewPassword = !this.isViewPassword;
  }
}
