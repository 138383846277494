import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClosedModalError } from 'src/app/core/errors';
import { AlertItem, AlertType, ComponentModal, Contract, MessageModal, User, UserGroup, Modules } from 'src/app/core/models';
import {
  AlertService, ContractService, ModalService,
  UserGroupService, UserService, StorageService, StorageKey, ContractGlobalService
} from 'src/app/core/services';
import { UserSelectModalComponent } from '../user-select-modal/user-select-modal.component';
import { updateLocalStorage } from 'src/app/core/utils/updateLocalStorage';
import { hasPermission } from 'src/app/core/utils/permission';

@Component({
  selector: 'app-user-groups-list',
  templateUrl: './user-groups-list.component.html',
  styleUrls: ['./user-groups-list.component.sass']
})
export class UserGroupsListComponent implements OnInit {
  public userGroups = [];
  public users = [];
  public searchForm: FormGroup;
  public userGroupForm: FormGroup;
  public modelChanged: Subject<string> = new Subject<string>();
  public params: any = {};
  public userGroupForms: Array<{ formGroup: FormGroup; isModified: boolean }>;
  public contracts: Array<Contract> = [];
  public inputValue: string;
  public newGroup = false;
  public dropMenuActive = false;
  public isModified = false;
  public updateGroup = false;
  public noRegister = false;
  public user: User;
  public userGroup: Array<UserGroup> = [];
  public userGroupById: { [params: string]: Array<User> } = {};
  public isValid = false;
  public currentPermissions = [];
  public promise: Promise<any>;
  public selectedContractId: string;

  public preProcessPermissions = [
    'TriageViolation',
    'TypingViolation',
    'DoubleTypingViolation',
    'VerifyViolation',
    'ValidateViolation',
    'UpdatePreProcessSettings',
    'QuarantineReport',
    'QuarantineManage',
    'PreProcessStepMap',
    'PreProcessProductionReport',
    'ViewLot',
    'CreateLot',
    'DeleteLot',
    'UpdateRegenerateLot',
    'ViolationsReport',
    'ViewActivity',
    'ManageLot',
    'AuditViolation',
    'ReviewLot'
  ];

  public permissions = [
    'UpdateContract',
    'ViewContract',
    'DeleteContract',
    'CreateCalibration',
    'UpdateCalibration',
    'ViewCalibration',
    'DeleteCalibration',
    'CreateCompany',
    'UpdateCompany',
    'ViewCompany',
    'DeleteCompany',
    'CreateEquipment',
    'UpdateEquipment',
    'ViewEquipment',
    'DeleteEquipment',
    'CreateEquipmentModel',
    'UpdateEquipmentModel',
    'ViewEquipmentModel',
    'DeleteEquipmentModel',
    'CreateLane',
    'UpdateLane',
    'ViewLane',
    'DeleteLane',
    'CreateUserGroup',
    'UpdateUserGroup',
    'ViewUserGroup',
    'DeleteUserGroup',
    'CreateSpot',
    'UpdateSpot',
    'ViewSpot',
    'DeleteSpot',
    'CreateVehicleWhiteList',
    'UpdateVehicleWhiteList',
    'ViewVehicleWhiteList',
    'DeleteVehicleWhiteList',
    'CreateUser',
    'UpdateUser',
    'ViewUser',
    'DeleteUser',
    'CreateExemptPeriod',
    'UpdateExemptPeriod',
    'ViewExemptPeriod',
    'DeleteExemptPeriod',
    'UpdatePreProcessSettings',
    'ViewViolation',
    'UpdateViolation',
    'ViewImportSettings',
    'UpdateImportSettings',
    'QuarantineReport',
    'QuarantineManage',
    'PreProcessStepMap',
    'PreProcessProductionReport',
    'ViewLot',
    'CreateLot',
    'UpdateLot',
    'DeleteLot',
    'UpdateRegenerateLot',
    'CreateSerialNumberConfig',
    'UpdateSerialNumberConfig',
    'ViewSerialNumberConfig',
    'DeleteSerialNumberConfig',
    'ViolationsReport',
    'ViewActivity'
  ];

  constructor(
    public userGroupService: UserGroupService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private userService: UserService,
    private contractService: ContractService,
    private contractsGlobalService: ContractGlobalService,
    private storageService: StorageService,
    private modalService: ModalService
  ) {
    this.modelChanged.pipe(debounceTime(500)).subscribe(() => {
      this.handleSearch();
    });
  }

  ngOnInit() {
    const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
    this.currentPermissions = currentPermissions &&
      currentPermissions.filter(contractPermission => contractPermission.actionIds.indexOf('ViewUserGroup') >= 0);
    this.user = this.storageService.get(StorageKey.currentUser);
    this.loadContract();
    this.createSearchForm();
    this.createForm();
    const currentUser = this.storageService.get(StorageKey.currentUser);
    if (currentUser && currentUser.email != null) {
      this.userService.getById(currentUser.id).then(res => {
        this.user = res;
      });
    }
  }

  verifyGroups() {
    if (!this.user?.superUser) {
      this.params['id[in]'] = `[${this.user?.groupIds.join(',')}]`;
    }
  }

  activeFormRegister() {
    this.newGroup = true;
    this.createForm();
  }

  activeMenu() {
    this.dropMenuActive = true;
  }

  disableMenu() {
    this.dropMenuActive = false;
  }

  disabledFormRegister() {
    this.newGroup = false;
  }

  createSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      search: ['']
    });
  }

  async hasPreProcessModule(userGroup: UserGroup) {
    if (userGroup == null || this.contracts.filter.length === 0) {
      await this.loadContract();
    }
    const contract = this.contracts.find(c => c.id === userGroup.contractId);
    if (contract == null) {
      return false;
    }

    return contract.modules.includes(Modules.violations);
  }

  loadContract() {
    this.contractService
      .getAll({ 'status[neq]': 'closed' })
      .then(res => {
        if (this.user.superUser) {
          this.contracts = res;
        } else {
          this.contracts = res.filter(contract =>
            this.currentPermissions.find(contractPermissions => contractPermissions.contractId === contract.id));
        }
      })
      .catch(error => { });
  }

  handleSearch() {
    this.noRegister = false;
    this.userGroups = [];
    this.userGroup = [];
    const filterObj = {};
    const search = this.searchForm.get('search').value;

    if (search != null && search !== '') {
      filterObj['name[contains,or]'] = `${search}`;
      filterObj['description[contains,or]'] = `${search}`;
    }
    this.params = filterObj;
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  createForm() {
    const contractIdArray = this.contractsGlobalService.contracts;
    if (contractIdArray && contractIdArray.length === 1) {
      this.selectedContractId = contractIdArray[0];
    }
    this.userGroupForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      contractId: [this.selectedContractId || '', [Validators.required]]
    });
    this.userGroupForm.valueChanges.subscribe(() => {
      this.isModified = this.userGroupForm.valid;
      this.isValid = this.userGroupForm.valid;
    });
  }

  updateUserGroup(userGroup: UserGroup) {
    return this.userGroupService.update(UserGroup.create(
      userGroup
    )
    ).then(res => {
      this.alertService.show(new AlertItem('UserGroupSaved', AlertType.success));
      return res;
    });
  }

  onSubmit() {
    if (this.promise != null) {
      return this.promise;
    }
    const name = this.userGroupForm.get('name').value.charAt(0).toUpperCase() + this.userGroupForm.get('name').value.slice(1);
    this.promise = this.userGroupService
      .create(
        UserGroup.create({
          name,
          description: this.userGroupForm.get('description').value || '',
          contractId: this.userGroupForm.get('contractId').value,
          userIds: [],
          permissions: ['ViewContract'],
          enabled: true
        })
      )
      .then(res => {
        this.userGroups = [];
        this.userGroupForm.reset();
        this.handleSearch();
        this.alertService.show(
          new AlertItem('UserGroupSaved', AlertType.success)
        );
        this.newGroup = false;
        return res;
      })
      .catch(error => {
        if (error.status === 409) {
          this.alertService.show(new AlertItem('UserGroupSaveConflict', AlertType.danger));
        } else {
          this.alertService.show(new AlertItem('UserGroupSaveError', AlertType.danger));
        }
        throw error;
      }).finally(() => {
        this.promise = null;
      });
    return this.promise;
  }
  toggleCollapse(i, area) {
    switch(area){
      case 1:
        this.userGroups[i].showArea1 = !this.userGroups[i].showArea1;
        break;
      case 2:
        this.userGroups[i].showArea2 = !this.userGroups[i].showArea2;
        break;
      case 3:
        this.userGroups[i].showArea3 = !this.userGroups[i].showArea3;
        break;
      case 4:
        this.userGroups[i].showArea4 = !this.userGroups[i].showArea4;
        break;
    }
  }

  createEditForm(userGroups) {
    this.userGroups = userGroups;
    if (this.userGroupForms == null) {
      this.userGroupForms = [];
    }

    this.userGroups.forEach(async userGroup => {
      userGroup.showArea1 = false;
      userGroup.showArea2 = false;
      userGroup.showArea3 = false;
      userGroup.showArea4 = false;
      if (this.userGroupForms[userGroup.contractId + userGroup.id] != null) {
        return;
      }
      this.userGroupForms[userGroup.contractId + userGroup.id] = {
        formGroup: new FormGroup({}),
        isModified: false
      };
      const contract = await this.contractService.getById(userGroup.contractId);
      this.userGroupForms[userGroup.contractId + userGroup.id].formGroup = this.formBuilder.group({
        name: [userGroup.name, Validators.required],
        description: [userGroup.description || ''],
        enabled: [userGroup.enabled],
        contractId: [contract.prettyName, Validators.required]
      });
      const allPermissions = this.permissions.concat(this.preProcessPermissions);
      allPermissions.forEach(permission => {
        let isDisabled = false;
        isDisabled = !hasPermission('UpdateUserGroup', this.storageService, [userGroup.contractId]);
        let checkedPermission = false;
        if (userGroup.permissions.indexOf(permission) >= 0) {
          checkedPermission = true;
        }

        this.userGroupForms[userGroup.contractId + userGroup.id].formGroup.addControl(
          permission,
          new FormControl({value: checkedPermission, disabled: isDisabled})
        );
      });
      this.userGroupForms[userGroup.contractId + userGroup.id].formGroup.valueChanges.subscribe(() => {
        this.userGroupForms[userGroup.contractId + userGroup.id].isModified = true;
      });
    });
  }

  setFormValues(userGroup) {
    this.userGroupForms[userGroup.contractId + userGroup.id].formGroup.patchValue({
      name: userGroup?.name,
      description: userGroup.description,
      enabled: userGroup.enabled,
    });
    const allPermissions = this.permissions.concat(this.preProcessPermissions);
    allPermissions.forEach(permission => {
      const checkedPermission = userGroup.permissions.indexOf(permission) >= 0;
      this.userGroupForms[userGroup.contractId + userGroup.id].formGroup.patchValue({
        [permission]: checkedPermission
      });
    });
    this.userGroupForms[userGroup.contractId + userGroup.id].isModified = false;
  }

  onSubmitEdit(userGroup: any) {
    if (this.promise != null) {
      return this.promise;
    }
    let permissions = [];
    const allPermissions = this.permissions.concat(this.preProcessPermissions);
    const index = allPermissions.findIndex(p => p === 'DoubleTypingViolation');
    if (index >= 0) {
      allPermissions.slice(index);
    }
    allPermissions.forEach(permission => {
      const checkedPermission = this.userGroupForms[userGroup.contractId + userGroup.id].formGroup.get(
        permission
      ).value;
      if (checkedPermission) {
        switch (permission) {
          case 'CreateEquipment':
            permissions.push('CreateEquipmentModel');
            break;
          case 'UpdateEquipment':
            permissions.push('UpdateEquipmentModel');
            break;
          case 'ViewEquipment':
            permissions.push('ViewEquipmentModel');
            break;
          case 'DeleteEquipment':
            permissions.push('DeleteEquipmentModel');
            break;
          case 'CreateEquipmentModel':
            return;
          case 'UpdateEquipmentModel':
            return;
          case 'ViewEquipmentModel':
            return;
          case 'DeleteEquipmentModel':
            return;
          case 'CreateSpot':
            permissions.push('CreateLane');
            break;
          case 'UpdateSpot':
            permissions.push('UpdateLane');
            break;
          case 'ViewSpot':
            permissions.push('ViewLane');
            break;
          case 'DeleteSpot':
            permissions.push('DeleteLane');
            break;
          case 'CreateLane':
            return;
          case 'UpdateLane':
            return;
          case 'ViewLane':
            return;
          case 'DeleteLane':
            return;
          case 'UpdateImportSettings':
            permissions.push('UploadImportFile');
            break;
          case 'ViewImportSettings':
            permissions.push('ViewImportFile');
            break;
          case 'TypingViolation':
            permissions.push('DoubleTypingViolation');
            break;
        }
        if ((permission === 'TriageViolation') || (permission === 'TypingViolation') ||
          (permission === 'VerifyViolation') || (permission === 'ValidateViolation')) {
          const isUpdateViolationChecked = permissions.find(item => item === 'UpdateViolation');
          if (isUpdateViolationChecked == null) {
            permissions.push('UpdateViolation');
          }
          const isViewViolationChecked = permissions.find(item => item === 'ViewViolation');
          if (isViewViolationChecked == null) {
            permissions.push('ViewViolation');
          }
          this.checkViolationFields(permission, userGroup.contractId + userGroup.name);
        }
        if (permission === 'UpdateViolation' || permission === 'ViewViolation') {
          const checkPermission = permissions.find(item => item === permission);
          if (checkPermission !== permission) {
            permissions.push(permission);
          }
        } else {
          permissions.push(permission);
        }
        if (this.preProcessPermissions.includes(permission) && !permissions.includes('ViewPreProcessSettings')) {
          permissions.push('ViewPreProcessSettings');
        }
        if (permission === 'QuarantineManage' && permissions.indexOf('QuarantineReport') === -1) {
          permissions.push('QuarantineReport');
        }
      }
    });
    permissions = permissions.filter((item, pos) => permissions.indexOf(item) === pos);
    if (!permissions.find(item => item === 'TypingViolation')) {
      const idx = permissions.indexOf('DoubleTypingViolation');
      if (idx > -1) {
        permissions.splice(idx, 1);
      }
    }
    this.promise = this.userGroupService
      .update(
        UserGroup.create({
          id: userGroup.id,
          contractId: userGroup.contractId,
          name: this.userGroupForms[userGroup.contractId + userGroup.id].formGroup.get('name').value,
          enabled: this.userGroupForms[userGroup.contractId + userGroup.id].formGroup.get('enabled').value,
          description: this.userGroupForms[userGroup.contractId + userGroup.id].formGroup.get('description')
            .value,
          userIds: userGroup.userIds,
          permissions,
          modifiedAt: userGroup.modifiedAt
        })
      )
      .then(res => {
        this.userGroupForms[userGroup.contractId + userGroup.id].isModified = false;
        this.alertService.show(
          new AlertItem('UserGroupSaved', AlertType.success)
        );
        this.userGroups = [];
        this.handleSearch();
        this.newGroup = false;
        this.userService.getById(this.user.id).then(users => {
          this.userGroupService.getAll(null, true).then(data => {
            updateLocalStorage(users, this.storageService, data);
          });
        });
        return res;
      })
      .catch(error => {
        this.alertService.show(
          new AlertItem('UserGroupSaveError', AlertType.danger)
        );
        throw error;
      }).finally(() => {
        this.promise = null;
      });
    return this.promise;
  }

  openUserView(userGroupId) {
    this.modalService.show(new ComponentModal(UserSelectModalComponent, userGroupId)).then(res => {
      this.userGroups = [];
      this.handleSearch();
      this.userService.getById(this.user.id).then(users => {
        this.userGroupService.getAll().then(data => {
          updateLocalStorage(users, this.storageService, data);
        });
      });
    }).catch(err => {
      if (err instanceof ClosedModalError) {
        const modalError = err as ClosedModalError;
      }
    });
  }

  deleteGroupModal(contractId, groupId, groupName) {
    this.contractService.getById(contractId).then(result => {
      this.modalService.show(new MessageModal(
        'Remover Grupo', `Deseja remover o grupo ${groupName} (${contractId} - ${result.name})?`, true
      )).then(() => this.userGroupService.delete(groupId).then(res => {
        this.handleSearch();
        this.userService.getById(this.user.id).then(users => {
          this.userGroupService.getAll().then(data => {
            const userGroup = data.filter(usrGrp => usrGrp.id !== groupId);
            updateLocalStorage(users, this.storageService, userGroup);
          });
        });
        this.alertService.show(
          new AlertItem('UserGroupDeleted', AlertType.success)
        );
      }).catch(error => {
        this.alertService.show(new AlertItem('UserGroupDeleteError', AlertType.danger));
      })).catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
    }).catch(err => {
      console.log(err);
    });
  }

  openDeleteModalUser(userGroup, userId, userName) {
    this.modalService.show(new MessageModal(`Remover usuário do grupo ${userGroup.name}`, `Deseja remover o usuário ${userName} ?`, true))
      .then(() => {
        const dataUsersIds = userGroup.userIds;
        const userIds = dataUsersIds.filter(item => item !== userId);
        return this.userGroupService.update(UserGroup.create({
          id: userGroup.id,
          contractId: userGroup.contractId,
          name: userGroup.name,
          enabled: userGroup.enabled,
          description: userGroup.description,
          userIds,
          permissions: userGroup.permissions,
          modifiedAt: userGroup.modifiedAt
        })).then(res => {
          this.userGroupById[userGroup.contractId + userGroup.name] = [];
          this.alertService.show(new AlertItem('UserRemovedFromGroup', AlertType.success));
          this.userGroups = [];
          this.handleSearch();
          this.newGroup = false;
          res.userIds.forEach(id => {
            this.userGroupById[userGroup.contractId + userGroup.name].push(this.users[id]);
          });
          this.userService.getById(this.user.id).then(users => {
            this.userGroupService.getAll().then(data => {
              updateLocalStorage(users, this.storageService, data);
            });
          });
          return res;
        }).catch(error => {
          this.alertService.show(new AlertItem('UserGroupSaveError', AlertType.danger));
          throw error;
        });
      }).catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  setUserGroups(list) {
    this.userGroup = this.userGroup.concat(list);
    list.forEach((userGroup: UserGroup) => {
      userGroup.showArea1 = false;
      userGroup.showArea2 = false;
      userGroup.showArea3 = false;
      userGroup.showArea4 = false;
      if (this.userGroups.indexOf(userGroup) < 0) {
        this.userGroupById[userGroup.contractId + userGroup.id] = [];
        const promises = [];
        userGroup.userIds.forEach(userId => {
          if (this.users.indexOf(userId) < 0) {
            promises.push(this.userService.getById(userId).then(user => {
              this.users[userId] = user;
              this.userGroupById[userGroup.contractId + userGroup.id].push(this.users[userId]);
            }));
          } else {
            this.userGroupById[userGroup.contractId + userGroup.id].push(this.users[userId]);
          }
        });
        Promise.all(promises).then(() => {
          this.userGroupById[userGroup.contractId + userGroup.id] = this.userGroupById[userGroup.contractId + userGroup.id]
            .sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1);
        }).catch(err => {
        });
        this.userGroups[userGroup.contractId + userGroup.name] = userGroup;
        this.userGroups.push(userGroup);
      }
    });
    if (this.userGroups.length === 0) {
      this.noRegister = true;
    } else {
      this.createEditForm(this.userGroups);
    }
  }

  checkPermissions(permissionChecked, userGroupId) {
    let checkedPermission = this.userGroupForms[userGroupId].formGroup.get(
      permissionChecked
    ).value;
    const separatePermission = permissionChecked.match(/(Create|Update|View|Delete)(.*)/);
    const verb = separatePermission[1];
    const model = separatePermission[2];
    const crud = ['Create', 'Delete', 'Update'];

    if (verb === 'View' && !checkedPermission) {
      crud.forEach(item => {
        this.userGroupForms[userGroupId].formGroup.patchValue({
          [item + model]: checkedPermission
        });
      });
    } else {
      if (!checkedPermission) {
        for (const item of crud) {
          checkedPermission = (this.userGroupForms[userGroupId].formGroup.get(
            item + model
          ) && this.userGroupForms[userGroupId].formGroup.get(
            item + model
          ).value) || false;
          if (checkedPermission) {
            break;
          }
        }
      }
      if (model !== 'Contract') {
        this.userGroupForms[userGroupId].formGroup.patchValue({
          ['View' + model]: checkedPermission
        });
      }
    }
  }

  checkViolationFields(permissionChecked, userGroupId) {
    if (permissionChecked === 'UpdatePreProcessSettings') {
      const checkedPermission = this.userGroupForms[userGroupId].formGroup.get(
        permissionChecked
      ).value;
      if (checkedPermission) {
        this.userGroupForms[userGroupId].formGroup.patchValue({
          ['ViewViolation']: checkedPermission
        });
      }
    }
  }

  checkViewViolation(userGroupId) {
    const checkedPermission = this.userGroupForms[userGroupId].formGroup.get('ViewViolation').value;
    this.userGroupForms[userGroupId].formGroup.patchValue({
      ['ViewViolation']: checkedPermission
    });
  }

  showImportFile(contractId) {
    const contract = this.contracts.find(c => c.id === contractId);
    if (contract) {
      return contract.enabledImportFile;
    }
  }
}
