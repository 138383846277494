import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalContent } from 'src/app/core/interface';
import { Contract, Reason, Regulation, ReasonTypes, User, AlertItem, AlertType } from 'src/app/core/models';
import {
  AlertService,
  ContractGlobalService, ContractService, ModalService,
  ReasonService,
  RegulationService, StorageKey, StorageService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-reason-create-or-update',
  templateUrl: './reason-create-or-update.component.html',
  styleUrls: ['./reason-create-or-update.component.sass']
})
export class ReasonCreateOrUpdateComponent extends BaseModal implements OnInit, ModalContent {
  @Input() initialState;
  public contractsPromise: Promise<Contract[]>;
  public reasonForm: FormGroup;
  public reason: Reason = new Reason();
  public contractGlobal = null;
  public isValid = false;
  public promise: Promise<any>;
  public user: User;
  public currentPermissions = [];
  public contracts: Contract[] = [];
  public regulations: Regulation[] = [];
  public contractSelected: Contract;
  public reasonTypes = Object.values(ReasonTypes);
  public reasonTypesI18n = [];
  public reasonType = '';

  constructor(
    private formBuilder: FormBuilder,
    private contractsGlobalService: ContractGlobalService,
    private storageService: StorageService,
    public modalService: ModalService,
    public contractService: ContractService,
    public regulationService: RegulationService,
    private alertService: AlertService,
    public reasonService: ReasonService
  ) {
    super(modalService);
    if (this.contractsGlobalService.contracts != null) {
      this.contractGlobal = this.contractsGlobalService.contracts.length === 1 ? this.contractsGlobalService.contracts[0] : null;
    }
  }

  ngOnInit() {
    this.reason = this.initialState as Reason || new Reason();
    const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
    this.currentPermissions = currentPermissions &&
      currentPermissions.filter(contractPermission =>
        contractPermission.actionIds.indexOf('CreateReason') >= 0 || contractPermission.actionIds.indexOf('UpdateReason') >= 0);
    this.user = this.storageService.get(StorageKey.currentUser);

    this.contractsPromise = this.contractService.getAll({ 'status[neq]': 'closed' }).then(res => {
      if (this.user.superUser) {
        this.contracts = res;
      } else {
        this.contracts = res.filter(contract => this.currentPermissions.find(contractPermissions =>
          contractPermissions.contractId === contract.id));
      }
      if (this.reason.contractId == null) {
        this.reason.contractId = this.contractGlobal;
      }

      this.regulationService.list({ regionId: this.contracts[0].regionId }).then(resultRegulation => {
        resultRegulation.result.map(item => {
          item.shortDescription = `${item.code} - ${item.shortDescription}`;
        });
        this.regulations = resultRegulation.result;
      });

      return this.contracts;
    });
    this.createForm();
  }

  createForm(): void {
    this.reasonForm = this.formBuilder.group({
      contractId: [this.reason.contractId || this.contractGlobal, [Validators.required]],
      code: [this.reason.code, [Validators.required]],
      name: [this.reason.name, [Validators.required]],
      regulationIds: [this.reason.regulationIds, [Validators.required]],
      type: [this.reason.type, [Validators.required]],
      enabled: [this.reason.enabled || false]
    });
    this.reasonForm.valueChanges.subscribe(() => {
      this.isValid = this.reasonForm.valid;
    });
    if (this.reason.contractId && this.reason?.contractId !== '' &&
      this.reason.code && this.reason?.code !== '' &&
      this.reason.name &&  this.reason?.name !== '' &&
      this.reason.regulationIds && this.reason.regulationIds.length > 0 &&
      this.reason.type &&  this.reason?.type !== '') {
      this.isValid = true;
    }
    if (this.reason?.type !== '') {
      this.reasonType = this.reason.type;
    }
    this.reasonForm.get('type').valueChanges.subscribe(type => {
      this.reasonType = type;
    });
  }

  get lastState(): any {
    throw new Error('Method not implemented.');
  }

  public async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    const regulationIds = this.reasonForm.get('regulationIds').value || [];
    let type = '';
    const typeRason = this.reasonForm.get('type').value;
    if (typeRason && typeRason !== '') {
      type = this.reasonTypesI18n.find(r => r.value.trim() === typeRason || r.id.trim() === typeRason).value.trim();
    }
    if (regulationIds.length === 0) {
      this.reasonForm.patchValue({ regulationIds: [] });
    }

    const enabled = this.reasonForm.get('enabled').value || false;
    const data: any = {
      id: this.reason.id,
      contractId: this.reasonForm.get('contractId').value,
      code: this.reasonForm.get('code').value,
      name: this.reasonForm.get('name').value,
      type,
      enabled,
      regulationIds
    };
    const reason = Reason.create(data);
    if (reason.id != null) {
      this.promise = this.reasonService.update(reason);
    } else {
      this.promise = this.reasonService.create(reason);
    }
    this.promise.then(res => {
      this.alertService.show(new AlertItem('ReasonSaved', AlertType.success));
      return res;
    }).catch(error => {
      if (error.status === 409) {
        this.alertService.show(new AlertItem('ReasonSaveConflict', AlertType.danger));
      } else {
        this.alertService.show(new AlertItem('ReasonSaveError', AlertType.danger));
      }
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }
}
