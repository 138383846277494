/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import { BaseApiModel } from './BaseApiModel';

export class RestrictionZones extends BaseApiModel {
  public code: string;
  public description: string;
  public contractId: number;
  public createdAt: string;
  public modifiedAt: string;
}
