import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';

import { ProductionContractReport, Regulation, User } from 'src/app/core/models';
import {
  ContractService, ProductionReportService, RegulationService,
  SessionService, PreProcessSettingsService, UserService, UserGroupService,
  StorageKey, StorageService, ContractGlobalService, RegionService
} from 'src/app/core/services';

@Component({
  selector: 'app-production-report',
  templateUrl: './production.component.html'
})
export class ProductionReportComponent implements OnInit {
  public contracts: any[] = [];
  public regulations: Regulation[] = [];
  public regulationsIds: any[] = [];
  public users: User[] = [];
  public userGroups: any[] = [];
  public contractProduction: ProductionContractReport[] = [];
  public contractsUser: any[] = [];
  public dataProduction: any = [];
  public stepsIds: any[] = ['typing', 'verifyValid', 'verifyInvalid', 'validate'];
  public stepsSession: any = ['typing', 'verify', 'validate'];
  public stepsTranslated;

  constructor(
    private contractsGlobalService: ContractGlobalService,
    private productionReportService: ProductionReportService,
    private contractService: ContractService,
    private sessionService: SessionService,
    private regulationService: RegulationService,
    private preProcessSettingsService: PreProcessSettingsService,
    private userService: UserService,
    private userGroupService: UserGroupService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private regionService: RegionService,
  ) {
    if (this.contractsGlobalService.contracts != null) {
      if (this.contractsGlobalService.contracts.length === 1) {
        const contract = this.contractsGlobalService.contracts.shift();
        this.router.navigate([contract], { relativeTo: this.route }).then(() => {
          window.location.reload();
        });
      }
    }
  }

  async ngOnInit() {
    const currentUser = this.storageService.get(StorageKey.currentUser);
    await this.regionService.list({ name: 'Brasil' }).then(async res => {
      const region = res.result[0];
      await this.regulationService.list({ regionId: region.id }).then(r => {
        this.regulations = r.result;
        this.regulationsIds = this.regulations.map(regulation => regulation.id);
      });

      const paramsContract: any = {};
      paramsContract['modules[contains]'] = 'preProcess';

      await this.userService.getAll({ 'verified[eq]': 'true' })
        .then(users => this.users = users);

      await this.userGroupService.getAll().then(userGroups => {
          this.userGroups = userGroups;

          userGroups.map(group => {
            if (currentUser.groupIds.includes(group.id) && group.permissions.includes('PreProcessProductionReport')) {
              this.contractsUser.push(group.contractId);
            }
          });
        });
        if (!currentUser.superUser) {
          paramsContract['code[in]'] = `[${this.contractsUser.join()}]`;
        }

      await this.contractService.getAll(paramsContract)
        .then(contracts => {
          this.contracts = contracts.map(contract => {
            const stepsContract = [];
            this.preProcessSettingsService.getById(contract.id).then(data => {
              const stepsFromData = Object.keys(data.violationSteps);
              stepsFromData.map(step => {
                if (this.stepsIds.includes(step)) {
                  stepsContract.push(step);
                }
              });
              // eslint-disable-next-line arrow-body-style
            }).catch(err => {
              return;
            });
            const contractGroup = this.userGroups
              .filter(group => group.contractId === contract.id)
              .map(group => ({ id: group.id, name: group.prettyId, users: group.userIds }));

            return {
              id: contract.id, contractName: contract.name,
              steps: stepsContract, groups: contractGroup,
              timezone: contract.timezone
            };
          });
          const contractsIds = contracts.map(c => c.id);
          const now = new Date().toISOString();
          const sessionsParams = {};
          sessionsParams['contractId[in]'] = `[${contractsIds.join()}]`;
          sessionsParams['startsAt[gte]'] = moment(now).utc().subtract(60, 'days').format();
          sessionsParams['modifiedAt[lte, or]'] = moment(now).utc().format();
          sessionsParams['step[in]'] = `[${this.stepsIds.join()}]`;

          this.sessionService.getAll(sessionsParams).then(sessions => {
            for (const contract of this.contracts) {
              const objContract: any = {};
              const sessionContract = sessions.filter(item => Number(item.contractId) === contract.id);
              objContract.id = contract.id;
              objContract.name = contract.contractName;
              objContract.steps = contract.steps;
              objContract.collapse = false;
              objContract.totalWork = 0;
              objContract.timeWorked = 0;
              objContract.regulations = [];
              objContract.groups = contract.groups;
              objContract.timezone = contract.timezone;

              objContract.users = [];
              let usersWithSessions = sessionContract.map(item => item.userId).
                filter((value, index, self) => self.indexOf(value) === index);
              let usersContract;

              if (contract.groups) {
                usersContract = contract.groups
                  .map(group => group.users)
                  .flat()
                  .reduce((list, userId) => {
                    if (!list.includes(userId)) { list.push(userId); }
                    return list;
                  }, []);

                usersWithSessions = usersContract.concat(usersWithSessions).reduce((list, userId) => {
                  if (!list.includes(userId)) { list.push(userId); }
                  return list;
                }, []);
              }

              for (const user of usersWithSessions) {
                const objUser: any = {};
                const userSessions = sessionContract.filter(item => item.userId === user);
                objUser.id = user;

                const userInfo = this.users.find(item => item.id === user);
                objUser.name = (userInfo && userInfo.name) || user;
                objUser.collapse = false;
                objUser.timeWorked = 0;
                objUser.totalWork = 0;
                objUser.superUser = (userInfo && userInfo.superUser);
                objUser.noInfo = userInfo === undefined ? true : false;
                objUser.noGroup = !usersContract.includes(user);

                contract.steps.map(step => {
                  const stepValid = step;
                  const sessionFromStep = userSessions.filter(item => item.step === stepValid);
                  objUser[step] = this.getSessionsSize(sessionFromStep, step);
                });

                objUser.dates = [];

                const datesWithSessions = userSessions.reduce((dates, session) => {
                  const startsAt = moment.tz(session.startsAt, contract.timezone).format();
                  const date = startsAt.split('T')[0];
                  if (!dates.includes(date)) { dates.push(date); }
                  return dates.sort().reverse();
                }, []);

                for (const date of datesWithSessions) {
                  const objDate: any = {};
                  const dateSessions = userSessions.filter(session => {
                    const startsAt = moment.tz(session.startsAt, contract.timezone).format();
                    return startsAt.split('T')[0] === date;
                  });

                  objDate.date = date;
                  objDate.timeWorked = 0;
                  objDate.totalWork = 0;
                  objDate.collapse = false;
                  objDate.timeWorked = dateSessions.reduce(this.getTimeWorked, 0);

                  contract.steps.map(step => {
                    const stepValid = step;
                    const sessionFromStep = dateSessions.filter(item => item.step === stepValid);
                    objDate[step] = this.getSessionsSize(sessionFromStep, step);
                  });

                  objDate.regulations = [];

                  for (const regulation of this.regulations) {
                    const objRegulation: any = {};
                    const regulationsSessions = dateSessions.filter(session => session[regulation.id] ||
                      session['invalid#' + regulation.id] || session['valid#' + regulation.id]);

                    objRegulation.code = regulation.code;
                    objRegulation.id = regulation.id;
                    objRegulation.totalWork = 0;

                    contract.steps.map(step => {
                      const stepValid = step;
                      const sessionFromStep = regulationsSessions.filter(item => item.step === stepValid);
                      objRegulation[step] = this.getSessionsSize(sessionFromStep, step, regulation.id);
                      objDate.totalWork += objRegulation[step];
                      objRegulation.totalWork += objRegulation[step];
                    });

                    if (regulationsSessions.length > 0 && !objContract.regulations.find(item => item.id === regulation.id)) {
                      const regulationData = {
                        id: regulation.id,
                        code: regulation.code,
                        description: regulation.description,
                        prettyName: regulation.prettyName
                      };
                      objContract.regulations.push(regulationData);
                    }

                    if (objRegulation.totalWork > 0) {
                      objDate.regulations.push(objRegulation);
                    }
                  }

                  if (objDate.totalWork > 0) {
                    objUser.totalWork += objDate.totalWork;
                    objUser.timeWorked += objDate.timeWorked;
                    objDate.regulations.sort((a, b) => (a.totalWork > b.totalWork) ? -1 : 1);
                    objUser.dates.push(objDate);
                  }
                }

                if (objUser.superUser === false && objUser.totalWork > 0) {
                  objContract.totalWork += objUser.totalWork;
                  objContract.timeWorked += objUser.timeWorked;
                  objContract.users.push(objUser);
                }
              }

              contract.steps.map(step => {
                objContract[step] = objContract.users.reduce((acc, cur) => acc + cur[step], 0);
              });

              objContract.users.sort((a, b) => (a.totalWork > b.totalWork) ? -1 : 1);
              this.contractProduction.push(objContract);
            }

            this.contractProduction.sort((a, b) => (a.totalWork > b.totalWork) ? -1 : 1);
            if (this.contractProduction.length > 0) {
              this.dataProduction = {
                users: this.users,
                userGroups: this.userGroups,
                regulations: this.regulations,
                steps: this.stepsTranslated,
                contracts: this.contracts,
                contractsProduction: this.contractProduction
              };

              this.productionReportService.emitDataProduction(this.dataProduction);
            }
          });
        });
    });
  }

  getSessionsSize(sessions, stepList, regulation = null) {
    const allRegulations = sessions.map((session) => {
      const { contractId, startsAt, createdAt, id, modifiedAt, userId, step, ...regulations } = session;
      const getValidRegulations = (reg) => stepList === 'verifyInvalid' ? reg.split('#')[0] === 'invalid' : reg.split('#')[0] !== 'invalid';
      let listRegulations;
      if (regulation) {
        const regulationsSource = Object.keys(regulations).filter(reg => Number(reg) === regulation);
        listRegulations = regulationsSource.filter(getValidRegulations);
      } else {
        listRegulations = Object.keys(regulations).filter(getValidRegulations);
      }

      let total = 0;
      listRegulations.forEach(item => total += regulations[item]);

      return total;
    });

    return allRegulations.reduce((acc, curr) => acc + curr, 0);
  }


  getTimeWorked(acc, curr) {
    const startTime = moment(curr.startsAt);
    const endTime = moment(curr.modifiedAt);
    return acc + endTime.diff(startTime);
  }

  setResources(value) {
    this.stepsTranslated = value;
  }

}
