import { Component, OnInit } from '@angular/core';
import { EquipmentModel, Reason, Regulation } from 'src/app/core/models';
import { ModalService, LaneService, ReasonService } from 'src/app/core/services';
import { Lane } from 'src/app/core/models';

@Component({
  selector: 'app-view-modal',
  templateUrl: './view-modal.component.html',
  styleUrls: ['./view-modal.component.sass']
})
export class ViewModalComponent implements OnInit {

  public params: any = {};
  public modalData: any = {};
  public equipmentModelsById: { [key: string]: EquipmentModel } = {};
  public regulationsById: { [key: string]: Regulation } = {};
  public laneById: { [key: string]: Lane } = {};
  public reasonById: { [key: string]: Reason } = {};
  public namePattern: any;
  public serieExemple: string;
  public isLoading: boolean;
  protected initialState: any;

  constructor(
    public modalService: ModalService,
    private reasonService: ReasonService,
    private laneService: LaneService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.params = { contractId: (this.initialState && this.initialState.contractId) || '' };
    this.modalData = (this.initialState && this.initialState.serialNumberConfig) || '';
    this.equipmentModelsById = (this.initialState && this.initialState.equipmentModelsById) || '';
    this.regulationsById = (this.initialState && this.initialState.regulationsById) || null ;
    this.namePattern = this.setNamePattern();
    await this.getLanes();
    await this.getReason();
    this.isLoading = false;
    this.setSerieExample();
  }

  onClose() {
    this.modalService.close();
  }

  async getLanes() {
    await this.laneService.getAll(this.params).then(lanes => {
      lanes.forEach(lane => {
        this.laneById[lane.id] = lane;
      });
    });
  }

  async getReason() {
    await this.reasonService.getAll(this.params).then(reasons => {
      reasons.forEach(item => {
        this.reasonById[item.id] = item;
      });
    });
  }

  setNamePattern() {
    return this.modalData.namePattern &&
    this.modalData.namePattern.split('}}').map(name => name.replace('{{', '').split('.')[1]).filter(i => i);
  }

  setSerieExample() {
    const sizePattern = this.modalData.sizePattern;
    const currentNumber = this.modalData.currentNumber || this.modalData.range.start;
    const names = this.setNamePattern();

    if (names != null) {
      let pathFormat = '';
      for (const name of names) {
        if (name === 'currentNumber' && sizePattern > 0) {
          let padlCurrent = '' + (currentNumber > 0 ? currentNumber : 1);
          padlCurrent = padlCurrent.padStart(sizePattern, '0');
          pathFormat += padlCurrent;
          continue;
        }
      }
      this.modalData.currentNumber = pathFormat;
      this.serieExemple = this.modalData.identifier + this.modalData.prefix + this.modalData.verifyingDigit + pathFormat;
    }
  }

}
