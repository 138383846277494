import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import {LotService, ModalService} from 'src/app/core/services';

@Component({
  selector: 'app-audit-history',
  templateUrl: './lote-audit-info.component.html'
})
export class LoteAuditInfoComponent extends BaseModal  implements OnInit {
  public violation: any = {};
  public lot: any = {};
  public loading = false;
  constructor( public modalService: ModalService, public lotService: LotService ) {
    super(modalService);
  }
  get isValid(): boolean {
      throw new Error('Method not implemented.');
  }
  get lastState(): any {
      throw new Error('Method not implemented.');
  }
  onAllSubmited(): Promise<any> {
      throw new Error('Method not implemented.');
  }
  ngOnInit(): void {
    this.lotService.getById(this.initialState?.lotId).then(lot => {
      if(lot) {
        this.lot = lot;
      }
    }).finally(() => {
      this.loading = true;
    });
  }
}
