import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Pagination, UserHistory } from '../models';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class UserHistoryService extends BaseApiService<UserHistory> {
  get cacheKey(): StorageKey {
    return StorageKey.userServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/userHistories`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  list(params: {[params: string]: string}): Promise<Pagination<UserHistory>> {
    return super.list(this.removeContractId(params));
  }

  protected unparse(obj) {
    return UserHistory.unparse(obj);
  }

  protected parse(obj) {
    return UserHistory.parse(obj);
  }
}
