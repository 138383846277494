<div class="item pointer-mouse" #item [ngClass]="{'blur': operation === 'blur', 'selected': isSelected}">
  <i *ngIf="isSelected || operation !== 'blur'" class="material-icons pointer-mouse">open_with</i>
  <div class="resizer handle-left"></div>
  <div class="resizer handle-right"></div>
  <div class="resizer handle-top"></div>
  <div class="resizer handle-bottom"></div>
  <div class="resizer handle-top-right"></div>
  <div class="resizer handle-top-left"></div>
  <div class="resizer handle-bottom-left"></div>
  <div class="resizer handle-bottom-right"></div>
</div>
