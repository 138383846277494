<ng-container *ngIf="loading; else loadingBlock">
  <div class="table-flex__item" *ngFor="let contract of violations; let i = index">
    <div class="table-flex__row bg-gray--250">
      <p class="w-25">{{ contractCode }} - {{ contractName }}</p>
      <p class="w-10">Todos
        <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(contract.regulations, 'reasons', contract)"
          *ngIf="contract.total > 0">
          <i class="material-icons" *ngIf="contract.regulations.collapse">expand_more</i>
          <i class="material-icons" *ngIf="!contract.regulations.collapse">expand_less</i>
        </button>
      </p>
      <p class="w-15">
        <ng-container *ngIf="getValueByType(contract, 'inProcess') > 0">
          <a class="no-href" (click)="filterNavigate('details', 'inProcess')">
            {{ getValueByType(contract, 'inProcess') }}/{{ showPercentage(contract, 'inProcess') }}</a>
        </ng-container>
        <ng-container *ngIf="getValueByType(contract, 'inProcess') === 0">0</ng-container>
      </p>
      <p class="w-15">
        <ng-container *ngIf="getValueByType(contract, 'valid') > 0">
          <a class="no-href" (click)="filterNavigate('details', 'valid')">
            {{ getValueByType(contract, 'valid') }}/{{ showPercentage(contract, 'valid') }}</a>
        </ng-container>
        <ng-container *ngIf="getValueByType(contract, 'valid') === 0">0</ng-container>
      </p>
      <p class="w-15">
        <ng-container *ngIf="getValueByType(contract, 'invalid') > 0">
          <a class="no-href" (click)="filterNavigate('details', 'invalid')">
            {{ getValueByType(contract, 'invalid') }}/{{ showPercentage(contract, 'invalid') }}</a>
        </ng-container>
        <ng-container *ngIf="getValueByType(contract, 'invalid') === 0">0</ng-container>

        <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(contract.reasons, 'regulations', contract)"
          *ngIf="haveInvalid(contract.situations)">
          <i class="material-icons" *ngIf="contract.reasons.collapse">expand_more</i>
          <i class="material-icons" *ngIf="!contract.reasons.collapse">expand_less</i>
        </button>
      </p>
      <p class="w-10"><a routerLink="by-date">{{ contract.total }}</a></p>
      <p class="w-10">
        <button class="btn btn-sm btn-icon-block" i18n-appTooltip appTooltip="Expandir/Retrair todos"
          [ngClass]="{ 'btn-secondary': allCollapse, 'btn-outline-secondary': !allCollapse }"
          (click)="toggleAllCollapse()" *ngIf="contract.total > 0">
          <i class="material-icons" *ngIf="allCollapse">unfold_less</i>
          <i class="material-icons" *ngIf="!allCollapse">unfold_more</i>
        </button>
      </p>
    </div>

    <!-- Contract Regulations -->
    <ng-container *ngIf="contract.regulations.collapse">
      <div class="table-flex__item" *ngFor="let regulation of contract.regulations.items; let i = index">
        <div class="table-flex__row">
          <p class="w-25"></p>
          <p class="w-10">
            <span i18n-appTooltip [appTooltip]="getRegulationInfo(regulation.id)"
              class="d-inline-flex align-items-center">
              <i class="material-icons text-light mr-2">info</i>
              {{ regulation.code }}
            </span>
          </p>
          <p class="w-15">
            <ng-container *ngIf="getValueByType(regulation, 'inProcess') > 0">
              <a class="no-href" (click)="filterNavigate('details', 'inProcess', regulation.id)">
                {{ getValueByType(regulation, 'inProcess') }}/{{ showPercentage(regulation, 'inProcess') }}</a>
            </ng-container>
            <ng-container *ngIf="getValueByType(regulation, 'inProcess') === 0">0</ng-container>
          </p>
          <p class="w-15">
            <ng-container *ngIf="getValueByType(regulation, 'valid') > 0">
              <a class="no-href" (click)="filterNavigate('details', 'valid', regulation.id)">
                {{ getValueByType(regulation, 'valid') }}/{{ showPercentage(regulation, 'valid') }}</a>
            </ng-container>
            <ng-container *ngIf="getValueByType(regulation, 'valid') === 0">0</ng-container>
          </p>
          <p class="w-15">
            <ng-container *ngIf="getValueByType(regulation, 'invalid') > 0">
              <a class="no-href" (click)="filterNavigate('details', 'invalid', regulation.id)">
                {{ getValueByType(regulation, 'invalid') }}/{{ showPercentage(regulation, 'invalid') }}</a>
            </ng-container>
            <ng-container *ngIf="getValueByType(regulation, 'invalid') === 0">0</ng-container>

            <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(regulation.reasons)"
              *ngIf="haveInvalid(regulation.situations)">
              <i class="material-icons" *ngIf="regulation.reasons.collapse">expand_more</i>
              <i class="material-icons" *ngIf="!regulation.reasons.collapse">expand_less</i>
            </button>
          </p>
          <p class="w-10"><a class="btn btn-no-href" (click)="filterNavigate('', '', regulation.id)">{{ regulation.total
              }}</a></p>
          <p class="w-10"></p>
        </div>

        <!-- Contract Regulation Reasons -->
        <ng-container *ngIf="regulation.reasons && regulation.reasons.collapse">
          <div class="table-flex__item">
            <div class="table-flex__row" *ngFor="let reason of regulation.reasons.items; let i = index">
              <p class="w-25"></p>
              <p class="w-10"></p>
              <p class="w-15"></p>
              <p class="w-15"></p>
              <p class="w-15">{{ getReasonInfo(reason.id) }}</p>
              <p class="w-10"><a class="btn btn-no-href"
                  (click)="filterNavigate('', '', regulation.id, '', '', reason.id)">{{ reason.total }}</a></p>
              <div class="w-10 pr-3">
                <div class="progress">
                  <div class="progress-bar bg-info" role="progressbar"
                    [style.width]="getPercentage(reason.total, getValueByType(regulation, 'invalid')) + '%'"
                    [attr.aria-valuenow]="reason.total" aria-valuemin="0"
                    [attr.aria-valuemax]="getValueByType(regulation, 'invalid')">
                    {{ getPercentage(reason.total, getValueByType(regulation, 'invalid')) + '%' }}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- Contract Reasons -->
    <ng-container *ngIf="contract.reasons && contract.reasons.collapse">
      <div class="table-flex__row" *ngFor="let reason of contract.reasons.items; let i = index">
        <p class="w-25"></p>
        <p class="w-10"></p>
        <p class="w-15"></p>
        <p class="w-15"></p>
        <p class="w-15">{{ getReasonInfo(reason.id) }}</p>
        <p class="w-10"><a class="btn btn-no-href" (click)="filterNavigate('', '', '', '', '', reason.id)">{{
            reason.total }}</a></p>
        <div class="w-10 pr-3">
          <div class="progress">
            <div class="progress-bar bg-info" role="progressbar"
              [style.width]="getPercentage(reason.total, getValueByType(contract, 'invalid')) + '%'"
              [attr.aria-valuenow]="reason.total" aria-valuemin="0"
              [attr.aria-valuemax]="getValueByType(contract, 'invalid')">
              {{ getPercentage(reason.total, getValueByType(contract, 'invalid')) + '%' }}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Spots -->
    <div class="table-flex__item" *ngFor="let spot of contract.spots; let i = index" [class.collapsed]="spot.collapse">
      <div class="table-flex__row bg-gray--200">
        <p class="w-25 w--push-3" i18n-appTooltip [appTooltip]="'Ponto: ' + spot.code + ' - ' + spot.description">
          {{ spot.code }} {{ (spot.description.length > 20)? ' - ' + (spot.description | slice:0:20) + '...' :
          ' - ' + (spot.description) }}</p>
        <p class="w-10">Todos
          <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(spot.regulations, 'reasons', spot)"
            *ngIf="spot.total > 0">
            <i class="material-icons" *ngIf="spot.regulations.collapse">expand_more</i>
            <i class="material-icons" *ngIf="!spot.regulations.collapse">expand_less</i>
          </button>
        </p>
        <p class="w-15">
          <ng-container *ngIf="getValueByType(spot, 'inProcess') > 0">
            <a class="no-href" (click)="filterNavigate('', 'inProcess', '', spot.id)">
              {{ getValueByType(spot, 'inProcess') }}/{{ showPercentage(spot, 'inProcess') }}</a>
          </ng-container>
          <ng-container *ngIf="getValueByType(spot, 'inProcess') === 0">0</ng-container>
        </p>
        <p class="w-15">
          <ng-container *ngIf="getValueByType(spot, 'valid') > 0">
            <a class="no-href" (click)="filterNavigate('details', 'valid', '', spot.id)">
              {{ getValueByType(spot, 'valid') }}/{{ showPercentage(spot, 'valid') }}</a>
          </ng-container>
          <ng-container *ngIf="getValueByType(spot, 'valid') === 0">0</ng-container>
        </p>
        <p class="w-15">
          <ng-container *ngIf="getValueByType(spot, 'invalid') > 0">
            <a class="no-href" (click)="filterNavigate('details', 'invalid', '', spot.id)">
              {{ getValueByType(spot, 'invalid') }}/{{ showPercentage(spot, 'invalid') }}</a>
          </ng-container>
          <ng-container *ngIf="getValueByType(spot, 'invalid') === 0">0</ng-container>

          <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(spot.reasons, 'regulations', spot)"
            *ngIf="haveInvalid(spot.situations)">
            <i class="material-icons" *ngIf="spot.reasons.collapse">expand_more</i>
            <i class="material-icons" *ngIf="!spot.reasons.collapse">expand_less</i>
          </button>
        </p>
        <p class="w-10"><a class="btn btn-no-href" (click)="filterNavigate('', '', '', spot.id)" i18n-appTooltip
            appTooltip="Total por ponto">
            {{ spot.total }}</a></p>
        <div class="w-10 text-center">
          <button class="btn btn-sm btn-icon-block"
            [ngClass]="{ 'btn-secondary': spot.collapse, 'btn-outline-secondary': !spot.collapse }"
            (click)="toggleCollapse(spot)" *ngIf="spot.total > 0">
            <i class="material-icons" *ngIf="spot.collapse">unfold_less</i>
            <i class="material-icons" *ngIf="!spot.collapse">unfold_more</i>
          </button>
        </div>
      </div>

      <!-- Spot Regulations -->
      <ng-container *ngIf="spot.regulations.collapse">
        <div class="table-flex__item" *ngFor="let regulationSpot of spot.regulations.items; let i = index">
          <div class="table-flex__row">
            <p class="w-25"></p>
            <p class="w-10">
              <span i18n-appTooltip [appTooltip]="getRegulationInfo(regulationSpot.id)"
                class="d-inline-flex align-items-center">
                <i class="material-icons text-light mr-2">info</i>
                {{ regulationSpot.code }}
              </span>
            </p>
            <p class="w-15">
              <ng-container *ngIf="getValueByType(regulationSpot, 'inProcess') > 0">
                <a class="no-href" (click)="filterNavigate('details', 'inProcess', regulationSpot.id, spot.id)">
                  {{ getValueByType(regulationSpot, 'inProcess') }}/{{ showPercentage(regulationSpot, 'inProcess')
                  }}</a>
              </ng-container>
              <ng-container *ngIf="getValueByType(regulationSpot, 'inProcess') === 0">0</ng-container>
            </p>
            <p class="w-15">
              <ng-container *ngIf="getValueByType(regulationSpot, 'valid') > 0">
                <a class="no-href" (click)="filterNavigate('details', 'valid', regulationSpot.id, spot.id)">
                  {{ getValueByType(regulationSpot, 'valid') }}/{{ showPercentage(regulationSpot, 'valid') }}</a>
              </ng-container>
              <ng-container *ngIf="getValueByType(regulationSpot, 'valid') === 0">0</ng-container>
            </p>
            <p class="w-15">
              <ng-container *ngIf="getValueByType(regulationSpot, 'invalid') > 0">
                <a class="no-href" (click)="filterNavigate('details', 'invalid', regulationSpot.id, spot.id)">
                  {{ getValueByType(regulationSpot, 'invalid') }}/{{ showPercentage(regulationSpot, 'invalid') }}</a>
              </ng-container>
              <ng-container *ngIf="getValueByType(regulationSpot, 'invalid') === 0">0</ng-container>

              <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(regulationSpot.reasons)"
                *ngIf="haveInvalid(regulationSpot.situations)">
                <i class="material-icons" *ngIf="regulationSpot.reasons.collapse">expand_more</i>
                <i class="material-icons" *ngIf="!regulationSpot.reasons.collapse">expand_less</i>
              </button>
            </p>
            <p class="w-10"><a class="btn btn-no-href" (click)="filterNavigate('', '', regulationSpot.id, spot.id)">{{
                regulationSpot.total }}</a></p>
            <p class="w-10"></p>
          </div>

          <ng-container *ngIf="regulationSpot.reasons && regulationSpot.reasons.collapse">
            <div class="table-flex__row" *ngFor="let reasonSpot of regulationSpot.reasons.items; let i = index">
              <p class="w-25"></p>
              <p class="w-10"></p>
              <p class="w-15"></p>
              <p class="w-15"></p>
              <p class="w-15">{{ getReasonInfo(reasonSpot.id) }}</p>
              <p class="w-10"><a class="btn btn-no-href"
                  (click)="filterNavigate('', '', regulationSpot.id, spot.id, '', reasonSpot.id)">
                  {{ reasonSpot.total }}</a>
              </p>
              <div class="w-10 pr-3">
                <div class="progress">
                  <div class="progress-bar bg-info" role="progressbar"
                    [style.width]="getPercentage(reasonSpot.total, getValueByType(spot, 'invalid')) + '%'"
                    [attr.aria-valuenow]="reasonSpot.total" aria-valuemin="0"
                    [attr.aria-valuemax]="getValueByType(spot, 'invalid')">
                    {{ getPercentage(reasonSpot.total, getValueByType(spot, 'invalid')) + '%' }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Spot Reasons -->
      <ng-container *ngIf="spot.reasons && spot.reasons.collapse">
        <div class="table-flex__row" *ngFor="let reasonSpot of spot.reasons.items; let i = index">
          <p class="w-25"></p>
          <p class="w-10"></p>
          <p class="w-15"></p>
          <p class="w-15"></p>
          <p class="w-15">{{ getReasonInfo(reasonSpot.id) }}</p>
          <p class="w-10"><a class="btn btn-no-href" (click)="filterNavigate('', '', '', spot.id, '', reasonSpot.id)">{{
              reasonSpot.total }}</a></p>
          <div class="w-10 pr-3">
            <div class="progress">
              <div class="progress-bar bg-info" role="progressbar"
                [style.width]="getPercentage(reasonSpot.total, getValueByType(spot, 'invalid')) + '%'"
                [attr.aria-valuenow]="reasonSpot.total" aria-valuemin="0"
                [attr.aria-valuemax]="getValueByType(spot, 'invalid')">
                {{ getPercentage(reasonSpot.total, getValueByType(spot, 'invalid')) + '%' }}</div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Lane -->
      <div class="table-flex__item collapse" *ngFor="let lane of spot.lanes; let i = index">
        <div class="table-flex__row bg-gray--100">
          <p class="w-25 w--push-5" i18n-appTooltip [appTooltip]="lane.name">
            {{ (lane.name && lane.name.length > 20)? (lane.name | slice:0:20) + '...' : (lane.name) }}</p>
          <p class="w-10">Todos
            <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(lane.regulations, 'reasons', lane)"
              *ngIf="lane.total > 0">
              <i class="material-icons" *ngIf="lane.regulations.collapse">expand_more</i>
              <i class="material-icons" *ngIf="!lane.regulations.collapse">expand_less</i>
            </button>
          </p>
          <p class="w-15">
            <ng-container *ngIf="getValueByType(lane, 'inProcess') > 0">
              <a class="no-href" (click)="filterNavigate('details', 'inProcess', '', spot.id, lane.id)">
                {{ getValueByType(lane, 'inProcess') }}/{{ showPercentage(lane, 'inProcess') }}</a>
            </ng-container>
            <ng-container *ngIf="getValueByType(lane, 'inProcess') === 0">0</ng-container>
          </p>
          <p class="w-15">
            <ng-container *ngIf="getValueByType(lane, 'valid') > 0">
              <a class="no-href" (click)="filterNavigate('details', 'valid', '', spot.id, lane.id)">
                {{ getValueByType(lane, 'valid') }}/{{ showPercentage(lane, 'valid') }}</a>
            </ng-container>
            <ng-container *ngIf="getValueByType(lane, 'valid') === 0">0</ng-container>
          </p>
          <p class="w-15">
            <ng-container *ngIf="getValueByType(lane, 'invalid') > 0">
              <a class="no-href" (click)="filterNavigate('details', 'invalid', '', spot.id, lane.id)">
                {{ getValueByType(lane, 'invalid') }}/{{ showPercentage(lane, 'invalid') }}</a>
            </ng-container>
            <ng-container *ngIf="getValueByType(lane, 'invalid') === 0">0</ng-container>

            <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(lane.reasons, 'regulations', lane)"
              *ngIf="haveInvalid(lane.situations)">
              <i class="material-icons" *ngIf="lane.reasons.collapse">expand_more</i>
              <i class="material-icons" *ngIf="!lane.reasons.collapse">expand_less</i>
            </button>
          </p>
          <p class="w-10"><a class="btn btn-no-href" (click)="filterNavigate('', '', '', spot.id, lane.id)">
              {{ lane.total }}</a></p>
          <p class="w-10"></p>
        </div>

        <!-- Lane Regulations -->
        <ng-container *ngIf="lane.regulations.collapse">
          <div class="table-flex__item" *ngFor="let regulationLane of lane.regulations.items; let i = index">
            <div class="table-flex__row">
              <p class="w-25"></p>
              <p class="w-10">
                <span i18n-appTooltip [appTooltip]="getRegulationInfo(regulationLane.id)"
                  class="d-inline-flex align-items-center">
                  <i class="material-icons text-light mr-2">info</i>
                  {{ regulationLane.code }}
                </span>
              </p>
              <p class="w-15">
                <ng-container *ngIf="getValueByType(regulationLane, 'inProcess') > 0">
                  <a class="no-href"
                    (click)="filterNavigate('details', 'inProcess', regulationSpot?.id, spot?.id, lane?.id)">
                    {{ getValueByType(regulationLane, 'inProcess') }}/{{ showPercentage(regulationLane, 'inProcess')
                    }}</a>
                </ng-container>
                <ng-container *ngIf="getValueByType(regulationLane, 'inProcess') === 0">0</ng-container>
              </p>
              <p class="w-15">
                <ng-container *ngIf="getValueByType(regulationLane, 'valid') > 0">
                  <a class="no-href" (click)="filterNavigate('details', 'valid', regulationSpot?.id, spot?.id, lane?.id)">
                    {{ getValueByType(regulationLane, 'valid') }}/{{ showPercentage(regulationLane, 'valid') }}</a>
                </ng-container>
                <ng-container *ngIf="getValueByType(regulationLane, 'valid') === 0">0</ng-container>
              </p>
              <p class="w-15">
                <ng-container *ngIf="getValueByType(regulationLane, 'invalid') > 0">
                  <a class="no-href"
                    (click)="filterNavigate('details', 'invalid', regulationSpot?.id, spot?.id, lane?.id)">
                    {{ getValueByType(regulationLane, 'invalid') }}/{{ showPercentage(regulationLane, 'invalid') }}</a>
                </ng-container>
                <ng-container *ngIf="getValueByType(regulationLane, 'invalid') === 0">0</ng-container>

                <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(regulationLane.reasons)"
                  *ngIf="haveInvalid(regulationLane.situations)">
                  <i class="material-icons" *ngIf="regulationLane.reasons.collapse">expand_more</i>
                  <i class="material-icons" *ngIf="!regulationLane.reasons.collapse">expand_less</i>
                </button>
              </p>
              <p class="w-10"><a class="btn btn-no-href"
                  (click)="filterNavigate('', '', regulationSpot?.id, spot?.id, lane?.id)">{{ regulationLane.total }}</a>
              </p>
              <p class="w-10"></p>
            </div>

            <ng-container *ngIf="regulationLane.reasons && regulationLane.reasons.collapse">
              <div class="table-flex__row" *ngFor="let reasonLane of regulationLane.reasons.items; let i = index">
                <p class="w-25"></p>
                <p class="w-10"></p>
                <p class="w-15"></p>
                <p class="w-15"></p>
                <p class="w-15">{{ getReasonInfo(reasonLane.id) }}</p>
                <p class="w-10"><a class="btn btn-no-href"
                    (click)="filterNavigate('', '', regulationLane?.id, spot?.id, reasonLane?.id, reasonSpot?.id)">{{
                    reasonLane.total }}</a></p>
                <div class="w-10 pr-3">
                  <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar"
                      [style.width]="getPercentage(reasonLane.total, getValueByType(lane, 'invalid')) + '%'"
                      [attr.aria-valuenow]="reasonLane.total" aria-valuemin="0"
                      [attr.aria-valuemax]="getValueByType(lane, 'invalid')">
                      {{ getPercentage(reasonLane.total, getValueByType(lane, 'invalid')) + '%' }}</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Lane Reasons -->
        <ng-container *ngIf="lane.reasons && lane.reasons.collapse">
          <div class="table-flex__row" *ngFor="let reasonLane of lane.reasons.items; let i = index">
            <p class="w-25"></p>
            <p class="w-10"></p>
            <p class="w-15"></p>
            <p class="w-15"></p>
            <p class="w-15">{{ getReasonInfo(reasonLane.id) }}</p>
            <p class="w-10"><a class="btn btn-no-href"
                (click)="filterNavigate('', '', '', spot?.id, lane?.id, reasonLane?.id)">{{ reasonLane.total }}</a></p>
            <div class="w-10 pr-3">
              <div class="progress">
                <div class="progress-bar bg-info" role="progressbar"
                  [style.width]="getPercentage(reasonLane.total, getValueByType(lane, 'invalid')) + '%'"
                  [attr.aria-valuenow]="reasonLane.total" aria-valuemin="0"
                  [attr.aria-valuemax]="getValueByType(lane, 'invalid')">
                  {{ getPercentage(reasonLane.total, getValueByType(lane, 'invalid')) + '%' }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>
