import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { ModalContent } from 'src/app/core/interface';
import { AlertItem, AlertType, Calibration, TypeCalibration } from 'src/app/core/models';
import { AlertService, ModalService, ContractService, SpotService, EquipmentService } from 'src/app/core/services';
import { CalibrationService } from 'src/app/core/services/calibration.service';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-equipments-create-or-update-calibration',
  templateUrl: './equipments-create-or-update-calibration.component.html',
  styleUrls: ['./equipments-create-or-update-calibration.component.sass']
})
export class EquipmentsCreateOrUpdateCalibrationComponent extends BaseModal implements OnInit, ModalContent {
  @Input() initialState;
  public equipmentCalibrationForm: FormGroup;
  public types = Object.keys(TypeCalibration).map(typeCalibration => ({ typeCalibration, value: TypeCalibration[typeCalibration] }));
  public get isValid() {
    return this.equipmentCalibrationForm.valid;
  }
  public promise: Promise<any>;
  public calibration: Calibration = new Calibration();
  public files = [];
  public documentFiles = [];
  public extraFiles = [];
  public newFiles = [];
  public newDocumentFiles = [];

  private dateValues = {
    endHour: null,
    endsAt: null,
    startsAt: null,
    startHour: null
  };

  constructor(
    public modalService: ModalService,
    private calibrationService: CalibrationService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private contractService: ContractService,
    private spotService: SpotService,
    private equipmentService: EquipmentService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.calibration = this.initialState as Calibration || new Calibration();
    const document = this.calibration && this.calibration.appraisal && this.calibration.appraisal.document;
    const extraFiles = this.calibration && this.calibration.extraFiles || [];
    if (document != null) {
      this.documentFiles.push(this.loadFileDetails(document));
    }
    extraFiles.forEach(src => {
      this.extraFiles.push(this.loadFileDetails(src));
    });
    this.createForm();
  }

  get lastState() {
    const state = {};
    const keys = Object.keys(this.equipmentCalibrationForm.controls);
    for (const key of keys) {
      state[key] = this.equipmentCalibrationForm.get(key).value;
    }
    return state;
  }

  createForm(): void {
    const startDate = this.calibration.date && moment(this.calibration.date).format('YYYY-MM-DD');
    const startsAt = this.calibration.startsAt && moment(this.calibration.startsAt).format('YYYY-MM-DD');
    const endsAt = this.calibration.endsAt && moment(this.calibration.endsAt).format('YYYY-MM-DD');
    const startHour = this.calibration.startsAt && moment(this.calibration.startsAt).format('HH:mm:ss');
    const endHour = this.calibration.endsAt && moment(this.calibration.endsAt).format('HH:mm:ss');
    const hourRegex = new RegExp(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]$/);

    this.equipmentCalibrationForm = this.formBuilder.group({
      type: [this.calibration.type || '', [Validators.required]],
      speed: [this.calibration.speed || ''],
      date: [startDate || '', [Validators.required]],
      startsAt: [startsAt || '', [Validators.required, this.validateDate]],
      endsAt: [endsAt || '', [Validators.required, this.validateDate]],
      startHour: [startHour || '00:00:00', [Validators.pattern(hourRegex), Validators.required, this.validateDate]],
      endHour: [endHour || '23:59:59', [Validators.pattern(hourRegex), Validators.required, this.validateDate]],
      sealNumber: [this.calibration.sealNumber || ''],
      agency: [this.calibration.agency || ''],
      appraisalNumber: [this.calibration.appraisal && this.calibration.appraisal.number || ''],
      nameTechnician: [this.calibration.technician && this.calibration.technician.name || ''],
      registrationTechnician: [this.calibration.technician && this.calibration.technician.registration || ''],
      typeFile: ['report'],
      appraisalDocument: [this.documentFiles],
      extraFiles: [this.extraFiles],
      processNumber: [this.calibration.processNumber || ''],
      enabled: [this.calibration.enabled || false],
    });
  }

  validateDate = () => {
    if (this.equipmentCalibrationForm == null) {
      return null;
    }
    const dateControls: any = {};
    const newDateValues = {};
    let hasChange = false;
    for (const key of Object.keys(this.dateValues)) {
      dateControls[key] = this.equipmentCalibrationForm.get(key);
      newDateValues[key] = dateControls[key].value;
      if (newDateValues[key] !== this.dateValues[key]) {
        hasChange = true;
      }
    }
    this.dateValues = Object.assign(newDateValues);
    if (hasChange) {
      for (const key of Object.keys(dateControls)) {
        dateControls[key].updateValueAndValidity();
      }
    }
    if ((dateControls.endsAt.value + dateControls.endHour.value) <=
      (dateControls.startsAt.value + dateControls.startHour.value)) {
      return {
        invalidPeriod: 'Fields startHour greater than endHour'
      };
    } else {
      return null;
    }
  };

  getFileName(src) {
    const decoded = decodeURIComponent(src || '');
    const result = decoded.match(/(metrologic#[0-9]+\/)([^\?]+)/) || '';
    return result !== '' ? result[2] : result;
  }

  public async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    const timezone = await this.getTimezone();
    const dtInit = this.equipmentCalibrationForm.get('startsAt').value + ' ' + this.equipmentCalibrationForm.get('startHour').value;
    const dtFinish = this.equipmentCalibrationForm.get('endsAt').value + ' ' + this.equipmentCalibrationForm.get('endHour').value;
    const date = moment.tz(this.equipmentCalibrationForm.get('date').value, timezone).utc().format('YYYY-MM-DD');
    const dateCalibrationInit = moment.tz(dtInit, timezone).utc().format();
    const dateCalibrationFinish = moment.tz(dtFinish, timezone).utc().format();
    if (date > dateCalibrationInit) {
      this.alertService.show(new AlertItem('CalibrationDateInvalid', AlertType.danger, true, 3000));
      throw new Error();
    }
    let document = this.equipmentCalibrationForm.get('appraisalDocument').value[0];
    if (document != null) {
      document = document.isNew === true ? document.detail : document.data;
    }
    const extraFiles = (this.equipmentCalibrationForm.get('extraFiles').value || []).map(f => f.isNew === true ? f.detail : f.data);
    const data = {
      id: this.calibration.id,
      equipmentId: this.calibration.equipmentId,
      contractId: this.calibration.contractId,
      type: this.equipmentCalibrationForm.get('type').value,
      speed: this.equipmentCalibrationForm.get('speed').value,
      date,
      startsAt: dateCalibrationInit,
      endsAt: dateCalibrationFinish,
      sealNumber: this.equipmentCalibrationForm.get('sealNumber').value,
      agency: this.equipmentCalibrationForm.get('agency').value,
      technician: {
        name: this.equipmentCalibrationForm.get('nameTechnician').value,
        registration: this.equipmentCalibrationForm.get('registrationTechnician').value
      },
      appraisal: {
        // eslint-disable-next-line id-blacklist
        number: this.equipmentCalibrationForm.get('appraisalNumber').value,
        document
      },
      extraFiles,
      modifiedAt: this.calibration.modifiedAt,
      processNumber: this.equipmentCalibrationForm.get('processNumber').value
    };

    if (this.equipmentCalibrationForm.get('speed').value === '') {
      delete data.speed;
    }

    if (data.appraisal.document == null) {
      delete data.appraisal.document;
    }

    const calibration = Calibration.create(data);
    if (calibration.id == null) {
      delete calibration.id;
      delete calibration.modifiedAt;
      this.promise = this.calibrationService.create(calibration, true);
    } else {
      this.promise = this.calibrationService.update(calibration, true);
    }

    this.promise.then((res) => {
      this.calibration = res;
      this.alertService.show(new AlertItem('CalibrationSaved', AlertType.success));
      return res;
    }).catch((error) => {
      if (error.status === 409) {
        this.alertService.show(new AlertItem('CalibrationSaveConflict', AlertType.danger, false, 5000));
      } else {
        this.alertService.show(new AlertItem('CalibrationSaveError', AlertType.danger));
      }
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }

  async getTimezone() {
    let timezone;
    const equipment = await this.equipmentService.getById(this.calibration.equipmentId);
    if (equipment.spotIds != null && equipment.spotIds.length > 0) {
      const spot = await this.spotService.getById(equipment.spotIds[0]);
      timezone = spot.timezone;
    }
    if (timezone == null) {
      const contract = await this.contractService.getById(equipment.contractId);
      timezone = contract.timezone;
    }
    return timezone;
  }

  loadFileDetails(url) {
    const name = this.getFileName(url);
    const type = this.getFileType(name);
    const data = {
      detail: {
        type,
        name
      },
      data: url
    };
    return data;
  }

  getFileType(name) {
    let type = name.split('.').pop();
    switch (type) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        type = `image/${type}`;
        break;
      default:
        return type;
    }
    return type;
  }
}
