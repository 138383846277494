<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <div [ngSwitch]='process'>
        <h5 class="modal-title" i18n *ngSwitchCase="'invalidate'">Invalidar Registros</h5>
        <h5 class="modal-title" i18n *ngSwitchCase="'nextStep'">Avançar Registros</h5>
        <h5 class="modal-title" i18n *ngSwitchCase="'reprocess'">Reprocessar Registros</h5>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body label">
      <div class="container">
        <div [ngSwitch]='process'>
          <p i18n *ngSwitchCase="'invalidate'">Tem certeza que deseja invalidar {{violationCount}} registros?</p>
          <div i18n *ngSwitchCase="'nextStep'">
            <div *ngIf="quarantineReason!=='invalidLaneNumber';else invalidLaneNumber">
              <p> As validações desta etapa serão desconsideradas e os registros serão encaminhados para a próxima validação de quarentena. Tem certeza que deseja avançar {{violationCount}} registros? </p>
            </div>
            <ng-template #invalidLaneNumber>
              <p> As validações desta etapa serão desconsideradas e os registros serão encaminhados para a próxima validação de quarentena. </p>
              <p> A infração será atualizada com o número da faixa cadastrado no sistema. Tem certeza que deseja avançar {{violationCount}} registros? </p>
            </ng-template>
          </div>
          <p i18n *ngSwitchCase="'reprocess'">Os registros selecionados serão validados novamente. Tem certeza que deseja reprocessar {{violationCount}} registros?</p>
        </div>
        <div class="loading-page" *ngIf="isLoading">
          <div class="loading-spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
        <form [formGroup]="form" class="reason-form" *ngIf="!isLoading">
          <div *ngIf="process==='invalidate'">
            <p i18n>Motivo</p>
            <select class="custom-select" formControlName="reasonId">
              <option *ngFor="let reason of reasons" [value]="reason.id">
                {{ reason.code }} - {{ reason.name }}
              </option>
            </select>
          </div>
          <div class="mt-4 text-right">
            <div [ngSwitch]='process'>
              <button class="btn btn-secondary" (click)="onCancel()" i18n>Cancelar</button>
              <button (click)="onSubmit()" class="btn btn-danger button-align" [ngClass]="{disabled: !isValid}"
                *ngSwitchCase="'invalidate'">
                Invalidar
              </button>
              <button (click)="onSubmit()" class="btn btn-primary button-align" *ngSwitchCase="'nextStep'">
                Avançar
              </button>
              <button (click)="onSubmit()" class="btn btn-primary button-align" *ngSwitchCase="'reprocess'">
                Reprocessar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
