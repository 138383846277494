<div class="container">
  <div class="page-title page-title--columns">
    <div class="page-title__left"><h2 i18n>Equipamentos</h2></div>
    <div class="page-title__right" *appPermission="'CreateEquipment'">
      <button i18n type="button" class="btn btn-fade-primary" title="Cadastrar Equipamento"
                (click)="openCreateOrUpdateEquipment()">Novo Equipamento</button>
    </div>
  </div>

  <div class="mb-4">
    <form [formGroup]="searchForm" (submit)="handleSearch()">
      <div class="input-group">
        <input type="text" class="form-control form-search" formControlName="search" i18n-placeholder
          placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">

        <div class="input-group-append">
          <div class="filter-dropdown">
            <button type="button" class="btn btn-outline-secondary btn-icon filter-dropdown__handle" i18n>
              <i class="material-icons">filter_alt</i> Filtrar</button>

            <div class="filter-dropdown__content">
              <div class="form-group">
                <app-input type="multiselect" [list]="manufacturers" label="Fabricantes" formControlName="manufacturerId"
                  selectText="name" selectValue="id" placeholder="Todos" i18n-placeholder i18n-label>
                </app-input>
              </div>

              <div class="form-group">
                <app-input type="multiselect" [list]="equipmentsModels" label="Modelo Equipamento"
                  formControlName="equipmentModelId" selectText="name" selectValue="id" placeholder="Todos"
                  i18n-placeholder i18n-label [inputDisabled]="disabledEquipmentModelId()">
                </app-input>
              </div>

              <div class="form-group">
                <label i18n>Situação</label>
                <select type="text" tabindex="4" class="form-control" formControlName="isEnabled" placeholder="Todos">
                  <option i18n value="" selected>Todos</option>
                  <option i18n value="false" selected>Desabilitado</option>
                  <option i18n value="true" selected>Habilitado</option>
                </select>
              </div>

              <div class="filter-dropdown__footer">
                <button i18n type="submit" class="btn btn-info btn-block" data-dismiss="modal">Aplicar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="mt-5 mb-3">
    <div class="table-responsive table-hover table-block">
      <table class="table bg-white">
        <thead class="bg-header-table">
          <tr>
            <th i18n *appPermission="'UpdateEquipment'">Habilitado</th>
            <th i18n class="text-center">Contrato</th>
            <th i18n class="text-center">Série</th>
            <th i18n class="text-center">Código</th>
            <th i18n class="col-3 text-center">Ponto</th>
            <th i18n class="text-center">Faixas</th>
            <th i18n class="text-center">Modelo</th>
            <th i18n *appPermission="['UpdateEquipment','DeleteEquipment']" class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let equipment of equipments">
            <td *appPermission="'UpdateEquipment'">
              <div class="custom-control custom-switch mt-2">
                <div class="form-group">
                  <label class="custom-control-label" [ngClass]="{checked:equipment.enabled}">
                    <input type="checkbox" class="form-control custom-control-input"
                    *appPermission="'UpdateEquipment'; contracts equipment.contractId"
                    [(ngModel)]="equipment.enabled"
                    (change)="updateEquipmentEdit(equipment)">
                  </label>
                </div>
              </div>
            </td>
            <td [appTooltip]="contracts[equipment.contractId]?.name" (click)="openEquipmentView(equipment)">
              {{ contracts[equipment.contractId] && contracts[equipment.contractId].code }}
            </td>
            <td (click)="openEquipmentView(equipment)">{{equipment.serialNumber}}</td>
            <td (click)="openEquipmentView(equipment)">{{equipment.clientCode || '-'}}</td>
            <td (click)="openEquipmentView(equipment)">
              <div *ngFor="let spotId of equipment.spotIds" class="badge badge-secondary mr-1">
                {{ spotById[spotId] && spotById[spotId].code + ' - ' + spotById[spotId].description }}
              </div>
            </td>
            <td (click)="openEquipmentView(equipment)" class="w-25">
              <div class="row p-0 m-0">
                <div *ngFor="let value of equipment.laneIds" class="badge badge-fade-info mb-1">
                  <p class="d-inline">{{lanes[value] && lanes[value].code}}&nbsp; - &nbsp;</p>
                  <p class="d-inline" i18n>Fx.</p>
                  <p class="d-inline">&nbsp; {{lanes[value] && lanes[value].number}}</p>
                  <p class="d-inline" i18n>(Ponto</p>
                  <p class="d-inline">&nbsp;
                    {{
                      lanes[value] && lanes[value].spotId &&
                      spotById[lanes[value].spotId] &&
                      spotById[lanes[value].spotId].code
                    }})
                  </p>
                </div>
              </div>
            </td>
            <td (click)="openEquipmentView(equipment)">
              {{models[equipment.equipmentModelId] && models[equipment.equipmentModelId].name}} -
              {{getManufacturerName(equipment)}}
              <label style="font-size: 11px; display: block;">
                {{models[equipment.equipmentModelId] && models[equipment.equipmentModelId].type}}
              </label>
            </td>
            <td *appPermission="['UpdateEquipment','DeleteEquipment','CreateCalibration']; contracts equipment.contractId">
              <button type="button" class="btn text-secondary btn-icon-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">more_horiz</i>
              </button>
              <div class="dropdown-menu" aria-labelledby="btnGroupDrop4">
                  <a class="dropdown-item" (click)="openCreateOrUpdateEquipment(equipment)" i18n
                  *appPermission="['UpdateEquipment']; contracts equipment.contractId">
                    Editar Equipamento
                  </a>
                  <a class="dropdown-item" (click)="openDeleteModal(equipment)" i18n
                  *appPermission="['DeleteEquipment']; contracts equipment.contractId">
                    Remover Equipamento
                  </a>
                  <a class="dropdown-item" (click)="openCreateCalibration(equipment)" i18n
                  *appPermission="['CreateCalibration']; contracts equipment.contractId">
                    Cadastrar Aferição
                  </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <app-pagination (items)="setEquipments($event)" [params]="params" [deleteEvent]="deleteEvent" [service]="equipmentService">
  </app-pagination>
</div>
