import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClosedModalError } from 'src/app/core/errors';
import { AtivityViewModalComponent } from '../ativity-view-modal/ativity-view-modal.component';
import { Actions, Activity, ComponentModal, Models, User } from 'src/app/core/models';
import {
  ActivityService,
  ContractService,
  ModalService,
   UserService,
   StorageKey,
   StorageService,
   ContractGlobalService
  } from 'src/app/core/services';
import { SelectPeriodComponent } from 'src/app/modals/select-period/select-period.component';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.sass']
})
export class ActivityListComponent implements OnInit {
  public params: any = {};
  public activities: Activity[] = [];
  public usersById: { [params: string]: User } = {};
  public loading: boolean;
  public actionsI18n = [];
  public actions = Object.values(Actions);
  public models = Object.values(Models);
  public modelsI18n = [];
  public searchForm: FormGroup;
  public modelChanged: Subject<string> = new Subject<string>();
  public modalPeriodSelected: any = {};
  public start;
  public end;
  public filterForm: FormGroup;
  public collapse: boolean;
  public users = [];
  public filterParamsDefault: any = {};
  public contracts;
  public modalState;
  public isValid = false;
  public userIds: any[];

  constructor(
    public activityService: ActivityService,
    public userService: UserService,
    public contractService: ContractService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    public contractGlobalService: ContractGlobalService,
    private storageService: StorageService
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  async ngOnInit() {
    const now = new Date().toISOString();
    this.start = moment(now).subtract(7, 'days').format();
    this.end = moment(now).utc().format();
    this.params['date[gte]'] = moment(this.start).utc().format();
    this.params['date[lte]'] = moment(this.end).utc().format();
    this.params.contractId = this.contractGlobalService.contracts || this.storageService.get(StorageKey.currentContracts);
    this.filterForm = this.formBuilder.group({
      actionIds: [''],
      modelIds: [''],
      userIds: [''],
      contractId: ['', Validators.required]
    });
    await this.userService.list({
      limit: '100',
      'superUser[bool]': 'false'
    }).then(res => {
      this.userIds = res.result.map(u => u.id);
      this.users = res.result.filter(user => user.name != null);
      this.users.map(user => {
        this.usersById[user.id] = user;
      });
    });
    this.params['userId[in]'] = `[${this.userIds}]`;
    this.params.limit = '20';
    this.filterParamsDefault = {
      actionIds: this.actionsI18n,
      modelIds: this.modelsI18n,
      userIds: this.users
    };
    this.contractService.getAll().then(res => {
      this.contracts = res;
    });
    this.createSearchForm();
  }

  setActivities(list) {
    const user = this.storageService.get(StorageKey.currentUser);
    list.forEach((activity: Activity) => {
      const activityCurrentUser = this.usersById[activity.userId];
      user.groupIds.map(group => {
        if (activityCurrentUser?.groupIds.includes(group)) {
          this.activities.push(activity);
        }
      });
    });
  }

  getActionOrModel(actionId, index) {
    const regex = /([A-Z][a-z]*)([A-Z][a-zA-Z]*)/;
    if (this.actionsI18n.length === 0) {
      return;
    }
    const match = actionId.match(regex);
    let result = index === 1 ? actionId : '';
    if (match != null) {
      result = match[index];
    }
    const action = index === 1 ? this.actionsI18n.find(q => q.id === result) : this.modelsI18n.find(q => q.id === result);
    const value = (action && action.value) || result;
    const complement = index === 2 && (action && action.value) ? ' de ' : ' ';
    return complement + value;
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: [''],
      userId: [''],
      actionId: [''],
      date: ['']
    });
  }

  handleSearch() {
    if (!this.searchForm.valid) {
      return null;
    }
    this.params = {};

    const search = this.searchForm.get('search').value;
    if (search != null && search !== '') {
      this.params['userId[contains,or]'] = `${search}`;
    }
    this.loading = true;
    this.activities = [];
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  async selectModalPeriod() {
    await this.modalService.show(new ComponentModal(SelectPeriodComponent, {
      startDate: this.start,
      endDate: this.end
    }))
      .catch(() => { })
      .then(data => {
        if (data == null) {
          return;
        }
        const modal = data as any;
        this.modalPeriodSelected = modal && modal.component
          && modal.component.instance
          && modal.component.instance.periodSelected;

        this.emitNewParams();
      });
  }

  emitNewParams() {
    const endHour = { hour: 23, minutes: 59, seconds: 59 };
    this.params = {};
    this.start = moment(this.modalPeriodSelected.startDate).utc().format();
    this.end = moment(this.modalPeriodSelected.endDate).utc().set(endHour).format();
    this.params.contractId = this.contractGlobalService.contracts || this.storageService.get(StorageKey.currentContracts);
    this.params['date[gte]'] = this.start;
    this.params['date[lte]'] = this.end;
    this.params['userId[in]'] = `[${this.userIds}]`;
    this.params.limit = '20';
    this.loading = true;
    this.activities = [];
  }

  toggleCollapse() {
    this.collapse = !this.collapse;
  }

  redefineFilter() {
    this.filterForm.get('actionIds').setValue(this.filterParamsDefault.actionIds);
    this.filterForm.get('modelIds').setValue(this.filterParamsDefault.modelIds);
    this.filterForm.get('userIds').setValue(this.filterParamsDefault.userIds);
  }

  setFilterValues() {
    this.filterForm.get('actionIds').setValue(this.actionsI18n);
    this.filterForm.get('modelIds').setValue(this.modelsI18n);
    this.filterForm.get('usersIds').setValue(this.users);
  }

  submitFilter() {
    let actions = this.filterForm.get('actionIds').value;
    let models = this.filterForm.get('modelIds').value;
    const userIds = this.filterForm.get('userIds').value;
    const contractId = this.filterForm.get('contractId').value;
    this.params = {};
    const actionIds = [];
    if (actions.length > 0 || models.length > 0) {
      if (actions.length === 0) {
        actions = this.actionsI18n.map(action => action.id);
      }
      if (models.length === 0) {
        models = this.modelsI18n.map(model => model.id);
      }
      for (const action of actions) {
        if (action === 'Login' || action === 'Unlock') {
          actionIds.push(`${action}`);
          continue;
        }
        for (const model of models) {
          actionIds.push(`${action}${model}`);
        }
      }
      this.params.actionIds = `[${actionIds}]`;
    }
    if (userIds.length > 0) {
      this.params.userId = userIds[0];
    }
    this.params['date[gte]'] = this.start;
    this.params['date[lte]'] = this.end;
    // this.params['contractId'] = contractId[0];
    this.params.limit = '20';
    this.loading = true;
    this.activities = [];
  }

  openViewModal(activity: Activity) {
    this.modalService.show(new ComponentModal(AtivityViewModalComponent, activity))
      .then(data => {
        const modal = data as any;
      })
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
          this.modalState = (modalError.modal as ComponentModal).component.instance.lastState;
        } else {
          this.modalState = null;
        }
      });
  }
}
