<div class="modal-dialog modal-dialog-centered modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h5 class="modal-title container" i18n>Editar Usuário</h5>
      </div>
      <button type="button" (click)="onCancel()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="userEditForm" (submit)="onSubmit()" enctype="multipart/form-data">
        <div class="row">
          <div class="col-md-12" *ngIf="user.verified">
            <app-input i18n-label type="text" formControlName="name" label="Nome" inputRequired=true [capitalize]="true"></app-input>
          </div>
          <div class="col-md-12">
            <app-input i18n-label type="text" formControlName="email" label="E-mail" inputRequired=true inputDisabled="true">
            </app-input>
          </div>
          <div class="col-md-12">
            <div class="form-group has-error-select">
              <app-input type="select" [list]="companies" label="Empresa" formControlName="companyId"
                selectText="name" selectValue="id" placeholder="Selecione uma empresa"
                [inputDisabled]="currentUser && !currentUser.superUser"
                i18n-placeholder i18n-label (ngModelChange)="loadUserGroups($event)" inputRequired="true">
              </app-input>
            </div>
          </div>
          <div class="col-md-12" *ngIf="currentUser && currentUser.superUser; else userGroupTags">
            <div class="form-group">
              <app-input type="multiselect" [list]="userGroups" label="Grupos de Usuários" formControlName="groupIds"
                selectText="prettyNames" selectValue="id" selectShowLimit="2" placeholder="Selecione grupo(s)"
                i18n-placeholder i18n-label>
              </app-input>
            </div>
          </div>
          <ng-template #userGroupTags>
            <div class="col-12">
              <label i18n>Grupo de Usuários</label>
              <div *ngFor="let userGroupId of user.groupIds">
                <div class="badge badge-fade-info" *ngIf="userGroupPrettyName[userGroupId]">
                  {{ userGroupPrettyName[userGroupId] }}
                </div>
              </div>
            </div>
          </ng-template>
          <div class="col-md-12">
            <app-input i18n-label type="text" formControlName="role" label="Cargo" [capitalize]="true" [capitalize]="true">
            </app-input>
          </div>
          <div class="col-md-12">
            <app-input
              i18n
              i18n-label
              type="text"
              formControlName="cpf"
              format="ddd.ddd.ddd-dd"
              label="CPF">
            </app-input>
          </div>
          <div class="col-md-12">
            <app-input i18n type="text" formControlName="registryNumber" label="N° da Matrícula" i18n-label></app-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <label class="form-control-label" i18n>Observações:</label>
            <textarea formControlName="extraData" rows="2" class="form-control"></textarea>
          </div>
        </div>
        <div class="row" *ngIf="currentUser && currentUser.superUser">
          <div class="col-md-12">
            <mat-checkbox formControlName="superUser" color="primary" i18n>Super Usuário</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-control-label" i18n>Arquivos:</label>
            <app-file-upload i18n-text="@@TextSingleLabel" formControlName="files" *ngIf="uploadFiles.length < 10"
              [files]="documentFiles"
              (outputFiles)="getOutputFiles($event)"
              accept="image/jpg, image/jpeg, image/png, image/gif, video/mp4, video/mpeg, application/doc, application/docx, application/pdf"
              text="Você pode selecionar no máximo até 10 arquivos." [limit]="10 - uploadFiles.length"></app-file-upload>
              <div class="file-drop">
                <ol class="file-drop__list" *ngFor="let file of uploadFiles;let i=index">
                  <li id ="file_{{i}}">
                    <figure>
                      <img (click)=openFile(file.url) [src]="file.url | sanitizer" width="50px" height="50px">

                    </figure>
                    <div class="file-drop__file-info">
                      <p i18n></p>
                      <p i18n class="size"></p>
                    </div>
                    <button class="btn btn-fade-secondary btn-icon" (click)="deleteFile(file, i)">
                      <i class="material-icons">remove_circle</i> Remover</button>
                  </li>
                </ol>
              </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="onCancel()" class="btn btn-outline-secondary">
        <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
      </button>
      <button (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
