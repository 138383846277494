/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-use-before-define */
import {Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from 'src/app/core/services';
import { ComponentModal } from 'src/app/core/models';

import { SelectValuesComponent } from 'src/app/modals/select-values/select-values.component';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MultiselectComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => MultiselectComponent), multi: true }
  ],
  styleUrls: ['./multiselect.component.sass']
})
export class MultiselectComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() inputRequired: boolean;
  @Input() formControlName: any;
  @Input() selectValue: any;
  @Input() selectText: any;
  @Input() buttonLabel: string;
  @Input() type: string;
  @Input() itemsOnList: number;
  @Input() charactersOnItem: number;

  get list() {
    return this._list;
  }

  @Input() set list(value) {
    if (value != null) {
      this._list = value;
      this.setMultiselectValue();
    }
  }
  get inputDisabled() {
    return this._inputDisabled;
  }

  @Input() set inputDisabled(value) {
    this._inputDisabled = value;
  }

  public multiselectValue = [];
  public modalItemsSelected: [];
  public innerValue: string;

  private _list = [];
  private _inputDisabled = false;
  private readonly errorMessages = {
    required: () => 'Este campo é requerido.'
  };
  private control: FormControl;
  private propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  };

  constructor(
    private modalService: ModalService
  ) {}

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  writeValue(v: any): void {
    this.value = v;
    this.setMultiselectValue();
  }

  registerOnTouched(fn: any): void {
  }

  setMultiselectValue() {
    if (Array.isArray(this.value) && Array.isArray(this.list)) {
      const values = this.value.map(item => this.list.find(l => this.getPropValue(l, this.selectValue) === item));
      this.multiselectValue = values.filter(v => v != null);
      const selectedIds = this.multiselectValue.map(item => item.id);
      const selectedList = [];
      const unselectedList = [];
      this.list.forEach(element => {
        if (selectedIds.includes(element.id)) {
          selectedList.push(element);
        } else {
          unselectedList.push(element);
        }
      });
      this._list = selectedList.concat(unselectedList);
    }
  }

  getMultiselectValue() {
    return this.multiselectValue.map(i => i && this.getPropValue(i, this.selectValue));
  }

  updateListValues() {
    const values = this.modalItemsSelected;
    this.propagateChange(values);
    this.value = values;
    this.setMultiselectValue();
  }

  removeItem(id) {
    const values = this.value.filter(i => i !== id);
    this.propagateChange(values);
    this.value = values;
    this.setMultiselectValue();
  }

  toggleValue(id) {
    let values = [];

    if (this.value.includes(id)) {
      values = this.value.filter(i => i !== id);
      this.value = values;
    } else {
      this.value.push(id);
      values = this.value;
    }

    this.propagateChange(values);
  }

  toggleActive(id) {
    return this.value.includes(id);
  }

  async selectModalValues() {
    await this.modalService.show(new ComponentModal(SelectValuesComponent, {
      list: this.list || [],
      selecteds: this.value,
      modalTitle: this.buttonLabel,
      selectText: this.selectText,
      selectValue: this.selectValue,
      type: this.type
    }))
      .catch(() => {})
      .then(data => {
        if (data == null) {
          return;
        }
        const modal = data as any;
        this.modalItemsSelected = modal && modal.component
          && modal.component.instance
          && modal.component.instance.itemsSelected;

        this.updateListValues();
      });
  }

  validate(c: FormControl) {
    this.control = c;
  }

  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  getMessage(type: string, params: any) {
    if (this.errorMessages[type] != null) {
      return this.errorMessages[type](params);
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getPropValue(value: object, prop: string) {
    if (prop == null) {
      return value;
    }
    const props = prop.split('.');
    for (const i of props) {
      value = value[i];
      break;
    }
    return value;
  }
}
