import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {MonitoringSettings, PreViolation} from '../models';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class MonitoringSettingsService extends BaseApiService<MonitoringSettings> {
  get cacheKey(): StorageKey {
    return StorageKey.monitoringSettingsServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    return `${environment.apiUrl}/contracts/${encodeURIComponent(id)}/monitoringSettings`;
  }

  createNew(request: MonitoringSettings) {
    return this.http.post<MonitoringSettings>(`${environment.apiUrl}/monitoringSettings`, request).pipe().toPromise();
  }

  updateMonitoring(request: MonitoringSettings) {
    return this.http.put(`${environment.apiUrl}/monitoringSettings/${request.id}`, request).pipe().toPromise();
  }

  protected unparse(obj) {
    return MonitoringSettings.unparse(obj);
  }

  protected parse(obj) {
    return MonitoringSettings.parse(obj);
  }
}
