<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header align-items-start">
      <div>
        <div *ngIf="displayRegulationsQRCode">
          <h5 class="modal-title" i18n>Configurações QRCode</h5>
        </div>
        <div *ngIf="displayRegulations">
          <h5 class="modal-title" i18n>Enquadramentos habilitados</h5>
        </div>
        <p class="text-secondary">{{ contractCodeDescription }}</p>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body my-3" *ngIf="!loading">
      <form [formGroup]="form" (submit)="onSubmit()">
        <div class="form-group pl-4" *ngIf="displayRegulationsQRCode">
          <input class="form-check-input" type="checkbox" formControlName="enabledQRCode" id="enabledQRCode">
          <label for="enabledQRCode" [ngClass]="{'disabled': !allowEdit}" i18n>Habilitar geração do QRCode:</label>
          <i i18n-appTooltip class="material-icons icon-sm text-secondary"
            appTooltip="Caso essa opção esteja habilitada, o sistema irá gerar um QRCode para acesso ao vídeo das infrações dos enquadramentos selecionados abaixo.">help</i>
        </div>
        <div class="form-group">
          <app-input i18n-label i18n-placeholder placeholder="Selecione os enquadramentos" label="Enquadramentos"
            type="multiselect" selectText="shortDescription" selectValue="id" [list]="regulations"
            formControlName="regulationIds" [inputDisabled]="!allowEdit">
          </app-input>
        </div>
      </form>
    </div>
    <div class="modal-footer" *ngIf="!loading">
      <div *ngIf="allowEdit">
        <button class="btn btn-outline-secondary mr-3" (click)="onCancel()">
          <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
        </button>
      </div>
      <button class="btn btn-primary" (click)="onSubmit()" [ngClass]="{disabled: !isValid}" [disabled]="!isValid"
        *appPermission="['UpdateContract']; contracts: contract && [contract.id]">
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
    <div class="card-body" *ngIf="loading">
      <div class="loading-page">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>
  </div>
</div>
