import { Component, HostListener } from '@angular/core';
import { ComponentModal } from 'src/app/core/models';
import { ModalService } from 'src/app/core/services';
import { ShortcutKeysModalComponent } from './pages/shortcut-keys/shortcut-keys-modal/shortcut-keys-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {
  public modalState;

  constructor(private modalService: ModalService) { }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event) {
    switch (event.key) {
      case 'F1':
        event.preventDefault();
        this.modalShortcutKeys();
        break;
      case 'Escape':
        event.preventDefault();
        this.modalService.close();
        break;
    }
  }

  modalShortcutKeys() {
    this.modalService.show(new ComponentModal(ShortcutKeysModalComponent, this.modalState)).then(data => {
    }).catch(() => null);
  }

}
