import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService } from 'src/app/core/services';

@Component({
  selector: 'app-select-values',
  templateUrl: './select-values.component.html',
  styleUrls: ['./select-values.component.sass']
})
export class SelectValuesComponent extends BaseModal implements OnInit {
  isValid: boolean;
  lastState: any;

  list: any[] = [];
  selecteds: any[];


  public modalTitle: string;
  public selectText: string;
  public selectValue: '';
  public promise: Promise<any>;
  public itemsSelected: any[];
  public allChecked: boolean;
  public loaded: boolean;
  public form: FormGroup = this.formBuilder.group({});
  public searchValue = '';
  public allComplete = false;
  public type: string;

  private searchChanged: Subject<string> = new Subject<string>();

  constructor(
    modalService: ModalService,
    private formBuilder: FormBuilder
  ) {
    super(modalService);
    this.searchChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  ngOnInit() {
    this.list = this.initialState && this.initialState.list || [];
    this.selecteds = this.initialState && this.initialState.selecteds || [];
    this.modalTitle = this.initialState && this.initialState.modalTitle || 'Selecione';
    this.selectText = this.initialState && this.initialState.selectText || 'name';
    this.selectValue = this.initialState && this.initialState.selectValue || 'id';
    this.type = this.initialState && this.initialState.type || 'select';

    if (this.type !== 'radio') {
      this.buildSelectItens();
    } else {
      this.buildRadioItens();
    }
  }

  handleSearch() {
    const search = this.searchValue;
    this.list.filter(item => {
      if (item[this.selectText].includes(search)) {
        item.filtered = true;
      } else {
        item.filtered = false;
      }
    });
    this.checkAll();
  }

  searchKey(text: string) {
    this.searchChanged.next(text);
  }

  buildSelectItens() {
    if (this.list != null) {
      this.form = new FormGroup({});

      for (const item of this.list) {
        item.filtered = true;
        this.form.addControl(
          item.id,
          new FormControl()
        );
      }

      this.form.valueChanges.subscribe(res => {
        this.isValid = this.form.valid;
      });

      if (this.selecteds != null) {
        this.selecteds.forEach(i => {
          this.form.controls[i]?.patchValue(true);
        });

        this.checkAll();
      }

      this.loaded = true;
    }
  }

  buildRadioItens() {
    if (this.list != null) {
      this.form = this.formBuilder.group({
        selectRadioValues: [null]
      });

      this.form.get('selectRadioValues').setValue(this.selecteds[0]);

      this.form.valueChanges.subscribe(res => {
        this.isValid = this.form.valid;
      });

      this.loaded = true;
    }
  }

  setAll(checked: boolean) {
    this.allChecked = checked;
    this.list.forEach((item) => {
      if (item.filtered === true) {
        this.form.controls[item.id].setValue(checked);
      }
    });
  }

  checkAll() {
    const values = this.getTrueValues();

    if (values.length < this.list.length) {
      this.allComplete = false;
      this.allChecked = false;
    } else {
      this.allComplete = true;
      this.allChecked = true;
    }
  }

  getTrueValues(): Array<any> {
    const values = [];

    if (this.type !== 'radio') {
      for (const [k, v] of Object.entries(this.form.getRawValue())) {
        if (v === true) { values.push(k); }
      }
    } else {
      values.push(this.form.value.selectRadioValues);
    }

    return values;
  }

  async onAllSubmited(): Promise<any> {
    this.itemsSelected = this.getTrueValues();
  }
}
