<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 i18n class="modal-title">Detalhes do Usuário</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-auto">
          <app-avatar [user]="user" class="avatar-sm"></app-avatar>
        </div>

        <div class="col">
          <h2 *ngIf="user.name" class="mb-1">{{user.name}}</h2>
          <h5 *ngIf="user.role" class="mb-1 text-secondary">{{user.role}}</h5>
          <p *ngIf="user.email" class="text-secondary">{{user.email}}</p>

          <hr />

          <div *ngIf="user.companyId" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">business</i></div>
            <div class="col-10">
              <p class="text-secondary mb-0" i18n><small>Empresa:</small></p>
              <p>{{companyList && companyList[user.companyId] && companyList[user.companyId].name}}</p>
            </div>
          </div>

          <div *ngIf="user.verified; else elseBlock" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">done</i></div>
            <div class="col-3">
              <p class="text-secondary mb-0" i18n><small>Situação:</small></p>
              <p>Confirmado</p>
            </div>
            <div class="col-7">
              <p class="text-secondary mb-0" i18n><small>Criado Por:</small></p>
              <div *ngIf="userCreator && userCreator.name; else elseNothing">
                <p>{{ userCreator && userCreator.name }} - {{ user.createdAt | moment: 'L' }} - {{ user.createdAt |
                  moment: 'LTS' }}</p>
              </div>
              <ng-template #elseNothing>
                <p>-</p>
              </ng-template>
            </div>
          </div>

          <div *ngIf="user.registryNumber" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">folder_shared</i></div>
            <div class="col-8">
              <p class="text-secondary mb-0" i18n><small>N° da matrícula:</small></p>
              <p>{{user.registryNumber}}</p>
            </div>
          </div>

          <div *ngIf="user.companyId" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">assignment_ind</i></div>
            <div class="col-10">
              <p class="text-secondary mb-0" i18n><small>CPF:</small></p>
              <p>{{user.cpf}}</p>
            </div>
          </div>

          <ng-template #elseBlock>
            <div class="row d-flex align-items-center">
              <div class="col-auto"><i class="material-icons icon-lg text-warning">watch_later</i></div>
              <div class="col-3">
                <p class="text-secondary mb-0" i18n><small>Situação:</small></p>
                <p>Pendente</p>
              </div>
              <div class="col-7">
                <p class="text-secondary mb-0" i18n><small>Criado Por:</small></p>
                <div *ngIf="userCreator && userCreator.name; else elseNothing">
                  <p>{{ userCreator && userCreator.name }} - {{ user.createdAt | moment: 'L' }} - {{ user.createdAt |
                    moment: 'LTS' }}</p>
                </div>
                <ng-template #elseNothing>
                  <p>-</p>
                </ng-template>
              </div>
              <div class="col-auto ml-auto">
                <button class="btn btn-fade-info btn-sm" [attr.hidden]="hasPermissionContract()"
                  (click)="sendReinvite()">Reenviar convite</button>
              </div>
            </div>
          </ng-template>

          <div *ngIf="user.groupIds" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">groups</i></div>
            <div class="col-10">
              <p class="text-secondary mb-0" i18n><small>Grupos:</small></p>
              <p><span class="badge badge-fade-info" *ngFor="let group of user.groupIds">
                  {{userGroupList[group] && userGroupList[group].prettyName }}
                </span></p>
            </div>
          </div>

          <div *ngIf="user.extraData" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">note_add</i></div>
            <div class="col-8">
              <p class="text-secondary mb-0" i18n><small>Observação:</small></p>
              <p>{{user.extraData?.observation}}</p>
            </div>
          </div>
          <div class="row d-flex">
            <label class="col-12 text-secondary">Arquivos</label>
            <div *ngFor="let data of user.files" class="col-2 pointer" data-toggle="tooltip"
              title="{{ getUserFileName(data.url) }}">
              <app-thumbnail [source]="data.url"></app-thumbnail>
            </div>
            <div class="col-12" *ngIf="user?.files?.length === 0">
              -
            </div>
          </div>
        </div>
      </div>
      <app-enum-i18n [input]="permissions" (output)="permissionsI18n=$event"></app-enum-i18n>
      <app-enum-i18n [input]="fieldsChanged" (output)="fieldsChangedI18n=$event"></app-enum-i18n>
      <div class="row">
        <div class="col">
          <div *ngIf="!isLoading" class="mt-5 mb-3">
            <div [id]="'userIdHistoryUnfold'">
              <div class="table-responsive table-hover table-block mb-1">
                <div class="d-flex flex-row justify-content-between" style="cursor: pointer;">
                  <div class="col" (click)="unfoldMoreOrLess()">
                    <span class="mb-0 ml-2 mt-3">Histórico de Atividades</span>
                  </div>
                  <div class="p-3">
                    <button type="button" class="btn text-secondary btn-icon-block" (click)="unfoldMoreOrLess()">
                      <i class="material-icons" *ngIf="typeUnfold === 'more'; else elseBlock">unfold_less</i>
                      <ng-template #elseBlock>
                        <i class="material-icons">unfold_more</i>
                      </ng-template>
                    </button>
                  </div>
                </div>
                <div *ngIf="typeUnfold === 'more'" class="limited-height">
                  <div [id]="'userHistoryId-' + userHistory.id" *ngFor="let userHistory of userHistories">
                    <div class="table-responsive table-hover table-block mb-1"
                      *ngIf="(userHistory?.field[0] | keyvalue)?.length">
                      <div class="d-flex flex-row justify-content-between" style="cursor: pointer;">
                        <div class="col" (click)="unfoldUserHistory(userHistory.id)">
                          <table class="table table-bordeless table-striped table-hover data-table">
                            <thead>
                              <tr class="mt-3">
                                <th class="text-center" i18n *ngIf="columnsToShow.includes('data')">Data/Hora</th>
                                <th class="text-center" i18n *ngIf="columnsToShow.includes('action')">Ação</th>
                                <th class="text-center" i18n *ngIf="columnsToShow.includes('name')">Nome</th>
                                <th class="text-center" i18n *ngIf="columnsToShow.includes('ip')">IP</th>
                              </tr>
                            </thead>
                            <tbody class="align-size-table">
                              <tr>
                                <td class="text-center" i18n *ngIf="columnsToShow.includes('data')">
                                  {{ userHistory.date | moment: 'L LTS' }}
                                </td>
                                <td class="text-center" i18n *ngIf="columnsToShow.includes('action')">
                                  {{ loadPermissionTranslated(userHistory.action) }}
                                </td>
                                <td class="text-center" i18n *ngIf="columnsToShow.includes('name')">
                                  {{ userHistory.content[0]?.contractId }} {{ userHistory.content[0].name || userHistory.content[0].email }}
                                </td>

                                <td class="text-center" i18n *ngIf="columnsToShow.includes('ip')">
                                  {{ userHistory.ip }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="p-3">
                          <button type="button" class="btn text-secondary btn-icon-block"
                            (click)="unfoldUserHistory(userHistory.id)">
                            <i class="material-icons"
                              *ngIf="userHistoryId===userHistory.id; else elseBlockUserHistory">unfold_less</i>
                            <ng-template #elseBlockUserHistory>
                              <i class="material-icons">unfold_more</i>
                            </ng-template>
                          </button>
                        </div>
                      </div>
                      <div *ngIf="userHistoryId === userHistory.id" class="limited-height">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="wrapper">
                              <div class="content-data">
                                <div *ngIf="fieldsByUser[userHistory.id]">
                                  <div class="data" *ngFor="let field of fieldsByUser[userHistory.id]">
                                    <div *ngIf="field !==' prettyNames'">
                                      <div class="col bold">
                                        <p i18n>Campo: {{fieldTranslate(field)}}</p>
                                      </div>
                                      <div *ngIf="field !== 'extraData'; else extraData">
                                        <div *ngIf="field !== 'enabled'; else enabled">
                                          <div *ngIf="field !== 'userIds'; else userIds">
                                            <div *ngIf="field !== 'files'; else files">
                                              <div class="col">
                                                <p class="ident-text-wrapper">Antes: {{userHistory?.field[0][field]?.old
                                                  }}
                                                </p>
                                              </div>
                                              <div class="col">
                                                <p class="ident-text-wrapper" i18n>
                                                  Depois: {{userHistory?.field[0][field]?.new}}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <ng-template #files>
                                            <p class="ident-text-wrapper">
                                              Antes:
                                            </p>
                                            <div class="col" *ngIf="userHistory?.field[0][field]?.old">
                                              <span class="ident-text-wrapper" *ngFor="let fileOld of userHistory?.field[0][field]?.old">
                                                {{ fileOld.file }}
                                              </span>
                                            </div>
                                            <br>
                                            <p class="ident-text-wrapper">
                                              Depois:
                                            </p>
                                            <div class="col" *ngIf="userHistory?.field[0][field]?.new">
                                              <span class="ident-text-wrapper" *ngFor="let fileNew of userHistory?.field[0][field]?.new">
                                                {{ fileNew.file }}
                                              </span>
                                            </div>
                                          </ng-template>

                                          <ng-template #userIds>
                                            <div class="col">
                                              <p class="ident-text-wrapper">Antes:
                                                {{getUserName(userHistory?.field[0][field]?.old) }}
                                              </p>
                                            </div>
                                            <div class="col">
                                              <p class="ident-text-wrapper" i18n>
                                                Depois: {{getUserName(userHistory?.field[0][field]?.new)}}
                                              </p>
                                            </div>
                                          </ng-template>
                                        </div>
                                        <ng-template #enabled>
                                          <div class="col">
                                            <p class="ident-text-wrapper">
                                              Antes: {{userHistory?.field[0][field]?.old === true? 'Habilitado':
                                              'Desabilitado'}}
                                            </p>
                                          </div>
                                          <div class="col">
                                            <p class="ident-text-wrapper" i18n>
                                              Depois: {{userHistory?.field[0][field]?.new === true? 'Habilitado':
                                              'Desabilitado'}}
                                            </p>
                                          </div>
                                        </ng-template>
                                      </div>
                                      <ng-template #extraData>
                                        <div class="col">
                                          <p class="ident-text-wrapper">Antes:
                                            {{userHistory?.field[0][field]?.old?.observation }}</p>
                                        </div>
                                        <div class="col">
                                          <p class="ident-text-wrapper" i18n>Depois:
                                            {{userHistory?.field[0][field]?.new?.observation }}</p>
                                        </div>
                                      </ng-template>

                                      <div *ngIf="field==='permissions'">
                                        <div
                                          *ngIf="userHistory?.field[0][field]?.old.length > userHistory?.field[0][field]?.new.length"
                                          class="col">
                                          Permissões removidas<p class="ident-text-wrapper" i18n>{{
                                            setPermissionsHistories(userHistory) }}</p>
                                        </div>
                                        <div
                                          *ngIf="userHistory?.field[0][field]?.old.length < userHistory?.field[0][field]?.new.length"
                                          class="col">
                                          Permissões adicionadas<p class="ident-text-wrapper" i18n>{{
                                            setPermissionsHistories(userHistory) }}</p>
                                        </div>
                                      </div>
                                      <div class="divide-data"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <app-pagination (items)="setUserHistories($event)" [params]="params" [deleteEvent]="deleteEvent"
                    [service]="userHistoryService">
                  </app-pagination>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isLoading" class="mt-5 mb-3">
            <div [id]="'userIdHistoryUnfoldModifications'">
              <div class="table-responsive table-hover table-block mb-1">
                <div class="d-flex flex-row justify-content-between" style="cursor: pointer;">
                  <div class="col" (click)="unfoldMoreOrLessModifications()">
                    <span class="mb-0 ml-2 mt-3">Histórico de Modificações</span>
                  </div>
                  <div class="p-3">
                    <button type="button" class="btn text-secondary btn-icon-block" (click)="unfoldMoreOrLessModifications()">
                      <i class="material-icons" *ngIf="typeUnfold === 'more'; else elseBlock">unfold_less</i>
                      <ng-template #elseBlock>
                        <i class="material-icons">unfold_more</i>
                      </ng-template>
                    </button>
                  </div>
                </div>
                <div *ngIf="typeUnfoldModifications === 'more'" class="limited-height">
                  <div [id]="'userHistoryId-' + userHistory.id" *ngFor="let userHistory of userHistoriesModifications">
                    <div class="table-responsive table-hover table-block mb-1"
                      *ngIf="(userHistory?.field[0] | keyvalue)?.length">
                      <div class="d-flex flex-row justify-content-between" style="cursor: pointer;">
                        <div class="col" (click)="unfoldUserHistoryModifications(userHistory.id)">
                          <table class="table table-bordeless table-striped table-hover data-table">
                            <thead>
                              <tr class="mt-3">
                                <th class="text-center" i18n *ngIf="columnsToShow.includes('data')">Data/Hora</th>
                                <th class="text-center" i18n *ngIf="columnsToShow.includes('action')">Ação</th>
                                <th class="text-center" i18n *ngIf="columnsToShow.includes('name')">Nome</th>
                                <th class="text-center" i18n *ngIf="columnsToShow.includes('ip')">IP</th>
                              </tr>
                            </thead>
                            <tbody class="align-size-table">
                              <tr>
                                <td class="text-center" i18n *ngIf="columnsToShow.includes('data')">
                                  {{ userHistory.date | moment: 'L LTS' }}
                                </td>
                                <td class="text-center" i18n *ngIf="columnsToShow.includes('action')">
                                  {{ loadPermissionTranslated(userHistory.action) }}
                                </td>
                                <td class="text-center" i18n *ngIf="columnsToShow.includes('name')">
                                 {{ userHistory.content[0]?.contractId }} {{ userHistory.content[0].name || userHistory.content[0].email }}
                                </td>

                                <td class="text-center" i18n *ngIf="columnsToShow.includes('ip')">
                                  {{ userHistory.ip }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="p-3">
                          <button type="button" class="btn text-secondary btn-icon-block"
                            (click)="unfoldUserHistoryModifications(userHistory.id)">
                            <i class="material-icons"
                              *ngIf="userHistoryId===userHistory.id; else elseBlockUserHistory">unfold_less</i>
                            <ng-template #elseBlockUserHistory>
                              <i class="material-icons">unfold_more</i>
                            </ng-template>
                          </button>
                        </div>
                      </div>
                      <div *ngIf="userHistoryId === userHistory.id" class="limited-height">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="wrapper">
                              <div class="content-data">
                                <div *ngIf="fieldsByUser[userHistory.id]">
                                  <div class="data" *ngFor="let field of fieldsByUser[userHistory.id]">
                                    <div *ngIf="field !==' prettyNames'">
                                      <div class="col bold">
                                        <p i18n>Campo: {{fieldTranslate(field)}}</p>
                                      </div>
                                      <div *ngIf="field !== 'extraData'; else extraData">
                                        <div *ngIf="field !== 'enabled'; else enabled">
                                          <div *ngIf="field !== 'userIds'; else userIds">
                                            <div *ngIf="field !== 'files'; else files">
                                              <div class="col">
                                                <p class="ident-text-wrapper">Antes: {{userHistory?.field[0][field]?.old
                                                  }}
                                                </p>
                                              </div>
                                              <div class="col">
                                                <p class="ident-text-wrapper" i18n>
                                                  Depois: {{userHistory?.field[0][field]?.new}}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <ng-template #files>
                                            <p class="ident-text-wrapper">
                                              Antes:
                                            </p>
                                            <div class="col" *ngIf="userHistory?.field[0][field]?.old">
                                              <span class="ident-text-wrapper" *ngFor="let fileOld of userHistory?.field[0][field]?.old">
                                                {{ fileOld.file }}
                                              </span>
                                            </div>
                                            <br>
                                            <p class="ident-text-wrapper">
                                              Depois:
                                            </p>
                                            <div class="col" *ngIf="userHistory?.field[0][field]?.new">
                                              <span class="ident-text-wrapper" *ngFor="let fileNew of userHistory?.field[0][field]?.new">
                                                {{ fileNew.file }}
                                              </span>
                                            </div>
                                          </ng-template>

                                          <ng-template #userIds>
                                            <div class="col">
                                              <p class="ident-text-wrapper">Antes:
                                                {{getUserName(userHistory?.field[0][field]?.old) }}
                                              </p>
                                            </div>
                                            <div class="col">
                                              <p class="ident-text-wrapper" i18n>
                                                Depois: {{getUserName(userHistory?.field[0][field]?.new)}}
                                              </p>
                                            </div>
                                          </ng-template>
                                        </div>
                                        <ng-template #enabled>
                                          <div class="col">
                                            <p class="ident-text-wrapper">
                                              Antes: {{userHistory?.field[0][field]?.old === true? 'Habilitado':
                                              'Desabilitado'}}
                                            </p>
                                          </div>
                                          <div class="col">
                                            <p class="ident-text-wrapper" i18n>
                                              Depois: {{userHistory?.field[0][field]?.new === true? 'Habilitado':
                                              'Desabilitado'}}
                                            </p>
                                          </div>
                                        </ng-template>
                                      </div>
                                      <ng-template #extraData>
                                        <div class="col">
                                          <p class="ident-text-wrapper">Antes:
                                            {{userHistory?.field[0][field]?.old?.observation }}</p>
                                        </div>
                                        <div class="col">
                                          <p class="ident-text-wrapper" i18n>Depois:
                                            {{userHistory?.field[0][field]?.new?.observation }}</p>
                                        </div>
                                      </ng-template>

                                      <div *ngIf="field==='permissions'">
                                        <div
                                          *ngIf="userHistory?.field[0][field]?.old.length > userHistory?.field[0][field]?.new.length"
                                          class="col">
                                          Permissões removidas<p class="ident-text-wrapper" i18n>{{
                                            setPermissionsHistories(userHistory) }}</p>
                                        </div>
                                        <div
                                          *ngIf="userHistory?.field[0][field]?.old.length < userHistory?.field[0][field]?.new.length"
                                          class="col">
                                          Permissões adicionadas<p class="ident-text-wrapper" i18n>{{
                                            setPermissionsHistories(userHistory) }}</p>
                                        </div>
                                      </div>
                                      <div class="divide-data"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <app-pagination (items)="setUserHistoriesModifications($event)" [params]="paramsModifications" [deleteEvent]="deleteEvent"
                    [service]="userHistoryService">
                  </app-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
