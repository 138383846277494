import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Manufacturer, Pagination } from '../models';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService extends BaseApiService<Manufacturer> {
  get cacheKey(): StorageKey {
    return StorageKey.manuFacturerServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/manufacturers`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  list(params: {[params: string]: string}): Promise<Pagination<Manufacturer>> {
    return super.list(this.removeContractId(params));
  }

  protected parse(obj) {
    return Manufacturer.parse(obj);
  }

  protected unparse(obj) {
    return Manufacturer.unparse(obj);
  }

}
