import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthenticationService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private refreshObservable: Observable<any> = null;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const a = document.createElement('a');
        a.href = request.url;
        if (err.status === 401 && a.pathname !== '/accessToken') {
          if (this.refreshObservable == null) {
            this.refreshObservable = this.authenticationService.refreshToken().pipe(
              catchError(error => {
                this.authenticationService.logout();
                this.router.navigate(['/login']);
                return throwError(err);
              })
            );
          }
          return this.refreshObservable.pipe(
            mergeMap(resp => {
              request = request.clone({headers: request.headers.set('Authorization', `Bearer ${resp.accessToken}`)});
              return next.handle(request);
            })
          );
        }
        return throwError(err);
      })
    );
  }
}
