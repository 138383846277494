import { Component, OnInit } from '@angular/core';
import { ContractGlobalService, ContractService, RegulationService, UserGroupService,
  StorageKey, StorageService, ViolationReportService } from 'src/app/core/services';
import { Regulation, ViolationReport } from 'src/app/core/models';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-violations-report',
  templateUrl: './violations.component.html'
})
export class ViolationsReportComponent implements OnInit {
  public contracts: any[] = [];
  public violationReport: ViolationReport[] = [];
  public regulations: Regulation[] = [];

  constructor(
    private contractsGlobalService: ContractGlobalService,
    private contractService: ContractService,
    private regulationService: RegulationService,
    private storageService: StorageService,
    private userGroupService: UserGroupService,
    private violationReportService: ViolationReportService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (this.contractsGlobalService.contracts != null) {
      if (this.contractsGlobalService.contracts.length === 1) {
        const contract = this.contractsGlobalService.contracts.shift();
        this.router.navigate([contract], { relativeTo: this.route }).then(() => {
          window.location.reload();
        });
      }
    }
  }

  async ngOnInit() {
    const currentUser = this.storageService.get(StorageKey.currentUser);

    const paramsContract: any = {};
    paramsContract['modules[contains]'] = 'violations';

    if (currentUser && !currentUser.superUser) {
      const contractsUser = [];
      await this.userGroupService.getAll()
      .then(userGroups => {
        userGroups.map(group => {
          if (currentUser.groupIds.includes(group.id) && group.permissions.includes('ViolationsReport')) {
            contractsUser.push(group.contractId);
          }
        });
      });
      paramsContract['id[in]'] = `[${contractsUser.join()}]`;
    }

    await this.contractService.getAll(paramsContract)
    .then(async contracts => {
      this.contracts = contracts.map(contract => ({
        contractId: contract.id,
        contractCode: contract.code,
        contractName: contract.name,
        timezone: contract.timezone,
        regionId: contract.regionId
      }));
      await this.regulationService.getAll({ regionId: contracts[0].regionId }).then(res => {
        this.regulations = res;
        const dataViolationReport = {
          contracts: this.contracts,
          regulations: this.regulations
        };
        this.violationReportService.emitDataViolationReport(dataViolationReport);
      });
    });
  }
}
