import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  LaneService, ReasonService, SpotService, ViolationService,
  ViewViolationService, ModalService, CategoryService, UserGroupService
} from 'src/app/core/services';
import * as moment from 'moment';
import { Lane, Reason, Regulation, Spot, Violation, ComponentModal, Category, User } from 'src/app/core/models';
import { DetailsViolationComponent } from 'src/app/modals/details-violation/details.component';
import {LoteInfoComponent} from 'src/app/modals/lote-info/lote-info.component';

@Component({
  selector: 'app-violations-view-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.sass']
})
export class ViolationsViewContractComponent implements OnInit {
  public contractId: string;
  public contractName: string;
  public loading: boolean;
  public contractViewViolationsSub: Subscription;
  public filterViewViolationsSub: Subscription;
  public periodViewViolationsSub: Subscription;
  public users: any[] = [];
  public filter: any = {};
  public startDate;
  public endDate;
  public contract: any = {};
  public timezone: string;
  public categories: Category[] = [];
  public violations: Violation[] = [];
  public lanes: Lane[] = [];
  public spots: Spot[] = [];
  public regulations: Regulation[] = [];
  public reasons: Reason[] = [];
  public situations: any[] = [];
  public params: any = {};
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public violationsStepsResource: any[] = [];
  public violationsSteps: any[] = ['validateLane', 'validateCalibration', 'validateSpeed', 'validateFiles',
    'validateExemptPeriod', 'validateViolationLimit', 'verifyValid', 'verifyInvalid', 'triage', 'typing', 'verify',
    'validate', 'lotAttribution', 'internalAudit', 'revision', 'filter', 'done', 'quarantine'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private laneService: LaneService,
    private modalService: ModalService,
    private reasonsService: ReasonService,
    private spotService: SpotService,
    private userGroupService: UserGroupService,
    private viewViolationService: ViewViolationService,
    public violationService: ViolationService
  ) { }

  async ngOnInit() {
    this.contractId = this.activatedRoute.snapshot.params.contractId;

    const now = new Date().toISOString();
    this.startDate = moment(now).subtract(1, 'month').utc().format();
    this.endDate = moment(now).utc().format();

    this.params['date[gte]'] = this.startDate;
    this.params['date[lte]'] = this.endDate;

    this.contractViewViolationsSub = this.viewViolationService.getDataViewViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe(async (data: any) => {
        this.contract = data.contracts.filter(item => item.id === Number(this.contractId)).shift();
        this.contractName = this.contract.name;
        this.regulations = data.regulations;
        this.situations = data.situations;
        this.spots = await this.spotService.getAll({ contractId: this.contractId });
        this.lanes = await this.laneService.getAll({ contractId: this.contractId });
        this.reasons = await this.reasonsService.getAll({ contractId: this.contractId });
        this.reasons =  this.reasons.sort((a, b): any => Number(a.code) - Number(b.code));
        this.categories = await this.categoryService.getAll({ regionId: this.contract.regionId });

        // const groupUsers = await this.userGroupService.getAll({ contractId: this.contractId });
        //   const userList = [];

        //   for (const group of groupUsers) {
        //     group.userIds.map(user => {
        //       if (!userList.includes(user)) {
        //         userList.push(user);
        //       }
        //     });
        //   }

        //   this.users = [];

        //   const userSelectDefault = {
        //     name: 'Selecione',
        //     id: 0
        //   }

        //   this.users.push(userSelectDefault);

        //   for (const user of data.users) {
        //     if (userList.includes(user.id)) {
        //       const userData = {
        //         name: user.name,
        //         id: user.id
        //       };

        //       if (user.name) {
        //         this.users.push(userData);
        //       }
        //     }
        //   }

        const dataContract = {
          regulations: this.regulations,
          situations: this.situations,
          spots: this.spots,
          lanes: this.lanes,
          reasons: this.reasons,
          categories: this.categories,
          users: this.users
        };

        this.viewViolationService.emitDataViewContractViolation(dataContract);
        this.setParams();
      });

    this.filter = {
      contractId: this.contractId, spotsIds: '', laneIds: '',
      regulationsIds: '', situationsIds: '', reasonsIds: '',
      numberSerie: '', numberPlate: '', numberId: ''
    };

    this.setParams();

    this.filterViewViolationsSub = this.viewViolationService.getFilterViewViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((result: any) => {
        this.violations = [];
        this.filter = result;
        this.setParams();
      });

    this.periodViewViolationsSub = this.viewViolationService.getPeriodViewViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((result: any) => {
        this.startDate = result.startDate;
        this.endDate = result.endDate;
        this.setParams();
      });
  }

  setParams() {
      this.params = {};
      this.params.contractId = this.contractId;
      this.params['date[gte]'] = moment(this.startDate).set({ hour: 0, minutes: 0, seconds: 0 }).format();
      this.params['date[lte]'] = moment(this.endDate).set({ hour: 23, minutes: 59, seconds: 59 }).format();

      if (this.filter.number_id) {
        this.params.passId = this.filter.number_id;
      }

      if (this.filter.number_serie) {
        this.params.serie = this.filter.number_serie;
      }

      if (this.filter.number_plate) {
        this.params['vehicle.plate'] = this.filter.number_plate;
      }

      if (this.filter.spotsIds.length > 0 && this.filter.spotsIds.length < this.spots.length) {
        const spotsIds = this.filter.spotsIds.map(Number);
        const lanesWithSpot = this.lanes.filter(lane => spotsIds.includes(lane.spotId)).map(lane => lane.code);
        this.params['laneCode[in]'] = `[${lanesWithSpot.join()}]`;
      }

      if (this.filter.laneIds.length > 0 && this.filter.laneIds.length < this.lanes.length) {
        const laneIds = this.filter.laneIds.map(Number);
        const lanesCode = this.lanes.filter(lane => laneIds.includes(lane.id)).map(lane => lane.code);
        this.params['laneCode[in]'] = `[${lanesCode.join()}]`;
      }

      if (this.filter.regulationsIds.length > 0 && this.filter.regulationsIds.length < this.regulations.length) {
        this.params['regulationId[in]'] = `[${this.filter.regulationsIds.join()}]`;
      }

      if (this.filter.situationsIds.length > 0 && this.filter.situationsIds.length < this.situations.length) {
        if (this.filter.situationsIds.includes('inProcess')) {
          const inProcess = this.filter.situationsIds.indexOf('inProcess');
          if (inProcess !== -1) {
            this.filter.situationsIds[inProcess] = 'quarantine';
          }
        }
        this.params['status[in]'] = `[${this.filter.situationsIds.join()}]`;
      }

      if (this.filter.reasonsIds.length > 0 && this.filter.situationsIds.includes('invalid')
        && this.filter.reasonsIds.length < this.reasons.length) {
        const reasonsCode = this.reasons.filter(reason => this.filter.reasonsIds.includes(reason.id)).map(reason => reason.code);
        this.params['reason.code'] = reasonsCode;
      }
      this.params.order = 'date';

      this.loading = true;
  }

  setViolations(list) {
    this.violations = list;
  }

  getSpot(lane) {
    const laneInfo = this.lanes.filter(item => item.code === lane).shift();
    if (laneInfo) {
      const spotInfo = this.spots.filter(item => item.id === laneInfo.spotId).shift();
      return spotInfo.code;
    }
  }

  getRegulationCode(regulation) {
    return this.regulations.filter(item => item.id === regulation).map(item => item.code).shift();
  }

  getRegulationInfo(regulation) {
    const regulationInfo = this.regulations.filter(item => item.id === regulation).shift();
    return (regulationInfo && regulationInfo.description) || '';
  }

  getSpotInfo(lane) {
    const laneInfo = this.lanes.filter(item => item.code === lane).shift();
    if (laneInfo) {
      const spotInfo = this.spots.filter(item => item.id === laneInfo.spotId).shift();
      return spotInfo.prettyName;
    }
  }

  async showDetails(violation) {
    await this.modalService.show(new ComponentModal(DetailsViolationComponent, { violation }))
      .catch((error) => console.log(error));
  }

  async showHistory(lotId) {
    await this.modalService.show(new ComponentModal(LoteInfoComponent, { lotId }))
      .catch((error) => console.log(error));
  }

  getHistories(violation) {
    return this.getNormalizedHistory(violation);
  }

  getLaneInfo(lane) {
    return this.lanes.filter(item => item.code === lane).map(item => item.prettyNames && item.prettyNames[0]).shift();
  }

  getAuthorInfo(violation) {
    let item;
    if (this.getNormalizedHistory(violation)[0].id === 'lotAttribution') {
      item = this.getNormalizedHistory(violation)[1];
    } else {
      item = this.getNormalizedHistory(violation).shift();
    }
    return item?.authorName || null;
  }

  getNormalizedHistory(violationsHistory: []) {
    const resultNormalized = [];
    Object.entries(violationsHistory).map(item => {
      const id: string = item[0];
      const data: any = item[1];

      if (Array.isArray(data)) {
        data.map(itemInside => {
          const itemObj: any = {};
          const stepName = this.violationsStepsResource.filter(step => step.id === id).shift();
          itemObj.id = id;
          itemObj.title = stepName ? stepName.value : null;
          itemObj.date = itemInside.date;
          if (itemInside.authorName) {
            itemObj.authorName = itemInside.authorName;
          }
          resultNormalized.push(itemObj);
        });
      } else {
        const itemObj: any = {};
        itemObj.id = id;
        itemObj.title = this.violationsStepsResource.filter(step => step.id === id).shift().value;
        itemObj.date = data.date;
        if (data.authorName) {
          itemObj.authorName = data.authorName;
        }
        resultNormalized.push(itemObj);
      }
    });

    resultNormalized.sort((a, b) => +new Date(b.date) - +new Date(a.date));
    return resultNormalized;
  }

  setResources(value) {
    this.violationsStepsResource = value;
  }
}
