<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 i18n class="modal-title">Teclas de Atalho</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="modal-body">
      <p i18n class="mb-0">As teclas de atalho tem como finalidade aumentar a eficiência, facilitar a navegação do usuário e
        usabilidade do sistema.</p>
      <p i18n class="mb-4">A disponibilidade de atalhos dependem da tela que está sendo acessada e do elemnto que está
        selecionado/ativo no momento.</p>

      <div class="row">
        <div class="col-6 pl-0">
          <div class="col-12">
            <fieldset>
              <legend i18n>Gerais</legend>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Fechar Tela</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    ESC
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Percorrer elementos editáveis (Avançar)</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    TAB
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Percorrer elementos editáveis (Retornar)</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    SHIFT + TAB
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Exibir "Guia de atalhos"</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    F1
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-12 mt-3">
            <fieldset>
              <legend i18n>Telas de Pré-Processamento</legend>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Validar Registro</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    ENTER
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Selecionar motivo de invalidação</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    CTRL + I
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Anterior (navegação dos registros)</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    CTRL + K
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Próximo (navegação dos registros)</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    CTRL + L
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Abrir painel de comentários</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    CTRL + M
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-12 mt-3">
            <fieldset>
              <legend i18n>Navegação dos arquivos (imagens/videos)</legend>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Arquivo anterior</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    CTRL + ,
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Próximo arquivo</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    CTRL + .
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Acionar/Pausar vídeo</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border" i18n>
                    Barra espaço
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Adiantar vídeo (x segundos)</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    <i class="material-icons" aria-hidden="true">arrow_right</i>
                  </div>
                </div>
              </div>
              <div class="row box">
                <div class="col-md-8">
                  <b i18n>Voltar vídeo (x segundos)</b>
                </div>
                <div class="col-md-4">
                  <div class="key-border">
                    <i class="material-icons" aria-hidden="true">arrow_left</i>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="col-6">
          <fieldset>
            <legend i18n>Modal de edição das imagens</legend>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Abrir modal de edição</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  CTRL + Z
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Aumentar contraste</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  *
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Diminuir contraste</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  /
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Aumentar brilho</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  +
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Diminuir brilho</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  -
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Recortar imagem</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  CTRL + R
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Obliterar Imagem</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  CTRL + O
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Adicionar caixa de seleção</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  CTRL + O
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b><mark i18n>Mover caixa de seleção</mark></b>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Para cima</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  CTRL + <i class="material-icons" aria-hidden="true">arrow_drop_up</i>
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Para baixo</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  CTRL + <i class="material-icons" aria-hidden="true">arrow_drop_down</i>
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Para direita</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  CTRL + <i class="material-icons" aria-hidden="true">arrow_right</i>
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Para esquerda</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  CTRL + <i class="material-icons" aria-hidden="true">arrow_left</i>
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b><mark i18n>Expandir/Reduzir caixa de seleção</mark></b>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Para cima</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  SHIFT + <i class="material-icons" aria-hidden="true">arrow_drop_up</i>
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Para baixo</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  SHIFT + <i class="material-icons" aria-hidden="true">arrow_drop_down</i>
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Para direita</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  SHIFT + <i class="material-icons" aria-hidden="true">arrow_right</i>
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-8">
                <b i18n>Para esquerda</b>
              </div>
              <div class="col-md-4">
                <div class="key-border">
                  SHIFT + <i class="material-icons" aria-hidden="true">arrow_left</i>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</div>
