import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-set-field',
  templateUrl: './set-field.component.html',
  styleUrls: ['./set-field.component.sass']
})
export class SetFieldComponent extends BaseModal implements OnInit  {
  get isValid() {
    return this.form.valid;
  }
  lastState: any;
  public form: FormGroup;
  public value: any;

  constructor(
    private formBuilder: FormBuilder,
    public modalService: ModalService
  ) {
    super(modalService);
   }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      field: ['', [Validators.required]]
    });
    this.form.get('field').valueChanges.subscribe(value => {
      this.value = value;
    });
  }

  onAllSubmited(): Promise<any> {
    return this.value;
  }

}
