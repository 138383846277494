import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { ActivitiesRoutingModule } from './activities-routing.module';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { AtivityViewModalComponent } from './ativity-view-modal/ativity-view-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    ActivityListComponent,
    AtivityViewModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    ActivitiesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  entryComponents: [
    AtivityViewModalComponent
  ]
})
export class ActivitiesModule { }
