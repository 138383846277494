<div class="card-header">
  <div class="row d-flex justify-content-between">
    <div class="col-2">
      <p i18n class="my-2 font-weight-bold align-font-size">Auditoria
      </p>
    </div>
  </div>
</div>

<div class="card-body">
  <div class="row mb-3">
    <div class="col-1">
      <mat-checkbox color="primary" i18n [value]="true"
        [checked]="preProcessSettings && preProcessSettings.audit?.enabledSamplingPlan"
        (change)="setEnabledSamplingPlan($event.checked)">Auditar por amostragem.</mat-checkbox>
    </div>
  </div>
  <form [formGroup]="form" *ngIf="preProcessSettings && preProcessSettings.audit?.enabledSamplingPlan">
    <div class="row mt-3 mb-1">
      <div class="mb-2 col-12">
        <div class="row" class="align-text">
          <label i18n>Selecione o NQA (Nível de Qualidade Aceitável) para auditoria por amostragem: <i i18n-appTooltip
              class="material-icons icon-sm text-secondary" data-placement="top" appTooltip="O nível de critério de aceite é definido de acordo com a NBR 5426
                para o plano de amostragem simples">help</i><small class="text-danger">*</small></label>
          <select formControlName="acceptableQualityLevel">
            <option value="1.5">1.5</option>
            <option value="2.5">2.5</option>
          </select>
        </div>
      </div>
    </div>
  </form>
</div>

<hr>

<div class="card-body">
  <div class="row mb-3">
    <div class="col-lg-9">
      <p i18n class="my-2 font-weight-bold">Configurações para reprovar lotes
        <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top"
          appTooltip="Todas as infrações reprovadas serão contabilizadas, porém, somente os registros invalidados de acordo com as configurações abaixo serão consideradas para reprovar um lote.">help</i>
      </p>
    </div>
    <div class="col-lg-3">
      <button class="btn btn-primary" i18n (click)="addFieldsToLotRefused()">Adicionar Novo</button>
    </div>
  </div>
  <div class="box-card card" *ngFor="let setting of fieldsToLotRefused">
    <button class="btn-close" (click)="removeFieldsToLotRefused(setting)"><i i18n-appTooltip
        class="material-icons icon-sm text-secondary">close</i></button>
    <div class="card-body">
      <p i18n>Campos que devem ser considerados (se alterados), para reprovar um lote:</p>
      <mat-checkbox color="primary" i18n [value]="true" [checked]="setting.violationStatus === 'valid'"
        (change)="setFieldsToLotRefused(setting.id, {violationStatus: $event.checked})">Status da
        Infração</mat-checkbox>
      <div class="row mb-2">
        <div class="col-4 pt-2">
          <mat-checkbox color="primary" i18n [checked]="setting.reasonsEnabled"
            (change)="setFieldsToLotRefused(setting.id, {reasonsEnabled: $event.checked})">Motivo de
            Invalidação</mat-checkbox>
        </div>
        <div class="col-8" *ngIf="setting.reasonsEnabled">
          <div class="select-wrapper">
            <div class="form-control select-box-button" (click)="selectBox(setting)">
              <p>
                <span *ngIf="setting.reasonsEnabled && setting.reasonIds.length === 0">Selecione um motivo de
                  invalidação</span>
                <span class="viewItem" *ngFor="let r of setting.reasonIds">{{ reasonsById[r]?.prettyName }}</span>
              </p>
              <div class="card select-box" [ngClass]="{active: setting.boxOpen}">
                <div class="card-body">
                  <mat-checkbox i18n color="primary" *ngIf="setting.reasonIds.length === reasons?.length"
                    (click)="setFieldsToLotRefused(setting.id, {reasonId: '!all'})" [checked]="true">
                    Todos
                  </mat-checkbox>
                  <mat-checkbox i18n color="primary" *ngIf="setting.reasonIds.length !== reasons?.length"
                    (click)="setFieldsToLotRefused(setting.id, {reasonId: 'all'})" [checked]="false">
                    Todos
                  </mat-checkbox>
                  <div *ngFor="let reason of reasons">
                    <mat-checkbox i18n color="primary" *ngIf="setting.reasonIds.includes(reason.id)"
                      (click)="setFieldsToLotRefused(setting.id, {reasonId: reason.id})" [checked]="true">
                      {{ reason.prettyName }}
                    </mat-checkbox>
                    <mat-checkbox i18n color="primary" *ngIf="!setting.reasonIds.includes(reason.id)"
                      (click)="setFieldsToLotRefused(setting.id, {reasonId: reason.id})">
                      {{ reason.prettyName }}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-checkbox color="primary" i18n [checked]="setting.vehicleFieldsEnabled"
        (change)="setFieldsToLotRefused(setting.id, {vehicleFieldsEnabled: $event.checked})">Dados
        Veiculares</mat-checkbox>
      <div class="row" *ngIf="setting.vehicleFieldsEnabled">
        <div class="col-6 col-sm-4 col-lg-3">
          <mat-checkbox i18n color="primary" *ngIf="setting?.vehicleFields?.length === 7"
            (click)="setFieldsToLotRefused(setting.id, {vehicleFields: '!all'})" [checked]="true">Todos</mat-checkbox>
          <mat-checkbox i18n color="primary" *ngIf="setting?.vehicleFields?.length !== 7"
            (click)="setFieldsToLotRefused(setting.id, {vehicleFields: 'all'})" [checked]="false">Todos</mat-checkbox>
        </div>
        <app-enum-i18n [input]="vehicleRequiredFields" (output)="vehicleRequiredFieldsI18n=$event"></app-enum-i18n>
        <div class="col-6 col-sm-4 col-lg-3" *ngFor="let field of vehicleRequiredFieldsI18n">
          <mat-checkbox color="primary" i18n *ngIf="!setting?.vehicleFields?.includes(field.id) && field?.value"
            (click)="setFieldsToLotRefused(setting.id, {vehicleFields: field.id}, true)">{{field.value}}</mat-checkbox>

          <mat-checkbox color="primary" i18n *ngIf="setting?.vehicleFields?.includes(field.id) && field?.value"
            (click)="setFieldsToLotRefused(setting.id, {vehicleFields: field.id}, false)"
            [checked]="true">{{field.value}}</mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>

<div class="card-body">
  <div class="row mb-3">
    <div class="col-md-12">
      <p i18n class="my-2 font-weight-bold">Lote Automático para Auditoria
        <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top"
          appTooltip="Para adicionar uma configuração e/ou editar uma configuração existente é necessário 'PAUSAR' a atribuição automática.">help</i>
      </p>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-1">
      <mat-checkbox color="primary" i18n [value]="true"
        [checked]="preProcessSettings && preProcessSettings.audit?.enabledLotGenerationAudit"
        (change)="setEnabledLotGenerationAudit($event.checked)">Habilitar geração automática de lote exclusiva para
        auditoria.</mat-checkbox>
    </div>
  </div>
  <div class="row mb-3" *ngIf="preProcessSettings && preProcessSettings.audit?.enabledLotGenerationAudit">
    <div class="col-md-12">
      <app-auto-lot-config-audit [preProcessSettings]="preProcessSettings"
        [stopAttribution]="preProcessSettings?.audit?.lotGeneration?.stopAttribution || false"
        [audit]="true"></app-auto-lot-config-audit>
    </div>
  </div>
</div>
