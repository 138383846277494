<div class="table-flex mb-4" *ngIf="loading; else loadingBlock">
  <div class="table-flex__header">
    <div class="w-25">
      <app-production-report-period></app-production-report-period>
    </div>
    <p class="w-15" i18n>Tempo <br />trabalhado</p>
    <div class="w-40">
      <p i18n>Qtd de registros processados</p>
      <div class="table-flex__header__subColumns">
        <p i18n *ngIf="contract.steps && contract.steps.includes('typing')">Digitação</p>
        <p i18n *ngIf="contract.steps && contract.steps.includes('verifyValid')">Verificação de válida</p>
        <p i18n *ngIf="contract.steps && contract.steps.includes('verifyInvalid')">Verificação de inválidas</p>
        <p i18n *ngIf="contract.steps && contract.steps.includes('validate')">Validação</p>
      </div>
    </div>
    <p class="w-15" i18n>Média <br />por registro</p>
    <p class="w-5"></p>
  </div>

  <div class="table-flex__item" *ngIf="items.length > 0">
    <div class="table-flex__row table-flex__row--sub-header">
      <p class="w-25">{{ contract.name }}</p>
      <p class="w-15">{{ setTime(contract.timeWorked) }}</p>
      <div class="w-40 table-flex__item__subColumns">
        <p *ngIf="contract.steps && contract.steps.includes('typing')">
          <a [routerLink]="['typing']">{{ contract.typing }}</a>
        </p>

        <p *ngIf="contract.steps && contract.steps.includes('verifyValid')">
          <a [routerLink]="['verifyValid']">{{ contract.verifyValid }}</a>
        </p>

        <p *ngIf="contract.steps && contract.steps.includes('verifyInvalid')">
          <a [routerLink]="['verifyInvalid']">{{ contract.verifyInvalid }}</a>
        </p>

        <p *ngIf="contract.steps && contract.steps.includes('validate')">
          <a [routerLink]="['validate']">{{ contract.validate }}</a>
        </p>
      </div>

      <p class="w-15">{{ setTime(contract.timeWorked / contract.totalWork) }}</p>
      <p class="w-5 ml-auto"></p>
    </div>
  </div>

  <div class="table-flex__item" *ngFor="let user of items; let i = index" [class.collapsed]="user.collapse">
    <div class="table-flex__row" [ngClass]="{'table-flex__row--no-result': user.totalWork === 0}">
      <p class="w-25 w--push-5 d-flex align-items-center">
        {{ user.name }}
        <i class="material-icons text-secondary ml-2 icon-md" i18n-appTooltip *ngIf="user.noGroups"
        appTooltip="Usuário não pertence mais a nenhum grupo.">help</i>
      </p>
      <p class="w-15">{{ setTime(user.timeWorked) }}</p>

      <div class="w-40 table-flex__item__subColumns">
        <p *ngIf="contract.steps && contract.steps.includes('typing')">{{ user.typing }}</p>
        <p *ngIf="contract.steps && contract.steps.includes('verifyValid')">{{ user.verifyValid }}</p>
        <p *ngIf="contract.steps && contract.steps.includes('verifyInvalid')">{{ user.verifyInvalid }}</p>
        <p *ngIf="contract.steps && contract.steps.includes('validate')">{{ user.validate }}</p>
      </div>

      <p class="w-15">{{ setTime(user.timeWorked / user.totalWork) }}</p>

      <p class="w-5 ml-auto">
        <button class="btn btn-sm btn-icon-block"
          [ngClass]="{ 'btn-fade-secondary': !user.collapse, 'btn-secondary': user.collapse }"
          (click)="toggleCollapse(user)" *ngIf="user.totalWork > 0">
          <i class="material-icons" *ngIf="user.collapse">unfold_less</i>
          <i class="material-icons" *ngIf="!user.collapse">unfold_more</i>
        </button>
      </p>
    </div>

    <div class="table-flex__item collapse" *ngFor="let date of user.dates; let i = index"
      [class.collapsed]="date.collapse">
      <div class="table-flex__row">
        <p class="w-25 w--push-10">{{ date.date | moment: 'L' }}</p>
        <p class="w-15">{{ setTime(date.timeWorked) }}</p>

        <div class="w-40 table-flex__item__subColumns">
          <p *ngIf="contract.steps && contract.steps.includes('typing')">{{ date.typing }}</p>
          <p *ngIf="contract.steps && contract.steps.includes('verifyValid')">{{ date.verifyValid }}</p>
          <p *ngIf="contract.steps && contract.steps.includes('verifyInvalid')">{{ date.verifyInvalid }}</p>
          <p *ngIf="contract.steps && contract.steps.includes('validate')">{{ date.validate }}</p>
        </div>

        <p class="w-15">{{ setTime(date.timeWorked / date.totalWork) }}</p>
        <p class="w-5 ml-auto">
          <button class="btn btn-sm btn-icon-block"
            [ngClass]="{ 'btn-outline-secondary': !date.collapse, 'btn-secondary': date.collapse }"
            (click)="toggleCollapse(date)">
            <i class="material-icons" *ngIf="date.collapse">unfold_less</i>
            <i class="material-icons" *ngIf="!date.collapse">unfold_more</i>
          </button>
        </p>
      </div>

      <div class="table-flex__item collapse" *ngFor="let regulation of date.regulations; let i = index"
        [class.collapsed]="regulation.collapse">
        <div class="table-flex__row">
          <p class="w-25 w--push-15 d-flex align-items-center">
            <span i18n-appTooltip [appTooltip]="getRegulationInfo(regulation.id)"
              class="d-inline-flex align-items-center">
              <i class="material-icons text-light mr-2">info</i>
              {{ regulation.code }}
            </span>
          </p>
          <p class="w-15"></p>
          <div class="w-40 table-flex__item__subColumns">
            <p *ngIf="contract.steps && contract.steps.includes('typing')">{{ regulation.typing }}</p>
            <p *ngIf="contract.steps && contract.steps.includes('verifyValid')">{{ regulation.verifyValid }}</p>
            <p *ngIf="contract.steps && contract.steps.includes('verifyInvalid')">{{ regulation.verifyInvalid }}</p>
            <p *ngIf="contract.steps && contract.steps.includes('validate')">{{ regulation.validate }}</p>
          </div>
          <p class="w-20"></p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>

<app-pagination-simple *ngIf="loading" (items)="setItems($event)" [data]="contract.users" [deleteEvent]="deleteEvent">
</app-pagination-simple>
