import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService } from 'src/app/core/services';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-select-period',
  templateUrl: './select-period.component.html',
  styleUrls: ['./select-period.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'}
  ],
})
export class SelectPeriodComponent extends BaseModal implements OnInit {
  isValid: boolean;
  lastState: any;

  public form: FormGroup;
  public periodSelected: any = {};
  public startDateValue;
  public endDateValue;
  public maxDate;
  public maxDateStart;
  public minDate;
  public rangeDates;

  constructor(
    modalService: ModalService,
    private formBuilder: FormBuilder,
  ) {
    super(modalService);
  }

  ngOnInit() {
    const now = new Date().toISOString();
    const startDateDefault = moment(now).utc().subtract(31, 'days');
    const endDateDefault = moment(now);
    const nowDate = moment();
    const earliestDate = moment().subtract(6, 'month');
    const days = nowDate.diff(earliestDate, 'days');
    const minDateDefault = moment(now).subtract(days, 'days');
    const rangeDates = [[7, 'days'], [15, 'days'], [1, 'month'], [2, 'month'], [3, 'month'], [4, 'month'], [5, 'month'], [6, 'month']];

    this.startDateValue = this.initialState && this.initialState.startDate || startDateDefault;
    this.endDateValue = this.initialState && this.initialState.endDate || endDateDefault;
    this.minDate = minDateDefault;
    this.rangeDates = rangeDates;
    this.maxDate = moment(now);
    this.maxDateStart = moment(now);

    this.form = this.formBuilder.group({
      startDate: [null],
      endDate: [null]
    });

    this.form.setValidators(this.fromToDate('startDate', 'endDate'));

    this.form.valueChanges.subscribe(res => {
      this.isValid = true;
    });

    this.form.get('startDate').setValue(this.startDateValue);
    this.form.get('endDate').setValue(this.endDateValue);
  }

  fromToDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const fromDate = formGroup.get(fromDateField).value;
      const toDate = moment(formGroup.get('endDate').value).utc().format('YYYY-MM-DD[T]23:59:59');
      if ((fromDate && toDate) && moment(fromDate).isSameOrAfter(toDate)) {
        return { [errorName]: true };
      }
      return null;
    };
  }

  async onAllSubmited(): Promise<any> {
    this.periodSelected = this.form.getRawValue();
  }

  rangePeriod(day, period) {
    const now = new Date().toISOString();
    const newDate = moment(now).utc().subtract(day, period);
    this.form.get('startDate').setValue(newDate);
    this.form.get('endDate').setValue(now);
  }

}
