<div class="container">
  <div class="page-title page-title--columns">
    <div class="page-title__left"><h2 i18n>Pontos</h2></div>
    <div class="page-title__right" *appPermission="'CreateSpot'">
      <button class="btn btn-fade-primary" (click)="newSpot()" i18n title="Novo Ponto">
        Novo Ponto</button>
    </div>
  </div>

  <ul class="nav nav-pills pb-5">
    <li class="nav-item"><a class="nav-link active" i18n>Pontos</a></li>
    <li class="nav-item"><a class="nav-link" routerLink="/lanes" i18n>Faixas</a></li>
    <li class="nav-item"><a class="nav-link" routerLink="/spots/map" i18n>Mapa</a></li>
  </ul>

  <div class="row mb-5">
    <div class="col">
      <form [formGroup]="searchForm" (submit)="handleSearch()">
        <div class="input-group">
          <input i18n-label type="text" class="form-control form-search" formControlName="search"
            i18n-placeholder placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">

          <div class="input-group-append">
            <div class="filter-dropdown filter-dropdown--md">
              <button type="button" class="btn btn-outline-secondary btn-icon filter-dropdown__handle" i18n>
                <i class="material-icons">filter_alt</i> Filtrar</button>

              <div class="filter-dropdown__content">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label i18n>País: </label>
                      <select tabindex="4" class="form-control" formControlName="region"
                        (ngModelChange)="loadStates($event)">
                        <option *ngFor="let region of regions" [value]="region.name">
                          {{region.name}}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col">
                    <div class="form-group">
                      <app-enum-i18n [input]="roadTypes" (output)="roadTypesI18n=$event"></app-enum-i18n>
                      <app-input i18n-placeholder="@@SpotListRoadTypePlaceholder" i18n-label="@@SpotListRoadTypeLabel"
                        type="multiselect" [list]="roadTypesI18n" label="Tipo da Via" formControlName="roadType"
                        selectText="value" selectValue="id" placeholder="Todos">
                      </app-input>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <app-input i18n-placeholder i18n-label type="select" [list]="states" label="Estado"
                        formControlName="state" selectText="name" selectValue="id" placeholder="Todos"
                        (ngModelChange)="loadCities($event)">
                      </app-input>
                    </div>
                  </div>

                  <div class="col" *ngIf="showCities">
                    <div class="form-group">
                      <app-input i18n-placeholder i18n-label type="select" [list]="cities" label="Cidade"
                        formControlName="city" selectText="name" selectValue="id" placeholder="Todos">
                      </app-input>
                    </div>
                  </div>
                </div>

                <div class="filter-dropdown__footer">
                  <button type="submit" class="btn btn-info btn-block" (click)="disableMenu()" data-dismiss="modal" i18n>Aplicar</button>
                </div>
              </div>
            </div>
            <div class="filter-dropdown">
              <div *ngIf="!isAllOpen; else retractButton">
                <button class="btn btn-outline-secondary btn-icon" (click)="toggleAllSpots()" i18n>
                  <i class="material-icons">unfold_more</i> Expandir todos
                </button>
              </div>
              <ng-template #retractButton>
                <button class="btn btn-secondary btn-icon" (click)="toggleAllSpots()" i18n>
                  <i class="material-icons">unfold_less</i> Retrair todos
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>


  <div *ngFor="let spot of spots; index as i" class="my-3">
    <app-spot-panel [spotId]="spot.spotId" [isShowing]="spot.isOpen || isAllOpen"
      [toggleSpot]="panelCommands.asObservable(boolean)" (updateSpotList)="refreshSpots($event, i)">
    </app-spot-panel>
  </div>
</div>

<div class="loading-page">
  <app-infinite-scroll (items)="setSpots($event)" [params]="params" (clear)="spots=[]" [service]="spotService" [permission]="'ViewSpot'">
  </app-infinite-scroll>
</div>
