<app-enum-i18n [input]="statusLot" (output)="statusLotI18n=$event"></app-enum-i18n>
<app-enum-i18n [input]="typeLot" (output)="typeLotI18n=$event"></app-enum-i18n>
<app-enum-i18n [input]="situation" (output)="situationI18n=$event"></app-enum-i18n>

<div class="container">
  <div class="page-title page-title--columns">
    <div class="page-title__left">
      <p class="page-title__link-back" i18n><a routerLink="/pre-process">Pré-processamento</a></p>
      <h2 i18n>Gerenciamento de Lotes</h2>
    </div>
  </div>

  <div class="row justify-content-between">
    <div class="col-10">
      <form [formGroup]="searchForm" (submit)="handleSearch()">
        <div class="input-group">
          <input type="text" class="form-control form-search" placeholder="Buscar registro"
            formControlName="search" (ngModelChange)="searchKey($event)" autocomplete="off">

          <div class="input-group-append">
            <div class="filter-dropdown">
              <button type="button" class="btn btn-outline-secondary btn-icon filter-dropdown__handle" i18n>
                <i class="material-icons">filter_alt</i> Filtrar</button>

              <div class="filter-dropdown__content">
                <div class="form-group">
                  <app-input type="multiselect" [list]="statusLotI18n" formControlName="status"
                    selectText="value" selectValue="id" selectShowLimit="2" placeholder="Status" i18n-placeholder>
                  </app-input>
                </div>

                <div class="form-group">
                  <app-input type="multiselect" [list]="typeLotI18n" formControlName="type"
                    selectText="value" selectValue="id" selectShowLimit="2" placeholder="Tipo da Infração" i18n-placeholder>
                  </app-input>
                </div>

                <div class="form-group">
                  <app-input type="multiselect" [list]="situationI18n" formControlName="situation"
                    selectText="value" selectValue="id" selectShowLimit="2" placeholder="Situação do Lote" i18n-placeholder>
                  </app-input>
                </div>

                <div class="filter-dropdown__footer">
                  <button i18n class="btn btn-info btn-block" (click)="handleSearch()" data-dismiss="modal">Aplicar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-2">
      <div class="filter-dropdown">
        <button i18n class="btn btn-fade-secondary btn-icon">
          <i class="material-icons">view_column</i>
          Exibir Colunas
        </button>

        <app-column-filter [items]="columns" (output)="columnsToShow=$event"></app-column-filter>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading" class="mt-5 mb-3">
    <div class="table-responsive table-block table-block--align-middle">
      <table class="table table-bordeless table-striped table-hover data-table">
        <thead>
          <tr>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('contract')">Contrato</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('lot')">Lote</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('lot-number')">Número</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('status')">Status</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('violationType')">Tipo de Infrações</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('step')">Etapa</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('situation')">Situação do Lote</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('violationsAmount')">Qtd de Infrações</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('violationPeriod')">Período das Infrações</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('modifiedAt')">Última Modificação</th>
            <th *appPermission="['UpdateLot']; contracts: contract && [contract.id]"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lot of lots">
            <td class="text-center" i18n *ngIf="columnsToShow.includes('contract')">{{ contract.prettyName }} </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('lot')">{{ lot.name }} </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('lot-number')">{{ lot.lotNumber }} </td>
            <td class="text-center" *ngIf="columnsToShow.includes('status')">
              <div [ngSwitch]="status">
                <div i18n *ngSwitchCase="'process'">Em processamento</div>
                <div i18n *ngSwitchCase="'audit'">Em Auditoria</div>
                <div i18n *ngSwitchDefault class="nowrap">{{ getStatusLot(lot.status) }} <i *ngIf="lot.status==='error'" i18n-appTooltip class="material-icons icon-sm text-secondary"
                  data-placement="top" [appTooltip]="getStatusError(lot)">info</i></div>
              </div>
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('violationType')">
              <strong>{{ (lot.type && getTypeLot(lot.type)) || '' }}</strong></td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('step') && lot.step==='export'">Exportar</td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('step') && lot.step==='internalAudit'">Auditoria interna</td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('step') && lot.step==='revision'">Revisão</td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('step') && lot.step==='externalAudit'">Auditoria externa</td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('step') && lot.step==='waitReturn'">Aguardando Retorno</td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('step') && lot.step==='serialNumberGeneration'">Geração de número de série</td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('step') && lot.step==='end'">Fim</td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('step') && lot.step==='done'">Concluído</td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('step') && !lot.step">-</td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('situation')">
              {{ (lot.situation && getSituation(lot.situation)) || '-' }} </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('violationsAmount')">
              {{ lot.violationsAmount || 0 }} </td>
            <td class="text-center" *ngIf="columnsToShow.includes('violationPeriod')">
              {{ lot.oldestViolationAt && (lot.oldestViolationAt | moment: 'l') || '' }}
              <span i18n *ngIf="lot.oldestViolationAt && lot.newestViolationAt">a</span>
              {{ lot.newestViolationAt && (lot.newestViolationAt | moment: 'l') || '' }}
            </td>
            <td class="text-center" *ngIf="columnsToShow.includes('modifiedAt')">
              {{ lot.modifiedAt && (lot.modifiedAt | moment: 'DD-MM-YYYY HH:mm:ss') || '-' }}
            </td>
            <td>
              <ng-container *appPermission="['UpdateLot']; contracts: contract && [contract.id]">
                <div class="dropdown" *ngIf="lot.status === 'open' && lot.violationsAmount > 0">
                  <button class="btn btn-icon-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="material-icons">more_horiz</i></button>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <a i18n class="dropdown-item" (click)="openCloseLotModal(lot)">Fechar lote</a>
                  </div>
                </div>
              </ng-container>
              <ng-container *appPermission="['UpdateRegenerateLot']">
                <div class="dropdown"
                  *ngIf="(lot.status === 'error' || lot.status === 'closed' || lot.status === 'done') && lot.violationsAmount > 0 && verifyRegenerateLotPeriod(lot.modifiedAt) && (lot.step === 'export' || lot.step === 'done')">
                  <button class="btn btn-icon-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="material-icons">more_horiz</i></button>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <a i18n class="dropdown-item" (click)="regenerateLot(lot.id)">Regerar lote</a>
                  </div>
                </div>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <app-pagination (items)="lots=$event" (loading)="loadingEvent($event)" [params]="params" [deleteEvent]="deleteEvent" [service]="lotService">
  </app-pagination>
</div>
