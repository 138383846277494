<div class="main-menu sticky-top" [ngClass]="{'main-menu--show': isOpen}">
  <div class="main-menu__scroll" [ngClass]="{'main-menu__scroll--scroller': isSubMenu}">
    <div class="main-menu__left">
      <ul>
        <li class="menu-active">
          <a routerLink="/" (click)="closeMenu()" class="main-menu__btn">
            <i class="material-icons">home</i>
            <span i18n>Dashboard</span>
          </a>
        </li>
        <li class="main-menu__divider d-md-none" i18n>Olá, {{user.name}}</li>
        <li class="d-md-none menu-link">
          <a routerLink="/users/myProfile" (click)="closeMenu()" class="main-menu__btn">
            <i class="material-icons">edit</i>
            <span i18n>Meu perfil</span>
          </a>
        </li>
        <li class="d-md-none pointer">
          <a (click)="logout()" class="main-menu__btn">
            <i class="material-icons">exit_to_app</i>
            <span i18n>Sair</span>
          </a>
        </li>
        <li class="main-menu__divider" i18n>Módulos</li>
        <li *appPermission="['UpdatePreProcessSettings', 'ViewPreProcessSettings']">
          <a href="javascript:void(0)" (click)="menuNavigate($event, menuRegisterPreProcess)" class="main-menu__btn">
            <i class="material-icons">burst_mode</i>
            <span i18n>Pré-Processamento</span>
          </a>
        </li>
        <li *appPermission="['ViewViolation', 'UpdateViolation', 'ViolationsReport']">
          <a href="javascript:void(0)" (click)="menuNavigate($event, menuRegisterViolations)" class="main-menu__btn">
            <i class="material-icons">error_outline</i>
            <span i18n>Infração</span>
          </a>
        </li>
        <li class="main-menu__divider">Geral</li>
        <li *appPermission="['ViewEquipment','ViewUserGroup','ViewSpot','ViewUser','ViewVehicleWhiteList']">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigate($event, menuRegister)">
            <i class="material-icons">playlist_add</i>
            <span i18n>Cadastros</span>
          </a>
        </li>
      </ul>

      <ul class="mt-auto">
        <li *ngIf="importFile">
          <span *appPermission="['UploadImportFile', 'ViewImportFile']">
            <a routerLink="/import-file" class="main-menu__btn" (click)="closeMenu()">
              <i class="material-icons">import_export</i>
              <span i18n>Importação de Arquivos</span>
            </a>
          </span>
        </li>
        <li>
          <a routerLink="/contracts" class="main-menu__btn" (click)="closeMenu()">
            <i class="material-icons">settings_applications</i>
            <span i18n>Configuração</span>
          </a>
        </li>
      </ul>

      <p class="main-menu__version">
        <small>
          <span i18n>Versão</span> {{appVersion}} |
          <span class="text-underline" (click)="clickModalRelease()" i18n>Ver ReleaseNotes</span>
        </small>
      </p>
    </div>
    <div #menuRegister class="main-menu__right">
      <ul>
        <li class="main-menu__back">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigateBack($event)">
            <i class="material-icons">subdirectory_arrow_left</i>
            <span i18n>Voltar</span>
          </a>
        </li>
        <li>
          <p href="javascript:void(0);" class="main-menu__head">
            <i class="material-icons">playlist_add</i>
            <span i18n>Cadastros</span>
          </p>
          <ul>
            <li *appPermission="'ViewEquipment'"><a routerLink="/equipments" class="main-menu__btn"
                (click)="closeMenu()" i18n>Equipamentos</a></li>
            <li *appPermission="'ViewUserGroup'"><a routerLink="/user-groups" class="main-menu__btn"
                (click)="closeMenu()" i18n>Grupo de usuários</a></li>
            <li *appPermission="'ViewSpot'"><a routerLink="/spots" class="main-menu__btn" (click)="closeMenu()"
                i18n>Pontos e Faixas</a></li>
            <li *appPermission="'ViewUser'"><a routerLink="/users" class="main-menu__btn" (click)="closeMenu()"
                i18n>Usuários</a></li>
            <li *appPermission="['ViewCalibration', 'CreateCalibration', 'UpdateCalibration', 'DeleteCalibration']">
              <a routerLink="/calibrations" class="main-menu__btn"
                (click)="closeMenu()" i18n>Aferições
              </a>
            </li>
            <li *appPermission="'ViewVehicleWhiteList'">
              <a routerLink="/vehicleWhiteLists" class="main-menu__btn" (click)="closeMenu()" i18n>Veículo Isento</a>
            </li>
          </ul>
        </li>
      </ul>

      <p class="mt-auto">
        <a routerLink="/contracts" class="main-menu__btn" (click)="closeMenu()">
          <i class="material-icons">settings_applications</i>
          <span i18n>Configuração</span>
        </a>
      </p>

      <p class="main-menu__version">
        <small>
          <span i18n>Versão</span> {{appVersion}} |
          <span class="text-underline" (click)="clickModalRelease()" i18n>Ver ReleaseNotes</span>
        </small>
      </p>
    </div>
    <div #menuRegisterPreProcess class="main-menu__right">
      <ul class="mb-auto">
        <li class="main-menu__back">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigateBack($event)">
            <i class="material-icons">subdirectory_arrow_left</i>
            <span i18n>Voltar</span>
          </a>
        </li>
        <li>
          <p href="javascript:void(0);" class="main-menu__head">
            <i class="material-icons">burst_mode</i>
            <span i18n>Pré-Processamento</span>
          </p>
          <ul>
            <li class="main-menu__accordion" #accordionProcess>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionProcess)"
              *appPermission="['TriageViolation','TypingViolation','DoubleTypingViolation','VerifyViolation','ValidateViolation']">
                <span i18n>Processar infrações</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['TriageViolation']">
                  <a routerLink="/pre-process/triage/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">filter_list</i>
                    <span i18n>Triagem</span>
                  </a>
                </li>

                <li *appPermission="['TypingViolation', 'DoubleTypingViolation']">
                  <a routerLink="/pre-process//typing/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">done</i>
                    <span i18n>Digitação</span>
                  </a>
                </li>

                <li *appPermission="['VerifyViolation']">
                  <a routerLink="/pre-process/verify/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">done_all</i>
                    <span i18n>Verificação</span>
                  </a>
                </li>

                <li *appPermission="['ValidateViolation']">
                  <a routerLink="/pre-process/validate/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">done_outline</i>
                    <span i18n>Validação</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="main-menu__accordion" #accordionOperations>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionOperations)"
              *appPermission="['AuditViolation','ViewLot','QuarantineReport']">
                <span i18n>Operações</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['AuditViolation']">
                  <a (click)="unavailable()" class="main-menu__btn">
                    <i class="material-icons">playlist_add_check</i>
                    <span i18n>Auditoria</span>
                  </a>
                </li>

                <li *appPermission="['ViewLot']">
                  <a routerLink="/pre-process/lot-list/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">assignment</i>
                    <span i18n>Gerenciar Lotes</span>
                  </a>
                </li>

                <li *appPermission="['QuarantineReport']">
                  <a routerLink="/pre-process/quarantine/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">report</i>
                    <span i18n>Quarentena</span>
                  </a>
                </li>

                <li *appPermission="['ViewSerialNumberConfig']">
                  <a routerLink="/pre-process/serial-number-config/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">toc</i>
                    <span i18n>Gerenciar série</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="main-menu__accordion" #accordionReports>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionReports)"
              *appPermission="['PreProcessStepMap', 'PreProcessProductionReport']">
                <span i18n>Relatórios</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['PreProcessStepMap']">
                  <a routerLink="/pre-process/violation-step/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">assessment</i>
                    <span i18n>Mapa de etapas</span>
                  </a>
                </li>

                <li *appPermission="['PreProcessProductionReport']">
                  <a routerLink="/pre-process/production-report" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">assignment_ind</i>
                    <span i18n>Produção</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <p *appPermission="'UpdatePreProcessSettings'">
        <a (click)="navigatePreProcess('settings')" class="main-menu__btn">
          <i class="material-icons">settings_applications</i>
          <span i18n>Administrar módulo</span>
        </a>
      </p>

      <p class="main-menu__version">
        <small>
          <span i18n>Versão</span> {{appVersion}} |
          <span class="text-underline" (click)="clickModalRelease()" i18n>Ver ReleaseNotes</span>
        </small>
      </p>
    </div>

    <div #menuRegisterViolations class="main-menu__right">
      <ul class="mb-auto">
        <li class="main-menu__back">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigateBack($event)">
            <i class="material-icons">subdirectory_arrow_left</i>
            <span i18n>Voltar</span>
          </a>
        </li>
        <li>
          <p href="javascript:void(0);" class="main-menu__head">
            <i class="material-icons">error_outline</i>
            <span i18n>Infrações</span>
          </p>
          <ul>
            <li class="main-menu__accordion" #viewViolation>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, viewViolation)"
              *appPermission="['ViewViolation']">
                <span i18n>Consulta</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['ViewViolation']">
                  <a routerLink="/violations/violations-view" class="main-menu__btn" (click)="closeMenu()">
                    <i class="material-icons">perm_scan_wifi</i>
                    <span i18n>Infrações</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="main-menu__accordion" #accordionReportViolation>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionReportViolation)"
              *appPermission="['ViolationsReport']">
                <span i18n>Relatórios</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['ViolationsReport']">
                  <a routerLink="/violations/violations-report" class="main-menu__btn" (click)="closeMenu()">
                    <i class="material-icons" i18n>assignment_late</i>
                    <span i18n>Infrações</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <p class="main-menu__version mt-auto">
        <small>
          <span i18n>Versão</span> {{appVersion}} |
          <span class="text-underline" (click)="clickModalRelease()" i18n>Ver ReleaseNotes</span>
        </small>
      </p>
    </div>
  </div>
</div>
