import { BaseApiModel } from './BaseApiModel';

export class EquipmentModel extends BaseApiModel {
  public name: string;
  public manufacturerId: string;
  public type: string;
  public encriptionKey: string;
  public rules: any[];
  public maxLanes: number;
  public createdAt: Date;
  public modifiedAt: Date;
  public deletedAt: Date;
  public contractIds?: number[];

  get pretty() {
    return this.name + ' - ' + this.type;
  }
}
