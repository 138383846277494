/* eslint-disable no-shadow */
import { BaseApiModel } from './BaseApiModel';

export class Lot extends BaseApiModel {
  public contractId: string;
  public status: string;
  public type: string;
  public priority: string;
  public situation: string;
  public samplingPlan: string;
  public violationsAmount: number;
  public oldestViolationAt: Date;
  public newestViolationAt: Date;
  public modifiedAt: Date;
  public violationIds: Array<{id: number}>;
  public isSpecial: boolean;
  public enabled: boolean;
  public totalSampling: number;
  public targetAuditorId: number;
  public lockUserId: number;
  public lockDate: any;
  public step: string;
}

export enum StatusLot {
  new = 'new',
  open = 'open',
  closed = 'closed',
  process = 'process',
  audit = 'audit',
  done = 'done',
  error = 'error'
}

export enum Situation {
  accepted = 'accepted',
  refused = 'refused',
  waitEvaluation = 'waitEvaluation'
}
export enum Priority {
  low = 'low',
  medium = 'medium',
  high = 'high'
}
export enum TypeLot {
  valid = 'valid',
  invalid = 'invalid',
  all = 'all'
}
