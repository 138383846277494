<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header align-items-start">
      <div>
        <h5 class="modal-title" i18n>Fabricantes e Modelos de Equipamentos</h5>
        <p class="text-secondary">{{ contractCodeDescription }}</p>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="modal-table my-3">
      <table class="table table-sm table-borderless text-center table-striped">
        <thead>
          <th i18n>Fabricante</th>
          <th i18n>Modelo de Equipamento</th>
          <th i18n>Tipo</th>
          <th></th>
        </thead>
        <tbody class="table-hover align-items-center">
          <tr *ngFor="let equipment of equipmentModels">
            <td class="align-middle">{{equipment && manufacturerById && manufacturerById[equipment.manufacturerId]?.name}}</td>
            <td class="align-middle">{{equipment?.name}}</td>
            <td class="align-middle">{{equipment?.type}}</td>
            <td>
              <button *appPermission="['UpdateContract']; contracts: contract && [contract.id]"
              class="btn text-secondary btn-icon-block" data-placement="top" i18n-appTooltip appTooltip="Apagar registro" (click)="removeModel(equipment.id)">
                <i class="material-icons">remove_circle_outline</i>
              </button>
            </td>
          </tr>
          <tr *ngFor="let form of forms"  [formGroup]="form">
            <td class="align-middle text-center">
              <app-input type="select" formControlName="manufacturerId" [list]="manufacturers"
                placeholder="Selecione um fabricante" class="no-margin w-75" inputRequired=true hasLabel=false selectText="name" selectValue="id"
                i18n-placeholder></app-input>
            </td>
            <td class="align-middle text-center">
              <app-input type="select" formControlName="equipmentModelId" [list]="equipments[form.value.manufacturerId]"
              placeholder="Selecione um equipamento" class="no-margin w-75" selectText="name" selectValue="id"
              inputRequired=true hasLabel=false [inputDisabled]="form.value.manufacturerId === ''"
              i18n-placeholder></app-input>
            </td>
            <td class="align-middle text-center">
              <app-input type="select" formControlName="equipmentModelType" [list]="equipments[form.value.manufacturerId]"
              class="no-margin w-75 textDesable" selectText="type" selectValue="id"
              hasLabel=true [inputDisabled]="form.value.equipmentModelId === ''"></app-input>
            </td>
            <td>
              <button [routerLink]="" *appPermission="['UpdateContract']; contracts: contract && [contract.id]"
              class="btn text-secondary btn-icon-block" data-placement="top" i18n-appTooltip appTooltip="Apagar registro" (click)="removeForm(form)">
                <i class="material-icons">remove_circle_outline</i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal-footer justify-content-between">
      <div class="mt-2 text-right">
        <button (click)="addNew()" *appPermission="['UpdateContract']; contracts: contract && [contract.id]" class="btn btn-fade-info">
          Adicionar novo</button>
      </div>

      <div>
        <button class="btn btn-outline-secondary mr-3" (click)="onCancel()">
          <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
        </button>

        <button class="btn btn-primary" (click)="onSubmit()" [ngClass]="{disabled: !isValid}"
          *appPermission="['UpdateContract']; contracts: contract && [contract.id]">
          <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
        </button>
      </div>
    </div>
  </div>
</div>
