<h3 i18n="@@ForgotTitle" class="mb-3">Esqueci minha senha</h3>

<form [formGroup]="forgotPassword" (submit)="passwordForgot()">
  <div class="form-group">
    <app-input i18n-label="@@ForgotLabelEmail" type="text" formControlName="email" label="Digite seu e-mail"></app-input>
  </div>

  <div class="row mt-4">
    <div class="col">
      <a routerLink="/login" class="btn btn-outline-secondary" i18n>Voltar</a>
    </div>

    <div class="col d-flex flex-row-reverse">
      <button type="submit" class="btn btn-primary" [disabled]="forgotPassword.invalid">
        <span *ngIf="!loading; else Loading" i18n>Redefinir senha</span>

      <ng-template #Loading>
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </ng-template>
      </button>
    </div>
  </div>
</form>
