export class BaseApiModel {
    public id: string;
    public static unparse(obj) {
        return Object.assign(new this(), obj);
    }

    public static parse(obj) {
        const parsed = Object.assign({}, obj);
        return parsed;
    }

    public static create(obj) {
        return Object.assign(new this(), obj);
    }
}
