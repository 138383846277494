<div class="file-drop">
  <p class="file-drop__legend">{{text}}</p>

  <div class="file-drop__area">
    <span class="file-drop__message" i18n>Arraste e solte os arquivos aqui ou clique para selecionar</span>
    <input class="file-drop__input" type="file" [accept]="accept" multiple>
  </div>

  <ol class="file-drop__list" *ngFor="let file of files;let i=index">
    <li>
      <figure>
        <img (click)=openFile(i) [src]="file.data | sanitizer" width="50px" height="50px" *ngIf="file.detail.type === 'image/png' || file.detail.type === 'image/jpg' ||
        file.detail.type === 'image/jpeg' || file.detail.type === 'image/gif' else imageFile">
        <ng-template #imageFile>
          <img src="/assets/images/attachments.png" width="50px" height="50px" (click)=openFile(i)>
        </ng-template>
      </figure>

      <div class="file-drop__file-info">
        <p i18n>{{ file.detail.name }}</p>
        <p i18n *ngIf="file.fileSize  " class="size">{{ file.fileSize }}</p>
      </div>

      <button class="btn btn-fade-secondary btn-icon" (click)="deleteAttachment(i)">
        <i class="material-icons">remove_circle</i> Remover</button>
    </li>
  </ol>
</div>
