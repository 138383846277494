import { BaseApiModel } from './BaseApiModel';

export class ProductionContractReport extends BaseApiModel {
  public contractId: string;
  public contractName: string;
  public steps: string[] = [];
  public groups: any[] = [];
  public users: any[] = [];
  public regulations: any[] = [];
  public totalWork: number;
}
