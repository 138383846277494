/* eslint-disable no-shadow */
import { BaseApiModel } from './BaseApiModel';

export class Lot extends BaseApiModel {
  public contractId: string;
  public name: string;
  public status: string;
  public type: string;
  public situation: string;
  public violationsAmount: number;
  public oldestViolationAt: Date;
  public newestViolationAt: Date;
  public modifiedAt: Date;
  public enabled: boolean;
  public totalSampling: number;
  public targetAuditorId: number;
  public lockUserId: number;
  public lockDate: any;
  public step: string;
  public violationIds: Array<number>;
  public audits: Array<any>;
}

export enum StatusLot {
  new = 'new',
  open = 'open',
  closed = 'closed',
  process = 'process',
  audit = 'audit',
  done = 'done',
  error = 'error'
}

export enum Situation {
  accepted = 'accepted',
  refused = 'refused',
  waitEvaluation = 'waitEvaluation'
}

export enum TypeLot {
  valid = 'valid',
  invalid = 'invalid',
  all = 'all'
}
