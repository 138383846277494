import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Lane, Reason, Regulation, Situation, Spot } from 'src/app/core/models';
import { ContractService, ViolationReportService } from 'src/app/core/services';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-by-date',
  templateUrl: './by-date.component.html',
  styleUrls: ['./by-date.component.sass']
})
export class ViolationsReportByDateComponent implements OnInit, OnDestroy {
  public contractId: string;
  public laneCode: string;
  public laneId: string;
  public loading: boolean;
  public contractName: string;
  public contractDataViolationsSub: Subscription;
  public contractViolationsSub: Subscription;
  public filterParamsSub: Subscription;
  public violationsFormat: any[] = [];
  public regulations: Regulation[] = [];
  public spots: Spot[] = [];
  public lanes: Lane[] = [];
  public violations: any[] = [];
  public reasons: Array<Reason> = [];
  public spotInfo: string;
  public hideSpot: boolean;
  public laneInfo: string;
  public hideLane: boolean;
  public items: any[] = [];
  public filterParam: any =
    {
      spotsIds: [] = [],
      laneIds: [] = [],
      regulationsIds: [] = [],
      situationsIds: [] = [],
      reasonsIds: [] = [],
      date: ''
    };
  public today;
  public contractCode: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private violationReportService: ViolationReportService,
    private contractService: ContractService
  ) { }

  ngOnInit() {
    this.hideSpot = true;
    this.hideLane = true;
    if (this.route.snapshot.parent) {
      this.contractId = this.route.snapshot.parent.params.contractId;
      this.contractService.getById(this.contractId).then(contract => {
        this.contractCode = contract.code;
      });
    }
    this.laneCode = this.route.snapshot.params.laneId;
    const now = new Date().toISOString();
    this.today = moment(now).utc().format('YYYY-MM-DD');

    this.contractDataViolationsSub = this.violationReportService.getContractDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.contractName = data.contractName;
        this.regulations = data.regulations;
        this.reasons = data.reasons;

        const laneInfo = data.lanes.filter(item => item.code === this.laneCode).shift();
        data.spots.map(spot => {
          this.spots.push(spot);
        });
        data.lanes.map(lane => {
          this.lanes.push(lane);
        });
        if (laneInfo) {
          this.laneId = laneInfo.id;
          this.spotInfo = data.spots.filter(spot => spot.id === laneInfo.spotId).map(item => item.prettyName).shift();
          this.laneInfo = data.lanes.filter(item => item.id === this.laneId).map(item => item.laneCodeAndSpotCode).shift();
        }
      });

    this.contractViolationsSub = this.violationReportService.getContractViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.setViolations(data);
        this.loading = true;
      });
    this.filterParamsSub = this.violationReportService.getFilterParamsViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => this.filterParam = data);

      this.getSpotInfo();
      this.getLaneInfo();
  }

  getSpotInfo() {
    if (this.filterParam.spotsIds[0]) {
      const spotId = this.filterParam.spotsIds[0];
      const spotInfo =  this.spots.filter(spot => spot.id === spotId).shift();
      this.spotInfo = `${spotInfo.code} - ${spotInfo.description}`;
      this.hideSpot = false;
    };
  }

  getLaneInfo() {
    if (this.filterParam.laneIds[0]) {
      const laneId = this.filterParam.laneIds[0];
      const laneInfo = this.lanes.filter(lane => lane.id === laneId).shift();
      this.laneInfo = laneInfo.prettyNames[0];
      this.hideLane = false;
    }
  }

  setViolations(data) {
    this.violations = [];
    const contract: any = {};
    contract.dates = [];
    contract.situations = this.getResult(data, 'type');
    contract.total = contract.situations.reduce((acc, curr) =>  acc + curr.total, 0);

    contract.collapse = false;

    const datesWithViolations = _.chain(data).uniqBy('date').map('date').value().sort().reverse();

    for (const date of datesWithViolations) {
      const objDate: any = {};
      const dateViolations = data.filter(item => item.date === date);

      objDate.id = date;
      objDate.regulations = {};
      objDate.regulations.items = this.getResult(dateViolations, 'regulation');
      objDate.situations = this.getResult(dateViolations, 'type');
      objDate.total = objDate.situations.reduce((acc, curr) =>  acc + curr.total, 0);

      objDate.regulations.collapse = false;
      objDate.collapse = false;
      objDate.reasons = {};
      objDate.reasons.collapse = false;

      if (this.haveInvalid(objDate.situations)) {
        objDate.reasons.items = this.getResult(dateViolations, 'situation');
      }

      contract.dates.push(objDate);
    }

    this.violations.push(contract);
  }

  getResult(fromData: any[], byValue: string) {
    const listValues = _.chain(fromData).filter(byValue).uniqBy(byValue).map(byValue).value();
    return listValues.map(item => {
        const objResult: any = {};
        objResult.id = item;
        const listViolations = fromData.filter(itemFilter => itemFilter[byValue] === item);
        if (byValue === 'regulation') {
          objResult.code = item.split('#').pop();
          objResult.situations = this.getResult(listViolations, 'type');

          if (this.haveInvalid(objResult.situations)) {
            objResult.reasons = {};
            objResult.reasons.collapse = false;
            objResult.reasons.items = this.getResult(listViolations, 'situation');
          }
        }
        objResult.total = listViolations.reduce((acc, curr) =>  acc + curr.value, 0);
        return objResult;
    });
  }

  haveInvalid = (fromData) => fromData.some(item => item.id === 'invalid' && item.total > 0);

  toggleCollapse(item: { collapse: boolean }, local: any = null, parent: any = null) {
    if (local) { parent[local].collapse = false; }
    item.collapse = !item.collapse;
  }

  getRegulationInfo(regulation) {
    const regulationInfo = this.regulations.filter(item => item.code === regulation).shift();
    return regulationInfo.description;
  }

  getReasonInfo(reason: string) {
    if (this.reasons.length > 0) {
      const reasonInfo = this.reasons.filter(item => item.code === reason).shift();
      return `${reasonInfo.code} - ${reasonInfo.name}`;
    }
  }

  filterBy(date, situation = null, regulationCode = null, reason = null) {
    this.filterParam.date = date;
    if (situation) { this.filterParam.situationsIds = new Array(situation); }
    if (regulationCode) {
      const regulation = this.regulations.find(r => r.code === regulationCode);
      if (this.filterParam.regulationsIds == null) {
        this.filterParam.regulationsIds = [];
      }
      this.filterParam.regulationsIds.push(regulation.id);
    }
    if (reason) {
      if (!regulationCode.filterParam.situationsIds.includes('invalid')) { this.filterParam.situationsIds = new Array('invalid'); }
      const reasonInfo = this.reasons.filter(item => item.code === reason).shift();
      this.filterParam.reasonsIds = new Array(reasonInfo.id);
    }

    this.violationReportService.emitFilterParamsDetailsViolationReport(this.filterParam);
    this.router.navigate([date], { relativeTo: this.route });
  }

  getPercentage = (value: number, total: number) => ((value / total) * 100).toFixed(2);

  getValueByType(data: any = {}, type: string) {
    const value = data.situations.find(item => item.id === type);
    return value ? value.total : 0;
  }

  showRow(data: any = {}, type: string) {
    const value = this.getValueByType(data, type);
    return value > 0 ? `${value}/${this.getPercentage(value, data.total)}%` : 0;
  }

  showPercentage(data: any = {}, type: string) {
    const value = this.getValueByType(data, type);
    return value > 0 ? `${this.getPercentage(value, data.total)}%` : 0;
  }

  redefineFilter() {
    const filterParam: any = { spotsIds: [] = [], laneIds: [] = [], regulationsIds: [] = [], situationsIds: [] = [], reasonsIds: [] = []};
    this.violationReportService.emitFilterParamsViolationReport(filterParam);
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  ngOnDestroy() {
    if (this.contractViolationsSub) {
      this.contractViolationsSub.unsubscribe();
    }

    if (this.filterParamsSub) {
      this.filterParamsSub.unsubscribe();
    }
  }

}
