import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RegionService } from 'src/app/core/services/region.service';
import { StateService } from 'src/app/core/services/state.service';
import { CityService } from 'src/app/core/services/city.service';
import { ContractGlobalService, EquipmentModelService, EquipmentService, LaneService } from 'src/app/core/services';
import { SpotService } from 'src/app/core/services/spot.service';
import { RoadType, Region, Spot, Equipment, Lane, EquipmentModel, EquipmentFeatures, OperationModes } from 'src/app/core/models';
import { State } from 'src/app/core/models/State';
import { City } from 'src/app/core/models/City';

const defaultStateFilter = {
  search: [''],
  region: ['Brasil'],
  state: [''],
  roadType: [],
  city: [''],
  equipmentFeatures: [],
  operationModes: []
};

const arrayToObject = <T extends { [key: string]: any }>(
  array: T[],
  filter: (cb: T) => boolean = () => true
) => {
  const result = {};
  for (const it of array) {
    if (filter(it)) {
      result[it.id] = it;
    }
  }
  return result;
};

const getDefaultZoom = <T extends { [key: string]: any }>(spots: T[]): number => {
  let spotsCounterCities = 18;
  spots.forEach((value, index) => {
    if (spots.findIndex(e => e.cityName === value.cityName) === index && spotsCounterCities > 6) {
      spotsCounterCities -= 4;
    }
  });

  return spotsCounterCities;
};

interface SpotMap extends Omit<Spot, 'prettyName'> {
  lanes: Lane[];
  equipments: Equipment[];
  title: string;
  isOcr: boolean;
}

@Component({
  selector: 'app-spots-list',
  templateUrl: './spots-list-map.component.html',
  styleUrls: ['./spots-list-map.component.sass']
})
export class SpotsListMapComponent implements OnInit, OnDestroy {
  public spots: Array<SpotMap> = [];
  public selected: Spot | undefined;
  public equipments: { [id: number]: Equipment } = {};
  public currentEquipments: Equipment[] = [];
  public equipmentModels: { [id: number]: EquipmentModel } = {};
  public lanes: { [id: number]: Lane } = {};
  public currentLanes: Lane[] = [];
  public regions: Array<Region> = [];
  public states: Array<State>;
  public cities: Array<City>;
  public searchForm: FormGroup;
  public dropMenuActive = false;
  public showCities = false;
  public modelChanged: Subject<string> = new Subject<string>();
  public roadTypes = Object.values(RoadType);
  public roadTypesI18n = [];
  public equipmentFeatures = Object.values(EquipmentFeatures);
  public equipmentFeaturesI18n = [];
  public operationModes = Object.values(OperationModes);
  public operationModesI18n = [];
  public contracts: Subject<number[]> = new Subject<number[]>();
  public noRegister = false;
  public isLoading = true;
  public isDataLoaded = false;
  public defaultMapState = {
    latitude: 0,
    longitude: 0,
    zoom: 12
  };
  private contractEvent: Subscription;

  constructor(
    private spotService: SpotService,
    private equipmentService: EquipmentService,
    private equipmentModelService: EquipmentModelService,
    private laneService: LaneService,
    private regionService: RegionService,
    private stateService: StateService,
    private cityService: CityService,
    private formBuilder: FormBuilder,
    private contractsGlobalService: ContractGlobalService
  ) {
    this.modelChanged
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group(defaultStateFilter);

    this.regionService
      .getAll({ name: 'Brasil' })
      .then((regions) => {
        this.regions = regions;
      })
      .catch(console.log)
      .finally(async () => {
        await this.loadAditionalData();
        return this.handleSearch();
      });

    this.contractEvent = this.contractsGlobalService
      .contractEvent
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  operationModesLabel(txt: string){
    switch(txt){
      case 'enabled':
        return 'Ativado';
      case 'disabled':
        return 'Desativado';
      case 'test':
        return 'Teste';
      default:
        return txt;
    }
  }

  laneTypesLabel(txt: string) {
    switch(txt){
      case 'fixed':
        return 'Fixo';
      case 'speedBump':
        return 'Lombada Eletrônica';
      case 'portable':
        return 'Portátil';
      default:
        return txt;
    }
  }

  ngOnDestroy() {
    this.contractEvent.unsubscribe();
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  async loadAditionalData() {

    this.currentEquipments = await this.equipmentService.getAll();
    this.equipments = arrayToObject(this.currentEquipments);

    this.currentLanes = await this.laneService.getAll();
    this.lanes = arrayToObject(this.currentLanes);

    this.equipmentModels = {};
    for (const model of await this.equipmentModelService.getAll()) {
      this.equipmentModels[model.id] = model;
    }

    await this.loadStates(this.regions[0].name);
    this.isDataLoaded = true;
  }

  async loadCities(stateId: string) {
    if (!!stateId) {
      this.cities = await this.cityService.getAll({ regionId: this.regions[0].id, stateId });
      this.showCities = true;
    }
  }

  async loadStates(currentRegion: string) {
    const regionId = this.regions.find(r => r.name === currentRegion).id;
    const states = await this.stateService.getAll({ regionId });
    this.states = states.sort((a, b) => a.name.localeCompare(b.name));
    this.showCities = false;
  }

  toggleMenu() {
    this.dropMenuActive = !this.dropMenuActive;
  }

  zoomIntoSpotList(spot: Spot) {
    this.dropMenuActive = false;

    if (this.selected === spot) {
      this.selected = undefined;
      this.defaultMapState = { ...this.defaultMapState, zoom: getDefaultZoom(this.spots) };
    } else {
      this.selected = spot;
      this.defaultMapState = {
        zoom: 16,
        latitude: Number(spot.coordinates.latitude),
        longitude: Number(spot.coordinates.longitude)
      };
      setTimeout(() => new google.maps.StreetViewPanorama(document.querySelector('div#street-view'), {
        disableDefaultUI: true,
        fullscreenControl: true,
        position: new google.maps.LatLng(
          this.defaultMapState.latitude, this.defaultMapState.longitude
        )
      }));
    };
  }

  handleSearch() {
    this.isLoading = true;
    this.noRegister = false;
    this.selected = undefined;
    this.dropMenuActive = false;
    const params: { [key: string]: string } = {
      'coordinates[contains]': 'latitude'
    };

    const search = this.searchForm.get('search').value;
    const region = this.searchForm.get('region').value;
    const city = this.searchForm.get('city').value;
    const state = this.searchForm.get('state').value;
    const roadType: string[] = this.searchForm.get('roadType').value;
    const operationModes: string[] = this.searchForm.get('operationModes').value;
    const equipmentFeatures: string[] = this.searchForm.get('equipmentFeatures').value;

    const contracts = this.contractsGlobalService.contracts;
    if (!!contracts) {
      params['contractId[in]'] = `[${contracts.join(',')}]`;
    }

    if (!!region) {
      const { id: regionId } = this.regions.find(r => r.name === region);
      params.regionId = `${regionId}`;
    }
    if (!!city) {
      params.cityId = `${city}`;
    }
    if (!!state) {
      params.stateId = `${state}`;
    }
    if (roadType?.length > 0) {
      params[`roadType[in]`] = `[${roadType.join(',')}]`;
    }

    if (operationModes?.length > 0) {
      this.lanes = arrayToObject(this.currentLanes, lane => operationModes.some((op) => op === lane.operationMode));
    } else {
      this.lanes = arrayToObject(this.currentLanes);
    }

    if (equipmentFeatures?.length > 0) {
      this.equipments = arrayToObject(this.currentEquipments, eq => equipmentFeatures.some((feat) => eq.features[feat]?.enabled));
    } else {
      this.equipments = arrayToObject(this.currentEquipments);
    }

    if (!!search) {
      params['code[contains,or]'] = `${search}`;
      params['description[contains,or]'] = `${search}`;
      params['contractId[contains,or]'] = `${search}`;
      params['cityName[contains,or]'] = `${search}`;
    }

    this.spotService.getAll(params)
      .then((result) => {
        if (result.length === 0) {
          throw new Error('Empty Spots');
        }

        const spots: SpotMap[] = [];
        for (const spot of result) {
          const lanes: Lane[] = spot.laneIds.map(id => this.lanes[id]).filter(e => e);
          const equipments: Equipment[] = spot.equipmentIds.map(id => this.equipments[id]).filter(e => e);

          if (operationModes?.length > 0 && lanes.length === 0) {
            continue;
          }

          if (equipmentFeatures?.length > 0 && equipments.length === 0) {
            continue;
          }

          spots.push({
            ...spot,
            lanes,
            equipments,
            title: lanes.map( lane => lane.prettyNames.join()).join('\n'),
            isOcr: equipments.some(eq => !!eq.features?.ocr?.enabled),
            maxHeight: !isNaN(spot?.maxHeight) ? spot?.maxHeight : null
          });
        }

        this.spots = spots;
        const [{ coordinates: { latitude, longitude } }] = this.spots;
        this.defaultMapState.latitude = Number(latitude);
        this.defaultMapState.longitude = Number(longitude);
        this.defaultMapState.zoom = getDefaultZoom(this.spots);
      })
      .catch(() => this.noRegister = true)
      .finally(() => this.isLoading = false);
  }
}
