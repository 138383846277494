import { Component, OnInit, Input } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalContent } from 'src/app/core/interface';
import { ReasonService } from 'src/app/core/services/reason.service';
import { Reason } from 'src/app/core/models';
import { ModalService } from 'src/app/core/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.sass']
})
export class ReasonModalComponent extends BaseModal implements OnInit, ModalContent {
  @Input() initialState;
  public reasons: Reason[] = [];
  public reasonId = '';
  public reason: Reason;

  public get isValid() {
    return this.reasonId != null;
  }

  constructor(
    private formBuilder: FormBuilder,
    private reasonService: ReasonService,
    modalService: ModalService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.reasonService.getAll(this.initialState).then(data => {
      this.reasons =  data.sort((a, b): any => Number(a.code) - Number(b.code));
    });
  }

  get lastState() {
    return;
  }

  public async onAllSubmited() {
  }

  onSubmit() {
    if (this.isValid) {
      this.reason = this.reasons.find(r => r.id === this.reasonId);
      return this.modalService.submit();
    }
  }

  onClose() {
    this.modalService.close();
  }
}
