<div class="modal-dialog modal-dialog-centered modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title container" i18n>Replicar as configurações em:</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body" #container>
        <div class="row">
          <div class="col-12">
            <mat-checkbox i18n color="primary" (change)="allClick()" [checked]="values.length === 7">Todos</mat-checkbox>
          </div>
          <form [formGroup]="weekForm">
          <div class="col-12" *ngFor="let day of weekdays">
            <mat-checkbox i18n color="primary" [formControlName]="day">{day, select,
              sunday{Domingo} monday{Segunda} tuesday{Terça} wednesday{Quarta} thursday{Quinta} friday{Sexta}
              saturday{Sábado}}
            </mat-checkbox>
          </div>
        </form>
        </div>
    </div>
    <div class="modal-footer">
      <button (click)="onCancel()" class="btn btn-outline-secondary">Cancelar</button>
      <button (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">Confirmar</button>
    </div>
  </div>
</div>
