import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClosedModalError } from 'src/app/core/errors';
import { Contract, Lot, StatusLot, Situation, TypeLot, User, AlertItem,
  AlertType, ComponentModal, PreProcessSettings, Samplings } from 'src/app/core/models';
import { AlertService, ContractService, LotService, ModalService, UserGroupService,
  UserService, PreProcessSettingsService, SamplingsService, StorageKey, StorageService  } from 'src/app/core/services';
import { LoteAuditInfoComponent } from 'src/app/modals/lote-audit-info/lote-audit-info.component';
@Component({
  selector: 'app-audit-lots',
  templateUrl: './audit-lots.component.html',
  styleUrls: ['./audit-lots.component.sass']
})

export class AuditLotsComponent implements OnInit {
  public lots: Array<Lot> = [];
  public columns = [
    'special',
    'priority',
    'status',
    'lot',
    'situation',
    'review',
    'samplingPlan',
    'violationTotal',
    'violationSample',
    'violationAudited',
    'violationDisapproved',
    'violationPending',
    'responsibleAuditUser',
    'blockedLot',
    'actions'
  ];
  public columnsToShow = [];
  public params: any = {};
  public statusLot = Object.keys(StatusLot);
  public statusLotI18n = [];
  public priorities = [
    'lowPriority',
    'mediumPriority',
    'highPriority'
  ];
  public prioritiesI18n = [];
  public priority = 'low';
  public situation = Object.keys(Situation);
  public situationI18n = [];
  public users: Array<User> = [];
  public usersById: { [params: string]: User } = {};
  public userGroups = [];
  public statusI18n: any;
  public typeLot = Object.keys(TypeLot);
  public typeLotI18n = [];
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public contract: Contract = new Contract();
  public modelChanged: Subject<string> = new Subject<string>();
  public searchForm: FormGroup;
  public contractId;
  public isLoading: boolean;
  public preProcessSettings: PreProcessSettings;
  public isNqa: number;
  public isLotAmount: number;
  public userEditForm: FormGroup;
  public currentPermissions = [];
  public user: User;
  public changesPermited = false;
  public samplings: any[] = [];

  constructor(
    public lotService: LotService,
    public activateRoute: ActivatedRoute,
    private alertService: AlertService,
    private contractService: ContractService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private userGroupService: UserGroupService,
    private preProcessSettingsService: PreProcessSettingsService,
    private samplingsService: SamplingsService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  async ngOnInit() {
    this.isLoading = true;
    this.createForm();
    this.contractId = Number(this.activateRoute.snapshot.params.contractId);
    this.columnsToShow = this.columns.slice(0);
    this.params = {
      contractId: `${this.contractId}`,
      step: 'internalAudit'
    };
    this.user = this.storageService.get(StorageKey.currentUser);
    if (!this.user.superUser) {
      const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
      this.currentPermissions = currentPermissions &&
        currentPermissions.filter(p =>
          (p.actionIds.indexOf('ManageLot') >= 0 || p.actionIds.indexOf('AuditViolation') >= 0 ||
            p.actionIds.indexOf('ReviewLot') >= 0) && p.contractId === this.contractId);
      if (this.currentPermissions.length === 0) {
        this.params.targetAuditorId = this.user.id;
      } else {
        this.changesPermited = true;
      }
    }else{
      this.changesPermited = true;
    }

    this.statusI18n = {process: 'Processando', closed: 'Disponível', done: 'Auditado'};
    this.contractService.getById(this.contractId).then(contract => this.contract = contract);
    await this.createSearchForm();
    await this.userGroupService.list({contractId: this.contractId}).then(res => {
      this.userGroups = res.result;
    });
    await this.userService.getAll({'groupIds[in]': `[${this.userGroups.map(group => group.id).join()}]`}).then(res => {
      res.map(user => {
        if (user.name) {
          this.users.push(user);
          this.usersById[user.id] = user;
        }
      });
    });
    await this.preProcessSettingsService.getById(this.route.snapshot.params.contractId).then(res => {
      this.preProcessSettings = res;
      this.isNqa = res?.audit?.acceptableQualityLevel || 0;
    });
    await this.samplingsService.getSamplings({ acceptableQualityLevel: `${this.isNqa}` }).subscribe(async (res: any[]) => {
      this.samplings = res;
    });
  }

  createForm() {
    this.userEditForm = this.formBuilder.group({
      name: ['']
    });
  }

  loadingEvent(valueEmitted) {
    this.isLoading = valueEmitted;
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: [''],
      type: [''],
      status: [''],
      situation: [''],
      priority: [''],
      user: []
    });
  }


  searchKey(text: string) {
    this.modelChanged.next(text);
  }
  handleSearch() {
    const filterObj: any = {};
    const search = this.searchForm.get('search').value;
    const type = this.searchForm.get('type').value;
    const situation = this.searchForm.get('situation').value;
    const status = this.searchForm.get('status').value;
    const user = this.searchForm.get('user').value;
    const priority = this.searchForm.get('priority').value;
    if (search != null && search !== '') {
      filterObj['name[contains,or]'] = `${search}`;
    }
    if (situation !== '' && situation.length > 0) {
      filterObj['situation[contains,in]'] = `[${situation}]`;
    }
    if (type !== '' && type.length > 0) {
      filterObj['type[contains,in]'] = `[${type}]`;
    }
    if (status !== '' && status.length > 0) {
      filterObj['status[contains,in]'] = `[${status}]`;
    }
    if (user !== '' && user?.length > 0) {
      filterObj['targetAuditorId[in]'] = `[${user}]`;
    }
    if (priority !== '' && priority.length > 0) {
      priority['priority[contains,in]'] = `[${priority}]`;
    }
    filterObj.sortAsc = 'false';
    this.params = {
      contractId: `${this.contractId}`,
      ...filterObj
    };
  }
  async setSpecial(lot: Lot) {
    const data = lot;
    let message = '';
    if (data.isSpecial) {
      data.isSpecial = false;
      message = 'AuditLotNotSpecial';
    } else {
      data.isSpecial = true;
      message = 'AuditLotSpecial';
    }
    await this.lotService.update(data).then(res => {
      this.alertService.show(
        new AlertItem(message, AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('AuditLotSpecialError', AlertType.danger)
      );
      data.isSpecial = false;
    });
  }

  async setActive(lot: Lot) {
    let message = '';
    if (lot.enabled) {
      lot.enabled = false;
      message = 'DisabledLot';
    } else {
      lot.enabled = true;
      message = 'EnabledLot';
    }
    this.lotService.update(lot).then(res => {
      this.alertService.show(
        new AlertItem(message, AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('ErrorEditLot', AlertType.danger)
      );
      lot.enabled = !lot.enabled;
    });
  }

  openAuditView(id: number) {
    this.modalService
      .show(new ComponentModal(LoteAuditInfoComponent, {lotId: id }))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  async setValue(lot: Lot, event: any) {
    this.isLotAmount = lot.violationsAmount;
    if (event.target.value === 'simple') {
      const findLevel = this.samplings.find(s => this.isLotAmount >= s.minAmountViolations && this.isLotAmount <= s.maxAmountViolations);
      lot.totalSampling = findLevel.amountSampling;
    } else {
      lot.totalSampling = 0;
    }
  }

  redirectToViolations(lotId: number, allowEditAudit: boolean, lot: Lot){
    if (allowEditAudit) {
      lot.status = 'process';
      this.lockLotForAuditor(lot);
    }
    this.router.navigateByUrl('pre-process/internalAudit/' + this.contractId + '/' + lotId, {
      state: { allowEditAudit }
     });
  }

  setPrioritiesI18n(priorities) {
    this.prioritiesI18n = priorities;
  }

  setLots(list) {
    this.lots = list;
  }

  getPriority(lot) {
    return lot.priority || this.priority;
  }
  async setPriority(lot, priority) {
    const data = lot;
    this.priority = priority;
    data.priority = priority;
    let message = '';
    switch (priority) {
      case 'low':
        message = 'AuditLotPriorityLow';
        break;
      case 'medium':
        message = 'AuditLotPriorityMedium';
        break;
      case 'high':
        message = 'AuditLotPriorityHigh';
        break;
    }
    await this.lotService.update(data).then(res => {
      this.alertService.show(
        new AlertItem(message, AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('AuditLotPriorityError', AlertType.danger)
      );
    });
  }

  setResponsibleUser(event: any, lot: Lot) {
    const data = lot;
    if (event.target.value === '') {
      return;
    } else {
      data.targetAuditorId = Number(event.target.value);
    }
    if (data.lockUserId > 0) {
      data.lockUserId = 0;
    }
    if (data.priority === '') {
      delete data.priority;
    }
    this.lotService.update(data, false).then(res => {
      if (res.targetAuditorId === Number(event.target.value)) {
        this.alertService.show(
          new AlertItem('SetUserInAuditLot', AlertType.success)
        );
      }
    }).catch(err => {
      if (err.status === 403) {
        this.alertService.show(
          new AlertItem('AuditLotUserWithoutPermission', AlertType.danger)
        );
      }
    });
  }

  setBlockedLot(lot: Lot, block = false) {
    const data = lot;
    let message = '';
    const now = new Date();
    if (data.lockUserId === data.targetAuditorId && (['process','closed'].includes(data.status))) {
      data.lockUserId = 0;
      data.lockDate = new Date(null);
      localStorage.removeItem('auditLockUser');
      message = 'AuditLotUnlocked';
    } else
    if (data.targetAuditorId > 0) {
      data.lockUserId = data.targetAuditorId;
      data.lockDate = now;
      localStorage.setItem('auditLockUser', 'true');
      message = 'AuditLotLocked';
    } else {
      this.alertService.show(
        new AlertItem('SelectAuditLotUser', AlertType.danger)
      );
      return;
    }
    if (data.priority === '') {
      delete data.priority;
    }
    this.lotService.update(data).then(res => {
      this.alertService.show(
        new AlertItem(message, AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('AuditLotLockedError', AlertType.danger)
      );
      localStorage.removeItem('auditLockUser');
    });
  }

  lockLotForAuditor(lot) {
    const data = lot;
    data.lockUserId = data.targetAuditorId;
    data.lockDate = new Date();
    this.lotService.update(data).then(res => {
      localStorage.setItem('auditLockUser', 'true');
      this.alertService.show(
        new AlertItem('AuditLotLocked', AlertType.success)
      );
    }).catch(err => {
      this.alertService.show(
        new AlertItem('AuditLotLockedError', AlertType.danger)
      );
      localStorage.removeItem('auditLockUser');
    });
  }
}
