<div class="loading-page" *ngIf="isLoading">
  <div class="loading-spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</div>
<app-quarantine-header [quarantine]="quarantine" [layer]="quarantineReason" *ngIf="!isLoading"></app-quarantine-header>
<app-enum-i18n [input]="resources" (output)="translatedResources=$event"></app-enum-i18n>
<app-enum-i18n [input]="days" (output)="daysI18n=$event"></app-enum-i18n>

<div class="container">
  <div class="mt-4">
    <form [formGroup]=searchForm>
      <div class="row justify-content-between">
        <div class="col filter-vertical">
          <label i18n>Filtrar por período:</label><br />
          <div class="btn-group">
            <button type="button" [ngClass]="{'btn': true, 'btn-secondary': periodFilter === '6 months'}"
              (click)="fillPeriod('6 months')" i18n>6 meses</button>

            <button type="button" [ngClass]="{'btn': true, 'btn-secondary': periodFilter === '3 months'}"
              (click)="fillPeriod('3 months')" i18n>3 meses</button>

            <button type="button" [ngClass]="{'btn': true, 'btn-secondary': periodFilter === '1 months'}"
              (click)="fillPeriod('1 months')" i18n>30 dias</button>

            <button type="button" [ngClass]="{'btn': true, 'btn-secondary': periodFilter === '15 days'}"
              (click)="fillPeriod('15 days')" i18n>15 dias</button>

            <button type="button" [ngClass]="{'btn': true, 'btn-secondary': periodFilter === '7 days'}"
              (click)="fillPeriod('7 days')" i18n>7 dias</button>

            <button type="button" [ngClass]="{'btn': true, 'btn-secondary': periodFilter === '0 days'}"
              (click)="fillPeriod('0 days')" i18n>Hoje</button>
          </div>
        </div>

        <div class="col">
          <label i18n>Período personalizado</label>
          <div class="d-flex justify-content-between">
            <div class="input-group input-group--adjust mr-3">
              <div class="input-group-prepend">
                <div class="input-group-text" i18n>De</div>
              </div>
              <app-input type="date" class="input-group--input-prepend" formControlName="startsAt"></app-input>
            </div>

            <div class="input-group input-group--adjust mr-3">
              <div class="input-group-prepend">
                <div class="input-group-text" i18n>Até</div>
              </div>
              <app-input type="date" class="input-group--input-prepend" formControlName="endsAt"></app-input>
            </div>

            <button i18n [ngClass]="{btn: true, 'btn-info': true, disabled: !isValid}" (click)="handleSearch()"
              data-dismiss="modal">Aplicar</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="mt-5">
    <table class="table table-hover table-block">
      <thead>
        <tr>
          <th class="text-center">
            <mat-checkbox i18n color="primary" [disabled]="isQuarantineProcess" (change)="allClick($event.checked)"
              [checked]="isCheckedAll">
            </mat-checkbox>
          </th>
          <th i18n>Data/Hora da Infração</th>
          <th i18n>Enquadramento</th>
          <th i18n class="text-center" class="text-center" *ngIf="columnsToShow.includes('plate')">Placa</th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('lane')">
            Configuração do Sistema
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('serialNumber')">
            Série do equipamento
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('equipmentModel')">
            Modelo do Equipamento
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('calibrationType')">
            Tipo de Aferição
          </th>
          <th i18n class="text-center td-bg" *ngIf="columnsToShow.includes('calibrationNumber')">
            Nº do laudo
          </th>
          <th i18n class="text-center td-bg" *ngIf="columnsToShow.includes('calibrationDate')">
            Data da Aferição
          </th>
          <th class="text-center td-bg" *ngIf="columnsToShow.includes('appraisalNumberComparison')">
            <div i18n>Nº do laudo</div>
            <div class="row">
              <div i18n class="col-6 th-custom">Enviado pelo equipamento</div>
              <div i18n class="col-6 th-custom">Cadastrado no sistema</div>
            </div>
          </th>
          <th class="text-center td-bg" *ngIf="columnsToShow.includes('appraisalDateComparison')">
            <div i18n>Data da Aferição</div>
            <div class="row">
              <div i18n class="col-6 th-custom">Enviado pelo equipamento</div>
              <div i18n class="col-6 th-custom">Cadastrado no sistema</div>
            </div>
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('calibrationEndsAt')">
            Expirada em
          </th>
          <th i18n class="text-center no-space" *ngIf="columnsToShow.includes('vehicleType')">
            Tipo do Veículo
          </th>
          <th i18n class="text-center no-space" *ngIf="columnsToShow.includes('speedLimit')">
            <span>Velocidade Permitida <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                data-placement="top" appTooltip="Velocidade permitida na via, cadastrada no sistema">info</i></span>
          </th>
          <th i18n class="text-center no-space" *ngIf="columnsToShow.includes('laneSpeed')">
            <span>Faixa de Velocidade <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                data-placement="top"
                appTooltip="Faixa de velocidade calculada a partir da velocidade permitida, cadastrada no sistema">info</i></span>
          </th>
          <th i18n class="text-center no-space" *ngIf="columnsToShow.includes('calculatedSpeed')">
            <span>Velocidade Considerada <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                data-placement="top"
                appTooltip="Velocidade tolerada na via a partir da velocidade registrada no equipamento">info</i></span>
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('whiteListRecordNumber')">
            Nº do Ofício
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('whiteListDate')">
            Período Cadastrado no Sistema
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('exemptPeriodReason')">
            Motivo de Isenção
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('exemptPeriodDate')">
            Período Cadastrado no Sistema
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('zoom')">
            Zoom
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('zoom2')">
            Zoom 2
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('pan')">
            Panorâmica 1
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('pan2')">
            Panorâmica 2
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('video')">
            Video 1
          </th>
          <th i18n class="text-center" *ngIf="columnsToShow.includes('video2')">
            Video 2
          </th>
          <th class="text-center td-bg" *ngIf="columnsToShow.includes('laneNumber')">
            <div i18n>Número da Faixa</div>
            <div class="row">
              <div i18n class="col-6 th-custom">Enviado pelo equipamento</div>
              <div i18n class="col-6 th-custom">Cadastrado no sistema</div>
            </div>
          </th>
          <th class="text-center td-bg" *ngIf="columnsToShow.includes('equipmentSerialNumber')">
            <div i18n>Número de série do equipamento</div>
            <div class="row">
              <div i18n class="col-6 th-custom">Enviado pelo equipamento</div>
              <div i18n class="col-6 th-custom">Cadastrado no sistema</div>
            </div>
          </th>
          <th class="text-center td-bg" *ngIf="columnsToShow.includes('operationPeriod')">
            <div i18n>Período de Ativação</div>
          </th>
          <th class="text-center td-bg" *ngIf="columnsToShow.includes('vehicleClassification')">
            <div i18n>Classificação Veícular</div>
            <div class="row">
              <div i18n class="col-6 th-custom">Enviado pelo Equipamento</div>
              <div i18n class="col-6 th-custom">Cadastrado no Sistema</div>
            </div>
          </th>
          <th class="text-center td-bg" *ngIf="columnsToShow.includes('violationLimitvehicleClassification')">
            <div i18n>Classificação</div>
          </th>
          <th class="text-center td-bg" *ngIf="columnsToShow.includes('violationLimitPeriod')">
            <div i18n>Período Cadastrado no Sistema</div>
          </th>
          <th i18n class="text-center no-space" *ngIf="columnsToShow.includes('discrepantVehicleType')">
            Tipo do Veículo
          </th>
          <th i18n class="text-center no-space" *ngIf="columnsToShow.includes('discrepantSpeedLimit')">
            <span>Velocidade Permitida <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                data-placement="top" appTooltip="Velocidade permitida na via, cadastrada no sistema">info</i></span>
          </th>
          <th i18n class="text-center no-space" *ngIf="columnsToShow.includes('discrepantCalculatedSpeed')">
            <span>Velocidade Considerada <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                data-placement="top"
                appTooltip="Velocidade tolerada na via a partir da velocidade registrada no equipamento">info</i></span>
          </th>
          <th i18n class="text-center no-space" *ngIf="columnsToShow.includes('discrepantSpeed')">
            <span>Velocidade Registrada <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                data-placement="top" appTooltip="Velocidade registrada na via, pelo infrator">info</i></span>
          </th>
        </tr>
      </thead>
      <div class="loading-page" *ngIf="isLoading">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <tbody *ngIf="!isLoading">
        <tr *ngFor="let violation of violations">
          <td class="text-center">
            <mat-checkbox i18n color="primary" [disabled]="isQuarantineProcess"
              [checked]="violationsSelected[violation.id]?.isChecked"
              (change)="selectViolation($event.checked, violation.id)">
            </mat-checkbox>
          </td>
          <td>
            {{ violation.date | moment: 'L LTS' }}
          </td>
          <td>
            {{regulationsMap[violation.regulationId]?.code}}
            <a class="align-regulation-description" i18n>
              {{regulationsMap[violation.regulationId]?.shortDescription}}
            </a>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('plate')">
            {{violation?.vehicle?.plateOCR || ' - '}}
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('lane')">
            <span *ngIf="columnsToShow.includes('invalidDayPeriod'); else invalidWeekDay">
              <div *ngFor="let laneConfig of enabledViolations[getDay(violation.date)]">
                {{laneConfig.startTime + ' - ' + laneConfig.endTime}}
              </div>
            </span>
            <ng-template #invalidWeekDay>
              <span *ngIf="enabledViolations[violation?.vehicle?.plateOCR?.slice(-1)]  ; else plateNotFound">
                <div *ngFor="let laneConfig of enabledViolations[violation?.vehicle?.plateOCR?.slice(-1)]">
                  <span i18n>
                    Final de Placa: {{(laneConfig.plateRegex ? getLanePlateDigit(laneConfig.plateRegex) : 'Todos') + ' /
                    ' }}
                    {{ daysI18n[laneConfig.weekdays[0]].value }}
                  </span>
                </div>
              </span>
              <ng-template #plateNotFound>
                Final de Placa: {{'Todos' + ' / ' + daysI18n[6].value + ', ' + daysI18n[0].value }}
              </ng-template>
            </ng-template>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('serialNumber')">
            {{violation?.equipment?.serialNumber}}
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('equipmentModel')">
            {{manufacturerByIds && manufacturerByIds[violation?.equipment?.manufacturerId]?.name + ' - ' +
            violation?.equipment?.modelName}}
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('calibrationType')">
            {{getCalibrationType(violation).value}}
          </td>
          <td class="text-center td-bg" *ngIf="columnsToShow.includes('calibrationNumber')">
            {{(calibrationsByViolationId[violation.id] && calibrationsByViolationId[violation.id][0] &&
            calibrationsByViolationId[violation.id][0]?.appraisal?.number) || '-' }}
          </td>
          <td class="text-center td-bg" *ngIf="columnsToShow.includes('calibrationDate')">
            {{violation?.equipment?.calibration?.date | moment: 'L'}}
          </td>
          <td class="text-center td-bg" *ngIf="columnsToShow.includes('appraisalNumberComparison')">
            <span class="td-custom"> {{violation?.equipment?.calibration?.appraisalNumber}}</span>
            <span class="td-custom"> {{calibrationsByViolationId[violation.id] &&
              calibrationsByViolationId[violation.id][0]?.appraisal?.number}}
            </span>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('appraisalDateComparison')">
            <div class='row'>
              <div class='col-6 td-custom'>
                {{(violation?.equipment?.calibration?.date | moment) || '-'}}
              </div>
              <div class='col-6 td-custom'>
                <span *ngIf="!isLoadingDate">{{calibrationsByViolationId[violation.id] &&
                  (calibrationsByViolationId[violation.id][0]?.date | moment) || '-'}}</span>
                <div class="loading-page p-0" *ngIf="isLoadingDate">
                  <div class="loading-spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('calibrationEndsAt')">
            {{calibrationsByViolationId[violation.id] &&
            (calibrationsByViolationId[violation.id][0]?.endsAt | moment: 'L')}}
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('vehicleType')">
            <div i18n *ngIf="violation?.vehicle?.size < 4; else heavyVehicle">Leve</div>
            <ng-template #heavyVehicle>
              <div i18n>Pesado</div>
            </ng-template>
          </td>
          <td class="text-center primary" *ngIf="columnsToShow.includes('speedLimit')">
            {{ getSpeedLimit(violation) }} <span i18n>km/h</span>
          </td>
          <td class="text-center primary" *ngIf="columnsToShow.includes('laneSpeed')">
            {{ limits?.initialSpeed }}
            <span i18n>à</span>
            {{ limits?.finalSpeed }}
            <span i18n>km/h</span>
          </td>
          <td class="text-center danger" *ngIf="columnsToShow.includes('calculatedSpeed')">
            {{ violation?.calculatedSpeed }} <span i18n>km/h</span>
          </td>
          <td class="text-center td-bg" *ngIf="columnsToShow.includes('whiteListRecordNumber')">
            {{ whiteListByViolationId[violation.id]?.recordNumber }}
          </td>
          <td class="text-center td-bg" *ngIf="columnsToShow.includes('whiteListDate')">
            {{ whiteListByViolationId[violation.id]?.startsAt | moment: 'LLL' }} <span i18n>à</span>
            {{ whiteListByViolationId[violation.id]?.endsAt | moment: 'LLL' }}
          </td>
          <td class="text-center td-bg" *ngIf="columnsToShow.includes('exemptPeriodReason')">
            {{ exemptPeriodByViolationId[violation.id]?.name}}
          </td>
          <td class="text-center td-bg" *ngIf="columnsToShow.includes('exemptPeriodDate')">
            {{ exemptPeriodByViolationId[violation.id]?.startsAt | moment: 'L LTS' }} <span i18n>à</span>
            {{ exemptPeriodByViolationId[violation.id]?.endsAt | moment: 'L LTS' }}
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('zoom')">
            <div [ngSwitch]="verifyRequiredFiles('zoom', violation)">
              <i class="material-icons primary" *ngSwitchCase="true">check</i>
              <i class="material-icons danger" *ngSwitchCase="false">close</i>
              <i *ngSwitchCase="">-</i>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('zoom2')">
            <div [ngSwitch]="verifyRequiredFiles('zoom2', violation)">
              <i class="material-icons primary" *ngSwitchCase="true">check</i>
              <i class="material-icons danger" *ngSwitchCase="false">close</i>
              <i *ngSwitchCase="">-</i>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('pan')">
            <div [ngSwitch]="verifyRequiredFiles('pan', violation)">
              <i class="material-icons primary" *ngSwitchCase="true">check</i>
              <i class="material-icons danger" *ngSwitchCase="false">close</i>
              <i *ngSwitchCase="">-</i>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('pan2')">
            <div [ngSwitch]="verifyRequiredFiles('pan2', violation)">
              <i class="material-icons primary" *ngSwitchCase="true">check</i>
              <i class="material-icons danger" *ngSwitchCase="false">close</i>
              <i *ngSwitchCase="">-</i>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('video')">
            <div [ngSwitch]="verifyRequiredFiles('video', violation)">
              <i class="material-icons primary" *ngSwitchCase="true">check</i>
              <i class="material-icons danger" *ngSwitchCase="false">close</i>
              <i *ngSwitchCase="">-</i>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('video2')">
            <div [ngSwitch]="verifyRequiredFiles('video2', violation)">
              <i class="material-icons primary" *ngSwitchCase="true">check</i>
              <i class="material-icons danger" *ngSwitchCase="false">close</i>
              <i *ngSwitchCase="">-</i>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('laneNumber')">
            <div class="row">
              <span class="col-6 td-custom"> {{ violation?.laneNumber }}</span>
              <span class="col-6 td-custom"> {{ quarantine?.number }} </span>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('equipmentSerialNumber')">
            <div class="row">
              <span class="col-6 td-custom"> {{ violation?.equipment?.serialNumber || '-' }}</span>
              <span class="col-6 td-custom"> {{ equipmentSerialNumberById[violation?.equipment?.id] || '-' }} </span>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('operationPeriod')">
            {{ settingByregulationId[violation?.regulationId]?.startsAt | moment: 'L' }} á {{
            settingByregulationId[violation?.regulationId]?.endsAt | moment: 'L' }}
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('vehicleClassification')">
            <div class="row">
              <span class="col-6 td-custom"> {{
                classificationByCode[violation?.vehicle?.classificationCode]?.description || '-' }}</span>
              <span class="col-6 td-custom"> {{
                classificationByCode[getClassificationCode(violation?.regulationId)]?.description || '-' }} </span>
            </div>
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('violationLimitvehicleClassification')">
            {{ classificationByCode[violation?.vehicle?.classificationCode]?.description|| '-' }}
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('violationLimitPeriod')">
            {{ violationPeriodsByRegulationId[violation?.regulationId]?.periods[0]?.startTime || '-' }} à {{
            violationPeriodsByRegulationId[violation?.regulationId]?.periods[0]?.endTime || '-' }}
          </td>
          <td class="text-center" *ngIf="columnsToShow.includes('discrepantVehicleType')">
            <div i18n *ngIf="violation?.vehicle?.size < 4; else heavyVehicle">Leve</div>
            <ng-template #heavyVehicle>
              <div i18n>Pesado</div>
            </ng-template>
          </td>
          <td class="text-center primary" *ngIf="columnsToShow.includes('discrepantSpeedLimit')">
            {{ getSpeedLimit(violation) }} <span i18n>km/h</span>
          </td>
          <td class="text-center danger" *ngIf="columnsToShow.includes('discrepantCalculatedSpeed')">
            {{ violation?.calculatedSpeed }} <span i18n>km/h</span>
          </td>
          <td class="text-center danger" *ngIf="columnsToShow.includes('discrepantSpeed')">
            {{ violation?.speed }} <span i18n>km/h</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex justify-content-end my-4" *appPermission="['QuarantineManage']">
    <button class="btn btn-secondary mr-3" [disabled]="!isChecked" *ngIf="quarantineReason !== 'spotMissing'"
      (click)="openQuarantineModalAction('nextStep')" i18n>
      <app-loading [promise]="promise" text="Avançar" i18n-text></app-loading>
    </button>
    <button class="btn btn-danger mr-3" [disabled]="!isChecked" *ngIf="quarantineReason !== 'spotMissing'"
      (click)="openQuarantineModalAction('invalidate')" i18n>
      <app-loading [promise]="promise" text="Invalidar" i18n-text></app-loading>
    </button>
    <button class="btn btn-primary" [disabled]="!isChecked" (click)="openQuarantineModalAction('reprocess')" i18n>
      <app-loading [promise]="promise" text="Reprocessar" i18n-text></app-loading>
    </button>
  </div>

  <hr />

  <div *ngIf="quarantine?.code">
    <app-pagination (items)="setViolations($event)" [params]="params" [deleteEvent]="deleteEvent"
      [service]="violationService">
    </app-pagination>
  </div>
</div>
