import { Component, OnInit, EventEmitter, Input, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-enum-i18n',
  templateUrl: './enum-i18n.component.html',
  styleUrls: ['./enum-i18n.component.sass']
})
export class EnumI18nComponent implements AfterViewInit {
  @Input() input = [];
  @Output() output: EventEmitter<Array<any>> = new EventEmitter();
  @ViewChild('items', { static: true, read: ElementRef }) items: ElementRef;

  constructor() { }

  ngAfterViewInit() {
    const children = (this.items.nativeElement as HTMLDivElement).children;
    const translated = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < children.length; i++) {
      const child = children[i] as HTMLDivElement;
      translated.push({
        id: child.title,
        value: child.textContent
      });
    }
    // set timeout so the event is not in the life cycle
    setTimeout(() => this.output.emit(translated), 1);
  }
}
