<div class="card-header">
  <div class="row d-flex justify-content-between">
    <div class="col">
      <h5 i18n class="my-2 font-weight-bold">Limite de Infrações</h5>
    </div>

    <div class="col-auto d-flex justify-content-end">
      <button class="btn btn-fade-primary" (click)="createForm()" i18n>Nova Configuração</button>
    </div>
  </div>
</div>

<div class="row d-flex justify-content-between">
  <div class="col">
    <p class="ml-4 mt-4">
      Caso exista limitação para a quantidade de infrações de um mesmo enquadramento em um período, crie uma nova
      configuração.
      <span class="material-icons icon-sm text-secondary" i18n-appTooltip appTooltip="Para o período cadastrado o sistema irá considerar apenas uma infração,
      as demais infrações detectadas para o mesmo veículo neste mesmo período serão automaticamente invalidadas.
      Por exemplo: infrações de rodízio ou zona de restrição de caminhões.">info</span>
    </p>
  </div>
</div>

<div class="card-body">
  <div class="toggle-panel card card-bordered mt-4" *ngFor="let form of violationLimitForms; index as i">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="mb-0">{{ regulationsMap[form.selectionForm.get('regulationId').value] &&
        regulationsMap[form.selectionForm.get('regulationId').value].prettyName }}</h5>
      <button class="btn btn-icon-block text-secondary" (click)="removeViolationLimit(i)">
        <i class="material-icons" i18n-appTooltip appTooltip="Remover configuração">remove_circle</i></button>
    </div>
    <div class="card-body">
      <form [formGroup]="form.selectionForm">
        <div class="row">
          <div class="col">
            <app-input i18n-label type="select" label="Enquadramento" placeholder="Selecione o enquadramento"
                       inputRequired=true [list]="regulations" formControlName="regulationId" selectValue="id"
                       selectText="prettyName">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-input i18n-label
              inputRequired=true
              type="multiselect"
              class="input-multiselect"
              label="Classificação veicular"
              placeholder="Tipo de veículo"
              [list]="classifications"
              formControlName="classificationIds"
              selectValue="id"
              selectShowLimit="1"
              selectText="description">
            </app-input>
          </div>
          <div class="col">
            <app-input i18n-label i18n-placehorlder type="select" label="Motivo de invalidação"
              placeholder="Selecione o motivo de Invalidação" inputRequired=true [list]="reasons"
              formControlName="reasonId" selectValue="id" selectText="prettyName">
            </app-input>
          </div>
        </div>
      </form>
      <div class="card-divider"></div>
      <div class="mt-3 row">
        <div class="col-3">
          <app-input i18n-label
            type="select"
            label="Condição de limite"
            selectValue="v"
            selectText="t"
            placeholder="Selecione o tipo"
            [list]="conditionPeriod"
            [ngModelChange]="toggle()"
            [(ngModel)]="form.isTime">
          </app-input>
        </div>
        <div class="col-9">
          <form [formGroup]="period" *ngFor="let period of form.periodForms; index as j">
            <div class="row align-items-center  mt-3" *ngIf="j === 0 && !!form.isTime">
              <div class="col-2">
                <span class="font-weight-bold" style="font-size: small;" i18n>Minutos</span>
              </div>
              <div class="col-4">
                <app-input
                  type="number"
                  class="no-margin hour-control"
                  formControlName="time"
                  hasLabel="false"
                  [textValue]="period.get('time').value">
                </app-input>
              </div>
            </div>
            <div class="row align-items-center mt-3 justify-content-between" *ngIf="!form.isTime">
              <div class="col-2">
                <span class="font-weight-bold" style="font-size: small;" i18n>Período {{ j + 1 }}</span>
              </div>
              <div class="col-4">
                <app-input
                  class="no-margin hour-control"
                  format="dd:dd:dd"
                  formControlName="startHour"
                  hasLabel="false"
                  [textValue]="period.get('startHour').value"
                  leftUnit="Início">
                </app-input>
              </div>
              <div class="col-4">
                <app-input
                  class="no-margin"
                  format="dd:dd:dd"
                  formControlName="endHour"
                  hasLabel="false"
                  [textValue]="period.get('endHour').value"
                  leftUnit="Fim">
                </app-input>
              </div>
              <div class="col-2 d-flex">
                <div *ngIf="form.periodForms.length > 1">
                  <button (click)="removePeriodForm(i, j)" class="btn btn-icon-block text-danger">
                    <i class="material-icons">remove_circle</i>
                  </button>
                </div>
                <div *ngIf="(form.periodForms.length - 1) === j">
                  <button (click)="createNewPeriodForm(i)" class="btn btn-icon-block text-success">
                    <i class="material-icons">add_circle</i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
