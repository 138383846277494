import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertItem, AlertType, User } from 'src/app/core/models';
import { AlertService, ModalService, UserService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.sass']
})
export class ChangePasswordModalComponent extends BaseModal implements OnInit {
  public changePasswordForm: FormGroup;
  public invalidPassword = false;
  public isValid = false;
  public initialState: User;
  public user: User;
  public promise: Promise<any>;

  get lastState() {
    return null;
  }

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private userService: UserService,
    modalService: ModalService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.user = this.initialState;
    this.createForm();
  }

  createForm(): void {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      passwordConfirmation: ['', [Validators.required]],
      oldPassword: ['', [Validators.required]]
    });
    this.changePasswordForm.valueChanges.subscribe(() => {
      this.isValid = false;
      const oldPassword = this.changePasswordForm.get('oldPassword').value;
      const password = this.changePasswordForm.get('password').value;
      const passwordConfirmation = this.changePasswordForm.get('passwordConfirmation').value;
      if (oldPassword != null && password === passwordConfirmation) {
        this.isValid = this.changePasswordForm.valid;
      }
    });
  }

  public async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    const oldPassword = this.changePasswordForm.get('oldPassword').value;
    const password = this.changePasswordForm.get('password').value;
    const copyUser = Object.assign(this.user);
    copyUser.newPassword = password;
    copyUser.password = oldPassword;
    delete copyUser.accessToken;
    delete copyUser.refreshToken;
    this.promise = this.userService.update(copyUser).then(async res => {
      this.alertService.show(new AlertItem('PasswordSaved', AlertType.success));
      return res;
    }).catch(async error => {
      await this.alertService.show(new AlertItem('PasswordSaveError', AlertType.danger));
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }
}
