import { BaseApiModel } from './BaseApiModel';

export class Company extends BaseApiModel {
    public name: string;
    public logo: string;
    public contractIds: Array<string>;
    public enabled: boolean;
    public modifiedAt: Date;
    public createdAt: Date;
    public extraData: any;
}
