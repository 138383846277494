import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PreViolation } from '../models';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PreViolationService extends BaseApiService<PreViolation> {
  get cacheKey(): StorageKey {
    return StorageKey.preViolationService;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/preViolations`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  accept(request: PreViolation) {
    return this.http.put(`${environment.apiUrl}/preViolations/${request.id}/accept`, request).pipe().toPromise();
  }

  verifyAccept(request: PreViolation) {
    return this.http.put(`${environment.apiUrl}/preViolations/verify/${request.id}/accept`, request).pipe().toPromise();
  }

  unlockUser(userId: number) {
    return this.http.put(`${environment.apiUrl}/preViolations/unlock`, { userId }).pipe().toPromise();
  }
  unlockPreViolation(violationIds: Array<number> = []) {
    return this.http.put(`${environment.apiUrl}/preViolations/unlock`, { violationIds }).pipe().toPromise();
  }

  refuse(request: PreViolation) {
    return this.http.put(`${environment.apiUrl}/preViolations/${request.id}/refuse`, request).pipe().toPromise();
  }

  protected unparse(obj) {
    return PreViolation.unparse(obj);
  }

  protected parse(obj) {
    return PreViolation.parse(obj);
  }
}
