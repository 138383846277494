<app-enum-i18n [input]="modules" (output)="modulesI18n=$event" ></app-enum-i18n>

<div class="container">
  <div class="page-title">
    <h2 i18n>Contratos</h2>
  </div>

  <div class="mb-4">
    <input type="text" class="form-control form-search" [(ngModel)]="searchValue"
      placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">
  </div>

  <div class="row mt-4">
    <div class="col-12 col-sm-6 col-lg-4 mb-4" *ngFor="let contract of contracts">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{ contract.code }}</h4>
          <h6 class="card-subtitle mb-0 text-secondary">{{ contract?.name }}</h6>
        </div>

        <div class="card-body">
          <p class="card-text">
            <span class="text-muted text-sm" i18n>Vigência até:</span><br/>
            {{ contract?.endsAt | moment: 'll' }}
          </p>

          <p class="card-text">
            <span class="text-muted text-sm" i18n>Módulos:</span><br/>
            {{ getModules(contract?.modules) }}
          </p>

          <p class="card-text">
            <span class="text-muted text-sm" i18n>Empresas:</span>
            <br />
            <strong i18n-appTooltip
              data-placement="top" appTooltip="Empresa responsável pelo contrato.">
              {{ contract?.company?.name }}
            </strong>
            <span *ngIf="contract.companies.length>1">
              ,&nbsp;
            </span>
            <span style="display: inline-flex;align-items: baseline;">
              <div>{{ getCompanies(contract.companies, contract.ownerCompanyId )}}</div>
            </span>
          </p>

          <div class="d-flex mt-3">
            <button (click)="openEquipmentModelPanel(contract)" class="btn btn-sm btn-fade-secondary mr-3" i18n>Fabricantes</button>
            <button [routerLink]="['/contracts/exempt-periods', contract.id]" *appPermission="'ViewExemptPeriod'; contracts contract.id"
              class="btn btn-sm btn-fade-secondary" i18n>Períodos Isentos</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loading-page">
  <app-infinite-scroll (items)="handleItems($event)" [params]="params" [service]="contractService"
  (clear)="contracts=[]" perPage="8"></app-infinite-scroll>
</div>
