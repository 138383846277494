import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalibrationsRoutingModule } from './calibrations-routing.module';
import { CalibrationsListComponent } from './calibrations-list/calibrations-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CoreModule } from 'src/app/core/core.module';
import { CalibrationViewModalComponent } from './calibration-view-modal/calibration-view-modal.component';

@NgModule({
  declarations: [
    CalibrationsListComponent,
    CalibrationViewModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    CalibrationsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  entryComponents: [
    CalibrationViewModalComponent
  ]
})
export class CalibrationsModule { }
