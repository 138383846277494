<div class="container">
  <div class="page-title" style="display: flex;justify-content: space-between;">
    <div>
      <p class="page-title__link-back" i18n><a routerLink="/pre-process">Pré-processamento</a></p>
      <h2 i18n>Triagem de infrações</h2>
    </div>
  </div>

  <div class="row">
    <div *ngIf="violationMatrix.length > 0 && !isLoading" class="col d-flex align-items-center justify-content-end p-0">
      <div class="col">
        <a class="btn btn-icon-block" [routerLink]="['/pre-process/triage/']">
          <i class="material-icons left mr-3">event_note</i>
          Filtrar
        </a>
      </div>
      <div class="col">
        <app-enum-i18n [input]="fileTypes" (output)="fileTypesI18n=$event"></app-enum-i18n>
        <app-input i18n-label type="select" [list]="fileTypesI18n" label="Tipo de arquivo" [(ngModel)]="fileType"
          selectText="value" selectValue="id"></app-input>
      </div>

      <div class="col-auto form-group">
        <label class="mb-0" i18n>Zoom</label>
        <div>
          <button (click)="zoomIn()" class="btn btn-secondary btn-icon-block mr-2"><i
              class="material-icons">zoom_in</i></button>
          <button (click)="zoomOut()" class="btn btn-secondary btn-icon-block mr-2"><i
              class="material-icons">zoom_out</i></button>
          <button (click)="plateZoom()" class="btn btn-icon-block mr-2" title="Ativar ou desativar zoom de imagem"
          [ngClass]="{'btn-secondary': disabledPlateZoom, 'btn-primary': !disabledPlateZoom}">
            <i class="material-icons">center_focus_weak</i>
          </button>
          <button (click)="selectAll()" class="btn btn-icon-block btn-secondary" title="Selecionar todas as imagens">
            <i class="material-icons">done_all</i>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="isLoading" class="d-flex flex-column align-items-center col-12 mt-3">
      <div class="loading-spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

    <div i18n *ngIf="violationMatrix.length === 0 && !isLoading" class="text-center text-secondary col-12 mt-4">
      Nenhuma imagem foi encontrada
    </div>
    <div *ngIf="violationMatrix.length === 0 && !isLoading" class="text-center text-secondary col-12 mt-5">
      <a routerLink="/pre-process/triage">
        <button class="btn btn-small btn-secondary" i18n>Filtrar Novamente</button>
      </a>
    </div>
  </div>

  <div class="violationContainer mb-3" #container>
    <div class="violationRow" *ngFor="let violationRow of violationMatrix">
      <div *ngFor="let violation of violationRow" class="violationItem">
        <div *ngIf="violation.file  ; else noImage">
          <div class="card" [ngClass]="{checked: violation.isChecked, disabled: violation.isDisabled}"
            (click)="selectViolation(violation)" (dblclick)="openImgModal(violation)">
            <app-img-glass [file]="violation.file"></app-img-glass>

            <!-- <div class="btn btn-icon-block" *ngIf="!violation.isChecked; else checkedIcon">
              <i class="material-icons">check_box_outline_blank</i>
            </div>

            <ng-template #checkedIcon>
              <div class="btn btn-icon-block">
                <i class="material-icons">check_box</i>
              </div>
            </ng-template> -->

            <!-- <button class="btn btn-icon-block pos-right" (click)="openImgModal(violation, $event)">
              <i class="material-icons">fullscreen</i>
            </button> -->
          </div>
        </div>
        <ng-template #noImage>
          <div i18n class="no-image"><span>Imagem não disponível</span></div>
        </ng-template>
      </div>
    </div>
    <div class="row" *ngIf="violationMatrix.length > 0">
      <div class="col shadow card-process-action">
        <label>
          {{ counter }} registros selecionados
        </label>
        <div *ngIf="loadingPromises" class="d-flex flex-column align-items-center col-12 mt-3">
          <div class="loading-spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
        <button (click)="openReasonModal()" class="btn btn-small btn-danger mr-2"
        [ngClass]="{disabled: !hasSelected}" *ngIf="!loadingPromises"
          i18n>Invalidar</button>
        <button (click)="onSubmit()" class="btn btn-small btn-primary" *ngIf="!loadingPromises"
        [disabled]="hasSelected"
        [ngClass]="{disabled: hasSelected}" i18n>Confirmar</button>
      </div>
    </div>
  </div>
</div>
