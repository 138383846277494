<app-enum-i18n [input]="quarantineStatus" (output)="quarantineStatusI18n=$event"></app-enum-i18n>

<div class="container">
  <div *ngIf="layer === 'root'; else noRoot">
    <div class="page-title">
      <div class="page-title__left">
        <p class="page-title__link-back" i18n><a routerLink="/pre-process">Pré-processamento</a></p>
        <h2 i18n>Quarentena</h2>
      </div>
    </div>
  </div>

  <ng-template #noRoot>
    <div class="page-title d-flex align-items-start">
      <button class="btn btn-fade-secondary mr-3" (click)="back()">
        <i class="material-icons align-button-back">arrow_back</i>
        <span i18n>Voltar</span>
      </button>

      <div>
        <p class="page-title__link-back"><a [routerLink]="['/pre-process/quarantine/' + quarantine?.contractId]" i18n>Quarentena</a></p>
        <h2 i18n>{{getStatusQuarantine(layer)}}</h2>
      </div>
    </div>

    <table class="table table-borderless table-block table-block-secondary">
      <thead>
        <tr>
          <th i18n class="text-center">Contrato</th>
          <th i18n class="text-center">Ponto</th>
          <th i18n class="text-center">Faixa</th>
          <th i18n class="text-center">Validação</th>
          <th i18n class="text-center">Quantidade de Registros</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center">
            {{ contractCode }}
          </td>
          <td class="text-center">
            {{ spotCode }}
          </td>
          <td class="text-center">
            {{ quarantine?.code }}
          </td>
          <td class="text-center">
            {{ getStatusQuarantine(layer) }}
          </td>
          <td class="text-center" id="totalViolationsQuarantine">
            {{ sumTotalQuarantines() }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</div>
