<app-enum-i18n [input]="violationsSteps" (output)="setResources($event)"></app-enum-i18n>
<app-enum-i18n [input]="quarantineStatus" (output)="quarantineStatusI18n=$event"></app-enum-i18n>

<div class="page-title d-flex align-items-start">
  <a routerLink="/violations/violations-view" class="btn btn-icon btn-fade-secondary mr-3">
    <i class="material-icons align-button-back">arrow_back</i>
    <span i18n>Voltar</span>
  </a>
  <div>
    <p class="page-title__link-back">
      <a routerLink="/violations/violations-view" i18n>Consulta de infração</a></p>
    <h2 i18n>{{ contract.code }} <span *ngIf="contractName">- {{ contract.name || '' }}</span></h2>
  </div>
</div>

<app-violations-view-filter></app-violations-view-filter>

<div class="table-flex mb-4" *ngIf="loading; else loadingBlock">
  <div class="table-flex__header">
    <div class="w-25"><app-violations-view-period></app-violations-view-period></div>
    <div class="w-100 pr-3">
      <div class="text-right" *ngIf="violations.length > 0">
        <button class="btn btn-icon btn-fade-secondary" (click)="exportCsv($event)">
          <i class="material-icons">file_download</i>
          Exportar
        </button>
      </div>
    </div>
  </div>

  <div class="table-flex__header">
    <p class="w-10" i18n>Ponto e faixa</p>
    <p class="w-5" i18n>Placa</p>
    <p class="w-10" i18n>Série</p>
    <p class="w-10" i18n>Data de cometimento</p>
    <p class="w-10" i18n>Data de recebimento</p>
    <p class="w-10" i18n>Enquadramento</p>
    <p class="w-15" i18n>Situação</p>
    <p class="w-10" i18n>Etapa</p>
    <p class="w-10" i18n>Lote</p>
    <p class="w-5" i18n>Visualizar</p>
  </div>

  <div class="table-flex__item" *ngFor="let violation of violations; let i = index">
    <div class="table-flex__row">
      <div class="w-10 pt-2">
        <p class="text-center py-0">
          <i class="material-icons text-secondary mr-1 icon-sm" data-placement="top" i18n-appTooltip
          [appTooltip]="getSpotInfo(violation.laneCode)" *ngIf="getSpotInfo(violation.laneCode)">info</i>
          {{ getSpot(violation.laneCode) }}
        </p>
        <p class="text-center pt-1 pb-0">
          <i class="material-icons text-secondary mr-1 icon-sm" data-placement="top" i18n-appTooltip
          [appTooltip]="getLaneInfo(violation.laneCode)" *ngIf="getLaneInfo(violation.laneCode)">info</i>
          {{ violation?.laneCode || '--' }}
        </p>
      </div>
      <p class="w-5 text-center">{{ violation?.vehicle?.plate || '--' }}</p>
      <p class="w-10 text-center">{{ violation?.serie || '--' }}</p>
      <p class="w-10" i18n>
        <span class="d-block">{{ violation?.date | moment: 'DD/MM/YYYY' }}</span>
        <small class="text-muted">{{ violation?.date | moment: 'HH:mm:ss' }}</small>
      </p>
      <p class="w-10" i18n>
        <span class="d-block">{{ violation?.createdAt | moment: 'DD/MM/YYYY' }}</span>
        <small class="text-muted">{{ violation?.createdAt | moment: 'HH:mm:ss' }}</small>
      </p>
      <p class="w-10 d-flex align-items-center justify-content-center" i18n i18n-appTooltip
      [appTooltip]="getRegulationInfo(violation.regulationId)">
        <i class="material-icons text-secondary ml-2 icon-sm" data-placement="top">info</i>
        {{ getRegulationCode(violation?.regulationId) }}
      </p>
      <p class="w-15 text-left">
        <span class="text-info text-left p-0 mb-0" *ngIf="violation.status==='quarantine'" i18n>Quarentena</span>
        <span class="text-info text-left p-0 mb-0" *ngIf="violation.status!=='valid' && violation.status!=='invalid' && violation.status!=='quarantine'" i18n>Em processamento</span>
        <span class="text-danger text-left mb-0 p-0" *ngIf="violation.status==='invalid'" i18n>Inválida</span>
        <span class="text-success text-left mb-0 p-0" *ngIf="violation.status==='valid'" i18n>Válida</span>
        <ng-container *ngIf="violation.status==='invalid'||violation.status==='valid' && getAuthorInfo(violation.history)">
          <div *ngIf="violation.history" class="text-left text-muted p-0 popover-hover">
            <div class="popover-hover__container">
              <h6 i18n>Histórico de processamento</h6>
              <table>
                <thead>
                <tr>
                  <th i18n>Etapa</th>
                  <th i18n>Responsável</th>
                  <th i18n>Data de processamento</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of getHistories(violation.history)">
                  <td>{{ item.title || '------' }}</td>
                  <td class="text-center">{{ item.authorName || '----' }}</td>
                  <td>{{ item.date | moment: 'L LTS'}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <small i18n>Processado por {{ getAuthorInfo(violation.history) }}</small> <i class="material-icons icon-sm text-secondary" data-placement="top">info</i>
          </div>
          <!-- <div *ngIf="violation?.history.quarantine" class="text-left text-muted p-0 popover-hover">
            <div class="popover-hover__container">
              <h6 i18n>Histórico de processamento</h6>
              <table>
                <thead>
                <tr>
                  <th i18n>Etapa</th>
                  <th i18n>Responsável</th>
                  <th i18n>Data de processamento</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <span *ngIf="violation.step==='validateLane'" i18n>Validar <br />faixa</span>
                    <span *ngIf="violation.step==='validateCalibration'" i18n>Validar <br />aferição</span>
                    <span *ngIf="violation.step==='validateSpeed'" i18n>Validar <br />velocidade</span>
                    <span *ngIf="violation.step==='validateFiles'" i18n>Validar <br />arquivo</span>
                    <span *ngIf="violation.step==='validateExemptPeriod'" i18n>Período <br />isento</span>
                    <span *ngIf="violation.step==='validateVehicleWhiteList'" i18n>Lista <br />branca</span>
                    <span *ngIf="violation.step==='validateViolationLimit'" i18n>Limite <br />de infração</span>
                    <span *ngIf="violation.step==='verifyValid'" i18n>Verificação <br />de Válidas</span>
                    <span *ngIf="violation.step==='triage'" i18n>Triagem</span>
                    <span *ngIf="violation.step==='typing'" i18n>Digitação</span>
                    <span *ngIf="violation.step==='verify'" i18n>Verificação</span>
                    <span *ngIf="violation.step==='validate'" i18n>Validação</span>
                    <span *ngIf="violation.step==='lotAttribution'" i18n>Atribuição <br />de lote</span>
                    <span *ngIf="violation.step==='internalAudit'" i18n>Auditoria <br />interna</span>
                    <span *ngIf="violation.step==='revision'" i18n>Revisão</span>
                    <span *ngIf="violation.step==='filter'" i18n>Filtro</span>
                    <span *ngIf="violation.step==='done'" i18n>Concluído</span>
                    <span *ngIf="violation.step==='serialNumberGeneration'" i18n>Geração do número de série</span>
                  </td>
                  <td> {{ violation?.history.quarantine.authorName || violation?.history.quarantine.functionName || '----' }} </td>
                  <td> {{ violation?.history.quarantine.date | moment: 'L LTS' }} </td>
                </tr>
                </tbody>
              </table>
            </div>
            <small i18n>Processado por {{ violation?.history.quarantine.authorName || '----' }}</small> <i class="material-icons icon-sm text-secondary" data-placement="top">info</i>
          </div> -->
        </ng-container>
        <ng-container *ngIf="violation.status==='quarantine'">
          <br>
          <span class="text-left" i18n>Motivo: </span> {{ getStatusQuarantine(violation?.quarantineReason) }}
        </ng-container>
        <small class="text-left text-muted" *ngIf="violation.status==='invalid'">Motivo: {{ violation?.reason.name }}</small>
      </p>
      <p class="w-10">
        <span *ngIf="violation.step==='validateLane'" i18n>Validar <br />faixa</span>
        <span *ngIf="violation.step==='validateEquipment'" i18n>Validar <br />equipamento</span>
        <span *ngIf="violation.step==='validateCalibration'" i18n>Validar <br />aferição</span>
        <span *ngIf="violation.step==='validateSpeed'" i18n>Validar <br />velocidade</span>
        <span *ngIf="violation.step==='validateFiles'" i18n>Validar <br />arquivo</span>
        <span *ngIf="violation.step==='validateExemptPeriod'" i18n>Período <br />isento</span>
        <span *ngIf="violation.step==='validateVehicleWhiteList'" i18n>Lista <br />branca</span>
        <span *ngIf="violation.step==='validateViolationLimit'" i18n>Limite <br />de infração</span>
        <span *ngIf="violation.step==='verifyValid'" i18n>Verificação <br />de Válidas</span>
        <span *ngIf="violation.step==='verifyInvalid'" i18n>Verificação <br />de Inválidas</span>
        <span *ngIf="violation.step==='triage'" i18n>Triagem</span>
        <span *ngIf="violation.step==='typing'" i18n>Digitação</span>
        <span *ngIf="violation.step==='verify'" i18n>Verificação</span>
        <span *ngIf="violation.step==='validate'" i18n>Validação</span>
        <span *ngIf="violation.step==='lotAttribution'" i18n>Atribuição <br />de lote</span>
        <span *ngIf="violation.step==='internalAudit'" i18n>Auditoria <br />interna</span>
        <span *ngIf="violation.step==='revision'" i18n>Revisão</span>
        <span *ngIf="violation.step==='filter'" i18n>Filtro</span>
        <span *ngIf="violation.step==='done'" i18n>Concluído</span>
        <span *ngIf="violation.step==='serialNumberGeneration'" i18n>Geração do número de série</span>
      </p>
      <p class="w-10">
        <span class="text-primary cursor-pointer" (click)="showHistory(violation.lotId)"
        *ngIf="lotsByViolation && lotsByViolation['lot'+violation?.lotId] && violation.lotId > 0" i18n-appTooltip [appTooltip]="getLotInfo(violation.lotId)">
          {{ lotsByViolation['lot'+violation.lotId].name.slice(0, 20) + '...' }}
        </span>
        <span class="text-primary cursor-pointer" (click)="showHistory(violation.lotIdAudit)"
          *ngIf="lotsByViolation && lotsByViolation['lot'+violation?.lotIdAudit] && violation.lotIdAudit > 0" i18n-appTooltip [appTooltip]="getLotInfo(violation.lotIdAudit)">
          {{ lotsByViolation['lot'+violation?.lotIdAudit]?.name.slice(0, 20) + '...' }}
        </span>
        <span *ngIf="!violation.lotId && !violation.lotIdAudit"
          class="w-10 text-break-all">
          {{ '--' }}
        </span>
      </p>
      <p class="w-5" i18n>
        <span class="dropdown dropright">
          <button class="btn text-secondary pl-1 pr-1" type="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">more_horiz</i>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="showDetails(violation)">Visualizar Infração</a>
            <a class="dropdown-item" (click)="downloadAsPDF(violation)">Visualizar AIT</a>
          </div>
        </span>
      </p>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>

<app-pagination (items)="setViolations($event)" [params]="params" [deleteEvent]="deleteEvent"
    [service]="violationService" maxResults="25"></app-pagination>
