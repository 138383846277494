<div class="modal-dialog modal-dialog-centered modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title container" i18n>Etapa de Triagem</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body" #container>
      <form [formGroup]="form">
        <app-input i18n-label i18n-toolTipMsg i18n-placeholder type="multiselect" [list]="regulations" selectValue="id" selectText="prettyName"
          label="Selecione os enquadramentos que deverão ser encaminhados para a etapa de Triagem"
          toolTipMsg='Caso a etapa de “Triagem" esteja habilitada para o contrato e nenhum enquadramento esteja selecionado,
          obrigatoriamente todos os registros serão encaminhados para a etapa de Triagem'
          selectShowLimit=5 placeholder="Selecione um ou mais enquadramentos" formControlName="regulationIds">
        </app-input>
      </form>
    </div>
    <div class="modal-footer">
      <button i18n (click)="onCancel()" class="btn btn-outline-secondary">Cancelar</button>
      <button i18n (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">Confirmar</button>
    </div>
  </div>
</div>
