import { Injectable } from '@angular/core';
import { LotStepReport } from '../models/LotStepReport';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LotStepReportService extends BaseApiService<LotStepReport> {
  get cacheKey(): StorageKey {
    return StorageKey.lotStepReportServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/report/lotStepReport`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  protected parse(obj) {
    return LotStepReport.parse(obj);
  }

  protected unparse(obj) {
    return LotStepReport.unparse(obj);
  }

}
