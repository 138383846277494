import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EquipmentsRoutingModule } from './equipments-routing.module';
import { EquipmentsListComponent } from './equipments-list/equipments-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EquipmentsCreateOrUpdateModalComponent } from './equipments-create-or-update-modal/equipments-create-modal.component';
import {
  EquipmentsCreateOrUpdateCalibrationComponent
} from './equipments-create-or-update-calibration/equipments-create-or-update-calibration.component';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    EquipmentsListComponent,
    EquipmentsCreateOrUpdateModalComponent,
    EquipmentsCreateOrUpdateCalibrationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    EquipmentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  entryComponents: [
    EquipmentsCreateOrUpdateModalComponent,
    EquipmentsCreateOrUpdateCalibrationComponent
  ]
})
export class EquipmentsModule { }
