import { BaseApiModel } from './BaseApiModel';

export class ReleaseNotes extends BaseApiModel {
  public id: string;
  public version: string;
  public date: string;
  public notes: [
    {
      module: string;
      description: string;
    }
  ];
}
