import { Component, OnInit, ViewChild, Input, HostListener } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  ModalService,
  StorageService,
  StorageKey,
  UserService,
  AlertService,
  BrandModelService,
  ColorService,
  CategoryService,
  ClassificationService,
  TypeService,
  RegionService} from 'src/app/core/services';
import {
  User,
  Violation,
  AlertItem,
  AlertType,
  Vehicle,
  BrandModel,
  Color,
  Category,
  Classification,
  Type,
  Region} from 'src/app/core/models';
import { Subject } from 'rxjs';
import { ViolationService } from 'src/app/core/services/violation.service';
import { PlateService } from 'src/app/core/services/plate.service';
import { BrandInternal } from 'src/app/core/models/BrandInternal';
import { City } from 'src/app/core/models/City';
import { State } from 'src/app/core/models/State';
import { BrandInternalService } from 'src/app/core/services/brandInternal.service';
import { CityService } from 'src/app/core/services/city.service';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-serach-plate-panel',
  templateUrl: './serach-plate-panel.component.html',
  styleUrls: ['./serach-plate-panel.component.sass']
})
export class SearchPlatePanelComponent extends BaseModal implements OnInit {
  @Input() violation: Violation;
  public form: FormGroup;
  params: { contractId: string; plate: string; regulationId: string; date: Date };

  public isLoading: boolean;
  public plateRegex: string;
  public plateSearchPromise = null;
  public vehicleData: Vehicle;
  public regions: Array<Region>;
  public brandModels: Array<BrandModel>;
  public colors: Array<Color>;
  public categories: Array<Category>;
  public classifications: Array<Classification>;
  public types: Array<Type>;
  public brandInternalModels: Array<BrandInternal>;
  public cities: Array<City>;
  public states: Array<State>;
  public viewData: boolean;

  public brandModelsById: { [key: string]: BrandModel } = {};
  public colorById: { [key: string]: Color } = {};
  public categoryById: { [key: string]: Category } = {};
  public classificationById: { [key: string]: Classification } = {};
  public typeById: { [key: string]: Type } = {};
  public brandInternalModelsById: { [key: string]: BrandInternal } = {};
  public cityById: { [key: string]: City } = {};
  public stateById: { [key: string]: State } = {};

  get isValid() {
    return this.form.valid;
  }

  constructor(
    modalService: ModalService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private plateService: PlateService,
    private regionService: RegionService,
    private brandModelService: BrandModelService,
    private colorService: ColorService,
    private categoryService: CategoryService,
    private classificationService: ClassificationService,
    private typeService: TypeService,
    private brandInternalService: BrandInternalService,
    private cityService: CityService,
    private stateService: StateService
  ) {
    super(modalService);
  }

  async ngOnInit() {
    this.viewData = false;
    this.violation = this.initialState as Violation || new Violation();
    this.createForm();
    await this.setRegions();
    await this.getAllData();
  }

  async setRegions() {
    await this.regionService.getAll().then(regions => {
      this.regions = regions;
      this.plateRegex = regions[0].plateRegex;
    });
    this.createForm();
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      plate: ['', [Validators.pattern(this.plateRegex)]]
    });
    this.form.get('plate').valueChanges.subscribe(plate => {
      if (plate.length === 7) {
        this.params = {
          contractId: this.violation?.contractId,
          regulationId: this.violation?.regulationId,
          date: this.violation?.date,
          plate: `${plate.toUpperCase()}`
        };
        if (this.isLoading !== false) {
          this.isLoading = true;
        }
        this.plateSearchPromise = this.findPlate(this.params);
      } else {
        this.viewData = false;
      }
    });
  }

  async findPlate(params: { contractId: string; plate: string; regulationId: string; date: Date }) {
    return await this.plateService.searchPlate(this.params).then(res => {
      this.vehicleData = res.vehicle;
      this.viewData = true;
    }).catch(err => {
      this.alertService.show(new AlertItem(
        'PlateNotRegistered', AlertType.warning, false, 5000));
    });
  }

  async getAllData() {
    await this.brandModelService.getAll({ regionId: this.regions[0].id }).then(res => {
      res.map(brand => {
        this.brandModelsById[brand.id] = brand;
      });
    });
    await this.brandInternalService.getAll().then(res => {
      res.map(brand => {
        this.brandInternalModelsById[brand.id] = brand;
      });
    });
    await this.colorService.getAll({ regionId: this.regions[0].id }).then(res => {
      res.map(color => {
        this.colorById[color.id] = color;
      });
    });
    await this.categoryService.getAll({ regionId: this.regions[0].id }).then(res => {
      res.map(category => {
        this.categoryById[category.id] = category;
      });
    });
    await this.classificationService.getAll({ regionId: this.regions[0].id }).then(res => {
      res.map(classification => {
        this.classificationById[classification.id] = classification;
      });
    });
    await this.typeService.getAll({ regionId: this.regions[0].id }).then(res => {
      res.map(type => {
        this.typeById[type.id] = type;
      });
    });
    await this.cityService.getAll({ regionId: this.regions[0].id }).then(res => {
      res.map(city => {
        this.cityById[city.id] = city;
      });
    });
    await this.stateService.getAll({ regionId: this.regions[0].id }).then(res => {
      res.map(state => {
        this.stateById[state.id] = state;
      });
    });
    this.isLoading = false;
  }

  onAllSubmited(): Promise<any> {
    return Promise.resolve(this.vehicleData);
  }

  get lastState(): any {
    return null;
  }
}
