/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import { BaseApiModel } from './BaseApiModel';
import { Equipment } from './Equipment';
import { Vehicle } from './Vehicle';

export class PreViolation extends BaseApiModel {
  public passId: string;
  public contractId: number;
  public laneCode: string;
  public date: string;
  public files: Array<{
    type: string;
    urlCurrent: string;
    urlDecrypted: string;
    size: number;
  }>;
  public marks: Array<{
    type: string;
    time: string;
    date: string;
  }>;
  public vehicle: Vehicle;
  public equipment: Equipment;
  public notes: any;
  public status: string;
  public step: string;
  public lockDate: string;
  public lockUserId: number;
  public regulationId: number;
  public laneNumber: number;

}

export enum PreViolationSteps {
  validateCFTV = 'validateCFTV',
  verifyCFTV = 'verifyCFTV'
}
