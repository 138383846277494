import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ProductionReportService } from 'src/app/core/services';
import { Regulation, User, UserGroup } from 'src/app/core/models';

@Component({
  selector: 'app-production-report-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.sass']
})
export class ProductionReportFilterComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public filterForm: FormGroup;
  public steps: any[] = [];
  public regulations: Regulation[];
  public users: User[];
  public userGroups: UserGroup[];
  public contractsProduction: any[] = [];
  public dataContract: any = {};
  public collapse: boolean;
  public routerParams;
  public dataProduction: any;
  public filterParamsDefault: any = {};
  public dataContractsSub: Subscription;
  public dataProductionSub: Subscription;
  public isValid: boolean;
  public routeSub: Subscription;
  public hideSteps: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private productionReportService: ProductionReportService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.routerParams = this.activatedRoute.snapshot.params;

    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof ActivationStart) {
        this.hideSteps = event.snapshot.params.stepId;
      }
    });

    this.filterForm = this.formBuilder.group({
      usersGroupsIds: [null],
      usersIds: [null],
      regulationsIds: [null],
      stepsIds: [null]
    });

    this.dataContractsSub = this.productionReportService.getDataProduction()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((dataProduction: any) => {
        this.dataProduction = dataProduction;
        this.dataContract = dataProduction
        .contractsProduction
        .filter(contract => contract.id === Number(this.routerParams.contractId))
        .shift();

        this.userGroups = this.dataContract.groups || [];
        this.users = this.getUserList(this.dataContract.groups);
        this.regulations = this.dataProduction.regulations;
        this.steps = [];
        this.dataContract.steps.map(step => {
          const stepInfo = this.dataProduction.steps.find(item => item.id === step);
          this.steps.push(stepInfo);
        });

        this.filterParamsDefault = {
          usersGroupsIds: this.userGroups.map(group => group.id),
          usersIds: this.users.map(user => user.id),
          regulationsIds: this.regulations.map(regulation => regulation.id),
          stepsIds: this.steps.map(step => step.id)
        };

        this.loading = true;
      });

    this.filterForm.valueChanges.subscribe(res => {
      this.isValid = this.filterForm.valid;
    });

    this.filterForm.get('usersGroupsIds').valueChanges.subscribe(res => {
      if (res.length > 0) {
        const groupIds = res.map(Number);
        const listGroups = this.dataContract.groups.filter(group => groupIds.includes(group.id));
        this.users = this.getUserList(listGroups);
      } else {
        this.users = this.getUserList(this.dataContract.groups);
      }
      const valuesUsersIds = this.users.map(user => user.id);
      this.filterForm.get('usersIds').setValue(valuesUsersIds);
    });
  }

  getUserList(listGroup) {
    const users = [];
    const usersInGroup = listGroup.map(group => group.users).flat().reduce((list, user) => {
      if (!list.includes(user)) { list.push(user); }
      return list;
    }, []);

    usersInGroup.map(user => {
      const userInfo = this.dataProduction.users.find(item => item.id === user);
      if (userInfo) { users.push(userInfo); }
    });

    return users;
  }

  toggleCollapse() {
    this.collapse = !this.collapse;
  }

  submitFilter() {
    if (!this.filterForm.value.usersGroupsIds || this.filterForm.value.usersGroupsIds.length === 0) {
      this.filterForm.value.usersGroupsIds = this.filterParamsDefault.usersGroupsIds;
    }

    if (!this.filterForm.value.usersIds || this.filterForm.value.usersIds.length === 0) {
      this.filterForm.value.usersIds = this.filterParamsDefault.usersIds;
    }

    if (!this.filterForm.value.regulationsIds || this.filterForm.value.regulationsIds.length === 0) {
      this.filterForm.value.regulationsIds = this.filterParamsDefault.regulationsIds;
    }

    if (!this.filterForm.value.stepsIds || this.filterForm.value.stepsIds.length === 0) {
      this.filterForm.value.stepsIds = this.filterParamsDefault.stepsIds;
    }

    this.productionReportService.emitFilterProduction(this.filterForm.value);
  }

  redefineFilter() {
    this.filterForm.get('usersGroupsIds').setValue([]);
    this.filterForm.get('regulationsIds').setValue([]);
    this.filterForm.get('stepsIds').setValue([]);
    this.users = this.getUserList(this.dataContract.groups);
    this.productionReportService.emitFilterProduction(this.filterParamsDefault);
  }

  ngOnDestroy() {
    this.dataContractsSub.unsubscribe();
  }
}
