import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Regulation } from 'src/app/core/models';
import { ProductionReportService } from 'src/app/core/services';

@Component({
  selector: 'app-production-report-steps',
  templateUrl: './steps.component.html'
})
export class ProductionReportStepsComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public contract: any = {};
  public stepId;
  public step: any[];
  public contractSub: Subscription;
  public dataSub: Subscription;
  public stepName: string;
  public stepTotal: number;
  public regulations: Regulation[] = [];
  public stepsData: any[] = [];

  constructor(
    private productionReportService: ProductionReportService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.stepId = this.activatedRoute.snapshot.params.stepId;

    this.dataSub = this.productionReportService.getDataProduction()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((dataProduction: any) => {
        this.regulations = dataProduction.regulations;
        this.stepsData = dataProduction.steps;
      });

    this.contractSub = this.productionReportService.getContractsProduction()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((contractProduction: any) => {
        this.contract = contractProduction;
        this.createListRegulation();

        if (this.stepsData.length > 0) {
          this.stepName = this.stepsData.find(item => item.id).value;
        }

        this.stepTotal = this.contract[this.stepId];

        this.loading = true;
      });
  }

  createListRegulation() {
    this.step = [];
    for (const regulation of this.contract.regulations) {
      const objRegulation = {
        id: regulation.id,
        description: regulation.description,
        code: regulation.code,
        total: 0
      };

      for (const user of this.contract.users) {
        let totalDates = 0;

        user.dates.forEach(date => {
          let totalRegulations = 0;

          date.regulations.forEach(reg => {
            if (reg[this.stepId] && reg.id === regulation.id) {
              totalRegulations += reg[this.stepId];
            }
          });

          totalDates += totalRegulations;
        });

        objRegulation.total += totalDates;
      }

      this.step.push(objRegulation);
    }

    this.step.sort((a, b) => (a.total > b.total) ? -1 : 1);
  }

  getRegulationInfo(regulation) {
    const regulationInfo = this.regulations.filter(item => item.id === regulation).shift();
    return regulationInfo.description;
  }

  calculateSize(value: number, total: number) {
    return Math.ceil(value / total * 100);
  }

  ngOnDestroy() {
    this.dataSub.unsubscribe();
    this.contractSub.unsubscribe();
  }
}
