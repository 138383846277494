<div class="card mb-2" *ngIf="files && files.length > 0; else noImageBlock">
  <div *ngIf="!isModal; else modalImageBlock">
    <div [ngClass]="{'carousel': true, 'slide': true, 'image-proc': true, 'image-proc--in-single-view': isSingleView,
      'image-proc--in-double-view': isDoubleView, 'image-proc--process-column-right': rightColumn, 'isAjustedSize': isAjustedSize }"
      #carousel data-pause="true" data-ride="carousel">
      <div class="carousel-inner flex-center-vertical">
        <div class="carousel-item image-proc__slide" [ngClass]="{ 'active': i === 0}"
          *ngFor="let file of files; index as i">
          <div class="edit-options" [ngClass]="{ 'disabled': violationStatus === 'valid' || violationStatus === 'invalid' }" *ngIf="!loadImageEdit">
            <ul *ngIf="!isVideo(file); else sidebarVideo">
              <li *ngIf="!hideImageEdit"><button class="btn btn-sm btn-icon-block" (click)="updateBrightness(0.1)" data-placement="right"
                  i18n-appTooltip appTooltip="Aumentar Brilho"><i class="material-icons">brightness_high</i></button>
              </li>
              <li *ngIf="!hideImageEdit"><button class="btn btn-sm btn-icon-block" (click)="updateBrightness(-0.1)" data-placement="right"
                  i18n-appTooltip appTooltip="Reduzir Brilho"><i class="material-icons">brightness_4</i></button></li>
              <li *ngIf="!hideImageEdit"><button class="btn btn-sm btn-icon-block" (click)="updateContrast(0.1)" data-placement="right"
                  i18n-appTooltip appTooltip="Aumentar Contraste"><i class="material-icons">brightness_1</i></button>
              </li>
              <li *ngIf="!hideImageEdit"><button class="btn btn-sm btn-icon-block" (click)="updateContrast(-0.1)" data-placement="right"
                  i18n-appTooltip appTooltip="Reduzir Contraste"><i class="material-icons">tonality</i>
                </button></li>
              <li *ngIf="!hideImageEdit"><button class="btn btn-sm btn-icon-block" (click)="updateGrayscale()" data-placement="right"
                  i18n-appTooltip appTooltip="Converter para tons de cinza"><i class="material-icons">invert_colors</i>
                </button></li>
              <li *ngIf="!hideImageEdit">
                <button class="btn btn-sm btn-icon-block" (click)="updateBlackWhite()" data-placement="right"
                  i18n-appTooltip appTooltip="Converter para preto e branco">
                  <i class="material-icons">filter_b_and_w</i>
                </button>
              </li>
              <li *ngIf="!hideImageEdit">
                <button class="btn btn-sm btn-icon-block" (click)="updateNegative()" data-placement="right"
                  i18n-appTooltip appTooltip="Converter para negativo">
                  <i class="material-icons">monochrome_photos</i>
                </button>
              </li>
              <li *ngIf="!hideImageEdit">
                <div class="btn-group dropright">
                  <button type="button" class="btn btn-sm btn-icon-block dropdown-toggle"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    data-placement="top" i18n-appTooltip appTooltip="Editar níveis de vermelho, verde e azul">
                    <i class="material-icons">tune</i>
                  </button>
                  <div class="dropdown-menu">
                    <b class="ml-2 mt-2">R</b> <input type="range" class="slider-red ml-2 mb-2" min="-255" max="255"
                      id="inputRangeRed" #rangeRed (input)="updateRangeColor('red', rangeRed.value)">
                    <b class="ml-2 mt-2">G</b> <input type="range" class="slider-green ml-2 mb-2" min="-255" max="255"
                      id="inputRangeGreen" #rangeGreen (input)="updateRangeColor('green', rangeGreen.value)">
                    <b class="ml-2 mt-2">B</b> <input type="range" class="slider-blue ml-2 mb-2" min="-255" max="255"
                      id="inputRangeBlue" #rangeBlue (input)="updateRangeColor('blue', rangeBlue.value)">
                  </div>
                </div>
              </li>
              <li><button class="btn btn-sm btn-icon-block" (click)="openEditImageModal(file, 'blur')"
                  data-placement="right" i18n-appTooltip appTooltip="Obliterar"
                  [ngClass]="{'btn-info': operation === 'blur'}" *ngIf="isBlurEnabled"><i
                    class="material-icons">blur_on</i></button></li>
              <li><button class="btn btn-sm btn-icon-block" (click)="restoreImage()" data-placement="right"
                  i18n-appTooltip appTooltip="Restaurar"><i class="material-icons">settings_backup_restore</i></button>
              </li>
              <li *ngIf="videoExist()"><button class="btn btn-sm btn-icon-block" data-placement="right" i18n-appTooltip
                  appTooltip="Mostrar vídeos"><i class="material-icons"
                    (click)="openViewVideoModal(file.src)">video_library</i></button>
              </li>
              <!-- <li><a ata-placement="right" i18n-appTooltip appTooltip="Aumentar Zoom"><i
                class="material-icons">zoom_in</i></a></li>-->
            </ul>

            <ng-template #sidebarVideo>
              <ul>
                <li><button data-placement="right" class="btn btn-sm btn-icon-block" i18n-appTooltip
                    appTooltip="Mostrar vídeos">
                    <i class="material-icons" (click)="openViewVideoModal(file)">video_library</i></button></li>
              </ul>
            </ng-template>
          </div>

          <div [ngClass]="{'img-container': true, 'img-container--in-single-view': isSingleView,
            'img-container--in-double-view': isDoubleView }" #imgContainer
            (click)="openViewFileModal(file.src, file.type)" *ngIf="!isVideo(file); else videoContainer">
            <img [ngClass]="{'img': true, 'img--in-single-view': isSingleView, 'img--in-double-view': isDoubleView }"
              [src]="modifedBuffers[file.type] || file.src" [alt]="file.type" #img (load)="onLoad($event)"
              crossOrigin="anonymous" (mouseover)="magnify()">
            <app-enum-i18n [input]="additionalImages" (output)="setAdditionalImagesI18n($event)"></app-enum-i18n>
            <div *ngIf="isShowType" class="img-description-layer">{{ getType(file.type) }}</div>
          </div>

          <ng-template #videoContainer>
            <div [ngClass]="{'video-container': true,'video-container--in-single-view': isSingleView,
            'video-container--in-double-view': isDoubleView }" *ngIf="isVideoSupported(file)">
              <video [ngClass]="{'video': true, 'video--in-single-view': isSingleView,
                'video--in-double-view': isDoubleView }"
                [id]="file.type" height="100%" controls autoplay loop muted>
                <source [src]="file.urlCurrent || file.urlDecrypted" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>

            <div class="video-container justify-content-center" *ngIf="!isVideoSupported(file)">
              <p class="text-secondary" i18n>Formato de vídeo não suportado</p>
            </div>
          </ng-template>
        </div>
      </div>
      <a class="carousel-control-prev" href="javascript:void(0)" (click)="previous()" role="button" data-slide="prev"
        *ngIf="files && files.length > 1">
        <span class="sr-only">Previous</span>
      </a>

      <a class="carousel-control-next" href="javascript:void(0)" (click)="next()" role="button" data-slide="next"
        *ngIf="files && files.length > 1">
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <ng-template #modalImageBlock>
    <div class="image-proc image-proc--in-modal">
      <div class="edit-options">
        <ul>
          <li><button class="btn btn-sm btn-icon-block" (click)="updateBrightness(0.1)" data-placement="right"
              i18n-appTooltip appTooltip="Aumentar Brilho">
              <i class="material-icons">brightness_high</i></button></li>
          <li><button class="btn btn-sm btn-icon-block" (click)="updateBrightness(-0.1)" data-placement="right"
              i18n-appTooltip appTooltip="Reduzir Brilho">
              <i class="material-icons">brightness_4</i></button></li>
          <li><button class="btn btn-sm btn-icon-block" (click)="updateContrast(0.1)" data-placement="right"
              i18n-appTooltip appTooltip="Aumentar Contraste">
              <i class="material-icons">brightness_1</i></button></li>
          <li><button class="btn btn-sm btn-icon-block" (click)="updateContrast(-0.1)" data-placement="right"
              i18n-appTooltip appTooltip="Reduzir Contraste">
              <i class="material-icons">tonality</i></button></li>
          <!-- <li><button ata-placement="right" i18n-appTooltip appTooltip="Aumentar Zoom">
            <i class="material-icons">zoom_in</i></button>
          </li>-->
          <li><button class="btn btn-sm btn-icon-block" (click)="updateGrayscale()" data-placement="right"
              i18n-appTooltip appTooltip="Converter para tons de cinza"><i
                class="material-icons">invert_colors</i></button></li>
          <li>
            <button class="btn btn-sm btn-icon-block" (click)="updateBlackWhite()" data-placement="right"
                  i18n-appTooltip appTooltip="Converter para preto e branco">
            <i class="material-icons">filter_b_and_w</i>
           </button>
          </li>
          <li>
            <button class="btn btn-sm btn-icon-block" (click)="updateNegative()" data-placement="right"
              i18n-appTooltip appTooltip="Converter para negativo">
              <i class="material-icons">monochrome_photos</i>
            </button>
          </li>
          <li>
            <div class="btn-group dropright">
              <button type="button" class="btn btn-sm btn-icon-block dropdown-toggle"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                data-placement="top" i18n-appTooltip appTooltip="Editar níveis de vermelho, verde e azul">
                <i class="material-icons">tune</i>
              </button>
              <div class="dropdown-menu">
                <b class="ml-2 mt-2">R</b> <input type="range" class="slider-red ml-2 mb-2" min="-100" max="100"
                  id="inputRangeRed" #rangeRed (input)="updateRangeRed(rangeRed.value)">
                <b class="ml-2 mt-2">G</b> <input type="range" class="slider-green ml-2 mb-2" min="-100" max="100"
                  id="inputRangeGreen" #rangeGreen (input)="updateRangeGreen(rangeGreen.value)">
                <b class="ml-2 mt-2">B</b> <input type="range" class="slider-blue ml-2 mb-2" min="-100" max="100"
                  id="inputRangeBlue" #rangeBlue (input)="updateRangeBlue(rangeBlue.value)">
              </div>
            </div>
          </li>
          <li>
            <button class="btn btn-sm btn-icon-block" data-placement="right" i18n-appTooltip appTooltip="Obliterar"
              [ngClass]="{'selected': operation === 'blur'}" *ngIf="isBlurEnabled">
              <i class="material-icons">blur_on</i></button>
            <ul *ngIf="operation === 'blur'">
              <li *ngFor="let coordinate of dragCoordinates[currentType]; index as i">
                <button (mousedown)="removeSquare(i)" data-placement="right" i18n-appTooltip
                  appTooltip="Remover obliteração" class="btn btn-sm btn-fade-danger btn-icon-block">
                  <i class="material-icons">remove_circle_outline</i>
                </button>
                <span (mousedown)="selectedBlurSquare = i" class="badge badge--modal"
                  [ngClass]="{'badge-info': selectedBlurSquare === i}">{{i + 1}}</span>
              </li>
              <li>
                <button (click)="addPosition()" class="btn btn-sm btn-fade-primary btn-icon-block" i18n-appTooltip
                  appTooltip="Adicionar nova obliteração">
                  <i class="material-icons">add_circle_outline</i>
                </button>
              </li>
            </ul>
          </li>
          <!-- <li><a data-placement="right" i18n-appTooltip appTooltip="Recortar"><i class="material-icons">content_cut</i></a></li> -->
          <li><button class="btn btn-sm btn-icon-block" (click)="restoreImage()" data-placement="right" i18n-appTooltip
              appTooltip="Restaurar">
              <i class="material-icons">settings_backup_restore</i></button>
          </li>
        </ul>
      </div>
      <div class="img-container" #imgContainer (window:resize)="onResize()">
        <img [src]="modifedBuffers[currentType] || files[0].src" #img (load)="onLoad($event)" crossOrigin="anonymous">
        <div *ngFor="let coordinate of dragCoordinates[currentType]; index as i">
          <app-drag-resize (dragEnd)="dragEndUpdate($event, i)" [limits]="limits" [operation]="operation"
            [coordinates]="coordinate" (mousedown)="selectedBlurSquare = i" [isSelected]="selectedBlurSquare === i">
          </app-drag-resize>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="vertical-align" *ngIf="isModal && false">
    <div class="move-commands">
      <div class="row">
        <div class="col-12">
          <span class="command" i18n>Mover</span>
          <div class="command-box">CTRL</div>
          <div class="plus-align">+</div>
          <div class="arrow-align">
            <div class="material-icons arrow-box">arrow_upward</div>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="material-icons arrow-box arrow-left-command">arrow_back</div>
          <div class="material-icons arrow-box arrow-down-command">arrow_downward</div>
          <div class="material-icons arrow-box arrow-right-command">arrow_forward</div>
        </div>
      </div>
    </div>
    <div class="expand-commands">
      <div class="row">
        <div class="col-12">
          <span class="label" i18n>Expandir</span>
          <div class="command-box">SHIFT</div>
          <div class="plus-align">+</div>
          <div class="arrow-align">
            <div class="material-icons arrow-box">arrow_upward</div>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="material-icons arrow-box arrow-left-command">arrow_back</div>
          <div class="material-icons arrow-box arrow-down-command">arrow_downward</div>
          <div class="material-icons arrow-box arrow-right-command">arrow_forward</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noImageBlock>
  <div class="card mb-3">
    <div class="card-body text-center">
      <h6 i18n>Imagem não disponível</h6>
    </div>
  </div>
</ng-template>
