=<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 i18n class="modal-title">Detalhes do Usuário</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-auto">
          <app-avatar [user]="user" class="avatar-sm"></app-avatar>
        </div>

        <div class="col">
          <h2 *ngIf="user.name" class="mb-1">{{user.name}}</h2>
          <h5 *ngIf="user.role" class="mb-1 text-secondary">{{user.role}}</h5>
          <p *ngIf="user.email" class="text-secondary">{{user.email}}</p>

          <hr />

          <div *ngIf="user.companyId" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">business</i></div>
            <div class="col-10">
              <p class="text-secondary mb-0" i18n><small>Empresa:</small></p>
              <p>{{companyList && companyList[user.companyId] && companyList[user.companyId].name}}</p>
            </div>
          </div>

          <div *ngIf="user.verified; else elseBlock" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">done</i></div>
            <div class="col-8">
              <p class="text-secondary mb-0" i18n><small>Situação:</small></p>
              <p>Confirmado</p>
            </div>
          </div>

          <div *ngIf="user.registryNumber" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">folder_shared</i></div>
            <div class="col-8">
              <p class="text-secondary mb-0" i18n><small>N° da matrícula:</small></p>
              <p>{{user.registryNumber}}</p>
            </div>
          </div>

          <div *ngIf="user.companyId" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">assignment_ind</i></div>
            <div class="col-10">
              <p class="text-secondary mb-0" i18n><small>CPF:</small></p>
              <p>{{user.cpf}}</p>
            </div>
          </div>

          <ng-template #elseBlock>
            <div class="row d-flex align-items-center">
              <div class="col-auto"><i class="material-icons icon-lg text-warning">watch_later</i></div>
              <div class="col-6">
                <p class="text-secondary mb-0" i18n><small>Situação:</small></p>
                <p>Pendente</p>
              </div>
              <div class="col-auto ml-auto">
                <button class="btn btn-fade-info btn-sm" [attr.hidden]="hasPermissionContract()"
                 (click)="sendReinvite()">Reenviar convite</button>
              </div>
            </div>
          </ng-template>

          <div *ngIf="user.createdAt" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">date_range</i></div>
            <div class="col-8">
              <p class="text-secondary mb-0" i18n><small>Data de criação:</small></p>
              <p>{{user.createdAt | moment: 'L'}} - {{user.createdAt | moment: 'LTS'}}</p>
            </div>
          </div>

          <div *ngIf="user.groupIds" class="row d-flex align-items-center">
            <div class="col-auto"><i class="material-icons icon-lg text-secondary">groups</i></div>
            <div class="col-10">
              <p class="text-secondary mb-0" i18n><small>Grupos:</small></p>
              <p><span class="badge badge-fade-info" *ngFor="let group of user.groupIds">
                {{userGroupList[group] && userGroupList[group].prettyName }}
              </span></p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
