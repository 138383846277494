<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <div>
        <h5 class="mb-0 modal-title" i18n style="line-height: initial;">
          Configurar infrações <br />
          <label style="color: slategrey; font-size: large;">
            {{lane?.prettyNames}}
          </label>
        </h5>
      </div>

      <div class="dropdown ml-auto mr-3">
        <button class="btn btn-fade-secondary btn-icon-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="material-icons">more_horiz</i>
        </button>

        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop4">
          <a class="dropdown-item" (click)="openCopyFrom()" i18n>Copiar configuração de faixa</a>
          <a class="dropdown-item" (click)="openCopyTo()" i18n>Replicar as configurações desta faixa</a>
        </div>
      </div>

      <button type="button" (click)="onCancel()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="modal-body">
      <ul class="nav nav-pills mb-3">
        <li class="nav-item" *ngFor="let item of violationForms; index as i">
          <a [ngClass]="{'nav-link': true, 'active': selected === i}" href="javascript:void(0);" (click)="selected=i">
            <div class="tab-name">{{item.name}}</div>
          </a>
        </li>
        <li class="nav-item">
          <a class="btn btn-icon-block text-info" href="javascript:void(0);" (click)="addTab()">
            <i class="material-icons">add_box</i>
          </a>
        </li>
        <li class="nav-item ml-auto" *ngIf="this.violationForms[selected]">
          <button class="btn btn-icon-block text-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">settings</i>
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop">
            <a class="dropdown-item pointer" (click)="deleteTab()" i18n
              *ngIf="this.violationForms">Remover aba</a>
            <a class="dropdown-item pointer" (click)="openCopyWeek()" i18n>Replicar as configurações de horário</a>
          </div>
        </li>
      </ul>
      <div *ngIf="!this.violationForms[selected]">
        <p style="padding: 12px">
          Nenhuma aba configurada
        </p>
      </div>
      <div class="tabs">
        <form [formGroup]="violationForms[selected].form" *ngIf="violationForms[selected]">

          <div class="form-group">
            <div class="row">
              <div class="col-md-5">
                <app-input label="Nome" i18n-label formControlName="name" inputRequired=true></app-input>
              </div>
              <div class="col-md-7">
                <div class="row">
                  <div class="col-md-6">
                    <label i18n class="text-secondary">Período de Ativação:</label>
                    <div class="d-flex">
                      <div class="input-group-prepend"><span class="input-group-text" i18n>Inicial</span></div>
                      <input class="form-control" type="date" formControlName="startsAt"
                        [min]="minDate"
                        [max]="maxDateStart">
                    </div>
                  </div>
                  <div class="col-md-6 pt-4">
                    <div class="d-flex">
                      <div class="input-group-prepend"><span class="input-group-text" i18n>Final</span></div>
                      <input class="form-control" type="date" formControlName="endsAt"
                        [min]="minDate"
                        [max]="maxDateStart">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <app-input label="Enquadramento" i18n-label formControlName="regulationIds" type="multiselect"
                [list]="regulations" selectText="prettyName" selectValue="id" inputRequired=true selectShowLimit="10"></app-input>
              </div>
              <div class="col-md-6">
                <app-input label="Classificação" i18n-label formControlName="classificationCodes" type="multiselect"
                [list]="classifications" selectText="description" selectValue="code" selectShowLimit="10"></app-input>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label i18n>Arquivos: <small class="text-danger">*</small></label>
            <div class="d-flex" style="flex-flow: wrap; align-content: stretch;">
              <mat-checkbox [formControlName]="type + 'Checkbox'"
              [ngClass]="{'mr-4': true, 'check-error': !validateFileTypes() && isFileTypePristine}"
              color="primary" *ngFor="let type of fileTypes; index as i" >
                {type, select,
                pan{Imagem Panorâmica 1} pan2{Imagem Panorâmica 2} zoom{Imagem Zoom 1} zoom2{Imagem Zoom 2} video{Vídeo 1}
                video2{Vídeo 2}}</mat-checkbox>
            </div>
          </div>

          <div class="mt-1">
            <label i18n>Dias da Semana</label>

            <table class="table table-bordered table-striped table-sm mb-0">
              <tr *ngFor="let day of weekdays">
                <td class="w-25 align-items-center">
                  <mat-checkbox [formControlName]="day + 'Enabled'" color="primary" i18n>{day, select, sunday {Domingo}
                    monday {Segunda} tuesday {Terça} wednesday {Quarta} thursday {Quinta} friday {Sexta} saturday
                    {Sábado}}</mat-checkbox>
                </td>
                <td *ngIf="violationForms[selected].form.get(day + 'Enabled').value; else disabled">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-3">
                      <mat-checkbox [formControlName]="day + 'Fullday'" color="primary" i18n>Dia Inteiro</mat-checkbox>
                    </div>
                    <div class="col-12 col-md-3">
                      <label i18n>Final da Placa</label>
                    </div>
                    <div class="col-12 col-md-3">
                      <app-input class="no-margin" [formControlName]="day + 'Plate'" type="multiselect" [list]="plates"
                      placeholder="Todos" selectText="plate" selectValue="plate" selectShowLimit=2 inputRequired=true
                      hasLabel=false></app-input>
                    </div>
                    <div class="col-md-3 align-items-end">
                      <button class="btn btn-fade-info btn-sm" (click)="openCopyTime(day)" i18n>Replicar dia</button>
                    </div>
                  </div>

                  <div *ngIf="!violationForms[selected].form.get(day + 'Fullday').value">
                    <form *ngFor="let timeForm of weekForms[selected][day]; index as j" [formGroup]="timeForm">
                      <div class="row no-gutters align-items-center mt-3">
                        <div class="col-12 col-md-3 mr-3"><label i18n>Período {{j+1}}</label></div>

                        <div class="col-12 col-md-3 mr-3">
                          <div class="input-group input-group--adjust">
                            <div class="input-group-prepend"><span class="input-group-text">Início</span></div>
                            <app-input class="input-group-prepend" formControlName="start" format="dd:dd" inputRequired=true
                            hasLabel=false></app-input>
                          </div>
                        </div>

                        <div class="col-12 col-md-3 mr-3">
                          <div class="input-group input-group--adjust">
                            <div class="input-group-prepend"><span class="input-group-text">Fim</span></div>
                            <app-input class="input-group-prepend" formControlName="end" format="dd:dd" inputRequired=true
                            hasLabel=false></app-input>
                          </div>
                        </div>

                        <div class="col-2">
                          <a href="javascript:void(0);" class="btn text-success btn-icon-block mr-2" *ngIf="j === weekForms[selected][day].length - 1"
                            (click)="addTime(day)"><i class="material-icons">add_circle_outline</i></a>

                          <a href="javascript:void(0);" class="btn text-danger btn-icon-block" *ngIf="weekForms[selected][day].length !== 1"
                            (click)="removeTime(day,j)"><i class="material-icons">remove_circle</i></a>
                        </div>
                      </div>
                    </form>
                  </div>
                </td>
                <ng-template #disabled>
                  <td i18n>INATIVO</td>
                </ng-template>
              </tr>
            </table>
          </div>
        </form>
      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-outline-secondary" (click)="onCancel()">
        <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
      </button>
      <button class="btn btn-primary" (click)="onSubmit()" [ngClass]="{disabled: !isValid}">
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
