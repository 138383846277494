<div class="filter mb-4">
  <div class="filter__header" (click)="toggleCollapse()">
    <h5 i18n>Filtrar relatório</h5>

    <button class="btn btn-sm btn-icon-block"
      [ngClass]="{ 'btn-outline-secondary': !collapse, 'btn-secondary': collapse }">
      <i class="material-icons" *ngIf="collapse">unfold_more</i>
      <i class="material-icons" *ngIf="!collapse">unfold_less</i>
    </button>
  </div>

  <div [class.collapsed]="collapse">
    <form class="form-horizontal" [formGroup]="filterForm" *ngIf="loading; else loadingBlock">
      <div class="filter__area">
        <div class="filter__block">
          <label i18n>Pontos:</label>
          <app-multiselect type="inline" [list]="spots" formControlName="spotsIds" selectValue="id" selectText="prettyName"
            itemsOnList="0" buttonLabel="Pontos" charactersOnItem="10">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Faixas:</label>
          <app-multiselect type="inline" [list]="lanes" formControlName="laneIds" selectValue="id" selectText="prettyName"
            itemsOnList="0" buttonLabel="Faixas" charactersOnItem="10">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Enquadramentos:</label>
          <app-multiselect type="inline" [list]="regulations" formControlName="regulationsIds" selectValue="id"
            selectText="prettyName" itemsOnList="0" buttonLabel="Enquadramentos" charactersOnItem="10">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Situação:</label>
          <app-multiselect type="inline" [list]="situations" formControlName="situationsIds" selectValue="id" selectText="value"
            itemsOnList="0" buttonLabel="Situações">
          </app-multiselect>
        </div>

        <div class="filter__block" *ngIf="reasonArea">
          <label i18n>Motivo da invalidação:</label>
          <app-multiselect type="inline" [list]="reasons" formControlName="reasonsIds" selectValue="id" selectText="prettyName"
            itemsOnList="0" buttonLabel="Motivo da invalidação">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Lotes:</label>
          <app-multiselect type="radio" [list]="lots" formControlName="lotIds" selectValue="id" selectText="name"
            itemsOnList="0" buttonLabel="Lotes">
          </app-multiselect>
        </div>
      </div>

      <div class="filter__area">
        <div class="filter__block">
          <label i18n>Nº de série do equipamento:</label>
          <app-input i18n-label type="text" formControlName="numberSerie" type="text"></app-input>
        </div>

        <div class="filter__block">
          <label i18n>Nº da placa:</label>
          <app-input i18n-label type="text" formControlName="numberPlate" type="text"></app-input>
        </div>

        <div class="filter__block">
          <label i18n>Id da infração:</label>
          <app-input i18n-label type="text" formControlName="numberId" type="text"></app-input>
        </div>

        <!-- <div class="filter__block w-25">
          <label i18n>Processado por:</label>
          <app-input class="input-multiselect" i18n-label type="select" [list]="users" selectText="name"
            selectValue="id" formControlName="usersIds" selectShowLimit="1" placeholder="Selecione"></app-input>
        </div> -->
      </div>

      <div class="filter__button">
        <button type="submit" class="btn btn-outline-secondary mr-3" i18n (click)="redefineFilter()">Redefinir
          filtro</button>
        <button type="submit" class="btn"  [ngClass]="{ 'btn-primary': true, disabled: !isValid }"
        i18n (click)="submitFilter()">Aplicar filtro</button>
      </div>
    </form>

    <ng-template #loadingBlock>
      <div class="loading-page">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
