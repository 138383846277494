<div class="container pb-5">
	<div class="page-title">
		<h2 i18n>Gerenciamento de Aferições</h2>
	</div>

	<div class="mb-4">
		<form [formGroup]="searchForm" (submit)="handleSearch()">
			<div class="input-group">
				<input type="text" class="form-control form-search" formControlName="search" i18n-placeholder
					placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">

				<div class="input-group-append">
					<div class="filter-dropdown filter-dropdown--md">
						<button type="button" class="btn btn-outline-secondary btn-icon filter-dropdown__handle" i18n><i
								class="material-icons">filter_alt</i> Filtrar</button>

						<div class="filter-dropdown__content">
							<form [formGroup]=searchForm>
								<div class="row">
									<div class="col">
										<div class="form-group">
											<label class="">Contratos:</label>
											<app-input i18n-label type="select" [list]="contracts" formControlName="contractId"
												selectText="prettyName" selectValue="id" selectShowLimit="1" placeholder="Selecione o Contrato"
												i18n-placeholder (ngModelChange)="setSpots($event)">
											</app-input>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<div class="form-group">
											<label class="">Pontos:</label>
											<app-input i18n-label type="multiselect" [list]="spots" formControlName="spotIds"
												selectText="prettyName" selectValue="id" selectShowLimit="1" placeholder="Selecione o Ponto"
												i18n-placeholder (ngModelChange)="setLanes($event)">
											</app-input>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<label class="">Faixas:</label>
										<div class="form-group">
											<app-input class="no-margin" formControlName="laneIds" type="multiselect" [list]="lanesBySpots"
												placeholder="Selecione faixa(s)" selectText="prettyNames" selectValue="id" selectShowLimit="1"
												i18n-placeholder i18n-label>
											</app-input>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<div class="form-group">
											<label i18n>Tipo da Aferição</label>
											<select type="text" tabindex="4" class="form-control" formControlName="type" placeholder="Todos">
												<option i18n value="" selected>Todos</option>
												<option i18n value="metrologic" selected>Metrológica</option>
												<option i18n value="non-metrologic" selected>Não Metrológica</option>
											</select>
										</div>
									</div>

									<div class="col">
										<div class="form-group">
											<label i18n>Status</label>
											<select type="text" tabindex="4" class="form-control" formControlName="status">
												<option i18n value="" selected>Todos</option>
												<option i18n value="current" selected>Vigente</option>
												<option i18n value="expiresIn30Days" selected>Vence em até 30 dias</option>
												<option i18n value="expired" selected>Vencidos</option>
											</select>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label i18n>Data da Aferição</label>
									<div class="row">
										<div class="col d-flex align-items-center">
											<span class="mr-3 ">De</span>
											<app-input type="date" formControlName="startsAt"></app-input>&nbsp;&nbsp;&nbsp;&nbsp;
											<span class="mr-3">à</span>
											<app-input type="date" formControlName="endsAt"></app-input>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label i18n>Período de Vigência</label>
									<div class="row">
										<div class="col d-flex align-items-center">
											<span class="mr-3">De</span>
											<app-input type="date" formControlName="expirationStartsAt"></app-input>&nbsp;&nbsp;&nbsp;&nbsp;
											<span class="mr-3">à</span>
											<app-input type="date" formControlName="expirationEndsAt"></app-input>
										</div>
									</div>
								</div>

								<div class="filter-dropdown__footer">
									<button i18n type="submit" class="btn btn-info btn-block" (click)="handleSearch()"
										data-dismiss="modal" ng-disabled="!isValid">Aplicar</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>

	<app-enum-i18n [input]="typesCalibration" (output)="typesCalibrationI18n=$event"></app-enum-i18n>

	<div class="loading-block" *ngIf="isloading">
		<div class="loading-spinner">
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
	</div>

	<div [id]="'calibrations-' + contract.code" *ngFor="let contract of contracts">
		<div class="table-responsive table-hover table-block mb-1" *ngIf="contractsCalibrations[contract.code]">
			<div class="d-flex flex-row justify-content-between" style="cursor: pointer;">
				<div class="col" (click)="setContractCode(contract.code)">
					<h5 class="mb-0 ml-2 mt-3">
						{{contract.code}} - {{contract.name}}
						<p class="text-secondary">
							<small>Total de aferições: {{contractsCalibrations[contract.code].length }}</small>
						</p>
					</h5>
				</div>
				<div class="p-3">
					<button type="button" class="btn text-secondary btn-icon-block" (click)="setContractCode(contract.code)">
						<i class="material-icons" *ngIf="contractCode === contract.code; else elseBlock">unfold_less</i>
						<ng-template #elseBlock>
							<i class="material-icons">unfold_more</i>
						</ng-template>
					</button>
				</div>
			</div>
			<div *ngIf="contractCode === contract.code" class="limited-height">
				<table class="table bg-white">
					<thead>
						<tr>
							<th i18n *appPermission="'UpdateCalibration'">Habilitado</th>
							<th class="text-center" i18n
								*ngIf="columnsToShow.includes('type') || columnsToShow.includes('serialNumber')">Série/Tipo do
								Equipamento
							</th>
							<th class="text-center" i18n *ngIf="columnsToShow.includes('calibrationDate')">Data da Aferição</th>
							<th class="text-center" i18n *ngIf="columnsToShow.includes('effectivePeriod')">Período de Vigência
							</th>
							<th class="text-center" i18n *ngIf="columnsToShow.includes('lane')">Faixa
							</th>
							<th class="text-center" i18n *ngIf="columnsToShow.includes('sealNumber')">Nº do Lacre</th>
							<th class="text-center" i18n *ngIf="columnsToShow.includes('appraisalNumber')">Nº do Laudo</th>
							<th class="text-center" i18n *ngIf="columnsToShow.includes('expirationDays')">Dias para Vencimento
							</th>
							<th class="text-center" i18n *appPermission="['UpdateCalibration','DeleteCalibration']">Ações</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let calibration of contractsCalibrations[contract.code]">
							<td *appPermission="'UpdateCalibration'">
								<div class="custom-control custom-switch mt-2">
									<div class="form-group">
										<label class="custom-control-label" [ngClass]="{checked:calibration.enabled}">
											<input type="checkbox" class="form-control custom-control-input"
												*appPermission="'UpdateCalibration'; contracts calibration.contractId"
												[(ngModel)]="calibration.enabled" (change)="updateCalibrationEdit(calibration)">
										</label>
									</div>
								</div>
							</td>
							<td (click)="openViewModal(calibration)" class="text-center">
								<div *ngIf="columnsToShow.includes('serialNumber')">
									{{equipments && equipments[calibration.equipmentId]?.serialNumber || '-'}}
								</div>
								<i style="font-size: 12px" *ngIf="columnsToShow.includes('type')">
									{{getTypeCalibration(calibration.type)}}
								</i>
							</td>
							<td (click)="openViewModal(calibration)" class="text-center"
								*ngIf="columnsToShow.includes('calibrationDate')">
								{{calibration.date | moment: 'L'}}</td>
							<td (click)="openViewModal(calibration)" class="text-center"
								*ngIf="columnsToShow.includes('effectivePeriod')">
								{{calibration.startsAt | moment: 'L'}}<label i18n>&nbsp;a</label>&nbsp;{{calibration.endsAt | moment:
								'L'}}
							</td>
							<td (click)="openViewModal(calibration)" class="text-center" *ngIf="columnsToShow.includes('lane')">
								<div class="text-center"
									*ngIf="equipments[calibration.equipmentId] && equipments[calibration.equipmentId].laneIds && equipments[calibration.equipmentId].laneIds.length > 0">
									<div *ngFor="let value of equipments[calibration.equipmentId].laneIds"
										class="badge badge-fade-info mr-1">
										<span>{{lanesByEquipment[value] && lanesByEquipment[value].code}}&nbsp; - &nbsp;</span>
										<span i18n>Fx.</span>
										<span>&nbsp; {{lanesByEquipment[value] && lanesByEquipment[value].number}}</span>
										<span i18n>(Ponto</span>
										<span>&nbsp;
											{{
											lanesByEquipment[value] && lanesByEquipment[value].spotId &&
											spotById[lanesByEquipment[value].spotId] &&
											spotById[lanesByEquipment[value].spotId].code
											}})
										</span>
									</div>
								</div>
							</td>
							<td (click)="openViewModal(calibration)" class="text-center" *ngIf="columnsToShow.includes('sealNumber')">
								{{calibration.sealNumber || '-'}}
							</td>
							<td (click)="openViewModal(calibration)" class="text-center"
								*ngIf="columnsToShow.includes('appraisalNumber')">
								{{calibration.appraisal?.number || '-'}}</td>
							<td (click)="openViewModal(calibration)" class="text-center"
								[ngClass]="{'expiredDays': transform(calibration.endsAt) < 30}"
								*ngIf="columnsToShow.includes('expirationDays')">
								{{transform(calibration.endsAt)}}</td>
							<!-- to do  -->
							<td class="text-center" *appPermission="['UpdateCalibration','DeleteCalibration']">
								<div>
									<button type="button" class="btn text-secondary btn-icon-block" data-toggle="dropdown"
										aria-haspopup="true" aria-expanded="false">
										<i class="material-icons">more_horiz</i>
									</button>

									<div class="dropdown-menu" aria-labelledby="btnGroupDrop4">
										<a class="dropdown-item" (click)="openCreateOrUpdateCalibration(calibration)" i18n
											*appPermission="['UpdateCalibration']; contracts calibration.contractId">Editar Aferição</a>
										<a class="dropdown-item" (click)="openDeleteModal(calibration)" i18n
											*appPermission="['DeleteCalibration']; contracts calibration.contractId">Remover Aferição</a>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div *ngIf="!isloading && noData" class="pt-5">
		<p class="text-center text-secondary" i18n>Não há dados encontrados.</p>
	</div>
</div>
