import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Pagination } from '../models';
import { BaseApiService } from './baseApiService';
import { City } from '../models/City';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CityService extends BaseApiService<City> {
  get cacheKey(): StorageKey {
    return StorageKey.cityServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    if (operation === 'list') {
      const regionId = params.regionId && encodeURIComponent(params.regionId);
      return `${environment.apiUrl}/regions/${regionId}/cities`;
    }
    let url = `${environment.apiUrl}/cities`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  list(params: {[params: string]: string}): Promise<Pagination<City>> {
    return super.list(this.removeContractId(params));
  }

  async getById(id: string, force = false): Promise<City> {
    let city = null;
    if (force === false) {
      const cities = this.storageService.get(StorageKey.cities) || {};
      for (const stateId of Object.keys(cities)) {
        if (cities[stateId] != null && cities[stateId].length > 0) {
          city = cities[stateId].find(c => c.id === id);
          break;
        }
      }
    }
    if (city == null) {
      city = super.getById(id, force);
      city.then(c => {
        if (c != null && c.stateId != null) {
          const storageCities = this.storageService.get(StorageKey.cities) || {};
          if (storageCities[c.stateId] == null || storageCities[c.stateId].length === 0) {
            this.getAll({regionId: c.regionId, stateId: c.stateId }).then(data => {
              const citiesByState = {[c.stateId]: data};
              storageCities[c.stateId] = citiesByState;
            });
          } else {
            if (storageCities[city.stateId] != null) {
              let storageCity = storageCities[city.stateId].find(oldCity => oldCity.id === city.id);
              storageCity = Object.assign(storageCity, c);
            }
          }
          this.storageService.set(StorageKey.cities, storageCities);
        }
      });
    }
    return city;
  }

  async getAll(params: { [params: string]: string } = null, force = false) {
    let cities;
    if (force === false && params != null && params.stateId != null) {
      const storageCities = this.storageService.get(StorageKey.cities) || {};
      cities = storageCities && storageCities[params.stateId] || [];
    }

    if (cities == null || cities.length === 0) {
      cities = await super.getAll(params, force).then(data => {
        if (params != null ) {
          const citiesByState = {};
          for (const item of data) {
            if (citiesByState[item.stateId] == null) {
              citiesByState[item.stateId] = [];
            }
            citiesByState[item.stateId].push(item);
          }
          const storageCities = Object.assign(this.storageService.get(StorageKey.cities) || {}, citiesByState);
          this.storageService.set(StorageKey.cities, storageCities);
        }
        return data;
      });
    }

    return cities;
  }


  protected unparse(obj) {
    return City.unparse(obj);
  }

  protected parse(obj) {
    return City.parse(obj);
  }

}
