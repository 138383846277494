import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertItem, AlertType } from 'src/app/core/models';
import { AlertService, AuthenticationService, PasswordService, TokenService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.sass']
})
export class VerifyUserComponent implements OnInit {

  changePasswordForm: FormGroup;
  loading = false;
  token = '';
  userId = '';
  public forgotPassword = false;
  public theme = environment.theme;
  type = 'verify';
  public isTokenValid = false;
  public isValid = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private setPasswordService: PasswordService,
    private verifyTokenService: TokenService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.token = this.route.snapshot.queryParamMap.get('passwordToken') || this.route.snapshot.queryParamMap.get('verificationToken');
    this.forgotPassword = this.route.snapshot.queryParamMap.get('passwordToken') != null ? true : false;
    this.type = this.forgotPassword ? 'setPassword' : this.type;
    this.verifyToken();
    this.createForm();
    this.authenticationService.logout(false);
  }

  createForm(): void {
    this.changePasswordForm = this.formBuilder.group({
      name: [''],
      password: ['', [Validators.required]],
      passwordConfirmation: ['', [Validators.required]],
      privateTerm: ['']
    });
    if (!this.forgotPassword) {
      this.changePasswordForm.get('privateTerm').setValidators(Validators.required);
      this.changePasswordForm.get('name').setValidators([Validators.required, Validators.pattern('^([A-Za-zÀ-ÿ.]+( )?)+$')]);
    }

    this.changePasswordForm.valueChanges.subscribe(() => {
      if (!this.forgotPassword) {
        this.isValid = this.changePasswordForm.valid && this.changePasswordForm.get('privateTerm').value;
      } else {
        this.isValid = this.changePasswordForm.valid;
      }
    });
  }

  verifyToken(): void {
    if (this.token == null) {
      this.router.navigate(['invalidToken']);
    }
    this.verifyTokenService.verifyToken(this.token)
      .subscribe(
        res => {
          this.isTokenValid = true;
        },
        error => {
          this.router.navigate(['invalidToken']);
        }
      );
  }

  setPassword(): void {
    this.loading = true;
    const name = this.changePasswordForm.get('name').value;
    const password = this.changePasswordForm.get('password').value;
    const passwordConfirmation = this.changePasswordForm.get('passwordConfirmation').value;

    if (password !== passwordConfirmation || (!name && !this.forgotPassword)) {
      this.alertService.show(new AlertItem('PasswordMismatch', AlertType.danger));
      this.loading = false;
    } else {
      this.setPasswordService.setPassword(name, password, this.userId, this.token, this.type)
        .subscribe(
          res => {
            this.router.navigate(['']);
          },
          error => {
            this.loading = false;
          }
        );
    }
  }
}
