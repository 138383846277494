<div class="page-dashboard">
  <div class="page-dashboard__welcome">
    <div class="page-title">
      <h2 i18n>Olá, {{userName}}</h2>
      <p class="page-title__subtitle" i18n>Bem vindo à nova plataforma de <br />gestão de infrações e tráfego.</p>
    </div>

    <div class="page-dashboard__modules">
      <h6 i18n>Escolha o módulo para acessar</h6>

      <div class="row">
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['TriageViolation','TypingViolation','DoubleTypingViolation','VerifyViolation','ValidateViolation', 'ViewPreProcessSettings']">
          <a routerLink="/pre-process" class="page-dashboard__block">
            <i class="material-icons">burst_mode</i>
            <span i18n>Pré-Processamento</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['UpdateViolation', 'ViewViolation', 'ViolationsReport']">
          <a routerLink="/violations" class="page-dashboard__block">
            <i class="material-icons">error_outline</i>
            <span i18n>Infração</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
          <a (click)="click()" class="page-dashboard__block">
            <i class="material-icons">navigation</i>
            <span i18n>Tráfego</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
          <a (click)="click()" class="page-dashboard__block">
            <i class="material-icons">security</i>
            <span i18n>Segurança</span>
          </a>
        </div>
      </div>
    </div>

    <div class="page-dashboard__config-button">
      <a routerLink="/contracts" class="btn btn-fade-secondary btn-icon" i18n>
        <i class="material-icons">settings_applications</i> Configuração
      </a>
    </div>
  </div>

  <mat-accordion class="page-dashboard__info mat-elevation-z1">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
      <mat-expansion-panel-header>
        <b i18n>Bem vindo ao ECS !</b>
      </mat-expansion-panel-header>
      <p i18n>A nova plataforma de sistemas que busca trazer praticidade e facilidade para a Gestão e Operação através
        de módulos inovadores.</p>
      <p i18n>Nosso primeiro módulo de Pré Processamento traz algumas quebras de paradigmas, proporcionando um
        processamento em tempo real das infrações e uma abordagem voltada para gestão com validações automáticas e relatórios intuitivos.
        Além de uma tela de processamento otimizada para a melhor performance dos digitadores.</p>
      <p i18n>Enquanto o Pré Processamento continua em constante evolução, o novo módulo de mobilidade inteligente vem
        sendo construído e em breve estará disponível.</p>
      <p i18n>Em caso de dúvidas, problemas ou sugestões, acesse:</p>
      <p class="mt-4">
        <a href="https://engebras.atlassian.net/servicedesk/customer/portal/1/group/6" target="_blank"
          class="btn btn-fade-primary btn-icon" i18n>
          <i class="material-icons">contact_support</i> Atendimento
        </a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel *appPermission="['ViewActivity']" [expanded]="step === 1" (opened)="setStep(1)">
      <mat-expansion-panel-header>
        <b i18n>Histórico de atividades</b>
      </mat-expansion-panel-header>
      <app-enum-i18n [input]="actions" (output)="actionsI18n=$event"></app-enum-i18n>
      <app-enum-i18n [input]="models" (output)="modelsI18n=$event"></app-enum-i18n>
      <div class="loading-spinner" *ngIf="isloading">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
      <div [ngSwitch]="activities" *ngIf="!isloading">
        <div *ngSwitchCase="null">
          Carregando lista ...
        </div>
        <div *ngSwitchCase="0">
          Nenhuma atividade registrada ...
        </div>

        <div *ngSwitchDefault>
          <div class="table-flex__row page-dashboard__activity-history-list" *ngFor="let activity of activities">
            <!-- <div class="w-15">
              {{usersById[activity.userId].name | json}}
              <app-avatar [user]="usersById[activity.userId]" class="avatar-header"></app-avatar>
            </div> -->
            <div class="w-60">
              {{(activity.userId && usersById && usersById[activity.userId] && usersById[activity.userId].name || activity.userId)}}: {{parseActionLabel(activity.actionId) }} <br />
             <b *ngIf="activity.contract">
               {{activity.contract.code}} - {{activity.contract.name}}
             </b>
            </div>
            <div class="w-15">
              {{ activity.date | moment: 'L LTS' }}
            </div>
          </div>

          <p class="mt-4">
            <a routerLink="/activities" class="btn btn-fade-primary btn-icon" i18n>
              <i class="material-icons">add_circle</i> Ver mais
            </a>
          </p>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>
