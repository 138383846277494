/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-lane-select-weekdays',
  templateUrl: './lane-select-weekdays.component.html',
  styleUrls: ['./lane-select-weekdays.component.sass']
})
export class LaneSelectWeekdaysComponent extends BaseModal implements OnInit {
  public values: string[] = [];
  public isSingle = false;
  public weekdays: string[] = [];
  public weekForm: FormGroup;

  get isValid() {
    return this.values.length > 0;
  }
  lastState: any;

  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.weekdays = this.initialState || [];
    this.createForm();
  }

  private createForm() {
    this.weekForm = new FormGroup({});
    this.weekdays.forEach(day => {
      this.weekForm.addControl(day, new FormControl());
    });

    this.weekForm.valueChanges.subscribe(form => {
      this.values = [];
      this.weekdays.forEach(day => {
        if (form[day]) {
          this.values.push(day);
        }
      });
    });
  }

  allClick() {
    const isAllSelected = this.values.length !== 7;
    this.weekdays.forEach(day => {
      this.weekForm.patchValue({
        [day]: isAllSelected
      });
    });
  }

  onAllSubmited(): Promise<any> {
    return Promise.resolve(this.values);
  }

}
