<div class="modal-dialog modal-dialog-centered modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <b class="modal-title container" i18n>{{lot.name}}</b>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body" *ngIf="loading; else loadingBlock" #container>
      <div class="container">
        <div class="capture-block">
          <div class="row">
            <div class="col col-md-3">
              <small i18n>Status</small><br />
              <small *ngIf="lot.status==='closed'" i18n>Fechado</small>
              <small *ngIf="lot.status==='new'" i18n>Novo</small>
              <small *ngIf="lot.status==='open'" i18n>Aberto</small>
              <small *ngIf="lot.status==='process'" i18n>Processar</small>
              <small *ngIf="lot.status==='audit'" i18n>Auditoria</small>
              <small *ngIf="lot.status==='done'" i18n>Concluído</small>
              <small *ngIf="lot.status==='error'" i18n>Erro</small>
              <small *ngIf="!lot.status" i18n>-</small>
            </div>
            <div class="col col-md-9">
              <small>Período Das Infrações</small><br />
              <small>{{ lot.newestViolationAt | moment: 'L LTS' }} - {{lot.oldestViolationAt | moment: 'L LTS'}}</small>
            </div>
          </div>
          <div class="mt-4 table-responsive table-block" *ngIf="lot.audits">
            <table class="table table-bordeless table-striped data-table">
              <thead>
                <tr>
                  <th>Iniciou Auditoria Em</th>
                  <th>Finalizou Auditoria Em</th>
                  <th>Auditor Responsável</th>
                </tr>
              </thead>
              <tr *ngFor="let audit in lot.audits">
                <td i18n>{{ audit.startsAt | moment: 'L LTS' }}</td>
                <td i18n>{{ audit.endsAt | moment: 'L LTS' }}</td>
                <td i18n>{{ audit.author || '-' }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loadingBlock>
      <div class="loading-page">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
