import { Component, Input, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import palette from 'google-palette';
import { ChartItem } from 'src/app/core/models/ChartItem';
import * as _ from 'lodash';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.sass']
})
export class ChartComponent {
  @ViewChild('chart', { static: false }) chart;
  @Input() xLabel: string;
  @Input() yLabel: string;

  private _items: ChartItem[];
  private _colors: string[];
  private _type: string;
  private _chart: Chart;
  private _hasLegend: boolean;

  @Input() set items(items: ChartItem[]) {
    this._items = items;
    if (items != null) {
      this.loadChart();
    }
  }
  get items() {
    return this._items;
  }

  @Input() set colors(colors: string[]) {
    if (_.isEqual(colors, this._colors)) {
      return;
    }
    this._colors = colors;
    if (colors != null) {
      this.loadChart();
    }
  }
  get colors() {
    return this._colors;
  }

  @Input() set type(type: string) {
    this._type = type;
    if (type != null) {
      this.loadChart();
    }
  }
  get type() {
    return this._type;
  }

  @Input() set hasLegend(hasLegend: boolean) {
    this._hasLegend = hasLegend;
    if (hasLegend == null) {
      this._hasLegend = true;
    }
  }

  get hasLegend() {
    return this._hasLegend;
  }


  constructor() { }

  loadChart() {
    if (this.items != null) {
      switch (this.type) {
        case 'pie':
          this.loadPieChart();
          break;
        case 'bar':
          this.loadBarChart();
          break;
      }
    }
  }

  loadPieChart() {
    const data: any = { datasets: [], labels: [] };
    const values = {};
    this.items.forEach(item => {
      Object.keys(item.values).forEach((key) => {
        if (!data.labels.includes(key)) {
          data.labels.push(key);
        }
        if (values[key] == null) {
          values[key] = 0;
        }
        values[key] += item.values[key];
      });
    });

    const dataset = { data: [] };
    dataset.data = Object.values(values);
    data.datasets.push(dataset);

    if (this.colors != null && this.colors.length > 0) {
      data.datasets.forEach(item => {
        item.backgroundColor = this.colors;
      });
    } else {
      data.datasets.forEach(item => {
        this.colors = item.data.map(() => this.getRandomColor());
        item.backgroundColor = this.colors;
      });
    }

    this.initChart(data, {});
  }

  loadBarChart() {
    const data: any = { datasets: [], labels: [] };
    this.items.forEach(item => {
      const dataset: any = { data: []};
      dataset.label = item.label;
      Object.keys(item.values).forEach(key => {
        if (!data.labels.includes(key)) {
          data.labels.push(key);
        }
        dataset.data.push(item.values[key]);
      });
      data.datasets.push(dataset);
    });

    if (this.colors && this.colors.length > 0) {
      data.datasets.forEach((item, index) => {
        item.backgroundColor = this.colors[index];
      });
    } else {
      this.colors = data.datasets.map(() => this.getRandomColor());
      data.datasets.forEach((item, index) => {
        item.backgroundColor = this.colors[index];
      });
    }

    const options = {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      hasLegend: {
        display: this.hasLegend,
          labels: {
            display: this.hasLegend
          }
      }
    };
    this.initChart(data, options);
  }

  initChart(data, options) {
    if (this.chart == null) {
      return;
    }

    const canvas: any = this.chart.nativeElement;
    const ctx = canvas.getContext('2d');

    const config = {
      data,
      type: this.type,
      options
    };
    if (this._chart == null) {
      this._chart = new Chart(ctx, config);
    } else {
      this._chart.destroy();
      this._chart = new Chart(ctx, config);
    }
  }

  getRandomColor() {
    const colors = palette('rainbow', 100, 1, 0.7);
    return `#${colors[Math.floor((Math.random() * colors.length))]}`;
  }
}
