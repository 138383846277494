<div class="card-header">
  <h5 i18n class="my-2 font-weight-bold">Gerais</h5>
</div>

<div class="card-body">
  <form [formGroup]="form">
    <div class="row mb-4">
      <div class="col-md-6">
        <app-enum-i18n [input]="vehicleDataSearchTypes" (output)="vehicleDataSearchTypesI18n=$event"></app-enum-i18n>
        <app-input i18n-label i18n-placeholder label="Selecione qual o método para pesquisa de dados veículares"
          inputRequired=true type="select" selectText="value" selectValue="id" [list]="vehicleDataSearchTypesI18n"
          formControlName="vehicleDataSearch" placeholder="Selecione qual o método de pesquisa veícular">
        </app-input>
      </div>
      <div class="col-md-6">
        <label i18n>Permitir validação caso a placa não seja encontrada: <i i18n-appTooltip
            class="material-icons icon-sm text-secondary" data-placement="top" appTooltip="Caso esta opção esteja selecionada, após realizar a pesquisa de placa e não retornar os dados do veículo,
           o sistema irá permitir a inserção manual dos dados.">help</i></label>
        <mat-radio-group formControlName="allowInputNotFoundVehicle" color="primary" aria-label="Select an option">
          <mat-radio-button [value]=true class="mr-3" i18n>Sim</mat-radio-button>
          <mat-radio-button [value]=false [checked]="!allowInputNotFoundVehicle" i18n>
            Não</mat-radio-button>
        </mat-radio-group>
      </div>
      <!-- <div class="col-md-4" *ngIf="preProcessSettings?.vehicleDataSearch !== 'disabled'">
        <label i18n>Consultar Dados Veículares na base interna: <i i18n-appTooltip
            class="material-icons icon-sm text-secondary" data-placement="top" appTooltip="Caso esta opção esteja selecionada, após realizar a pesquisa de placa e não retornar os dados do veículo,
           o sistema irá permitir a inserção manual dos dados.">help</i></label>
        <mat-radio-group formControlName="allowInputNotFoundVehicle" color="primary" aria-label="Select an option">
          <mat-radio-button [value]=true class="mr-3" i18n>Sim</mat-radio-button>
          <mat-radio-button [value]=false i18n>Não</mat-radio-button>
        </mat-radio-group>
      </div> -->
    </div>
    <div class="row mb-4" *ngIf="preProcessSettings?.vehicleDataSearch !== 'disabled'">
      <div class="col-12">
        <mat-checkbox i18n color="primary" formControlName="changeBrandModelForClientBrand"> Substituir o campo "Marca/Modelo" pelo Campo "Marca do Cliente" </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso esta opção esteja selecionada, somente os campos 'Placa' e 'Marca' serão considerados como obrigatórios">help</i>
      </div>
    </div>

    <div class="mb-4">
      <label i18n>Selecione as informações relacionadas ao veículo que devem ser consideradas de preenchimento
        obrigatório: <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top"
          appTooltip="Os itens que não estiverem selecionados como obrigatório, serão exibidos inativos nas telas de processamento de infração.">help</i></label>

      <div class="row">
        <div class="col-6 col-sm-4 col-lg-3">
          <mat-checkbox i18n color="primary" formControlName="allRequiredFields">Todos</mat-checkbox>
        </div>
        <app-enum-i18n [input]="vehicleRequiredFields" (output)="vehicleRequiredFieldsI18n=$event"></app-enum-i18n>
        <div class="col-6 col-sm-4 col-lg-3" *ngFor="let field of vehicleRequiredFieldsI18n">
          <mat-checkbox color="primary" [formControlName]="field.id" i18n>{{field.value}}</mat-checkbox>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <app-input i18n-label i18n-placeholder placeholder="Selecione um motivo de invalidação"
        label="Selecione o motivo de invalidação que será aplicado para placas cadastradas na lista branca"
        inputRequired=true type="select" selectText="prettyName" selectValue="id" [list]="reasons"
        formControlName="whitelistReasonId">
      </app-input>
    </div>

    <div class="card-divider"></div>

    <h5 class="font-weight-bold" i18n>Quarentena
      <i i18n-appTooltip data-html="true" class="material-icons icon-sm text-secondary" appTooltip="Defina as configurações das infrações na etapa de 'Quarentena'.
        <br>
        Serão encaminhadas para esta etapa as infrações que não passarem pelas validações de negócio.
        <br>
        Após expiração do período máximo da infração nesta etapa, os registros serão invalidados automaticamente com o motivo definido e encaminhado para a etapa configurada.
        <br>
        O período máximo está limitado em até 60 dias.">help</i>
    </h5>

    <div class="row">
      <div class="col-12 col-sm-3">
        <app-input i18n-label i18n-rightUnit label="Período máximo" type="number" min="1" max="60" rightUnit="dias"
          formControlName="quarantineMaxPeriod" inputRequired=true>
        </app-input>
      </div>
      <div class="col-12 col-sm-5">
        <app-enum-i18n [input]="violationSteps" (output)="violationStepsI18n=$event"></app-enum-i18n>
        <app-input i18n-label i18n-placeholder label="Próxima etapa para infrações recusadas" type="select"
          selectText="value" selectValue="id" [list]="violationStepsI18n" formControlName="quarantineRefused"
          placeholder="Selecione uma etapa" inputRequired=true>
        </app-input>
      </div>
      <div class="col-12 col-sm-4">
        <app-input i18n-label label="Motivo de invalidação" type="select" selectText="prettyName" selectValue="id"
          [list]="reasons" formControlName="quarantineReasonId" placeholder="Selecione um motivo" inputRequired=true>
        </app-input>
      </div>
      <div class="col-12">
        <label i18n>Permitir validação de velocidade discrepante?
          <i i18n-appTooltip class="material-icons icon-sm text-secondary"
            appTooltip="Serão encaminhadas para Quarentena com o motivo de 'Velocidade Discrepante'
            as infrações em que a velocidade registrada seja superior a porcentagem definida em relação à velocidade regulamentada da via.">
            help
          </i><small class="text-danger">*</small>
        </label>
        <br />
        <mat-radio-group formControlName="allowValidateNotOddSpeed" required="true">
          <mat-radio-button i18n color="primary" style="margin-right: 3rem;" [value]="true">Sim</mat-radio-button>
          <mat-radio-button i18n color="primary" style="margin-right: 3rem;" [value]="false">Não</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row">
        <div class="col-8 " class="align-text-label">
          <label i18n>Considerar velocidade discrepante a partir de</label>
        </div>
        <div class="col-2" class="align-input">
          <input type="number" min="1" max="100" formControlName="setPercentageOddSpeed" required="true">
        </div>
        <div class="col-2" class="align-text-label1">
          <label i18n>% da velocidade regulamentada na via.<small class="text-danger">*</small></label>
        </div>
      </div>
      <br />
      <div class="col-12">
        <label i18n>Realizar validações de negócio nas infrações com aferição não metrológica?
          <i i18n-appTooltip class="material-icons icon-sm text-secondary"
            appTooltip="Serão realizadas as validações de negócio nas infrações com aferição não metrológica (data da aferição, laudo pendente ou inválido e vigência da aferição)">
            help
          </i>
        </label>
        <br />
        <mat-radio-group formControlName="verifyNoMetrologicalCalibrationValidity">
          <mat-radio-button i18n color="primary" style="margin-right: 3rem;" [value]="true">Sim</mat-radio-button>
          <mat-radio-button i18n color="primary" style="margin-right: 3rem;" [value]="false">Não</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="card-divider"></div>
    <h5 class="font-weight-bold" i18n>Configurações relacionadas às etapas de processamento</h5>

    <div class="row" *ngIf="isSkipTypingStep || isShowDataEditing || isAlertOddSpeed">
      <div class="col-12" *ngIf="isSkipTypingStep">
        <mat-checkbox i18n color="primary" formControlName="typingOcrSkip"> Caso tenha leitura por OCR pular etapa de
          Digitação </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso esta opção esteja selecionada, os registros com a placa identificada pelo OCR do equipamento irão desconsiderar a etapa de Digitação e seguir para a próxima etapa configurada">help</i>
      </div>

      <div class="col-12 tooltip-div" *ngIf="isShowDataEditing">
        <mat-checkbox i18n color="primary" formControlName="changesPermited"> Deverá permitir a edição dos dados do
          veículo nas etapas de Verificação </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso esta opção esteja selecionada, os dados do veículos ficarão habilitados para edição nas etapas de Verificação">help</i>
      </div>

      <div class="col-12" *ngIf="isAlertOddSpeed">
        <mat-checkbox i18n color="primary" formControlName="alertOddSpeed"> Alertar velocidade discrepante
        </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso este campo esteja ativo, as infrações com velocidade acima de 100% da regulamentada para via serão exibidas com um alerta visual nas telas de processamento.">help</i>
      </div>
    </div>

    <label i18n> <br>A permanência máxima da infração nas etapas de processamento é de 90 dias.
      Após expiração deste período os registros serão deletados do sistema.</label>

    <div class="card-divider"></div>
    <h5 class="font-weight-bold" i18n>Relatórios</h5>

    <p class="text-secondary mb-4" i18n>Indicação de registros críticos no Relatório de Mapa de Etapas:</p>

    <div class="row">
      <div class="col">
        <app-input i18n-label i18n-rightUnit label="Infração" type="text" rightUnit="dias"
          formControlName="violationAgeAlert" inputRequired=true>
        </app-input>
      </div>
      <div class="col">
        <app-input i18n-label i18n-rightUnit label="Lote" type="text" rightUnit="dias" formControlName="lotAgeAlert"
          inputRequired=true>
        </app-input>
      </div>
    </div>

    <div class="card-divider"></div>
    <h5 class="font-weight-bold" i18n>Configuração de lotes</h5>

    <div class="row mt-3 mb-1">
      <div class="col">
        <mat-checkbox color="primary" formControlName="exportOriginalFiles">
          <span i18n>Caso ocorra alteração nas imagens durante o processamento as originais também devem ser
            exportadas</span>
        </mat-checkbox>
      </div>
    </div>
  </form>
</div>
