<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h5 *ngIf="reason.id; else titleBlock" class="modal-title container" i18n>Editar Motivo de Invalidação</h5>
        <ng-template #titleBlock>
          <h5 class="modal-title container" i18n>Novo Motivo de Invalidação</h5>
        </ng-template>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="reasonForm" (submit)="onSubmit()">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <app-input i18n-label="@@ContractLabel" [type]="reason && reason.id ? 'text' : 'select'"
                formControlName="contractId" inputRequired=true label="Selecione o contrato" [inputDisabled]="reason.id"
                [list]="contracts" i18n-placeholder placeholder="Selecione o contrato" selectValue="id"
                selectText="prettyName">
              </app-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-4">
            <div class="form-group">
              <app-input i18n-label="@@Code" type="text" formControlName="code" inputRequired=true label="Código"
                [inputDisabled]="reason.id">
              </app-input>
            </div>
          </div>

          <div class="col col-md-8">
            <div class="form-group">
              <app-input i18n-label="@@Name" type="text"
                inputRequired=true formControlName="name" label="Nome">
              </app-input>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col col-md-5">
            <div class="form-group">
              <app-input i18n-label i18n-placeholder placeholder="Selecione os enquadramentos"
              label="Enquadramentos"  inputRequired=true
              type="multiselect" selectText="shortDescription" selectValue="id" [list]="regulations"
              selectShowLimit=3
              formControlName="regulationIds">
            </app-input>
            </div>
          </div>
          <div class="col col-md-4">
            <div class="form-group">
              <app-enum-i18n [input]="reasonTypes" (output)="reasonTypesI18n=$event"></app-enum-i18n>
              <app-input i18n-placeholder="@@ReasonTypes" i18n-label="@@ReasonTypesLabel"
              type="select" [list]="reasonTypesI18n" label="Tipo" formControlName="type"
              selectText="value" selectValue="id" placeholder="{{reasonType}}" inputRequired=true>
            </app-input>
            </div>
          </div>
          <div class="col col-md-3">
            <label i18n>Habilitado?
              <div class="custom-control custom-switch">
                <div class="form-group mt-1">
                  <label class="custom-control-label" [ngClass]="{checked: reasonForm.get('enabled').value}">
                    <input type="checkbox" class="form-control custom-control-input" formControlName="enabled">
                  </label>
                </div>
              </div>
            </label>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" (click)="onCancel()">
        <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
      </button>
      <button class="btn btn-primary" (click)="onSubmit()" [ngClass]="{disabled: !isValid}">
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
