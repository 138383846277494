import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AlertItem } from '../models/AlertItem';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private events = new Subject<AlertItem>();

  getEvents(): Observable<any> {
    return this.events.asObservable();
  }

  show(item: AlertItem) {
    this.events.next(item);
  }

  complete() {
    this.events.complete();
  }
}
