import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClosedModalError } from 'src/app/core/errors';
import { AlertItem, AlertType, Company, ComponentModal, User } from 'src/app/core/models';
import { AlertService, CompanyService, ModalService,
  StorageKey, StorageService, UserService, UserGroupService } from 'src/app/core/services';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { AvatarService } from 'src/app/core/services/avatar.service';
import { Subscription } from 'rxjs';
import {Location} from '@angular/common';
import { validateCpf } from 'src/app/core/utils/validateCpf';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.sass']
})
export class MyProfileComponent implements OnInit, OnDestroy {
  public userEditForm: FormGroup;
  public isValid = false;
  public user: User;
  public company: Company;
  public groups: any[] = [];
  public userInfo: any = {};
  public loading: boolean;
  public promise: Promise<any>;
  private _subs: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private companyService: CompanyService,
    private modalService: ModalService,
    private storageService: StorageService,
    private avatarService: AvatarService,
    private _location: Location
  ) { }

  ngOnInit() {
    const user = this.storageService.get(StorageKey.currentUser);

    if (user != null) {
      this.user = User.create(user);
      this.companyService.getById(this.user.companyId).then(company => {
        this.userInfo.company = company.name;
        this.createForm();
        this.loading = true;
      });
    }

    if (this.user == null) {
      this.user = User.create({});
      this.createForm();
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  createForm(): void {
    this.userEditForm = this.formBuilder.group({
      email: [this.user.email],
      role: [this.user.role],
      cpf: [this.user.cpf || ''],
      companyId: this.userInfo.company,
      name: [this.user.name, Validators.required],
      registryNumber: this.user.registryNumber,
    });
    this._subs.push(
      this.userEditForm.valueChanges.subscribe(() => {
        this.isValid = this.userEditForm.valid;
      })
    );
  }

  submit() {
    if (this.promise != null) {
      return this.promise;
    }

    const cpf = this.userEditForm.get('cpf').value;
    if(cpf && !validateCpf(cpf)){
      return this.alertService.show(new AlertItem('InvalidCpf', AlertType.danger));
    }

    const user = User.create({
      cpf: cpf.replace(/\D+/g, ''),
      id: this.user.id,
      email: this.user.email,
      companyId: this.user.companyId,
      avatar: this.user.avatar,
      superUser: this.user.superUser,
      groupIds: this.user.groupIds,
      role: this.userEditForm.get('role').value || '',
      name: this.userEditForm.get('name').value,
      registryNumber: this.userEditForm.get('registryNumber').value || '',
      modifiedAt: this.user.modifiedAt
    });

    this.promise = this.userService.update(user).then(res => {
      this.storageService.set(StorageKey.currentUser, res);
      this.alertService.show(new AlertItem('UserSaved', AlertType.success));
      this.avatarService.updateMyProfile(res);
      this._location.back();
      return res;
    }).catch(error => {
      this.alertService.show(new AlertItem('UserSaveError', AlertType.danger));
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }

  cancel() {
    this._location.back();
  }

  openChangePassword() {
    this.modalService.show(new ComponentModal(ChangePasswordModalComponent, this.user))
    .catch(err => {
      if (err instanceof ClosedModalError) {
        const modalError = err as ClosedModalError;
      }
    });
  }
}
