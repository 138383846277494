/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Violation, Session } from '../models';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { map, catchError } from 'rxjs/operators';
import { StorageKey } from './storage.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ViolationService extends BaseApiService<Violation> {
  get cacheKey(): StorageKey {
    return StorageKey.violationServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/violations`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    if (operation === 'accept' || operation === 'refuse' || operation === 'imageAdjust' ||
      operation === 'imageRestore' || operation === 'unlock' || operation === 'lockedByMe' || operation === 'ait') {
      url = url + '/' + operation;
    }
    return url;
  }

  protected unparse(obj) {
    return Violation.unparse(obj);
  }

  protected parse(obj) {
    return Violation.parse(obj);
  }

  public accept(model: Violation): Promise<Violation> {
    const url = this.getUrl('accept', null, model.id);
    return this.updateBase(model, url);
  }

  public refuse(model: Violation): Promise<Violation> {
    const url = this.getUrl('refuse', null, model.id);
    return this.updateBase(model, url);
  }

  public updateImage(id, settingsImage): Promise<Violation> {
    const url = this.getUrl('imageAdjust', null, id);
    return this.http.post(url, this.parse(settingsImage)).pipe(
      catchError(this.handleError),
      map(m => this.setCache(m.id, m))
    ).toPromise();
  }

  private updateBase(model: Violation, url): Promise<Violation> {
    return this.http.put(url, this.parse(model)).pipe(
      catchError(this.handleError),
      map(m => this.setCache(m.id, m))
    ).toPromise();
  }

  public restoreImage(id, settingsImage): Promise<Violation> {
    const url = this.getUrl('imageRestore', null, id);
    return this.http.post(url, this.parse(settingsImage)).pipe(
      catchError(this.handleError),
      map(m => this.setCache(m.id, m))
    ).toPromise();
  }

  public unlock(userId: string, violationIds: string[] = []): Promise<Array<Violation>> {
    const url = this.getUrl('unlock', null, null);
    const body = { userId, violationIds };

    return this.http.put(url, body).pipe(
      catchError(this.handleError),
      map(res => res.map(violation => this.setCache(violation.id, violation)))
    ).toPromise();
  }

  public session(contractId, step): Promise<Session> {
    const url = `${environment.apiUrl}/session/start`;
    const body = { contractId, step };

    return this.http.post(url, body).pipe(
      catchError(this.handleError),
      map(res => res)
    ).toPromise();
  }


  recountSession(contractId: any): Promise<any>{
    const url = `${environment.apiUrl}/sessions/recount/${contractId}`;
    return this.http.put(url, { }).pipe().toPromise();
  }

  public getLockedViolationsByMe(next, params = {}): Promise<Pagination<Violation>> {
    const url = this.getUrl('lockedByMe', null, null);
    let req: Observable<any>;
    if (next != null) {
      req = this.http.get(`${environment.apiUrl}${next}`);
    } else {
      const httpParams = new HttpParams({
        fromObject: params as { [params: string]: string }
      });
      req = this.http.get(url, { params: httpParams });
    }
    return req.pipe(
      catchError((responseError) => this.handleError(responseError)),
      map(body => {
        body.result = body.result.map(model => this.unparse(model));
        this.setCacheList(body.result);
        return body;
      })
    ).toPromise();
  }

  public ait(id, params = {}) {
    const url = this.getUrl('ait', null, id);
    const httpParams = new HttpParams({
      fromObject: params as { [params: string]: string }
    });
    const req: Observable<any> = this.http.get(url, { params: httpParams });
    return req.pipe(
      catchError((responseError) => this.handleError(responseError))
    ).toPromise();
  }
}
