<div>
    <h1>404</h1>
    <p class="mb-5" i18n>Oops.. Algo deu errado...</p>
    <p class="text-secondary mt-5 mb-5" i18n>Não conseguimos encontrar a página que você está procurando.</p>
</div>

<div class="mt-5">
    <button [routerLink]="['']" class="btn btn-fade-info btn-icon" i18n>
        <i class="material-icons">subdirectory_arrow_left</i>
        Retornar para o dashboard</button>
</div>
