import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ViolationService, ModalService, ViolationReportService, ViewViolationService, ContractService, SpotService,
  StorageService,
  ViolationCsvService,
  StorageKey,
  AlertService
} from 'src/app/core/services';
import * as moment from 'moment';
import { Lane, Reason, Regulation, Spot, Violation, ComponentModal,
  Category, Contract, MessageModal, AlertItem, AlertType } from 'src/app/core/models';
import { DetailsViolationComponent } from 'src/app/modals/details-violation/details.component';
import {LoteInfoComponent} from 'src/app/modals/lote-info/lote-info.component';
import { QuarantineStatus } from 'src/app/core/models/Quarantine';

@Component({
  selector: 'app-violations-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.sass']
})
export class ViolationsReportDetailsComponent implements OnInit, OnDestroy {
  public contractId: any = {};
  public dateId: string;
  public laneId: string;
  public laneCode: any = [];
  public spotInfo: string;
  public laneInfo: string;
  public hideLane: boolean;
  public hideSpot: boolean;
  public loading: boolean;
  public contractDataViolationsSub: Subscription;
  public filterViewViolationsSub: Subscription;
  public filteredParamsViolationsSub: Subscription;
  public violations: Violation[] = [];
  public lanes: Lane[] = [];
  public spots: Spot[] = [];
  public regulations: Regulation[] = [];
  public reasons: Reason[] = [];
  public categories: Category[] = [];
  public params: any = {};
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public violationsSteps: any[] = [
    'validateLane',
    'validateCalibration',
    'validateSpeed',
    'validateFiles',
    'validateExemptPeriod',
    'validateViolationLimit',
    'verifyValid',
    'verifyInvalid',
    'triage',
    'typing',
    'verify',
    'validate',
    'lotAttribution',
    'internalAudit',
    'revision',
    'filter',
    'done',
    'quarantine',
    'verifyCFTV',
    'validateCFTV',
    'monitoringCFTV',
  ];
  public violationsStepsResource: any[] = [];
  public periodViolationsSub: Subscription;
  public startDate;
  public endDate;
  public contract: Contract;
  public ready = false;
  public quarantineStatusI18n = [];
  public quarantineStatus = Object.values(QuarantineStatus);
  public filter: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    public violationService: ViolationService,
    private violationReportService: ViolationReportService,
    private viewViolationService: ViewViolationService,
    private contractService: ContractService,
    private spotService: SpotService,
    private violationCSVService: ViolationCsvService,
    public storageService: StorageService,
    private alertService: AlertService,
  ) { }

  async ngOnInit() {
    if (this.activatedRoute.snapshot.parent) {
      this.contractId = this.activatedRoute.snapshot.parent.params.contractId;
    }

    this.dateId = this.activatedRoute.snapshot.params.dateId;
    this.periodViolationsSub = this.violationReportService.getPeriodViolationReport()
      .subscribe((dataPeriod: any) => {
        this.startDate = dataPeriod.startDate;
        this.endDate = dataPeriod.endDate;
        if (!this.startDate && !this.endDate) {
          const now = new Date().toISOString();
          this.startDate = moment(now).subtract(7, 'days').utc().format();
          this.endDate = moment(now).utc().format();
        }
        this.params['date[gte]'] = dataPeriod.startDate;
        this.params['date[lte]'] = dataPeriod.endDate;
      });

    if (!this.startDate && !this.endDate) {
      const now = new Date().toISOString();
      this.startDate = moment(now).subtract(7, 'days').utc().format();
      this.endDate = moment(now).utc().format();
    }
    const endHour = { hour: 23, minutes: 59, seconds: 59 };
    this.endDate = moment(this.endDate).set(endHour).format();
    this.params = {};
    this.params['date[gte]'] = this.startDate;
    this.params['date[lte]'] = this.endDate;
    this.params.contractId = this.contractId;
    await this.contractService.getById(this.contractId).then(contract => {
      this.contract = contract;
    });

    this.contractDataViolationsSub = this.violationReportService.getContractDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.regulations = data.regulations;
        this.lanes = data.lanes;
        this.spots = data.spots;
        this.reasons = data.reasons;
        this.loading = true;
      });

    this.filteredParamsViolationsSub = await this.violationReportService.getFilterParamsDetailsViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe(async (data: any) => {
        if (data.spotsIds && data.spotsIds.length > 0) {
          const lanesWithSpot = this.lanes.filter(lane => data.spotsIds.includes(lane.spotId)).map(lane => lane.code);
          if (lanesWithSpot.length > 0) {
            this.params['laneCode[in]'] = `[${lanesWithSpot.join()}]`;
          }
          this.filter.spotsIds = data.spotsIds;
        }

        if (data.laneIds && data.laneIds.length > 0) {
          const laneIds =  data.laneIds.map(Number);
          const lanesCode = this.lanes.filter(lane => laneIds.includes(lane.id)).map(lane => lane.code);
          this.params['laneCode[in]'] = `[${lanesCode.join()}]`;
          this.filter.laneIds = data.laneIds;
        }

        if (data.regulationsIds && data.regulationsIds.length > 0) {
          this.params['regulationId[in]'] = `[${data.regulationsIds.join()}]`;
          this.filter.regulationsIds = data.regulationsIds;
        }

        if (data.situationsIds && data.situationsIds.length > 0) {
          if (data.situationsIds.length === 1 && data.situationsIds.includes('inProcess')) {
            this.params['step[neq,and]'] = 'done';
            this.params['step[neq]'] = 'lotAttribution';
          }

          if (data.situationsIds.length > 1 && data.situationsIds.includes('inProcess')) {
            this.params['status[in]'] = `[invalid,valid]`;
          }

          if (!data.situationsIds.includes('inProcess')) {
            this.params['status[in]'] = `[${data.situationsIds.join()}]`;
            this.params['step[in]'] = `[lotAttribution, done]`;
            this.filter.steps = ['lotAttribution', 'done'];
          }
          this.filter.situationsIds = data.situationsIds;
        }
        this.params.order = 'date';

        if (data.reasonsIds && data.reasonsIds.length > 0 && data.situationsIds.includes('invalid')
          && data.reasonsIds.length < this.reasons.length) {
          const reasonsCode = this.reasons.filter(reason => data.reasonsIds.includes(reason.id)).map(reason => reason.code);
          this.params['reason.code'] = reasonsCode;
        }
        if (data.date != null) {
          delete this.params['date[gte]'];
          delete this.params['date[lte]'];
          const dateStart = `${data.date}T00:00:00`;
          const dateEnd = `${data.date}T23:59:59`;
          this.params['date[gte]'] = moment(dateStart).utc().format();
          this.params['date[lte]'] = moment(dateEnd).utc().format();
        }

        if (data.spotsIds && data.spotsIds.length > 0) {
          let spotCode = '';
          await this.spotService.getById(data.spotsIds[0]).then(spot => {
            spotCode = spot.code;
          }).catch (err => {
            console.log(err);
          });
          this.params.spotCode = spotCode;
        }
      });

      this.ready = true;
  }

  setViolations(list) {
    this.violations = list;

    if (this.regulations.length > 0) {
      this.loading = true;
    }

    this.violations.map(violation => {
      this.laneCode.push(violation.laneCode);
    });
    this.getSpotInfo(this.laneCode);
    this.getLaneInfo(this.laneCode);
  }

  getRegulationCode(regulation) {
    return this.regulations.filter(item => item.id === regulation).map(item => item.code).shift();
  }

  getRegulationInfo(regulation) {
    if (this.regulations.length > 0) {
      const regulationInfo = this.regulations.filter(item => item.id === regulation).shift();
      return regulationInfo.description;
    }
  }

  getAuthorInfo(violation) {
    let item;
    if (violation) {
      if (this.getNormalizedHistory(violation)[0].id === 'lotAttribution') {
        item = this.getNormalizedHistory(violation)[1];
      } else {
        item = this.getNormalizedHistory(violation).shift();
      }
    }
    return item?.authorName || null;
  }

  getHistories(history) {
    return this.getNormalizedHistory(history);
  }

  getNormalizedHistory(violationsHistory: []) {
    const resultNormalized = [];
    Object.entries(violationsHistory || []).map(item => {
      const id: string = item[0];
      const data: any = item[1];
      if (Array.isArray(data)) {
        data.map(itemInside => {
          const itemObj: any = {};
          const stepName = this.violationsStepsResource.filter(step => step.id === id).shift();
          itemObj.id = id;
          itemObj.title = stepName ? stepName.value : null;
          itemObj.date = itemInside.date;
          if (itemInside.authorName) {
            itemObj.authorName = itemInside.authorName;
          }
          resultNormalized.push(itemObj);
        });
      } else if (id === 'CFTV') {
        Object.entries(data || []).map(itemCFTV => {
          const dataCFTV: any = itemCFTV[1];
          dataCFTV.map(itemInside => {
            const idCFTV: string = itemCFTV[0];
            const itemObj: any = {};
            const stepName = this.violationsStepsResource.filter(step => step.id === idCFTV).shift();
            itemObj.id = idCFTV;
            itemObj.title = stepName ? stepName.value : null;
            itemObj.date = itemInside.date;
            if (itemInside.authorName) {
              itemObj.authorName = itemInside.authorName;
            }
            resultNormalized.push(itemObj);
          });
        });
      } else {
        const itemObj: any = {};
        itemObj.id = id;
        itemObj.title = this.violationsStepsResource.filter(step => step.id === id).shift()?.value;
        itemObj.date = data.date;
        if (data.authorName) {
          itemObj.authorName = data.authorName;
        }
        resultNormalized.push(itemObj);
      }
    });

    resultNormalized.sort((a, b) => +new Date(b.date) - +new Date(a.date));
    return resultNormalized;
  }

  async showDetails(violation) {
    const data = {
      regulations: this.regulations,
      lanes: this.lanes,
      categories: this.categories
    };

    this.viewViolationService.emitDataViewContractViolation(data);

    await this.modalService.show(new ComponentModal(DetailsViolationComponent, { violation }))
      .catch((error) => console.log(error));
  }

  getSpotInfo(lane) {
    const spots = [];
    const spotsInfo = [];
    lane.map(laneItem => {
      spots.push(this.lanes.filter(item => item.code === laneItem).shift());
    });
    if (spots.length > 0) {
      spots.map(spotItem => {
        spotsInfo.push(this.spots.filter(item => item.laneIds.includes(spotItem.id)).shift());
      });
      this.spotInfo = `${spotsInfo[0]?.code} - ${spotsInfo[0]?.description}`;
      spotsInfo.map(item => {
        if (item?.id !== spotsInfo[0]?.id) {
          this.hideSpot = true;
        }
      });
    }
  }

  getLaneInfo(lane) {
    const laneInfo = lane[0] && this.lanes.filter(item => item.code === lane[0])
      .map(item => item && item.prettyNames && item.prettyNames[0]).shift();

    if (laneInfo) {
      lane.map(item => {
        if (item !== lane[0]) {
          this.hideLane = true;
        }
      });
      this.laneInfo = laneInfo;
    }
  }

  exportCsv() {
    const params: any = {
      startDate: this.params['date[gte]'],
      endDate: this.params['date[lte]'],
      contractId : this.contractId
    };
    if (this.filter?.spotsIds?.length > 0) {
      params.spotsIds = this.uniq(this.filter.spotsIds.map(Number));
    }
    if (this.filter?.laneIds?.length > 0) {
      params.laneIds = this.uniq(this.filter.laneIds.map(Number));
    }
    if (this.filter?.situationsIds?.length > 0) {
      params.situationsIds = this.uniq(this.filter.situationsIds);
    }
    if (this.filter?.regulationsIds?.length > 0) {
      params.regulationIds = this.uniq(this.filter.regulationsIds.map(Number));
    }
    if (this.filter?.steps?.length > 0) {
      params.steps = this.filter.steps;
    }
    const currentUser = this.storageService.get(StorageKey.currentUser);
    this.violationCSVService.violationViewCSV(params).then(async () => {
      await this.modalService.show(new MessageModal('Relatório CSV - Relatório de Infrações',
      `Será enviado um email para ${currentUser.email} quando finalizar
      a geração do arquivo CSV. Favor aguarde`, false));
    }).catch(() => {
      this.alertService.show(new AlertItem('ReportCSV', AlertType.danger));
    });
  }

  uniq(a) {
    const seen = {};
    return a.filter((item) => seen.hasOwnProperty(item) ? false : (seen[item] = true));
  }

  getViolationStep(step) {
    const steps = {
      validateLane: 'Validar faixa',
      validateCalibration: 'Validar afericao',
      validateSpeed: 'Validar velocidade',
      validateFiles: 'Validar arquivo',
      validateExemptPeriod: 'Periodo isento',
      validateVehicleWhiteList: 'Lista branca',
      validateViolationLimit: 'Limite de infracoes',
      verifyValid: 'Verificacao de válidas',
      validateEquipment: 'Equipamento invalido',
      triage: 'Triagem',
      typing: 'Digitacao',
      verify: 'Verificacao',
      validate: 'Validacao',
      lotAttribution: 'Atribuicao de lote',
      internalAudit: 'Auditoria interna',
      revision: 'Revisao',
      filter: 'Filtro',
      verifyCFTV: 'Verificacao CFTV',
      validateCFTV: 'Validacao CFTV',
      monitoringCFTV: 'Monitoramento CFTV',
      done: 'Concluido'
    };
    return steps[step];
  }

  getViolationSituation(status) {
    if (status !== 'valid' && status !== 'invalid') {
      return 'Em processamento';
    }
    if (status === 'valid') {
      return 'Valida';
    }
    if (status === 'invalid') {
      return 'Invalida';
    }
  }

  ngOnDestroy() {
    if (this.contractDataViolationsSub) {
      this.contractDataViolationsSub.unsubscribe();
      this.filteredParamsViolationsSub.unsubscribe();
    }
  }

  setResources(value) {
    this.violationsStepsResource = value;
  }

  async showHistory(lotId) {
    await this.modalService.show(new ComponentModal(LoteInfoComponent, { lotId }))
      .catch((error) => console.log(error));
  }

  getStatusQuarantine(quarantineReason = null) {
    if (this.quarantineStatusI18n.length === 0 || quarantineReason == null) {
      return;
    }
    return this.quarantineStatusI18n.find(q => q.id === quarantineReason).value;
  }
}
