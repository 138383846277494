import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService } from 'src/app/core/services';
import { ReleaseNotesService } from 'src/app/core/services/releaseNotes.service';
import { ReleaseNotes } from 'src/app/core/models/ReleaseNotes';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.sass']
})
export class ReleaseNotesComponent extends BaseModal implements OnInit {
  isValid: boolean;
  lastState: any;
  public theme = environment.theme;
  public noRegister = false;
  public releaseNotes: ReleaseNotes[] = [];

  constructor(
    modalService: ModalService,
    public releaseNotesService: ReleaseNotesService
  ) {
    super(modalService);
  }

  ngOnInit() {  }

  setReleaseNotes(e) {
    for (const i in e) {
      if (!e[i].version || !e[i].version.match(/([0-9]?)[0-9].[0-9].[0-9]/gm) || !e[i].notes || e[i].notes.length < 1) {
        delete e[i];
      }
    }
    this.releaseNotes = this.releaseNotes.concat(e);
    if (this.releaseNotes.length === 0) {
      this.noRegister = true;
    }
  }

  async onAllSubmited(): Promise<any> { }
}
