import { Component, OnInit } from '@angular/core';
import { Regulation, AlertItem, AlertType } from 'src/app/core/models';
import { RegulationService } from 'src/app/core/services/regulation.service';
import { ContractService, ModalService, AlertService } from 'src/app/core/services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-select-regulation-modal',
  templateUrl: './select-regulation-modal.component.html',
  styleUrls: ['./select-regulation-modal.component.sass']
})
export class SelectRegulationModalComponent extends BaseModal implements OnInit {
  public regulations: Array<Regulation> = [];
  public selectedRegulationIds = [];
  public form: FormGroup;
  public steps = [
    'triage',
    'typing',
    'doubleTyping',
    'validate',
    'verify'
  ];
  public step: string;
  public translatedStep: {id: string; value: string};

  constructor(
    private regulationService: RegulationService,
    private contractService: ContractService,
    private formBuilder: FormBuilder,
    public modalService: ModalService,
    private alertService: AlertService
  ) {
    super(modalService);
  }

  ngOnInit() {
    if (this.initialState == null) {
      this.alertService.show(new AlertItem('GenericError', AlertType.danger));
    }
    const params = this.initialState || {};
    const contractId = params.contractId;
    this.step = params.step;
    this.contractService.getById(contractId).then(contract => {
      this.regulationService.getAll({ regionId: contract.regionId }).then(regulations => {
        this.regulations = regulations;
      });
    });
    this.form = this.createForm();
  }

  createForm() {
    const form = this.formBuilder.group({
      regulationIds: ['']
    });
    return form;
  }

  get isValid(): boolean {
    if (this.form != null) {
      return this.form.valid;
    } else {
      return false;
    }
  }

  get lastState(): any {
    return null;
  }

  async onAllSubmited(): Promise<any> {
    this.selectedRegulationIds = this.form.get('regulationIds').value;
  }

  setStep(data) {
    if (data != null && data.length > 0) {
      this.translatedStep = data.find(d => d.id === this.step);
    }
  }
}
