<div class="table-flex mb-4" *ngIf="loading; else loadingBlock">
  <div class="table-flex__header">
    <div class="w-25">
      <app-production-report-period></app-production-report-period>
    </div>
    <p class="w-50" i18n>Enquadramento</p>
    <p class="w-25" i18n>Qtd de registros processados <span>{{ stepTotal }}</span></p>
  </div>

  <div class="table-flex__item">
    <div class="table-flex__row" *ngFor="let regulation of step">
      <div class="w-25">
        <p class="d-flex align-items-center">
          <span i18n-appTooltip [appTooltip]="getRegulationInfo(regulation.id)" class="d-inline-flex align-items-center">
            <i class="material-icons text-light mr-2">info</i>
            {{ regulation.code }}
          </span>
        </p>
      </div>
      <div class="w-50 table-graph">
        <div class="table-graph__column" i18n-appTooltip [appTooltip]="calculateSize(regulation.total, stepTotal) + '% de ' + stepTotal">
          <span [style.width.%]="calculateSize(regulation.total, stepTotal)"></span>
        </div>
      </div>
      <p class="w-25 text-center">{{ regulation.total }}</p>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>
