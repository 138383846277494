import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService, StorageService, ContractService, StorageKey, ViolationService } from '../../services';
import { NavigationEnd, ResolveEnd, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-process',
  templateUrl: './dashboard-process.component.html',
  styleUrls: ['./dashboard-process.component.sass']
})
export class DashboardProcessComponent implements OnInit, OnDestroy {
  @ViewChild('menu', { static: true, read: ElementRef }) menu: ElementRef;
  @ViewChild('menuButton', { static: true, read: ElementRef }) menuButton: ElementRef;

  public isMenuOpen = false;
  public theme = environment.theme;
  public user: any = {};
  public selectContracts = [];
  public breadcrumbs: { isTranslatable: boolean; level: string }[];
  private _subs = [];

  constructor(
    private authService: AuthenticationService,
    private storageService: StorageService,
    public contractService: ContractService,
    public violationService: ViolationService,
    private router: Router
  ) { }


  async ngOnInit() {
    const user = this.storageService.get(StorageKey.currentUser);
    if (user != null) {
      this.user = user;
    }
    this.breadcrumbs = this.getUrl(this.router.url);
    this._subs.push(this.router.events.subscribe((event) => {
      if (event instanceof ResolveEnd || event instanceof NavigationEnd) {
        this.breadcrumbs = this.getUrl(event.url);
      }
    }));
  }

  ngOnDestroy() {
    this._subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  logout() {
    const user = this.storageService.get(StorageKey.currentUser);
    if (user == null || user.id == null) {
      return;
    }
    const userId = user.id;
    this.violationService.unlock(userId, []);
    this.authService.logout();
  }

  toogleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  click(event) {
    if (this.isMenuOpen) {
      if (this.menuButton.nativeElement === event.target || this.menuButton.nativeElement.contains(event.target)) {
        return;
      }
      if (this.menu.nativeElement !== event.target && !this.menu.nativeElement.contains(event.target)) {
        this.isMenuOpen = false;
      }
    }
  }

  getUrl(url) {
    url = url.split('?')[0];
    const link =  url === '/' ? [] : url.split('/');
    if (link.length > 1) {
      link.shift();
    }
    return link.slice(0, 3);
  }
}
