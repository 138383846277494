<div class="modal-dialog modal-dialog-centered modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h5 i18n class="modal-title container">Novo Usuário</h5>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="userForm" (submit)=" onSubmit()">
        <div class="row">
          <div class="col-md-12">
            <app-input i18n-label type="text" formControlName="email" label="E-mail" inputRequired=true></app-input>
          </div>
          <div class="col-md-12">
            <div class="form-group has-error-select">
              <app-input type="select" [list]="companies" label="Empresa" formControlName="companyId"
                selectText="name" selectValue="id" placeholder="Selecione uma empresa"
                i18n-placeholder inputRequired=true i18n-label (ngModelChange)="userGroupsByCompany($event)">
              </app-input>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <app-input type="multiselect" [list]="userGroupContracts" label="Grupos de Usuários" formControlName="groupIds"
                selectText="prettyName" selectValue="id" selectShowLimit="2" placeholder="Selecione grupo(s)"
                i18n-placeholder i18n-label [inputDisabled]="formGroupDisabled" inputRequired=true>
              </app-input>
            </div>
          </div>
          <div class="col-md-12">
            <app-input i18n-label type="text" formControlName="role" label="Cargo"></app-input>
          </div>
          <div class="col-md-12">
            <app-input
            i18n
            i18n-label
            type="text"
            formControlName="cpf"
            format="ddd.ddd.ddd-dd"
            label="CPF">
          </app-input>
          </div>
          <div class="col-md-12">
            <app-input i18n-label type="text" formControlName="registryNumber" label="N° da Matrícula" max=5></app-input>
          </div>
        </div>
        <div class="row" *ngIf="currentUser && currentUser.superUser">
          <div class="col-md-12">
            <mat-checkbox i18n formControlName="superUser" color="primary">Super Usuário</mat-checkbox>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="onCancel()" class="btn btn-outline-secondary">
        <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
      </button>
      <button (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
