import { BaseApiModel } from './BaseApiModel';
import { Timezone } from './Timezone';

export class Contract extends BaseApiModel {
  public code: string;
  public name: string;
  public regionId: string;
  public startsAt: Date;
  public endsAt: Date;
  public enabled: {
    initus: boolean;
    omnia: boolean;
  };
  public limits: {
    spots: number;
    equipments: number;
  };
  public speedCalculations: Array<{
    regulationId: string;
    forumula: string;
  }>;
  public companies: Array<string>;
  public companyIds: Array<string>;
  public extraDataKeys: Array<any>;
  // eslint-disable-next-line no-use-before-define
  public modules: Array<Modules>;
  public equipmentModelIds: any[];
  public aitTemplates: Array<{
    templateId: string;
    regulationIds: Array<string>;
  }>;
  public status: string;
  public ownerCompanyId: string;
  public createdAt: Date;
  public modifiedAt: Date;
  public timezone: Timezone;
  public enabledImportFile: boolean;
  public client: Array<{
    code: string;
    startsAt: string;
    endsAt: string;
  }>;

  get prettyName() {
    return `${this.code} - ${(this.name)}`;
  }
}

// eslint-disable-next-line no-shadow
export enum Modules {
  violations = 'violations',
  traffic = 'traffic',
  surveillance = 'surveillance',
  status = 'status',
  backup = 'backup',
  preProcess = 'preProcess'
}
