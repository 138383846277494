import { Component, OnInit } from '@angular/core';
import { AlertItem } from 'src/app/core/models';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.sass']
})
export class AlertComponent implements OnInit {
  public items: Array<AlertItem> = [];
  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.alertService.getEvents().subscribe((item: AlertItem) => {
      this.items.push(item);
      setTimeout(() => {
        this.close(item);
      }, item.timeout);
    });
  }

  close(item) {
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }
}
