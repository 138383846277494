import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Pagination, StorageLocation } from '../models';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageLocationService extends BaseApiService<StorageLocation> {
  get cacheKey(): StorageKey {
    return StorageKey.storageLocationServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/storageLocations`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  protected unparse(obj) {
    return StorageLocation.unparse(obj);
  }

  protected parse(obj) {
    return StorageLocation.parse(obj);
  }

}
