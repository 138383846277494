import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchPlate } from '../models';
import { BaseApiService } from './baseApiService';
import { catchError, map } from 'rxjs/operators';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PlateService extends BaseApiService<SearchPlate> {
  get cacheKey(): StorageKey {
    return StorageKey.plateServiceCache;
  }

  private baseUrl = `${environment.apiUrl}`;
  getUrl(params, id: string = null): string {
    let url = `${environment.apiUrl}/vehicles`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  async searchPlate(params: any ): Promise<SearchPlate> {
    return this.http.get(`${this.baseUrl}/searchPlate`, { params })
      .pipe(
        catchError((responseError) => this.handleError(responseError)),
        map(body => body)
      ).toPromise();
  }

  protected unparse(obj) {
    return SearchPlate.unparse(obj);
  }

  protected parse(obj) {
    return SearchPlate.parse(obj);
  }

}
