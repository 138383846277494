import { BaseApiModel } from './BaseApiModel';

export class Regulation extends BaseApiModel {
  public code: string;
  public description: string;
  public shortDescription: string;
  public penalty: number;
  public severity: string;
  public stateId: string;
  public law: {
    article: string;
    paragraph: string;
    item: string;
    subsection: string;
  };
  public value: number;
  public regionId: string;

  get prettyName() {
    return `${this.code} - ${this.shortDescription || this.description}`;
  }
}
