/// <reference types="@types/google.maps" />
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalContent } from 'src/app/core/interface';
import { ModalService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-spot-map-modal',
  templateUrl: './spot-map-modal.component.html',
  styleUrls: ['./spot-map-modal.component.sass']
})
export class SpotMapModalComponent extends BaseModal implements OnInit, AfterViewInit, ModalContent {
  @Input() initialState;
  @ViewChild('map', { static: true }) map: ElementRef;
  @ViewChild('card', { static: true }) card: ElementRef;
  public htmlMap: any;
  public addressForm: FormGroup;
  public lng: number;
  public lat: number;
  public street: string;
  public zipCode: string;
  public city;
  public state: string;
  public country: string;
  public streetNumber: string;
  lastState: any;
  public isValid = true;
  private marker: any;

  get address() {
    return {lat: this.lat, lng: this.lng, street: this.street, streetNumber: this.streetNumber,
      zipCode: this.zipCode, city: this.city, state: this.state, country: this.country};
  }

  constructor(
    protected http: HttpClient,
    private formBuilder: FormBuilder,
    modalService: ModalService,
    private cd: ChangeDetectorRef
  ) {
    super(modalService);
  }

  ngOnInit() {
    const coordinates = this.initialState;
    const isNew = coordinates == null || coordinates.latitude == null || coordinates.longitude == null;
    this.createForm(coordinates);
    const mapProp = {
      center: this.getLatLng(this.getFormCoordinates()),
      zoom: isNew ? 10 : 17,
    };
    this.htmlMap = new google.maps.Map(this.map.nativeElement, mapProp);
    this.htmlMap.addListener('click', (event) => {
      this.addMarker(event.latLng);
    });
    this.addMarker(this.getFormCoordinates());
    this.fillCoordinates();
  }

  ngAfterViewInit() {
    const input = document.getElementsByClassName('input')[0].getElementsByTagName('input')[0] as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.bindTo('bounds', this.htmlMap);

    autocomplete.addListener('place_changed', () => {
      this.marker.setVisible(false);
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }
      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        this.htmlMap.fitBounds(place.geometry.viewport);
      } else {
        this.updateMapView(place.geometry.location);
      }
      this.marker.setPosition(place.geometry.location);
      this.marker.setVisible(true);
      this.fillCoordinates(false);
    });
  }

  updateMapView(location = null, coordinates = null) {
    this.htmlMap.setCenter(location || this.getLatLng(coordinates));
    this.htmlMap.setZoom(17);
  }

  getLatLng(coordinates) {
    return new google.maps.LatLng(coordinates.lat, coordinates.lng);
  }

  addMarker(location) {
    if (this.marker != null) {
      this.marker.setMap(null);
    }
    this.marker = new google.maps.Marker({
      position: location,
      map: this.htmlMap,
      draggable: true,
    });
  }

  getFormCoordinates() {
    return {
      lat: parseFloat(this.addressForm.get('latitude').value),
      lng: parseFloat(this.addressForm.get('longitude').value)
    };
  }

  createForm(coordinates) {
    if (coordinates == null || coordinates.latitude == null || coordinates.longitude == null) {
      coordinates = { latitude: -23.5489, longitude: -46.6388 };
    }
    this.addressForm = this.formBuilder.group({
      street: [''],
      longitude: [coordinates.longitude, Validators.required],
      latitude: [coordinates.latitude, Validators.required]
    });
    this.addressForm.valueChanges.pipe(debounceTime(500)).subscribe(this.updateMapViewByForm.bind(this));
  }

  updateMapViewByForm(controls) {
    this.isValid = this.addressForm.valid;
    if (this.isValid && (controls.latitude !== this.lat || controls.longitude !== this.lng)) {
      const newCoordinates = this.getFormCoordinates();
      this.addMarker(newCoordinates);
      this.updateMapView(null, newCoordinates);
      this.fillCoordinates();
    }
  }

  async onAllSubmited(): Promise<any> {
  }

  async fillCoordinates(patchStreetValue = true) {
    this.lat = this.marker.getPosition().lat();
    this.lng = this.marker.getPosition().lng();
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: { lat: this.lat, lng: this.lng }}, (res) => {
      if (res == null || res.length === 0) {
        return;
      }
      const addresses = res.find(r => r.types.includes('street_address'));
      if (addresses == null) {
        return;
      }
      for (const address of addresses.address_components) {
        if (address.types.includes('postal_code')) {
          this.zipCode = address.long_name;
        }
        if (address.types.includes('street_number')) {
          this.streetNumber = address.long_name;
        }
        if (address.types.includes('country')) {
          this.country = address.long_name;
        }
        if (address.types.includes('administrative_area_level_1')) {
          this.state = address.short_name;
        }
        if (address.types.includes('administrative_area_level_2')) {
          this.city = address.long_name;
        }
        if (address.types.includes('route')) {
          this.street = address.long_name;
        }
      }
      this.addressForm.patchValue({
        latitude: this.lat,
        longitude: this.lng
      });
      if (patchStreetValue) {
        this.addressForm.patchValue({
          street: this.street
        });
      }
      this.cd.detectChanges();
    });
    this.isValid = true;
  }
}
