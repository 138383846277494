import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.sass']
})
export class ColumnFilterComponent implements OnInit {
  @Input() items = [];
  @Output() output: EventEmitter<Array<any>> = new EventEmitter();
  public i18nItems = [];
  public form: FormGroup = this.formBuilder.group({});
  public shouldShow = true;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
  }

  createForm() {
    this.i18nItems.forEach(item => {
      this.form.addControl( item.id, new FormControl(true));
    });
    this.output.emit(Object.keys(this.form.value));

    this.form.valueChanges.subscribe(result => {
      const parsed = [];
      Object.keys(result).forEach(key => {
        if (result[key] === true) {
          parsed.push(key);
        }
      });
      this.output.emit(parsed);
    });
  }

  setItem(items) {
    this.i18nItems = items;
    this.createForm();
  }
}
