<div class="modal-container" *ngFor="let item of modals; let i = index">
  <div class="modal-bg"></div>
  <app-wrapper *ngIf="isComponentModal(item.modal); else messageModal" [type]="item.modal.type"
    [initialState]="item.modal.initialState" (component)="item.modal.setComponent($event)"></app-wrapper>
  <ng-template #messageModal>
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{item.modal.title}}</h5>
        </div>
        <div class="modal-body">
          <p *ngIf="isMessageModal(item.modal); else selectModal">
            {{item.modal.message}}
          </p>
          <ng-template #selectModal>
            <ngx-treeview [config]="item.modal.config" [items]="item.modal.items"
              (selectedChange)="selectChange(item.modal, $event)" [ngClass]="{ radio: item.modal.isSingle }">
            </ngx-treeview>
          </ng-template>
        </div>
        <div class="modal-footer">
          <button (click)="onCancel()" class="btn btn-outline-secondary" *ngIf="item.modal.confirmation">Cancelar</button>
          <button (click)="onSubmit()" class="btn btn-primary">Confirmar</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
