import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: Date, format: string = 'L'): string {
    if (value == null) {
      return '';
    }
    moment.locale('pt-br');
    return moment(value).format(format);
  }
}
