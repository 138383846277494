/// <reference types="@types/jquery" />
/// <reference types="@types/bootstrap" />
import { Directive, Input, ElementRef, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input() appTooltip: string;
  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.title = this.appTooltip;
    $(this.el.nativeElement).tooltip();
  }

  ngOnDestroy() {
    $(this.el.nativeElement).tooltip('dispose');
  }
}
