import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import * as _ from 'lodash';

import { ViolationReportService } from 'src/app/core/services';
import { Lane, Reason, Regulation, Spot } from 'src/app/core/models';

@Component({
  selector: 'app-violations-report-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.sass']
})
export class ViolationsReportFilterComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public collapse: boolean;
  public filterForm: FormGroup;
  public dataContractsSub: Subscription;
  public contractViolationsSub: Subscription;
  public contractViolationsSubBackup: Subscription;
  public filterParamsSub: Subscription;
  public dataProduction: any;
  public spots: Spot[] = [];
  public lanes: Lane[] = [];
  public allLanes = [];
  public isValid: boolean;
  public regulations: Regulation[] = [];
  public situations: any[] = [];
  public reasons: Reason[] = [];
  public violations: any[] = [];
  public reasonArea: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private violationReportService: ViolationReportService,
  ) { }

  ngOnInit() {
    const violationReportFilterParams = JSON.parse(localStorage.getItem('violationReportFilterParams'));
    this.filterForm = this.formBuilder.group({
      spotsIds: [violationReportFilterParams?.spotsIds || null],
      laneIds: [violationReportFilterParams?.laneIds || null],
      regulationsIds: [violationReportFilterParams?.regulationsIds || null],
      situationsIds: [violationReportFilterParams?.situationsIds || null],
      reasonsIds: [violationReportFilterParams?.reasonsIds || null],
      hideLanes: [true]
    });

    this.dataContractsSub = this.violationReportService.getContractDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((dataViolationReport: any) => {
        this.spots = dataViolationReport.spots;
        this.lanes = [];
        this.allLanes = dataViolationReport.lanes.map( e => ({ ...e, prettyName: e.prettyNames?.[0] }));
        for (const i in this.allLanes) {
          if (!this.allLanes[i].prettyNames) {
            const spot = this.spots.find(s => s.id === this.allLanes[i].spotId);
            if (spot) {
              this.allLanes[i].prettyName = `${this.allLanes[i].code} - ${this.allLanes[i].number} (Ponto ${spot.code})`;
            }
          }
        }
        this.regulations = dataViolationReport.regulations;
        this.situations = dataViolationReport.situations;
        this.reasons = dataViolationReport.reasons;
        this.loading = true;
      });

    this.redefineFilter();

    this.contractViolationsSubBackup = this.violationReportService.getFilteredViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => this.violations = data);

    this.filterForm.get('situationsIds').valueChanges.subscribe(res => {
      if (res && res.includes('invalid')) { this.reasonArea = true; } else { this.reasonArea = false; }
    });

    this.filterForm.get('spotsIds').valueChanges.subscribe(res => {
      this.lanes = [];
      if (res) {
        const spotIds = res.map(Number);
        this.lanes = this.allLanes.filter((lane: any) => spotIds.includes(lane.spotId) && lane?.prettyName);
        localStorage.setItem('violationReportFilterSpotsId', JSON.stringify(res));
      }
    });

    this.filterForm.valueChanges.subscribe(res => {
      this.isValid = this.filterForm.valid;
    });

    this.filterParamsSub = this.violationReportService.getFilterParamsViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.lanes = [];
      });

    this.submitFilter();
  }

  redefineFilter() {
    this.filterForm.get('spotsIds').setValue([]);
    this.filterForm.get('laneIds').setValue([]);
    this.filterForm.get('regulationsIds').setValue([]);
    this.filterForm.get('situationsIds').setValue([]);
    this.filterForm.get('reasonsIds').setValue([]);
    this.filterForm.get('hideLanes').setValue(true);
    this.lanes = [];
    this.violationReportService.emitFilterParamsViolationReport(this.filterForm.value);
  }

  submitFilter() {
    const regulationsIds = [];
    if (this.filterForm.value.reasonsIds && this.filterForm.value.reasonsIds.length > 0) {
      this.filterForm.value.reasonsIds.map(item => {
        regulationsIds.push(Number(item));
      });
      this.filterForm.value.regulationsIds = regulationsIds;
    }
    this.violationReportService.emitFilterParamsViolationReport(this.filterForm.value);
  }

  uniq(a) {
    const seen = {};
    return a.filter((item) => seen.hasOwnProperty(item) ? false : (seen[item] = true));
  }

  toggleCollapse() {
    this.collapse = !this.collapse;
  }

  ngOnDestroy() {
    this.dataContractsSub.unsubscribe();
    this.contractViolationsSubBackup.unsubscribe();
  }
}
