<div class="modal-dialog modal-dialog-centered modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title container" i18n *ngIf="isSingle">Copiar configurações da faixa:</h5>
      <h5 class="modal-title container" i18n *ngIf="!isSingle">Replicar as configurações desta faixa em:</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
      </div>
      <div class="modal-body" #container>
        <form class="form-horizontal" [formGroup]="editForm" *ngIf="loading; else loadingBlock">
          <div *ngIf="isSingle">
            <label i18n>Filtrar por ponto:</label>
            <app-input class="input-multiselect" i18n-label type="select" [list]="spots" selectText="prettyName" selectValue="id"
              formControlName="spotsIds" selectShowLimit="1" placeholder="Selecione o ponto"></app-input>
            <label i18n>Faixa:</label>
            <app-input class="input-multiselect" i18n-label type="select" [list]="lanes" selectText="prettyNames" selectValue="id"
              formControlName="laneIds" selectShowLimit="1" placeholder="Selecione a faixa"></app-input>
          </div>
          <div *ngIf="!isSingle">
            <label i18n>Filtrar por pontos:</label>
            <app-input class="input-multiselect" i18n-label type="multiselect" [list]="spots" selectText="prettyName" selectValue="id"
              formControlName="spotsIds" selectShowLimit="1" placeholder="Selecione os pontos"></app-input>
            <label i18n>Faixas:</label>
            <app-input class="input-multiselect" i18n-label type="multiselect" [list]="lanes" selectText="prettyNames" selectValue="id"
              formControlName="laneIds" selectShowLimit="1" placeholder="Selecione as faixas"></app-input>
          </div>
        </form>
        <ng-template #loadingBlock>
          <div class="loading-page">
            <div class="loading-spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="modal-footer">
      <button (click)="onCancel()" class="btn btn-outline-secondary" i18n>Cancelar</button>
      <button (click)="submitForm()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}"
        i18n>Confirmar</button>
    </div>
  </div>
</div>
