import { BaseApiModel } from './BaseApiModel';

export class UserHistory extends BaseApiModel {
    public userId: number;
    public ip: string;
    public action: string;
    public field: any[];
    public content: any[];
    public date: string;
}
