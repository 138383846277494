import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BrandModelService, CategoryService, ClassificationService, ColorService, LaneService, ModalService, RegionService, SpotService, ViewViolationService, RegulationService } from 'src/app/core/services';
import { BrandModel, Category, Classification, Color, Lane, Region, Regulation, Spot } from 'src/app/core/models';
import { CityService } from 'src/app/core/services/city.service';
import { City } from 'src/app/core/models/City';

@Component({
  selector: 'app-violations-view-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.sass']
})
export class DetailsViolationComponent extends BaseModal implements OnInit, OnDestroy {
  isValid: boolean;
  lastState: any;
  public violation: any = {};
  public fileUrl: string;
  public fileType: string;
  public contractViewViolationsSub: Subscription;
  public regulations: Regulation[] = [];
  public lanes: Lane[] = [];
  public categories: Category[] = [];
  public state = 0;
  public files: [] = [];
  public region: Region;
  public color: Color;
  public brandModel: BrandModel;
  public category: Category;
  public classification: Classification;
  public city: City;
  public lane: Lane;
  public spot: Spot;
  public loading = false;
  public regulation: string;


  constructor(
    modalService: ModalService,
    private viewViolationService: ViewViolationService,
    private regionService: RegionService,
    private colorService: ColorService,
    private brandModelService: BrandModelService,
    private categoryService: CategoryService,
    private classificationService: ClassificationService,
    private cityService: CityService,
    private laneService: LaneService,
    private spotService: SpotService,
    private regulationService: RegulationService
  ) {
    super(modalService);
  }

  async ngOnInit() {
    this.loading = true;
    this.violation = this.initialState && this.initialState.violation || {};
    await this.getRegion();
    await this.getColor(this.violation.vehicle.colorCode);
    await this.getBrandModel(this.violation.vehicle.brandModelCode);
    await this.getCategoryByCode(this.violation.vehicle.categoryCode);
    await this.getClassification(this.violation.vehicle.classificationCode);
    await this.getCity(this.violation.vehicle.cityCode);
    await this.getLane(this.violation.laneCode);
    await this.regulationService.getById(this.violation.regulationId).then(regulation => {
      this.regulation = regulation.code;
    });
    this.contractViewViolationsSub = this.viewViolationService.getDataViewContractViolation()
    .pipe(filter(result => Object.keys(result).length > 0))
    .subscribe((data: any) => {
      if (data.regulations.length > 0) {
        this.regulations = data.regulations;
      }

      this.lanes = data.lanes;
      this.categories = data.categories;
    });
    this.regulationService.list({ regionId: this.region.id }).then(res => {
      this.regulations = res.result;
    });
    this.files = this.violation.files || null;
    this.verifySpeed();
    this.loading = false;
  }

  verifySpeed() {
    if (this.violation.speed >= this.violation.speedLimit + (this.violation.speedLimit / 100) * 100) {
      this.violation.speedWarning = true;
    }
  }

  async getRegion() {
    await this.regionService.getAll({ name: 'Brasil'}).then(res => {
      this.region = res.shift();
    });
  }

  async getColor(colorCode) {
    if (colorCode === undefined || colorCode === '') return;
    await this.colorService.getAll({ code: colorCode, regionId: this.region.id }).then(res => {
      this.color = res.shift();
    });
  }

  async getBrandModel(brandModelCode) {
    if (brandModelCode === undefined || brandModelCode === '') return;
    await this.brandModelService.getAll({ code: brandModelCode, regionId: this.region.id }).then(res => {
      this.brandModel = res.shift();
    });
  }

  async getCategoryByCode(categoryCode) {
    if (categoryCode === undefined || categoryCode === '') return;
    await this.categoryService.getAll({ code: categoryCode, regionId: this.region.id }).then(res => {
      this.category = res.shift();
    });
  }

  async getClassification(classificationCode) {
    if (classificationCode === undefined || classificationCode === '') return;
    await this.classificationService.getAll({ code: classificationCode, regionId: this.region.id }).then(res => {
      this.classification = res.shift();
    });
  }

  async getCity(cityCode) {
    if (cityCode === undefined || cityCode === '') return;
    await this.cityService.getAll({ code: cityCode, regionId: this.region.id }).then(res => {
      this.city = res.shift();
    }).catch (err => {
      this.loading = false;
    });
  }

  async getLane(laneCode) {
    if (laneCode === undefined || laneCode === '') return;
    await this.laneService.getAll({ code: laneCode, contractId: this.violation.contractId }).then(res => {
      this.lane = res.shift();
    });
    await this.spotService.getById(this.lane.spotId).then(res => {
      this.spot = res;
      this.lane.prettyNames = [`${laneCode} - Fx ${this.lane.number} (Ponto ${this.spot.code})`];
    });
  }

  async onAllSubmited(): Promise<any> {
  }

  getRegulationCode(regulation) {
    if (this.regulations.length > 0) {
      return this.regulations.filter(item => item.id === regulation).map(item => item.code).shift();
    } else {
      return this.regulation;
    }
  }

  getRegulationInfo(regulation) {
    if (this.regulations.length > 0) {
      return this.regulations.filter(item => item.id === regulation).map(item => item.description).shift();
    }
  }

  getLaneInfo(lane) {
    const laneInfo = this.lanes.filter(item => item.code === lane).map(item => item.prettyNames && item.prettyNames[0]).shift();
    if (laneInfo) {
      return laneInfo;
    } else {
      return '--';
    }
  }

  getCategory(category) {
    return this.categories.filter(item => item.code === category).map(item => item.description).shift();
  }

  ngOnDestroy() {
    this.contractViewViolationsSub?.unsubscribe();
  }
}
