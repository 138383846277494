import { Component, OnInit, ViewChild,
  ViewContainerRef, Input, Output, EventEmitter,
  ComponentRef, ComponentFactoryResolver, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.sass']
})
export class WrapperComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type;
  @Input() initialState;
  @Output() component: EventEmitter<ComponentRef<any>> = new EventEmitter();

  @ViewChild('target', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;

  private _component: ComponentRef<any>;
  private _initialized = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  addComponent() {
    if (this._component != null) {
      this._component.destroy();
      this._component = null;
    }
    if (this.type != null) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.type);
      this._component = this.viewContainerRef.createComponent(factory);
      this._component.instance.initialState = this.initialState;
      this.component.emit(this._component);
    }
  }

  ngOnInit() {
    this._initialized = true;
    this.addComponent();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this._initialized) {
      this.addComponent();
    }
  }
  ngOnDestroy() {
    if (this._component != null) {
      this._component.destroy();
      this._component = null;
    }
  }
}
