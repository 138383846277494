/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ViolationCsvService extends BaseApiService<any> {
  get cacheKey(): StorageKey {
    return StorageKey.violationServiceCache;
  }

  getUrl(): string {
    return `${environment.apiUrl}/report/violationCsv`;
  }

  protected unparse(obj) {
  }

  protected parse(obj) {
  }

  violationViewCSV(params): Promise<any> {
    const url = this.getUrl();
    return this.http.post(url, params).pipe(
      catchError(this.handleError),
      map(res => res)
    ).toPromise();
  }
}
