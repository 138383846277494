<app-enum-i18n [input]="violationsSteps" (output)="setResources($event)"></app-enum-i18n>

<ng-container *ngIf="loading; else loadingBlock">
  <div class="table-flex__header">
    <p class="w-15" i18n>Placa</p>
    <p class="w-15" i18n>Série</p>
    <p class="w-15" i18n>Data de<br/> cometimento</p>
    <p class="w-10" i18n>Data de recebimento</p>
    <p class="w-20" i18n>Enquadramento</p>
    <p class="w-20" i18n>Situação</p>
    <p class="w-15" i18n>Etapa</p>
    <p class="w-5" i18n></p>
  </div>

  <div class="table-flex__item">
    <div class="table-flex__row bg-gray--250" *ngIf="!hideSpot">
      <p i18n>Ponto: {{ spotInfo }}</p>
    </div>
    <div class="table-flex__row bg-gray--200 pl-3" *ngIf="!hideLane">
      <p i18n>{{ laneInfo }}</p>
    </div>
  </div>

  <div class="table-flex__item" *ngFor="let violation of violations; let i = index">
    <div class="table-flex__row bg-gray--150">
      <p class="w-15 text-center">{{ violation?.vehicle?.plate || '--' }}</p>
      <p class="w-15 text-center">{{ violation?.serie || '--' }}</p>
      <p class="w-10" i18n>
        <span class="d-block">{{ violation?.date | moment: 'DD/MM/YYYY' }}</span>
        <small class="text-muted">{{ violation?.date | moment: 'HH:mm:ss' }}</small>
      </p>
      <p class="w-10" i18n>
        <span class="d-block">{{ violation?.createdAt | moment: 'DD/MM/YYYY' }}</span>
        <small class="text-muted">{{ violation?.createdAt | moment: 'HH:mm:ss' }}</small>
      </p>
      <p class="w-20 d-flex align-items-center justify-content-center" i18n i18n-appTooltip
      [appTooltip]="getRegulationInfo(violation.regulationId)">
        {{ getRegulationCode(violation?.regulationId) }}
        <i class="material-icons text-secondary ml-2 icon-sm" data-placement="top">info</i>
      </p>
      <p class="w-25 text-left">
        <span class="text-info text-left p-0 mb-0" *ngIf="violation.status!=='valid' && violation.status!=='invalid'" i18n>Em processamento</span>
        <span class="text-danger text-left mb-0 p-0" *ngIf="violation.status==='invalid'" i18n>Inválida</span>
        <span class="text-success text-left mb-0 p-0" *ngIf="violation.status==='valid'" i18n>Válida</span>
        <ng-container *ngIf="violation.status==='invalid'||violation.status==='valid' && getAuthorInfo(violation.history)">
          <div *ngIf="violation.history" class="text-muted text-left p-0 popover-hover">
            <div class="popover-hover__container">
              <h6 i18n>Histórico de processamento</h6>
              <table>
                <thead>
                  <tr>
                    <th i18n>Etapa</th>
                    <th i18n>Responsável</th>
                    <th i18n>Data de processamento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of getHistories(violation.history)">
                    <td>{{ item.title || '------' }}</td>
                    <td class="text-center">{{ item.authorName || '----' }}</td>
                    <td>{{ item.date | moment: 'L LTS'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <small class="text-left" i18n>Processado por</small> {{ getAuthorInfo(violation.history) }} <i class="material-icons icon-sm text-secondary" data-placement="top">info</i>
          </div>
          <!-- <div *ngIf="violation?.history.quarantine" class="text-muted p-0 popover-hover">
            <div class="popover-hover__container">
              <h6 i18n>Histórico de processamento</h6>
              <table>
                <thead>
                  <tr>
                    <th i18n>Etapa</th>
                    <th i18n>Responsável</th>
                    <th i18n>Data de processamento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span *ngIf="violation.step==='validateLane'" i18n>Validar <br />faixa</span>
                      <span *ngIf="violation.step==='validateCalibration'" i18n>Validar <br />aferição</span>
                      <span *ngIf="violation.step==='validateSpeed'" i18n>Validar <br />velocidade</span>
                      <span *ngIf="violation.step==='validateFiles'" i18n>Validar <br />arquivo</span>
                      <span *ngIf="violation.step==='validateExemptPeriod'" i18n>Período <br />isento</span>
                      <span *ngIf="violation.step==='validateVehicleWhiteList'" i18n>Lista <br />branca</span>
                      <span *ngIf="violation.step==='validateViolationLimit'" i18n>Limite <br />de infração</span>
                      <span *ngIf="violation.step==='verifyValid'" i18n>Verificação <br />de Válidas</span>
                      <span *ngIf="violation.step==='triage'" i18n>Triagem</span>
                      <span *ngIf="violation.step==='typing'" i18n>Digitação</span>
                      <span *ngIf="violation.step==='verify'" i18n>Verificação</span>
                      <span *ngIf="violation.step==='validate'" i18n>Validação</span>
                      <span *ngIf="violation.step==='lotAttribution'" i18n>Atribuição <br />de lote</span>
                      <span *ngIf="violation.step==='internalAudit'" i18n>Auditoria <br />interna</span>
                      <span *ngIf="violation.step==='revision'" i18n>Revisão</span>
                      <span *ngIf="violation.step==='filter'" i18n>Filtro</span>
                      <span *ngIf="violation.step==='done'" i18n>Concluído</span>
                      <span *ngIf="violation.step==='serialNumberGeneration'" i18n>Geração do número de série</span>
                    </td>
                    <td> {{ violation?.history.quarantine.authorName || violation?.history.quarantine.functionName || '----' }} </td>
                    <td> {{ violation?.history.quarantine.date | moment: 'L LTS' }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <span i18n>Processado por</span> {{ violation?.history.quarantine.authorName || '----' }} <i class="material-icons icon-sm text-secondary" data-placement="top">info</i>
          </div> -->
        </ng-container>
        <small class="text-left text-muted" *ngIf="violation.status==='invalid'">Motivo: {{ violation?.reason.name }}</small>
      </p>
      <p class="w-15">
        <span *ngIf="violation.step==='validateLane'" i18n>Validar <br />faixa</span>
        <span *ngIf="violation.step==='validateCalibration'" i18n>Validar <br />aferição</span>
        <span *ngIf="violation.step==='validateSpeed'" i18n>Validar <br />velocidade</span>
        <span *ngIf="violation.step==='validateFiles'" i18n>Validar <br />arquivo</span>
        <span *ngIf="violation.step==='validateExemptPeriod'" i18n>Período <br />isento</span>
        <span *ngIf="violation.step==='validateVehicleWhiteList'" i18n>Lista <br />branca</span>
        <span *ngIf="violation.step==='validateViolationLimit'" i18n>Limite <br />de infração</span>
        <span *ngIf="violation.step==='verifyValid'" i18n>Verificação <br />de Válidas</span>
        <span *ngIf="violation.step==='validateEquipment'" i18n>Equipamento <br />inválido</span>
        <span *ngIf="violation.step==='triage'" i18n>Triagem</span>
        <span *ngIf="violation.step==='typing'" i18n>Digitação</span>
        <span *ngIf="violation.step==='verify'" i18n>Verificação</span>
        <span *ngIf="violation.step==='validate'" i18n>Validação</span>
        <span *ngIf="violation.step==='lotAttribution'" i18n>Atribuição <br />de lote</span>
        <span *ngIf="violation.step==='internalAudit'" i18n>Auditoria <br />interna</span>
        <span *ngIf="violation.step==='revision'" i18n>Revisão</span>
        <span *ngIf="violation.step==='filter'" i18n>Filtro</span>
        <span *ngIf="violation.step==='done'" (click)="showHistory(violation.lotId)"
              class="text-primary cursor-pointer" i18n>Concluído</span>
        <span *ngIf="violation.step==='serialNumberGeneration'" i18n>Geração do número de série</span>
      </p>
      <p class="w-5" i18n>
        <button type="button" class="btn btn-sm btn-fade-secondary btn-icon-block" (click)="showDetails(violation)">
          <i class="material-icons">manage_search</i>
        </button>
      </p>
    </div>
  </div>
</ng-container>

<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>

<app-pagination (items)="setViolations($event)" [params]="params" [deleteEvent]="deleteEvent"
    [service]="violationService"></app-pagination>
