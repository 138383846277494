/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-use-before-define */
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputComponent), multi: true }
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.sass']
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() inputRequired: boolean;
  @Input() formControlName: any;
  @Input() placeholder = '';
  @Input() textValue: any;
  @Input() promise: Promise<any>;
  @Input() isLabel = false;
  @Input() hasLabel = true;
  @Input() isLarge = false;
  @Input() isSmall = false;
  get list() {
    return this._list;
  }
  @Input() set list(value) {
    if (value != null) {
      this._list = value;
      this.setMultiselectValue();
    }
  }
  get type() {
    return this._type;
  }
  @Input() set type(value) {
    this._type = value;
    if (value === 'multiselect' || value === 'select') {
      this.setMultiSelectSettings();
      this.setMultiselectValue();
    }
  }
  @Input() selectValue: any;
  @Input() selectText: any;
  @Input() capitalize = false;
  @Input() uppercase = false;
  @Input() format: string;
  @Input() leftUnit;
  @Input() rightUnit;
  @Input() toolTipMsg: string;
  @Input() hasRefresh = false;
  @Output() refresh = new EventEmitter<any>();

  get inputDisabled() {
    return this._inputDisabled;
  }
  @Input() set inputDisabled(value) {
    this._inputDisabled = value;
    this.setMultiSelectSettings();
  }

  @Input() selectShowLimit = 0;
  dropdownSettings: any = {};
  oldValue = '';
  multiselectValue = [];
  public innerValue: string;
  public isSelected: boolean;

  private _inputDisabled = false;
  private _list = [];
  private _type = 'text';
  private readonly errorMessages = {
    required: () => 'Este campo é requerido.',
    email: () => 'Forneça um endereço de email válido.',
    min: (params) => 'Forneça um valor maior ou igual a ' + params.min + '.',
    minlength: (params) => 'Forneça ao menos ' + params.requiredLength + ' caracteres. ',
    max: (params) => 'Forneça um valor menor ou igual a ' + params.max + '.',
    maxlength: (params) => 'Forneça não mais que ' + params.requiredLength + ' caracteres.',
    pattern: (params) => 'O formato fornecido é inválido.',
    invalidPeriod: (params) => 'Campo data/hora inicio não pode ser maior que data/hora fim.',
    duplicatedStep: (params) => 'Etapa já selecionada',
    periodAndMaxAmountViolationError: (params) => 'Ao menos período ou quantidade máxima de infrações, devem ser preenchidos',
    duplicateRegulation: (params) => `Enquadramento ${params} já configurado para esta faixa`,
    quarantineFormError: () => 'Obrigatório se um dos outros dois campos estiverem preenchidos',
    isLowerThan: (params) => `O valor deste campo deve ser menor que ${params.value}`,
    isBiggerThan: (params) => `O valor deste campo deve ser maior que ${params.value}`
  };
  private control: FormControl;
  private propagateChange = (_: any) => {};

  ngOnInit(): void {
    this.setMultiSelectSettings();
  }

  setMultiSelectSettings() {
    this.dropdownSettings = {
      text: this.placeholder || '',
      searchPlaceholderText: 'Buscar',
      selectAllText: 'Todos',
      unSelectAllText: 'Todos',
      primaryKey: this.selectValue,
      labelKey: this.selectText,
      enableSearchFilter: true,
      enableCheckAll: true,
      enableFilterSelectAll: false,
      disabled: this.inputDisabled,
      classes: 'inputDiv',
      singleSelection: this.type === 'select'
    };

    if (this.selectShowLimit > 0) {
      this.dropdownSettings.badgeShowLimit = this.selectShowLimit;
    }
  }

  onChange(event) {
    this.propagateChange(event.target.value);
  }

  onTextChange(event) {
    const value = this.formatValue(event.target.value, event);
    this.propagateChange(value);
  }

  onSelectChange(event) {
    const value = this.formatValue(event.target.value, event);
    this.propagateChange(value);
  }

  onMultiSelect(event) {
    const value = this.getMultiselectValue();
    this.propagateChange(value);
    this.value = value;
  }

  onMultiSelectAll(event) {
    const value = this.getMultiselectValue();
    this.propagateChange(value);
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = this.formatValue(v);
    }
  }

  writeValue(v: any): void {
    this.value = v;
    if (this.type === 'multiselect' || this.type === 'select') {
      this.setMultiselectValue();
    }

    if (this.value === null) {
      this.isSelected = true;
    }
  }

  registerOnTouched(fn: any): void {
  }

  setMultiselectValue() {
    if (this.type === 'multiselect' && Array.isArray(this.value) && Array.isArray(this.list)) {
      const values = this.value.map(item => this.list.find(l => this.getPropValue(l, this.selectValue) === item));
      this.multiselectValue = values.filter(v => v != null);
    } else if (this.type === 'select' && Array.isArray(this.list)) {
      this.multiselectValue = [];
      const value = this.list.find(l => l && this.getPropValue(l, this.selectValue) === this.value);
      if (value != null) {
        this.multiselectValue = [value];
      } else {
        this.multiselectValue = [];
      }
    }
  }

  getMultiselectValue() {
    if (this.type === 'multiselect' || this.type === 'select') {
      const values = this.multiselectValue.map(i => i && this.getPropValue(i, this.selectValue));
      if (this.type === 'multiselect') {
        return values;
      } else {
        return values[0];
      }
    }
  }

  validate(c: FormControl) {
    this.control = c;
  }

  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  getMessage(type: string, params: any) {
    if (this.errorMessages[type] != null) {
      return this.errorMessages[type](params);
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getPropValue(value: object, prop: string) {
    if (prop == null) {
      return value;
    }
    const props = prop.split('.');
    for (const i of props) {
      value = value[i];
      break;
    }
    return value;
  }

  formatValue(value, event = null) {
    if (this.type !== 'text' || this.format == null) {
      return value;
    }
    let i = 0;
    let formated = '';
    const deleted = this.oldValue.length > value.length;
    for (const c of this.format) {
      let reg = null;
      switch (c) {
        case 'd':
          reg = /[0-9]/;
          break;
        case 'c':
          reg = /[a-zA-Z]/;
          break;
        case 'x':
          reg = /[a-zA-Z0-9]/;
          break;
      }
      if (reg != null) {
        if (i >= value.length) {
          break;
        }
        while (i < value.length) {
          if (reg.test(value[i])) {
            formated += value[i];
            i++;
            break;
          }
          i++;
        }
      } else {
        if (deleted && i >= value.length) {
          break;
        }
        if (value[i] === c) {
          i++;
        }
        formated += c;
      }
    }
    if (event != null) {
      let pos = event.target.selectionStart - this.oldValue.length;
      if (deleted) {
        pos++;
      }
      event.target.value = formated;
      event.target.selectionStart = formated.length + pos;
      event.target.selectionEnd = formated.length + pos;
    }
    this.oldValue = formated;
    return formated;
  }
}
