<div class="violations-dashboard">
  <div class="container">
    <div class="page-title">
      <h2 i18n>Infração</h2>
      <p class="page-title__subtitle" i18n>Escolha a funcionalidade que deseja acessar</p>
    </div>

    <div class="mt-4 mb-4" *appPermission="['ConsultViolation']">
      <h6 i18n>Consulta</h6>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3">
          <a routerLink="/violations/violations-view" class="violations-dashboard__block">
            <i class="material-icons">perm_scan_wifi</i>
            <span i18n>Infrações</span>
          </a>
        </div>
      </div>
    </div>

    <div class="mt-4 mb-4" *appPermission="['ViolationsReport']">
      <h6 i18n>Relatórios</h6>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3">
          <a routerLink="/violations/violations-report" class="violations-dashboard__block">
            <i class="material-icons">assignment_late</i>
            <span i18n>Infrações</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
