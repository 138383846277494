<div class="panel" [ngClass]="{'panel--show': !isRendering}">
  <div class="panel__container">
    <div class="panel__header">
      <h4 i18n>Consulta de Placa</h4>
      <p class="text-secondary subtitle" i18n>Informe a placa para realizar a pesquisa</p>
      <hr>
    </div>
    <div class="panel__body">
      <div class="row mb-2">
        <div class="col-6">
          <form [formGroup]="form">
            <app-input i18n-label type="text" label="Placa" formControlName="plate" class="form-control-special"
              [uppercase]="true" [isLarge]="true" [promise]="plateSearchPromise">
            </app-input>
          </form>
        </div>
      </div>
      <div class="row" *ngIf="isLoading">
        <div class="col-12">
          <div class="loading-page">
            <div class="loading-spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3" *ngIf="viewData && !isLoading">
        <div class="col-6">
          <p class="card-label mb-0" i18n>Marca/Modelo</p>
          <p class="card-label-secondary" i18n>{{ brandModelsById[vehicleData?.brandModelId]?.description || '-' }}</p>
        </div>
        <div class="col-6">
          <p class="card-label mb-0" i18n>Marca/Cliente</p>
          <p class="card-label-secondary" i18n>{{ brandInternalModelsById[vehicleData?.brandModelId]?.description || '-' }}</p>
        </div>
      </div>
      <div class="row mb-3" *ngIf="viewData && !isLoading">
        <div class="col-3">
          <p class="card-label mb-0" i18n>Cor</p>
          <p class="card-label-secondary" i18n>{{ colorById[vehicleData?.colorId]?.description || '-' }}</p>
        </div>
        <div class="col-3">
          <p class="card-label mb-0" i18n>Ano</p>
          <p class="card-label-secondary" i18n>{{ vehicleData?.year }}</p>
        </div>
        <div class="col-3">
          <p class="card-label mb-0" i18n>Categoria</p>
          <p class="card-label-secondary" i18n>{{ categoryById[vehicleData?.categoryId]?.description || '-' }}</p>
        </div>
        <div class="col-3">
          <p class="card-label mb-0" i18n>Classificação</p>
          <p class="card-label-secondary" i18n>{{ classificationById[vehicleData?.classificationId]?.description || '-' }}</p>
        </div>
      </div>
      <div class="row mb-3" *ngIf="viewData && !isLoading">
        <div class="col-3">
          <p class="card-label mb-0" i18n>Tipo</p>
          <p class="card-label-secondary" i18n>{{ typeById[vehicleData?.typeId]?.description || '-' }}</p>
        </div>
        <div class="col-3">
          <p class="card-label mb-0" i18n>País</p>
          <p class="card-label-secondary" i18n>Brasil</p>
        </div>
        <div class="col-3">
          <p class="card-label mb-0" i18n>UF</p>
          <p class="card-label-secondary" i18n>{{ stateById[cityById[vehicleData?.cityId]?.stateId]?.abbreviation || '-' }}</p>
        </div>
        <div class="col-3">
          <p class="card-label mb-0" i18n>Município</p>
          <p class="card-label-secondary" i18n>{{ cityById[vehicleData?.cityId]?.name || '-' }}</p>
        </div>
      </div>
    </div>
    <div class="panel__bottom">
      <div class="d-flex w-100 justify-content-end">
        <button class="btn btn-outline-secondary mr-3" (click)="onCancel()" i18n>Cancelar</button>
        <button [disabled]="!cityById[vehicleData?.cityId] || !stateById[cityById[vehicleData?.cityId]?.stateId]" class="btn btn-info" (click)="onSubmit()" i18n>Aplicar</button>
      </div>
    </div>
  </div>
</div>
