/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ViolationReport } from '../models/ViolationReport';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ViolationReportService extends BaseApiService<ViolationReport> {
  private dataViolationReport$: Subject<{}> = new BehaviorSubject<{}>({});
  private contractViolationReport$: Subject<{}> = new BehaviorSubject<{}>({});
  private contractDataViolationReport$: Subject<{}> = new BehaviorSubject<{}>({});
  private periodViolationReport$: Subject<{}> = new BehaviorSubject<{}>({});
  private filteredViolationReport$: Subject<{}> = new BehaviorSubject<{}>({});
  private filterParamsViolationReport$: Subject<{}> = new BehaviorSubject<{}>({});
  private filterParamsDetailsViolationReport$: Subject<{}> = new BehaviorSubject<{}>({});

  get cacheKey(): StorageKey {
    return StorageKey.violationReportServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/report/violationCountReport`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  protected parse(obj) {
    return ViolationReport.parse(obj);
  }

  protected unparse(obj) {
    return ViolationReport.unparse(obj);
  }

  emitDataViolationReport(allData: {} = {}) {
    this.dataViolationReport$.next(allData);
  }

  emitContractViolationReport(allData: {} = {}) {
    this.contractViolationReport$.next(allData);
  }

  emitContractDataViolationReport(allData: {} = {}) {
    this.contractDataViolationReport$.next(allData);
  }

  emitPeriodViolationReport(allData: {} = {}) {
    this.periodViolationReport$.next(allData);
  }

  emitFilteredViolationReport(allData: {} = {}) {
    this.filteredViolationReport$.next(allData);
  }

  emitFilterParamsViolationReport(allData: {} = {}) {
    this.filterParamsViolationReport$.next(allData);
  }

  emitFilterParamsDetailsViolationReport(allData: {} = {}) {
    this.filterParamsDetailsViolationReport$.next(allData);
  }

  getDataViolationReport() {
    return this.dataViolationReport$.asObservable();
  }

  getContractViolationReport() {
    return this.contractViolationReport$.asObservable();
  }

  getContractDataViolationReport() {
    return this.contractDataViolationReport$.asObservable();
  }

  getPeriodViolationReport() {
    return this.periodViolationReport$.asObservable();
  }

  getFilteredViolationReport() {
    return this.filteredViolationReport$.asObservable();
  }

  getFilterParamsViolationReport() {
    return this.filterParamsViolationReport$.asObservable();
  }

  getFilterParamsDetailsViolationReport() {
    return this.filterParamsDetailsViolationReport$.asObservable();
  }

}
