<app-enum-i18n [input]="steps" (output)="setStep($event)"></app-enum-i18n>
<div class="modal-dialog modal-dialog-centered modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>{{translatedStep && translatedStep.value}}</h5>
      <button type="button" (click)="onCancel()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body" #container>
      <form [formGroup]="form">
        <div class="mt-3 mb-5">
          <app-input class="relative" i18n-label i18n-toolTipMsg i18n-placeholder type="multiselect" [list]="regulations"
          selectValue="id" selectText="prettyName" label="Selecione o enquadramento que deseja processar" selectShowLimit=1
          placeholder="Selecione o enquadramento" formControlName="regulationIds" toolTipMsg="Caso não seja selecionado nenhum item, serão considerados todos os enquadramentos.">
          </app-input>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button i18n (click)="onCancel()" class="btn btn-outline-secondary">Cancelar</button>
      <button i18n (click)="onSubmit()"
        [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">Confirmar</button>
    </div>
  </div>
</div>
