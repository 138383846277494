import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ModalService, ViewViolationService } from 'src/app/core/services';
import { Category, Lane, Regulation } from 'src/app/core/models';

@Component({
  selector: 'app-violations-view-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.sass']
})
export class DetailsViolationComponent extends BaseModal implements OnInit, OnDestroy {
  isValid: boolean;
  lastState: any;
  public violation: any = {};
  public fileUrl: string;
  public fileType: string;
  public contractViewViolationsSub: Subscription;
  public regulations: Regulation[] = [];
  public lanes: Lane[] = [];
  public categories: Category[] = [];
  public state = 0;
  public files: [] = [];

  constructor(
    modalService: ModalService,
    private viewViolationService: ViewViolationService,
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.violation = this.initialState && this.initialState.violation || {};
    this.contractViewViolationsSub = this.viewViolationService.getDataViewContractViolation()
    .pipe(filter(result => Object.keys(result).length > 0))
    .subscribe((data: any) => {
      this.regulations = data.regulations;
      this.lanes = data.lanes;
      this.categories = data.categories;
    });
    this.files = this.violation.files || null;
    this.verifySpeed();
  }

  verifySpeed() {
    if (this.violation.speed >= this.violation.speedLimit + (this.violation.speedLimit / 100) * 100) {
      this.violation.speedWarning = true;
    }
  }

  async onAllSubmited(): Promise<any> {
  }

  getRegulationCode(regulation) {
    if (this.regulations.length > 0) {
      return this.regulations.filter(item => item.id === regulation).map(item => item.code).shift();
    }
  }

  getRegulationInfo(regulation) {
    if (this.regulations.length > 0) {
      return this.regulations.filter(item => item.id === regulation).map(item => item.description).shift();
    }
  }

  getLaneInfo(lane) {
    const laneInfo = this.lanes.filter(item => item.code === lane).map(item => item.prettyNames && item.prettyNames[0]).shift();
    if (laneInfo) {
      return laneInfo;
    }
  }

  getCategory(category) {
    return this.categories.filter(item => item.code === category).map(item => item.description).shift();
  }

  ngOnDestroy() {
    this.contractViewViolationsSub.unsubscribe();
  }
}
