<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Veículo na lista branca</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body label">
      <div class="container">
        <p i18n>
          Veículo está dentro do período de isenção e não deve ser considerado um registro VÁLIDO.
        </p>
        <p>
          Data da infração: {{ vehicleWhiteList?.date | moment: 'L' }}. Período de isenção: {{ vehicleWhiteList?.startsAt | moment: 'L' }} à {{ vehicleWhiteList?.endsAt | moment: 'L' }}.
        </p>

      </div>
    </div>
    <div class="modal-footer">
      <button i18n (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true}">Confirmar</button>
    </div>
  </div>
</div>
