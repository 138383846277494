import { BaseApiModel } from './BaseApiModel';

export class State extends BaseApiModel {
    public name: string;
    public regionId: string;
    public abbreviation: string;
    public modifiedAt: Date;
    public createdAt: Date;
    public deletedAt: Date;
}
