import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { debounceTime, filter } from 'rxjs/operators';
import { Spot, Lane, Reason, Category, Regulation, Lot } from 'src/app/core/models';
import {
  ViolationReportService, LaneService,
  ReasonService, SpotService, CategoryService, CsvDataServiceService,
  LotService
} from 'src/app/core/services';

@Component({
  selector: 'app-violations-report-contract',
  templateUrl: './contract.component.html'
})

export class ViolationsReportContractComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public contractId: string;
  public contractCode: string;
  public contractName: string;
  public contract: any = {};
  public spots: Spot[] = [];
  public violations: any[] = [];
  public violationsSource: any[] = [];
  public lanes: Array<Lane> = [];
  public lots: Array<Lot> = [];
  public reasons: Array<Reason> = [];
  public categories: Category[] = [];
  public contractViolationsSub: Subscription;
  public periodViolationsSub: Subscription;
  public filterViolationsSub: Subscription;
  public routeSub: Subscription;
  public regulations: Regulation[] = [];
  public contractDataViolationReport: any = {};
  public situationsIds: any[] = ['inProcess', 'valid', 'invalid'];
  public pageLevel = 0;
  public laneId: string;
  public noViolations: boolean;
  public situationsTranslated;
  public startDate;
  public endDate;
  public filterParams: any = {};
  public exportData: any = [];
  public hideExportCsvButton = false;

  constructor(
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private laneService: LaneService,
    private lotService: LotService,
    private reasonService: ReasonService,
    private router: Router,
    private spotService: SpotService,
    private violationReportService: ViolationReportService,
    private csvDataService: CsvDataServiceService,
  ) { }

  async ngOnInit() {
    if (this.route.snapshot) {
      this.contractId = this.route.snapshot.params.contractId;
    }

    if (this.route.snapshot.children[0].params.dateId) {
      this.hideExportCsvButton = true;
    }

    if (this.route.snapshot.children) {
      this.getPageLevel(this.route.snapshot.children[0].params);
    }

    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof ActivationStart) {
        this.getPageLevel(event.snapshot.params);
      }
    });
    const now = new Date().toISOString();
    this.startDate = moment(now).subtract(7, 'days').utc().format();
    this.endDate = moment(now).utc().format();
    if (localStorage.getItem('periodViolationReport')) {
      const periodViolationReport = JSON.parse(localStorage.getItem('periodViolationReport'));
      this.startDate = periodViolationReport.startDate;
      this.endDate = periodViolationReport.endDate;
    }

    this.contractViolationsSub = this.violationReportService.getDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe(async (data: any) => {
        this.contract = data.contracts.find(contract => contract.contractId === Number(this.contractId));

        this.contractId = this.contract ? this.contract.contractId : null;
        this.contractCode = this.contract ? this.contract.contractCode : null;
        this.contractName = this.contract ? this.contract.contractName : null;
        this.regulations = data.regulations;

        const regionId = this.contract ? this.contract.regionId : null;
        const timezone = this.contract ? this.contract.timezone : null;
        const paramsLot: any = {
          contractId: this.contractId,
          ['sortAsc']: 'false',
          ['violationsAmount[gte]']: 0,
          order: 'oldestViolationAt,newestViolationAt'
        };
        await this.lotService.getAll(paramsLot).then((result) => this.lots = result);
        await this.laneService.getAll({ contractId: this.contractId }).then((result) => this.lanes = result);
        await this.reasonService.getAll({
          contractId: this.contractId
        }).then((res) => {
          this.reasons = res.sort((a, b): any => Number(a.code) - Number(b.code));
        });
        await this.spotService.getAll({ contractId: this.contractId }).then((result) => this.spots = result);
        this.getViolations();

        this.categoryService.getAll({ regionId }).then((result) => {
          this.categories = result;
        }).finally(() => {
          this.contractDataViolationReport = {
            contractId: this.contractId,
            contractName: this.contractName,
            timezone,
            regulations: data.regulations,
            lanes: this.lanes,
            reasons: this.reasons,
            spots: this.spots,
            categories: this.categories,
            situations: this.situationsTranslated,
            lots: this.lots
          };

          this.violationReportService.emitContractDataViolationReport(this.contractDataViolationReport);
        });

        setTimeout(() => {
          this.loading = true;
        }, 2000);
      });

    this.periodViolationsSub = this.violationReportService.getPeriodViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((dataPeriod: any) => {
        this.startDate = dataPeriod.startDate;
        this.endDate = dataPeriod.endDate;
        this.getViolations();
      });

    this.filterViolationsSub = this.violationReportService.getFilterParamsViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((result: any) => {
        this.filterParams = result;
        this.filterValues();
      });
  }

  getViolations() {
    const params: any = {};
    params['contractId[in]'] = `[${this.contractId}]`;
    params['date[gte]'] = moment(this.startDate).format('YYYY-MM-DD');
    params['date[lte]'] = moment(this.endDate).format('YYYY-MM-DD');
    this.violations = [];

    this.violationReportService.getAll(params).then((result: any) => {
      if (result.length > 0) {
        for (const violation of result) {
          const { contractId, date, laneId, modifiedAt, id, createdAt, ...regulationsKeys } = violation;
          for (const [key, value] of Object.entries(regulationsKeys)) {
            const objViolation: any = {};
            const keySplit = key.split('#');
            objViolation.value = value;
            objViolation.date = date;
            objViolation.regulation = this.regulations
              .filter((item: any) => parseInt(item.id, 10) === parseInt(keySplit[0], 10))
              .map(item => item.code).shift();
            objViolation.laneId = this.lanes.filter(lane => lane.id === laneId).map(item => item.id).shift();
            objViolation.laneCode = this.lanes.filter(lane => lane.id === laneId).map(item => item.code).shift();
            objViolation.spotId = this.spots.filter((spot: any) => spot.laneIds.includes(laneId)).map(item => item.id).shift();
            objViolation.spotCode = this.spots.filter((spot: any) => spot.laneIds.includes(laneId)).map(item => item.code).shift();

            if (!objViolation.regulation || !objViolation.spotId || !objViolation.laneId) {
              continue;
            }
            if (keySplit[1] === undefined) {
              objViolation.type = 'inProcess';
            } else if (keySplit[1] === 'valid') {
              objViolation.type = 'valid';
            } else {
              objViolation.type = 'invalid';
              objViolation.reasonCode = keySplit[1];
              const reason = this.reasons.find(r => r.code === keySplit[1]);
              objViolation.reasonName = reason.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            }
            objViolation.reasonCode = !objViolation.reasonCode ? '' : objViolation.reasonCode;
            objViolation.reasonName = !objViolation.reasonName ? '' : objViolation.reasonName;

            if (keySplit[1] !== 'linked') {
              this.violations.push(objViolation);
            }
          }
        }

        this.filterValues();
      } else {
        this.noViolations = true;
        this.violationReportService.emitContractViolationReport({ list: false });
      }
    }).finally(() => {
      this.loading = true;
    });
  }

  filterValues() {
    let result = this.violations;
    if (this.filterParams.spotsIds && this.filterParams.spotsIds.length > 0) {
      const spotsIds = this.filterParams.spotsIds.map(Number);
      result = result.filter(violation => spotsIds.includes(violation.spotId));
    }

    if (this.filterParams.laneIds && this.filterParams.laneIds.length > 0) {
      const laneIds = this.filterParams.laneIds.map(Number);
      result = result.filter(violation => laneIds.includes(violation.laneId));
    }

    if (this.filterParams.regulationsIds && this.filterParams.regulationsIds.length > 0) {
      const regulationIds = this.filterParams.regulationsIds.map(Number);
      const regulationCodes = this.regulations.filter(r => regulationIds.includes(r.id)).map(reg => reg.code);
      result = result.filter(violation => regulationCodes.includes(violation.regulation));
    }

    if (this.filterParams.situationsIds && this.filterParams.situationsIds.length > 0) {
      result = result.filter(violation => this.filterParams.situationsIds.includes(violation.type));
    }

    if (this.filterParams.reasonsIds && this.filterParams.reasonsIds.length > 0) {
      const reasonValues = [];
      this.filterParams.reasonsIds.map(id => {
        reasonValues.push(this.reasons.filter(reason => reason.id === id).shift()?.prettyName.split(' ')[0]);
      });
      result = result.filter(violation => reasonValues.includes(violation.reasonCode));
    }

    if (result.length > 0) {
      this.violationReportService.emitContractViolationReport(result);
      this.violationReportService.emitFilteredViolationReport(result);
      this.noViolations = false;
    } else {
      this.noViolations = true;
      this.violationReportService.emitContractViolationReport({ list: false });
    }
    this.violationReportService.getContractViolationReport()
      .pipe(filter(resultReport => Object.keys(resultReport).length > 0), debounceTime(1000))
      .subscribe((data: any) => {
        this.getPageLevel(this.route.snapshot.children[0].params);
        this.exportData = data;
      });
  }

  exportCsv(value) {
    if (this.exportData.length > 0) {
      const startDate = moment(this.startDate.split('T')[0]).format('DD-MM-YYYY');
      const endDate = moment(this.endDate.split('T')[0]).format('DD-MM-YYYY');
      const nameReport = `${this.contractCode} - ${this.contractName} - Relatorio de Infracao - ${startDate}_${endDate}.csv`;
      this.csvDataService.exportToCsv(nameReport, this.exportData, value.isTrusted);
    }
  }

  setResources(value) {
    this.situationsTranslated = value;
  }

  getPageLevel(routeParams: any = {}) {
    this.pageLevel = 1;

    if (Object.keys(routeParams).includes('contractId')) {
      this.pageLevel = 0;
    }

    if (Object.keys(routeParams).includes('dateId')) {
      this.pageLevel = 2;
    }
    if ([0, 1].includes(this.pageLevel) === false) {
      this.hideExportCsvButton = true;
    }
  }

  getBackLink() {
    const filterParam: any = this.filterParams;
    if (this.pageLevel === 0) {
      this.router.navigate(['../'], { relativeTo: this.route });
    } else if (this.pageLevel === 1) {
      this.router.navigate(['../' + this.contractId], { relativeTo: this.route });
    } else {
      this.hideExportCsvButton = false;
      this.router.navigate(['../' + this.contractId + '/by-date'], { relativeTo: this.route });
    }
    this.violationReportService.emitFilterParamsViolationReport(filterParam);
  }

  ngOnDestroy() {
    if (this.contractViolationsSub) {
      this.contractViolationsSub.unsubscribe();
      this.periodViolationsSub.unsubscribe();
      this.filterViolationsSub.unsubscribe();
      this.violationReportService.emitContractDataViolationReport({});
      this.violationReportService.emitContractViolationReport({});
    }
    localStorage.removeItem('periodViolationReport');
  }
}
