import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PreProcessSettings, Contract } from 'src/app/core/models';
import { PreProcessSettingsService } from 'src/app/core/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pre-process-config-lot-manual',
  templateUrl: './config-lot-manual.component.html',
  styleUrls: ['./config-lot-manual.component.sass']
})
export class ConfigLotManualComponent implements OnInit {
  @Input() preProcessSettings: PreProcessSettings;
  @Output() nextStepCommand = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  @Output() isValid = new EventEmitter();
  public formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    if (this.preProcessSettings != null && this.preProcessSettings.lotGeneration != null) {
      this.createForm(this.preProcessSettings.lotGeneration);
    } else {
      this.createForm();
    }
    this.isValid.emit(this.formGroup.valid);
  }

  createForm(lotGeneration: any = {}) {
    if (this.formGroup == null) {
      this.formGroup = new FormGroup({});
    }
    this.formGroup = this.formBuilder.group({
      name: [lotGeneration.namePattern, Validators.required],
      path: [lotGeneration.pathPattern, Validators.required]
    });
    this.formGroup.valueChanges.subscribe(() => {
      this.isValid.emit(this.formGroup.valid);
      if (this.formGroup.valid) {
        this.outputValue.emit(this.getOutput());
      }
    });
  }

  getOutput() {
    const lotGeneration = {
      namePattern: this.formGroup.get('name').value,
      pathPattern: this.formGroup.get('path').value
    };
    return { lotGeneration };
  }
}
