import { BaseApiModel } from './BaseApiModel';

export class Violation extends BaseApiModel {
  public passId: string;
  public auditAuthorId: any;
  public contractId: string;
  public laneCode: string;
  public laneClientCode: string;
  public lotId: string;
  public regulationId: string;
  public date: Date;
  public speedLimit: number;
  public speed: number;
  public speedWarning: boolean;
  public redLightPeriod: number;
  public stopPeriod: number;
  public equipmentViolationCounter: number;
  public address: {
    street: string;
    // eslint-disable-next-line id-blacklist
    number: number;
    zipCode: string;
    isKm: boolean;
    landMark: string;
  };
  public gracePeriod: number;
  public vehicle: {
    length: number;
    profile: number;
    size: number;
    plate: string;
    plateOCR: string;
    brandModelCode: string;
    colorCode: string;
    classificationCode: string;
    categoryCode: string;
    typeCode: string;
    cityCode: string;
    year: number;
    modelYear: number;
    chassis: string;
    renavam: number;
    speed: number;
    weight: number;
  };
  public files: Array<{
    // eslint-disable-next-line no-use-before-define
    type: FileType;
    targeHeight: number;
    targePosition: string;
    urlCurrent: string;
    urlEncrypted: string;
    urlDecrypted: string;
  }>;
  public equipment: {
    id: string;
    serialNumber: string;
    manufacturerId: string;
    model: string;
    calibration: {
      appraisalNumber: string;
      date: Date;
    };
  };
  public reason: {
    code: string;
    name: string;
    type: string;
  };
  public linkedParent: string;
  public linkedChild: string;
  public notes: Array<{
    userId: string;
    dateTime: Date;
    comment: string;
  }>;
  public quarantineReason: string;
  public status: string;
  public step: string;
  public history: {
    triage: Array<{
      date: Date;
      author: string;
      registryNumber: {
        type: string;
      };
    }>;
    typing: Array<{
      date: Date;
      author: string;
      registryNumber: string;
    }>;
    doubleTyping: Array<{
      date: string;
      author: string;
      registryNumber: string;
    }>;
    verifyValid: Array<{
      date: Date;
      author: string;
      registryNumber: string;
    }>;
    verifyInvalid: Array<{
      date: Date;
      author: string;
      registryNumber: string;
    }>;
    validate: Array<{
      date: Date;
      author: string;
      registryNumber: string;
    }>;
    lotAttribution: {
      date: Date;
      author: string;
      registryNumber: string;
    };
    audits: Array<{
      date: Date;
      author: string;
      registryNumber: string;
      lotRevision: string;
    }>;
    revisions: Array<{
      date: Date;
      author: string;
      registryNumber: string;
      lotRevision: string;
    }>;
  };
  public lock: {
    date: Date;
    user: string;
  };
  public createdAt: Date;
  public modifiedAt: Date;
  public extraData: any;
  public exemptPeriodId: string;
  public vehicleWhiteListId: string;
}

// eslint-disable-next-line no-shadow
export enum FileType {
  pan = 'pan',
  pan2 = 'pan2',
  zoom = 'zoom',
  video = 'video',
  video2 = 'video2',
  additional = 'additional',
  additional2 = 'additional2'
}
