import { Injectable } from '@angular/core';
import { Session } from '../models/Session';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService  extends BaseApiService<Session> {
  get cacheKey(): StorageKey {
    return StorageKey.sessionServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/sessions`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  protected parse(obj) {
    return Session.parse(obj);
  }

  protected unparse(obj) {
    return Session.unparse(obj);
  }
}
