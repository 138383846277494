import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService } from 'src/app/core/services';

@Component({
  selector: 'app-img-edit-modal',
  templateUrl: './img-edit-modal.component.html',
  styleUrls: ['./img-edit-modal.component.sass']
})
export class ImgEditModalComponent extends BaseModal implements OnInit {
  public lastImageState;
  public operation = '';
  public type;
  public isBlurEnabled = false;
  public isClipEnabled = false;
  public imagesSettings: any;
  public modifedBuffers;

  files: Array<{ src: string; type: string }> = [];
  constructor(
    modalService: ModalService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.type = (this.initialState && this.initialState.type) || '';
    const files = (this.initialState && this.initialState.files) || [];
    this.isBlurEnabled = (this.initialState && this.initialState.isClipEnabled) || false;
    this.isClipEnabled = (this.initialState && this.initialState.isBlurEnabled) || false;
    if (files != null ) {
      files.forEach(file => {
        if (file.type === this.type) {
          this.files.push(file);
        }
      });
    }
    this.operation = (this.initialState && this.initialState.operation) || '';
    this.imagesSettings = (this.initialState && this.initialState.imagesSettings);
    this.modifedBuffers = (this.initialState && this.initialState.modifedBuffers) || {};
  }

  get isValid(): boolean {
    return this.lastImageState != null;
  }
  get lastState(): any {
    return this.lastImageState;
  }
  onAllSubmited(): Promise<any> {
    return this.lastImageState;
  }

  setImageState(event) {
    this.lastImageState = event;
  }
}
