import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ViolationReportCounterService extends BaseApiService<any> {
  get cacheKey(): StorageKey {
    return StorageKey.violationReportCounterServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/report/violationCountReport/`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  recount(contractId: any, date: any): Promise<any>{
    return this.http.get(`${environment.apiUrl}/report/violationCountReport?contractId=${contractId}&date=${date}`, {}).pipe().toPromise();
  }

  recountReport(contractId: any, startDate: any, endDate: any): Promise<any>{
    return this.http.post(`${environment.apiUrl}/report/violationRecount`, {
      contractId,
      startDate,
      endDate
    }).pipe().toPromise();
  }

  protected unparse(obj) {
    return;
  }

  protected parse(obj) {
    return;
  }

}
