import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class QuarentineRecountService extends BaseApiService<any> {
  get cacheKey(): StorageKey {
    return StorageKey.quarentineRecountServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/quarantine/recount/`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  recount(contractId: any): Promise<any>{
    return this.http.put(`${environment.apiUrl}/quarantine/recount/${contractId}`, {}).pipe().toPromise();
  }

  protected unparse(obj) {
    return;
  }

  protected parse(obj) {
    return;
  }

}
