import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';
import { Samplings } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SamplingsService extends BaseApiService<Samplings> {
  get cacheKey(): StorageKey {
    return StorageKey.samplingsServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/sampling`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  getSamplings(query: any) {
    const headers = {};
    headers['Content-Type'] = 'application/json';
    headers['Authorization[in]'] = `${localStorage.getItem('accessToken')}`;
    return this.http.get(`${environment.apiUrl}/sampling?acceptableQualityLevel=${query.acceptableQualityLevel}`, headers);
  }

  protected unparse(obj) {
    return Samplings.unparse(obj);
  }

  protected parse(obj) {
    return Samplings.parse(obj);
  }
}
