/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessViolationService {

  private dataProcessViolation$: Subject<{}> = new BehaviorSubject<{}>({});
  private dataProcessContractViolation$: Subject<{}> = new BehaviorSubject<{}>({});
  private filterProcessViolation$: Subject<{}> = new BehaviorSubject<{}>({});

  emitDataProcessViolation(allData: {} = {}) {
    this.dataProcessViolation$.next(allData);
  }

  emitDataProcessContractViolation(allData: {} = {}) {
    this.dataProcessContractViolation$.next(allData);
  }

  emitFilterProcessViolation(period: {} = {}) {
    this.filterProcessViolation$.next(period);
  }

  getDataProcessViolation() {
    return this.dataProcessViolation$.asObservable();
  }

  getDataProcessContractViolation() {
    return this.dataProcessContractViolation$.asObservable();
  }

  getFilterProcessViolation() {
    return this.filterProcessViolation$.asObservable();
  }

}
