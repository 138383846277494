import { Modal } from './Modal';
import { TreeviewItem } from 'ngx-treeview';

export class SelectModal extends Modal {
    public confirmation = true;
    public config;
    constructor(
        public title: string,
        public items: TreeviewItem[],
        public single: boolean = false,
        enableAll: boolean = false,
        hasFilter: boolean = false,
        public values = []
    ) {
        super();
        this.config = {
            hasAllCheckBox: enableAll,
            hasFilter,
            hasCollapseExpand: false,
            decoupleChildFromParent: false
        };
    }
}
