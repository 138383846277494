/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import { BaseApiModel } from './BaseApiModel';

export class Lane extends BaseApiModel {
  public code: string;
  public name: string;
  public clientCode: string;
  // eslint-disable-next-line id-blacklist
  public number: number;
  public equipmentIds: Array<string>;
  public contractId: string;
  public spotId: string;
  public direction: string;
  public modifiedAt: Date;
  public createdAt: Date;
  public prettyNames: Array<string>;
  public operationMode: OperationModes;
  public restrictionZoneCode: string;
  public isBetweenLane: boolean;
  public speeds: {
    regularVehicles: number;
    heavyVehicles: number;
    regulationLimits: Array<{
      regulationId: string;
      initialRegular: number;
      finalRegular: number;
      initalHeavy: number;
      finalHeavy: number;
    }>;
  };
  public enabledViolations: Array<{
    regulationId: string;
    classificationCodes: Array<string>;
    requiredFiles: Array<string>;
    startsAt: string;
    endsAt: string;
    periods: Array<{
      startTime: string;
      endTime: string;
      weekdays: Array<number>;
      plateRegex: string;
    }>;
  }>;
  public extraData: any;
  public clientDescription: string;
  public contractClientCodes: Array<{
    code: any;
    startsAt: any;
    endsAt: any;
  }>;

  get prettyId() {
    return this.id.replace(/#/g, '-');
  }

  get laneCodeAndSpotCode() {
    return `${this.code} - Fx ${this.number}`; // todo (Ponto ${this.spotId.split('#')[1]})`;
  }
}

export enum OperationModes {
  enabled = 'enabled',
  test = 'test',
  disabled = 'disabled'
}
