<div class="container">
  <div class="page-title">
    <h2 i18n>Configurações Pré-Processamento: {{ contract.code + ' - ' + contract.name }}</h2>
  </div>

  <div class="row mb-5">
    <div class="col-12 col-md-3">
      <ul class="list-group">
        <li class="list-group-item" (click)="changeStep('violationSteps')"
          [ngClass]="{'list-group-item--done': isStepDone('violationSteps')}">
          <i class="material-icons" *ngIf="!isStepDone('violationSteps')  && step !== 'violationSteps'">radio_button_unchecked</i>
          <i class="material-icons" *ngIf="step === 'violationSteps'">radio_button_checked</i>
          <i class="material-icons" *ngIf="isStepDone('violationSteps')">task_alt</i>
          <span i18n>Fluxo das Infrações</span>
        </li>
        <li class="list-group-item" (click)="changeStep('lotSteps')"
          [ngClass]="{'list-group-item--done': isStepDone('lotSteps')}">
          <i class="material-icons" *ngIf="!isStepDone('lotSteps')  && step !== 'lotSteps'">radio_button_unchecked</i>
          <i class="material-icons" *ngIf="step === 'lotSteps'">radio_button_checked</i>
          <i class="material-icons" *ngIf="isStepDone('lotSteps')">task_alt</i>
          <span i18n>Fluxo de Lotes</span>
        </li>
        <li class="list-group-item" (click)="changeStep('general')"
          [ngClass]="{'list-group-item--done': isStepDone('general')}">
          <i class="material-icons" *ngIf="!isStepDone('general')  && step !== 'general'">radio_button_unchecked</i>
          <i class="material-icons" *ngIf="step === 'general'">radio_button_checked</i>
          <i class="material-icons" *ngIf="isStepDone('general')">task_alt</i>
          <span i18n>Gerais</span>
        </li>
        <li class="list-group-item" (click)="changeStep('lotManual')"
          [ngClass]="{'list-group-item--done': isStepDone('lotManual')}">
          <i class="material-icons" *ngIf="!isStepDone('lotManual')  && step !== 'lotManual'">radio_button_unchecked</i>
          <i class="material-icons" *ngIf="step === 'lotManual'">radio_button_checked</i>
          <i class="material-icons" *ngIf="isStepDone('lotManual')">task_alt</i>
          <span i18n>Lote Manual</span>
        </li>

        <li class="list-group-item" (click)="changeStep('audit')"
          [ngClass]="{'list-group-item--done': isStepDone('audit')}" *ngIf="isAudit">
          <i class="material-icons" *ngIf="!isStepDone('audit') && step !== 'audit'">radio_button_unchecked</i>
          <i class="material-icons" *ngIf="step === 'audit'">radio_button_checked</i>
          <i class="material-icons" *ngIf="isStepDone('audit')">task_alt</i>
          <span i18n>Auditoria</span>
        </li>
        <li class="list-group-item" (click)="changeStep('lotAutoGeneration')"
          [ngClass]="{'list-group-item--done': isStepDone('lotAutoGeneration')}">
          <i class="material-icons" *ngIf="!isStepDone('lotAutoGeneration')  && step !== 'lotAutoGeneration'">radio_button_unchecked</i>
          <i class="material-icons" *ngIf="step === 'lotAutoGeneration'">radio_button_checked</i>
          <i class="material-icons" *ngIf="isStepDone('lotAutoGeneration')">task_alt</i>
          <span i18n>Lote Automático</span>
        </li>
        <li class="list-group-item" (click)="changeStep('violationLimit')"
          [ngClass]="{'list-group-item--done': isStepDone('violationLimit')}">
          <i class="material-icons" *ngIf="!isStepDone('violationLimit')  && step !== 'violationLimit'">radio_button_unchecked</i>
          <i class="material-icons" *ngIf="step === 'violationLimit'">radio_button_checked</i>
          <i class="material-icons" *ngIf="isStepDone('violationLimit')">task_alt</i>
          <span i18n>Limite de Infrações</span>
        </li>
        <li class="list-group-item" (click)="changeStep('imageEdit')"
          [ngClass]="{'list-group-item--done': isStepDone('imageEdit')}">
          <i class="material-icons" *ngIf="!isStepDone('imageEdit')  && step !== 'imageEdit'">radio_button_unchecked</i>
          <i class="material-icons" *ngIf="step === 'imageEdit'">radio_button_checked</i>
          <i class="material-icons" *ngIf="isStepDone('imageEdit')">task_alt</i>
          <span i18n>Edição de Imagens</span>
        </li>
      </ul>
    </div>
    <div class="col-12 col-md-9">
      <div class="card">
        <div *ngIf="isPreProcessLoaded; else loadingBlock">
          <div [ngSwitch]="step">
            <div *ngSwitchCase="'general'">
              <app-pre-process-config-general [preProcessSettings]="preProcessSettingsCopy" (isValid)="isValid=$event"
                (nextStepCommand)="nextStep($event)" (outputValue)="setPreProcessValues($event)">
              </app-pre-process-config-general>
            </div>
            <div *ngSwitchCase="'lotAutoGeneration'">
              <app-auto-lot-config [preProcessSettings]="preProcessSettingsCopy" (isValid)="isValid=$event"
                (nextStepCommand)="nextStep($event)" (outputValue)="setPreProcessValues($event)"
                [promise]="promise" (savePreProcessCommand)="savePreProcess($event)" [stopAttribution]="stopAttribution">
              </app-auto-lot-config>
            </div>
            <div *ngSwitchCase="'violationLimit'">
              <app-pre-process-config-violation-limit [preProcessSettings]="preProcessSettingsCopy"
                (nextStepCommand)="nextStep($event)" (isValid)="isValid=$event"
                (outputValue)="setPreProcessValues($event)">
              </app-pre-process-config-violation-limit>
            </div>
            <div *ngSwitchCase="'lotSteps'">
              <app-lot-steps [preProcessSettings]="preProcessSettingsCopy" (nextStepCommand)="nextStep($event)"
                (isValid)="isValid=$event" (outputValue)="setPreProcessValues($event)">
              </app-lot-steps>
            </div>
            <div *ngSwitchCase="'imageEdit'">
              <app-pre-process-config-edit-image [preProcessSettings]="preProcessSettingsCopy"
                (nextStepCommand)="nextStep($event)" (isValid)="isValid=$event"
                (outputValue)="setPreProcessValues($event)">
              </app-pre-process-config-edit-image>
            </div>
            <div *ngSwitchCase="'lotManual'">
              <app-pre-process-config-lot-manual [preProcessSettings]="preProcessSettingsCopy"
                (nextStepCommand)="nextStep($event)" (isValid)="isValid=$event"
                (outputValue)="setPreProcessValues($event)">
              </app-pre-process-config-lot-manual>
            </div>
            <div *ngSwitchCase="'audit'">
              <app-config-audit [preProcessSettings]="preProcessSettingsCopy"
                (nextStepCommand)="nextStep($event)" (isValid)="isValid=$event"
                (outputValue)="setPreProcessValues($event)">
              </app-config-audit>
            </div>
            <div *ngSwitchCase="'violationSteps'">
              <app-pre-process-config-violation-steps [preProcessSettings]="preProcessSettingsCopy"
                (nextStepCommand)="nextStep($event)" (isValid)="isValid=$event"
                (outputValue)="setPreProcessValues($event)">
              </app-pre-process-config-violation-steps>
            </div>
          </div>
        </div>

        <ng-template #loadingBlock>
          <div class="col-12">
            <div class="loading-spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </ng-template>

        <div class="card-footer d-flex justify-content-end mt-3" *ngIf="preProcessSettings.id; else wizardButtons">
          <button class="btn btn-outline-secondary mr-3" (click)="redirect()">
            <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
          </button>
          <button class="btn btn-primary" [ngClass]="{'disabled': !isValid}"
            (click)="confirmation()">
            <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
          </button>
        </div>

        <ng-template #wizardButtons>
          <div class="card-footer d-flex justify-content-end mt-3">
            <button class="btn btn-outline-secondary mr-3" [ngClass]="{'disabled': !isValid}"
              (click)="prevStep()">
              <app-loading [promise]="promise" text="Voltar" i18n-text></app-loading>
            </button>
            <button class="btn btn-primary" [ngClass]="{'disabled': !isValid}"
              (click)="nextStep()">
              <app-loading [promise]="promise" text="Avançar" i18n-text></app-loading>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
