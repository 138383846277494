import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreProcessSettings, AlertItem, AlertType, MessageModal, Contract, VehicleDataSearchTypes } from 'src/app/core/models';
import { PreProcessSettingsService } from 'src/app/core/services/preProcessSettings.service';
import * as _ from 'lodash';
import { AlertService, ContractService, ModalService } from 'src/app/core/services';

@Component({
  selector: 'app-pre-process-config-wizard',
  templateUrl: './config-wizard.component.html',
  styleUrls: ['./config-wizard.component.sass']
})
export class ConfigWizardComponent implements OnInit {
  public preProcessSettings: PreProcessSettings = new PreProcessSettings();
  public preProcessSettingsCopy: PreProcessSettings = new PreProcessSettings();
  public contract: Contract = new Contract();
  public contractId: number;
  public isValid: boolean;
  public isAudit = false;
  public step = 'violationSteps';
  public steps = [
    'violationSteps',
    'lotSteps',
    'general',
    'lotManual',
    'audit',
    'lotAutoGeneration',
    'violationLimit',
    'imageEdit'
  ];
  public isPreProcessLoaded = false;
  public promise: Promise<any>;
  public stopAttribution = false;
  public stopAttributionForExternalAudit = false;
  public vehicleDataSearchTypes = Object.values(VehicleDataSearchTypes);
  public disabledButton = false;

  constructor(
    private preProceSettingsService: PreProcessSettingsService,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public modalService: ModalService,
    public router: Router
  ) { }

  ngOnInit() {
    this.contractId = Number(this.activatedRoute.snapshot.params.contractId);
    this.contractService.getById(this.contractId).then(res => {
      this.contract = res;
    });
    this.preProceSettingsService.getById(this.contractId, true)
      .then(preProcessSettings => {
        if (typeof preProcessSettings !== 'object') {
          throw new Error();
        }
        this.preProcessSettings = preProcessSettings;
        const lotSteps = Object.keys(this.preProcessSettings.lotSteps);
        this.isAudit = lotSteps.find(l => l === 'internalAudit') != null ? true : false;
      }).catch(() => {
        this.preProcessSettings.contractId = this.contractId;
      }).finally(() => {
        this.isPreProcessLoaded = true;
        this.preProcessSettingsCopy = _.cloneDeep(this.preProcessSettings);
        this.stopAttribution = this.preProcessSettings.lotGeneration && this.preProcessSettings.lotGeneration.stopAttribution;
        this.stopAttribution = this.stopAttribution == null ? true : this.stopAttribution;
        this.stopAttributionForExternalAudit = this.preProcessSettings?.audit?.lotGeneration?.stopAttribution;
        this.stopAttributionForExternalAudit = this.stopAttributionForExternalAudit == null ? true : this.stopAttributionForExternalAudit;
      });
  }

  async changeStep(nextStep) {
    if (this.preProcessSettings.id == null) {
      return;
    }
    this.disabledButton = false;

    if (this.step === 'lotAutoGeneration') {
      const stopAttribution = this.preProcessSettings.lotGeneration.stopAttribution;
      this.disabledButton = stopAttribution == null ? false : !this.preProcessSettings.lotGeneration.stopAttribution;
    }
    if (this.step === 'audit' && this.preProcessSettings.audit && this.preProcessSettings.audit.lotGeneration) {
      const stopAttribution = this.preProcessSettings.audit.lotGeneration.stopAttribution;
      this.disabledButton = stopAttribution == null ? false : !this.preProcessSettings.audit.lotGeneration.stopAttribution;
    }
    if (this.preProcessSettingsCopy.audit !== undefined && (this.preProcessSettings.audit.lotGeneration &&
        this.preProcessSettings.audit.lotGeneration.stopAttribution != null)) {
      this.preProcessSettingsCopy.audit.lotGeneration.stopAttribution = this.preProcessSettings.audit.lotGeneration.stopAttribution;
    }
    if (this.preProcessSettingsCopy.lotGeneration !== undefined && (this.preProcessSettings.lotGeneration &&
        this.preProcessSettings.lotGeneration.stopAttribution != null)) {
      this.preProcessSettingsCopy.lotGeneration.stopAttribution = this.preProcessSettings.lotGeneration.stopAttribution;
    }
    this.preProcessSettingsCopy.modifiedAt = this.preProcessSettings.modifiedAt;
    if (!_.isEqual(this.preProcessSettings, this.preProcessSettingsCopy)) {
      await this.modalService.show(new MessageModal('Descartar alterações', 'Deseja cancelar as alterações realizadas?', true))
        .then(() => {
          this.preProcessSettingsCopy = _.cloneDeep(this.preProcessSettings);
          this.step = nextStep;
          return;
        }).catch(() => null);
    } else {
      this.step = nextStep;
    }
  }

  nextStep() {
    if (this.isValid && this.promise == null) {
      const index = this.steps.indexOf(this.step);
      if (this.step !== 'imageEdit') {
        this.step = this.steps[index + 1];
        const lotSteps = (this.preProcessSettingsCopy?.lotSteps && Object.keys(this.preProcessSettingsCopy.lotSteps)) || [];
        if (this.step === 'audit' && !lotSteps.some(s => ['externalAudit', 'internalAudit'].includes(s)) ) {
          this.nextStep();
        }
      } else {
        this.savePreProcess();
      }
    }
  }

  prevStep() {
    if (this.step === 'violationSteps') {
      return;
    }
    if (this.isValid) {
      const index = this.steps.indexOf(this.step);
      this.step = this.steps[index - 1];
    }
    const lotSteps = Object.keys(this.preProcessSettingsCopy.lotSteps);
    if (this.step === 'audit' && !lotSteps.some(s => ['externalAudit', 'internalAudit'].includes(s)) ) {
      this.prevStep();
    }
  }

  setPreProcessValues(preProcessConfig) {
    if (!this.isValid) {
      return;
    }
    this.setValues(this.preProcessSettingsCopy, preProcessConfig);
  }

  setValues(obj, param) {
    const keys = Object.keys(param);
    if (keys.length < 1) {
      return;
    }

    for (const key of keys) {
      if (param[key] == null || param[key] === '') {
        delete obj[key];
      } else if (obj[key] != null && typeof obj[key] === 'object' && !Array.isArray(param[key])) {
        this.setValues(obj[key], param[key]);
      } else {
        obj[key] = param[key];
        if (typeof param[key] === 'object') {
          this.setValues(obj[key], param[key]);
        }
      }
    }
  }

  isStepDone(step) {
    const currentIndex = this.steps.indexOf(this.step);
    const targetIndex = this.steps.indexOf(step);
    if (this.preProcessSettingsCopy && this.preProcessSettingsCopy?.lotSteps) {
      const lotSteps = Object.keys(this.preProcessSettingsCopy.lotSteps);
      this.isAudit = lotSteps.find(l => ['externalAudit', 'internalAudit'].includes(l)) != null ? true : false;
    }
    this.disabledButton = false;
    if (this.step === 'lotAutoGeneration') {
      const stopAttribution = this.preProcessSettings?.lotGeneration?.stopAttribution;
      this.disabledButton = stopAttribution == null ? false : !this.preProcessSettings.lotGeneration.stopAttribution;
    }
    if (this.step === 'audit' && this.preProcessSettings?.audit && this.preProcessSettings?.audit?.lotGeneration) {
      this.disabledButton = !this.preProcessSettings?.audit?.lotGeneration?.stopAttribution;
    }
    if (this.step === 'audit' && this.preProcessSettings?.modifiedAt > this.preProcessSettingsCopy?.modifiedAt) {
      this.preProcessSettingsCopy = _.cloneDeep(this.preProcessSettings);
    }
    this.stopAttribution = this.preProcessSettings?.lotGeneration?.stopAttribution;
    return (currentIndex > targetIndex) || (targetIndex !== currentIndex && this.preProcessSettings && this.preProcessSettings.id != null);
  }

  savePreProcess() {
    if (this.isValid === false || this.promise != null) {
      return;
    }
    if (['externalAudit', 'lotAutoGeneration', 'audit'].includes(this.step)) {
      this.modalService.show(new MessageModal(
        'Confirmar Alteração',
        'Os lotes abertos nas configurações antigas serão fechados automaticamente. Deseja confirmar esta alteração?',
        true
      ))
      .then(() => {
        this.approvedSavePreProcess();
      })
      .catch(() => null);
    } else {
      this.approvedSavePreProcess();
    }
  }

  async approvedSavePreProcess() {
    (this.preProcessSettingsCopy.id as any) = this.preProcessSettingsCopy.contractId;

    if (!this.preProcessSettingsCopy.whitelistReasonId) {
      this.preProcessSettingsCopy.whitelistReasonId = 0;
    }
    if (!this.preProcessSettingsCopy.enabledRestrictionZone) {
      this.preProcessSettingsCopy.enabledRestrictionZone = false;
    }
    if (!this.preProcessSettingsCopy.lotSteps) {
      this.preProcessSettingsCopy.lotSteps = {};
    }
    if (this.preProcessSettingsCopy.violationSteps && !this.preProcessSettingsCopy.violationSteps.lotAttribution) {
      this.preProcessSettingsCopy.violationSteps.lotAttribution = { lotStep: 'export' };
    }
    if (this.preProcessSettingsCopy.allowInputNotFoundVehicle == null) {
      this.preProcessSettingsCopy.allowInputNotFoundVehicle = false;
    }
    if (this.preProcessSettingsCopy.changeBrandModelForClientBrand == null) {
      this.preProcessSettingsCopy.changeBrandModelForClientBrand = false;
    }
    if (this.preProcessSettingsCopy.audit && !this.preProcessSettingsCopy.audit.enabledLotGenerationAudit) {
      this.preProcessSettingsCopy.audit.enabledLotGenerationAudit = false;
    }
    if (this.preProcessSettingsCopy.audit && !this.preProcessSettingsCopy.audit.enabledSamplingPlan) {
      this.preProcessSettingsCopy.audit.enabledSamplingPlan = false;
    }

    if (this.preProcessSettingsCopy.lotGeneration && this.preProcessSettingsCopy.lotGeneration.autoGeneration
      && this.preProcessSettingsCopy.lotGeneration.autoGeneration.length > 0) {
      const autos = this.preProcessSettingsCopy.lotGeneration.autoGeneration;
      for (const index in autos) {
        if (autos[index].lotControlId == null) {
          delete this.preProcessSettingsCopy.lotGeneration.autoGeneration[index].lotControlId;
        }
      }
    }

    const lotSteps = Object.keys(this.preProcessSettingsCopy.lotSteps);
    this.isAudit = lotSteps.find(l => ['externalAudit', 'internalAudit'].includes(l)) != null ? true : false;
    if (this.isAudit) {
      if (this.preProcessSettingsCopy?.audit?.lotGeneration
        && this.preProcessSettingsCopy.audit.lotGeneration.autoGeneration
        && this.preProcessSettingsCopy.audit.lotGeneration.autoGeneration.length > 0) {
        const autos = this.preProcessSettingsCopy.audit.lotGeneration.autoGeneration;
        for (const index in autos) {
          if (autos[index].lotControlId == null) {
            delete this.preProcessSettingsCopy.audit.lotGeneration.autoGeneration[index].lotControlId;
          }
        }
      }
      if (this.preProcessSettingsCopy?.audit?.fieldsToLotRefused) {
        const fieldsRefused = [];
        this.preProcessSettingsCopy.audit.fieldsToLotRefused.map(fields => {
          let violationStatus = fields.violationStatus;
          let reasonIds = fields.reasonIds ;
          let vehicleFields = fields.vehicleFields;

          if (fields.violationStatus != null) {
            violationStatus = fields.violationStatus;
          }
          if (fields.reasonIds && fields.reasonIds.length > 0) {
            reasonIds = fields.reasonIds;
          }
          if (fields.vehicleFields && fields.vehicleFields.length > 0) {
            vehicleFields = fields.vehicleFields;
          }

          fieldsRefused.push({
            violationStatus,
            reasonIds,
            vehicleFields
          });
        });
        this.preProcessSettingsCopy.audit.fieldsToLotRefused = fieldsRefused;
      }
    }
    if (!this.isAudit) {
      delete this.preProcessSettingsCopy.audit;
    }

    this.promise = this.preProceSettingsService.update(this.preProcessSettingsCopy).then(res => {
      this.preProcessSettings = res;
      this.preProcessSettingsCopy = _.cloneDeep(this.preProcessSettings);
      if (this.step === 'lotAutoGeneration') {
        this.stopAttribution = this.preProcessSettings.lotGeneration.stopAttribution;
      }
      this.alertService.show(new AlertItem('PreProcessUpdated', AlertType.success));
    }).catch(err => {
      console.error(err);
      this.alertService.show(new AlertItem('PreProcessUpdateError', AlertType.danger));
    }).finally(() => {
      this.promise = null;
    });
  }

  async redirect() {
    if (this.promise != null) {
      return;
    }
    this.modalService.show(new MessageModal('Sair da configuração', 'Deseja voltar ao menu de pré processamento?', true))
      .catch(() => null)
      .then((e) => {
        if(e?.confirmation){
          this.router.navigate(['pre-process']);
        }
      });
  }

  hasSomeStep(steps = [], target) {
    if (steps.length === 0 || this.preProcessSettings == null || this.preProcessSettings[target] == null) {
      return false;
    } else {
      return steps.some(s => this.preProcessSettings[target][s] != null);
    }
  }

  async confirmation() {
    if (this.step === 'lotSteps') {
      this.modalService.show(new MessageModal('Tem certeza que deseja confirmar a alteração?',
    'Caso uma nova etapa de processamento tenha sido adicionada/removida, a mesma será aplicada à partir dos lotes que estiverem abertos.',
      true))
      .catch(() => null)
      .then((e) => {
        if(e?.confirmation){
          this.savePreProcess();
        }
      });
    } else {
      this.savePreProcess();
    }
  }
}
