import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PreProcessSettings, Reason, VehicleDataSearchTypes, VehicleRequiredFields } from 'src/app/core/models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ReasonService } from 'src/app/core/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-config-audit',
  templateUrl: './config-audit.component.html',
  styleUrls: ['./config-audit.component.sass']
})
export class ConfigAuditComponent implements OnInit, OnDestroy {
  @Input() preProcessSettings: PreProcessSettings = new PreProcessSettings();
  @Output() nextStepCommand = new EventEmitter();
  @Output() isValid = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  public form: FormGroup;
  public vehicleRequiredFields = Object.values(VehicleRequiredFields);
  public vehicleRequiredFieldsI18n: Array<{ id: string; value: string }> = [];
  public isAllRequiredFieldsSelected = false;
  public qualityLevel = 2.5;
  public isQualityLevel = 0;
  public reasons: Array<Reason>;
  public reasonsById: { [id: string]: Reason } = {};
  public reasonIds: Array<number> = [];
  public fieldsToLotRefused: Array<{
    id: string;
    violationStatus: string;
    reasonsEnabled: boolean;
    reasonIds: Array<number>;
    vehicleFields: Array<string>;
    boxOpen: boolean;
    vehicleFieldsEnabled: boolean;
  }> = [];

  constructor(
    private formBuilder: FormBuilder,
    private reasonService: ReasonService,
    private route: ActivatedRoute
  ) { }
  ngOnDestroy(): void { }


  ngOnInit() {
    this.createForm();
    this.isValid.emit(this.form.valid);
    this.getReasons();
    if (this.getValue(this.preProcessSettings, 'audit.fieldsToLotRefused')) {
      this.fieldsToLotRefused = this.getValue(this.preProcessSettings, 'audit.fieldsToLotRefused');
      this.fieldsToLotRefused.map(item => {
        item.id = this.uid();
        if (item.reasonIds.length > 0) {
          item.reasonsEnabled = true;
        } else {
          item.reasonsEnabled = false;
        }
        if (item.vehicleFields.length > 0) {
          item.vehicleFieldsEnabled = true;
        } else {
          item.vehicleFieldsEnabled = false;
        }
      });
    }
  }

  uid() {
    const a = new Uint32Array(3);
    window.crypto.getRandomValues(a);
    return (performance.now().toString(36)+Array.from(a).map(b => b.toString(36)).join('')).replace(/\./g,'');
   };

  async getReasons() {
    await this.reasonService.getAll({
      contractId: this.route.snapshot.params.contractId,
      order: 'code'
    }).then(res => {
      this.reasons = res.sort((a, b): any => Number(a.code) - Number(b.code));
      res.map(item => {
        this.reasonsById[item.id] = item;
        this.reasonIds.push(Number(item.id));
      });
    });
  }

  createForm() {
    const requiredFields = this.getValue(this.preProcessSettings, 'audit.requiredFieldsAudit');
    this.isQualityLevel = this.getValue(this.preProcessSettings, 'audit.acceptableQualityLevel') || this.qualityLevel;

    this.form = this.formBuilder.group({
      allowAuditInvalidateLot: [this.getValue(this.preProcessSettings, 'audit.allowAuditInvalidateLot')],
      acceptableQualityLevel: [this.getValue(this.preProcessSettings, 'audit.acceptableQualityLevel') || this.qualityLevel],
      violationStatus: [],
      reasonIds: [],
      vehicleFields: []
    });
    this.form.addControl('allRequiredFields', new FormControl('', []));
    for (const field of this.vehicleRequiredFields) {
      this.form.addControl(field, new FormControl(requiredFields.includes(field), []));
      this.form.get(field).valueChanges.subscribe(() => {
        this.isAllRequiredFieldsSelected = this.vehicleRequiredFields.every(f => this.form.get(f).value);
        this.form.get('allRequiredFields').setValue(this.isAllRequiredFieldsSelected);
      });
    }

    if (requiredFields.length > 0) {
      this.form.get('allRequiredFields').setValue(this.vehicleRequiredFields.length === requiredFields.length);
    }

    this.form.get('allRequiredFields').valueChanges.subscribe(value => {
      if (this.isAllRequiredFieldsSelected === value) {
        return;
      }
      for (const field of this.vehicleRequiredFields) {
        this.form.controls[field].setValue(value);
      }
    });
    this.form.valueChanges.subscribe(() => {
      this.isValid.emit(this.form.valid);
      if (this.form.valid === true) {
        this.outputValue.emit(this.getOutputValue());
      }
    });
  }

  getOutputValue() {
    const fieldsRefused = this.fieldsToLotRefused;
    const audit = {
      allowAuditInvalidateLot: this.form.get('allowAuditInvalidateLot').value,
      acceptableQualityLevel: Number(this.form.get('acceptableQualityLevel').value),
      fieldsToLotRefused: fieldsRefused
    };
    const config = {
      audit
    };
    return config;
  }

  getValue(model, attr, value = '') {
    let arr = [];
    if (typeof attr === 'string') {
      arr = attr.split('.');
    } else {
      arr = attr;
    }
    if (arr.length > 0) {
      if (model[arr[0]] == null) {
        return value;
      } else {
        return this.getValue(model[arr[0]], arr.slice(1), value);
      }
    } else {
      return model;
    }
  }

  addFieldsToLotRefused() {
    this.fieldsToLotRefused.push({
      id: this.uid(),
      violationStatus: '',
      reasonsEnabled: false,
      reasonIds: [],
      vehicleFields: [],
      boxOpen: false,
      vehicleFieldsEnabled: false
    });
    this.isValid.emit(false);
  }

  removeFieldsToLotRefused(setting: any) {
    this.fieldsToLotRefused = this.fieldsToLotRefused.filter(a => a.id !== setting?.id);
    this.isValid.emit(true);
    this.outputValue.emit(this.getOutputValue());
  }

  setFieldsToLotRefused(id: any, value: any) {
    const data = this.fieldsToLotRefused.filter(a => a.id === id).shift();
    if(value.violationStatus === '') {
      data.violationStatus = 'valid';
      this.isValid.emit(true);
      this.outputValue.emit(this.getOutputValue());
    } else if (value.violationStatus === 'valid') {
      data.violationStatus = '';
      this.isValid.emit(false);
    }
    if (value.reasonId && value.reasonId !== 'all' && !data.reasonIds.includes(value.reasonId)) {
      data.reasonIds.push(value.reasonId);
      this.isValid.emit(true);
      this.outputValue.emit(this.getOutputValue());
    } else if (value.reasonId && data.reasonIds.includes(value.reasonId)) {
      data.reasonIds = data.reasonIds.filter(a => a !== value.reasonId);
      if (data.reasonIds.length === 0) {
        this.isValid.emit(false);
      }
    }
    if (value.reasonId === '!all') {
      data.reasonIds = [];
      this.isValid.emit(false);
    }
    if (value.reasonId === 'all' && data.reasonIds !== this.reasonIds) {
      data.reasonIds = this.reasonIds;
      this.isValid.emit(true);
      this.outputValue.emit(this.getOutputValue());
    } else if (value.reasonId === 'all' && data.reasonIds === this.reasonIds) {
      data.reasonIds = [];
      this.isValid.emit(false);
    }
    if (value.vehicleFields && value.vehicleFields !== 'all' && !data.vehicleFields.includes(value.vehicleFields)) {
      data.vehicleFields.push(value.vehicleFields);
      this.isValid.emit(true);
      this.outputValue.emit(this.getOutputValue());
    } else if (value.vehicleFields && data.vehicleFields.includes(value.vehicleFields)) {
      data.vehicleFields = data.vehicleFields.filter(a => a !== value.vehicleFields);
      if (data.vehicleFields.length === 0) {
        this.isValid.emit(false);
      }
    }
    if (value.vehicleFields === '!all') {
      data.vehicleFields = [];
      this.isValid.emit(false);
    }
    if (value.vehicleFields === 'all' && data.vehicleFields !== this.vehicleRequiredFields) {
      data.vehicleFields = this.vehicleRequiredFields;
      this.isValid.emit(true);
      this.outputValue.emit(this.getOutputValue());
    } else if (value.vehicleFields === 'all' && data.vehicleFields === this.vehicleRequiredFields) {
      data.vehicleFields = [];
      this.isValid.emit(false);
    }
    if (value.reasonsEnabled && !data.reasonsEnabled) {
      data.reasonsEnabled = true;
    } else if (value.reasonsEnabled && data.reasonsEnabled) {
      data.reasonsEnabled = false;
      data.reasonIds = [];
      if (data.violationStatus !== 'valid') {
        this.isValid.emit(false);
      }
    }
    if (data.vehicleFields.length === this.vehicleRequiredFields.length) {
      data.vehicleFieldsEnabled = true;
    }
    if (value.vehicleFieldsEnabled && !data.vehicleFieldsEnabled) {
      data.vehicleFieldsEnabled = true;
    } else if (value.vehicleFieldsEnabled && data.vehicleFieldsEnabled) {
      data.vehicleFieldsEnabled = false;
      data.vehicleFields = [];
      if (data.violationStatus !== 'valid') {
        this.isValid.emit(false);
      }
    }
  }

  selectBox(target: any) {
    target.boxOpen = target.boxOpen ? false : true;
  }
}
