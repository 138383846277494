import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { AlertService, RegionService, RegulationService, ViolationReportService } from 'src/app/core/services';
import { Regulation, AlertItem, AlertType, Region } from 'src/app/core/models';
import { ViolationContractCounterService } from 'src/app/core/services/violationContractCounter.service';
import { ViolationReportCounterService } from 'src/app/core/services/violationReportCounter.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-violations-report-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ViolationsReportListComponent implements OnInit, OnDestroy, AfterViewChecked {
  public loading: boolean;
  public regulations: Regulation[] = [];
  public contractsViolations: any[] = [];
  public contractsList: any[] = [];
  public dataViolationsSub: Subscription;
  public regulationsByCode: any = {};
  public items: any[] = [];
  public searchValue = '';
  public allCollapse: boolean;
  public loadingRecount: boolean;
  public violationConractCounter: any;
  public regulationById: { [params: string]: Regulation } = {};
  public region: Region;
  private searchChanged: Subject<string> = new Subject<string>();

  constructor(
    public route: ActivatedRoute,
    private violationReportService: ViolationReportService,
    private violationConractCounterService: ViolationContractCounterService,
    private changeDetectionRef: ChangeDetectorRef,
    private violationReportCounterService: ViolationReportCounterService,
    private alertService: AlertService,
    private regulationService: RegulationService,
    private regionService: RegionService
  ) {
    this.searchChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  async ngOnInit() {
    await this.getRegion();
    await this.getAllRegulations();
    this.dataViolationsSub = this.violationReportService.getDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe(async (data: any) => {
        await this.violationConractCounterService.getAll({ limit: '10' })
          .then(violationContracts => {
            if (violationContracts.length > 0) {
              this.contractsViolations = [];
              for (const violationContract of data.contracts) {
                const objContract: any = {};
                const contract = data.contracts.find(c => c.contractId === parseInt(violationContract.contractId, 10));
                if (contract !== undefined) {
                  objContract.id = (contract && contract.contractId);
                  objContract.code = (contract && contract.contractCode);
                  objContract.name = (contract && contract.contractName) || violationContract.contractId;
                  objContract.collapse = false;
                  objContract.total = 0;
                  objContract.regulations = [];
                  const getFields = violationContracts.filter(item => item.id === String(contract.contractId)).shift();
                  const fields = getFields === undefined ? [] : Object.keys(getFields);

                  for (const field of fields) {
                    if (parseInt(field, 10) > 0) {
                      const regulation = data.regulations.find(r => r.id === parseInt(field, 10));
                      if (regulation && regulation.id != null) {
                        this.regulationsByCode[regulation.id] = regulation;
                        const objRegulation: any = {};
                        objRegulation.id = regulation.id;
                        objRegulation.code = regulation.code;
                        objRegulation.qtd = violationContracts.filter((a) => a.id === String(contract.contractId)).shift()[field];
                        objContract.total += objRegulation.qtd;
                        if (objRegulation.qtd > 0) {
                          objContract.regulations.push(objRegulation);
                        }
                      }
                    }
                  }

                  objContract.regulations.sort((a, b) => (a.qtd > b.qtd) ? -1 : 1);
                  this.contractsViolations.push(objContract);
                  this.contractsViolations.sort((a, b) => a.id - b.id);
                  this.contractsList = this.contractsViolations;
                  this.loading = true;
                }
              }
              this.violationConractCounter = violationContracts;
              this.violationConractCounter.map(item => {
                let total = 0;
                const values = Object.values(item);
                values.map((num: number) => {
                  if (num !== Number( item.contractId) || num !== Number(item.id)) {
                    total = total + num;
                  }
                  item.total = total;
                });
              });
            }
          });
      });
  }

  async getAllRegulations() {
    await this.regulationService.getAll({ regionId: this.region?.id }).then(res => {
      res.map(regulation => {
        this.regulationsByCode[regulation.code] = regulation;
      });
    });
  }

  async getRegion() {
    await this.regionService.getAll({ name: 'Brasil'}).then(res => {
      this.region = res.shift();
    });
  }

  getTotalViolations(contractCode) {
    return this.violationConractCounter.
      filter(contract => contract.contractId === Number(contractCode) ||
        contract.id === Number(contractCode)).shift()?.total;
  }

  getTotalRegulations(contractCode) {
    const data = this.violationConractCounter.filter(contract => contract.contractId === Number(contractCode)).shift();
    let totalRegulations = [];
    if (data) {
      totalRegulations = Object.keys(data).map((key) => {
        if (key !== 'id' && key !== 'id' && key !== 'total') {
          return [key, data[key]];
        }
      });
    }
    return totalRegulations;
  }

  ngAfterViewChecked(): void {
    this.changeDetectionRef.detectChanges();
  }

  toggleCollapse(item) {
    item.collapse = !item.collapse;
  }

  toggleAllCollapse() {
    if (!this.allCollapse) {
      this.allCollapse = true;
      this.contractsViolations.map(item => item.collapse = true);
    } else {
      this.allCollapse = false;
      this.contractsViolations.map(item => item.collapse = false);
    }
  }

  setItems(list) {
    this.items = list;
  }

  getTotal() {
    return this.contractsViolations.reduce((acc, curr) => acc + curr.total, 0);
  }

  searchKey(text: string) {
    this.searchChanged.next(text);
  }

  handleSearch() {
    const search = this.searchValue;
    if (search != null && search !== '') {
      this.contractsViolations = this.contractsList.filter(
        item => item.name.toLowerCase().includes(search.toLowerCase()) ||
        String(item.code).includes(search)
      );
    } else {
      this.contractsViolations = this.contractsList;
    }
  }

  ngOnDestroy() {
    if (this.dataViolationsSub) {
      this.dataViolationsSub.unsubscribe();
    }
  }
}
