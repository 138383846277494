<div class="my-profile mb-3">
  <div class="container">
    <div class="page-title">
      <p class="page-title__link-back" i18n><a routerLink="/users">Usuários</a></p>
      <h2 i18n>Meu perfil</h2>
    </div>

    <div class="row">
      <div class="col-3 offset-md-1">
        <app-avatar [user]="user"></app-avatar>
        <h3 class="mt-3 mb-4">{{ user.name }}</h3>

        <div class="mt-4" *ngIf="user?.prettyNames?.length; else loadingInline">
          <h6><strong>Grupos</strong></h6>
          <div *ngFor="let nameGroup of user.prettyNames" class="badge badge-fade-secondary mr-1">
            {{ nameGroup }}
          </div>
        </div>

        <ng-template #loadingInline>
          <div class="loading-inline">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
        </ng-template>

        <div class="mt-5">
          <button class="btn btn-fade-info btn-sm"
            (click)="openChangePassword()"
            i18n="@@ProfileAlterPassword">Alterar senha de acesso</button>
        </div>
      </div>

      <div class="col-7">
        <div class="card">
          <ng-container *ngIf="loading; else loadingBlock">
            <form [formGroup]="userEditForm" (submit)="submit()">
              <div class="card-body p-5 pb-2">
                <div class="row d-flex align-items-center">
                  <div class="col-1 mt-2"><i class="material-icons icon-lg text-secondary">account_box</i></div>
                  <div class="col-11">
                    <app-input i18n-label="@@ProfileLabelName" type="text" label="Nome" formControlName="name"
                    inputRequired=true></app-input>
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col-1 mt-2"><i class="material-icons icon-lg text-secondary">alternate_email</i></div>
                  <div class="col-11">
                    <app-input i18n-label="@@ProfileLabelEmail" type="text" label="Email" formControlName="email"
                    inputDisabled="true"></app-input>
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col-1 mt-2"><i class="material-icons icon-lg text-secondary">business</i></div>
                  <div class="col-11">
                    <app-input i18n-label="@@ProfileLabelEmpresa" type="text" label="Empresa" formControlName="companyId"
                    inputDisabled="true"></app-input>
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col-1 mt-2"><i class="material-icons icon-lg text-secondary">assignment_ind</i></div>
                  <div class="col-11">
                    <app-input i18n-label="@@ProfileLabelOffice" type="text" formControlName="role" label="Cargo">
                    </app-input>
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col-1 mt-2"><i class="material-icons icon-lg text-secondary">assignment_ind</i></div>
                  <div class="col-11">
                    <app-input
                      i18n-label="@@CPF"
                      formControlName="cpf"
                      format="ddd.ddd.ddd-dd"
                      label="CPF">
                    </app-input>
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col-1 mt-2"><i class="material-icons icon-lg text-secondary">folder_shared</i></div>
                  <div class="col-11">
                    <app-input i18n-label="@@ProfileLabelRegistryNumber" formControlName="registryNumber"
                    label="N° da Matrícula"></app-input>
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex">
                <button type="button" (click)="cancel()" class="btn btn-outline-secondary mr-3 ml-auto">
                  <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
                </button>

                <button type="submit" class="btn btn-primary" [ngClass]="{disabled: !isValid}">
                  <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
                </button>
              </div>
            </form>
          </ng-container>

          <ng-template #loadingBlock>
            <div class="loading-page">
              <div class="loading-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
