<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Editar Imagem</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <app-img-edit [files]="files" [currentType]="type" [isModal]="true"
        (outputValues)="setImageState($event)"
        [operation]="operation"
        [isBlurEnabled]="isBlurEnabled"
        [isClipEnabled]="isClipEnabled"
        [imagesSettings]="imagesSettings"
        [modifedBuffers]="modifedBuffers"
        >
      </app-img-edit>
    </div>
    <div class="modal-footer">
      <button i18n (click)="onCancel()" class="btn btn-outline-secondary">Cancelar</button>
      <button i18n (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">Confirmar</button>
    </div>
  </div>
</div>
