import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService } from 'src/app/core/services';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-lot-extraData',
  templateUrl: './lot-extraData.component.html',
  styleUrls: ['./lot-extraData.component.sass'],
  animations: [
    trigger('openPanel', [
      state('isOpen', style({
        height: 300,
        opacity: 1
      })),
      state('closed', style({
        height: 0,
        opacity: 0,
        paddingTop: 0,
        paddingBottom: 0
      })),
      transition('closed => isOpen', animate('500ms ease-out')),
      transition('isOpen => closed', animate('500ms ease-out'))
    ])
  ]
})
export class LotExtraDataComponent extends BaseModal implements OnInit {
  @Input() initialState;
  isValid: boolean;
  lastState: any;
  public loading: boolean;

  constructor(
    modalService: ModalService
  ) {
    super(modalService);
   }

  async ngOnInit() {
    this.loading = true;
    this.loading = false;
  }

  extraDataToString(data) {
    return JSON.stringify(data);
  }

  async onAllSubmited(): Promise<any> {
  }
}
