import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { hasPermission, getModuleByPermission } from 'src/app/core/utils/permission';
import { StorageService, ContractGlobalService, ContractService, PreProcessSettingsService, StorageKey } from 'src/app/core/services';
import { Steps, PreProcessSettings } from 'src/app/core/models';
import * as _ from 'lodash';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit, OnDestroy {
  @Input() appPermission: string | Array<string>;
  @Input() appPermissionHide = true;
  public stepsPreProcessSettings = Object.keys(Steps);
  public currentContracts = [];
  public preProcessSettingsCache = null;
  public preProcessById: { [id: string]: PreProcessSettings} = {};
  public entries: { appPermission: string | Array<string>; appPermissionHide: boolean;
    appPermissionContracts: Array<string>;};
  public responseCache: boolean = null;
  public reloadTimeout = 500;

    get appPermissionContracts() {
      return this._appPermissionContracts;
    }
    @Input() set appPermissionContracts(value) {
      this._appPermissionContracts = value;
      this.setVisibility();
    }

  private contractGlobal = null;
  private contractsSubscription;
  private _appPermissionContracts = null;
  private allowed = null;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private storageService: StorageService,
    private contractGlobalService: ContractGlobalService,
    private contractService: ContractService,
    private preProcessSettingsService: PreProcessSettingsService) { }

  ngOnInit() {
    this.contractGlobal = this.contractGlobalService.contracts;
    const preProcessAll = this.preProcessSettingsService.getCacheAll();
    this.currentContracts = this.contractService.getCacheAll();
    if (preProcessAll == null) {
      this.loadPreProcessSettings();
    } else {
      preProcessAll.forEach(preProcess => this.preProcessById[preProcess.contractId] = preProcess);
    }
    this.setVisibility();
    this.contractsSubscription = this.contractGlobalService.contractEvent.
      subscribe(value => {
        this.contractGlobal = value;
        this.setVisibility();
      });
  }

  setVisibility(force: boolean = false) {
    const contracts = this.getContractsGlobal();
    const newEntries = {
      appPermissionContracts: this.appPermissionContracts,
      appPermission: this.appPermission,
      appPermissionHide: this.appPermissionHide,
      contracts
    };
    if (force === false && this.entries != null && _.isEqual(this.entries, newEntries)) {
      this.showOrHide();
      return;
    }
    this.entries = newEntries;
    let permissions = this.appPermission;
    if (!Array.isArray(this.appPermission)) {
      permissions = [this.appPermission];
    }
    for (const permission of permissions) {

      if (hasPermission(permission, this.storageService, contracts)) {
        const isPermissionByContract = this.verifyPermissionProcess(permission);
        if (isPermissionByContract === true) {
          this.responseCache = this.appPermissionHide && isPermissionByContract;
          this.showOrHide();
          return;
        }
      }
    }
    this.responseCache = !this.appPermissionHide;
    this.showOrHide();
  }

  showOrHide() {
    if (this.responseCache === true) {
      this.show();
    } else {
      this.hide();
    }
  }

  getContractsGlobal() {
    let contractIds = null;
    if (this._appPermissionContracts != null) {
      contractIds = this._appPermissionContracts;
    } else if (this.contractGlobal != null) {
      contractIds = this.contractGlobal;
    }
    if (contractIds != null && !Array.isArray(contractIds)) {
      contractIds = [contractIds];
    }
    return contractIds;
  }

  show() {
    if (!this.allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    this.allowed = true;
  }

  hide() {
    if (this.allowed !== false) {
      this.viewContainer.clear();
    }
    this.allowed = false;
  }

  ngOnDestroy() {
    this.contractsSubscription.unsubscribe();
  }

  verifyPermissionProcess(permission) {
    const user = this.storageService.get(StorageKey.currentUser);
    if (user.superUser === true) {
      return true;
    }

    if (permission == null) {
      return true;
    }

    const moduleByPermission = getModuleByPermission(permission);
    if (moduleByPermission == null) {
      return true;
    }
    this.contractGlobal = this.getContractsGlobal();
    const contractIds = this.contractGlobal || (this.currentContracts && this.currentContracts.map(c => c.id));
    if (contractIds != null && contractIds.length > 0) {
      if (this.preProcessById != null && Object.keys(this.preProcessById).length > 0) {
        for (const id of contractIds) {
          const preProcessSetting = this.preProcessById[id];
          if (moduleByPermission.module === 'viewSerial') {
            if (permission === 'ViewSerialNumberConfig') {
              if (preProcessSetting != null &&
                preProcessSetting.lotSteps != null &&
                Object.keys(preProcessSetting.lotSteps).includes(moduleByPermission.step)
              ) {
                return true;
              }
            }
          } else if (moduleByPermission.module === 'preProcess') {
            if (preProcessSetting != null && preProcessSetting.violationSteps != null) {
              if (moduleByPermission.step.startsWith('verify') &&
                (Object.keys(preProcessSetting.violationSteps).includes('verifyValid') ||
                  Object.keys(preProcessSetting.violationSteps).includes('verifyInvalid'))) {
                return true;
              } else if (Object.keys(preProcessSetting.violationSteps).includes(moduleByPermission.step)) {
                return true;
              } else if (permission === 'ViewViolation' && moduleByPermission.step === 'violations') {
                return true;
              } else if (Object.keys(preProcessSetting.lotSteps).includes(moduleByPermission.step)) {
                return true;
              }
            }
          }
        }
      }
    }
    const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
    if (currentPermissions[0].actionIds.includes(permission)) {
      return true
    }
    return false;
  }

  retryLoadPreProcessSettings() {
    const reloadTimeout = this.reloadTimeout * 2;
    this.reloadTimeout = reloadTimeout > 10000 ? 10000 : reloadTimeout;
    setTimeout(this.loadPreProcessSettings.bind(this), this.reloadTimeout);
  }

  async loadPreProcessSettings() {
    try {
      this.currentContracts = (await this.contractService.getAll());
      const promises = this.currentContracts.map(contract => this.preProcessSettingsService.getById(contract.id)
        .then(preProcess => this.preProcessById[preProcess.contractId] = preProcess)
        .catch(console.error));

      await Promise.all(promises).then(() => {
        this.setVisibility();
      });
    } catch (err) {
      if (err.status !== 404 && err.status !== 403) {
        this.retryLoadPreProcessSettings();
        return;
      }
    }
    this.reloadTimeout = 500;
    this.setVisibility(true);
  }
}
