import { BaseApiModel } from './BaseApiModel';

export class Vehicle extends BaseApiModel {
  public contractId: string;
  public plate: string;
  public brandModelId: string;
  public colorId: string;
  public classificationId: string;
  public categoryId: string;
  public typeId: string;
  public cityId: string;
  public year: number;
  public modelYear: number;
  public chassis: string;
  public renavam: string;
  public createdAt: Date;
  public modifiedAt: Date;
  public deletedAt: Date;
}
