<app-enum-i18n [input]="statusLot" (output)="statusLotI18n=$event"></app-enum-i18n>
<p>lot-audit-details works!</p>
<div class="modal-dialog modal-full modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n><strong>Detalhes do Lote: {{ lot?.name }}</strong></h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body pt-0">
      <div class="loading-page" *ngIf="loading">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <div class="mt-3">
        <div class="row">
          <div class="col-md-12">
            <div class="wrapper">
              <div class="content-data">
                <div class="item item-content">
                  <div class="row pb-1em">
                    <div class="col">
                      <p i18n>Status</p>
                    </div>
                    <div class="col">
                      <p i18n>Período das Infrações</p>
                    </div>
                    <div class="col">
                      <p i18n>Início da Auditoria em</p>
                    </div>
                    <div class="col">
                      <p i18n>Finalizou Auditoria em</p>
                    </div>
                    <div class="col">
                      <p i18n>Auditor Responsável</p>
                    </div>
                  </div>
                </div>
                <div *ngIf="!lot.audits || lot?.audits?.length === 0; else hasAudit">
                  <div class="row data">
                    <div class="col">
                      <p i18n>{{ getStatusLot(lot.status) }}</p>
                    </div>
                    <div class="col">
                      <p i18n>
                        {{ lot?.oldestViolationAt | moment: 'L' }}
                        {{ '- '}}
                        {{ lot?.newestViolationAt | moment: 'L' }}
                      </p>
                    </div>
                    <div class="col">
                      <p i18n> {{ '-' }} </p>
                    </div>
                    <div class="col">
                      <p i18n>
                        {{ '-' }}
                      </p>
                    </div>
                    <div class="col">
                      <p i18n> {{ '-' }} </p>
                    </div>
                  </div>
                </div>
                <ng-template #hasAudit>
                  <div class="row data" *ngFor="let audit of lot?.audits">
                    <div class="col">
                      <p i18n>{{ getStatusLot(lot.status) }}</p>
                    </div>
                    <div class="col">
                      <p i18n>
                        {{ lot?.oldestViolationAt | moment: 'L' }}
                        {{ '- '}}
                        {{ lot?.newestViolationAt | moment: 'L' }}
                      </p>
                    </div>
                    <div class="col">
                      <p i18n>
                        {{ audit?.startsAt | moment: 'L' }}
                        {{ audit?.startsAt | moment: 'LTS' }}
                      </p>
                    </div>
                    <div class="col">
                      <p i18n>
                        {{ audit?.endsAt | moment: 'L' }}
                        {{ audit?.endsAt | moment: 'LTS' }}
                      </p>
                    </div>
                    <div class="col">
                      <p i18n>{{ audit?.author }}</p>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
