import { BaseApiModel } from './BaseApiModel';
import { Timezone } from './Timezone';

export class Spot extends BaseApiModel {
  public code: string;
  public contractId: string;
  public laneIds: Array<string>;
  public equipmentIds: Array<string>;
  public cityId: string;
  public cityName: string;
  public stateId: string;
  public regionId: string;
  public address: {
    street: string;
    // eslint-disable-next-line id-blacklist
    number: number;
    zipCode: string;
    landmarks: string;
    isKm: boolean;
  };
  public coordinates: {
    latitude: string;
    longitude: string;
  };
  public description: string;
  public roadType: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  public extraData: object;
  public modifiedAt: Date;
  public createdAt: Date;
  public timezone: Timezone;
  public maxHeight: number;
  public clientCode: string;

  get prettyName() {
    return `${this.code} - ${(this.description)}`;
  }
}

// eslint-disable-next-line no-shadow
export enum RoadType {
  local = 'local',
  collector = 'collector',
  arterial = 'arterial',
  expressway = 'expressway',
  highway = 'highway'
}
