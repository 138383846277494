<app-enum-i18n [input]="items" (output)="setItem($event)"></app-enum-i18n>
<div class="filter-dropdown__content">
  <div>
    <p class="mb-0" i18n>Exibir Colunas</p>
    <p class="mb-0 text-secondary"><small i18n>Selecione os itens que deseja exibir:</small></p>
  </div>
  <div class="items">
    <form [formGroup]=form>
      <div class="my-2" *ngFor="let item of i18nItems; index as i">
        <mat-checkbox i18n color="primary" [formControlName]="item.id">{{item.value}}</mat-checkbox>
      </div>
    </form>
  </div>
</div>
