import { BaseApiModel } from './BaseApiModel';

export class StorageLocation extends BaseApiModel {
    public name: string;
    public key: string;
    public plateRegex: string;
    public typeReportId: number;
    public contractId: number;
    public active: boolean;
    public statusStorageId: number;
    public createdAt: Date;
    public deletedAt: Date;
    public query: Array<string>;
}
