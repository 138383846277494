
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CsvDataServiceService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  public translate: any = {
    regulation: 'enquadramento',
    regulations: 'enquadramentos',
    spotId: 'identificador_ponto',
    laneId: 'identificador_faixa',
    laneCode: 'codigo_faixa',
    value: 'contador',
    date: 'data',
    violationDate: 'data_cometimento',
    type: 'tipo',
    regulationId: 'identificador_enquadramento',
    inProcess: 'em_progresso',
    spotCode: 'codigo_ponto',
    valid: 'valido',
    invalid: 'invalido',
    quarantine: 'quarentena',
    plate: 'placa',
    serialNumber: 'serie',
    step: 'etapa',
    situation: 'situacao',
    createdAt: 'data_recebimento',
    spotLane: 'ponto_faixa',
    description: 'descricao',
    reasonCode: 'codigo_invalidacao',
    reasonName: 'descricao_invalidacao'


  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  public exportToCsv(filename: string, rows: object[], isExportCSV = false) {
    if (!rows || !rows.length || !isExportCSV) {
      return;
    }
    const rowsTranslated = [];
    for (const data of rows) {
      const body = data;
      const bodyTranslate = {};
      for (const field of Object.keys(body)) {
        bodyTranslate[this.translate[field]] = body[field];
      }
      rowsTranslated.push(bodyTranslate);
    }
    const separator = ',';
    const keys = Object.keys(rowsTranslated[0]);
    const csvContent = keys.join(separator) +
      '\n' +
      rowsTranslated.map(row => keys.map(k => {
        const rowTranslate = this.translate[row[k]] || row[k];
        let cell = row[k] === null || row[k] === undefined ? '' : rowTranslate;

        cell = cell instanceof Date
        ? cell.toLocaleString()
        : cell.toString().replace(/"/g, '""');
        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator)).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
