import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards';
import { LoginComponent } from './pages/login/login.component';
import { LoginLayoutComponent } from './core/layouts/login/login-layout/login-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { VerifyUserComponent } from './pages/login/verify-user/verify-user.component';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { InvalidTokenComponent } from './pages/login/invalid-token/invalid-token.component';
import { DashboardComponent } from './core/layouts/dashboard/dashboard.component';
import { AvailableContractsComponent } from './pages/pre-process/available-contracts/available-contracts.component';
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: ':module/:step/available-contracts',
        component: AvailableContractsComponent
      },
      {
        path: ':module/:submodule/:step/available-contracts',
        component: AvailableContractsComponent
      }
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'contracts',
        loadChildren: () => import('./pages/contracts/contracts.module').then(m => m.ContractsModule)
      },
      {
        path: 'lanes',
        loadChildren: () => import('./pages/lanes/lanes.module').then(m => m.LanesModule)
      },
      {
        path: 'spots',
        loadChildren: () => import('./pages/spots/spots.module').then(m => m.SpotsModule)
      },
      {
        path: 'user-groups',
        loadChildren: () => import('./pages/user-groups/user-groups.module').then(m => m.UserGroupsModule)
      },
      {
        path: 'equipments',
        loadChildren: () => import('./pages/equipments/equipments.module').then(m => m.EquipmentsModule)
      },
      {
        path: 'import-file',
        loadChildren: () => import('./pages/file-imports/file-imports.module').then(m => m.FileImportsModule)
      },
      {
        path: 'contracts/exempt-periods',
        loadChildren: () => import('./pages/exempt-periods/exempt-periods.module').then(m => m.ExemptPeriodsModule)
      },
      {
        path: 'pre-process',
        loadChildren: () => import('./pages/pre-process/pre-process.module').then(m => m.PreProcessModule)
      },
      {
        path: 'calibrations',
        loadChildren: () => import('./pages/calibrations/calibrations.module').then(m => m.CalibrationsModule)
      },
      {
        path: 'violations',
        loadChildren: () => import('./pages/violations/violations.module').then(m => m.ViolationsModule)
      },
      {
        path: 'activities',
        loadChildren: () => import('./pages/activities/activities.module').then(m => m.ActivitiesModule)
      },
      {
        path: 'vehicleWhiteLists',
        loadChildren: () => import('./pages/vehicle-white-list/vehicle-white-list.module').then(m => m.VehicleWhiteListModule)
      },
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'verification',
        component: VerifyUserComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'invalidToken',
        component: InvalidTokenComponent
      },
      {
        path: 'setPassword',
        component: VerifyUserComponent
      },
    ]
  },
  {
    path: '**',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        component: PageNotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
