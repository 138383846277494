import { Pipe, PipeTransform } from '@angular/core';
/*
 * Uppercase the first letter
*/
@Pipe({ name: 'ucFirst' })
export class UcFirstPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value[0].toUpperCase() + value.substring(1).toLowerCase();
    }

    return value;
  }
}
