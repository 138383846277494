import { Component, OnInit } from '@angular/core';
import { Quarantine } from 'src/app/core/models/Quarantine';
import { ActivatedRoute, Router } from '@angular/router';
import { QuarantineService, SpotService, LaneService, ContractService, ModalService, AlertService } from 'src/app/core/services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Spot, Lane, Contract, AlertItem, AlertType } from 'src/app/core/models';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { QuarentineRecountService } from 'src/app/core/services/quarentineRecount.service';

@Component({
  selector: 'app-quarantine-list',
  templateUrl: './quarantine-list.component.html',
  styleUrls: ['./quarantine-list.component.sass']
})
export class QuarantineListComponent implements OnInit {

  public quarantines: Array<Quarantine> = [];
  public columns = [
    'contract',
    'spot',
    'lane',
    'wrongRegulation',
    'calibrationFailure',
    'laneFailure',
    'equipmentFailure',
    'speedFailure',
    'waitingFiles',
    'exemptPeriod',
    'whiteList',
    'violationLimit',
    'missingTarge',
    'doubleViolation',
    'total'
  ];
  public columnsToShow = [];
  public params: any = {};
  public layer = '';
  public quarantineDetail: Quarantine;
  public contractId = '';
  public quarantinesWithDetail = [
    'wrongRegulation',
    'calibrationFailure',
    'laneFailure',
    'invalidSpeed',
    'equipmentFailure',
    'speedFailure'
  ];
  public searchForm: FormGroup;
  public lanes: Lane[];
  public spots: Spot[];
  public modelChanged: Subject<string> = new Subject<string>();
  public spotById: { [params: string]: Spot } = {};
  public contract: Contract;
  public loadingRecount: boolean;

  constructor(
    public quarantineService: QuarantineService,
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private spotService: SpotService,
    private laneService: LaneService,
    private router: Router,
    private contractService: ContractService,
    private quarentineRecountService: QuarentineRecountService,
    public modalService: ModalService,
    private alertService: AlertService
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
   }

  async ngOnInit() {
    this.contractId = this.route.snapshot.params.contractId;
    this.contractService.getById(this.contractId).then(contract => {
      this.contract = contract;
    });
    this.columnsToShow = this.columns.slice(0);
    this.params.contractId = `${this.contractId}`;
    let module = this.route.snapshot.queryParamMap.get('module') || 'root';
    let quarantineId = this.route.snapshot.queryParamMap.get('quarantineId');
    if (localStorage.getItem('lastLayer') && localStorage.getItem('lastQuarantineId')) {
      module = localStorage.getItem('lastLayer');
      quarantineId = localStorage.getItem('lastQuarantineId');
    }
    if (quarantineId != null) {
      await this.quarantineService.getAll({
        contractId: this.contractId
      }).then(quarantine => {
        const item = quarantine.filter((i: any) => i.id === Number(quarantineId)).shift();
        this.sendQuarantine(item, module);
      });
    } else {
      this.layer = module;
      if(this.layer !== 'root') {
        this.params.order = 'date';
      }
    }
    this.spotService.getAll({ contractId: this.contractId}).then(res => {
      this.spots = res;
      res.forEach(spot => {
        this.spotById[spot.id] = spot;
      });
    });
    this.createSearchForm();
  }

  setQuarantines(event) {
    if (event.length === 0) {
      return ;
    }
    this.quarantines = event;
    event.map(quarantine => {
      this.sumTotalQuarantines(quarantine);
      // if ( > 0) {
      //   this.quarantines.push(quarantine);
      // }
    });
  }

  sumWrongRegulation(quarantine: Quarantine) {
    let total = 0;
    if (quarantine != null && quarantine.report != null) {
      if (quarantine.report.disabledRegulation != null) {
        total += quarantine.report.disabledRegulation;
      }
      if (quarantine.report.invalidWeekDay != null) {
        total += quarantine.report.invalidWeekDay;
      }
      if (quarantine.report.invalidDayPeriod != null) {
        total += quarantine.report.invalidDayPeriod;
      }
      if (quarantine.report.invalidVehicleClassification != null) {
        total += quarantine.report.invalidVehicleClassification;
      }
      if (quarantine.report.invalidRegulationPeriod != null) {
        total += quarantine.report.invalidRegulationPeriod;
      }
    }
    return total;
  }

  sumCalibrationFailure(quarantine: Quarantine) {
    let total = 0;
    if (quarantine != null && quarantine.report != null) {
      if (quarantine.report.missingAppraisalDocument != null) {
        total += quarantine.report.missingAppraisalDocument;
      }
      if (quarantine.report.invalidAppraisalNumber != null) {
        total += quarantine.report.invalidAppraisalNumber;
      }
      if (quarantine.report.expiredAppraisal != null) {
        total += quarantine.report.expiredAppraisal;
      }
      if (quarantine.report.invalidAppraisalDate != null) {
        total += quarantine.report.invalidAppraisalDate;
      }
    }
    return total;
  }

  sumInvalidEquipment(quarantine: Quarantine) {
    let total = 0;
    if (quarantine != null && quarantine.report != null) {
      if (quarantine.report.invalidEquipmentSerialNumber != null) {
        total += quarantine.report.invalidEquipmentSerialNumber;
      }
      if (quarantine.report.disabledEquipment != null) {
        total += quarantine.report.disabledEquipment;
      }
    }
    return total;
  }

  sumLaneFailure(quarantine: Quarantine) {
    let total = 0;
    if (quarantine != null && quarantine.report != null) {
      if (quarantine.report.disabledLane != null) {
        total += quarantine.report.disabledLane;
      }
      if (quarantine.report.testingLane != null) {
        total += quarantine.report.testingLane;
      }
      if (quarantine.report.invalidLaneNumber != null) {
        total += quarantine.report.invalidLaneNumber;
      }
      if (quarantine.report.spotMissing != null) {
        total += quarantine.report.spotMissing;
      }
    }
    return total;
  }

  sumInvalidSpeed(quarantine: Quarantine) {
    let total = 0;
    if (quarantine != null && quarantine.report != null) {
      if (quarantine.report.invalidSpeed != null) {
        total += quarantine.report.invalidSpeed;
      }
      if (quarantine.report.oddSpeed != null) {
        total += quarantine.report.oddSpeed;
      }
    }
    return total;
  }

  sumTotalQuarantines(quarantine: Quarantine) {
    let total = 0;
    if (quarantine.report != null) {
      if (quarantine.report.testingLane != null) {
        total += quarantine.report.testingLane;
      }
      if (quarantine.report.disabledLane != null) {
        total += quarantine.report.disabledLane;
      }
      if (quarantine.report.invalidSpeed != null) {
        total += quarantine.report.invalidSpeed;
      }
      if (quarantine.report.oddSpeed != null) {
        total += quarantine.report.oddSpeed;
      }
      if (quarantine.report.disabledRegulation != null) {
        total += quarantine.report.disabledRegulation;
      }
      if (quarantine.report.waitingFiles != null) {
        total += quarantine.report.waitingFiles;
      }
      if (quarantine.report.exemptPeriod != null) {
        total += quarantine.report.exemptPeriod;
      }
      if (quarantine.report.whiteList != null) {
        total += quarantine.report.whiteList;
      }
      if (quarantine.report.invalidWeekDay != null) {
        total += quarantine.report.invalidWeekDay;
      }
      if (quarantine.report.invalidDayPeriod != null) {
        total += quarantine.report.invalidDayPeriod;
      }
      if (quarantine.report.invalidAppraisalDate != null) {
        total += quarantine.report.invalidAppraisalDate;
      }
      if (quarantine.report.expiredAppraisal != null) {
        total += quarantine.report.expiredAppraisal;
      }
      if (quarantine.report.missingAppraisalDocument != null) {
        total += quarantine.report.missingAppraisalDocument;
      }
      if (quarantine.report.invalidAppraisalNumber != null) {
        total += quarantine.report.invalidAppraisalNumber;
      }
      if (quarantine.report.violationLimit != null) {
        total += quarantine.report.violationLimit;
      }
      if (quarantine.report.missingTarge != null) {
        total += quarantine.report.missingTarge;
      }
      if (quarantine.report.doubleViolation != null) {
        total += quarantine.report.doubleViolation;
      }
      if (quarantine.report.invalidLaneNumber != null) {
        total += quarantine.report.invalidLaneNumber;
      }
      if (quarantine.report.spotMissing != null) {
        total += quarantine.report.spotMissing;
      }
      if (quarantine.report.invalidEquipmentSerialNumber != null) {
        total += quarantine.report.invalidEquipmentSerialNumber;
      }
      if (quarantine.report.disabledEquipment != null) {
        total += quarantine.report.disabledEquipment;
      }
      if (quarantine.report.invalidVehicleClassification != null) {
        total += quarantine.report.invalidVehicleClassification;
      }
    }
    return total;
  }

  sendQuarantine(quarantine: Quarantine, layer) {
    this.layer = layer;
    this.quarantineDetail = quarantine;
    localStorage.setItem('lastLayer', layer);
    localStorage.setItem('lastQuarantineId', quarantine.id);
  }

  navigateQuarantineDetail(quarantine, quarantineReason) {
    this.router.navigate([`pre-process/quarantine/${quarantine.contractId}/${quarantineReason}/${quarantine.id}`]);
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: [''],
      spotIds: [''],
      laneCodes: ['']
    });
    this.searchForm.get('spotIds').valueChanges.subscribe(spotIds => {
      this.lanes = [];
      if (spotIds != null && spotIds.length > 0) {
        const filter = { contractId: this.contractId };
        if (spotIds.length !== this.spots.length) {
          filter['spotId[in]'] = `[${spotIds.join(',')}]`;
        }
        this.laneService.getAll(filter).then(res => {
          this.lanes = res;
        });
      }
    });
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  handleSearch() {
    this.quarantines = [];
    const params = { contractId: this.contractId };
    const searchValue = this.searchForm.get('search').value;
    if (searchValue != null && searchValue !== '') {
      params['spotId[contains, or]'] = searchValue;
      params['code[contains, or]'] = searchValue;
      params['number[contains, or]'] = searchValue;
    }
    const spots = this.searchForm.get('spotIds').value;
    if (spots != null && spots.length > 0) {
      params['spotId[in]'] = `[${spots.join(',')}]`;
    }
    const lanes = this.searchForm.get('laneCodes').value;
    if (lanes != null && lanes.length > 0) {
      params['code[in]'] = `[${lanes.join(',')}]`;
    }
    this.params = params;
  }

  async updateCounters() {
    this.loadingRecount = true;
    await this.quarentineRecountService.recount(this.route.snapshot.params.contractId).then(res => {
      this.alertService.show(new AlertItem('QuarantineRecountSuccess', AlertType.success, null, 5000));
    }).catch(err => {
      this.alertService.show(new AlertItem('QuarantineRecountDanger', AlertType.danger));
    });
    this.loadingRecount = false;
  }
}
