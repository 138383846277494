import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrandModel, Pagination } from '../models';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class BrandModelService extends BaseApiService<BrandModel> {
  get cacheKey(): StorageKey {
    return StorageKey.brandModelServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    switch (operation) {
      case 'list':
        let regionId = params.regionId;
        if (regionId == null) {
          regionId = 'Brasil';
        }
        return `${environment.apiUrl}/regions/${encodeURIComponent(regionId)}/brandModels`;
      default:
        let url = `${environment.apiUrl}/brandModels`;
        if (id != null) {
          url = url + '/' + encodeURIComponent(id);
        }
        return url;
    }
  }

  list(params: { [params: string]: string }): Promise<Pagination<BrandModel>> {
    return super.list(this.removeContractId(params));
  }

  protected unparse(obj) {
    return BrandModel.unparse(obj);
  }

  protected parse(obj) {
    return BrandModel.parse(obj);
  }

}
