import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AvatarService } from 'src/app/core/services/avatar.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.sass']
})
export class AvatarComponent implements OnInit, OnDestroy {
  @Input() user: User = new User();
  @Input() class: string;
  public formatedName;
  private _subs: Subscription[] = [];

  constructor( private avatarService: AvatarService) { }

  ngOnInit() {
    if (this.user && this.user.name != null) {
      this.capitalizeName(this.user.name);
    }
    this._subs.push(
      this.avatarService.getEvents().subscribe((user: User) => {
        if (this.user.id !== user.id) {
          return;
        }
        this.user.name = user.name;
        this.user.avatar = user.avatar;
        this.capitalizeName(user.name);
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  public capitalizeName(name: string) {
    this.formatedName = name.split(' ').map(firstChar => firstChar[0]).join('').slice(0, 2);
  }

}
