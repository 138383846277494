<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h5 class="modal-title container" *ngIf="calibration.type === undefined" i18n>Nova Aferição</h5>
        <h5 class="modal-title container" *ngIf="calibration.type  " i18n>Editar Aferição</h5>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="equipmentCalibrationForm" (submit)="onSubmit()" enctype="multipart/form-data">
        <div class="row mb-3">
          <div class="col-12 col-md-3">
            <div class="form-group">
              <label i18n>Data da Aferição <small class="text-danger">*</small></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="material-icons">date_range</i></span>
                </div>
                <input type="date" class="form-control" formControlName="date">
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4">
            <label i18n>Tipo de Aferição <small class="text-danger">*</small></label>
            <select class="form-control" #classe (change)="0" formControlName="type">
              <option value="" selected disabled i18n>Selecione o tipo de aferição</option>
              <option value="metrologic" i18n>Metrológica</option>
              <option value="non-metrologic" i18n>Não Metrológica</option>
            </select>
          </div>

          <div class="col-12 col-md-4 {{classe.value}} tooltip-div">
            <div class="form-group" *ngIf="classe.value === 'metrologic'">
              <label i18n>Velocidade Aferida <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top" appTooltip="É a velocidade medida no momento da aferição.">info</i></label>
              <div class="input-group">
                <input type="number" class="form-control form-control-number" formControlName="speed" min="1" max="400">
                <div class="input-group-append" *ngIf="classe.value === 'metrologic'">
                  <span class="input-group-text" i18n>km/h</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <fieldset>
          <legend i18n>Vigência</legend>
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="form-group">
                <label i18n>Data Inicial <small class="text-danger">*</small></label>
                <div class="input-group input-group--adjust">
                  <div class="input-group-prepend" *ngIf="!equipmentCalibrationForm.get('startsAt').invalid || !equipmentCalibrationForm.get('startHour').invalid">
                    <div class="input-group-text">
                      <i class="material-icons">date_range</i>
                    </div>
                  </div>
                  <app-input class="input-group--input-prepend" type="date" formControlName="startsAt" inputRequired=true hasLabel=false></app-input>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group">
                <label i18n>Hora Inicial <small class="text-danger">*</small></label>
                <div class="input-group input-group--adjust">
                  <div class="input-group-prepend">
                    <div class="input-group-text" *ngIf="!equipmentCalibrationForm.get('startHour').invalid">
                      <i class="material-icons">access_time</i>
                    </div>
                  </div>
                  <app-input class="input-group--input-prepend" format="dd:dd:dd" formControlName="startHour" inputRequired=true hasLabel=false></app-input>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group">
                <label i18n>Data Final <small class="text-danger">*</small></label>
                <div class="input-group input-group--adjust">
                  <div class="input-group-prepend" *ngIf="!equipmentCalibrationForm.get('endsAt').invalid || !equipmentCalibrationForm.get('endHour').invalid">
                    <div class="input-group-text">
                      <i class="material-icons">date_range</i>
                    </div>
                  </div>
                  <app-input class="input-group--input-prepend" type="date" formControlName="endsAt" inputRequired=true hasLabel=false></app-input>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group">
                <label i18n>Hora Final <small class="text-danger">*</small></label>
                <div class="input-group input-group--adjust">
                  <div class="input-group-prepend">
                    <div class="input-group-text" *ngIf="!equipmentCalibrationForm.get('endHour').invalid">
                      <i class="material-icons">access_time</i>
                    </div>
                  </div>
                  <app-input class="input-group--input-prepend" format="dd:dd:dd" formControlName="endHour" inputRequired=true hasLabel=false></app-input>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset class="mt-2 mb-3">
          <legend>Técnico</legend>
          <div class="row">
            <div class="col-12 col-md-6">
              <app-input type="text" i18n-label="@@TechnicianLabel" label="Nome" formControlName="nameTechnician">
              </app-input>
            </div>
            <div class="col-12 col-md-6">
              <app-input type="text" i18n-label="@@RegistrationLabel" label="Matrícula"
                formControlName="registrationTechnician">
              </app-input>
            </div>
          </div>
        </fieldset>
        <div class="row mt-2">
          <div class="col-12 col-md-6">
            <app-input i18n-label="@@sealNumberLabel" type="text" label="Número do Lacre" formControlName="sealNumber">
            </app-input>
          </div>
          <div class="col-12 col-md-6">
            <app-input type="text" i18n-label="@@AppraisalLabel" label="Número do Laudo"
              formControlName="appraisalNumber">
            </app-input>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 col-md-6">
            <app-input type="text" i18n-toolTipMsg i18n-label="@@AgencyLabel" label="Número do Processo"
              formControlName="processNumber" toolTipMsg="Número do processo junto ao órgão regulamentador">
            </app-input>
          </div>
          <div class="col-12 col-md-6">
            <app-input type="text" i18n-label="@@OrganLabel" label="Órgão Aferidor" formControlName="agency">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h6 i18n>Arquivos :</h6>
            <label class="radio-inline mr-3 pointer" i18n>
              <input type="radio" formControlName="typeFile" value="report" i18n>
              Laudo</label>
            <label class="radio-inline pointer" i18n>
              <input type="radio" formControlName="typeFile" value="supplementaryArchives" i18n>
              Arquivos Complementares</label>
            <hr>
            <div *ngIf="equipmentCalibrationForm.value.typeFile === 'report'">
              <app-file-upload i18n-text="@@TextSingleLabel" formControlName="appraisalDocument"
                [files]=documentFiles
                (outputFiles)="documentFiles = $event"
                accept="image/jpg, image/jpeg, image/png, image/gif, video/mp4, video/mpeg, application/doc, application/docx, application/pdf"
                text="Selecione apenas um arquivo." limit="1"></app-file-upload>
            </div>
            <div *ngIf="equipmentCalibrationForm.value.typeFile === 'supplementaryArchives'">
              <app-file-upload i18n-text="@@TextMultipleLabel" formControlName="extraFiles" accept="*" limit="10"
                [files]=extraFiles
                text="Você pode selecionar no máximo até 10 arquivos."
                (outputFiles)="extraFiles = $event"></app-file-upload>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" (click)="onCancel()">
        <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
      </button>
      <button class="btn btn-primary" (click)="onSubmit()" [ngClass]="{disabled: !isValid}">
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
