export const getImageLimits = (imgContainerElement) => {
  let height;
  let width;
  const img = imgContainerElement.querySelector('img');

  if ((img.naturalWidth / imgContainerElement.offsetWidth)
    > (img.naturalHeight / imgContainerElement.offsetHeight)) {
    width = imgContainerElement.offsetWidth;
    height = img.naturalHeight * (imgContainerElement.offsetWidth / img.naturalWidth);
  } else {
    height = imgContainerElement.offsetHeight;
    width = img.naturalWidth * (imgContainerElement.offsetHeight / img.naturalHeight);
  }

  const minX = (imgContainerElement.offsetWidth / 2) - (width / 2);
  const minY = (imgContainerElement.offsetHeight / 2) - (height / 2);
  const maxX = (imgContainerElement.offsetWidth / 2) + (width / 2);
  const maxY = (imgContainerElement.offsetHeight / 2) + (height / 2);

  img.style = `max-width: ${width}px; width: 100%`;

  return { minX: Math.round(minX), minY: Math.round(minY), maxX: Math.round(maxX), maxY: Math.round(maxY) };
};

export const getProportion = (imgContainerElement) => {
  const img = imgContainerElement.querySelector('img');
  if ((img.naturalWidth / imgContainerElement.offsetWidth)
    > (img.naturalHeight / imgContainerElement.offsetHeight)) {
    return imgContainerElement.offsetWidth / img.naturalWidth;
  } else {
    return (imgContainerElement.offsetHeight / img.naturalHeight);
  }
};
