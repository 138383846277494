import { ClosedModalError } from 'src/app/core/errors';
import {
  Calibration, Equipment, ComponentModal, MessageModal, AlertItem, AlertType, TypeCalibration, Lane, Spot, Contract
} from 'src/app/core/models';
import {
  CalibrationService,
  EquipmentService,
  ModalService,
  AlertService,
  LaneService,
  SpotService,
  ContractGlobalService,
  StorageService,
  StorageKey
} from 'src/app/core/services';
import { Component, OnInit, EventEmitter } from '@angular/core';
import {
  EquipmentsCreateOrUpdateCalibrationComponent
} from '../../equipments/equipments-create-or-update-calibration/equipments-create-or-update-calibration.component';
import * as moment from 'moment';
import { CalibrationViewModalComponent } from '../calibration-view-modal/calibration-view-modal.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { validateDate } from 'src/app/core/utils/validateDate';
import { ContractService } from 'src/app/core/services';

@Component({
  selector: 'app-calibrations-list',
  templateUrl: './calibrations-list.component.html',
  styleUrls: ['./calibrations-list.component.sass']
})
export class CalibrationsListComponent implements OnInit {
  public calibrations: Array<Calibration> = [];
  public equipments: Array<Equipment> = [];
  public lanes: Array<Lane> = [];
  public columns = [
    'current',
    'contract',
    'type',
    'calibrationDate',
    'effectivePeriod',
    'serialNumber',
    'lane',
    'sealNumber',
    'appraisalNumber',
    'expirationDays',
    'actions'
  ];
  public columnsToShow = [];
  public params: any = {};
  public dateNow = new Date();
  public calibrationListEndsAt: { [params: string]: any } = {};
  public modalState;
  public typesCalibration = Object.keys(TypeCalibration);
  public typesCalibrationI18n = [];
  public now = new Date().toISOString();
  public lanesByEquipment = {};
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public modelChanged: Subject<string> = new Subject<string>();
  public searchForm: FormGroup;
  public spotById: { [params: string]: Spot } = {};
  public contractCode: string;
  public contracts: Contract[];
  public contractsCalibrations: { [contractId: number]: Calibration[] } = {};
  public isloading = true;
  public noData = false;
  public lanesPromise: Promise<Array<Lane>>;
  public lanesBySpots: Array<Lane> = [];
  public currentPermissions = [];
  public spots = [];
  public spotsAll = [];

  public contractIds = [];

  get isValid() {
    return this.searchForm.valid;
  }

  constructor(
    public calibrationService: CalibrationService,
    private equipmentService: EquipmentService,
    private modalService: ModalService,
    private laneService: LaneService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private spotService: SpotService,
    private contractsGlobalService: ContractGlobalService,
    private storageService: StorageService,
    private contractService: ContractService

  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });

    this.contractsGlobalService.contractEvent.pipe(
      debounceTime(1000))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  ngOnInit() {
    this.lanesPromise = this.laneService.getAll();
    this.columnsToShow = this.columns.slice(0);
    this.contractService.getAll().then((res) => {
      this.contracts = res;
    });
    this.equipmentService.getAll(null, true).then(res => {
      res.forEach(equipment => {
        this.equipments[equipment.id] = equipment;
      });
    });
    this.laneService.getAll().then(res => {
      res.forEach(lane => {
        this.lanesByEquipment[lane.id] = lane;
      });
    });
    this.currentPermissions = this.storageService.get(StorageKey.currentPermissions);
    if (this.contractsGlobalService?.contracts != null) {
      this.contractIds = this.currentPermissions &&
        this.currentPermissions.filter(contractPermission =>
          this.contractsGlobalService?.contracts.includes(contractPermission.contractId) &&
          contractPermission.actionIds.indexOf('ViewCalibration') >= 0)
          .map(m => m.contractId);
    } else {
      this.contractIds = this.currentPermissions &&
        this.currentPermissions.filter(contractPermission =>
          contractPermission.actionIds.indexOf('ViewCalibration') >= 0).map(m => m.contractId);
    }
    this.spotService.list({ 'contractId[in]': `[${this.contractIds}]` }).then(res => {
      this.spotsAll = res.result;
      this.spotsAll.forEach(spot => {
        this.spotById[spot.id] = spot;
      });
      this.laneService.list({ 'contractId[in]': `[${this.contractIds}]` }).then(resLanes => {
        this.lanes = resLanes.result;
      });
    });

    this.createSearchForm();
    this.handleSearch();
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: [''],
      equipmentId: [''],
      type: [''],
      sealNumber: [''],
      serialNumber: [''],
      appraisalNumber: [''],
      startsAt: [''],
      endsAt: [''],
      expirationStartsAt: [''],
      expirationEndsAt: [''],
      status: [''],
      laneIds: [[]],
      spotIds: [[]],
      contractId: ['']
    });
    const startsAt = this.searchForm.get('startsAt');
    const endsAt = this.searchForm.get('endsAt');
    const expirationStartsAt = this.searchForm.get('expirationStartsAt');
    const expirationEndsAt = this.searchForm.get('expirationEndsAt');

    startsAt.setValidators(validateDate(endsAt, false));
    endsAt.setValidators(validateDate(startsAt, true));
    expirationStartsAt.setValidators(validateDate(expirationEndsAt, false));
    expirationEndsAt.setValidators(validateDate(expirationStartsAt, true));
  }

  setContractCode(contractId: string) {
    this.contractCode = this.contractCode === contractId ? null : contractId;
    setTimeout(() =>
      document
        .getElementById(`calibrations-${contractId}`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  async setCalibrations() {
    this.isloading = true;
    const res = await this.calibrationService.getAll(this.params);
    this.contractsCalibrations = {};
    for (const calibration of res) {
      this.contractsCalibrations[calibration.contractId] = res.filter(x => x.contractId === calibration.contractId) || [];
    };
    this.isloading = false;
    if (res.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
  }

  handleSearch() {
    if (!this.searchForm.valid) {
      return null;
    }
    const filterObj = {};
    const search = this.searchForm.get('search').value;
    const type = this.searchForm.get('type').value;
    const contractId = this.searchForm.get('contractId').value === '' ? null:
      this.searchForm.get('contractId').value;
    if (!contractId) {
      this.searchForm.get('spotIds').setValue('');
      this.searchForm.get('laneIds').setValue('');
    }
    const spotIds = this.searchForm.get('spotIds').value;
    const laneIds = this.searchForm.get('laneIds').value;
    const equipmentIds = [];
    if (contractId && laneIds.length > 0) {
      const lanes = this.lanes.filter(lane => laneIds.includes(lane.id));
      lanes.map(l => {
        l.equipmentIds.map(e => {
          if (!equipmentIds.includes(e)) {
            equipmentIds.push(e);
          }
        });
      });
    } else if (contractId && spotIds.length > 0) {
      const spots = this.spots.filter(spot => spotIds.includes(spot.id));
      spots.map(s => {
        s.laneIds.map(laneId => {
          const lane = this.lanes.find(l => l.id === laneId);
          if (lane.equipmentIds && lane.equipmentIds.length > 0) {
            lane.equipmentIds.map(e => {
              if (!equipmentIds.includes(e)) {
                equipmentIds.push(e);
              }
            });
          }
        });
      });
    }
    if (equipmentIds.length > 0 || ((spotIds.length > 0 || laneIds.length > 0) && equipmentIds.length) === 0) {
      filterObj['equipmentId[in]'] = `[${equipmentIds}]`;
    }

    if (search != null && search !== '') {
      filterObj['equipmentId[contains,or]'] = `${search}`;
      filterObj['appraisal.number[contains,or]'] = `${search}`;
      filterObj['sealNumber[contains,or]'] = `${search}`;
      filterObj['serialNumber[contains,or]'] = `${search}`;
      filterObj['appraisal.number[contains,or]'] = `${search}`;
    }
    if (type !== '') {
      filterObj['type[contains,in]'] = `[${type}]`;
    }

    const startsAt = this.searchForm.get('startsAt').value || null;
    const endsAt = this.searchForm.get('endsAt').value || null;
    if (startsAt != null && endsAt != null) {
      filterObj['date[gte]'] = moment(startsAt).utc().format();
      filterObj['date[lte]'] = moment(endsAt + ' 23:59:59').utc().format();
    }

    const expirationStartsAt = this.searchForm.get('expirationStartsAt').value || null;
    const expirationEndsAt = this.searchForm.get('expirationEndsAt').value || null;
    if (expirationStartsAt != null && expirationEndsAt != null) {
      filterObj['startsAt[lte]'] = moment(`${expirationEndsAt} 23:59:59`).utc().format();
      filterObj['endsAt[gte]'] = moment(expirationStartsAt).utc().format();
    }

    const status = this.searchForm.get('status').value;
    if (status) {
      switch (status) {
        case 'current':
          filterObj['endsAt[gte]'] = this.now;
          break;
        case 'expired':
          filterObj['endsAt[lte]'] = this.now;
          break;
        case 'expiresIn30Days':
          const format = 'YYYY-MM-DD';
          const newDate = moment(this.now, format).add(30, 'days');
          filterObj['endsAt[gte]'] = moment(this.now).utc().format();
          filterObj['endsAt[lte]'] = moment.utc(newDate).utc().format();
      }
    }
    this.params = filterObj;

    const currentUser = this.storageService.get(StorageKey.currentUser);
    if (this.contractsGlobalService?.contracts) {
      this.params['contractId[in]'] = `[${this.contractsGlobalService.contracts.join()}]`;
    } else if (contractId && contractId !== '') {
      this.params.contractId = contractId;
    } else if (currentUser && !currentUser.superUser) {
      this.params['contractId[in]'] = `[${this.contractIds.join(',')}]`;
    } else {
      delete this.params['contractId[in]'];
    }
    this.setCalibrations();
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  openCreateOrUpdateCalibration(calibration: Calibration) {
    this.modalService.show(new ComponentModal(EquipmentsCreateOrUpdateCalibrationComponent, calibration))
      .then(() => {
        this.setCalibrations();
      })
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
          this.modalState = (modalError.modal as ComponentModal).component.instance.lastState;
        } else {
          this.modalState = null;
        }
      });
  }

  openDeleteModal(calibration: Calibration) {
    this.modalService.show(new MessageModal('Remover Aferição', 'Deseja remover esta aferição?', true))
      .then(() => {
        this.delete(calibration);
      })
      .catch(err => {
        if (err.error && err.error.type === 'calibrationNotDelete') {
          this.alertService.show(new AlertItem('ModelNotDelete', AlertType.danger, false, 5000));
        } else {
          this.alertService.show(new AlertItem('CalibrationDeleteError', AlertType.danger));
        }
      });
  }

  delete(calibration: Calibration) {
    this.calibrationService.delete(calibration.id)
      .then(res => {
        this.deleteEvent.emit(calibration.id);
        this.alertService.show(new AlertItem('CalibrationDeleted', AlertType.success));
        this.setCalibrations();
      })
      .catch(err => {
        if (err.error && err.error.type === 'calibrationNotDelete') {
          this.alertService.show(new AlertItem('ModelNotDelete', AlertType.danger, false, 5000));
        } else {
          this.alertService.show(new AlertItem('CalibrationDeleteError', AlertType.danger));
        }
      });
  }

  updateCalibrationEdit(calibration: Calibration) {
    return this.calibrationService.update(Calibration.create(calibration))
      .then(res => {
        this.alertService.show(new AlertItem('CalibrationSaved', AlertType.success));
        this.setCalibrations();
        return res;
      }).catch(error => {
        this.setCalibrations();
        this.alertService.show(new AlertItem('CalibrationSavePeriodError', AlertType.danger));
      });
  }

  getTypeCalibration(typeCalibration) {
    if (typeCalibration === 'non-metrologic') {
      typeCalibration = 'nonMetrologic';
    }
    return this.typesCalibrationI18n.find(type => type.id === typeCalibration).value;
  }

  transform(endsAt: string) {
    return moment(endsAt).diff(moment(), 'days');
  }

  openViewModal(calibration: Calibration) {
    this.modalService.show(new ComponentModal(CalibrationViewModalComponent, calibration))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  setLanes(spotIds) {
    if (spotIds.length === 0) {
      this.searchForm.patchValue({ laneIds: [] });
    }
    this.lanesBySpots = [];
    if (spotIds && spotIds.length > 0) {
      this.lanesBySpots = this.lanes.filter(lane => spotIds.includes(lane.spotId));
    }
  }
  setSpots(contractId = null) {
    this.spots = [];
    this.lanesBySpots = [];
    if (!contractId) {
      return ;
    }
    this.spots = this.spotsAll.filter(s => s.contractId === contractId);
  }

}
