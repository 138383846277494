<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h5 *ngIf="equipment.id; else titleBlock" class="modal-title container" i18n>Editar Equipamento</h5>
        <ng-template #titleBlock>
          <h5 class="modal-title container" i18n>Novo Equipamento</h5>
        </ng-template>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="equipmentForm" (submit)="onSubmit()">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <app-input i18n-label="@@ContractLabel" [type]="equipment && equipment.id ? 'text' : 'select'"
                formControlName="contractId" inputRequired=true label="Selecione o contrato"
                [inputDisabled]="equipment.id" [list]="contracts" i18n-placeholder placeholder="Selecione o contrato"
                selectValue="id" selectText="prettyName" (ngModelChange)="filterContract($event)">
              </app-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-md-4">
            <div class="form-group">
              <app-input i18n-label="@@SeriesNumber" type="text" formControlName="serialNumber" inputRequired=true
                label="Número de Série">
              </app-input>
            </div>
          </div>

          <div class="col col-md-3">
            <div class="form-group">
              <app-input i18n-label="@@ClientsCode" type="text" formControlName="clientCode" label="Código do Cliente">
              </app-input>
            </div>
          </div>

          <div class="col col-md-5">
            <div class="form-group">
              <app-input i18n-label="@@EquipmentModel" type="select" [list]="equipmentModels" inputRequired=true
                label="Modelo" formControlName="equipmentModelId" selectText="name" selectValue="id"
                placeholder="Selecione modelo de equipamento" i18n-placeholder>
              </app-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label i18n>Características:</label>

            <div class="row mt-1">
              <div class="col-auto">
                <mat-checkbox color="primary" formControlName="ocr" i18n>Leitura OCR</mat-checkbox>
              </div>

              <div class="col-auto">
                <mat-checkbox color="primary" formControlName="classification" i18n>Classificação Veicular
                </mat-checkbox>
              </div>

              <div class="col-auto">
                <mat-checkbox color="primary" formControlName="trafficLight" i18n>Semáforo</mat-checkbox>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-md-12 mt-4">
            <div class="form-group">
              <app-input i18n-label type="multiselect" [list]="spots" label="Ponto" formControlName="spotIds"
                selectText="prettyName" selectValue="id" selectShowLimit="2" placeholder="Selecione o Ponto"
                i18n-placeholder (ngModelChange)="setLanes($event)">
              </app-input>
            </div>
          </div>
          <div class="col col-6 col-md-6 mt-4">
            <div class="form-group">
              <app-input class="no-margin" formControlName="laneIds" type="multiselect" [list]="lanesBySpots"
                placeholder="Selecione faixa(s)" label="Faixas" selectText="prettyNames" selectValue="id"
                selectShowLimit="2" i18n-placeholder i18n-label></app-input>
            </div>
          </div>
          <div class="col-6 col-md-6 mt-4">
            <label i18n>Habilitado?
              <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                appTooltip="Para recebimento das infrações na plataforma, é necessário que o equipamento esteja habilitado.">info</i></label>
            <div class="custom-control custom-switch">
              <div class="form-group mt-1">
                <label class="custom-control-label" [ngClass]="{checked: equipmentForm.get('enabled').value}">
                  <input type="checkbox" class="form-control custom-control-input" formControlName="enabled">
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3" *ngIf="displayCftvFields">
          <div class="col-md-12">
            <p><strong>CFTV-RTSP</strong></p>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <app-input type="text" formControlName="name" inputRequired=true label="Câmera Nome"></app-input>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <app-input type="text" formControlName="url" inputRequired=true label="URL"></app-input>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <app-input type="number" formControlName="port" inputRequired=true label="Porta"></app-input>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <app-input type="text" formControlName="user" inputRequired=true label="Usuário"></app-input>
            </div>
          </div>
          <div class="col-md-3" *ngIf="!isViewPassword">
            <div class="form-group">
              <app-input type="text" formControlName="password" inputRequired=true label="Senha"></app-input>
            </div>
          </div>
          <div class="col-md-3" *ngIf="isViewPassword">
            <div class="form-group">
              <label class="font-weight-bold" i18n>Senha</label><br />
              <app-input type="password" [ngClass]="{'type-password': true}"
                label="Senha" [inputRequired]="equipment?.cftv? true : false"
                [textValue]="equipment?.cftv?.password || '-'" hasLabel="false" [isLabel]="true">
              </app-input>
            </div>
          </div>
          <div class="col-md-2 pl-0 mt-4">
            <span class="material-icons" (click)="setViewPassword()" *ngIf="isViewPassword">visibility</span>
            <span class="material-icons" (click)="setViewPassword()" *ngIf="!isViewPassword">visibility_off</span>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" (click)="onCancel()">
        <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
      </button>
      <button class="btn btn-primary" (click)="onSubmit()" [ngClass]="{disabled: !isValid}">
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
