import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { StorageService, StorageKey } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private storageService: StorageService
    ) { }

    canActivate(): boolean {
        const user = this.storageService.get(StorageKey.currentUser);
        if (user) {
          return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
