<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">

    <div class="modal-header" [ngSwitch]="theme">
      <h5 *ngSwitchDefault class="modal-title ml-2" i18n>O que há de novo no ECS</h5>
      <h5 *ngSwitchCase="'gtots'" class="modal-title ml-2" i18n>O que há de novo no Gtots</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="modal-body">
      <div class="mt-3" *ngFor="let item of releaseNotes">
        <h6><span i18n>Versão</span> {{item.version}} - <span i18n>Liberada em</span> {{item.createdAt | moment: 'L'}}
        </h6>
        <div class="mt-3" [ngClass]="{'card-columns': item.notes.length > 1}">
          <div class="card card-bordered" *ngFor="let note of item.notes">
            <div class="card-header d-flex align-items-center">
              <h5 *ngIf="note.module === 'preProcess'" i18n class="d-flex mb-0">
                <i class="material-icons mr-3">burst_mode</i> Módulo Pré-Processamento
              </h5>

              <h5 *ngIf="note.module === 'violation'" i18n class="d-flex mb-0">
                <i class="material-icons mr-3">error_outline</i> Módulo Infração
              </h5>

              <h5 *ngIf="note.module === 'traffic'" i18n class="d-flex mb-0">
                <i class="material-icons mr-3">navigation</i> Módulo Tráfego
              </h5>

              <h5 *ngIf="note.module === 'surveillance'" i18n class="d-flex mb-0">
                <i class="material-icons mr-3">security</i> Módulo Segurança
              </h5>

              <h5 *ngIf="note.module === 'base'" i18n class="d-flex mb-0">
                <i class="material-icons mr-3">assignment_turned_in</i> Módulo Base
              </h5>
            </div>
            <div class="card-body" [innerHtml]="note.description"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <div>
        <app-infinite-scroll (items)="setReleaseNotes($event)" [params]="{ sortAsc: 'false' }"
          [service]="releaseNotesService" perPage="1"></app-infinite-scroll>
      </div>
    </div>
  </div>

</div>
