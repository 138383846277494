<app-quarantine-header [quarantine]="quarantineDetail" [layer]="layer" (outLayer)="layer=$event"></app-quarantine-header>

<div class="container">
  <div class="row no-gutters">
    <div class="col-lg-9">
      <div class="mb-4" *ngIf="layer === 'root'">
        <form [formGroup]="searchForm" (submit)="handleSearch()">
          <div class="input-group">
            <input i18n-placeholder type="text" class="form-control form-search"
            formControlName="search" placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">

            <div class="input-group-append">
              <div class="filter-dropdown">
                <button type="button" class="btn btn-outline-secondary btn-icon filter-dropdown__handle" i18n>
                  <i class="material-icons">filter_alt</i> Filtrar</button>

                <div class="filter-dropdown__content">
                  <div class="form-group">
                    <app-input i18n-placeholder type="multiselect" [list]="spots" label="Pontos" formControlName="spotIds"
                    selectText="prettyName" selectValue="id" selectShowLimit="2" placeholder="Selecione os pontos"
                    i18n-placeholder i18n-label></app-input>
                  </div>

                  <div class="form-group">
                    <app-input i18n-placeholder type="multiselect" [list]="lanes" label="Faixas" formControlName="laneCodes"
                    selectText="prettyNames" selectValue="code" selectShowLimit="2" placeholder="Selecione as faixas"
                    i18n-placeholder i18n-label></app-input>
                  </div>

                  <div class="filter-dropdown__footer">
                    <button i18n type="submit" class="btn btn-info btn-block" (click)="handleSearch()"
                      data-dismiss="modal">Aplicar</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="filter-dropdown ml-3">
              <button class="btn btn-fade-secondary btn-icon">
                <i class="material-icons">view_column</i>
                <span>Exibir Colunas</span>
              </button>
              <app-column-filter [items]="columns" (output)="columnsToShow=$event"></app-column-filter>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-lg-3 pl-2" *ngIf="quarantines.length > 0 && layer === 'root'">
      <button class="btn btn-fade-secondary btn-icon" (click)="updateCounters()">
        <i class="material-icons" [ngClass]="{ 'rotating': loadingRecount }">sync</i> Atualizar contadores
      </button>
    </div>
  </div>

  <div [ngSwitch]="layer">
    <div *ngSwitchCase="'root'">
      <table class="table table-borderless table-striped table-hover table-block">
        <thead>
          <tr>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('contract')}">Contrato</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('supportGroup')}">Grupo de Pontos</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('spot')}">Ponto</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('lane')}">Faixa</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('laneFailure')}">Ponto/Faixa Inválida</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('equipmentFailure')}">Equipamento Inválido</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('wrongRegulation')}">Enquadramento Inválido</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('calibrationFailure')}">Aferição Inválida</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('speedFailure')}">Velocidade
              Inválida</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('waitingFiles')}">Aguardando Arquivos</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('missingTarge')}">Tarja Inexistente</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('exemptPeriod')}">Período Isento</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('whiteList')}">Lista Branca</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('violationLimit')}">Limite Infrações</th>

            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('doubleViolation')}">Infração Duplicada</th>
            <th i18n [ngClass]="{'text-center': true, 'd-none': !columnsToShow.includes('total')}">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let quarantine of quarantines">
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('contract')}">
              {{ contract?.code }} </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('supportGroup')}"></td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('spot')}">
              {{ spotById[quarantine.spotId]?.code }} </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('lane')}">
              {{ quarantine?.code }} </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('laneFailure')}">
              <button class="btn btn-link" (click)="sendQuarantine(quarantine, 'laneFailure')">
                {{ sumLaneFailure(quarantine) }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('equipmentFailure')}">
              <button class="btn btn-link" (click)="sendQuarantine(quarantine, 'equipmentFailure')">
                {{ sumInvalidEquipment(quarantine) }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('wrongRegulation')}">
              <button class="btn btn-link" (click)="sendQuarantine(quarantine, 'wrongRegulation')">
                {{ sumWrongRegulation(quarantine) }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('calibrationFailure')}">
              <button class="btn btn-link" (click)="sendQuarantine(quarantine, 'calibrationFailure')">
                {{ sumCalibrationFailure(quarantine) }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('speedFailure')}">
              <button class="btn btn-link" (click)="sendQuarantine(quarantine, 'speedFailure')">
                {{ sumInvalidSpeed(quarantine) }}
              </button>
            </td>
            <td class="text-center" [ngClass]="{'d-none': !columnsToShow.includes('waitingFiles')}">
              <button class="btn btn-link" (click)="navigateQuarantineDetail(quarantine, 'waitingFiles')">
                {{ quarantine?.report?.waitingFiles || 0 }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('missingTarge')}">
              <button class="btn btn-link" (click)="navigateQuarantineDetail(quarantine, 'missingTarge')">
                {{ quarantine?.report?.missingTarge || 0 }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('exemptPeriod')}">
              <button class="btn btn-link" (click)="navigateQuarantineDetail(quarantine, 'exemptPeriod')">
                {{ quarantine?.report?.exemptPeriod || 0 }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('whiteList')}">
              <button class="btn btn-link" (click)="navigateQuarantineDetail(quarantine, 'whiteList')">
                {{ quarantine?.report?.whiteList || 0 }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('violationLimit')}">
              <button class="btn btn-link" (click)="navigateQuarantineDetail(quarantine, 'violationLimit')">
                {{ quarantine?.report?.violationLimit || 0 }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('doubleViolation')}">
              <button class="btn btn-link" (click)="navigateQuarantineDetail(quarantine, 'doubleViolation')">
                {{ quarantine?.report?.doubleViolation || 0 }}
              </button>
            </td>
            <td class="text-center align-middle" [ngClass]="{'d-none': !columnsToShow.includes('total')}">
              {{ sumTotalQuarantines(quarantine) }}
            </td>
          </tr>
        </tbody>
      </table>

      <app-pagination (items)="setQuarantines($event)" [params]="params" [service]="quarantineService"></app-pagination>
    </div>

    <div *ngIf="quarantinesWithDetail.includes(layer)">
      <app-quarantine-reason-detail [quarantine]="quarantineDetail" [quarantineName]="layer">
      </app-quarantine-reason-detail>
    </div>
  </div>
</div>
