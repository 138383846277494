/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  ContractGlobalService, AlertService, StorageService, StorageKey, ContractService, UserService, ViolationSourceService
} from 'src/app/core/services';
import { Subscription } from 'rxjs';
import { AlertItem, AlertType } from 'src/app/core/models';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BaseApiService } from 'src/app/core/services/baseApiService';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass']
})
export class PaginationComponent implements OnInit, OnDestroy {
  _perPage = 5;
  rows = [];

  @Input() service: BaseApiService<any>;
  @Input() permission = null;
  get params() {
    return this._params;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() set params(params: {} | Promise<any>) {
    this.getParamsEvent(params);
  }
  @Input() deleteEvent: EventEmitter<any>;
  get perPage() {
    return this._perPage;
  }
  @Input() set perPage(value) {
    this.selectedPage = Math.floor((this.selectedPage - 1) * this._perPage / value) + 1;
    this._perPage = value;
    this.setItems();
    this.setPagesArray();
    if (this.nextUrl != null) {
      const split = this.nextUrl.split('?');
      const base = split.shift();
      const params = new URLSearchParams('?' + split.join('?'));
      params.set('limit', '' + (this.perPage * 3));
      this.nextUrl = base + '?' + params.toString();
    }
  }
  @Input() maxResults = 100;

  @Output() items: EventEmitter<Array<any>> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();

  public isLoading = false;
  public selectedPage = 1;
  public pages = [];
  public noRegister = false;
  public contracts = '';
  public contractsSubscription: Subscription;
  public perPageByCacheKey;

  private _params = {};
  private startPage = 1;
  private endPage = 1;
  private nextUrl = undefined;
  private allItems = [];
  private queryPromise = null;
  private contractParam = [];

  constructor(
    private contractsGlobalService: ContractGlobalService,
    private alertService: AlertService,
    private storageService: StorageService,
    private router: Router
  ) {
    if (this.contractsGlobalService.contracts != null && this.contractsGlobalService.contracts.length > 0) {
      const contracts = this.contractsGlobalService.contracts;
      this.contractParam = contracts;
    } else {
      this.contractParam = null;
    }
  }

  async ngOnInit() {
    this.perPageByCacheKey = this.storageService.get('recordsNumberByListageCache') || [];
    if (this.perPageByCacheKey != null && this.service && this.service.cacheKey) {
      const perPageByCache = this.perPageByCacheKey.find(page => page.name === this.service.cacheKey);
      this.perPage = perPageByCache && perPageByCache.perPage || this.perPage;
    }
    await this.setItems();
    await this.setPagesArray();
    if (this.deleteEvent != null) {
      this.deleteEvent.subscribe(this.getDeleteEvent.bind(this));
    }
    this.contractsSubscription = this.contractsGlobalService.contractEvent
      .subscribe(contracts => {
        if (contracts && contracts.length > 0) {
          this.contractParam = contracts;
        } else {
          this.contractParam = null;
        }
        this.allItems = [];
        this.selectedPage = 1;
        this.nextUrl = undefined;
        this.setItems();
      });
  }

  ngOnDestroy() {
    this.contractsSubscription.unsubscribe();
  }

  get hasNext() {
    return this.selectedPage < this.endPage;
  }
  get hasPrevious() {
    return this.selectedPage > 1;
  }

  async getParamsEvent(event) {
    this._params = await event;
    this.allItems = [];
    this.selectedPage = 1;
    this.nextUrl = undefined;
    this.setItems();
  }

  async getContractParams() {
    const params = (_.cloneDeep(await this.params)) || {};
    if (params['contractId[in]'] != null) {
      delete params['contractId[in]'];
    }
    if (this.contractParam != null && this.service != null && !(this.service instanceof ViolationSourceService)) {
      if (this.service instanceof ContractService) {
        params['id[in]'] = this.contractParam;
      } else if (this.service instanceof UserService) {
        if (params.contractsGroup != null) {
          params.contractsGroup = this.contractParam;
        }
        if (params.contractsCompany != null ) {
          params.contractsCompany = this.contractParam;
        }
      } else {
        params['contractId[in]'] = `[${this.contractParam.join(',')}]`;
      }
    }
    const currentUser = this.storageService.get(StorageKey.currentUser);
    if (this.permission && !currentUser.superUser) {
      const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
      let contractIds = [];
      if (this.contractParam != null) {
        contractIds = currentPermissions &&
        currentPermissions.filter(contractPermission =>
          this.contractParam.includes(contractPermission.contractId) && contractPermission.actionIds.indexOf(this.permission) >= 0)
        .map(m => m.contractId);
      } else {
        contractIds = currentPermissions &&
          currentPermissions.filter(contractPermission =>
            contractPermission.actionIds.indexOf(this.permission) >= 0).map(m => m.contractId);
      }
      this.params['contractId[in]'] = `[${contractIds.join(',')}]`;
    }
    return params;
  }

  getDeleteEvent(event) {
    const index = this.allItems.findIndex(i => i.id === event);
    this.allItems.splice(index, 1);
    this.setItems();
  }

  query() {
    if (this.queryPromise == null) {
      this.queryPromise = this.queryFunc().then(() => {
        this.queryPromise = null;
      });
    }
    return this.queryPromise;
  }

  async queryFunc() {
    if (this.service != null && this.nextUrl !== null) { // nextUrls === null means that there is no nextPage
      this.isLoading = true;
      this.loading.emit(true);
      const contractParams = await this.getContractParams();
      const params = Object.assign({}, this.params, {
        limit: this.perPage * this.maxResults
      }, contractParams);
      this.service.list(this.nextUrl || params).then(data => {
        const pagination = data;
        if (pagination.result) {
          this.allItems = pagination.result;
          this.rows = pagination.result;
          this.setItems();
        } else {
          this.rows = [];
        }
        if (this.allItems.length === 0) {
          this.noRegister = true;
        } else {
          this.noRegister = false;
        }
        const end = this.perPage * this.selectedPage;
        this.nextUrl = pagination.next;
        this.setPagesArray();
        if (end > this.allItems.length || (this.endPage !== (this.allItems.length / this.perPage) && this.endPage <= 6)) {
          // return this.queryFunc();
        }
      })
        .catch(err => {
          if (err.status === 403) {
            this.alertService.show(new AlertItem('Unauthorized', AlertType.danger));
            this.router.navigate(['/']);
          }
        }).finally(() => {
          this.isLoading = false;
          this.loading.emit(false);
        });
    }
  }

  setPagesArray() {
    let maxPage = Number.MAX_SAFE_INTEGER;
    if (this.nextUrl == null) {
      maxPage = Math.ceil(this.allItems.length / this.perPage);
    }
    this.startPage = Math.max(this.selectedPage - 3, 1);
    this.endPage = Math.min(Math.max(this.selectedPage + 1, this.startPage + 3), maxPage);
    this.startPage = Math.min(this.startPage, Math.max(this.endPage - 3, 1));
    const newArray = [];
    for (let i = this.startPage; i <= this.endPage; i++) {
      if (this.rows && this.rows.length) {
        newArray.push(i);
      }
    }
    if (this.service && this.service.cacheKey) {
      this.pages = newArray;
      const perPageByCacheKey = {
        name: this.service.cacheKey,
        perPage: this.perPage
      };
      if (!this.perPageByCacheKey.some(page => page.name != null && page.name === perPageByCacheKey.name)) {
        this.perPageByCacheKey.push(perPageByCacheKey);
      } else {
        this.perPageByCacheKey = this.perPageByCacheKey.map(page => {
          if (page.name === this.service.cacheKey) {
            page.perPage = this.perPage;
          }
          return page;
        });
      }
      this.storageService.set('recordsNumberByListageCache', this.perPageByCacheKey);
    }
  }

  setPage(n) {
    this.selectedPage = n;
    this.setItems();
    this.setPagesArray();
  }

  async setItems() {
    let start = this.perPage * (this.selectedPage - 1);
    let end = this.perPage * this.selectedPage;

    if (this.allItems.length < end) {
      await this.query();
      if (this.allItems.length < start) {
        this.selectedPage = Math.ceil(this.allItems.length / this.perPage);
        start = this.perPage * (this.selectedPage - 1);
        end = this.perPage * this.selectedPage;
      }
    }
    this.items.emit(this.allItems.slice(start, end));
  }

  next() {
    if (this.selectedPage < this.endPage) {
      this.setPage(this.selectedPage + 1);
    }
  }
  previous() {
    if (this.selectedPage > 1) {
      this.setPage(this.selectedPage - 1);
    }
  }
}
