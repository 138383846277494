<app-enum-i18n [input]="situationsIds" (output)="setResources($event)"></app-enum-i18n>
<div class="row">
  <div  class="col-1">
    <button class="btn btn-icon btn-fade-secondary text-secondary mr-3" (click)="getBackLink()">
      <i class="material-icons align-button-back">arrow_back</i>
      <span i18n>Voltar</span>
    </button>
  </div>
  <div  class="col-8 ml-3">
    <p class="page-title__link-back"><a routerLink="/violations/violations-report" i18n>Todos os contratos</a></p>
    <div class="page-title d-flex align-items-start">
      <h2 i18n>{{ contractCode }} <span *ngIf="contractName">- {{ contractName }}</span></h2>
    </div>
  </div>
  <div class="col-2" *ngIf="!hideExportCsvButton" id="hideExportCsvButton">
    <button class="btn btn-icon btn-fade-secondary" (click)="exportCsv($event)">
      <i class="material-icons">file_download</i>
      Exportar
    </button>
  </div>
</div>
<ng-container *ngIf="loading; else loadingBlock">
  <div [ngClass]="{'d-none': pageLevel === 2}">
    <app-violations-report-filter></app-violations-report-filter>
  </div>

  <div class="table-flex mb-4">
    <div class="table-flex__header" [ngClass]="{'d-none': pageLevel === 2}">
      <div class="w-25">
        <app-violations-report-period></app-violations-report-period>
      </div>
      <p class="w-10" i18n>Enquadramento</p>
      <p class="w-15" i18n>Qtd<br /> em processamento</p>
      <p class="w-15" i18n>Qtd<br /> válidas</p>
      <p class="w-15" i18n>Qtd<br /> inválidas</p>
      <p class="w-10" i18n>Qtd<br /> total</p>
      <p class="w-10"></p>
    </div>

    <router-outlet *ngIf="!noViolations"></router-outlet>

    <div *ngIf="noViolations">
      <p class="py-5 text-center text-secondary" i18n>Não há infrações</p>
    </div>
  </div>

</ng-container>


<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>
