import { Component, OnInit } from '@angular/core';
import { ModalContent } from 'src/app/core/interface';
import { AlertItem, AlertType, User, UserGroup, Contract } from 'src/app/core/models';
import {
  AlertService, ModalService, UserService, ContractService, StorageKey, StorageService,
  UserGroupService,
  CompanyService
} from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { hasPermission } from 'src/app/core/utils/permission';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.sass']
})
export class UserViewComponent extends BaseModal implements OnInit, ModalContent {
  public userGroups: Array<UserGroup> = [];
  public isValid = false;
  public user: User = new User();
  public initialState: User;
  public haveRole: boolean;
  public userGroupList: { [params: string]: any } = {};
  public companyList: { [params: string]: any } = {};
  public contractIds = [];

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private contractService: ContractService,
    private userGroupService: UserGroupService,
    private storageService: StorageService,
    modalService: ModalService,
    private companyService: CompanyService,
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.user = this.initialState as User || new User();
    this.haveRole = this.user.role != null ? true : false;
    if (this.user.groupIds != null) {
      this.user.groupIds.forEach(userGroupId => {
        this.userGroupService.getById(userGroupId).then(userGroup => {
          if (this.userGroupList[userGroup.id] == null) {
            this.contractService.getById(userGroup.contractId).then(contract => {
              this.contractIds.push(contract.id);
              this.userGroupList[userGroup.id] = {
                id: userGroup.id,
                prettyName: userGroup.name + ` (${contract.code} - ${contract.name})`
              };
            });
          }
        });
      });
    }
    this.companyService.getById(this.user.companyId).then(company => {
      if (this.companyList[company.id] == null) {
        this.companyList[company.id] = company;
      }
    });
  }

  hasPermissionContract() {
    if (this.user.superUser) {
      return;
    }
    if (hasPermission('UpdateUser', this.storageService, this.contractIds)) {
      return;
    }
    return true;
  }

  get lastState() {
    return {};
  }

  public async onAllSubmited() {
  }

  onClose() {
    this.modalService.close();
  }

  sendReinvite() {
    this.userService.reinvite(this.user).then(res => {
      this.alertService.show(new AlertItem('EmailSent', AlertType.success));
    });
  }

}
