import { Injectable } from '@angular/core';
import { ViolationStepReport } from '../models/ViolationStepReport';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ViolationStepReportService extends BaseApiService<ViolationStepReport> {
  get cacheKey(): StorageKey {
    return StorageKey.violationStepReportServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/report/violationStepReport`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  protected parse(obj) {
    return ViolationStepReport.parse(obj);
  }

  protected unparse(obj) {
    return ViolationStepReport.unparse(obj);
  }

}
