import { Input, ViewChild, ElementRef, OnInit, OnDestroy, Injectable } from '@angular/core';

@Injectable()
export class BasePanel implements OnInit, OnDestroy {
  @ViewChild('panel', { static: true }) public panel: ElementRef;
  private _isShowing = false;
  @Input() public set isShowing(value) {
    if (this._isShowing !== value) {
      this._isShowing = value;
      if (this._isShowing) {
        this.open();
      } else {
        this.close();
      }
    }
  }
  public get isShowing() {
    return this._isShowing;
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    const element = (this.panel.nativeElement as HTMLElement);
    if (!this.isShowing) {
      element.style.height = 0 + 'px';
    } else {
      element.style.height = 'auto';
    }
    element.addEventListener('transitionend', this.resetHeight.bind(this));
  }

  resetHeight() {
    if (this.isShowing) {
      const element = (this.panel.nativeElement as HTMLElement);
      element.style.height = 'auto';
    }
  }

  ngOnDestroy() {
    const element = (this.panel.nativeElement as HTMLElement);
    element.removeEventListener('transitionend', this.resetHeight);
  }

  toggle() {
    this.isShowing = !this.isShowing;
  }

  open() {
    const element = (this.panel.nativeElement as HTMLElement);
    const sectionHeight = element.scrollHeight;
    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 'px';
  }

  close() {
    const element = (this.panel.nativeElement as HTMLElement);
    const sectionHeight = element.scrollHeight;
    const elementTransition = element.style.transition;
    requestAnimationFrame(() => {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;
      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(() => {
        element.style.height = 0 + 'px';
      });
    });
  }
}
