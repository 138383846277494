import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { ViolationReportService } from 'src/app/core/services';
import { Regulation } from 'src/app/core/models';
import { ViolationContractCounterService } from 'src/app/core/services/violationContractCounter.service';

@Component({
  selector: 'app-violations-report-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ViolationsReportListComponent implements OnInit, OnDestroy, AfterViewChecked {
  public loading: boolean;
  public regulations: Regulation[] = [];
  public contractsViolations: any[] = [];
  public contractsList: any[] = [];
  public dataViolationsSub: Subscription;
  public regulationsById: any = {};
  public items: any[] = [];
  public searchValue = '';
  public allCollapse: boolean;
  private searchChanged: Subject<string> = new Subject<string>();

  constructor(
    private violationReportService: ViolationReportService,
    private violationConractCounterService: ViolationContractCounterService,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    this.searchChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  ngOnInit() {
    this.dataViolationsSub = this.violationReportService.getDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.violationConractCounterService.getAll({ limit: '10' })
          .then(violationContracts => {
            if (violationContracts.length > 0) {
              this.contractsViolations = [];
              for (const violationContract of violationContracts) {
                const objContract: any = {};
                const contract = data.contracts.find(c => c.contractId === parseInt(violationContract.id, 10));
                objContract.id = (contract && contract.contractId);
                objContract.code = (contract && contract.contractCode);
                objContract.name = (contract && contract.contractName) || violationContract.id;
                objContract.collapse = false;
                objContract.total = 0;
                objContract.regulations = [];
                const fields = Object.keys(violationContract);

                for (const field of fields) {
                  if (parseInt(field, 10) > 0) {
                    const regulation = data.regulations.find(r => r.id === parseInt(field, 10));
                    if (regulation && regulation.id != null) {
                      this.regulationsById[regulation.id] = regulation;
                      const objRegulation: any = {};
                      objRegulation.id = regulation.id;
                      objRegulation.code = regulation.code;
                      objRegulation.qtd = violationContract[field];
                      objContract.total += objRegulation.qtd;
                      if (objRegulation.qtd > 0) {
                        objContract.regulations.push(objRegulation);
                      }
                    }
                  }
                }

                objContract.regulations.sort((a, b) => (a.qtd > b.qtd) ? -1 : 1);
                this.contractsViolations.push(objContract);
                this.contractsViolations.sort((a, b) => a.id - b.id);
                this.contractsList = this.contractsViolations;

                this.loading = true;
              }
            }
          });
      });
  }

  ngAfterViewChecked(): void {
    this.changeDetectionRef.detectChanges();
  }

  toggleCollapse(item) {
    item.collapse = !item.collapse;
  }

  toggleallCollapse() {
    if (!this.allCollapse) {
      this.allCollapse = true;
      this.contractsViolations.map(item => item.collapse = true);
    } else {
      this.allCollapse = false;
      this.contractsViolations.map(item => item.collapse = false);
    }
  }

  setItems(list) {
    this.items = list;
  }

  getTotal() {
    return this.contractsViolations.reduce((acc, curr) => acc + curr.total, 0);
  }

  searchKey(text: string) {
    this.searchChanged.next(text);
  }

  handleSearch() {
    const search = this.searchValue;
    if (search != null && search !== '') {
      this.contractsViolations = this.contractsList.filter(
        item => item.name.toLowerCase().includes(search.toLowerCase()) ||
        String(item.code).includes(search)
      );
    } else {
      this.contractsViolations = this.contractsList;
    }
  }

  ngOnDestroy() {
    if (this.dataViolationsSub) {
      this.dataViolationsSub.unsubscribe();
    }
  }
}
