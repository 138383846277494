import { Component, OnInit } from '@angular/core';
import { ModalContent } from 'src/app/core/interface';
import { ModalService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { RegulationService } from 'src/app/core/services/regulation.service';
import { Regulation } from 'src/app/core/models';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-violation-step-view-modal',
  templateUrl: './violation-step-view-modal.component.html',
  styleUrls: ['./violation-step-view-modal.component.sass']
})

export class ViolationStepViewModalComponent extends BaseModal implements OnInit, ModalContent {
  public isValid = false;
  public violationSteps: any = {};
  public columns = [];
  public columnsI18n = [];
  public regulation = new Regulation();
  public tableColumns = [];

  constructor(
    modalService: ModalService,
    private regulationService: RegulationService
  ) {
    super(modalService);
  }

  ngOnInit() {
    if (this.initialState != null) {
      this.violationSteps = this.initialState.violationSteps || [];
      this.columns = this.initialState.columnStep || [];
      this.loadViolation(this.violationSteps[0]);
      this.regulationService.getById(this.initialState.regulationId).then(regulation => {
        this.regulation = regulation;
      });
    }
  }

  onClose() {
    this.modalService.close();
  }

  public async onAllSubmited() { }

  loadViolation(violation) {
    const tableColumns = [];
    const keys = Object.entries(violation);

    this.columns.map((step, index) => {
      tableColumns[index] = {};
      tableColumns[index].step = step.step;
      tableColumns[index].stepName = step.stepName;
      const datesOld = [];
      const datesNew = [];
      if (violation[step.step] && violation[step.step].oldestViolationAt) {
        if (!datesOld.includes(violation[step.step].oldestViolationAt)) {
          datesOld.push(new Date(violation[step.step].oldestViolationAt));
          tableColumns[index].dateOldest = new Date(Math.min.apply(null, datesOld));
        }
      }
      if (violation[step.step] && violation[step.step].newestViolationAt) {
        if (!datesOld.includes(violation[step.step].newestViolationAt)) {
          datesNew.push(new Date(violation[step.step].newestViolationAt));
          tableColumns[index].dateNewest = new Date(Math.max.apply(null, datesNew));
        }
      }
    });

    this.tableColumns = tableColumns;
  }

  get lastState() {
    return {};
  }
}
