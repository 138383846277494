<div class="row" style="margin: -48px 0 0 0; height: calc(100vh - 72px); width: 100%;">
  <div class="card col-4 p-2" style="height: 100%; overflow: auto;">
    <form [formGroup]="searchForm" (submit)="handleSearch()">
      <div class="input-group">
        <div class="input-group-append">
          <a class="btn btn-light btn-icon" routerLink="/spots">
            <i class="material-icons" style="margin: 0">arrow_back</i>
          </a>
        </div>
        <input i18n-label i18n-placeholder
          autofocus
          type="text"
          class="form-control form-search"
          formControlName="search"
          placeholder="Buscar registro"
          (ngModelChange)="searchKey($event)"
          autocomplete="off" />
        <div class="input-group-append">
          <button type="button"
                  class="btn btn-primary btn-icon"
                  [disabled]="!isDataLoaded"
                  (click)="toggleMenu()">
            <i class="material-icons" style="margin: 0">filter_alt</i>
          </button>
        </div>
      </div>
      <div style="position: relative;">
        <div class="filter-container" [ngClass]="{'open': dropMenuActive}">
          <div class="row mt-2">
            <div class="col">
              <div class="form-group">
                <label i18n>País: </label>
                <select tabindex="4" class="form-control" formControlName="region"
                  (ngModelChange)="loadStates($event)">
                  <option *ngFor="let region of regions" [value]="region.name">
                    {{region.name}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <app-enum-i18n [input]="roadTypes" (output)="roadTypesI18n=$event"></app-enum-i18n>
                <app-input i18n-placeholder="@@SpotListRoadTypePlaceholder" i18n-label="@@SpotListRoadTypeLabel"
                  type="multiselect" [list]="roadTypesI18n" label="Tipo da Via" formControlName="roadType"
                  selectText="value" selectValue="id" placeholder="Todos">
                </app-input>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <app-enum-i18n [input]="operationModes" (output)="operationModesI18n=$event"></app-enum-i18n>
                <app-input i18n-placeholder i18n-label
                          type="multiselect"
                          [list]="operationModesI18n"
                          label="Modo de operação"
                          formControlName="operationModes"
                          selectText="value"
                          selectValue="id"
                          placeholder="Todos">
                </app-input>
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <app-enum-i18n [input]="equipmentFeatures" (output)="equipmentFeaturesI18n=$event"></app-enum-i18n>
                <app-input i18n-placeholder i18n-label
                          type="multiselect"
                          [list]="equipmentFeaturesI18n"
                          label="Características"
                          formControlName="equipmentFeatures"
                          selectText="value"
                          selectValue="id"
                          placeholder="Todos">
                </app-input>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <app-input i18n-placeholder i18n-label type="select" [list]="states" label="Estado"
                  formControlName="state" selectText="name" selectValue="id" placeholder="Todos"
                  (ngModelChange)="loadCities($event)">
                </app-input>
              </div>
            </div>

            <div class="col" *ngIf="showCities">
              <div class="form-group">
                <app-input i18n-placeholder i18n-label type="select" [list]="cities" label="Cidade"
                  formControlName="city" selectText="name" selectValue="id" placeholder="Todos">
                </app-input>
              </div>
            </div>
          </div>

          <div>
            <button i18n type="submit" class="btn btn-info btn-block" (click)="toggleMenu()" data-dismiss="modal">
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="!isLoading && !noRegister; else loadingBlock" >
      <mat-list *ngIf="!selected; else infoBlock">
        <mat-action-list dense *ngFor="let spot of spots" (click)="zoomIntoSpotList(spot)" >
          <mat-list-item [ngClass]="{'selected': selected === spot}">
            <mat-divider></mat-divider>
            <i style="padding: 6px;" matListAvatar class="material-icons">place</i>
            <p i18n matLine style="font-weight: 700;">{{spot.contractId}} Ponto: {{spot.code}}</p>
            <label matLine>{{spot.description.toUpperCase()}}</label>
          </mat-list-item>
        </mat-action-list>
      </mat-list>
    </div>

    <ng-template #infoBlock>
      <mat-expansion-panel class="mb-2 mt-2" expanded>
        <div class="row pt-4">
          <h5 class="col" i18n><b>{{selected.contractId}} - Contrato | Ponto: {{selected.code}}</b></h5>
          <button (click)="zoomIntoSpotList(selected)"
            type="button"
            class="btn btn-icon"
            style="position: absolute;  right: 10px;  top:10px; z-index: 1;">
            <i class="material-icons" style="margin: 0;">close</i>
          </button>
        </div>
        <div class="row">
          <p i18n class="col-4">Código do Cliente<br/> <b>{{selected.code}}</b> </p>
          <p i18n class="col-4">Altura máxima <br/> <b>{{selected.maxHeight}}</b> </p>
          <p i18n class="col-4">Tipo de Via <br/> <b>{{selected.roadType | ucFirst}}</b> </p>
        </div>
        <div class="row">
          <p i18n class="col-8">Endereço<br/> <b>{{selected.address.street}}</b> </p>
          <p i18n class="col-4">Nº/KM <br/> <b>{{selected.address.number}}</b> </p>
        </div>
        <div class="row">
          <p i18n class="col-4">Latitude<br/> <b>{{selected.coordinates.latitude}}</b> </p>
          <p i18n class="col-4">Longitude <br/> <b>{{selected.coordinates.longitude}}</b> </p>
          <p i18n class="col-4">Cidade <br/> <b>{{selected.cityName}}</b> </p>
        </div>
        <div class="row mt-2">
          <div id="street-view"></div>
          <h6 i18n class="col-12 mt-4" style="display: flex; align-items: center">
            <i class="material-icons" style="width: 40px">place</i>
            {{selected.description}}
          </h6>
        </div>
      </mat-expansion-panel>
      <mat-accordion>
        <div *ngFor="let lid of selected.laneIds; first as isFirst">
          <mat-expansion-panel *ngIf="!!lanes[lid]" [expanded]="isFirst">
            <mat-expansion-panel-header [class]="lanes[lid].operationMode" i18n>
              Código: {{lanes[lid].prettyNames}}
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="row mt-4">
                <p i18n class="col-4">Código do Cliente<br/> <b>{{lanes[lid].clientCode}}</b> </p>
                <p i18n class="col-4">Modo de Operação <br/> <b>{{operationModesLabel(lanes[lid].operationMode)}}</b> </p>
                <p i18n class="col-4">Entre Faixas <br/> <b>{{lanes[lid].isBetweenLane ? 'Sim' : 'Não'}}</b> </p>
              </div>
              <div class="row">
                <p i18n class="col-4">Sentido <br/> <b>{{lanes[lid].direction}}</b> </p>
                <p i18n class="col-4">Vel. da via - Leve <br/> <b>{{lanes[lid].speeds.regularVehicles}} km/h</b> </p>
                <p i18n class="col-4">Vel. da via - Pesado <br/> <b>{{lanes[lid].speeds.heavyVehicles}} km/h</b> </p>
              </div>
              <div class="row">
                <p i18n class="col-12">Descrição do cliente <br/> <b>{{lanes[lid].clientDescription}}</b> </p>
              </div>
              <div *ngFor="let eid of selected.equipmentIds">
                <div class="row">
                  <p i18n class="col-4">Equipamento <br/> <b>{{equipments[eid].serialNumber}}</b> </p>
                  <p i18n class="col-4">Tipo <br/> <b>{{ laneTypesLabel(equipmentModels[ equipments[eid].equipmentModelId ]?.type) }}</b> </p>
                  <p i18n class="col-4">Modelo <br/> <b>{{ equipmentModels[ equipments[eid].equipmentModelId ]?.name }}</b> </p>
                </div>
                <p>Características</p>
                <div class="info-icons" i18n>
                  <i class="material-icons inline" [ngClass]="{
                    'checked': equipments[eid].features?.ocr?.enabled,
                    'unchecked': !equipments[eid].features?.ocr?.enabled
                  }"></i>
                  OCR
                </div>
                <div class="info-icons" i18n>
                  <i class="material-icons inline" [ngClass]="{
                    'checked': equipments[eid].features?.classification?.enabled,
                    'unchecked': !equipments[eid].features?.classification?.enabled
                  }"></i>
                  Classificação veícular
                </div>
                <div class="info-icons" i18n>
                  <i class="material-icons inline" [ngClass]="{
                    'checked': equipments[eid].features?.trafficLight?.enabled,
                    'unchecked': !equipments[eid].features?.trafficLight?.enabled
                  }"></i>
                  Semáforo
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </ng-template>
  </div>
  <div class="col-8" style="position: relative;" *ngIf="!isLoading && !noRegister">
    <div class="map-container">
      <agm-map
        [latitude]="defaultMapState.latitude"
        [longitude]="defaultMapState.longitude"
        [zoom]="defaultMapState.zoom">
        <agm-marker
          *ngFor="let spot of spots"
          [markerClickable]="true"
          (markerClick)="zoomIntoSpotList(spot)"
          [label]="spot.isOcr ? 'OCR' : ''"
          [title]="spot.title"
          [latitude]="spot.coordinates.latitude"
          [longitude]="spot.coordinates.longitude">
        </agm-marker>
      </agm-map>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner" *ngIf="isLoading">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <p class="text-center text-secondary" *ngIf="noRegister">Não há dados encontrados.</p>
  </div>
</ng-template>
