import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { User, AlertItem, AlertType } from 'src/app/core/models';
import { AlertService } from 'src/app/core/services';

@Component({
  selector: 'app-pre-process-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class PreProcessDashboardComponent implements OnInit {
  public currentUser: Observable<User>;
  public layer = 'root';

  constructor(
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const routeCurrent = this.route.snapshot.url[0];
    if (routeCurrent != null) {
      this.layer = routeCurrent.path;
    } else {
      this.layer = 'root';
    }
  }

  unavailable() {
    this.alertService.show(new AlertItem('ModuleUnavailable', AlertType.info));
  }
}
