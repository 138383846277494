<app-enum-i18n [input]="resourcesToBeTranslated" (output)="setResources($event)"></app-enum-i18n>

<div class="container mb-5" #container (window:resize)="onResize()">
  <div class="page-title">
    <p class="page-title__link-back" i18n><a routerLink="/pre-process">Pré-processamento</a></p>
    <h2 i18n>Mapa de Etapas</h2>
  </div>

  <ul class="nav nav-pills pb-5">
    <li class="nav-item"><a class="nav-link active" i18n>Infrações</a></li>
    <li class="nav-item"><a class="nav-link" routerLink='' (click)="routerLotStep()" i18n>Lotes</a></li>
  </ul>

  <div *ngIf="loading === null; else loadingBlock">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="chart-item">
              <h5 i18n>Infrações por enquadramento</h5>
              <app-chart class="chart" [type]="barType" [items]=chartItems [hasLegend]="true"></app-chart>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="chart-item">
              <h5 i18n>Infrações por etapa</h5>
              <app-chart class="chart" [type]="pieType" [items]="chartItems"></app-chart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="table-responsive table-hover table-block">
        <table id="table-data" class="table bg-white">
          <thead class="bg-header-table">
            <tr>
              <th class="no-sort"></th>
              <th i18n class="text-center">Enquadramento</th>
              <th *ngFor="let column of tableColumns" class="text-center" i18n>{{ column.stepName }}</th>
              <th i18n class="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of tableRows; index as i" (click)="openViolationStepView(row.id)">
              <td class="text-center">
                <i class="material-icons text-warning" data-toggle="tooltip" data-placement="Top" title="Ver detalhes"
                  *ngIf="row.ageAlert">warning</i>
                <i class="material-icons" data-toggle="tooltip" data-placement="Top" title="Ver detalhes"
                  *ngIf="!row.ageAlert">info_outline</i>
              </td>
              <td class="text-center">{{ row.violation }}</td>
              <td class="text-center" *ngFor="let column of tableColumns" i18n>
                {{ (column && column.violations[i] && column.violations[i].value) || 0}}
              </td>
              <td class="text-center">{{ row.total }}</td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td></td>
              <td i18n class="text-center">Total por etapas</td>
              <td class="text-center" *ngFor="let column of tableColumns">
                {{ column && column.violationsTotal }}
              </td>
              <td class="text-center">{{ showAllValues() }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>
