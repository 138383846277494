/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component, OnInit, Input,
  EventEmitter, Output, SimpleChanges,
  SimpleChange, OnChanges, OnDestroy } from '@angular/core';
import { IApiService } from 'src/app/core/interface';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { ContractGlobalService, AlertService, ContractService, UserService, ActivityService } from 'src/app/core/services';
import { AlertItem, AlertType } from 'src/app/core/models';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ReleaseNotesService } from 'src/app/core/services/releaseNotes.service';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.sass']
})
export class InfiniteScrollComponent implements OnInit, OnChanges, OnDestroy {
  _perPage = 6;
  @Input() service: IApiService<any>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() set params(params: {} | Promise<any>) {
    this.getParamsEvent(params);
  }
  get params() {
    return this._params;
  }
  get perPage() {
    return this._perPage;
  }
  @Input() set perPage(value) {
    this._perPage = value;
    this.loadItems();
  }
  @Input() container: HTMLElement;
  @Output() items: EventEmitter<Array<any>> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() clear = new EventEmitter();

  public isLoading = false;
  public noRegister = false;
  public contracts = '';
  public contractsSubscription: Subscription;

  private nextUrl = undefined;
  private queryPromise = null;
  private _params = {};
  private allItems = [];

  private contractParam: string;

  constructor(
    private contractsGlobalService: ContractGlobalService,
    private alertService: AlertService,
    private router: Router
  ) {
    if (this.contractsGlobalService.contracts != null && this.contractsGlobalService.contracts.length > 0) {
      const contracts =  this.contractsGlobalService.contracts;
      this.contractParam = `[${contracts.join(',')}]`;
    } else {
      this.contractParam = null;
    }
  }

  ngOnInit() {
    if (this.container == null) {
      window.addEventListener('scroll', this.scroll.bind(this), true);
    } else {
      this.container.addEventListener('scroll', this.scroll.bind(this), true);
    }
    this.contractsSubscription = this.contractsGlobalService.contractEvent
      .subscribe(contracts => {
        if (contracts && contracts.length > 0) {
          this.contractParam = `[${contracts.join(',')}]`;
        } else {
          this.contractParam = null;
        }
        this.nextUrl = undefined;
        this.clear.emit();
        this.loadItems();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const params: SimpleChange = changes.paramsEvent;
    if (params != null && !isEqual(params.currentValue, params.previousValue)) {
      if (params.previousValue != null) {
        (params.previousValue as EventEmitter<any>).unsubscribe();
      }
      if (params.currentValue != null) {
        (params.currentValue as EventEmitter<any>).subscribe(this.getParamsEvent.bind(this));
      }
    }
  }

  ngOnDestroy() {
    this.contractsSubscription.unsubscribe();
    window.removeEventListener('scroll', this.scroll.bind(this), true);
  }

  scroll() {
    let total = 0;
    let offset = 0;
    if (this.container == null) {
      total = document.documentElement.scrollHeight - window.innerHeight;
      offset = window.pageYOffset;
    } else {
      total = this.container.scrollHeight - this.container.clientHeight;
      offset = this.container.scrollTop;
    }
    if (total === 0 || offset + 10 > total) {
      this.loadItems();
    }
  }

  async getContractParams() {
    const params = (_.cloneDeep(await this.params)) || {};
    if (this.contractParam != null && this.service != null) {
      if (this.service instanceof ContractService) {
        params['id[in]'] = this.contractParam;
      } else if (this.service instanceof UserService) {
        if (params.contractsGroup != null) {
          params.contractsGroup = this.contractParam;
        }
        if (params.contractsCompany != null ) {
          params.contractsCompany = this.contractParam;
        }
      } else if (!(this.service instanceof ActivityService)) {
       if (!(this.service instanceof ReleaseNotesService)){
        params['contractId[in]'] = this.contractParam;
       }
      } else if (this.contractsGlobalService.contracts !== null) {
        params.contractId = this.contractsGlobalService.contracts;
      }
    }
    return params;
  }

  async getParamsEvent(event) {
    this._params = await event;
    this.nextUrl = undefined;
    this.allItems = [];
    this.loadItems();
  }

  query() {
    if (this.queryPromise == null) {
      return this.queryPromise = this.queryFunc().then(data => {
        if (data != null) {
          this.items.emit(data);
        }
        this.queryPromise = null;
        return data;
      });
    }
    return this.queryPromise;
  }

  async queryFunc() {
    if (this.service != null && this.nextUrl !== null) { // nextUrls === null means that there is no nextPage
      this.loading.emit(true);
      this.isLoading = true;
      const contractParams = await this.getContractParams();
      const params = Object.assign({}, this.params, {
        limit: this.perPage
      }, contractParams);
      let pagination;
      try {
        pagination = await this.service.list(this.nextUrl || params) || [];
        this.allItems = this.allItems.concat(pagination.result);
        this.noRegister = this.allItems.length === 0;
      } catch (err) {
        if (err.status === 403) {
          this.alertService.show(new AlertItem('Unauthorized', AlertType.danger));
          this.router.navigate(['/']);
        } else if (err.status === 500) {
          this.alertService.show(new AlertItem('InternalServerError', AlertType.danger));
        }
        this.nextUrl = null;
        this.loading.emit(false);
        this.isLoading = false;
        return [];
      }
      this.nextUrl = pagination.next;
      this.loading.emit(false);
      this.isLoading = false;
      return pagination.result;
    } else {
      return null;
    }
  }

  async loadItems() {
    const newItems = await this.query();
    if (newItems != null && this.nextUrl !== null) {
      setTimeout(this.scroll.bind(this), 100);
    }
  }
}
