/* eslint-disable id-blacklist */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  AlertItem, AlertType, ComponentModal, Lane, MessageModal,
  Region, RoadType, Spot, User, Contract, Timezone
} from 'src/app/core/models';
import {
  AlertService, ContractService, ModalService, SpotService, StorageKey,
  StorageService, ContractGlobalService, LaneService
} from 'src/app/core/services';
import { CityService } from 'src/app/core/services/city.service';
import { StateService } from 'src/app/core/services/state.service';
import { SpotMapModalComponent } from 'src/app/pages/spots/spot-map-modal/spot-map-modal.component';
import { BasePanel } from '../base-panel/base-panel';
import * as _ from 'lodash';
import { RegionService } from 'src/app/core/services/region.service';
import { State } from 'src/app/core/models/State';

@Component({
  selector: 'app-spot-panel',
  templateUrl: './spot-panel.component.html',
  styleUrls: ['./spot-panel.component.sass']
})
export class SpotPanelComponent extends BasePanel implements OnInit, OnDestroy {
  @Input() public spotId: string = null;
  @ViewChild('panel', { static: true }) panel: ElementRef;
  @Output() public updateSpotList = new EventEmitter();
  @Input() toggleSpot: Observable<any>;
  public isEdit: boolean;
  public isNewSpot = false;
  public spot: Spot = new Spot();
  public spotForm: FormGroup;
  public roadTypes = Object.keys(RoadType);
  public cities: any = {};
  public contracts: Array<Contract> = [];
  public currentCity: any = {};
  public currentState: any = {};
  public states: Array<State> = [];
  public regions: Array<Region> = [];
  public currentRegionName = 'Brasil';
  public currentRegionId;
  public isFormValid = false;
  public invalidLanes = [];
  public awaitSaveLanes = 0;
  public lanesWithError = 0;
  public lanes: Array<{ lane: Lane; laneId: string; isValid: boolean }> = [];
  public pristine = true;
  public roadType = RoadType;
  public roadTypesI18n = [];
  public currentRoadType = '';
  public contractGlobal = null;
  public contractByIds: { [key: string]: Contract } = {};
  public user: User;
  public promise: Promise<any>;
  public saveLaneResolve = null;
  public saveLaneReject = null;
  public allTimezones = Object.keys(Timezone).map(item => ({ id: item, value: item }));
  public removedLanes = [];
  public regionByIds: { [key: string]: Region } = {};
  public stateByIds: { [key: string]: State } = {};
  public region: Region;
  public state: State;
  public contractId = null;
  public contractCode = '';
  public contract: Contract = null;

  private saveLaneEvent = new Subject<{ spotId: string; source: any }>();
  private _subscriptions = [];

  constructor(
    private formBuilder: FormBuilder,
    private contractService: ContractService,
    private spotService: SpotService,
    private laneService: LaneService,
    private stateService: StateService,
    private cityService: CityService,
    private modalService: ModalService,
    private alertService: AlertService,
    private storageService: StorageService,
    private contractGlobalService: ContractGlobalService,
    private regionService: RegionService,
  ) {
    super();
    if (this.contractGlobalService.contracts != null) {
      if (this.contractGlobalService.contracts.length === 1) {
        this.contractGlobal = this.contractGlobalService.contracts[0];
        this.setRegion(this.contractGlobal);
      }
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.storageService.get(StorageKey.currentUser);
    if (this.spotId != null) {
      this.spotService.getById(this.spotId).then(spot => {
        this.spot = spot;
        this.fillLanes();
      });
    } else {
      this.isEdit = true;
      this.isNewSpot = true;
      this.spot.address = {
        street: null,
        number: null,
        zipCode: null,
        landmarks: null,
        isKm: null,
      };
      this.spot.coordinates = {
        latitude: null,
        longitude: null
      };
      this.spot.laneIds = [];
    }
    this.isEdit = this.isNewSpot;
    if (this.toggleSpot != null) {
      this._subscriptions.push(this.toggleSpot.subscribe(isShowing => {
        if (isShowing !== this.isShowing) {
          this.toggle();
        }
      }));
      if (this.isNewSpot) {
        this.toggle();
      }
    }
    const user = this.storageService.get(StorageKey.currentUser);
    const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
    const contractPermissions = currentPermissions && currentPermissions.filter(permissions => {
      if (permissions.actionIds.includes('CreateSpot')) {
        return permissions.contractId;
      }
    });
    this.contractService.getAll().then(data => {
      if (user.superUser) {
        this.contracts = data;
      } else {
        this.contracts = data.filter(contract => contractPermissions.find(c => c.contractId === contract.id));
      }
      data.forEach(contract => {
        this.contractByIds[contract.id] = contract;
        this.regionService.getById(contract.regionId).then(region => {
          this.regionByIds[contract.regionId] = region;
        });
      });
      this.regionService.getAll({ name: 'Brasil' }).then(res => {
        this.region = res[0];
        this.currentRegionId = this.region.id;
        this.stateService.getAll({ regionId: this.region.id }).then(states => {
          this.states = states;
          states.forEach(state => {
            this.stateByIds[state.id] = state;
          });
          this.createForm();
          this.setFormValues();
        }).catch(error => {
        });
      });
      this.regionService.getAll().then(res => {
        this.regions = res;
      });
    });
    this.createForm();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async fillLanes() {
    let uncached = 0;
    this.lanes = [];
    if (this.spot.laneIds) {
      for (const id of this.spot.laneIds) {
        if (this.laneService.getCache(id) == null) {
          uncached++;
        }
      }
      if (uncached > 1) {
        await this.laneService.getAll({
          spotId: this.spot.id
        });
      }
      this.spot.laneIds.forEach(laneId => {
        this.lanes.push({ laneId, lane: null, isValid: true });
      });
    }
  }

  createForm() {
    this.contractId = this.spot.contractId || this.contractGlobal;
    if (this.spot.stateId != null) {
      this.state = this.states.find(s => s.id === this.spot.stateId);
    }
    const regionId = this.spot.regionId || (this.region && this.region.id) || '';
    const maxHeight = isNaN(this.spot.maxHeight) ? 0 : Number(this.spot.maxHeight);
    this.spotForm = this.formBuilder.group({
      description: [this.spot.description, Validators.required],
      contractId: [this.contractByIds[this.contractId] && this.contractByIds[this.contractId].code || '', Validators.required],
      code: [this.spot.code, Validators.required],
      street: [this.spot.address && this.spot.address.street, Validators.required],
      addressNumber: [this.spot.address && this.spot.address.number],
      isKm: [this.spot.address && this.spot.address.isKm],
      roadType: [this.spot.roadType || '', Validators.required],
      latitude: [this.spot.coordinates && this.spot.coordinates.latitude],
      longitude: [this.spot.coordinates && this.spot.coordinates.longitude],
      landmarks: [this.spot.address && this.spot.address.landmarks],
      city: [this.spot.cityId || '', Validators.required],
      state: [this.spot.stateId || '', Validators.required],
      region: new FormControl(
        {
          value: (this.regionByIds && this.regionByIds[regionId] && this.regionByIds[regionId].name) || '', disabled: true
        }),
      zipCode: [this.spot.address && this.spot.address.zipCode, Validators.pattern(/^[0-9]{5}\-[0-9]{3}$/)],
      timezone: [this.spot.timezone, Validators.required],
      maxHeight: [maxHeight, Validators.pattern(/([0-9])/)]
    });
    this.spotForm.valueChanges.subscribe(() => {
      this.pristine = false;
      this.isFormValid = this.spotForm.valid;
    });
    this.spotForm.get('contractId').valueChanges.subscribe(value => {
      const contract = this.contractByIds[value];
      if (contract != null) {
        this.spotForm.patchValue({
          timezone: contract.timezone
        });
        this.contractCode = contract.code;
      }
    });
  }

  clearLatLng() {
    this.spotForm.patchValue({
      latitude: '',
      longitude: ''
    });
  }

  setRoadTypeI18n(roadTypes) {
    this.roadTypesI18n = roadTypes;
    const valueRoadType = roadTypes.find(roadType => roadType.id === this.spot.roadType);
    if (valueRoadType != null) {
      this.currentRoadType = valueRoadType.value;
    }
  }

  setFormValues() {
    const region = this.region.name;
    const regionId = this.spot.regionId || (this.region && this.region.id) || '';
    const contractId = this.spot.contractId || this.contractGlobal;
    this.contract = this.contracts.find(c => c.id === contractId);
    const maxHeight = isNaN(this.spot.maxHeight) ? 0 : Number(this.spot.maxHeight);
    if (this.spot.stateId != null) {
      this.state = this.states.find(s => s.id === this.spot.stateId);
    }
    this.spotForm.patchValue({
      description: this.spot.description,
      contractId,
      code: this.spot.code,
      street: this.spot.address && this.spot.address.street || '',
      addressNumber: this.spot.address && this.spot.address.number || 0,
      isKm: this.spot.address && this.spot.address.isKm || false,
      roadType: this.spot.roadType || '',
      latitude: this.spot.coordinates && this.spot.coordinates.latitude,
      longitude: this.spot.coordinates && this.spot.coordinates.longitude,
      landmarks: this.spot.address && this.spot.address.landmarks || '',
      city: this.spot.cityId || '',
      state: this.spot.stateId || '',
      region: (this.regionByIds && this.regionByIds[regionId] && this.regionByIds[regionId].name) || '',
      zipCode: this.spot.address && this.spot.address.zipCode || '',
      timezone: this.spot.timezone ||
        this.contractByIds[contractId] && this.contractByIds[contractId].timezone,
      maxHeight
    });
    const contract = this.contracts.find(c => c.id === this.spotForm.get('contractId').value);
    if (contract != null) {
      this.contractCode = contract.code;
    }
  }

  async confirmationModal() {
    let confirmation = null;
    await this.modalService.show(new MessageModal(
      `Código do ponto`, `Todas as infrações serão atualizadas com o novo código do ponto. Deseja confirmar alteração?`, true))
      .then(() => {
        confirmation = true;
      }).catch(error => {
        confirmation = false;
      });
    return confirmation;
  }

  async submit() {
    if (this.promise != null) {
      return this.promise;
    }
    this.awaitSaveLanes = 0;
    if (this.pristine) {
      if (this.lanes.length > 0 && this.spot.id != null) {
        this.saveLaneEvent.next({ spotId: this.spot.id, source: this });
        this.promise = new Promise((resolve, reject) => {
          this.saveLaneResolve = resolve;
          this.saveLaneReject = reject;
        });
      } else {
        this.isEdit = false;
        this.alertService.show(new AlertItem('SpotSaved', AlertType.success));
      }
      return;
    }
    if (this.spotId != null && this.spot.code !== this.spotForm.get('code').value) {
      const confirm = await this.confirmationModal();
      if (!confirm) {
        const form = this.spotForm.get('code');
        form.setValue(this.spot.code);
        return;
      }
    }
    const spot = new Spot();
    if (typeof this.spotForm.get('contractId').value === 'number') {
      this.contract = this.contracts.find(c => c.id === this.spotForm.get('contractId').value);
    }
    this.contractCode = this.contract.code;
    spot.contractId = this.contract.id;
    spot.coordinates = {
      latitude: null,
      longitude: null
    };
    spot.address = {
      street: this.spotForm.get('street').value,
      number: this.spotForm.get('addressNumber').value,
      isKm: this.spotForm.get('isKm').value,
      landmarks: this.spotForm.get('landmarks').value,
      zipCode: this.spotForm.get('zipCode').value
    };
    spot.description = this.spotForm.get('description').value;
    spot.code = this.spotForm.get('code').value;
    spot.roadType = this.spotForm.get('roadType').value;
    spot.coordinates.latitude = this.spotForm.get('latitude').value || '';
    spot.coordinates.longitude = this.spotForm.get('longitude').value || '';
    spot.cityId = this.spotForm.get('city').value;
    spot.stateId = this.spotForm.get('state').value;
    spot.regionId = this.region.id;
    spot.timezone = this.spotForm.get('timezone').value;
    spot.maxHeight = Number(this.spotForm.get('maxHeight').value);
    const city = this.cities.currentCities.find(c => c.id === spot.cityId);
    spot.cityName = city.name;
    if (spot.coordinates.latitude === '' || spot.coordinates.longitude === '') {
      delete spot.coordinates;
    }
    spot.laneIds = this.spot.laneIds;
    spot.equipmentIds = this.spot.equipmentIds;
    spot.modifiedAt = this.spot.modifiedAt;
    if (this.spot.id != null) {
      spot.id = this.spot.id;
      this.promise = this.spotService.update(spot)
        .then(res => {
          if (this.lanes.length > 0) {
            this.saveLaneEvent.next({ spotId: this.spot.id, source: this });
            return new Promise(async (resolve, reject) => {
              this.saveLaneResolve = resolve;
              this.saveLaneReject = reject;
            });
          }
          this.isEdit = false;
          this.alertService.show(new AlertItem('SpotSaved', AlertType.success));
          this.state = this.states.find(s => s.id === spot.stateId);
          this.spot = spot;
          this.setFormValues();
        }).catch(error => {
          this.alertService.show(new AlertItem('SpotSaveError', AlertType.danger));
          throw error;
        }).finally(() => {
          if (this.lanes.length === 0) {
            this.promise = null;
            this.spotService.getAll(null, true);
          }
        });
    } else {
      spot.contractId = this.spotForm.get('contractId').value;
      spot.equipmentIds = [];
      this.promise = this.spotService.create(spot)
        .then(newSpot => {
          this.alertService.show(new AlertItem('SpotSaved', AlertType.success));
          this.state = this.states.find(s => s.id === spot.stateId);
          this.spot = newSpot;
          this.setFormValues();
          this.setRoadTypeI18n(this.roadTypesI18n);
          if (this.lanes.length > 0) {
            this.saveLaneEvent.next({ spotId: newSpot.id, source: this });
            return new Promise(async (resolve, reject) => {
              this.saveLaneResolve = resolve;
              this.saveLaneReject = reject;
            });
          } else {
            this.isNewSpot = false;
            this.isEdit = false;
          }
        }).catch(error => {
          if (error.status === 409) {
            this.alertService.show(new AlertItem('SpotSaveConflict', AlertType.danger));
          } else {
            this.alertService.show(new AlertItem('SpotSaveError', AlertType.danger));
          }
          throw error;
        }).finally(() => {
          if (this.lanes.length === 0) {
            this.promise = null;
            this.spotService.getAll(null, true);
          }
        });
    }
  }

  clearEmptyLanes() {
    let count = this.lanes.length - 1;
    while (count >= 0) {
      if (this.spot.laneIds.indexOf(this.lanes[count].laneId) < 0) {
        this.lanes.splice(count, 1);
      }
      count--;
    }
  }

  editSpot() {
    this.isEdit = !this.isEdit;
    if (!this.isEdit) {
      this.clearEmptyLanes();
    }
    if (!this.spotForm.pristine) {
      this.setFormValues();
    }
    if (!this.isShowing) {
      this.toggle();
    }
    this.loadCities(this.spot.stateId);
  }

  setRegion(contractId) {
    this.contractService.getById(contractId).then(contract => {
      this.currentRegionId = contract.regionId;
    });
  }

  loadCities(stateId: any) {
    if (stateId == null || stateId === '') {
      return;
    }
    if (this.cities[stateId] != null) {
      this.cities.currentCities = this.cities[stateId];
      return;
    } else {
      this.cities.currentCities = [];
    }
    this.cityService.getAll({ regionId: this.region.id, stateId }).then(res => {
      this.cities[stateId] = res;
      this.cities.currentCities = _.sortBy(res, 'name');
    }).catch(error => {
      console.log(error);
    });
  }

  loadStates(currentRegionId: string) {
    this.stateService.getAll({ regionId: currentRegionId }).then(res => {
      this.states = res;
    }).catch(error => {
      console.log(error);
    });
  }

  newLane() {
    this.isFormValid = false;
    this.lanes.unshift({ laneId: null, lane: null, isValid: false });
  }

  loadLane(event, index) {
    if (event != null) {
      this.lanes[index].lane = event.lane;
    }
  }

  async refreshLane(event: any, index: number) {
    if (event == null) {
      return;
    }
    if (event.error != null) {
      this.lanesWithError++;
    } else {
      const laneId = event.laneId;
      if (event.deleted) {
        if (laneId != null) {
          this.spot.laneIds.splice(this.spot.laneIds.indexOf(laneId), 1);
        }
        this.removedLanes.push(laneId);
        this.lanes.splice(index, 1);
        if (this.lanes.length === 0) {
          this.isFormValid = true;
        }
        this.pristine = false;
      } else {
        if (event.source !== this) {
          return;
        }
        this.awaitSaveLanes++;
        this.lanes[index].laneId = laneId;
        if (this.awaitSaveLanes === this.lanes.length || this.lanes.length === 0) {
          if (!_.isEqual(this.spot.laneIds, this.lanes.map(l => l.laneId))) {
            this.lanes = [];
            await this.spotService.getById(this.spot.id, true); // load spot with new lanes values after cascade
            this.fillLanes();
          }
          if (this.isNewSpot) {
            this.isNewSpot = false;
            this.setFormValues();
          } else {
            this.alertService.show(new AlertItem('SpotSaved', AlertType.success));
          }
          this.isEdit = false;
          this.removedLanes = [];
        }
      }
    }
    if ((this.awaitSaveLanes + this.lanesWithError) === this.lanes.length || this.lanes.length === 0) {
      if (this.saveLaneReject != null && this.lanesWithError > 0) {
        this.saveLaneReject();
      } else if (this.saveLaneResolve != null) {
        this.saveLaneResolve();
      }
      this.promise = null;
      this.lanesWithError = 0;
      this.awaitSaveLanes = 0;
    }
  }

  delete(spotId: string) {
    this.spotService.delete(spotId).then(res => {
      this.alertService.show(new AlertItem('SpotDeleted', AlertType.success));
      this.updateSpotList.emit({ spotId: this.spot.id, deleted: true });
      this.spotService.getAll(null, true);
      return res;
    })
      .catch(error => {
        this.alertService.show(new AlertItem('SpotDeleteError', AlertType.danger));
      });
  }

  openDeleteModal() {
    this.modalService.show(new MessageModal('Remover Ponto', 'Deseja remover este Ponto?', true))
      .then(() => {
        this.delete(this.spot.id);
      }).catch(err => {
      });
  }

  openMapModal() {
    const coordinates = {
      latitude: this.spotForm.get('latitude').value,
      longitude: this.spotForm.get('longitude').value,
    };
    this.modalService.show(new ComponentModal(SpotMapModalComponent, coordinates))
      .catch(() => null)
      .then(async d => {
        const address = d && d.component && d.component.instance && d.component.instance.address;
        if (address == null) {
          return;
        }
        if (address.country) {
          this.currentRegionId = this.region.id;
          const region = this.regions.find(r => r.name === address.country);
          if (region != null) {
            this.currentRegionId = region.id;
          }
          await this.loadStates(this.currentRegionId);
        }
        if (address.state != null) {
          address.state = this.states.find(state => state.abbreviation === address.state);
          if (address.state != null) {
            address.state = address.state.id;
            if (address.city != null) {
              const cities = await this.cityService.getAll({ regionId: this.currentRegionId, stateId: address.state }).catch(() => { });
              this.cities[address.state] = cities;
              this.cities.currentCities = _.sortBy(cities, 'name');
              for (const key of Object.keys(this.cities)) {
                const currentCity = this.cities[key].find(city => city.name.toUpperCase() === address.city
                  .toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                if (currentCity != null) {
                  address.city = currentCity.id;
                  break;
                }
              }
            } else {
              this.loadCities(address.state);
            }
          }
        }
        if (address != null) {
          this.spotForm.patchValue({
            latitude: address.lat.toString(),
            longitude: address.lng.toString(),
            street: address.street || '',
            zipCode: address.zipCode || this.spot.address.zipCode,
            state: address.state || this.spot.stateId,
            city: address.city || this.spot.cityId,
            addressNumber: address.streetNumber || this.spot.address.number,
            region: this.region.name
          });
        }
      });
  }

  validateForm(event, index) {
    if (!event.valid) {
      this.invalidLanes[index] = false;
    } else {
      this.invalidLanes.splice(index, 1);
    }
    this.isFormValid = this.invalidLanes.length <= 0 && this.spotForm.valid;
  }

  cancel() {
    if (this.promise != null) {
      return;
    }
    if (this.isNewSpot) {
      this.updateSpotList.emit({ spotId: null, deleted: true });
    }
    this.spot.laneIds = this.spot.laneIds.concat(this.removedLanes);
    this.removedLanes = [];
    this.fillLanes();
    this.setFormValues();
    this.isEdit = false;
    this.clearEmptyLanes();
  }
}
