import { Component, OnInit } from '@angular/core';
import { ModalContent } from 'src/app/core/interface';
import { AlertItem, AlertType, User, UserGroup, Contract, UserHistory, ComponentModal } from 'src/app/core/models';
import {
  AlertService, ModalService, UserService, ContractService, StorageKey, StorageService,
  UserGroupService,
  CompanyService
} from 'src/app/core/services';
import { UserHistoryService } from 'src/app/core/services/userHistory.service';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { hasPermission } from 'src/app/core/utils/permission';
import * as _ from 'lodash';
import { getCalibrationFileName } from 'src/app/core/utils/getCalibrationFileName';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.sass']
})
export class UserViewComponent extends BaseModal implements OnInit, ModalContent {
  public userGroups: Array<UserGroup> = [];
  public isValid = false;
  public user: User = new User();
  public initialState: User;
  public haveRole: boolean;
  public userGroupList: { [params: string]: any } = {};
  public companyList: { [params: string]: any } = {};
  public contractIds = [];
  public userHistories: Array<UserHistory> = [];
  public userHistoriesModifications: Array<UserHistory> = [];

  public isLoading: boolean;
  public columns = [
    'data',
    'name',
    'userId',
    'action',
    'field',
    'content',
    'ip'
  ];
  public permissions = [
    'CreateUserGroup',
    'UpdateUserGroup',
    'ViewUserGroup',
    'DeleteUserGroup',
    'CreateUser',
    'UpdateUser',
    'ViewUser'
  ];
  public permissionsI18n = [];
  public columnsToShow = [];
  public typeUnfold = 'less';
  public typeUnfoldModifications = 'less';
  public params = {};
  public paramsModifications = {};

  public userHistoryId = null;
  public fieldsByUser = {};
  public differenceBetweenPermissions = [];
  public fieldsChanged = [
    'role',
    'name',
    'registryNumber',
    'signature',
    'groupIds',
    'extraData',
    'enabled',
    'files',
    'userIds'
  ];
  public fieldsChangedI18n = [];
  public users: any = [];
  public getUserFileName = getCalibrationFileName;
  public userCreator = new User();

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private contractService: ContractService,
    private userGroupService: UserGroupService,
    private storageService: StorageService,
    modalService: ModalService,
    private companyService: CompanyService,
    private userHistoryService: UserHistoryService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.isLoading = false;
    this.columnsToShow = this.columns.slice(0);
    this.user = this.initialState as User || new User();
    this.userService.getById(this.user.id).then(res => {
      this.user = res;
    });

    if (this.user.authorId && this.user.authorId !== 0) {
      this.userService.getById(this.user.authorId).then(userCreator => {
        this.userCreator = userCreator;
      });
    }
    this.haveRole = this.user.role != null ? true : false;
    if (this.user.groupIds != null) {
      this.user.groupIds.forEach(userGroupId => {
        this.userGroupService.getById(userGroupId).then(userGroup => {
          if (this.userGroupList[userGroup.id] == null) {
            this.contractService.getById(userGroup.contractId).then(contract => {
              this.contractIds.push(contract.id);
              this.userGroupList[userGroup.id] = {
                id: userGroup.id,
                prettyName: userGroup.name + ` (${contract.code} - ${contract.name})`
              };
            });
          }
        });
      });
    }
    this.companyService.getById(this.user.companyId).then(company => {
      if (this.companyList[company.id] == null) {
        this.companyList[company.id] = company;
      }
    });
    this.params = {
      userId: this.user.id,
      order: 'date',
      ['sortAsc']: 'false'
    };
    this.paramsModifications = {
      'userIdsModified[contains]': `[${this.user.id}]`,
      order: 'date',
      ['sortAsc']: 'false'
    };

    this.userService.getAll().then(res => {
      this.users = res;
    });
  }

  unfoldMoreOrLess() {
    this.typeUnfold = this.typeUnfold === 'more' ? 'less' : 'more';
    setTimeout(() =>
      document
        .getElementById(`userIdHistoryUnfold`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  unfoldMoreOrLessModifications() {
    this.typeUnfoldModifications = this.typeUnfoldModifications === 'more' ? 'less' : 'more';
    setTimeout(() =>
      document
        .getElementById(`userIdHistoryUnfoldModifications`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  unfoldUserHistory(userHistoryId) {
    this.userHistoryId = this.userHistoryId === userHistoryId ? 0 : userHistoryId;
    setTimeout(() =>
      document
        .getElementById(`userHistoryId-${userHistoryId}`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  unfoldUserHistoryModifications(userHistoryId) {
    this.userHistoryId = this.userHistoryId === userHistoryId ? 0 : userHistoryId;
    setTimeout(() =>
      document
        .getElementById(`userHistoryId-${userHistoryId}`)
        .scrollIntoView({ behavior: 'smooth' }
        ), 355);
  }

  hasPermissionContract() {
    if (this.user.superUser) {
      return;
    }
    if (hasPermission('UpdateUser', this.storageService, this.contractIds)) {
      return;
    }
    return true;
  }

  get lastState() {
    return {};
  }

  public async onAllSubmited() {
  }

  onClose() {
    this.modalService.close();
  }

  sendReinvite() {
    this.userService.reinvite(this.user).then(res => {
      this.alertService.show(new AlertItem('EmailSent', AlertType.success));
    });
  }

  loadPermissionTranslated(permission) {
    if (this.permissionsI18n.length === 0 || !permission) {
      return;
    }
    return this.permissionsI18n.find(f => f.id === permission)?.value;
  }

  setUserHistories(items = []) {
    if (items.length === 0) {
      return;
    }
    this.userHistories = items;
    if (this.userHistoryId == null) {
      this.userHistoryId = 0;
    }
    this.userHistories.map(user => {
      if (!this.fieldsByUser[user.id]) {
        this.fieldsByUser[user.id] = (user.field && user.field[0] && Object.keys(user.field[0])) || [];
      }
    });
  }

  setUserHistoriesModifications(items = []) {
    if (items.length === 0) {
      return;
    }
    this.userHistoriesModifications = items;
    if (this.userHistoryId == null) {
      this.userHistoryId = 0;
    }
    this.userHistoriesModifications.map(user => {
      if (!this.fieldsByUser[user.id]) {
        this.fieldsByUser[user.id] = (user.field && user.field[0] && Object.keys(user.field[0])) || [];
      }
    });
  }


  setPermissionsHistories(userHistory) {
    const arraNew = userHistory.field[0].permissions.new;
    const arraOld = userHistory.field[0].permissions.old;
    return _.xor(arraNew, arraOld);
  }

  fieldTranslate(field) {
    if (this.fieldsChangedI18n.length === 0 || !field) {
      return;
    }
    return this.fieldsChangedI18n.find(f => f.id === field)?.value || field.toUpperCase();
  }

  getUserName(userIds = []) {
    const names = [];
    for (const userId of userIds) {
      const user = this.users.find(u => u.id === userId);
      if (user && !names.includes(user.name || user.email)) {
        names.push(user.name || user.email);
      }
    }
    return names;
  }

}
