<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h3 class="modal-title container">
          <span *ngIf="view === 'edit'" i18n>Editar Série</span>
          <span *ngIf="!view" i18n>Nova Série</span>
        </h3>
      </div>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-4">
              <app-input type="text" i18n-label label="Identificador" formControlName="identifier">
              </app-input>
            </div>
            <div class="col-4">
              <app-input type="text" i18n-label label="Sigla" formControlName="prefix">
              </app-input>
            </div>
            <div class="col-4">
              <app-input type="text" i18n-label label="Dígito Verificador" formControlName="verifyingDigit">
              </app-input>
            </div>
            <div class="col-3">
              <app-input type="number" i18n-label label="Nº Inicial" inputRequired=true formControlName="start">
              </app-input>
            </div>
            <div class="col-3">
              <app-input type="number" i18n-label label="Nº Final" inputRequired=true formControlName="end">
              </app-input>
            </div>
            <div class="col-3">
              <app-input type="number" i18n-label label="Padrão de tamanho" formControlName="sizePattern" toolTipMsg="Caso o tamanho da série seja menor que o padrão definido,
                o sistema irá complementar o número da série com 0 à esquerda.">
              </app-input>
            </div>
            <div class="col-3">
              <app-input type="multiselect" [list]="equipmentModels" i18n-label label="Modelo do Equipamento"
                formControlName="equipmentModelIds" selectText="pretty" selectValue="id" selectShowLimit=1>
              </app-input>
            </div>
            <div class="col-12">
              <app-input type="multiselect" [list]="regulations" i18n-label label="Enquadramentos"
                formControlName="regulationIds" selectText="prettyName" selectValue="id" selectShowLimit=2
                placeholder="Selecione enquadramento(s)">
              </app-input>
            </div>
            <div class="col-12">
              <app-input type="multiselect" [list]="spots" i18n-label label="Pontos" formControlName="spotIds"
                placeholder="Selecione ponto(s)" selectText="prettyName" selectValue="id" selectShowLimit=2
                (ngModelChange)="setLanes($event)">
              </app-input>
            </div>
            <div class="col-12">
              <app-input formControlName="laneIds" type="multiselect" [list]="lanesBySpots"
                placeholder="Selecione faixa(s)" label="Faixas" selectText="prettyNames" selectValue="id"
                selectShowLimit="2" i18n-placeholder i18n-label>
              </app-input>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-sm-6">
                  <app-enum-i18n [input]="violationStatus" (output)="violationStatusI18n=$event"></app-enum-i18n>
                  <app-input i18n-label class="input-multiselect" type="multiselect" [list]="violationStatusI18n"
                    formControlName="violationStatus" label="Situação da infração" selectText="value"
                    placeholder="Selecione o status" selectValue="id">
                  </app-input>
                </div>
                <div class="col-sm-6">
                  <app-input i18n-label class="input-multiselect" type="multiselect" [list]="reasons"
                    [inputDisabled]="disableViolationStatus()" placeholder="Selecione o(s) motivo(s)"
                    formControlName="reasonIds" selectText="prettyName" selectValue="id" label="Motivo de Invalidação"
                    selectShowLimit="3">
                  </app-input>
                </div>
              </div>
            </div>
            <app-enum-i18n [input]="serialNumberFields" (output)="serialNumberFieldsI18n=$event"></app-enum-i18n>
            <div class="col-12">
              <app-enum-i18n [input]="serieArrayExample" (output)="serieArrayExampleI18n=$event"></app-enum-i18n>
              <div class="period__options">
                <span i18n>Padrão de Nome:
                  <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top"
                    appTooltip="Expressão que define o padrão de nome das séries, de acordo com os campos informados acima. Caso seja necessário, altere as sequências do campo. As séries serão geradas de acordo com o valo especificado abaixo.">help</i>
                  <i (click)="generateNamePattern()" class="material-icons refresh-icon">cached</i>
                  <small class="input-required mr-4">*</small>
                </span>
                <div cdkDropListGroup>
                  <div>
                    <div class="block__list" cdkDropList [cdkDropListData]="serieArrayExampleI18n"
                      (cdkDropListDropped)="drop($event)">
                      <ul class="list-group list-group-horizontal">
                        <ng-container *ngFor="let item of serieArrayExampleI18n; index as i">
                          <li class="list-group-item" cdkDrag>
                            {{ item.value }}
                            <i type="button" aria-label="Close" (click)="deleteFields(item)">
                              <i class="material-icons">close</i>
                            </i>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 mt-3">
              <span i18n>Modelo de série:</span>
              <input class="form-control serialNumber" formControlName="serieExample" type="text" disabled="true" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="onCancel()" class="btn btn-outline-secondary" i18n>
        <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
      </button>
      <button (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !form.valid}" i18n>
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
