<div class="card-header">
  <h5 class="my-2 font-weight-bold" i18n>Edição de imagens</h5>
</div>

<div class="card-body">
  <form [formGroup]="formGroup">
    <div class="mb-4">
      <p i18n>Selecione as edições que poderão ser realizadas nas imagens:</p>
      <div><mat-checkbox color="primary" formControlName="blur" i18n>Obliteração</mat-checkbox></div>
      <div><mat-checkbox color="primary" formControlName="clip" i18n>Recorte</mat-checkbox></div>
      <div><mat-checkbox color="primary" formControlName="hideImageEditParam" i18n>Ocultar recursos de edição de imagem:&nbsp;</mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Serão ocultados os botões que possibilitam realizar edição nas imagens, por exemplo: aplicar brilho, contraste, converter para tons de cinza, entre outros.">help</i></div>
    </div>
    <div class="row" *ngIf="hasClip">
      <p class="mt-2" i18n>Defina os posicionamentos padrões para sobreposição da imagem após o recorte:</p>
      <div class="col-md-8">
        <div class="image-block" #imgContainer (window:resize)="onResize($event)">
          <img [src]="img.src">
          <app-drag-resize [limits]="limits" [coordinates]="coordinates" (coordinatesChange)="updateCoordinates($event)"></app-drag-resize>
        </div>
      </div>
      <div class="col-md-4">
        <app-input type="text" i18n-label label="Posição X" formControlName="x" inputDisabled></app-input>
        <app-input type="text" i18n-label label="Posição Y" formControlName="y" inputDisabled></app-input>
        <app-input type="text" i18n-label label="Largura" formControlName="width" inputDisabled></app-input>
        <app-input type="text" i18n-label label="Altura" formControlName="height" inputDisabled></app-input>
      </div>
    </div>
  </form>
</div>
