import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'src/app/core/layouts/dashboard/dashboard.component';
import { ReasonListComponent } from './reason-list/reason-list.component';

const routes: Routes = [  {
  path: '',
  component: DashboardComponent,
  children: [
    {path: '', component: ReasonListComponent}
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReasonsRoutingModule { }
