import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  PreProcessSettings, Regulation, Reason, Lane, EquipmentModel, ViolationStatus,
  MessageModal, AlertItem, AlertType, ViolationSource
} from 'src/app/core/models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RegulationService } from 'src/app/core/services/regulation.service';
import {
  ReasonService, LaneService, EquipmentModelService, ContractService,
  ModalService, AlertService, PreProcessSettingsService, ViolationSourceService
} from 'src/app/core/services';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-auto-lot-config',
  templateUrl: './auto-lot-config.component.html',
  styleUrls: ['./auto-lot-config.component.sass']
})
export class AutoLotConfigComponent implements OnInit {
  @Input() preProcessSettings: PreProcessSettings = new PreProcessSettings();
  @Output() nextStepCommand = new EventEmitter();
  @Output() isValid = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  @Output() savePreProcessCommand = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('promise') set setPromise(promise) {
    this.promise = promise;
    if (promise != null) {
      promise.finally(() => {
        this.promise = null;
      });
    }
  }
  @Input() stopAttribution: boolean;

  public violationStatus = Object.values(ViolationStatus);
  public violationStatusI18n = [];
  public autoGenerationConfigs: Array<{
    form: FormGroup; namePattern: string; pathPattern: string; lotControlId: string;
    prettyValues: { regulations: string; lanes: string; equipmentModels: string; reasons: string; violationStatus: string };
  }> = [];
  public reasons: Reason[] = [];
  public regulations: Regulation[] = [];
  public lanes: Lane[] = [];
  public equipmentModels: EquipmentModel[] = [];
  get _isValid() {
    if (this.autoGenerationConfigs != null && this.autoGenerationConfigs.length > 0) {
      return this.autoGenerationConfigs.every(config => config.form.valid);
    } else {
      return true;
    }
  }
  public resourcesToTranslate = ['spot', 'valid', 'invalid', 'minutes'];
  public i18nResources = [];
  public promise: Promise<any>;
  public availableCrons = null;
  public cronIds = ['byDay', 'byTwoDays', 'byWeek'];
  public lotCloseConditions = ['period', 'time'];
  public weekdays = moment.weekdays(true);
  public weekdaysI18n = [];
  public cron;
  public cronIdsI18n;
  public lotCloseConditionsI18n;
  public lotCloseConditionFields: any = {};
  public hourRegex;
  public timezone = null;
  public contractId = '';
  public isOddSpeed = false;
  public violationSource: Array<ViolationSource>;

  constructor(
    private regulationService: RegulationService,
    private laneService: LaneService,
    private reasonService: ReasonService,
    private equipmentModelService: EquipmentModelService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private contractService: ContractService,
    private modalService: ModalService,
    private alertService: AlertService,
    private preProceSettingsService: PreProcessSettingsService,
    private violationSourceService: ViolationSourceService
  ) { }

  ngOnInit() {
    this.hourRegex = new RegExp(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/);
    const contractId = this.activatedRoute.snapshot.params.contractId;
    this.contractId = contractId;

    this.reasonService.getAll({ contractId, 'enabled[bool]': 'true' }).then(data => {
      this.reasons = data.sort((a, b): any => Number(a.code) - Number(b.code));
      this.equipmentModelService.getAll().then(res => {
        this.contractService.getById(contractId).then(contract => {
          this.timezone = contract.timezone;
          if (contract.equipmentModelIds != null) {
            res.map(item => {
              switch (item.type) {
                case 'fixed':
                  item.type = 'Fixo';
                  break;
                case 'portable':
                  item.type = 'Portátil';
                  break;
                case 'speedBump':
                  item.type = 'Lombada Eletrônica';
                  break;
              }
            });
            this.equipmentModels = res.filter(equipmentModel => contract.equipmentModelIds.indexOf(equipmentModel.id) >= 0);
            this.setField('equipmentModelIds', this.equipmentModels.map(e => e.id));
          }
          this.regulationService.getAll({ regionId: contract.regionId }).then(regulations => {
            this.regulations = regulations;
            this.setField('regulationIds', regulations.map(r => r.id));
          });
        });
      });
      this.laneService.getAll({ contractId }).then(lanes => {
        this.lanes = lanes;
        this.setField('laneIds', lanes.map(l => l.id));
      });
      if (this.getValue(this.preProcessSettings, 'lotGeneration.autoGeneration').length > 0) {
        this.preProcessSettings.lotGeneration.autoGeneration.forEach(autoGeneration => {
          this.createForm(autoGeneration);
        });
      } else {

        if (Object.keys(this.lotCloseConditionFields).length) {
          this.createForm();
        }
      }
    });
    this.isValid.emit(this._isValid);
    this.listViolationSources();
  }

  getLotCondition(autoGeneration) {
    this.lotCloseConditionFields = {};
    if (autoGeneration.cron != null && autoGeneration.cron !== '') {
      this.lotCloseConditionFields.lotCloseCondition = 'period';
      this.lotCloseConditionFields.cronId = 'byDay';
      const cronLotCloseCondition = autoGeneration.cron.split(' ');
      if (cronLotCloseCondition[4] != null && cronLotCloseCondition[4] !== '*') {
        this.lotCloseConditionFields.cronId = 'byWeek';
        const dayOfWeek = cronLotCloseCondition[4];
        this.lotCloseConditionFields.week = this.weekdays[dayOfWeek];
      } else if (cronLotCloseCondition[2] != null && cronLotCloseCondition[2] !== '*') {
        this.lotCloseConditionFields.cronId = 'byTwoDays';
      }
      if (cronLotCloseCondition[0] !== '*' && cronLotCloseCondition[1] !== '*') {
        const lotCloseHour = cronLotCloseCondition[1].padStart(2, '0') + ':' + cronLotCloseCondition[0].padStart(2, '0');
        const dateNow = moment(new Date()).format('YYYY-MM-DD');
        const dateTime = moment.tz(`${dateNow} ${lotCloseHour}`, this.timezone).format();
        const hour = String(moment(dateTime).hour());
        const minutes = String(moment(dateTime).minutes());
        this.lotCloseConditionFields.time = `${hour.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
      }
    } else if (autoGeneration.period != null) {
      this.lotCloseConditionFields.lotCloseCondition = 'time';
    }
  }
  createForm(autoGeneration: any = {}) {
    const config: any = {};
    let violationStatus = autoGeneration.violationStatus || [];
    if (violationStatus.length === 0) {
      violationStatus = this.violationStatus;
    }
    let pathPattern = autoGeneration.pathPattern;
    if (pathPattern == null) {
      pathPattern = autoGeneration.isNotProcessable ? '' : this.getValue(this.preProcessSettings, 'lotGeneration.pathPattern');
    }
    this.getLotCondition(autoGeneration);
    config.form = this.formBuilder.group({
      generationLimit: [autoGeneration.generationLimit, [Validators.min(1), Validators.pattern('^[0-9]*$')]],
      namePattern: [autoGeneration.namePattern || this.getValue(this.preProcessSettings, 'lotGeneration.namePattern'), Validators.required],
      pathPattern: [pathPattern, pathPattern ? Validators.required : null],
      period: [autoGeneration.period],
      maxAmountViolation: [autoGeneration.maxAmountViolation || 500, [Validators.required, Validators.min(1), Validators.max(1000)]],
      regulationIds: [autoGeneration.regulationIds],
      laneIds: [autoGeneration.laneIds],
      reasonIds: [autoGeneration.reasonIds],
      equipmentModelIds: [autoGeneration.equipmentModelIds],
      violationStatus: [violationStatus],
      isNotProcessable: [autoGeneration.isNotProcessable ? autoGeneration.isNotProcessable : false],
      lotCloseConditionCron: [this.lotCloseConditionFields.cronId],
      lotCloseCondition: [this.lotCloseConditionFields.lotCloseCondition, Validators.required],
      lotCloseHour: [this.lotCloseConditionFields.time || '23:59', Validators.pattern(this.hourRegex)],
      lotCloseWeek: [this.lotCloseConditionFields.week, [Validators.required, Validators.minLength(3)]],
      oddSpeedParam: [autoGeneration.oddSpeed || this.isOddSpeed],
      lotSpecial: [autoGeneration.isSpecial || false],
      violationSourceAbbreviation: [autoGeneration.violationSourceId]
    });

    if (autoGeneration.namePattern != null && autoGeneration.pathPattern) {
      config.namePattern = autoGeneration.namePattern;
      config.pathPattern = autoGeneration.pathPattern;
    }
    config.lotControlId = autoGeneration && autoGeneration.lotControlId;

    if (_.isEmpty(autoGeneration)) {
      this.autoGenerationConfigs.unshift(config);
    } else {
      this.autoGenerationConfigs.push(config);
    }
    config.form.valueChanges.subscribe(() => {
      this.verifyChanges(config, autoGeneration.lotControlId);
      this.isValid.emit(this._isValid);
      if (this._isValid === true) {
        this.outputValue.emit(this.getOutput());
      }
    });
    if (this.lotCloseConditionFields.cronId !== 'byWeek') {
      const lotCloseWeek = config.form.get('lotCloseWeek');
      lotCloseWeek.clearValidators();
      lotCloseWeek.updateValueAndValidity();
    }

    if (violationStatus.length === 1 && violationStatus[0] === 'valid') {
      config.form.patchValue({
        reasonIds: []
      });
    } else {
      const reasonIds = config.form.get('reasonIds').value || [];
      config.form.patchValue({
        reasonIds: reasonIds.length > 0 ? reasonIds : this.reasons.map(r => r.id)
      });
    }

    const pathPattermControl = config.form.get('pathPattern');
    config.form.get('isNotProcessable').valueChanges.subscribe(value => {
      if (value) {
        config.form.patchValue({ pathPattern: '' });
        config.form.get('pathPattern').clearValidators();
        config.form.get('pathPattern').updateValueAndValidity();
      } else {
        this.setField('pathPattern', '');
        config.form.get('pathPattern').setValidators(Validators.required);
        config.form.get('pathPattern').pristine = false;
        config.form.get('pathPattern').updateValueAndValidity();
      }
    });

    config.form.get('violationStatus').valueChanges.subscribe(value => {
      const reasonIds = config.form.get('reasonIds');
      if ((value.length === 1 && value[0] === 'valid') || value.length === 0) {
        config.form.patchValue({
          reasonIds: []
        });
        this.setField('reasonIds', []);
        reasonIds.clearValidators();
        reasonIds.updateValueAndValidity();

      } else if (value.includes('invalid') && (reasonIds.value == null || reasonIds.value.length === 0)) {
        reasonIds.setValidators(Validators.required);
        reasonIds.pristine = false;
        reasonIds.setErrors({ incorrect: true });
      }
    });

    config.form.get('namePattern').valueChanges.subscribe(value => config.namePattern = value);
    config.form.get('pathPattern').valueChanges.subscribe(value => config.pathPattern = value);

    const lotCloseCondition = config.form.get('lotCloseCondition');
    const period = config.form.get('period');

    period.valueChanges.subscribe(value => {
      if (lotCloseCondition.value === 'time' && value === 0) {
        period.clearValidators();
        period.updateValueAndValidity();
      }
    });

    const lotCloseHour = config.form.get('lotCloseHour');
    lotCloseHour.valueChanges.subscribe(value => {
      if (value === '') {
        this.setValidatorsCloseCondition(config);
      }
    });

    const lotCloseConditionCron = config.form.get('lotCloseConditionCron');
    lotCloseConditionCron.valueChanges.subscribe(() => {
      this.setValidatorsCloseCondition(config);
    });

    lotCloseCondition.valueChanges.subscribe(() => {
      this.setValidatorsCloseCondition(config);
    });
  }

  enableOddSpeed(regulationIds = []) {
    if (regulationIds == null) {
      return;
    }
    return (regulationIds.includes(74550) || regulationIds.includes(74630) || regulationIds.includes(74710));
  }

  setValidatorsCloseCondition(config: any) {
    const value = config.form.get('lotCloseCondition').value;
    const period = config.form.get('period');
    const lotCloseConditionCron = config.form.get('lotCloseConditionCron');
    const lotCloseWeek = config.form.get('lotCloseWeek');
    const lotCloseHour = config.form.get('lotCloseHour');
    const conditionCron = lotCloseConditionCron.value;
    switch (value) {
      case 'period':
        switch (conditionCron) {
          case 'byDay':
          case 'byTwoDays':
            period.clearValidators();
            period.updateValueAndValidity();
            if (lotCloseHour.value === '') {
              lotCloseHour.setValidators(Validators.required, Validators.pattern(this.hourRegex));
              config.form.controls.lotCloseHour.setErrors({ incorrect: true });
            }
            lotCloseWeek.clearValidators();
            lotCloseWeek.updateValueAndValidity();
            break;
          case 'byWeek':
            period.clearValidators();
            period.updateValueAndValidity();
            config.form.patchValue({
              lotCloseWeek: '',
            });
            lotCloseWeek.setValidators(Validators.required, Validators.minLength(3));
            config.form.controls.lotCloseWeek.setErrors({ incorrect: true });
            lotCloseHour.clearValidators();
            lotCloseHour.updateValueAndValidity();
            break;
          default:
            lotCloseConditionCron.setValidators(Validators.required);
            config.form.controls.lotCloseConditionCron.setErrors({ incorrect: true });
        }
        break;
      case 'time':
        if (lotCloseConditionCron.status !== 'VALID') {
          lotCloseConditionCron.clearValidators();
          lotCloseConditionCron.updateValueAndValidity();
        }
        config.form.controls.period.setValidators(Validators.min(1), Validators.pattern('^[0-9]*$'));
        config.form.patchValue({
          period: 1
        });
        break;
    }
    config.form.updateValueAndValidity();
  }

  removeConfig(index) {
    if (!this.stopAttribution) {
      return;
    }
    this.autoGenerationConfigs.splice(index, 1);
    this.isValid.emit(this._isValid);
    if (this._isValid === true) {
      this.outputValue.emit(this.getOutput());
    }
  }

  addNewConfig() {
    if (!this.stopAttribution && this.preProcessSettings?.lotGeneration?.stopAttribution != null) {
      return;
    }
    if (!this.preProcessSettings?.lotGeneration?.stopAttribution) {
      this.stopAttribution = true;
    }
    this.createForm();
  }

  nextStep(nextStep) {
    if (this._isValid === true) {
      this.nextStepCommand.emit(nextStep);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.isValid.emit(this._isValid);
    if (this._isValid === true) {
      this.outputValue.emit(this.getOutput());
    }
  }

  getOutput() {
    const autoGeneration = [];
    this.autoGenerationConfigs.forEach(config => {
      const generationLimit = config.form.get('generationLimit').value &&
        parseInt(config.form.get('generationLimit').value, 10);

      let regulationIds = config.form.get('regulationIds').value || [];
      if (regulationIds.length === this.regulations.length) {
        regulationIds = null;
      }
      let laneIds = config.form.get('laneIds').value || [];
      if (laneIds.length === this.lanes.length) {
        laneIds = null;
      }
      let reasonIds = config.form.get('reasonIds').value || [];
      if (reasonIds.length === this.reasons.length) {
        reasonIds = [];
      }
      let equipmentModelIds = config.form.get('equipmentModelIds').value || [];
      if (equipmentModelIds.length === this.equipmentModels.length) {
        equipmentModelIds = null;
      }
      let violationStatus = config.form.get('violationStatus').value || [];
      if (violationStatus.length === this.violationStatus.length) {
        violationStatus = null;
      }

      let cron;
      let period = config.form.get('period').value &&
        parseInt(config.form.get('period').value, 10);

      const maxAmountViolation = config.form.get('maxAmountViolation').value &&
        parseInt(config.form.get('maxAmountViolation').value, 10);

      let minutes = '*';
      let hour = '*';
      const dayMoth = '*';
      const month = '*';
      let dayweek = '*';

      if (config.form.get('lotCloseCondition').value === 'period') {
        const cronId = config.form.get('lotCloseConditionCron').value;
        const lotCloseHour = config.form.get('lotCloseHour').value || '23:59';
        const lotCloseWeek = config.form.get('lotCloseWeek').value;
        const dateNow = moment(new Date()).format('YYYY-MM-DD');
        const dateTime = moment.tz(`${dateNow} ${lotCloseHour}`, this.timezone).utc().format();
        hour = String(moment(dateTime).utc().hour());
        minutes = String(moment(dateTime).utc().minutes());

        switch (cronId) {
          case 'byDay':
            this.availableCrons = `${minutes} ${hour} ${dayMoth} ${month} ${dayweek}`;
            break;
          case 'byTwoDays':
            this.availableCrons = `${minutes} ${hour} ${dayMoth}/5 ${month} ${dayweek}`;
            break;
          case 'byWeek':
            minutes = '*';
            hour = '*';
            if (lotCloseWeek && lotCloseWeek !== '') {
              dayweek = String(moment().day(lotCloseWeek).day());
              this.availableCrons = `${minutes} ${hour} ${dayMoth} ${month} ${dayweek}`;
            }
        }
        period = null;
      } else {
        this.availableCrons = `${minutes} 0/${period} ${dayMoth} ${month} ${dayweek}`;
      }
      if (this.availableCrons != null) {
        cron = this.availableCrons;
      }
      const isNotProcessable = config.form.get('isNotProcessable').value || false;

      const autoGenerationConfig = {
        generationLimit,
        namePattern: config.form.get('namePattern').value,
        pathPattern: config.form.get('pathPattern').value,
        period,
        maxAmountViolation,
        cron,
        regulationIds,
        laneIds,
        reasonIds,
        equipmentModelIds,
        violationStatus,
        lotControlId: config.lotControlId,
        isNotProcessable,
        oddSpeed: config.form.get('oddSpeedParam').value,
        isSpecial: config.form.get('lotSpecial').value,
        violationSourceId: config.form.get('violationSourceAbbreviation').value
      };
      if ((autoGenerationConfig.period === 0 || autoGenerationConfig.period === null)) {
        delete autoGenerationConfig.period;
      } else {
        delete autoGenerationConfig.cron;
      }
      autoGeneration.push(autoGenerationConfig);
    });
    return {
      lotGeneration: {
        stopAttribution: this.stopAttribution,
        autoGeneration
      }
    };
  }

  verifyChanges(config, lotControlId) {
    if (this.preProcessSettings.lotGeneration.autoGeneration == null) {
      this.preProcessSettings.lotGeneration.autoGeneration = [];
    }
    const autoGeneration: any = this.preProcessSettings.lotGeneration.autoGeneration.find(l => l.lotControlId === lotControlId);
    config.form.get('generationLimit').valueChanges.subscribe(value => {
      if (autoGeneration?.generationLimit !== value) {
        this.outputValue.emit(this.getOutput());
      }
    });
    config.form.get('regulationIds').valueChanges.subscribe(value => {
      if (this.regulations.length !== value?.length && autoGeneration?.regulationIds?.length !== value?.length) {
        this.outputValue.emit(this.getOutput());
      }
    });
    config.form.get('laneIds').valueChanges.subscribe(value => {
      if (this.lanes.length !== value?.length && autoGeneration?.laneIds?.length !== value?.length) {
        this.outputValue.emit(this.getOutput());
      }
    });
    config.form.get('violationStatus').valueChanges.subscribe(value => {
      if (autoGeneration?.violationStatus?.length !== value?.length) {
        this.outputValue.emit(this.getOutput());
      }
    });
    config.form.get('period').valueChanges.subscribe(value => {
      this.outputValue.emit(this.getOutput());
    });
    config.form.get('maxAmountViolation').valueChanges.subscribe(value => {
      if (autoGeneration?.maxAmountViolation !== value) {
        this.outputValue.emit(this.getOutput());
      }
    });
    config.form.get('isNotProcessable').valueChanges.subscribe(value => {
      if (autoGeneration?.isNotProcessable !== value) {
        this.outputValue.emit(this.getOutput());
      }
    });
    config.form.get('namePattern').valueChanges.subscribe(value => {
      if (autoGeneration?.namePattern !== value) {
        this.outputValue.emit(this.getOutput());
      }
    });
    config.form.get('pathPattern').valueChanges.subscribe(value => {
      if (autoGeneration?.pathPattern !== value) {
        this.outputValue.emit(this.getOutput());
      }
    });
    config.form.get('violationSourceAbbreviation').valueChanges.subscribe(value => {
      if (autoGeneration?.violationSourceAbbreviation !== value) {
        this.outputValue.emit(this.getOutput());
      }
    });
    config.form.get('lotCloseConditionCron').valueChanges.subscribe(value => {
      this.outputValue.emit(this.getOutput());
    });
    config.form.get('lotCloseHour').valueChanges.subscribe(value => {
      this.outputValue.emit(this.getOutput());
    });
    config.form.get('lotCloseWeek').valueChanges.subscribe(value => {
      this.outputValue.emit(this.getOutput());
    });
    if (this.stopAttribution == null) {
      this.stopAttribution = true;
    }
  }

  getValue(model, attr, value = '') {
    let arr = [];
    if (typeof attr === 'string') {
      arr = attr.split('.');
    } else {
      arr = attr;
    }
    if (arr.length > 0) {
      if (model[arr[0]] == null) {
        return value;
      } else {
        return this.getValue(model[arr[0]], arr.slice(1), value);
      }
    } else {
      return model;
    }
  }

  move(prevIndex, currentIndex) {
    moveItemInArray(this.autoGenerationConfigs, prevIndex, currentIndex);
    this.isValid.emit(this._isValid);
    if (this._isValid === true) {
      this.outputValue.emit(this.getOutput());
    }
  }

  async updateStopAttribution(value) {
    if (this._isValid === true) {
      this.stopAttribution = value;
      this.preProceSettingsService.getById(this.contractId, true).then(async (preProcessSettings) => {
        this.preProcessSettings = preProcessSettings;
        this.preProcessSettings.lotGeneration.stopAttribution = value;
        await this.preProceSettingsService.update(this.preProcessSettings).then(preProcess => {
          this.preProcessSettings = preProcess;
          this.stopAttribution = this.preProcessSettings.lotGeneration.stopAttribution;
          this.alertService.show(new AlertItem('PreProcessUpdated', AlertType.success));
        }).catch(err => {
          console.error(err);
          this.alertService.show(new AlertItem('PreProcessUpdateError', AlertType.danger));
        });
      });
    }
  }

  setField(field, data) {
    if (this.preProcessSettings.id == null) {
      return;
    }
    this.autoGenerationConfigs.forEach(config => {
      const value = config.form.get(field).value || [];
      if (value.length === 0) {
        config.form.patchValue({
          [field]: data
        });
      }
    });
  }

  disableViolationStatus(index) {
    const value = this.autoGenerationConfigs[index].form.get('violationStatus').value || [];
    return value.length === 1 && value[0] === 'valid';
  }

  removeLoteControlId(index) {
    if (!this.stopAttribution) {
      return;
    }
    this.modalService.show(new MessageModal('Reiniciar Contador do Lote',
      'Tem certeza que deseja reiniciar o contador de identificação do lote ?', true))
      .then(() => {
        const autoGeneration = this.autoGenerationConfigs[index];
        if (autoGeneration != null) {
          const lotControlId = autoGeneration.lotControlId;
          const indexLotControl = this.preProcessSettings.lotGeneration.
            autoGeneration.findIndex(a => a.lotControlId === lotControlId);
          delete this.preProcessSettings.lotGeneration.autoGeneration[indexLotControl].lotControlId;
          this.promise = this.preProceSettingsService.update(this.preProcessSettings)
            .then(res => {
              this.preProcessSettings = res;
              this.autoGenerationConfigs[index].lotControlId =
                this.preProcessSettings.lotGeneration.autoGeneration[indexLotControl].lotControlId;
              this.alertService.show(new AlertItem('PreProcessUpdated', AlertType.success));
            }).catch(err => {
              this.alertService.show(new AlertItem('PreProcessUpdateError', AlertType.danger));
            }).finally(() => {
              this.promise = null;
            });
        }
      }).catch(err => { });
  }

  setSpecial(config) {
    if (!this.stopAttribution) {
      return;
    }
    if (config.form.get('lotSpecial').value) {
      config.form.patchValue({
        lotSpecial: false
      });
    } else {
      config.form.patchValue({
        lotSpecial: true
      });
    }
  }

  listViolationSources() {
    this.violationSourceService.getAll().then(res => {
      this.violationSource = res;
    });
  }
}
