<div class="form-group" [ngClass]="{'form-group--icon': toolTipMsg   }">
  <label *ngIf="hasLabel === true" class="form-control-label"
    [ngClass]="{required: inputRequired && !isLabel }">{{label !== undefined ? label + ':' : ''}}
    <i *ngIf="toolTipMsg !== undefined" class="material-icons icon-sm text-secondary" [appTooltip]="toolTipMsg">help</i>
    <span *ngIf="hasRefresh" (click)="refresh.next()" class="material-icons refresh-icon">cached</span>
  </label>
  <i *ngIf="toolTipMsg !== undefined && hasLabel === false" class="material-icons icon-sm text-secondary" [appTooltip]="toolTipMsg">help</i>
  <div *ngIf="!isLabel; else labelBlock">
    <div [ngSwitch]="type" [ngClass]="{'align-input': leftUnit   || rightUnit  }" class="inputDiv">
      <span *ngIf="leftUnit  " class="leftUnit">{{leftUnit}}</span>

      <angular2-multiselect *ngSwitchCase="type === 'multiselect' || type === 'select' ? type : false" [data]="list" [(ngModel)]="multiselectValue"
         (onSelect)="onMultiSelect($event)" (onSelectAll)="onMultiSelect($event)" (onDeSelect)="onMultiSelect($event)" (onDeSelectAll)="onMultiSelect($event)"
        [settings]="dropdownSettings" [ngClass]="{'is-invalid': shouldShowErrors(), 'capitalize' : capitalize}"></angular2-multiselect>

      <select *ngSwitchCase="'native-select'" class="form-control"
        [ngClass]="{'is-invalid': shouldShowErrors(), 'text-capitalize' : capitalize, 'text-uppercase': uppercase, 'form-control-sm': isSmall}"
        [(ngModel)]="innerValue" (input)="onSelectChange($event)" [disabled]=inputDisabled>
        <option [selected]="isSelected" value="" *ngIf="placeholder && !inputDisabled">{{ placeholder }}</option>
        <option *ngFor="let item of list" value="{{ item[selectValue] }}">{{ item[selectText] }}</option>
      </select>

      <textarea *ngSwitchCase="'textarea'" [disabled]=inputDisabled  class="form-control" (input)="onTextChange($event)"
        [(ngModel)]="innerValue" [ngClass]="{'is-invalid': shouldShowErrors(), 'capitalize' : capitalize}"
        placeholder="{{ placeholder ? placeholder : '' }}"></textarea>

      <input *ngSwitchDefault [type]="type" [placeholder]="placeholder || ''" [disabled]=inputDisabled [(ngModel)]="innerValue"
        class="form-control" (input)="onTextChange($event)"
        [ngClass]="{'is-invalid': shouldShowErrors(), 'text-large' : isLarge, 'text-capitalize' : capitalize, 'text-uppercase': uppercase, 'form-control-sm': isSmall}"/>

      <span class="rightUnit" *ngIf="rightUnit  ">{{rightUnit}}</span>
      <app-loading [promise]="promise" class="loading" [ngClass]="{'select': type === 'multiselect' || type === 'select'}"></app-loading>
    </div>
  </div>

  <ng-template #labelBlock>
    <div class="label-content">
      {{textValue}}
    </div>
  </ng-template>

  <small *ngIf="shouldShowErrors()" class="text-danger">
    <span *ngFor="let error of listOfErrors() | slice:0:1">{{error}}</span>
  </small>
</div>
