<div class="card-header">
  <h5 i18n class="my-2 font-weight-bold">Fluxo de Lotes</h5>
</div>

<div class="card-body">
  <div class="row mb-4">
    <div class="col d-flex align-items-center justify-content-center">
      <i class="material-icons">extension</i>
      <span i18n>Etapa</span>
    </div>
    <div class="col d-flex align-items-center justify-content-center">
      <i class="material-icons text-success">check_circle</i>
      <span i18n>Próxima Etapa</span>
    </div>
  </div>

  <app-enum-i18n [input]="lotSteps" (output)="setLotStepsI18n($event)"></app-enum-i18n>
  <app-enum-i18n [input]="options" (output)="setOptionsI18n($event)"></app-enum-i18n>
  <div *ngFor="let form of forms; index as i">
    <form [formGroup]="form">
      <div class="row step-block mx-3" *ngIf="form.get('step').value!=='done'">
        <div class="col-6">
          <h5 class="stepTitle">{{i18nResources[form.get('step').value].value}}</h5>
        </div>

        <div class="col-6">
          <div class="form-group">
            <app-input i18n-placeholder type="select" [list]="lotStepsI18n" selectText="value" selectValue="id"
              formControlName="accepted" (ngModelChange)="updateGraph(i)" placeholder="Selecione uma etapa">
            </app-input>
          </div>
        </div>

        <div class="col-6 offset-6" *ngIf="form.get('step').value === 'internalAudit'">
          <div class="form-group">
            <app-input i18n-placeholder type="select" [list]="lotStepsI18n" selectText="value" selectValue="id"
              formControlName="refused" (ngModelChange)="updateGraph(i)" placeholder="Selecione uma etapa">
            </app-input>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="card-divider"></div>

  <h6 i18n>Visualização das etapas</h6>
  <app-state-diagram [value]="graphValue"></app-state-diagram>
</div>
