import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  LaneService, ReasonService, SpotService, ViolationService,
  ViewViolationService, ModalService, CategoryService, UserGroupService, AlertService, CsvDataServiceService,
  ViolationCsvService,
  StorageService,
  StorageKey,
  LotService
} from 'src/app/core/services';
import * as moment from 'moment';
import {
  Lane, Reason, Regulation, Spot, Violation, ComponentModal,
  Category, User, AlertItem, AlertType, MessageModal,
  Lot} from 'src/app/core/models';
import { DetailsViolationComponent } from 'src/app/modals/details-violation/details.component';
import { LoteInfoComponent } from 'src/app/modals/lote-info/lote-info.component';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { QuarantineStatus } from 'src/app/core/models/Quarantine';

@Component({
  selector: 'app-violations-view-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.sass']
})
export class ViolationsViewContractComponent implements OnInit {
  public contractId: string;
  public contractName: string;
  public loading: boolean;
  public contractViewViolationsSub: Subscription;
  public filterViewViolationsSub: Subscription;
  public periodViewViolationsSub: Subscription;
  public users: any[] = [];
  public filter: any = {};
  public startDate;
  public endDate;
  public contract: any = {};
  public timezone: string;
  public categories: Category[] = [];
  public violations: Violation[] = [];
  public lanes: Lane[] = [];
  public spots: Spot[] = [];
  public regulations: Regulation[] = [];
  public reasons: Reason[] = [];
  public situations: any[] = [];
  public params: any = {};
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public violationsStepsResource: any[] = [];
  public violationsSteps: any[] = [
    'validateLane',
    'validateCalibration',
    'validateSpeed',
    'validateFiles',
    'validateExemptPeriod',
    'validateViolationLimit',
    'verifyValid',
    'verifyInvalid',
    'triage',
    'typing',
    'verify',
    'validate',
    'lotAttribution',
    'internalAudit',
    'revision',
    'filter',
    'done',
    'quarantine',
    'verifyCFTV',
    'validateCFTV',
    'monitoringCFTV',
  ];
  public quarantineStatusI18n = [];
  public quarantineStatus = Object.values(QuarantineStatus);
  public lots = [];
  public lotsByViolation = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private laneService: LaneService,
    private modalService: ModalService,
    private reasonsService: ReasonService,
    private spotService: SpotService,
    private viewViolationService: ViewViolationService,
    public violationService: ViolationService,
    private alertService: AlertService,
    private violationCSVService: ViolationCsvService,
    public storageService: StorageService,
    private lotService: LotService
  ) { }

  async ngOnInit() {
    this.contractId = this.activatedRoute.snapshot.params.contractId;

    const now = new Date().toISOString();
    this.startDate = moment(now).subtract(1, 'month').utc().format();
    this.endDate = moment(now).utc().format();

    this.params['date[gte]'] = this.startDate;
    this.params['date[lte]'] = this.endDate;

    this.contractViewViolationsSub = this.viewViolationService.getDataViewViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe(async (data: any) => {
        this.contract = data.contracts.filter(item => item.id === Number(this.contractId)).shift();
        this.contractName = this.contract.name;
        this.regulations = data.regulations;
        this.situations = data.situations;
        this.spots = await this.spotService.getAll({ contractId: this.contractId });
        this.lanes = await this.laneService.getAll({ contractId: this.contractId });
        const paramsLot: any = {
          contractId: this.contractId,
          ['sortAsc']: 'false',
          ['violationsAmount[gte]']: 0,
          order: 'oldestViolationAt,newestViolationAt'
        };
        await this.lotService.list(paramsLot).then(res => {
          this.lots = res.result;
          this.lots.map(lot => {
            if (this.lotsByViolation['lot'+lot.id] == null) {
              this.lotsByViolation['lot'+lot.id] = lot;
            }
          });
        });
        this.reasons = await this.reasonsService.getAll({ contractId: this.contractId });
        this.reasons =  this.reasons.sort((a, b): any => Number(a.code) - Number(b.code));
        this.categories = await this.categoryService.getAll({ regionId: this.contract.regionId });

        const dataContract = {
          regulations: this.regulations,
          situations: this.situations,
          spots: this.spots,
          lanes: this.lanes,
          lots: this.lots,
          reasons: this.reasons,
          categories: this.categories,
          users: this.users
        };

        this.viewViolationService.emitDataViewContractViolation(dataContract);
        this.setParams();
      });

    this.filter = {
      contractId: this.contractId, spotsIds: '', laneIds: '',
      regulationsIds: '', situationsIds: '', reasonsIds: '',
      numberSerie: '', numberPlate: '', numberId: ''
    };

    this.setParams();

    this.filterViewViolationsSub = this.viewViolationService.getFilterViewViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((result: any) => {
        this.violations = [];
        this.filter = result;
        this.setParams();
      });

    this.periodViewViolationsSub = this.viewViolationService.getPeriodViewViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((result: any) => {
        this.startDate = result.startDate;
        this.endDate = result.endDate;
        this.setParams();
      });
  }

  setParams() {
      this.params = {};
      this.params.contractId = this.contractId;
      this.params['date[gte]'] = moment(this.startDate).set({ hour: 0, minutes: 0, seconds: 0 }).format();
      this.params['date[lte]'] = moment(this.endDate).set({ hour: 23, minutes: 59, seconds: 59 }).format();
      if (this.filter.numberId) {
        this.params.passId = this.filter.numberId;
      }

      if (this.filter.numberSerie) {
        this.params['equipment.serialNumber'] = this.filter.numberSerie;
      }

      if (this.filter.numberPlate) {
        this.params['vehicle.plate'] = this.filter.numberPlate;
      }

      if (this.filter.spotsIds.length > 0 && this.filter.spotsIds.length < this.spots.length) {
        const spotsIds = this.filter.spotsIds.map(Number);
        const lanesWithSpot = this.lanes.filter(lane => spotsIds.includes(lane.spotId)).map(lane => lane.code);
        this.params['laneCode[in]'] = `[${lanesWithSpot.join()}]`;
      }

      if (this.filter.laneIds.length > 0 && this.filter.laneIds.length < this.lanes.length) {
        const laneIds = this.filter.laneIds.map(Number);
        const lanesCode = this.lanes.filter(lane => laneIds.includes(lane.id)).map(lane => lane.code);
        this.params['laneCode[in]'] = `[${lanesCode.join()}]`;
      }

      if (this.filter.regulationsIds.length > 0 && this.filter.regulationsIds.length < this.regulations.length) {
        this.params['regulationId[in]'] = `[${this.filter.regulationsIds.join()}]`;
      }

      if (this.filter.situationsIds && this.filter.situationsIds.length > 0) {
        if (this.filter.situationsIds.length === 1 && this.filter.situationsIds.includes('inProcess')) {
          this.params['step[neq,and]'] = 'done';
          this.params['step[neq]'] = 'lotAttribution';
        }

        if (this.filter.situationsIds.length > 1 && this.filter.situationsIds.includes('inProcess')) {
          this.params['status[in]'] = `[invalid,valid]`;
        }

        if (!this.filter.situationsIds.includes('inProcess')) {
          this.params['status[in]'] = `[${this.filter.situationsIds.join()}]`;
          this.params['step[in]'] = `[lotAttribution, done]`;
          this.filter.steps = ['lotAttribution', 'done'];
        }
      }

      this.filter.reasonsIds = (this.filter.reasonsIds && this.filter.reasonsIds.map(Number)) || [];
      if (this.filter.reasonsIds.length > 0 && this.filter.situationsIds.includes('invalid')
        && this.filter.reasonsIds.length < this.reasons.length) {
        const reasonsCode = this.reasons.filter(reason => this.filter.reasonsIds.includes(reason.id)).map(reason => reason.code);
        this.params['reason.code[in]'] = `[${reasonsCode.join()}]`;
      }
      if (this.filter?.lotIds?.length > 0) {
        this.params['lotId[or]'] = this.filter.lotIds[0];
        this.params['lotIdAudit[or]'] = this.filter.lotIds[0];
      }
      this.params.order = 'date';
      this.loading = true;
  }

  async setViolations(list) {
    this.violations = list;
    const lotIds = [];
    this.violations.map(async (v) => {
      if (v.lotId && !lotIds.includes(v.lotId)) {
        lotIds.push(v.lotId);
      }
      if (v.lotIdAudit && !lotIds.includes(v.lotIdAudit)) {
        lotIds.push(v.lotIdAudit);
      }
    });
  }

  getLotInfo(id) {
    const lot = this.lots.find(l => l.id === id);
    return lot && lot.name;
  }

  getSpot(lane) {
    const laneInfo = this.lanes.filter(item => item.code === lane).shift();
    if (laneInfo) {
      const spotInfo = this.spots.filter(item => item.id === laneInfo.spotId).shift();
      return (spotInfo && spotInfo.code) || '-';
    }
  }

  getRegulationCode(regulation) {
    return this.regulations.filter(item => item.id === regulation).map(item => item.code).shift();
  }

  getRegulationInfo(regulation) {
    const regulationInfo = this.regulations.filter(item => item.id === regulation).shift();
    return (regulationInfo && regulationInfo.description) || '';
  }

  getSpotInfo(lane) {
    const laneInfo = this.lanes.filter(item => item.code === lane).shift();
    if (laneInfo) {
      const spotInfo = this.spots.filter(item => item.id === laneInfo.spotId).shift();
      return (spotInfo && spotInfo.prettyName) || '-';
    }
  }

  async showDetails(violation) {
    await this.modalService.show(new ComponentModal(DetailsViolationComponent, { violation }))
      .catch((error) => console.log(error));
  }

  public downloadAsPDF(violation) {
    this.violationService.ait(violation.id).then(ait => {
      const fileTemplate = ait;
      const bodyTemplate = fileTemplate.body;
      const iframe = document.createElement('iframe');
      document.body.appendChild(iframe);
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(bodyTemplate);
      iframe.contentWindow.document.close();
      let bodyAit: HTMLElement = iframe.contentWindow.document.querySelector('.align-table');
      if (bodyAit == null) {
        bodyAit = iframe.contentWindow.document.querySelector('body');
      }
      html2canvas(bodyAit,
        {
          allowTaint: false,
          useCORS: true,
          logging: true
        }
      ).then(canvas => {
        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
        const width = pdf.internal.pageSize.getWidth();
        const height = canvas.height * width / canvas.width;
        pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height);
        pdf.save(`${violation.passId}.pdf`); // Generated PDF
        document.body.removeChild(iframe);
      });
    }).catch(error => {
      this.alertService.show(new AlertItem('AitError', AlertType.danger));
    });
;
  }

  async showHistory(lotId) {
    await this.modalService.show(new ComponentModal(LoteInfoComponent, { lotId }))
      .catch((error) => console.log(error));
  }

  getHistories(history) {
    return this.getNormalizedHistory(history);
  }

  getLaneInfo(lane) {
    return this.lanes.filter(item => item.code === lane).map(item => item.prettyNames && item.prettyNames[0]).shift();
  }

  getAuthorInfo(violation) {
    let item;
    if (this.getNormalizedHistory(violation)[0].id === 'lotAttribution') {
      item = this.getNormalizedHistory(violation)[1];
    } else {
      item = this.getNormalizedHistory(violation).shift();
    }
    return item?.authorName || null;
  }

  getNormalizedHistory(violationsHistory: []) {
    const resultNormalized = [];
    Object.entries(violationsHistory).map(item => {
      const id: string = item[0];
      const data: any = item[1];

      if (Array.isArray(data)) {
        data.map(itemInside => {
          const itemObj: any = {};
          const stepName = this.violationsStepsResource.filter(step => step.id === id).shift();
          itemObj.id = id;
          itemObj.title = stepName ? stepName.value : null;
          itemObj.date = itemInside.date;
          if (itemInside.authorName) {
            itemObj.authorName = itemInside.authorName;
          }
          resultNormalized.push(itemObj);
        });
      } else if (id === 'CFTV') {
        Object.entries(data || []).map(itemCFTV => {
          const dataCFTV: any = itemCFTV[1];
          dataCFTV.map(itemInside => {
            const idCFTV: string = itemCFTV[0];
            const itemObj: any = {};
            const stepName = this.violationsStepsResource.filter(step => step.id === idCFTV).shift();
            itemObj.id = idCFTV;
            itemObj.title = stepName ? stepName.value : null;
            itemObj.date = itemInside.date;
            if (itemInside.authorName) {
              itemObj.authorName = itemInside.authorName;
            }
            resultNormalized.push(itemObj);
          });
        });
      } else {
        const itemObj: any = {};
        itemObj.id = id;
        itemObj.title = this.violationsStepsResource?.filter(step => step.id === id).shift()?.value || null;
        itemObj.date = data.date;
        if (data.authorName) {
          itemObj.authorName = data.authorName;
        }
        resultNormalized.push(itemObj);
      }
    });

    resultNormalized.sort((a, b) => +new Date(b.date) - +new Date(a.date));
    return resultNormalized;
  }

  setResources(value) {
    this.violationsStepsResource = value;
  }

  exportCsv() {
    const params: any = {
      startDate: this.params['date[gte]'],
      endDate: this.params['date[lte]'],
      contractId : this.contractId
    };
    if (this.filter?.spotsIds?.length > 0) {
      params.spotsIds = this.filter.spotsIds;
    }
    if (this.filter?.laneIds?.length > 0) {
      params.laneIds = this.filter.laneIds;
    }
    if (this.filter?.situationsIds?.length > 0) {
      params.situationsIds = this.filter.situationsIds;
    }
    if (this.filter?.reasonsIds?.length > 0) {
      params.reasonsIds = this.filter.reasonsIds;
    }
    if (this.filter.numberId) {
      params.numberId = this.filter.numberId;
    }
    if (this.filter.numberPlate) {
      params.numberPlate =  this.filter.numberPlate;
    }
    if (this.filter.numberSerie) {
      params.numberSerie = this.filter.numberSerie;
    }
    if (this.filter?.regulationsIds?.length > 0) {
      params.regulationIds = this.filter.regulationsIds.map(Number);
    }
    if (this.filter?.steps?.length > 0) {
      params.steps = this.filter.steps;
    }
    const currentUser = this.storageService.get(StorageKey.currentUser);
    this.violationCSVService.violationViewCSV(params).then(async () => {
      await this.modalService.show(new MessageModal('Relatório CSV - Consulta de Infração',
      `Será enviado um email para ${currentUser.email} quando finalizar
      a geração do arquivo CSV. Favor aguarde`, false));
    }).catch(() => {
      this.alertService.show(new AlertItem('ReportCSV', AlertType.danger));
    });
  }

  getViolationStep(step) {
    const steps = {
      validateLane: 'Validar faixa',
      validateCalibration: 'Validar aferição',
      validateSpeed: 'Validar velocidade',
      validateFiles: 'Validar arquivo',
      validateExemptPeriod: 'Período isento',
      validateVehicleWhiteList: 'Lista branca',
      validateViolationLimit: 'Limite de infrações',
      verifyValid: 'Verificação de válidas',
      verifyInvalid: 'Verificação de inválidas',
      validateEquipment: 'Equipamento inválido',
      triage: 'Triagem',
      typing: 'Digitação',
      verify: 'Verifiação',
      validate: 'Validação',
      lotAttribution: 'Atribuição de lote',
      internalAudit: 'Auditoria interna',
      revision: 'Revisão',
      filter: 'Filtro',
      verifyCFTV: 'Verificacao CFTV',
      validateCFTV: 'Validacao CFTV',
      monitoringCFTV: 'Monitoramento CFTV',
      done: 'Concluído'
    };
    return steps[step];
  }

  getViolationSituation(status) {
    if (status !== 'valid' && status !== 'invalid') {
      return 'Em processamento';
    }
    if (status === 'valid') {
      return 'Válida';
    }
    if (status === 'invalid') {
      return 'Inválida';
    }
  }

  getStatusQuarantine(quarantineReason = null) {
    if (this.quarantineStatusI18n.length === 0 || quarantineReason == null) {
      return;
    }
    return this.quarantineStatusI18n.find(q => q.id === quarantineReason).value;
  }
}
