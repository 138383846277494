<div class="toast-container">
  <div class="alert alert-{{item.type}} show" role="alert" *ngFor="let item of items; let i = index">
    <span i18n>{item.message, select,
      InvalidCpf {CPF inválido.}
      UserEditError {Erro ao editar usuário.}
      ContractConfiged {Contrato configurado com sucesso.}
      ContractConfigError {Erro ao configurar contrato.}
      CalibrationSaveConflict {Já existe uma aferição com a mesma data de aferição ou numero do laudo ou habilitado no período.}
      CalibrationDateInvalid {A data da aferição deve ser igual ou menor que a data inicial de vigência da aferição.}
      CalibrationDeleted {Aferição removida com sucesso.}
      CalibrationDeleteError {Erro ao remover Aferição.}
      CalibrationSaved {Aferição salva com sucesso.}
      CalibrationSaveError {Erro ao salvar aferição}
      CalibrationSavePeriodError {Aferição já habilitada no período.}
      EquipmentSaved {Equipamento salvo com sucesso.}
      EquipmentSaveError {Erro ao salvar equipamento.}
      EquipmentSaveConflict {Já existe um equipamento com estes dados.}
      EquipmentDeleted {Equipamento removido com sucesso.}
      EquipmentDeleteError {Erro ao remover Equipamento.}
      ExemptPeriodSaved {Período isento salvo com sucesso.}
      ExemptPeriodSaveError {Erro ao salvar período isento.}
      ExemptPeriodDeleted {Período isento removido com sucesso.}
      ExemptPeriodDeleteError {Erro ao remover período isento.}
      ModuleUnavailable {Módulo em desenvolvimento.}
      LaneConfiged {Faixa configurada com sucesso.}
      LaneConfigError {Erro ao configurar faixa.}
      LaneSaved {Faixa salva com sucesso.}
      LaneSaveConflict {Já existe uma faixa com este código no contrato informado.}
      LaneSaveError {Erro ao salvar faixa.}
      LaneDeleted {Faixa removida com sucesso.}
      LaneDeleteError {Erro ao remover Faixa.}
      LotClosed {Lote fechado com sucesso.}
      LotClosedError {Erro ao fechar o lote.}
      LotRegenerateError {Erro ao regerar o lote.}
      LotRegenerate {Lote regerado com sucesso.}
      EnabledLot {Lote Ativo.}
      DisabledLot {Lote Inativo.}
      ErrorEditLot {Não foi possível alterar o lot.}
      AuditLotSpecial {Lote definido como especial.}
      AuditLotNotSpecial {Marcação de Lote especial removida.}
      AuditLotSpecialError {Erro ao definir Lote como especial.}
      RecoveryLinkSent {Enviamos um link de recuperação de senha para seu e-mail.}
      EmailNotFound {Este e-mail não possui cadastro em nossa base de dados.}
      InvalidLogin {Sua conta ou senha está incorreta.}
      AccessDenied {Usuário sem permissão de acesso.}
      ViolationInvalidationError {Falhou ao invalidar.}
      ViolationValidationError {Falhou ao validar.}
      ViolationSaved {Infração salva com sucesso.}
      ViolationRefused {Infração recusada com sucesso.}
      ViolationSaveError {Erro ao salvar infração.}
      ViolationDuplicity {Infração com enquadramento já selecionado na etapa anterior.}
      AitError {AIT não encontrado.}
      ViolationRefusedError {Erro ao recusar infração.}
      ProcessedViolationAlreadyExists {Já existe um registro processado para esta placa, enquadramento e período. Enquadramento com limite de infrações.}
      FramingNotEnabledForTrack {Enquadramento não habilitado para a faixa. Necessário configurar.}
      NoViolationsTyping {Não há infrações a serem processadas.}
      ViolationListError {Falha na busca das infrações.}
      UserGroupSaved {Grupo de Usuários salvo com sucesso.}
      UserGroupSaveConflict {Já existe um grupo com este nome cadastrado.}
      UserGroupSaveError {Erro ao salvar grupo de usuários.}
      UserGroupDeleted {Grupo removido com sucesso.}
      UserGroupDeleteError {Erro ao remover grupo de usuários.}
      UserGroupUpdate {Grupo de usuário atualizado com sucesso.}
      SpotGroupSaved {Grupo de pontos salvo com sucesso.}
      SpotGroupSaveConflict {Já existe um grupo com este nome cadastrado.}
      SpotGroupSaveError {Erro ao salvar grupo de pontos.}
      SpotGroupDeleted {Grupo removido com sucesso.}
      SpotGroupDeleteError {Erro ao remover grupo de pontos.}
      SpotGroupUpdate {Grupo de ponto atualizado com sucesso.}
      SpotGroupRemovedFromGroup {Ponto removido do grupo.}
      Unauthorized {Usuário não tem permissão.}
      UserRemovedFromGroup {Usuário removido do grupo.}
      PasswordSaved {Senha salva com sucesso.}
      PasswordSaveError {Erro ao salvar senha. Verifique se há espaço na senha criada}
      UserSaved {Usuário salvo com sucesso.}
      UserSaveConflict {Já existe um usuário com este email cadastrado.}
      UserSaveError {Erro ao salvar usuário.}
      UserDeleted {Usuário removido com sucesso.}
      UserEnabled {Usuário desabilitado com sucesso.}
      UserDisabled {Usuário habilitado com sucesso.}
      UserDeleteError {Erro ao remover usuário.}
      EmailSent {Email enviado com sucesso.}
      FileLimit {Limite de arquivos ultrapassado}
      SpotSaved {Ponto salvo com sucesso.}
      SpotSaveConflict {Já existe um ponto com este código no contrato informado.}
      SpotSaveError {Erro ao salvar ponto.}
      SpotDeleted {Ponto removido com sucesso.}
      SpotDeleteError {Erro ao remover ponto.}
      SpotNotDelete {Não é possível deletar este registro pois existe infração relacionada à ele.}
      ModelNotDelete {Não é possível deletar este registro pois existe infração relacionada à ele.}
      SuccessConfigLane {Faixa configurada com sucesso.}
      FailedConfigLane {Erro ao configurar faixa.}
      PasswordMismatch {As senhas digitadas não conferem.}
      InternalServerError {Erro interno no servidor.}
      ExemptPeriodUpdated {Período isento atualizado com sucesso.}
      ExemptPeriodUpdateError {Erro ao atualizar período isento.}
      PreProcessSaved {Configuração de pré processamento criado com sucesso.}
      PreProcessUpdated {Configuração de pré processamento atualizada com sucesso.}
      PreProcessSaveError {Erro ao criar onfiguração de pré processamento.}
      PreProcessUpdateError {Erro ao atualizar configuração de pré processamento.}
      ViolationImageSaved {Imagem atualizada com sucesso.}
      ViolationImageSaveError {Erro ao atualizar a imagem.}
      ViolationImageRestored {Imagem restaurada com sucesso.}
      ViolationImageRestoredError {Erro ao restaurar imagem.}
      GenericError {Algo deu errado.}
      FileImportError {Erro ao importar arquivo}
      FileImportSaved {Arquivo importado com sucesso}
      PlateNotFoundNotAuthorized {Placa não encontrada na pesquisa. A infração deverá ser invalidada.}
      PlateNotFoundAuthorized {Placa não encontrada na pesquisa. Os dados obrigatórios devem ser informados manualmente ou a infração deverá ser invalidada.}
      PlateNotRegistered {Placa informada não consta no cadastro.}
      PlateFoundWhitelist {Veículo cadastrado na lista branca. Este registro deve ser invalidado.}
      QuarantineProcessViolationsReprocess {Infração(ões) encaminhada(s) para ser(serem) reprocessada(s) com sucesso.}
      QuarantineProcessViolationsInvalidate { Infração(ões) invalidada(s) com sucesso}
      QuarantineProcessViolationsNextStep { Infração(ões) encaminhada(s) para a próxima etapa de validação com sucesso.}
      QuarantineProcessViolationsError {Erro ao processar as infrações.}
      SerialNumberConfigSaveError {Erro ao salvar faixa de série.}
      SerialNumberConfigUpdateError {Erro ao alterar faixa de série.}
      SerialNumberConfigDeleteError {Erro ao deletar faixa de série.}
      SerialNumberConfigDuplicityError {Faixa de série duplicada.}
      SerialNumberConfigSaveSuccess {Faixa de série cadastrada com sucesso.}
      SerialNumberConfigUpdateSuccess {Faixa de série alterada com sucesso.}
      SerialNumberConfigDeleteSuccess {Faixa de série deletada com sucesso.}
      SerialNumberConfigGenerationStarted {Não é possível atualizar a configuração após o início da geração}
      ViolationSaveWhiteList {Erro ao salvar, a placa está cadastrada na lista branca.}
      QuarantineRecountSuccess {Sistema irá realizar a atualização dos contadores, este procedimento pode demorar alguns minutos para ser finalizado. Necessário forçar a atualização da tela.}
      QuarantineRecountDanger {Erro ao atualizar contadores.}
      ViolationSourceSaved {Origem de Multa salvo com sucesso.}
      ViolationSourceSaveError {Erro ao salvar Origem de Multa.}
      ViolationSourceSaveConflict {Já existe um Origem de Multa com estes dados.}
      ViolationSourceDeleted {Origem de Multa removido com sucesso.}
      ViolationSourceDeleteError {Erro ao remover Origem de Multa.}
      ProcessViolationWhiteList {Este veículo está cadastrado na lista branca e será invalidado automaticamente com o motivo configurado para o contrato.}
      VehicleWhiteListSaved {Veículo Isento salvo com sucesso.}
      VehicleWhiteListSaveError {Erro ao salvar veículo isento.}
      VehicleWhiteListSaveConflict {Já existe uma placa como veículo isento.}
      VehicleWhiteListDeleted {Veículo Isento removido com sucesso.}
      VehicleWhiteListDeleteError {Erro ao remover Veículo Isento.}
      MonitoringUpdated {Configuração de ecsSpy atualizada com sucesso.}
      MonitoringCreated {Configuração de ecsSpy Criada com sucesso.}
      MonitoringError {Ocorreu um erro ao atualizar as configurações de ecsSpy.}
      AuditLotLocked {Lote bloqueado para o Auditor.}
      AuditLotUnlocked {Lote desbloqueado para o Auditor.}
      AuditLotLockedError {Erro ao bloquear lote para o Auditor.}
      AuditLotUnlockedError {Erro ao desbloquear lote para o Auditor.}
      SelectAuditLotUser {Necessário atribuir o lote para um Auditor.}
      AuditRequiredFields {1 ou mais Campos Requeridos não foram preenchidos.}
      ProcessAuditUnauthorized {Lote indisponível para processamento. Você será redirecionado para a tela de gerenciamento de lotes.}
      AuditLotPriorityLow {Lote definido com prioridade Baixa.}
      AuditLotPriorityMedium {Lote definido com prioridade Média.}
      AuditLotPriorityHigh {Lote definido com prioridade Alta.}
      AuditLotPriorityError {Erro ao definir prioridade do Lote.}
      AuditLotUserWithoutPermission {Usuário sem permissão de Auditoria.}
      AuditSaved {Auditoria finalizada com sucesso.}
      AuditSaveError {Erro ao salvar o lote auditado.}
      SetUserInAuditLot {Auditor atribuído ao lote.}
      disconnected {Atenção! Usuário acessando o sistema de outro lugar, por este motivo você foi desconectado. Para acessar o sistema, realize o login novamente}
      ViolationExpiredToEditing {Infração com tempo expirado para edição.}
      CanceledLotSuccess {Lote cancelado com sucesso.}
      CanceledLotError {Ocorreu um erro ao tentar cancelar o lote.}
      ReasonSaved {Motivo de invalidação salvo com sucesso.}
      ReasonSaveError {Erro ao salvar motivo de invalidação.}
      ReasonSaveConflict {Já existe uma motivo de invalidação com estes dados.}
      ReasonDeleted {Motivo removido de invalidação com sucesso.}
      BrandInternalDeleted {Marca do veículo removido de invalidação com sucesso.}
      ReasonDeleteError {Erro ao remover motivo de invalidação.}
      BrandInternalDeleteError {Erro ao remover marca do veículo.}
      OcrReportSaveError {Erro ao gerar CSV.}
      OcrReportSaved {Geração de Relatório CSV enviado com sucesso}
      AccessApiDigifortError {Ocorreu um erro ao acessar a API da câmera, tente regerar novamente.}
      AccessApiDigifortPanError {Ocorreu um erro ao acessar a API da câmera, capture a imagem Pan a partir do vídeo.}
      AccessApiDigifortZoomError {Ocorreu um erro ao acessar a API da câmera, capture a imagem Zoom a partir do vídeo.}
      ReportCSV {Ocorreu um erro ao exportar o Relatório. Refaça as permissões das telas Consulta de Infrações e tente novamente}
      GetRequiredFilesError {Ocorreu erro na geração das mídias, a infração sera encaminhada para etapa de Verificação CFTV.}
      EcsSpyRequiredFieldsWarning {Campos essenciais não preenhidos, infração encaminhada para Verificação.}
      BrandModelCreated {Marca do veículo criado com sucesso.}
      BrandModelEdited {Marca do veículo editado com sucesso.}
      BrandModelCreateError {Ocorreu um erro ao salvar a marca do veículo.}
      BrandModelEditedError {Ocorreu um erro ao editar a marca do veículo.}
    }</span>
    <button type="button" class="btn btn-icon-block text-white" data-dismiss="toast" aria-label="Close" (click)="close(item)">
      <i class="material-icons">close</i>
    </button>
  </div>
</div>
