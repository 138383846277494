<div class="container">
  <div class="page-title d-flex align-items-start mt-4">
    <div>
      <p class="page-title__link-back"><a (click)="backToPreProcess()" style="cursor:pointer" i18n>Pre-processamento</a></p>
      <h2 i18n>Etapa de {{stepName}}</h2>
    </div>
  </div>

  <div class="w-75 mx-auto mb-5">
    <h5 class="mb-4" i18n>Filtrar infrações:</h5>
    <form class="form-horizontal" [formGroup]="filterForm" *ngIf="loading; else loadingBlock">
      <div class="card mb-2">
        <div class="card-body pb-2">
          <div class="d-flex justify-content-between">
            <p class="mb-2" i18n>Selecione um contrato:</p>
            <div class="loading-inline d-none" [ngClass]="{ 'd-block': toggleClass.contract }">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>
            </div>
          </div>
          <app-input class="input-multiselect" i18n-label type="select" [list]="contracts" selectText="prettyName"
            selectValue="id" formControlName="contractId" selectShowLimit="1" placeholder="Selecione"></app-input>
        </div>
      </div>
      <ng-container *ngIf="showArea.spots">
        <div class="card mb-2">
          <div class="card-body pb-2">
            <div class="d-flex cursor-pointer" (click)="toggleCollapse('regulationBody')">
              <p class="mb-1" i18n>Enquadramento(s):</p>
              <p class="mb-1 ml-auto">
                <i class="material-icons" *ngIf="!showArea.regulationBody">expand_more</i>
                <i class="material-icons" *ngIf="showArea.regulationBody">expand_less</i>
              </p>
            </div>
            <div class="d-none mt-2" [ngClass]="{ 'd-block': showArea.regulationBody }">
              <app-input class="input-multiselect" i18n-label type="multiselect" [list]="regulations"
                selectText="prettyName" selectValue="id" formControlName="regulationsIds" selectShowLimit="1"
                placeholder="Selecione"></app-input>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body pb-2">
            <div class="d-flex cursor-pointer" (click)="toggleCollapse('equipmentBody')">
              <p class="mb-1" i18n>Equipamento:</p>
              <p class="mb-1 ml-auto">
                <i class="material-icons" *ngIf="!showArea.equipmentBody">expand_more</i>
                <i class="material-icons" *ngIf="showArea.equipmentBody">expand_less</i>
              </p>
            </div>
            <div class="d-none mt-2" [ngClass]="{ 'd-block': showArea.equipmentBody }">
              <app-input class="input-multiselect" i18n-label type="multiselect" [list]="equipments" selectText="serialNumber"
                selectValue="id" formControlName="equipmentsIds" selectShowLimit="1" placeholder="Selecione">
              </app-input>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body pb-2">
            <div class="d-flex cursor-pointer" (click)="toggleCollapse('spotBody')">
              <p class="mb-1" i18n>Ponto(s):</p>
              <p class="mb-1 ml-auto">
                <i class="material-icons" *ngIf="!showArea.spotBody">expand_more</i>
                <i class="material-icons" *ngIf="showArea.spotBody">expand_less</i>
              </p>
            </div>
            <div class="d-none mt-2" [ngClass]="{ 'd-block': showArea.spotBody }">
              <app-input class="input-multiselect" i18n-label type="multiselect" [list]="spots" selectText="prettyName"
                selectValue="id" formControlName="spotsIds" selectShowLimit="1" placeholder="Selecione"></app-input>
            </div>
          </div>
        </div>
        <div class="card mb-2" *ngIf="showArea.lanes; else loadingBlockLane">
          <div class="card-body pb-2">
            <div class="d-flex cursor-pointer" (click)="toggleCollapse('laneBody')">
              <p class="mb-1" i18n>Faixa(s):</p>
              <p class="mb-1 ml-auto">
                <i class="material-icons" *ngIf="!showArea.laneBody">expand_more</i>
                <i class="material-icons" *ngIf="showArea.laneBody">expand_less</i>
              </p>
            </div>
            <div class="d-none mt-2" [ngClass]="{ 'd-block': showArea.laneBody }">
              <app-input class="input-multiselect" i18n-label type="multiselect" [list]="lanes" selectText="prettyNames"
                selectValue="code" formControlName="laneIds" selectShowLimit="1" placeholder="Selecione"></app-input>
            </div>
          </div>
        </div>
        <div class="card mb-2" *ngIf="step !== 'typing' && step !== 'triage'">
          <div class="card-body pb-2">
            <div class="d-flex cursor-pointer" (click)="toggleCollapse('situationBody')">
              <p class="mb-1" i18n>Situaçõe(s):</p>
              <p class="mb-1 ml-auto">
                <i class="material-icons" *ngIf="!showArea.situationBody">expand_more</i>
                <i class="material-icons" *ngIf="showArea.situationBody">expand_less</i>
              </p>
            </div>
            <div class="d-none mt-2" [ngClass]="{ 'd-block': showArea.situationBody }">
              <app-input class="input-multiselect" i18n-label type="multiselect" [list]="situations" selectText="name"
                selectValue="id" formControlName="situationsIds" selectShowLimit="1" placeholder="Selecione">
              </app-input>
            </div>
          </div>
        </div>
        <div class="card mb-2" *ngIf="showArea.reasons && step !== 'typing' && step !== 'triage'">
          <div class="card-body pb-2">
            <div class="d-flex cursor-pointer" (click)="toggleCollapse('reasonBody')">
              <p class="mb-1" i18n>Motivo da invalidação(s):</p>
              <p class="mb-1 ml-auto">
                <i class="material-icons" *ngIf="!showArea.reasonBody">expand_more</i>
                <i class="material-icons" *ngIf="showArea.reasonBody">expand_less</i>
              </p>
            </div>
            <div class="d-none mt-2" [ngClass]="{ 'd-block': showArea.reasonBody }">
              <app-input class="input-multiselect" i18n-label type="multiselect" [list]="reasons"
                selectText="prettyName" selectValue="id" formControlName="reasonsIds" selectShowLimit="1"
                placeholder="Selecione"></app-input>
            </div>
          </div>
        </div>
        <div class="card mb-2" *ngIf="step !== 'typing' && step !== 'triage'">
          <div class="card-body pb-2">
            <div class="d-flex cursor-pointer" (click)="toggleCollapse('moreBody')">
              <p class="mb-1" i18n>Série, placa ou identificação:</p>
              <p class="mb-1 ml-auto">
                <i class="material-icons" *ngIf="!showArea.moreBody">expand_more</i>
                <i class="material-icons" *ngIf="showArea.moreBody">expand_less</i>
              </p>
            </div>
            <div class="d-none mt-2" [ngClass]="{ 'd-block': showArea.moreBody }">
              <app-input i18n-label type="text" formControlName="numberSerie" type="text"
                label="Série da infração: (opcional)"></app-input>
              <app-input i18n-label type="text" formControlName="numberPlate" type="text"
                label="Placa do veículo: (opcional)"></app-input>
              <app-input i18n-label type="text" formControlName="numberId" type="text"
                label="Identificação da infração: (opcional)"></app-input>
            </div>
          </div>
        </div>
        <div class="card mb-2" *ngIf="step !== 'typing' && step !== 'triage'">
          <div class="card-body pb-2">
            <div class="d-flex cursor-pointer" (click)="toggleCollapse('authorBody')">
              <p class="mb-1" i18n>Autor da digitação:</p>
              <p class="mb-1 ml-auto">
                <i class="material-icons" *ngIf="!showArea.authorBody">expand_more</i>
                <i class="material-icons" *ngIf="showArea.authorBody">expand_less</i>
              </p>
            </div>
            <div class="d-none mt-2" [ngClass]="{ 'd-block': showArea.authorBody }">
              <app-input class="input-multiselect" i18n-label type="multiselect" [list]="usersByContract"
                selectText="name" selectValue="id" formControlName="authorIds" selectShowLimit="1"
                placeholder="Selecione"></app-input>

              <!-- <app-input i18n-label type="text" formControlName="authorId" type="text" label="Id do autor: (opcional)">
              </app-input> -->
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body d-flex justify-content-between align-items-center">
            <p i18n class="mb-0">Período:</p>
            <p (click)="selectModalPeriod()" class="d-flex align-items-center mb-0 cursor-pointer">
              <i class="material-icons mr-2">date_range</i>
              <span i18n>De {{ startDate | moment: 'L' }} à {{ endDate | moment: 'L' }}</span>
            </p>
          </div>
        </div>
      </ng-container>
      <div class="d-flex mt-4 justify-content-end">
        <button type="button" class="btn mr-3 margin-left" [ngClass]="{ 'btn-secondary': true }"
          i18n (click)="backToPreProcess()">Voltar</button>
        <button *ngIf="showArea.spots" type="submit" class="btn" [ngClass]="{ 'btn-primary': true, disabled: !isValid }"
          i18n (click)="submitFilter()">Continuar</button>
      </div>
    </form>
    <ng-template #loadingBlock>
      <div class="loading-page">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
