export const validateCpf = (cpf: string) => {
  if((cpf = cpf.replace(/[^\d]/g,'')).length !== 11){
    return false;
  }

  if (cpf === cpf[0].repeat(11) ){
    return false;
  }

  let rest: number;
  let soma = 0;

  for (let i=1; i<=9; i++){
    soma = soma + Number(cpf[i-1]) * (11 - i);
  }

  rest = (soma * 10) % 11;

  if ((rest === 10) || (rest === 11)){
    rest = 0;
  }

  if (rest !== Number(cpf[9])){
    return false;
  }

  soma = 0;

  for (let i = 1; i <= 10; i++){
    soma = soma + Number(cpf[i-1]) * (12 - i);
  }

  rest = (soma * 10) % 11;

  if ((rest === 10) || (rest === 11)){
    rest = 0;
  }

  if (rest !== Number(cpf[10])){
    return false;
  }

  return true;
};
