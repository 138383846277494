<div class="container">
  <div class="page-title page-title--columns">
    <div class="page-title__left"><h2 i18n>Motivos de Invalidação</h2></div>
    <div class="page-title__right" *appPermission="'CreateReason'">
      <button i18n type="button" class="btn btn-fade-primary" title="Cadastrar Motivo de Invalidação"
        (click)="openCreateOrUpdateReason()">Novo Motivo de Invalidação</button>
    </div>
  </div>
  <div class="mb-4">
    <form [formGroup]="searchForm" (submit)="handleSearch()">
      <div class="input-group">
        <input type="text" class="form-control form-search" formControlName="search" i18n-placeholder
          placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">
      </div>
    </form>
  </div>
  <div class="mt-5 mb-3">
    <div class="table-responsive table-hover table-block">
      <table class="table bg-white">
        <thead class="bg-header-table">
          <tr>
            <th i18n *appPermission="'UpdateReason'">Habilitado</th>
            <th i18n class="text-center">Contrato</th>
            <th i18n class="text-center">Código</th>
            <th i18n class="col-3 text-center">Nome</th>
            <th i18n class="text-center">Enquadramentos</th>
            <th i18n class="text-center">Tipo</th>
            <th i18n *appPermission="['UpdateReason','DeleteReason']" class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let reason of reasons">
            <td *appPermission="'UpdateReason'">
              <div class="custom-control custom-switch mt-2">
                <div class="form-group">
                  <label class="custom-control-label" [ngClass]="{checked:reason.enabled}">
                    <input type="checkbox" class="form-control custom-control-input"
                    *appPermission="'UpdateReason'; contracts reason.contractId"
                    [(ngModel)]="reason.enabled"
                    (change)="updateReasonEdit(reason)">
                  </label>
                </div>
              </div>
            </td>
            <td [appTooltip]="contracts[reason.contractId]?.name" (click)="openReasonView(reason)">
              {{ contracts[reason.contractId] && contracts[reason.contractId].code }}
            </td>
            <td class="text-center" (click)="openReasonView(reason)">{{reason.code}}</td>
            <td class="text-center" (click)="openReasonView(reason)">{{reason.name || '-'}}</td>
            <td (click)="openReasonView(reason)">
              <div *ngFor="let regulationId of reason.regulationIds" class="badge badge-secondary mr-1">
                <i *ngIf="regulationById[regulationId]" i18n-appTooltip
                  appTooltip="{{regulationById[regulationId].description || ''}}">
                  {{ regulationById[regulationId]?.code }}
                </i>
              </div>
            </td>
            <td class="text-center" (click)="openReasonView(reason)">{{reason.type || '-'}}</td>
            <td class="text-center" *appPermission="['UpdateReason','DeleteReason']; contracts reason.contractId">
              <button type="button" class="btn text-secondary btn-icon-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">more_horiz</i>
              </button>
              <div class="dropdown-menu" aria-labelledby="btnGroupDrop4">
                  <a class="dropdown-item" (click)="openCreateOrUpdateReason(reason)" i18n
                  *appPermission="['UpdateReason']; contracts reason.contractId">
                    Editar Motivo
                  </a>
                  <a class="dropdown-item" (click)="openDeleteModal(reason)" i18n
                  *appPermission="['DeleteReason']; contracts reason.contractId">
                    Remover Motivo
                  </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-pagination (items)="setReasons($event)" [params]="params" [deleteEvent]="deleteEvent" [service]="reasonService" [permission]="'ViewReason'">
  </app-pagination>
</div>
