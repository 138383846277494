import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Reason, PreProcessSettings, Classification } from 'src/app/core/models';
import { Regulation } from 'src/app/core/models/Regulation';
import { ClassificationService, ContractService, ReasonService } from 'src/app/core/services';
import { RegulationService } from 'src/app/core/services/regulation.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-pre-process-config-violation-limit',
  templateUrl: './config-violation-limit.component.html',
  styleUrls: ['./config-violation-limit.component.sass']
})
export class ConfigViolationLimitComponent implements OnInit {
  @Input() preProcessSettings: PreProcessSettings = new PreProcessSettings();
  @Output() nextStepCommand = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  @Output() isValid = new EventEmitter();
  public conditionPeriod = [{t: 'Periodo', v: false}, {t: 'Tempo', v: true}];
  public regulations: Array<Regulation> = [];
  public regulationsMap: Array<Regulation> = [];
  public classifications: Array<Classification> = [];
  public reasons: Array<Reason>;
  public violationLimitForms: Array<{ formTitle: string; selectionForm: FormGroup; periodForms: Array<FormGroup>; isTime: boolean }> = [];

  private get _isValid() {
    for (const limitForm of this.violationLimitForms) {
      if (!limitForm.selectionForm.valid) {
        return false;
      }
      if(limitForm.isTime){
        if(!limitForm.periodForms[0].get('time').valid){
          return false;
        }
      } else if(!limitForm.periodForms.every(
        periodForm => periodForm.get('startHour').valid && periodForm.get('endHour').valid
      )){
      return false;
    }
    }
    return true;
  }

  constructor(
    public regulationService: RegulationService,
    public classificationService: ClassificationService,
    public activatedRoute: ActivatedRoute,
    public reasonService: ReasonService,
    public formBuilder: FormBuilder,
    public contractService: ContractService
  ) {
  }

  ngOnInit() {
    const contractId = this.activatedRoute.snapshot.params.contractId;
    this.reasonService.getAll({ contractId }).then(data => {
      this.reasons = data.sort((a, b): any => Number(a.code) - Number(b.code));
      this.contractService.getById(contractId).then(contract => {
        this.regulationService.getAll({ regionId: contract.regionId }).then(regulations => {
          this.regulations = regulations;
          regulations.forEach(regulation => {
            this.regulationsMap[regulation.id] = regulation;
            this.classificationService.getAll({ regionId: contract.regionId }).then(classifications => {
              this.classifications = classifications;
            });
          });
        });
      });
    });
    if (this.preProcessSettings && this.preProcessSettings.violationLimit && this.preProcessSettings.violationLimit.length >= 1) {
      this.createEditFormValues(this.preProcessSettings.violationLimit);
    }
    this.isValid.emit(this._isValid);
    this.outputValue.emit(this.getOutput());
  }

  createSelectForm(regulationId = '', reasonId = '', classificationIds = []) {
    const form = this.formBuilder.group({
      regulationId: [regulationId],
      reasonId: [reasonId, Validators.required],
      classificationIds: [classificationIds]
    });
    form.valueChanges.subscribe(() => {
      this.isValid.emit(this._isValid);
      if (this._isValid) {
        this.outputValue.emit(this.getOutput());
      }
    });
    return form;
  }

  createPeriodForms(startHour = '', endHour = '', time = 0) {
    const hourRegex = new RegExp(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/);
    const form = this.formBuilder.group({
      time: [time],
      startHour: [startHour],
      endHour: [endHour]
    });
    form.get('startHour').setValidators([
      Validators.required,
      this.validateHour.bind(this, form),
      Validators.pattern(hourRegex)
    ]);
    form.get('endHour').setValidators([
      Validators.required,
      this.validateHour.bind(this, form),
      Validators.pattern(hourRegex)
    ]);
    form.get('time').setValidators([
      Validators.required,
      Validators.min(1)
    ]);
    form.valueChanges.subscribe(() => {
      this.isValid.emit(this._isValid);
      if (this._isValid) {
        this.outputValue.emit(this.getOutput());
      }
    });
    return form;
  }

  toggle(){
    this.isValid.emit(this._isValid);
  }

  createForm(): void {
    const newForm = {
      isTime: false,
      selectionForm: this.createSelectForm(),
      periodForms: [this.createPeriodForms()],
      formTitle: ''
    };
    this.violationLimitForms.unshift(newForm);
    this.isValid.emit(this._isValid);
  }

  createEditFormValues(violationLimits: PreProcessSettings['violationLimit'] = []) {
    violationLimits.forEach(violationLimit => {
      const isTime = violationLimit.periods.some(e => !!e.time);
      const form = {
        isTime,
        selectionForm: null,
        periodForms: [],
        formTitle: ''
      };
      form.selectionForm = this.createSelectForm(violationLimit.regulationId, violationLimit.reasonId);
      form.selectionForm.get('regulationId').valueChanges.subscribe(value => {
        form.formTitle = this.regulations.find(regulation => regulation.id === value).prettyName;
      });
      const periodForms = [];
      for (const period of violationLimit.periods) {
        if (isTime) {
          periodForms.push(this.createPeriodForms('', '', period.time));
          break;
        }
        periodForms.push(this.createPeriodForms(period.startTime, period.endTime, 0));
      }
      form.periodForms = periodForms;
      this.violationLimitForms.push(form);
    });
  }

  createNewPeriodForm(index) {
    const form = this.createPeriodForms();
    this.violationLimitForms[index].periodForms.push(
      form
    );
  }

  removePeriodForm(indexViolitionLimitForms, indexPeriodForms) {
    this.violationLimitForms[indexViolitionLimitForms].periodForms.splice(indexPeriodForms, 1);
  }

  validateHour = (form: FormGroup) => {
    const hourControls: { [key: string]: AbstractControl } = {};
    const newDateValues = {};
    for (const key of Object.keys(form.controls)) {
      hourControls[key] = form.get(key);
      newDateValues[key] = hourControls[key].value;
    }
    if (hourControls.endHour.pristine || hourControls.startHour.pristine) {
      return null;
    } else if (hourControls.endHour.value <= hourControls.startHour.value) {
      return { invalidPeriod: 'Fields startHour greater than endHour' };
    } else {
      hourControls.startHour.setErrors(null);
      hourControls.endHour.setErrors(null);
      return null;
    }
  };

  getOutput() {
    const violationLimit = [];
    this.violationLimitForms.forEach(violationLimitForm => {
      const regulationId = violationLimitForm.selectionForm.controls.regulationId.value;
      const reasonId = violationLimitForm.selectionForm.controls.reasonId.value;
      const classificationIds = violationLimitForm.selectionForm.controls.classificationIds.value;
      const periods = [];
      for (const periodForm of violationLimitForm.periodForms) {
        if (violationLimitForm.isTime) {
          periods.push({ time: Number(periodForm.controls.time.value) });
          break;
        }
        periods.push({
          startTime: `${periodForm.controls.startHour.value}`,
          endTime: `${periodForm.controls.endHour.value}`
        });
      };
      violationLimit.push({
        regulationId,
        reasonId,
        periods,
        classificationIds
      });
    });
    return { violationLimit };
  }

  removeViolationLimit(index) {
    this.violationLimitForms.splice(index, 1);
    this.isValid.emit(this._isValid);
    if (this._isValid === true) {
      this.outputValue.emit(this.getOutput());
    }
  }
}
