import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService } from 'src/app/core/services';
import { Steps } from 'src/app/core/models';

@Component({
  selector: 'app-message-duplicate-violation-modal',
  templateUrl: './message-duplicate-violation-modal.component.html',
  styleUrls: ['./message-duplicate-violation-modal.component.sass']
})
export class MessageDuplicateViolationModalComponent extends BaseModal implements OnInit {
  public violation;
  isValid = true;
  public stepI18n = [];
  public steps = Object.keys(Steps);

  constructor(
    public modalService: ModalService
  ) {
    super(modalService);
   }

  ngOnInit() {
    this.violation = this.initialState;
  }

  get lastState(): any {
    return null;
  }

  async onAllSubmited(): Promise<any> { }

  getStep(step) {
    if (this.stepI18n.length === 0 || step == null) {
      return;
    }
    return  this.stepI18n.find(p => p.id === step).value;
  }
}
