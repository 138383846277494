import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserGroupsListComponent } from './user-groups-list/user-groups-list.component';
import { UserGroupsRoutingModule } from './user-groups-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserSelectModalComponent } from './user-select-modal/user-select-modal.component';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    UserGroupsListComponent,
    UserSelectModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    UserGroupsRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    UserSelectModalComponent
  ]
})
export class UserGroupsModule { }
