<div class="modal-dialog modal-sm modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Selecionar período</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="modal-body">
      <div class="calendar">
        <div class="calendar__left">
          <div class="btn-group-vertical" role="group" aria-label="Buttons range">
            <button type="button" class="btn btn-fade-secondary" (click)="rangePeriod(date[0], date[1])" i18n *ngFor="let date of rangeDates">
              <span *ngIf="date[1] === 'days'">Últimos {{ date[0] }} dias</span>
              <span *ngIf="date[0] === 1 && date[1] === 'month'">Últimos 30 dias</span>
              <span *ngIf="date[0] > 2 && date[1] === 'month'">Últimos {{ date[0] }} meses</span>
            </button>
          </div>
        </div>
        <div class="calendar__right">
          <form [formGroup]="form" class="form">
            <div class="form-group mr-2">
              <label i18n>De</label>
              <div class="input-group">
                <input [matDatepicker]="startDatePicker" (click)="startDatePicker.open()" readonly class="form-control" [max]="maxDateStart"
                [min]="minDate" formControlName="startDate" [ngClass]="{'is-invalid': form.errors}">
                <mat-datepicker #startDatePicker></mat-datepicker>

                <div class="input-group-prepend">
                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                </div>

                <div *ngIf="form.errors" class="invalid-feedback">
                  <i class="material-icons icon-sm mr-1">info</i>
                  <span i18n>Data inicial não pode ser superior a data final.</span>
                </div>
              </div>
            </div>

            <div class="form-group mr-2">
              <label i18n>Até</label>
              <div class="input-group">
                <input [matDatepicker]="endDatePicker" (click)="endDatePicker.open()" readonly class="form-control" [min]="minDate"
                [max]="maxDate" formControlName="endDate">
                <mat-datepicker #endDatePicker></mat-datepicker>
                <div class="input-group-prepend">
                  <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal-footer justify-content-end">
      <button (click)="onCancel()" class="btn btn-outline-secondary mr-2" i18n>Cancelar</button>
      <button (click)="onSubmit()" [ngClass]="{ btn: true, 'btn-primary': true, disabled: !isValid }" i18n>Selecionar</button>
    </div>
  </div>
</div>
