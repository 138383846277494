import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { UsersListComponent } from './users-list/users-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserViewComponent } from './user-view/user-view.component';
import { UsersEditModalComponent } from './users-edit-modal/users-edit-modal.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    UsersListComponent,
    MyProfileComponent,
    UserViewComponent,
    UsersEditModalComponent,
    UserCreateComponent,
    ChangePasswordModalComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    UsersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  entryComponents: [
    UserViewComponent,
    UsersEditModalComponent,
    UserCreateComponent,
    ChangePasswordModalComponent
  ]
})
export class UsersModule { }
