import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService, StorageKey } from '../services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private storageService: StorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const accessToken = this.storageService.get(StorageKey.accessToken);
      if (accessToken && !request.headers.get('Authorization')) {
        request = request.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${accessToken}`
          }
        });
      }
      return next.handle(request);
    }
}
