<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Infração Duplicada</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body label">
      <div class="row ml-3">
        <p i18n>Já existe uma infração para o enquadramento selecionado.</p>
      </div>
      <div class="container">
        <div class="capture-block">
          <div class="row">
            <div class="col col-md-4">
              <span i18n>Identificação da Infração</span>
              {{ violation?.passId }}
            </div>
            <div class="col col-md-2">
              <span i18n>Enquadramento</span>
              <div>
                <strong>{{violation?.regulationId}}</strong>
              </div>
            </div>
            <div class="col col-md-2">
              <span i18n>Data</span>
                <div>
                  {{ violation?.date | moment: 'L'}}
                </div>
            </div>
            <div class="col col-md-2">
              <span i18n>Hora</span>
              <div>
                {{ violation?.date | moment: 'LTS'}}
              </div>
            </div>
            <app-enum-i18n [input]="steps" (output)="stepI18n=$event"></app-enum-i18n>
            <div class="col col-md-2">
              <span i18n>Etapa</span>
              <div>
                {{ getStep(violation?.step)}}
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col col-md-2">
              <span i18n>Faixa</span>
              <div>
                {{ violation?.laneCode }}
                <p class="d-inline" *ngIf="violation?.laneNumber" i18n>(Fx</p>
                <p class="d-inline" *ngIf="violation?.laneNumber">&nbsp;{{ violation?.laneNumber }})</p>
              </div>
            </div>
            <div class="col col-8" *ngIf="violation?.address">
              <span i18n>Local</span>
              <div>
                {{ violation?.address?.street }}
              </div>
            </div>
            <div class="col col-md-2">
              <span i18n>Série Equipamento</span>
              <div>
                {{ violation?.equipment?.serialNumber }}
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <p i18n>Necessário alterar o enquadramento ou cancelar o registro atual.</p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button i18n (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true}">Confirmar</button>
    </div>
  </div>
</div>
