<div class="page-title d-flex align-items-start">
  <div>
    <p class="page-title__link-back"><a routerLink="/pre-process" i18n>Pré-processamento</a></p>
    <h2 i18n>Relatório de produção</h2>
  </div>
</div>

<div class="d-flex">
  <!-- <div class="d-flex mr-3">
    <button class="btn btn-fade-secondary btn-icon-block mr-2"><i class="material-icons">view_comfy</i></button>
    <button class="btn btn-fade-secondary btn-icon-block"><i class="material-icons">dehaze</i></button>
  </div> -->
  <div class="w-100">
    <input type="text" class="form-control form-search" [(ngModel)]="searchValue"
    placeholder="Buscar registro" (ngModelChange)="searchKey($event)">
  </div>
</div>

<div class="row pb-5" *ngIf="loading; else loadingBlock">
  <div class="col-12 col-sm-4 mt-4" *ngFor="let contract of filteredContractsList">
    <div class="card card-link">
      <button class="btn btn-fade-secondary btn-icon" (click)="updateCounters(contract)">
        <i class="material-icons" id="loadingRecount{{ contract.id }}">sync</i> Atualizar contadores
      </button>
      <a routerLink="{{ contract.id }}" class="text-dark no-hover">
        <div class="card-header">
          <p class="h4 mb-0">{{ contract.id }}</p>
          <p class="text-secondary mb-0">{{ contract.name }}</p>
        </div>
        <div class="card-body">
          <p class="mb-2" i18n>Registros processados por etapa: <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Resultado para os últimos 7 dias">info</i></p>
          <div class="d-flex mb-4">
            <div *ngIf="contract.steps.includes('typing')" class="mr-3">
              <p i18n class="mb-0"><small class="d-block">Digitação</small></p>
              <p class="h5 mb-0">{{ contract.typing }}</p>
            </div>

            <div *ngIf="contract.steps.includes('verifyValid')" class="mr-3">
              <p i18n class="mb-0"><small class="d-block">Verificação <br />de válidas</small></p>
              <p class="h5 mb-0">{{ contract.verifyValid }}</p>
            </div>

            <div *ngIf="contract.steps.includes('verifyInvalid')" class="mr-3">
              <p i18n class="mb-0"><small class="d-block">Verificação <br />de inválidas</small></p>
              <p class="h5 mb-0">{{ contract.verifyInvalid }}</p>
            </div>

            <div *ngIf="contract.steps.includes('validate')">
              <p i18n class="mb-0"><small class="d-block">Validação</small></p>
              <p class="h5 mb-0">{{ contract.validate }}</p>
            </div>
          </div>

          <div class="d-flex">
            <div class="mr-5">
              <p i18n class="mb-0"><small class="d-block">Total de horas<br /> trabalhadas</small></p>
              <p class="h5 mb-0">{{ setTime(contract.timeWorked) }}</p>
            </div>

            <div>
              <p i18n class="mb-0"><small class="d-block">Média <br />por registro</small></p>
              <p class="h5 mb-0">{{ setTime(contract.timeWorked / contract.totalWork) }}</p>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>
