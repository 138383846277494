import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Company, ComponentModal, Contract, Modules } from 'src/app/core/models';
import { ContractService, ModalService } from 'src/app/core/services';
import { EquipmentModelPanelComponent } from '../equipment-model-panel/equipment-model-panel.component';
import { ConfigGeneralModelPanelComponent } from '../config-general-model-panel/config-general-model-panel.component';
import { ClosedModalError } from 'src/app/core/errors';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.sass']
})
export class ContractListComponent {
  public contracts: Contract[] = [];
  public params = {};
  public searchValue = '';
  public noRegister = false;
  public modules = Object.values(Modules);
  public modulesI18n = [];
  public companyNamesById: { [params: number]: Array<Company> } = {};
  public modalState;

  permissions = [];
  public user: any = {};

  private searchChanged: Subject<string> = new Subject<string>();

  constructor(
    public contractService: ContractService,
    private modalService: ModalService,
  ) {
    this.searchChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  handleSearch() {
    this.noRegister = false;
    this.contracts = [];
    const search = this.searchValue;
    const filterObj = {};
    if (search != null && search !== '') {
      filterObj['name[contains,or]'] = `${search}`;
      filterObj['code[contains,or]'] = `${search}`;
    }
    this.params = filterObj;
  }

  getModules(module: any[]) {
    const modulesContract = [];
    for (const i of module) {
      if (this.modulesI18n.length > 0) {
        modulesContract.push(this.modulesI18n.find(mod => mod.id === i)?.value);
      }
    }
    return modulesContract.join(', ');
  }

  searchKey(text: string) {
    this.searchChanged.next(text);
  }

  handleItems(e) {
    this.contracts = this.contracts.concat(e);
    if (this.contracts.length === 0) {
      this.noRegister = true;
    }
    this.contracts.map(contract => {
      const companies = [];
      contract.companies.map(company => {
        if (company.id !== contract.ownerCompanyId) {
          companies.push(company.name);
        };
      });
      this.companyNamesById[contract.id] = companies.join(', ');
    });
  }

  openEquipmentModelPanel(contract) {
    this.modalService.show(new ComponentModal(EquipmentModelPanelComponent, contract))
      .then(() => this.handleSearch())
      .catch(() => { });
  }

  openConfigGeneralModelPanel(contract, type = null, value = null) {
    if (type) {
      contract[type] = value;
    }
    this.modalService.show(new ComponentModal(ConfigGeneralModelPanelComponent, contract))
      .then((data) => {
        this.handleSearch();
      })
      .catch((err) => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
          this.modalState = (modalError.modal as ComponentModal).component.instance.lastState;
        } else {
          this.modalState = null;
        }
      });
  }
}
