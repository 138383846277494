import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpotsListComponent } from './spots-list/spots-list.component';
import { SpotsRoutingModule } from './spots.routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from 'src/app/core/core.module';
import { SpotsListMapComponent } from './spots-list-map/spots-list-map.component';
import { AgmCoreModule } from '@agm/core';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [
    SpotsListComponent,
    SpotsListMapComponent
  ],
  imports: [
    MatExpansionModule,
    MatListModule,
    MatCheckboxModule,
    CommonModule,
    SharedModule,
    CoreModule,
    SpotsRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: process.env.googleMapsKey
    })
  ],
  exports: [SpotsListComponent, SpotsListMapComponent]
})
export class SpotsModule { }
