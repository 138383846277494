<div class="container">
  <div class="filter mb-4">
    <div class="filter__header" (click)="toggleCollapse()">
      <h5 i18n>Filtrar relatório</h5>

      <button class="btn btn-sm btn-icon-block"
        [ngClass]="{ 'btn-outline-secondary': !collapse, 'btn-secondary': collapse }">
        <i class="material-icons" *ngIf="collapse">unfold_more</i>
        <i class="material-icons" *ngIf="!collapse">unfold_less</i>
      </button>
    </div>

    <div [class.collapsed]="collapse">
      <form class="form-horizontal" [formGroup]="filterForm">
        <div class="filter__area">
          <!-- <div class="filter__block">
            <label i18n>Contrato:</label>
            <app-multiselect i18n=label type="radio" formControlName="contractId" inputRequired=true
              [list]="contracts" i18n-placeholder buttonLabel="Selecione um contrato" selectValue="id"
              selectText="prettyName">
            </app-multiselect>
          </div> -->
          <div class="filter__block">
            <label i18n>Usuario:</label>
            <app-multiselect type="radio" [list]="users" formControlName="userIds" selectValue="id" selectText="name"
              itemsOnList="0" buttonLabel="Usuários" charactersOnItem="10" inputRequired=true>
            </app-multiselect>
          </div>

          <div class="filter__block">
            <label i18n>Ação:</label>
            <app-multiselect type="inline" [list]="actionsI18n" formControlName="actionIds" selectValue="id"
              selectText="value" itemsOnList="0" buttonLabel="Ações" charactersOnItem="10">
            </app-multiselect>
          </div>

          <div class="filter__block">
            <label i18n>Modelo:</label>
            <app-multiselect type="inline" [list]="modelsI18n" formControlName="modelIds" selectValue="id"
              selectText="value" itemsOnList="0" buttonLabel="Modelos" charactersOnItem="10">
            </app-multiselect>
          </div>
        </div>

        <div class="filter__button">
          <button type="submit" class="btn btn-outline-secondary mr-3" i18n (click)="redefineFilter()">Redefinir
            filtro</button>
          <button type="submit" class="btn" [ngClass]="{ 'btn-primary': true, disabled: !isValid }" i18n
            (click)="submitFilter()">Aplicar filtro</button>
        </div>
      </form>

      <ng-template #loadingBlock>
        <div class="loading-page">
          <div class="loading-spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="table-flex mb-4">
    <div class="table-flex__header">
      <div class="w-30">
        <div class="period-filter" (click)="selectModalPeriod()">
          <p class="period-filter__label">Período:</p>
          <p class="period-filter__area" *ngIf="start">
            <i class="material-icons">date_range</i>
            <span i18n>De {{ start | moment: 'L' }} à {{ end | moment: 'L' }}</span>
          </p>
        </div>
      </div>
      <!-- <div class="w-60">
        <form [formGroup]="searchForm" (submit)="handleSearch()">
          <input type="text" class="form-control form-search" formControlName="search" i18n-placeholder
            placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">
        </form>
      </div> -->
    </div>
    <app-enum-i18n [input]="actions" (output)="actionsI18n=$event"></app-enum-i18n>
    <app-enum-i18n [input]="models" (output)="modelsI18n=$event"></app-enum-i18n>
    <div class="table-flex__item" *ngFor="let activity of activities; let i = index">
      <div class="table-flex__row" *ngIf="usersById && usersById[activity.userId]">
        <p class="w-5 d-flex align-items-center" *ngIf="usersById && usersById[activity.userId]">
          <app-avatar [user]="usersById[activity.userId]" class="avatar-header"></app-avatar>
        </p>
        <p (click)="openViewModal(activity)" class="w-70 d-flex align-items-center">
          <span>{{ (usersById[activity.userId].name || activity.userId) }}</span>
          <span class="ml-1" i18n>realizou</span>
          <span class="ml-1">{{ getActionOrModel(activity.actionId, 1) }}</span>
          <span class="ml-1">{{ getActionOrModel(activity.actionId, 2) }}</span>
          <span class="ml-1" *ngIf="activity.contractId" i18n>no contrato</span>
          <span class="ml-1" *ngIf="activity.contractId" i18n>{{ activity.contractId }}</span>
        </p>
        <p (click)="openViewModal(activity)" class="w-25 d-flex align-items-center">
          {{ activity.date | moment: 'L LTS' }}
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <app-infinite-scroll (items)="setActivities($event)" [params]="params" (clear)="activities=[]"
        [service]="activityService">
      </app-infinite-scroll>
    </div>
  </div>
</div>
