import { BaseApiModel } from './BaseApiModel';

export class ExemptPeriod extends BaseApiModel {
  public contractId: string;
  public name: string;
  public startsAt: Date;
  public endsAt: any[];
  public reasonId: string;
  public regulationIds: Array<string>;
  public classificationIds: Array<string>;
  public enabled: boolean;
  public laneIds: Array<string>;
  public modifiedAt: Date;
  public createdAt: Date;
}
