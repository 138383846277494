<div class="form-group">
  <label *ngIf="label" i18n>{{ label }}</label>

  <div [ngSwitch]="type">
    <div *ngSwitchCase="'inline'" class="select-area select-area--inline">

      <div *ngIf="multiselectValue.length !== list.length">
        <div class="select-area__drop" *ngFor="let item of multiselectValue | slice: 0: itemsOnList">

          <span *ngIf="!charactersOnItem; else showTooltip">
            {{ item[selectText] }}
          </span>

          <ng-template #showTooltip>
            <span data-placement="top" i18n-appTooltip appTooltip="{{ item[selectText] }}">
              {{ item[selectText] | slice:0:charactersOnItem }} ...
            </span>
          </ng-template>

          <button
            class="btn btn-icon-block btn-sm"
            (click)="removeItem(item[selectValue])">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>

      <button class="btn btn-sm btn--add" [disabled]="inputDisabled"
        (click)="selectModalValues()" [ngClass]="{'btn-fade-secondary': inputDisabled,
        'btn-info': multiselectValue.length === list.length, 'btn-fade-info': multiselectValue.length !== list.length}">
        <span *ngIf="multiselectValue.length > 0" i18n>
          {{ buttonLabel }} ({{ multiselectValue.length }} / {{list.length }})
        </span>
        <span *ngIf="multiselectValue.length === 0">
         {{ buttonLabel }}
        </span>
      </button>
    </div>

    <div *ngSwitchCase="'toggle'" class="select-area">
      <div class="select-area__results">
        {{list | json}}

        <button class="btn btn-sm btn-outline-info btn-icon btn-icon-right btn-toggle"
             *ngFor="let item of list | slice: 0: itemsOnList; let i = index"
             (click)="toggleValue(item[selectValue])"
             [disabled]="inputDisabled"
             [class.active]="toggleActive(item[selectValue])">
            <span>{{ item[selectText] }}</span>
            <i class="material-icons" *ngIf="toggleActive(item[selectValue])">done</i>
            <i class="material-icons" *ngIf="!toggleActive(item[selectValue])">add</i>
        </button>
      </div>
    </div>

    <div *ngSwitchCase="'radio'" class="select-area select-area--inline">
      <button class="btn btn-sm btn-fade-info btn--add"
        (click)="selectModalValues()" [disabled]="inputDisabled">
        <span *ngIf="multiselectValue.length === 0" i18n>{{ buttonLabel }}</span>
        <span *ngIf="multiselectValue.length > 0 && !charactersOnItem">
          {{ multiselectValue[0][selectText] }}
        </span>
        <span *ngIf="multiselectValue.length > 0 && charactersOnItem"
          data-placement="top" i18n-appTooltip appTooltip="{{ multiselectValue[0][selectText] }}">
          {{ multiselectValue[0][selectText] | slice:0:charactersOnItem }} ...
        </span>
      </button>
    </div>

    <div *ngSwitchDefault class="select-area select-area--input">
      <div class="select-area__click" (click)="selectModalValues()"></div>

      <div class="select-area__results">
        <div
          *ngIf="!multiselectValue.length; else resultsBlock"
          class="select-area__drop select-area__drop--none" (click)="selectModalValues()" i18n>
          {{ buttonLabel }}
        </div>
        <ng-template #resultsBlock>
          <div class="select-area__drop" *ngFor="let item of multiselectValue | slice: 0: itemsOnList">

            <span *ngIf="!charactersOnItem; else showTooltip">
              {{ item[selectText] }}
            </span>

            <ng-template #showTooltip>
              <span data-placement="top" i18n-appTooltip appTooltip="{{ item[selectText] }}">
                {{ item[selectText] | slice:0:charactersOnItem }} ...
              </span>
            </ng-template>

            <button
              class="btn btn-icon-block btn-sm"
              (click)="removeItem(item[selectValue])" [disabled]="inputDisabled">
              <i class="material-icons">close</i>
            </button>
          </div>
        </ng-template>

        <div class="select-area__button">
          <div class="select-area__qtd" *ngIf="multiselectValue.length > 0">+{{ multiselectValue.length - itemsOnList }}</div>
          <div class="select-area__filter"><i class="material-icons">filter_list</i></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="shouldShowErrors()" class="text-danger">
    <span *ngFor="let error of listOfErrors() | slice:0:1">{{error}}</span>
  </div>
</div>
