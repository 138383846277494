import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import * as _ from 'lodash';

import { ViolationReportService } from 'src/app/core/services';
import { Lane, Reason, Regulation, Spot } from 'src/app/core/models';

@Component({
  selector: 'app-violations-report-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.sass']
})
export class ViolationsReportFilterComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public collapse: boolean;
  public filterForm: FormGroup;
  public dataContractsSub: Subscription;
  public contractViolationsSub: Subscription;
  public contractViolationsSubBackup: Subscription;
  public filterParamsSub: Subscription;
  public dataProduction: any;
  public spots: Spot[] = [];
  public lanes: Lane[] = [];
  public allLanes: Lane[] = [];
  public isValid: boolean;
  public regulations: Regulation[] = [];
  public situations: any[] = [];
  public reasons: Reason[] = [];
  public violations: any[] = [];
  public reasonArea: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private violationReportService: ViolationReportService,
  ) { }

  ngOnInit() {
    this.filterForm = this.formBuilder.group({
      spotsIds: [null],
      laneIds: [null],
      regulationsIds: [null],
      situationsIds: [null],
      reasonsIds: [null]
    });

    this.dataContractsSub = this.violationReportService.getContractDataViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((dataViolationReport: any) => {
        this.spots = dataViolationReport.spots;
        this.lanes = dataViolationReport.lanes.map( e => ({ ...e, prettyName: e.prettyNames?.[0] }));
        this.allLanes = dataViolationReport.lanes.map( e => ({ ...e, prettyName: e.prettyNames?.[0] }));
        this.regulations = dataViolationReport.regulations;
        this.situations = dataViolationReport.situations;
        this.reasons = dataViolationReport.reasons;
        this.loading = true;
      });

    this.contractViolationsSubBackup = this.violationReportService.getFilteredViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => this.violations = data);

    this.filterForm.get('situationsIds').valueChanges.subscribe(res => {
      if (res && res.includes('invalid')) { this.reasonArea = true; } else { this.reasonArea = false; }
    });

    this.filterForm.get('spotsIds').valueChanges.subscribe(res => {
      if (res) {
        const spotIds = res.map(Number);
        this.lanes = this.allLanes.filter(lane => spotIds.includes(lane.spotId));
      }
    });

    this.filterForm.valueChanges.subscribe(res => {
      this.isValid = this.filterForm.valid;
    });

    this.filterParamsSub = this.violationReportService.getFilterParamsViolationReport()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.filterForm.setValue(data);
        this.lanes = this.allLanes;
      });
  }

  redefineFilter() {
    this.filterForm.get('spotsIds').setValue([]);
    this.filterForm.get('laneIds').setValue([]);
    this.filterForm.get('regulationsIds').setValue([]);
    this.filterForm.get('situationsIds').setValue([]);
    this.filterForm.get('reasonsIds').setValue([]);
    this.lanes = this.allLanes;
    this.violationReportService.emitFilterParamsViolationReport(this.filterForm.value);
  }

  submitFilter() {
    this.violationReportService.emitFilterParamsViolationReport(this.filterForm.value);
  }

  toggleCollapse() {
    this.collapse = !this.collapse;
  }

  ngOnDestroy() {
    this.dataContractsSub.unsubscribe();
    this.contractViolationsSubBackup.unsubscribe();
  }
}
