/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewViolationService {

  private dataViewViolation$: Subject<{}> = new BehaviorSubject<{}>({});
  private dataViewContractViolation$: Subject<{}> = new BehaviorSubject<{}>({});
  private filterViewViolation$: Subject<{}> = new BehaviorSubject<{}>({});
  private periodViewViolation$: Subject<{}> = new BehaviorSubject<{}>({});

  emitDataViewViolation(allData: {} = {}) {
    this.dataViewViolation$.next(allData);
  }

  emitDataViewContractViolation(allData: {} = {}) {
    this.dataViewContractViolation$.next(allData);
  }

  emitFilterViewViolation(period: {} = {}) {
    this.filterViewViolation$.next(period);
  }

  emitPeriodViewViolation(period: {} = {}) {
    this.periodViewViolation$.next(period);
  }

  getDataViewViolation() {
    return this.dataViewViolation$.asObservable();
  }

  getDataViewContractViolation() {
    return this.dataViewContractViolation$.asObservable();
  }

  getFilterViewViolation() {
    return this.filterViewViolation$.asObservable();
  }

  getPeriodViewViolation() {
    return this.periodViewViolation$.asObservable();
  }

}
