<div class="container">
  <div class="page-title page-title--columns">
    <div class="page-title__left">
      <h2 i18n>Grupo de Usuários</h2>
    </div>
    <div class="page-title__right" *appPermission="'CreateUserGroup'">
      <button i18n type="button" class="btn btn-fade-primary" title="Cadastrar novo grupo"
        (click)="activeFormRegister()">Novo Grupo</button>
    </div>
  </div>

  <div class="mb-4">
    <form [formGroup]="searchForm" (submit)="handleSearch()">
      <div class="form-group">
        <input i18n-placeholder type="text" class="form-control form-search" formControlName="search"
          placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">
      </div>
    </form>
  </div>

  <div class="mt-4">
    <div class="card-columns">

      <!-- New Group-->
      <form [formGroup]="userGroupForm" (submit)="onSubmit()" *ngIf="newGroup">
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="my-2" i18n>Novo grupo</h5>
          </div>
          <div class="card-body">
            <app-input i18n-label type="text" formControlName="name" label="Nome" inputRequired=true
              [capitalize]="true">
            </app-input>
            <app-input i18n-label type="text" formControlName="description" label="Descrição">
            </app-input>
            <div class="form-group">
              <app-input i18n=label type="select" formControlName="contractId" inputRequired=true label="Contrato"
                [list]="contracts" i18n-placeholder placeholder="Selecione um contrato" selectValue="id"
                selectText="prettyName">
              </app-input>
            </div>
          </div>

          <div class="card-footer d-flex">
            <button class="btn btn-outline-secondary mr-2 ml-auto" (click)="disabledFormRegister()">
              <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
            </button>
            <button type="submit" class="btn btn-primary" [ngClass]="{disabled: userGroupForm.invalid}">
              <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
            </button>
          </div>
        </div>
      </form>

      <!-- Cards -->
      <div *ngFor="let userGroup of userGroups; let i = index;">
        <form [formGroup]="userGroupForms[userGroup.contractId+userGroup.id].formGroup"
          (ngSubmit)="onSubmitEdit(userGroup)">
          <div class="card mb-4">
            <div class="card-header">
              <div class="row justify-content-between">
                <div class="col mr-auto">
                  <input type="text" class="no-input h5" formControlName="name" />
                  <input type="text" class="no-input text-secondary" formControlName="description" />
                </div>

                <div class="col-auto" *appPermission="'DeleteUserGroup'; contracts: [userGroup.contractId]">
                  <button type="button" class="btn text-secondary btn-icon-block" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons pointer">more_vert</i>
                  </button>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item"
                      (click)="deleteGroupModal(userGroup.contractId, userGroup.id, userGroup.name)" i18n>
                      Remover grupo</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="form-group">
                <label i18n>Contrato</label>
                <app-input type="text" formControlName="contractId" inputDisabled=true></app-input>
              </div>

              <div class="form-group">
                <label i18n>Usuários</label>

                <div class="user-list-control">
                  <div *ngFor="let user of userGroupById[userGroup.contractId+userGroup.id]">
                    <div *ngIf="user">
                      <div class="user-list-control__item" data-toggle="tooltip" title="{{user.name || user.email}}">
                        <app-avatar [user]='user' class="avatar-userGroup-list"></app-avatar>
                        <button type="button" aria-label="Close" title="Remover usuário {{user.name}}"
                          (click)="openDeleteModalUser(userGroup, user.id, user.name)"
                          *appPermission="'UpdateUserGroup'; contracts [userGroup.contractId]"><i
                            class="material-icons">close</i></button>
                      </div>
                    </div>
                  </div>

                  <div class="user-list-control__add"
                    *appPermission="'UpdateUserGroup'; contracts [userGroup.contractId]">
                    <button type="button" title="Adicionar usuário do grupo" data-toggle="tooltip"
                      title="Adicionar usuário">
                      <i class="material-icons" (click)="openUserView(userGroup.id)">add</i>
                    </button>
                  </div>
                </div>
              </div>
              <label class="">Permissões</label>
              <hr>
              <div class="card-body pb-2">
                <div class="d-flex cursor-pointer text-primary" (click)="toggleCollapse(i,1)">
                  <p class="mb-1" i18n>Cadastro Básico</p>
                  <p class="mb-1 ml-auto">
                    <i class="material-icons" *ngIf="!userGroup.showArea1">expand_more</i>
                    <i class="material-icons" *ngIf="userGroup.showArea1">expand_less</i>
                  </p>
                </div>
                <div class="d-none mt-2" [ngClass]="{ 'd-block': userGroup.showArea1 }">
                  <div class="card-table">
                    <table class="responsive-table">
                      <thead>
                        <tr>
                          <th i18n align="left"></th>
                          <th i18n class="text-center">Cadastrar</th>
                          <th i18n class="text-center">Editar</th>
                          <th i18n class="text-center">Visualizar</th>
                          <th i18n class="text-center">Excluir</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr align="center">
                          <td align="left" i18n>Contrato</td>
                          <td></td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateContract"
                                (change)="checkPermissions('UpdateContract', userGroup.contractId+userGroup.id)"
                                id="UpdateContract-{{userGroup.contractId+userGroup.id}}">
                              <label class="custom-control-label"
                                for="UpdateContract-{{userGroup.contractId+userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewContract"
                                (change)="checkPermissions('ViewContract', userGroup.contractId+userGroup.id)"
                                id="ViewContract-{{userGroup.contractId+userGroup.id}}">
                              <label class="custom-control-label"
                                for="ViewContract-{{userGroup.contractId+userGroup.id}}" checked></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Período Isento</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateExemptPeriod"
                                (change)="checkPermissions('CreateExemptPeriod', userGroup.contractId+userGroup.id)"
                                id="CreateExemptPeriod-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateExemptPeriod-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateExemptPeriod"
                                (change)="checkPermissions('UpdateExemptPeriod', userGroup.contractId+userGroup.id)"
                                id="UpdateExemptPeriod-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateExemptPeriod-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewExemptPeriod"
                                (change)="checkPermissions('ViewExemptPeriod', userGroup.contractId+userGroup.id)"
                                id="ViewExemptPeriod-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewExemptPeriod-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteExemptPeriod"
                                (change)="checkPermissions('DeleteExemptPeriod', userGroup.contractId+userGroup.id)"
                                id="DeleteExemptPeriod-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteExemptPeriod-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Empresa</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateCompany"
                                (change)="checkPermissions('CreateCompany', userGroup.contractId+userGroup.id)"
                                id="CreateCompany-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateCompany-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateCompany"
                                (change)="checkPermissions('UpdateCompany', userGroup.contractId+userGroup.id)"
                                id="UpdateCompany-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateCompany-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewCompany"
                                (change)="checkPermissions('ViewCompany', userGroup.contractId+userGroup.id)"
                                id="ViewCompany-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewCompany-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteCompany"
                                (change)="checkPermissions('DeleteCompany', userGroup.contractId+userGroup.id)"
                                id="DeleteCompany-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteCompany-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Equipamento</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateEquipment"
                                (change)="checkPermissions('CreateEquipment', userGroup.contractId+userGroup.id)"
                                id="CreateEquipment-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateEquipment-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateEquipment"
                                (change)="checkPermissions('UpdateEquipment', userGroup.contractId+userGroup.id)"
                                id="UpdateEquipment-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateEquipment-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewEquipment"
                                (change)="checkPermissions('ViewEquipment', userGroup.contractId+userGroup.id)"
                                id="ViewEquipment-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewEquipment-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteEquipment"
                                (change)="checkPermissions('DeleteEquipment', userGroup.contractId+userGroup.id)"
                                id="DeleteEquipment-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteEquipment-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Aferição</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateCalibration"
                                (change)="checkPermissions('CreateCalibration', userGroup.contractId+userGroup.id)"
                                id="CreateCalibration-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateCalibration-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateCalibration"
                                (change)="checkPermissions('UpdateCalibration', userGroup.contractId+userGroup.id)"
                                id="UpdateCalibration-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateCalibration-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewCalibration"
                                (change)="checkPermissions('ViewCalibration', userGroup.contractId+userGroup.id)"
                                id="ViewCalibration-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewCalibration-{{userGroup.id}}"
                                checked></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteCalibration"
                                (change)="checkPermissions('DeleteCalibration', userGroup.contractId+userGroup.id)"
                                id="DeleteCalibration-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteCalibration-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Usuário</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateUser"
                                (change)="checkPermissions('CreateUser', userGroup.contractId+userGroup.id)"
                                id="CreateUser-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateUser-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateUser"
                                (change)="checkPermissions('UpdateUser', userGroup.contractId+userGroup.id)"
                                id="UpdateUser-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateUser-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewUser"
                                (change)="checkPermissions('ViewUser', userGroup.contractId+userGroup.id)"
                                id="ViewUser-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewUser-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteUser"
                                (change)="checkPermissions('DeleteUser', userGroup.contractId+userGroup.id)"
                                id="DeleteUser-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteUser-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Grupo de Usuários</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateUserGroup"
                                (change)="checkPermissions('CreateUserGroup', userGroup.contractId+userGroup.id)"
                                id="CreateUserGroup-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateUserGroup-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateUserGroup"
                                (change)="checkPermissions('UpdateUserGroup', userGroup.contractId+userGroup.id)"
                                id="UpdateUserGroup-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateUserGroup-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewUserGroup"
                                (change)="checkPermissions('ViewUserGroup', userGroup.contractId+userGroup.id)"
                                id="ViewUserGroup-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewUserGroup-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteUserGroup"
                                (change)="checkPermissions('DeleteUserGroup', userGroup.contractId+userGroup.id)"
                                id="DeleteUserGroup-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteUserGroup-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Ponto/Faixa</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateSpot"
                                (change)="checkPermissions('CreateSpot', userGroup.contractId+userGroup.id)"
                                id="CreateSpot-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateSpot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateSpot"
                                (change)="checkPermissions('UpdateSpot', userGroup.contractId+userGroup.id)"
                                id="UpdateSpot-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateSpot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewSpot"
                                (change)="checkPermissions('ViewSpot', userGroup.contractId+userGroup.id)"
                                id="ViewSpot-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewSpot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteSpot"
                                (change)="checkPermissions('DeleteSpot', userGroup.contractId+userGroup.id)"
                                id="DeleteSpot-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteSpot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Origem de Multa</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="CreateViolationSource"
                                (change)="checkPermissions('CreateViolationSource', userGroup.contractId+userGroup.id)"
                                id="CreateViolationSource-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateViolationSource-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="UpdateViolationSource"
                                (change)="checkPermissions('UpdateViolationSource', userGroup.contractId+userGroup.id)"
                                id="UpdateViolationSource-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateViolationSource-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewViolationSource"
                                (change)="checkPermissions('ViewViolationSource', userGroup.contractId+userGroup.id)"
                                id="ViewViolationSource-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewViolationSource-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="DeleteViolationSource"
                                (change)="checkPermissions('DeleteViolationSource', userGroup.contractId+userGroup.id)"
                                id="DeleteViolationSource-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteViolationSource-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Veículo Isento</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="CreateVehicleWhiteList"
                                (change)="checkPermissions('CreateVehicleWhiteList', userGroup.contractId+userGroup.id)"
                                id="CreateVehicleWhiteList-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateVehicleWhiteList-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="UpdateVehicleWhiteList"
                                (change)="checkPermissions('UpdateVehicleWhiteList', userGroup.contractId+userGroup.id)"
                                id="UpdateVehicleWhiteList-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateVehicleWhiteList-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewVehicleWhiteList"
                                (change)="checkPermissions('ViewVehicleWhiteList', userGroup.contractId+userGroup.id)"
                                id="ViewVehicleWhiteList-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewVehicleWhiteList-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="DeleteVehicleWhiteList"
                                (change)="checkPermissions('DeleteVehicleWhiteList', userGroup.contractId+userGroup.id)"
                                id="DeleteVehicleWhiteList-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteVehicleWhiteList-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Motivo de Invalidação</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateReason"
                                (change)="checkPermissions('CreateReason', userGroup.contractId+userGroup.id)"
                                id="CreateReason-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateReason-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateReason"
                                (change)="checkPermissions('UpdateReason', userGroup.contractId+userGroup.id)"
                                id="UpdateReason-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateReason-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewReason"
                                (change)="checkPermissions('ViewReason', userGroup.contractId+userGroup.id)"
                                id="ViewReason-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewReason-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteReason"
                                (change)="checkPermissions('DeleteReason', userGroup.contractId+userGroup.id)"
                                id="DeleteReason-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteReason-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Grupo de Pontos</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateSpotGroup"
                                (change)="checkPermissions('CreateSpotGroup', userGroup.contractId+userGroup.id)"
                                id="CreateSpotGroup-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateSpotGroup-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateSpotGroup"
                                (change)="checkPermissions('UpdateSpotGroup', userGroup.contractId+userGroup.id)"
                                id="UpdateSpotGroup-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateSpotGroup-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewSpotGroup"
                                (change)="checkPermissions('ViewSpotGroup', userGroup.contractId+userGroup.id)"
                                id="ViewSpotGroup-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewSpotGroup-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteSpotGroup"
                                (change)="checkPermissions('DeleteSpotGroup', userGroup.contractId+userGroup.id)"
                                id="DeleteSpotGroup-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteSpotGroup-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" i18n>Marca do Veículo</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateBrandInternal"
                                (change)="checkPermissions('CreateBrandInternal', userGroup.contractId+userGroup.id)"
                                id="CreateBrandInternal-{{userGroup.id}}">
                              <label class="custom-control-label" for="CreateBrandInternal-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateBrandInternal"
                                (change)="checkPermissions('UpdateBrandInternal', userGroup.contractId+userGroup.id)"
                                id="UpdateBrandInternal-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateBrandInternal-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewBrandInternal"
                                (change)="checkPermissions('ViewBrandInternal', userGroup.contractId+userGroup.id)"
                                id="ViewBrandInternal-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewBrandInternal-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteBrandInternal"
                                (change)="checkPermissions('DeleteBrandInternal', userGroup.contractId+userGroup.id)"
                                id="DeleteBrandInternal-{{userGroup.id}}">
                              <label class="custom-control-label" for="DeleteBrandInternal-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>




                        <tr align="center">
                          <td align="left" i18n>Histórico de Atividades</td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewActivity"
                                id="ViewActivity-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewActivity-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-body pb-2">
                <div class="d-flex cursor-pointer text-primary" (click)="toggleCollapse(i,2)">
                  <p class="mb-1" i18n>Pré-Processamento</p>
                  <p class="mb-1 ml-auto">
                    <i class="material-icons" *ngIf="!userGroup.showArea2">expand_more</i>
                    <i class="material-icons" *ngIf="userGroup.showArea2">expand_less</i>
                  </p>
                </div>
                <div class="d-none mt-2" [ngClass]="{ 'd-block': userGroup.showArea2 }">
                  <div class="card-table">
                    <table class="responsive-table" *ngIf="hasPreProcessModule(userGroup)">
                      <thead>
                        <tr>
                          <th i18n align="left"></th>
                          <th i18n class="text-center">Permissão</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr align="center">
                          <td align="left" class="td-level-one" i18n>Configuração</td>
                          <td></td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Visualizar</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="ViewPreProcessSettings" id="ViewPreProcessSettings-{{userGroup.id}}"
                                (change)="checkPermissions('ViewPreProcessSettings', userGroup.contractId+userGroup.id)">
                              <label class="custom-control-label align-pre-process"
                                for="ViewPreProcessSettings-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Editar</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="UpdatePreProcessSettings"
                                id="UpdatePreProcessSettings-{{userGroup.id}}"
                                (change)="checkPermissions('UpdatePreProcessSettings', userGroup.contractId+userGroup.id)">
                              <label class="custom-control-label align-pre-process"
                                for="UpdatePreProcessSettings-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr align="center">
                          <td align="left" class="td-level-one" i18n>Processar Infrações</td>
                          <td></td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Triagem</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="TriageViolation"
                                id="TriageViolation-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="TriageViolation-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Digitação</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="TypingViolation"
                                id="TypingViolation-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="TypingViolation-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Verificação</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="VerifyViolation"
                                id="VerifyViolation-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="VerifyViolation-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Validação</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ValidateViolation"
                                id="ValidateViolation-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ValidateViolation-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-one" i18n>Auditoria</td>
                          <td></td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Gerenciar Lote</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ManageLot"
                                id="ManageLot-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ManageLot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Auditar Lote</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="AuditViolation"
                                id="AuditViolation-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="AuditViolation-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Revisar Lote</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ReviewLot"
                                id="ReviewLot-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ReviewLot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-one" i18n>Gerenciar Lotes</td>
                          <td></td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Visualizar Lote</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewLot"
                                id="ViewLot-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ViewLot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Gerar Lote</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CreateLot"
                                id="CreateLot-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="CreateLot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Fechar Lote</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="CloseLot"
                                id="CloseLot-{{userGroup.id}}" (change)="changeInput('CloseLot' ,userGroup.id)">
                              <label class="custom-control-label align-pre-process"
                                for="CloseLot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Remover Lote</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="DeleteLot"
                                id="DeleteLot-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="DeleteLot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Regerar Lote</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateRegenerateLot"
                                id="UpdateRegenerateLot-{{userGroup.id}}"
                                (change)="changeInput('UpdateRegenerateLot', userGroup.id)">
                              <label class="custom-control-label align-pre-process"
                                for="UpdateRegenerateLot-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Cancelar Lote</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateLotExport"
                                id="UpdateLotExport-{{userGroup.id}}"
                                (change)="changeInput('UpdateLotExport', userGroup.id)">
                              <label class="custom-control-label align-pre-process"
                                for="UpdateLotExport-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-one" i18n>Gerenciar Séries</td>
                          <td></td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Visualizar Séries</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="ViewSerialNumberConfig" id="ViewSerialNumberConfig-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ViewSerialNumberConfig-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Cadastrar Série</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="CreateSerialNumberConfig"
                                id="CreateSerialNumberConfig-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="CreateSerialNumberConfig-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Editar Série</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="UpdateSerialNumberConfig"
                                id="UpdateSerialNumberConfig-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="UpdateSerialNumberConfig-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Remover Série</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="DeleteSerialNumberConfig"
                                id="DeleteSerialNumberConfig-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="DeleteSerialNumberConfig-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-one" i18n>Quarentena</td>
                          <td></td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Visualizar</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="QuarantineReport"
                                id="QuarantineReport-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="QuarantineReport-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Gerenciar</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="QuarantineManage"
                                id="QuarantineManage-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="QuarantineManage-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-one" i18n>Relatórios</td>
                          <td></td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Mapa de Etapas</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="PreProcessStepMap"
                                id="PreProcessStepMap-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="PreProcessStepMap-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Produção</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                formControlName="PreProcessProductionReport"
                                id="PreProcessProductionReport-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="PreProcessProductionReport-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Aproveitamento OCR</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ReportOCR"
                                id="ReportOCR-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ReportOCR-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-body pb-2">
                <div class="d-flex cursor-pointer text-primary" (click)="toggleCollapse(i,3)">
                  <p class="mb-1" i18n>Infrações</p>
                  <p class="mb-1 ml-auto">
                    <i class="material-icons" *ngIf="!userGroup.showArea3">expand_more</i>
                    <i class="material-icons" *ngIf="userGroup.showArea3">expand_less</i>
                  </p>
                </div>
                <div class="d-none mt-2" [ngClass]="{ 'd-block': userGroup.showArea3 }">
                  <div class="card-table">
                    <table class="responsive-table" *ngIf="hasPreProcessModule(userGroup)">
                      <thead>
                        <tr>
                          <th i18n align="left"></th>
                          <th></th>
                          <th i18n class="text-center">Permissão</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr align="center">
                          <td align="left" class="td-level-one" i18n>Consulta</td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Infrações</td>
                          <td></td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ConsultViolation"
                                id="ConsultViolation-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ConsultViolation-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-one" i18n>Relatórios</td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Infrações</td>
                          <td></td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViolationsReport"
                                id="ViolationsReport-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ViolationsReport-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-body pb-2" *ngIf="showImportFile(userGroup.contractId)">
                <div class="d-flex cursor-pointer text-primary" (click)="toggleCollapse(i,4)">
                  <p class="mb-1" i18n>Importação de arquivos</p>
                  <p class="mb-1 ml-auto">
                    <i class="material-icons" *ngIf="!userGroup.showArea4">expand_more</i>
                    <i class="material-icons" *ngIf="userGroup.showArea4">expand_less</i>
                  </p>
                </div>
                <div class="d-none mt-2" [ngClass]="{ 'd-block': userGroup.showArea4 }">
                  <div class="card-table" *ngIf="showImportFile(userGroup.contractId)">
                    <table class="responsive-table" *ngIf="hasPreProcessModule(userGroup)">
                      <thead>
                        <tr>
                          <th i18n align="left"></th>
                          <th i18n class="text-center">Visualizar</th>
                          <th i18n class="text-center">Importar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr align="center">
                          <td align="left" i18n></td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewImportSettings"
                                id="ViewImportSettings-{{userGroup.id}}">
                              <label class="custom-control-label" for="ViewImportSettings-{{userGroup.id}}"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateImportSettings"
                                id="UpdateImportSettings-{{userGroup.id}}">
                              <label class="custom-control-label" for="UpdateImportSettings-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-body pb-2" *ngIf="showEcsSpy(userGroup.contractId)?.includes('monitoring')">
                <div class="d-flex cursor-pointer text-primary" (click)="toggleCollapse(i,5)">
                  <p class="mb-1" i18n>eCSSpy</p>
                  <p class="mb-1 ml-auto">
                    <i class="material-icons" *ngIf="!userGroup.showArea5">expand_more</i>
                    <i class="material-icons" *ngIf="userGroup.showArea5">expand_less</i>
                  </p>
                </div>
                <div class="d-none mt-2" [ngClass]="{ 'd-block': userGroup.showArea5 }">
                  <div class="card-table">
                    <table class="responsive-table" *ngIf="hasPreProcessModule(userGroup)">
                      <thead>
                        <tr>
                          <th i18n align="left">Configuração</th>
                          <th></th>
                          <th i18n class="text-center">Permissão</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Visualizar</td>
                          <td></td>
                          <td>
                            <div class="custom-control custom-checkbox ml-2">
                              <input type="checkbox" class="custom-control-input" formControlName="ViewMonitoringSettings"
                                id="ViewMonitoringSettings-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ViewMonitoringSettings-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Editar</td>
                          <td></td>
                          <td>
                            <div class="custom-control custom-checkbox ml-2">
                              <input type="checkbox" class="custom-control-input" formControlName="UpdateMonitoringSettings"
                                id="UpdateMonitoringSettings-{{userGroup.id}}"
                                (change)="checkPermissions('UpdateMonitoringSettings', userGroup.contractId+userGroup.id)">
                              <label class="custom-control-label align-pre-process"
                                for="UpdateMonitoringSettings-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="responsive-table" *ngIf="hasPreProcessModule(userGroup)">
                      <thead>
                        <tr>
                          <th i18n align="left">Processamento</th>
                          <th></th>
                          <th i18n class="text-center" style="visibility: collapse;">Permissão</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Monitoramento CFTV</td>
                          <td></td>
                          <td class="align-column">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="OnlineMonitoring"
                                id="OnlineMonitoring-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="OnlineMonitoring-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Validar Infrações CFTV</td>
                          <td></td>
                          <td class="align-column">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ValidateCFTV"
                                id="ValidateCFTV-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ValidateCFTV-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Verificar Infrações CFTV</td>
                          <td></td>
                          <td class="align-column">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="VerifyCFTV"
                                id="VerifyCFTV-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="VerifyCFTV-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th i18n align="left">Relatórios</th>
                          <th></th>
                          <th i18n class="text-center" style="visibility: collapse;">Permissão</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr align="center">
                          <td align="left" class="td-level-two" i18n>Mapa de Etapas</td>
                          <td></td>
                          <td class="align-column">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" formControlName="ViolationStepCFTV"
                                id="ViolationStepCFTV-{{userGroup.id}}">
                              <label class="custom-control-label align-pre-process"
                                for="ViolationStepCFTV-{{userGroup.id}}"></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end"
              *ngIf="userGroupForms[userGroup.contractId+userGroup.id].isModified">
              <button type="button" class="btn btn-outline-secondary mr-2" (click)="setFormValues(userGroup)">
                <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
              </button>
              <button type="submit" class="btn btn-primary"
                [ngClass]="{'disabled' : !userGroupForms[userGroup.contractId+userGroup.id].formGroup.valid}">
                <app-loading [promise]="promise" text="Salvar alterações" i18n-text></app-loading>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="loading-page">
  <app-infinite-scroll (items)="setUserGroups($event)" (clear)="userGroups=[]" [params]="params"
    (items)="disabledFormRegister()" [service]="userGroupService" [permission]="'ViewUserGroup'"></app-infinite-scroll>
</div>
