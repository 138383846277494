<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Detalhes do Equipamento</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body label">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" label="Contrato"
              [textValue]="contract && contract.code + ' - '  + contract.name">
            </app-input>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" i18n-label="@@EquipmentSerialNumberLabel" label="Número de Série"
              [textValue]="equipment.serialNumber || '-'">
            </app-input>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" i18n-label="@@EquipmentClientCodeLabel" label="Código do Cliente"
              [textValue]="equipment.clientCode || '-'">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" i18n-label="@@EquipmentModelLabel" label="Modelo"
              [textValue]="models[equipment.equipmentModelId] && models[equipment.equipmentModelId].name">
            </app-input>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" label="Tipo" i18n-label="@@EquipmentTypeLabel"
              [textValue]="equipmentModel.type || '-'">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-4 col-md-3 col-lg-4">
            <div>
              <label class="font-weight-bold" i18n>Ponto(s):</label>
            </div>
            <div *ngFor="let spot of spots">
              {{ spot && spot.code + ' - ' + spot.description }}
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-8">
            <div>
              <label class="font-weight-bold" i18n>Faixa(s):</label>
            </div>
            <div *ngFor="let lane of lanes">
              <p class="d-inline">{{ lane && lane.code }} &nbsp; - &nbsp;</p>
              <p class="d-inline" i18n>Fx.</p>
              <p class="d-inline">&nbsp; {{ lane.number }}(</p>
              <p class="d-inline" i18n>Ponto</p>
              <p class="d-inline">&nbsp; {{ spotsAll[lane.spotId] && spotsAll[lane.spotId].code }})</p>
            </div>
          </div>
        </div>
        <div class="row mt-2 mb-4">
          <div class="col-12 font-weight-bold"><label class="font-weight-bold" i18n>Características</label></div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-2">
            <label class="font-weight-bold" i18n> Leitura OCR</label><br />
            <div *ngIf="equipment.features && equipment.features.ocr
                && equipment.features.ocr.enabled; else ocrDisabled">
              <i class="material-icons text-success">done</i>
            </div>
            <ng-template #ocrDisabled>
              <i class="material-icons text-danger">close</i>
            </ng-template>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-3">
            <label class="font-weight-bold" i18n>Classificação Veícular</label><br />
            <div *ngIf="equipment.features && equipment.features.classification &&
                equipment.features.classification.enabled; else classificationDisabled">
              <i class="material-icons text-success">done</i>
            </div>
            <ng-template #classificationDisabled>
              <i class="material-icons text-danger">close</i>
            </ng-template>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-3">
            <label class="font-weight-bold" i18n>Semáforo</label><br />
            <div *ngIf="equipment.features && equipment.features.trafficLight &&
            equipment.features.trafficLight.enabled; else trafficLightDisabled">
              <i class="material-icons text-success">done</i>
            </div>
            <ng-template #trafficLightDisabled>
              <i class="material-icons text-danger">close</i>
            </ng-template>
          </div>
        </div>
        <div class="loading-spinner" *ngIf="isLoading">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
        <div class="row flex-center-vertical d-none">
          <div class="col-12 col-md-3 flex-center-vertical">
            <span class="font-weight-bold" i18n>Token de autenticação</span>
          </div>
          <div class="col-12 col-md-6 flex-center-vertical">
            <div class="input-group">
              <input type="text" class="form-control" #tokenInput>
              <div class="input-group-append" (click)="copyInputToken(tokenInput)">
                <button class="btn btn-outline-secondary btn-icon-block" type="button">
                  <i class="material-icons">content_copy</i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <button class="btn btn-secondary custom-class-btn" i18n> Gerar Novo Token</button>
          </div>
        </div>
        <ul class="nav nav-tabs">
          <li class="nav-item" (click)="shownCalibrations = metrologicCalibrations"
            *ngIf="metrologicCalibrations.length > 0">
            <a class="nav-link" [ngClass]="{'active' : shownCalibrations === metrologicCalibrations}"
              i18n>Metrológica</a>
          </li>
          <li class="nav-item" (click)="shownCalibrations = nonMetrologicCalibrations"
            *ngIf="nonMetrologicCalibrations.length > 0">
            <a class="nav-link" [ngClass]="{'active' : shownCalibrations ===  nonMetrologicCalibrations}"
              i18n>Não Metrológica</a>
          </li>
        </ul>
        <div class="row">
          <div class="col-12">
            <div class="carousel slide" #carousel data-pause="true" data-ride="carousel">
              <div class="carousel-inner flex-center-vertical">
                <div class="carousel-item" [ngClass]="{ 'active' : i === 0 }"
                  *ngFor="let calibration of shownCalibrations; index as i">
                  <div class="row container">
                    <div class="col-12 col-md-5 offset-md-1">
                      <div class="row carousel-content">
                        <div class="col-12 mb-3">
                          <p class="mb-0"><label class="font-weight-bold" i18n>Validade</label></p>
                          <p>
                            <span i18n>De</span> {{calibration.startsAt | moment: 'DD/MM/YYYY'}}
                            <span i18n>até</span> {{calibration.endsAt | moment: 'DD/MM/YYYY'}}
                          </p>
                        </div>
                        <div class="col-md-6 col-12">
                          <app-input i18n-label="@@CalibrationDataLabel" label="Data" [isLabel]="true"
                            [textValue]="calibration.date| moment: 'DD/MM/YYYY'">
                          </app-input>
                        </div>
                        <div class="col-md-6 col-12">
                          <app-input i18n-label="@@CalibrationAppraisalNumberLabel" label="Número do Laudo"
                            [isLabel]="true" [textValue]="calibration?.appraisal?.number || '-'">
                          </app-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-12">
                          <div class="row">
                            <div class="col-12">
                              <label class="font-weight-bold" i18n>Aferidor</label>
                            </div>
                            <div *ngIf="calibration?.technician?.name ||
                                        calibration?.technician?.registration ||
                                        calibration?.agency; else noChecker">
                              <div class="col-12">{{calibration?.technician?.name}}</div>
                              <div class="col-12">{{calibration?.technician?.registration}}</div>
                              <div class="col-12">{{calibration?.agency}}</div>
                            </div>
                            <ng-template class="col-12" #noChecker>
                              <div class="col-4">-</div>
                            </ng-template>
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="row">
                            <div class="col-12">
                              <app-input i18n-label="@@EquipmentSealNumberLabel" label="Número do Lacre"
                                [isLabel]="true" [textValue]="calibration.sealNumber || '-'">
                              </app-input>
                            </div>
                            <div class="col-12" *ngIf="calibration.type !== 'non-metrologic'">
                              <app-input i18n-label="@@CalibrationSpeedLabel" label="Velocidade Aferida"
                                [isLabel]="true" [textValue]="calibration.speed">
                              </app-input>
                            </div>
                            <div class="col-12">
                              <app-input i18n-toolTipMsg i18n-label="@@AgencyLabel" label="Número do Processo"
                                [isLabel]="true" [textValue]="calibration.processNumber || '-'"
                                toolTipMsg="Número do processo junto ao órgão regulamentador">
                              </app-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5 scroll-column">
                      <div class="row carousel-content">
                        <div class="col-12 mb-3">
                          <div>
                            <label class="font-weight-bold" i18n>Laudo</label>
                          </div>
                          <button (click)="openViewFileModal(calibration.appraisal.document)"
                            class="btn btn-icon-block btn-fade-secondary" data-toggle="tooltip"
                            title="{{ getCalibrationFileName(calibration.appraisal.document) }}"
                            *ngIf="calibration.appraisal.document; else noAppraisalBlock">
                            <i class="material-icons">file_copy</i>&nbsp; Ver laudo</button>
                          <ng-template #noAppraisalBlock> - </ng-template>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-1">
                          <label class="font-weight-bold" i18n>Arquivos Complementares</label>
                          <div class="files-container">
                            <div *ngFor="let file of calibration.extraFiles" class="pointer files-container__file"
                              data-toggle="tooltip" title="{{ getCalibrationFileName(file) }}">
                              <app-thumbnail [source]="file"></app-thumbnail>
                            </div>
                          </div>
                          <div class="col-6" *ngIf="calibration.extraFiles.length === 0"> - </div>
                        </div>
                      </div>
                    </div>
                    <a i18n-title="EquipmentPrevCalibration" *ngIf="i !== 0" class="carousel-custom-prev"
                      href="javascript:void(0)" (click)="previous(i)" role="button" data-slide="prev"
                      data-toggle="tooltip" data-placement="top" title="Aferição Vigente">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a i18n-title="EquipmentNextCalibration"
                      *ngIf="shownCalibrations && i !== shownCalibrations.length - 1" class="carousel-custom-next"
                      href="javascript:void(0)" (click)="next(i)" role="button" data-slide="next"
                      data-toggle="tooltip" data-placement="top" title="Aferições Futuras">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
