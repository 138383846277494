import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Regulation } from 'src/app/core/models';

import { ProductionReportService, UserGroupService, UserService } from 'src/app/core/services';

@Component({
  selector: 'app-production-report-contract-view',
  templateUrl: './contract-view.component.html'
})
export class ProductionReportContractViewComponent implements OnInit, OnDestroy, AfterViewChecked {
  public loading: boolean;
  public contract: any;
  public regulations: Regulation[] = [];
  public contractSub: Subscription;
  public dataSub: Subscription;
  public items: any[] = [];
  public users: any[] = [];
  public contractGroups: any[] = [];

  constructor(
    public userService: UserService,
    private productionReportService: ProductionReportService,
    private changeDetectionRef: ChangeDetectorRef,
    private userGroupService: UserGroupService
  ) {
  }

  async ngOnInit() {
    this.contractSub = await this.productionReportService.getContractsProduction()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe(async (contractProduction: any) => {
        this.contract = contractProduction;
        this.loading = true;
        await this.userGroupService.list({ contractId: contractProduction.id }).then(async (res) => {
          const groups = res.result;
          this.contractGroups = groups.map(group => group.id);
          await this.userService.getAll().then(response => {
            response.map(user => {
              if (user.groupIds.length > 0) {
                this.users[user.id] = user;
              }
            });
          });
        });
      });

    this.dataSub = this.productionReportService.getDataProduction()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((dataProduction: any) => this.regulations = dataProduction.regulations);
  }

  setTime(time) {
    const miliseconds = parseInt(time, 10);
    return moment.duration(miliseconds, 'ms').format('hh:mm:ss', { trim: false });
  }

  ngAfterViewChecked(): void {
    this.changeDetectionRef.detectChanges();
  }

  setItems(list) {
    this.items = list;
    this.verifyGroup();
  }

  verifyGroup() {
    this.items.map(user => {
      if (this.contractGroups.length > 0) {
        const verifyGroup = [];
        this.contractGroups.map(group => {
          if (this.users.length > 0 && this.users[user.id].groupIds.includes(group)) {
            verifyGroup.push(user.id);
          }
        });
        this.items.filter(x => x.id === user.id).shift().noGroups = false;
        if (!verifyGroup.includes(user.id) || !this.users[user.id]) {
          this.items.filter(x => x.id === user.id).shift().noGroups = true;
        }
      }
    });
  }

  toggleCollapse(item) {
    item.collapse = !item.collapse;
  }

  getRegulationInfo(regulation) {
    const regulationInfo = this.regulations.filter(item => item.id === regulation).shift();
    return regulationInfo?.description;
  }

  ngOnDestroy() {
    this.contractSub.unsubscribe();
    this.dataSub.unsubscribe();
  }
}
