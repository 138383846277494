/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { StorageLocation } from '../models';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { map, catchError } from 'rxjs/operators';
import { StorageKey } from './storage.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ReportOcrService extends BaseApiService<StorageLocation> {
  get cacheKey(): StorageKey {
    return StorageKey.storageLocationServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/report/reportOcr`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  protected unparse(obj) {
    return StorageLocation.unparse(obj);
  }

  protected parse(obj) {
    return StorageLocation.parse(obj);
  }

  public utilization(body): Promise<StorageLocation> {
    const url = `${environment.apiUrl}/report/reportOcr/utilization`;
    return this.http.post(url, body).pipe(
      catchError(this.handleError),
      map(res => res)
    ).toPromise();
  }
}
