/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */

import { StorageService, StorageKey } from '../services';
import { PreProcessSettingsService } from 'src/app/core/services';

const hasPermission = (permission, storageService: StorageService, contracts = null) => {
  const user = storageService.get(StorageKey.currentUser);
  if (user == null) {
    return false;
  } else if (user.superUser) {
    return true;
  } else {
    const permissions = storageService.get(StorageKey.currentPermissions);
    for (const item of permissions) {
      if (contracts != null && !contracts.includes(item.contractId)) {
        continue;
      }
      const allowed = item.actionIds.some(p => p === permission);
      if (allowed === true) {
        return true;
      }
    }
    return false;
  }
};

const permissionStep = (step) => {
  if (step === 'settings') {
    return 'UpdatePreProcessSettings';
  }
  switch (step) {
    case 'triage':
      return 'TriageViolation';
    case 'typing':
      return 'TypingViolation';
    case 'validate':
      return 'ValidateViolation';
    case 'verify':
      return 'VerifyViolation';
    case 'audit':
      return 'AuditViolation';
    case 'violations':
      return 'ViewViolation';
    case 'quarantine':
      return 'QuarantineReport';
    case 'violation-step':
      return 'PreProcessStepMap';
    case 'production-report':
      return 'PreProcessProductionReport';
    case 'lot-list':
      return 'ViewLot';
    case 'serial-number-config':
      return 'ViewSerialNumberConfig';
  }
  return '';
};

const searchContractsPermited = async (
  permissions: any[],
  step: string,
  preProcessSettingsService: PreProcessSettingsService,
  currentContracts
) => {
  let contracts = [];
  const preProcessById = [];

  const promises = currentContracts.map(contract => preProcessSettingsService.getById(contract.id)
      .then(preProcess => preProcessById[preProcess.id] = preProcess).catch(() => null));

  return Promise.all(promises).then(() => {
    for (const permission of permissions) {
      const preProcess = preProcessById[permission.contractId] && preProcessById[permission.contractId] || {};
      if (!(verifyStepContract(step, step === 'ViewSerialNumberConfig' ? preProcess.lotSteps  || {} : preProcess.violationSteps || {}))) {
        continue;
      }
      contracts = verifyPermissions(permission, step, contracts);
    }
    return contracts;
  });

};

const verifyPermissions = (permission, step, contracts: string[]) => {
  if (permission.actionIds.indexOf(step) >= 0) {
    contracts.push(permission.contractId);
  }
  return contracts;
};

const verifyStepContract = (step: string, violationSteps) => {
    if (step.startsWith('Verify') &&
      (!Object.keys(violationSteps).includes('verifyValid') && !Object.keys(violationSteps).includes('verifyInvalid'))) {
      return false;
    }

    if (step.startsWith('Typing') && !Object.keys(violationSteps).includes('typing')) {
      return false;
    }

    if (step.startsWith('Validate') && !Object.keys(violationSteps).includes('validate')) {
      return false;
    }

    if (step.startsWith('Triage') && !Object.keys(violationSteps).includes('triage')) {
      return false;
    }

    if (step.startsWith('ViewSerialNumberConfig') && !Object.keys(violationSteps).includes('serialNumberGeneration')) {
      return false;
    }

    return true;
};

const getPermissionsByRoute = (path) => {
  path = path.replace(/\/?(\?.*)?$/, '');
  switch (true) {
    case verifyPath('/contracts', path):
      return 'ViewContract';
    case verifyPath('/equipments', path):
      return 'ViewEquipment';
    case verifyPath('/contracts/{id}/exempt-periods', path):
      return 'ViewExemptPeriod';
    case verifyPath('/users', path):
      return 'ViewUser';
    case verifyPath('/user-groups', path):
      return 'ViewUserGroup';
    case verifyPath('/spots', path):
      return 'ViewSpot';
    case verifyPath('/lanes', path):
      return 'ViewLane';
    case verifyPath('/pre-process', path):
      return 'ViewPreProcessSettings';
    case verifyPath('/pre-process/process', path):
      return 'ViewPreProcessSettings';
    case verifyPath('/pre-process/triage/{id}', path):
      return 'TriageViolation';
    case verifyPath('/pre-process/typing/{id}', path):
      return 'TypingViolation';
    case verifyPath('/pre-process/verify/{id}', path):
      return 'VerifyViolation';
    case verifyPath('/pre-process/validate/{id}', path):
      return 'ValidateViolation';
    case verifyPath('/activities', path):
      return 'ViewActivity';
    case verifyPath('/reasons', path):
      return 'ViewReason';
    case verifyPath('/companies', path):
      return 'ViewCompany';
    case verifyPath('/calibrations', path):
      return 'ViewCalibration';
    case verifyPath('/vehicles', path):
      return 'ViewVehicle';
    case verifyPath('/vehicleWhiteLists', path):
      return 'ViewVehicleWhiteList';
    case verifyPath('/lots', path):
      return 'ViewLot';
    case verifyPath('/violations', path):
      return 'ViewViolation';
    case verifyPath('/violations/{id}/ait', path):
      return 'ViewViolationAit';
    case verifyPath('/pre-process/quarantine/{id}', path):
      return 'QuarantineReport';
  }
};

const verifyPath = (url, path) => new RegExp('^' + url.replace(/{[^}]*}/g, '[^/]+')).test(path);

const getAllowedContracts = (activeRoute, storageService: StorageService) => {
  const currentPermissions = getPermissions(storageService);
  const currentContracts = storageService.get(StorageKey.currentContracts);
  const urlSelect = activeRoute.snapshot._routerState.url;
  const permissionRoute = getPermissionsByRoute(urlSelect);
  if (permissionRoute != null) {
    return currentPermissions.filter(permission => permission.actionIds.indexOf(permissionRoute) >= 0).map(contract => contract.contractId);
  }
  return currentContracts;
};

const getPermissions = (storageService: StorageService) => storageService.get(StorageKey.currentPermissions);

const getModuleByPermission = (permission) => {
  let modulePermission = null;
  switch (permission) {
    case 'TriageViolation':
      modulePermission = {
        module: 'preProcess',
        step: 'triage'
      };
      break;
    case 'TypingViolation':
      modulePermission = {
        module: 'preProcess',
        step: 'typing'
      };
      break;
    case 'ValidateViolation':
      modulePermission = {
        module: 'preProcess',
        step: 'validate'
      };
      break;
    case 'VerifyViolation':
      modulePermission = {
        module: 'preProcess',
        step: 'verifyValid'
      };
      break;
    case 'AuditViolation':
      modulePermission = {
        module: 'preProcess',
        step: 'internalAudit'
      };
      break;
    case 'ViewViolation':
      modulePermission = {
        module: 'preProcess',
        step: 'violations'
      };
      break;
    case 'ViewSerialNumberConfig':
      modulePermission = {
        module: 'viewSerial',
        step: 'serialNumberGeneration'
      };
  }
  return modulePermission;
};

export {
  hasPermission,
  permissionStep,
  searchContractsPermited,
  getAllowedContracts,
  getModuleByPermission
};
