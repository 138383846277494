<div class="pre-process-dashboard">
  <div class="container">
    <div class="page-title">
      <h2 i18n>Pré-Processamento</h2>
      <p class="page-title__subtitle" i18n>Escolha a funcionalidade que deseja acessar</p>
    </div>

    <div class="mt-4" *appPermission="['TriageViolation','TypingViolation','DoubleTypingViolation','VerifyViolation','ValidateViolation']">
      <h6 i18n>Processar infrações</h6>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['TriageViolation']">
          <a routerLink="triage" class="pre-process-dashboard__block">
            <i class="material-icons">filter_list</i>
            <span i18n>Triagem</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['TypingViolation','DoubleTypingViolation']">
          <a routerLink="typing" class="pre-process-dashboard__block">
            <i class="material-icons">done</i>
            <span i18n>Digitação</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['VerifyViolation']">
          <a routerLink="verify" class="pre-process-dashboard__block">
            <i class="material-icons">done_all</i>
            <span i18n>Verificação</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['ValidateViolation']">
          <a routerLink="validate" class="pre-process-dashboard__block">
            <i class="material-icons">done_outline</i>
            <span i18n>Validação</span>
          </a>
        </div>
      </div>
    </div>

    <div class="mt-4" *appPermission="['AuditViolation','ViewLot','QuarantineReport', 'QuarantineManage','ViewSerialNumberConfig','ManageLot','ReviewLot']">
      <h6 i18n>Operações</h6>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['ViewLot']">
          <a routerLink="lot-list/available-contracts" class="pre-process-dashboard__block">
            <i class="material-icons">assignment</i>
            <span i18n>Gerenciar Lotes</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['QuarantineReport', 'QuarantineManage']">
          <a routerLink="quarantine/available-contracts" class="pre-process-dashboard__block">
            <i class="material-icons">report</i>
            <span i18n>Quarentena</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3"  *appPermission="['ViewSerialNumberConfig']">
          <a routerLink="serial-number-config/available-contracts" class="pre-process-dashboard__block">
            <i class="material-icons">toc</i>
            <span i18n>Gerenciar Série</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3"  *appPermission="['AuditViolation','ManageLot','ReviewLot']">
          <a routerLink="internalAudit/available-contracts" class="pre-process-dashboard__block">
            <i class="material-icons">playlist_add_check</i>
            <span i18n>Auditoria</span>
          </a>
        </div>
      </div>
    </div>

    <div class="mt-4 mb-4" *appPermission="['PreProcessStepMap','PreProcessProductionReport']">
      <h6 i18n>Relatórios</h6>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['PreProcessStepMap']">
          <a routerLink="violation-step/available-contracts" class="pre-process-dashboard__block">
            <i class="material-icons">assessment</i>
            <span i18n>Mapa de Etapas</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['PreProcessProductionReport']">
          <a routerLink="production-report" class="pre-process-dashboard__block">
            <i class="material-icons">assignment_ind</i>
            <span i18n>Produção</span>
          </a>
        </div>
      </div>
    </div>

  <div class="mt-5 mb-4" *appPermission="['UpdatePreProcessSettings', 'ViewPreProcessSettings']">
    <a routerLink="settings/available-contracts" class="btn btn-fade-secondary btn-icon">
      <i class="material-icons icon-align-text">settings_applications</i>
      <span i18n>Administrar módulo</span>
    </a>
  </div>
</div>
