/* eslint-disable no-shadow */
import { BaseApiModel } from './BaseApiModel';

export class Equipment extends BaseApiModel {
  public authenticationToken: string;
  public contractId: any;
  public createdAt: Date;
  public equipmentModelId: any;
  public laneIds: string[];
  public laneQuantity: number;
  public modifiedAt: Date;
  public serialNumber: string;
  public clientCode: string;
  public spotIds: string[];
  public enabled: boolean;
  public features: {
    ocr: {
      enabled: boolean;
    };
    classification: {
      enabled: boolean;
    };
    trafficLight: {
      enabled: boolean;
    };
  };
  public metrologicCalibrationId: string;
  public nonMetrologicCalibrationId: string;
  public cftv: {
    url: string;
    port: number;
    user: string;
    password: string;
    name: string;
  };
  public name: string;
  public address: string;
}

export enum EquipmentFeatures {
  classification = 'classification',
  ocr = 'ocr',
  trafficLight = 'trafficLight'
};

export enum TypeEquipment {
  fixo = 'Fixo',
  lombada = 'Lombada',
  semaforo = 'Semaforo',
  portatil = 'Portatil',
  radar = 'Radar Móvel',
}
