/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Lane, Spot } from 'src/app/core/models';
import { SpotService, ModalService, ContractService, LaneService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-lane-select',
  templateUrl: './lane-select.component.html',
  styleUrls: ['./lane-select.component.sass']
})
export class LaneSelectComponent extends BaseModal implements OnInit {
  get lastState(): any {
    throw new Error('Method not implemented.');
  }
  public loading: boolean;
  public values: string[] = [];
  public isSingle = false;
  public contractId;
  public laneCode;
  public editForm: FormGroup;
  public toggleClass: any = {};
  public showArea: any = {};
  public lanes: Lane[] = [];
  public spots: Spot[] = [];
  public allLanes: any[] = [];

  get isValid() {
    return this.values.length > 0;
  }

  constructor(
    public formBuilder: FormBuilder,
    public spotService: SpotService,
    public laneService: LaneService,
    public contractService: ContractService,
    public modalService: ModalService,
  ) {
    super(modalService);
  }

  async ngOnInit() {
    this.loading = true;

    this.isSingle = this.initialState != null && this.initialState.isSingle;
    this.contractId = this.initialState != null && this.initialState.contractId;
    this.laneCode = this.initialState != null && this.initialState.laneId;

    this.editForm = this.formBuilder.group({
      spotsIds: [[], null],
      laneIds: [[], Validators.required]
    });

    this.spots = await this.spotService.getAll({contractId: this.contractId});
    this.allLanes = (await this.laneService.getAll({contractId: this.contractId})).filter(lane => lane.id !== this.laneCode);

    this.loading = false;

    const spotsIds = this.spots.map(spot => spot.id);
    this.lanes = this.allLanes.filter(lane => spotsIds.includes(lane.spotId));

    this.editForm.get('spotsIds').valueChanges.subscribe(spotId => {
      if (this.editForm.get('spotsIds').value !== null && this.editForm.get('spotsIds').value !== undefined) {
        if(this.isSingle) {
          this.lanes = this.allLanes.filter(lane => spotId === lane.spotId);
        } else {
          this.lanes = this.allLanes.filter(lane => this.editForm.get('spotsIds').value.includes(lane.spotId));
        }
      } else {
        this.lanes = this.allLanes;
      }
    });
    this.editForm.get('laneIds').valueChanges.subscribe(laneId => {
      this.values = [laneId];
    });
    this.loading = true;
  }

  submitForm() {
    this.onAllSubmited();
    this.modalService.submit();
  }

  onAllSubmited(): Promise<any> {
    if (this.isSingle) {
      this.values = [this.editForm.get('laneIds').value];
    } else {
      this.values = this.editForm.get('laneIds').value;
    }
    return Promise.resolve(this.values);
  }
}
