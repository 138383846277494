import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalContent } from 'src/app/core/interface';
import { Company, User, UserGroup } from 'src/app/core/models';
import { AlertItem, AlertType } from 'src/app/core/models/AlertItem';
import {
  AlertService, ModalService, UserGroupService, UserService,
  ContractService, StorageKey, StorageService, ContractGlobalService
} from 'src/app/core/services';
import { CompanyService } from 'src/app/core/services/company.service';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { validateCpf } from 'src/app/core/utils/validateCpf';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.sass']
})
export class UserCreateComponent extends BaseModal implements OnInit, ModalContent {
  @Input() initialState;
  public userForm: FormGroup;
  public currentUser: User;
  public userGroups: Array<UserGroup>;
  public userGroupContracts = [];
  public companies: Array<Company>;
  public isValid = false;
  public user: User;
  formGroupDisabled: boolean;
  public contractIds = null;
  public promise: Promise<any>;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private contractsGlobalService: ContractGlobalService,
    private userGroupService: UserGroupService,
    private companyService: CompanyService,
    private alertService: AlertService,
    private contractService: ContractService,
    private storageService: StorageService,
    modalService: ModalService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.createForm();
    const currentPermissions = this.storageService.get(StorageKey.currentPermissions);

    this.currentUser = this.storageService.get(StorageKey.currentUser);

    this.companyService.getAll().then(list => {
      if (this.currentUser.superUser) {
        this.companies = list;
      } else {
        this.companies = list.filter(a => a.id === this.currentUser.companyId);
      }
    });
    if (this.currentUser && this.currentUser.superUser === false) {
      this.contractIds = currentPermissions &&
        currentPermissions.filter(contractPermission =>
          contractPermission.actionIds.indexOf('CreateUser') >= 0)
          .map(contract => contract.contractId);
    }
    this.loadUserGroup(this.contractIds);

    if (this.initialState != null) {
      const keys = Object.keys(this.initialState);
      for (const key of keys) {
        const form = this.userForm.get(key);
        if (form) {
          form.setValue(this.initialState[key]);
        }
      }
    }
  }

  get lastState() {
    const state = {};
    const keys = Object.keys(this.userForm.controls);
    for (const key of keys) {
      state[key] = this.userForm.get(key).value;
    }
    return state;
  }

  createForm(): void {
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      companyId: ['', [Validators.required]],
      avatar: [''],
      cpf: [''],
      superUser: [false],
      role: [''],
      registryNumber: ['', [Validators.maxLength(10)]],
      groupIds: [[], [Validators.required]],
    });
    this.userForm.valueChanges.subscribe((x) => {
      if (x.companyId === '') {
        this.formGroupDisabled = true;
      } else {
        this.formGroupDisabled = false;
      }
      this.isValid = this.userForm.valid;
    });
  }

  userGroupsByCompany(company) {
    this.userGroupContracts = [];
    let contractIds = null;
    this.companyService.getById(company)
      .then(data => {
        if (this.currentUser.superUser) {
          contractIds = data.contractIds;
        } else {
          contractIds = data.contractIds.filter(contractId => this.contractIds.indexOf(contractId) >= 0);
        }
        this.loadUserGroup(contractIds);
      }).catch(err => {
        console.log(err);
      });
  }

  public async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    const cpf = this.userForm.get('cpf').value;
    if(cpf && !validateCpf(cpf)){
      return this.alertService.show(new AlertItem('InvalidCpf', AlertType.danger));
    }
    this.promise = this.userService.create(User.create({
      email: this.userForm.get('email').value,
      cpf: cpf.replace(/\D+/g, ''),
      registryNumber: this.userForm.get('registryNumber').value,
      companyId: this.userForm.get('companyId').value,
      role: this.userForm.get('role').value,
      avatar: this.userForm.get('avatar').value,
      superUser: this.userForm.get('superUser').value,
      groupIds: this.userForm.get('groupIds').value,
      verificationUrl: window.location.origin + '/verification'
    })).then(res => {
      this.alertService.show(new AlertItem('UserSaved', AlertType.success));
      this.user = res;
      return res;
    }).catch(error => {
      if (error.status === 409) {
        this.alertService.show(new AlertItem('UserSaveConflict', AlertType.danger));
      } else {
        this.alertService.show(new AlertItem('UserSaveError', AlertType.danger));
      }
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }

  loadUserGroup(contractIds) {
    let paramsUserGroup = {};
    if (contractIds != null) {
      paramsUserGroup = { 'contractId[contains,in]': `[${contractIds}]` };
    }
    this.userGroupService.getAll(paramsUserGroup).then(userGroups => {
      if(this.contractsGlobalService.contracts?.length > 0){
        userGroups = userGroups.filter(
            ug => this.contractsGlobalService.contracts.some(ct => ct === ug.contractId)
        );
      }
      this.userGroups = userGroups;
      this.userGroups.forEach(userGroup => {
        this.contractService.getById(userGroup.contractId).then(contract => {
          this.userGroupContracts.push({
            id: userGroup.id,
            prettyName: userGroup.name + ` (${contract.code} - ${contract.name})`
          });
        });
      });
    });
  }
}
