import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService, EquipmentService, EquipmentModelService } from 'src/app/core/services';
import { Calibration, Equipment, EquipmentModel } from 'src/app/core/models';
import { getCalibrationFileName } from '../../../core/utils/getCalibrationFileName';

@Component({
  selector: 'app-calibration-view-modal',
  templateUrl: './calibration-view-modal.component.html',
  styleUrls: ['./calibration-view-modal.component.sass']
})
export class CalibrationViewModalComponent extends BaseModal implements OnInit {
  public calibration: Calibration = new Calibration();
  public equipment: Equipment;
  public equipmentModel: EquipmentModel;
  public extraDataKeys = [];
  public getCalibrationFileName = getCalibrationFileName;


  constructor(
    public modalService: ModalService,
    private equipmentService: EquipmentService,
    private equipmentModelService: EquipmentModelService
  ) {
    super(modalService);
  }

  get isValid() { return null; }
  get lastState() { return null; }
  onAllSubmited() { return null; }

  ngOnInit() {
    this.calibration = this.initialState || {};
    this.extraDataKeys = Object.keys(this.calibration.extraData || {});
    this.equipmentService.getById(this.calibration.equipmentId).then(equipment => {
      this.equipment = equipment;
      this.equipmentModelService.getById(equipment.equipmentModelId).then(res => {
        this.equipmentModel = res;
      });
    });
  }

  openFile(source) {
    const win = window.open(source, '_blank');
    win.focus();
  }
}
