<app-enum-i18n [input]="statusLot" (output)="statusLotI18n=$event"></app-enum-i18n>
<app-enum-i18n [input]="typeLot" (output)="typeLotI18n=$event"></app-enum-i18n>
<app-enum-i18n [input]="situation" (output)="situationI18n=$event"></app-enum-i18n>

<div class="container">
  <div class="page-title page-title--columns align-page-title">
    <div class="page-title__left">
      <p class="page-title__link-back" i18n><a routerLink="/pre-process">Pré-processamento</a></p>
      <h2 i18n>Auditoria</h2>
    </div>
  </div>

  <div class="row no-gutters justify-content-between align-search">
    <div class="col-10 pr-3">
      <form [formGroup]="searchForm" (submit)="handleSearch()">
        <div class="input-group">
          <input type="text" class="form-control form-search" placeholder="Buscar registro" formControlName="search"
            (ngModelChange)="searchKey($event)" autocomplete="off">

          <div class="input-group-append">
            <div class="filter-dropdown">
              <button type="button" class="btn btn-outline-secondary btn-icon filter-dropdown__handle" i18n>
                <i class="material-icons">filter_alt</i> Filtrar
              </button>
              <app-enum-i18n [input]="priorities" (output)="setPrioritiesI18n($event)"></app-enum-i18n>
              <div class="filter-dropdown__content">
                <div class="form-group">
                  <app-input type="multiselect" [list]="prioritiesI18n" formControlName="priority" selectText="name"
                    selectValue="id" selectText="value" selectShowLimit="2" placeholder="Prioridade" i18n-placeholder>
                  </app-input>
                </div>
                <div class="form-group">
                  <app-input type="multiselect" [list]="statusLotI18n" formControlName="status" selectText="value"
                    selectValue="id" selectShowLimit="2" placeholder="Status" i18n-placeholder>
                  </app-input>
                </div>

                <div class="form-group">
                  <app-input type="multiselect" [list]="typeLotI18n" formControlName="type" selectText="value"
                    selectValue="id" selectShowLimit="2" placeholder="Tipo da Infração" i18n-placeholder>
                  </app-input>
                </div>

                <div class="form-group">
                  <app-input type="multiselect" [list]="situationI18n" formControlName="situation" selectText="value"
                    selectValue="id" selectShowLimit="2" placeholder="Situação do Lote" i18n-placeholder>
                  </app-input>
                </div>

                <div class="form-group">
                  <app-input type="multiselect" [list]="users" formControlName="user" selectText="name" selectValue="id"
                    selectShowLimit="2" placeholder="Auditor Responsável" i18n-placeholder>
                  </app-input>
                </div>

                <div class="filter-dropdown__footer">
                  <button i18n class="btn btn-info btn-block" (click)="handleSearch()"
                    data-dismiss="modal">Aplicar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-2">
      <div class="filter-dropdown">
        <button i18n class="btn btn-fade-secondary btn-icon">
          <i class="material-icons">view_column</i>
          Exibir Colunas
        </button>

        <app-column-filter [items]="columns" (output)="columnsToShow=$event"></app-column-filter>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading" class="mt-3 mb-3">
    <div class="table-responsive table-block align-table">
      <table class="table table-bordeless table-striped table-hover data-table">
        <thead>
          <tr>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('special')" i18n>Especial</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('priority')" i18n>Prioridade</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('status')" i18n>Status</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('lot')" i18n>Lote</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('situation')" i18n>Situação</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('review')" i18n>Revisão</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('samplingPlan') && preProcessSettings?.audit?.enabledSamplingPlan" i18n>Plano de amostragem</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('violationTotal')" i18n>Total</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('violationSample')" i18n>Amostra</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('violationAudited')" i18n>Auditado</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('violationDisapproved')" i18n>Inconsistente</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('violationPending')" i18n>Pendente</th>
            <th class="text-center break-line" i18n *ngIf="columnsToShow.includes('responsibleAuditUser')" i18n>Auditor
              Responsável</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('blockedLot')" i18n>Lote Bloqueado</th>
            <th class="text-center" i18n *ngIf="columnsToShow.includes('actions')" i18n>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lot of lots">
            <td class="text-center" i18n *ngIf="columnsToShow.includes('special')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}">
              <button class="btn" (click)="setSpecial(lot)" [disabled]="!lot?.enabled || !changesPermited || !isManageLot || lot?.audits?.length > 0">
                <span class="material-icons star" *ngIf="!lot?.isSpecial">star_border</span>
                <span class="material-icons star" *ngIf="lot?.isSpecial">grade</span>
              </button>
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('priority')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}">
              <div class="dropdown">
                <button class="btn btn-link text-secondary" type="button" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false" [disabled]="!lot?.enabled || !changesPermited || lot?.status === 'done' || !isManageLot">
                  <div [ngSwitch]='getPriority(lot)'>
                    <div *ngSwitchCase="'high'">
                      <i class="material-icons priority">expand_less</i>
                    </div>
                    <div *ngSwitchCase="'medium'">
                      <i class="material-icons medium-priority">density_medium</i>
                    </div>
                    <div *ngSwitchCase="'low'">
                      <i class="material-icons low-priority">keyboard_arrow_down</i>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu priority-menu" aria-labelledby="dropdownMenu1">
                  <span class="dropdown-item" (click)="setPriority(lot, 'high')">
                    <i class="material-icons priority">expand_less</i> Alta Prioridade
                  </span>
                  <span class="dropdown-item" (click)="setPriority(lot, 'medium')">
                    <i class="material-icons medium-priority">density_medium</i> Média Prioridade
                  </span>
                  <span class="dropdown-item" (click)="setPriority(lot, 'low')">
                    <i class="material-icons low-priority">keyboard_arrow_down</i> Baixa Prioridade
                  </span>
                </div>
              </div>
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('status')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}" i18n (click)="openLotDetail(lot)">
              {{ statusI18n[lot?.status] }}
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('lot')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}" i18n (click)="openLotDetail(lot)">
              {{ lot?.name }} <br /> {{ typeI18n[lot?.type] }}
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('situation')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}" i18n (click)="openLotDetail(lot)">
              {{ situationById && situationById[lot?.situation]?.value || '-' }}
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('review')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}" i18n (click)="openLotDetail(lot)">
              {{ lot?.revision || '-' }}
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('samplingPlan') && preProcessSettings?.audit?.enabledSamplingPlan" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}">
              <select class="form-control samplingPlan" (change)="setValue(lot, $event)" [value]="lot?.samplingPlan || 'total'"
              [disabled]="lot?.lockUserId > 0 || lot?.violationsAmount === 1 || !lot?.enabled  || !changesPermited || lot?.status === 'done' || !isManageLot">
                <option value="total">100%</option>
                <option value="simple">Simples</option>
              </select>
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('violationTotal')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}" i18n (click)="openLotDetail(lot)">
              {{ lot?.violationsAmount }}
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('violationSample')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}" i18n (click)="openLotDetail(lot)">
              {{ lot?.totalSampling || '-' }}
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('violationAudited')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}" i18n (click)="openLotDetail(lot)">
              {{ sumTotAudits(lot) || '-' }}
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('violationDisapproved')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}" i18n (click)="openLotDetail(lot)">
              {{ sumTotAuditRefuseds(lot) }}
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('violationPending')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}" i18n (click)="openLotDetail(lot)">
              {{ (lot?.violationsAmount - sumTotAudits(lot)) || '-' }}
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('responsibleAuditUser')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}">
              <select placeholder="Selecione" class="form-control" (change)="setResponsibleUser($event, lot)" [disabled]="lot?.lockUserId > 0 || !lot?.enabled || lot?.status === 'done' || !isManageLot">
                <option value="">Selecione</option>
                <option *ngFor="let user of users" value="{{ user.id }}" [selected]="user.id === lot?.targetAuditorId || (lot?.audits && user.id === lot?.audits[lot.audits.length - 1]?.authorId)">{{ user.name }}</option>
              </select>
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('blockedLot')" [ngClass]="{'setDisable':!lot?.enabled}" [ngClass]="{'setDone':lot?.status === 'done'}">
              <div class="custom-control custom-switch float-left ml-5 position-checked">
                <div class="form-group">
                  <label class="custom-control-label" [ngClass]="{checked: lot?.lockUserId > 0}">
                    <input type="checkbox" class="setActive form-control custom-control-input" (click)="setBlockedLot(lot)" [disabled]="!lot?.enabled || lot?.status === 'done' || !isManageLot">
                  </label>
                </div>
              </div>
            </td>
            <td class="text-center" i18n *ngIf="columnsToShow.includes('actions')">
              <div class="dropdown dropup">
                <button class="btn btn-link text-secondary" type="button" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false"><i class="material-icons">more_horiz</i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <span class="dropdown-item" *ngIf="isAuditLot && lot?.status !== 'done' && (!lot.targetAuditorId || lot?.targetAuditorId === this.user.id)" [ngClass]="{'disabledItem':!lot?.enabled}" (click)="redirectToViolations(lot.id, true, lot)">Iniciar Auditoria</span>
                  <span class="dropdown-item" [ngClass]="{'disabledItem':!lot?.enabled}" (click)="redirectToViolations(lot.id, false, lot)">Visualizar Lote</span>
                  <span class="dropdown-item" *ngIf="isManageLot && lot?.status !== 'done'" (click)="setActive(lot)">{{ lot.enabled ? 'Inativar Lote' : 'Ativar Lote' }}</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-pagination (items)="lots=$event" (loading)="loadingEvent($event)" [params]="params" [deleteEvent]="deleteEvent"
    [service]="lotService">
  </app-pagination>
</div>
