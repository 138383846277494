export const validateDate = (control, isBigger = true) => {
  let oldState = true;
  return (verifyControl) => {
    if (verifyControl.value === '' || control.value === '') {
      return null;
    }
    const isValid = isBigger ? verifyControl.value >= control.value : verifyControl.value <= control.value;
    const needUpdate = oldState !== isValid;
    oldState = isValid;
    if (needUpdate) {
      control.updateValueAndValidity();
    }
    if (!isValid) {
      return {
        invalidPeriod: 'Fields startHour greater than endHour'
      };
    } else {
      return null;
    }
  };
};
