<app-enum-i18n *ngIf="toBeTranslated?.length > 0" [input]="toBeTranslated" (output)="setTranslated($event)"></app-enum-i18n>
<nav class="breadcrumbs" *ngIf="levels?.length > 0">
  <ul>
    <li>
      <a routerLink="/">
        <i class="material-icons">home</i>
        <span *ngIf="levels?.length < 3" i18n>Dashboard</span>
      </a>
    </li>
    <li aria-current="page" *ngFor="let item of translatedLevels; index as i">
      <a [routerLink]="getLink(i + 1)" *ngIf="i < (translatedLevels.length -1); else last">
        <span>{{item.value}}</span>
      </a>
      <ng-template #last>
        <span>{{item.value}}</span>
      </ng-template>
    </li>
  </ul>
</nav>
