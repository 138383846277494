/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import { BaseApiModel } from './BaseApiModel';

export class MonitoringSettings extends BaseApiModel {
  contractId: number;
  maxVideoTime: number;
  observation: string;
  manageVideoTime: boolean;
  modifiedAt: string;
  createdAt: string;
}
