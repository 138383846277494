/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
export class AlertItem {
    constructor(
        public message: string,
        public type: AlertType,
        public keepAfterRouteChange: boolean = false,
        public timeout: number = null
    ) {
        this.timeout = timeout || 2000;
    }
}

export enum AlertType {
    success = 'success',
    danger = 'danger',
    warning = 'warning',
    info = 'info',
    primary = 'primary',
    secondary = 'secondary',
}
