<div class="modal-dialog modal-full modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Id da infração: {{ violation?.passId }}</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="modal-body">
      <div class="image-gallery" *ngIf="files && files.length > 0; else noImages">
        <div class="image-gallery__main">
          <div *ngFor="let file of files; let i = index" [ngClass]="{'d-block': state === i, 'd-none': state !== i}">
            <figure *ngIf="file.type !== 'video' && file.type !== 'video2'">
              <img src="{{ file.urlCurrent || file.urlDecrypted }}" alt="{{ file.type }}" />
            </figure>

            <video *ngIf="file.type === 'video' || file.type === 'video2'" controls autoplay loop muted>
              <source src="{{ file.urlCurrent || file.urlDecrypted }}" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div class="image-gallery__thumbs">
          <div *ngFor="let file of files; let i = index" (click)="state = i" [ngClass]="{'active': state === i}">
            <figure *ngIf="file.type !== 'video' && file.type !== 'video2'">
              <img src="{{ file.urlCurrent || file.urlDecrypted }}" alt="{{ file.type }}" />
            </figure>

            <video *ngIf="file.type === 'video' || file.type === 'video2'" controls autoplay loop muted>
              <source src="{{ file.urlCurrent || file.urlDecrypted }}" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <ng-template #noImages>
        <div class="card mb-3">
          <div class="card-body text-center">
            <p i18n>Não há imagens.</p>
          </div>
        </div>
      </ng-template>

      <div class="row">
        <div class="col-6">
          <div class="more-info">
            <div class="d-flex">
              <p i18n-appTooltip [appTooltip]="getRegulationInfo(violation.regulationId)">
                <span i18n>Enquadramento:</span> <i>{{ getRegulationCode(violation.regulationId) }}</i>
              </p>
              <div class="mr-3">
                <p class="mb-0 text-center"><span i18n>Velocidade</span></p>
                <div class="d-flex justify-content-between">
                  <p><span i18n>Permitida:</span> {{ violation.speedLimit ? violation.speedLimit + 'km/h' : '--'}}</p>
                  <p [ngClass]="{'speedWarning': violation?.speedWarning}"><span i18n>Registrada:</span> {{ violation.speed ? violation.speed + 'km/h' : '--' }}</p>
                  <p><span i18n>Considerada:</span> {{ violation.calculatedSpeed ? violation.calculatedSpeed + 'km/h' : '--' }}</p>
                </div>
              </div>
              <p><span i18n>Data/Hora de cometimento:</span> {{ violation.date | moment: 'DD/MM/YYYY HH:mm:ss' }}</p>
            </div>

            <div class="d-flex">
              <p><span i18n>Faixa:</span> {{ getLaneInfo(violation.laneCode) }}</p>
              <p><span i18n>Série do equipamento:</span> {{ violation?.equipment?.serialNumber || '--' }}</p>
              <p><span i18n>Data de aferição:</span> {{ violation?.equipment?.calibration?.date || '--' | moment: 'DD/MM/YYYY' }}</p>
            </div>

            <div class="d-flex">
              <p><span i18n>Local:</span> {{ violation?.address?.street }}</p>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="more-info">
            <div class="d-flex w-100">
              <p><span i18n>Placa:</span> <i>{{ violation?.vehicle?.plate || '--' }}</i></p>
              <p><span i18n>Cor:</span> {{ violation?.vehicle?.colorCode || '--' }}</p>
              <p><span i18n>Ano:</span> {{ violation?.vehicle?.year || '--' }}</p>
              <p><span i18n>Marca/Modelo:</span> {{ violation?.vehicle?.brandModelCode || '--' }}</p>
            </div>

            <div class="d-flex w-100">
              <p><span i18n>Categoria:</span> {{ getCategory(violation?.vehicle?.categoryCode) || '--' }}</p>
              <p><span i18n>Classificação:</span> {{ violation?.vehicle?.classificationCode || '--' }}</p>
            </div>

            <div class="d-flex w-100">
              <p><span i18n>País:</span> {{ violation?.vehicle?.regionId || '--' }}</p>
              <p><span i18n>UF:</span> {{ violation?.vehicle?.cityCode || '--' }}</p>
              <p><span i18n>Município:</span> {{ violation?.vehicle?.cityCode || '--' }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
