import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Calibration } from '../models';
import { BaseApiService } from './baseApiService';
import { catchError, map } from 'rxjs/operators';
import { isString } from 'util';
import { Observable } from 'rxjs';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CalibrationService extends BaseApiService<Calibration> {
  get cacheKey(): StorageKey {
    return StorageKey.calibrationServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/calibrations`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  async listByEquipment(id: string, params: { [params: string]: string} = null): Promise<Array<Calibration>> {
    let req: Observable<any>;

    if (typeof params === 'string') {
      req = this.http.get(`${environment.apiUrl}/equipments/${id}/calibrations` + params);
    } else {
      const httpParams = new HttpParams({
        fromObject: params as { [params: string]: string }
      });
      req = this.http.get(`${environment.apiUrl}/equipments/${encodeURIComponent(id)}/calibrations`, { params: httpParams });
    }
    return req.pipe(
      catchError((responseError) => this.handleError(responseError)),
      map(body => {
        body = body.result.map(model => this.setCache(model.id, model));
        return body;
      })
    ).toPromise();
  }

  protected unparse(obj) {
    return Calibration.unparse(obj);
  }

  protected parse(obj) {
    return Calibration.parse(obj);
  }

}
