/// <reference types="@types/jquery" />
/// <reference types="@types/bootstrap" />
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as mime from 'mime';
import { ModalService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-view-file-modal',
  templateUrl: './view-file-modal.component.html',
  styleUrls: ['./view-file-modal.component.sass']
})
export class ViewFileModalComponent extends BaseModal implements OnInit {
  @ViewChild('carousel', { static: false }) carousel;
  isValid: boolean;
  lastState: any;
  public files;
  public shownFile;
  public trustedUrl;

  constructor(
    public sanitizer: DomSanitizer,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.files = this.initialState && this.initialState.files || [];
    this.shownFile = this.initialState && this.initialState.shownFile || '';
    if (this.files.length === 0) {
      this.files.push(this.shownFile);
    }
  }

  getExtension(url): string {
    if (url != null) {
      const match = url.match(/\.[^.?]+(?=\?|$)/);
      if (match != null) {
        url = match[0];
      }
      return url;
    }
    return '';
  }

  getMime(url) {
    const extension = this.getExtension(url);
    return mime.getType(extension);
  }

  async onAllSubmited(): Promise<any> {
  }

  previous() {
    if (this.carousel != null) {
      $(this.carousel.nativeElement).carousel('prev');
    }
  }

  next() {
    if (this.carousel != null) {
      $(this.carousel.nativeElement).carousel('next');
    }
  }

  isDocument(url) {
    const extension = this.getExtension(url);
    const documentTypes = ['.pdf', '.docx', '.doc'];
    return documentTypes.includes(extension);
  }
}
