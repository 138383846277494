import { Component, OnInit } from '@angular/core';
import { Actions, Activity, Models, User } from 'src/app/core/models';
import { ModalService, UserService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-ativity-view-modal',
  templateUrl: './ativity-view-modal.component.html',
  styleUrls: ['./ativity-view-modal.component.sass']
})
export class AtivityViewModalComponent extends BaseModal implements OnInit {
  public activity: Activity;
  public actionsI18n = [];
  public actions = Object.values(Actions);
  public models = Object.values(Models);
  public modelsI18n = [];
  public fields = [];
  public user: User = new User();

  constructor(
    public modalService: ModalService,
    public userService: UserService
  ) {
    super(modalService);
  }

  get isValid() { return null; }
  get lastState() { return null; }
  onAllSubmited() { return null; }

  ngOnInit() {
    this.activity = this.initialState;
    const content = this.activity && this.activity.content;
    this.fields = content == null ? [] : Object.keys(content);
    if (this.activity && this.activity.userId) {
      this.userService.getById(this.activity.userId).then(user => {
        this.user = user;
      });
    }
  }

  getActionOrModel(actionId = '', index = 1) {
    const regex = /([A-Z][a-z]*)([A-Z][a-zA-Z]*)/;
    if (this.actionsI18n.length === 0) {
      return;
    }
    const match = actionId.match(regex);
    let result = index === 1 ? actionId : '';
    if (match != null) {
      result = match[index];
    }

    const action = index === 1 ? this.actionsI18n.find(q => q.id === result) : this.modelsI18n.find(q => q.id === result);
    const value = (action && action.value) || result;
    const complement = index === 2 && (action && action.value) ? ' de ' : ' ';
    return complement + value;
  }
}
