<div class="panel" [ngClass]="{'panel--show': !isRendering}">
  <div class="panel__container">
    <div class="panel__header">
      <h3 i18n>Comentários</h3>
      <p class="text-secondary" i18n>Comentários realizados</p>
    </div>

    <div class="panel__body">
      <div class="comment__list violation-comment" #comment [scrollTop]="scrollDown">
        <div *ngFor="let note of notes; index as i">
          <div class="d-flex">
            <div class="account__avatar mr-3">
              <app-avatar [user]='userById[note.userId]' class="avatar-header"></app-avatar>
            </div>
            <div class="media-body">
              <h5 class="mt-0 mb-0">{{ userById[note.userId].name }}</h5>
              <time class="text-secondary"><small>{{ note.dateTime | moment: 'LLL' }}</small></time>
              <div class="mt-2">{{ note.comment }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel__bottom">
      <form [formGroup]="noteForm" (submit)="onSubmit()">
        <div class="d-flex w-100 justify-content-between">
          <label>Comentar</label>
          <span>{{ amountWordsTyped }}</span>
        </div>

        <div class="w-100">
          <app-input i18n-label i18n-placeholder type="textarea" formControlName="comment"
          placeholder="Digite Comentário" class="w-100" (ngModelChange)="searchKey($event)"></app-input>
        </div>

        <div class="d-flex w-100 justify-content-end">
          <button class="btn btn-outline-secondary mr-3" (click)="onCancel()" i18n>Cancelar</button>
          <button (click)="saveNotes()" [ngClass]="{btn: true, 'btn-info': true, disabled: !isValid}"
            i18n>Comentar</button>
        </div>
      </form>
    </div>
  </div>
</div>
