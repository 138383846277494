import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  AuthenticationService, ContractGlobalService,
  ContractService,
  StorageService,
  StorageKey,
  ModalService,
  ViolationService
} from '../../services';
import { ComponentModal, Contract } from '../../models';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, ResolveEnd, ActivationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { getAllowedContracts } from 'src/app/core/utils/permission';
import { ReleaseNotesService } from '../../services/releaseNotes.service';
import { ReleaseNotesComponent } from 'src/app/modals/release-notes-modal/release-notes.component';
import { ClosedModalError } from '../../errors';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('menu', { static: true, read: ElementRef }) menu: ElementRef;
  @ViewChild('menuButton', { static: true, read: ElementRef }) menuButton: ElementRef;

  public isMenuOpen = false;
  public contracts: Contract[] = [];
  public listContracts: Contract[] = [];
  public theme = environment.theme;
  public urlContractId;
  public breadcrumbs: { isTranslatable: boolean; level: string}[];
  public lastReleaseUser;
  public lastReleaseUpdated;
  public noticeLastRelease = false;
  public hideSelectContract = false;
  public user: any = {};
  public selectContracts = [];
  private _subs: Array<Subscription> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private storageService: StorageService,
    public contractService: ContractService,
    private contractGlobalService: ContractGlobalService,
    private router: Router,
    private releaseNotesService: ReleaseNotesService,
    public violationService: ViolationService,
    private modalService: ModalService
    ) {
  }


  async ngOnInit() {
    const user = this.storageService.get(StorageKey.currentUser);
    if (user != null) {
      this.user = user;
    }

    this.releaseNotesService.getAll().then(notes => {
      if (notes.length > 0) {
        this.lastReleaseUpdated = notes.slice(-1).pop().version;
        this.lastReleaseUser = this.storageService.get(StorageKey.lastReleaseNote) || this.lastReleaseUpdated;
        this.storageService.set(StorageKey.lastReleaseNote, this.lastReleaseUpdated);
        if (this.lastReleaseUser !== this.lastReleaseUpdated) {
          this.noticeLastRelease = true;
        }
      }
    }).catch(error => {
      console.log(error);
    });

    this.contracts = _.sortBy(await this.contractService.getAll().catch(error => this.contracts), 'code');
    this.listContracts = this.contracts.slice();

    const findContractId = (route) => {
      let value = route.snapshot.paramMap.get('contractId');
      if (value != null) {
        return value;
      }
      for (const r of route.children) {
        value = findContractId(r);
        if (value != null) {
          return value;
        }
      }
      return null;
    };

    const isHideSelectContract = (route) => {
      this.hideSelectContract = false;
      const hiddedPaths = ['production-report', 'available-contracts', 'violations-report', 'violations-view'];
      for (const r of route.children) {
        if (r.snapshot.url.some(url => hiddedPaths.includes(url.path))) {
          this.hideSelectContract = true;
        }
      }
    };

    this._subs.push(this.router.events.subscribe((event) => {
      if (event instanceof ResolveEnd || event instanceof NavigationEnd) {
        this.breadcrumbs = this.getUrl(event.url);
      }
      if (event instanceof NavigationEnd) {
        isHideSelectContract(this.activatedRoute);
        this.urlContractId = findContractId(this.activatedRoute);
        this.setAllowedContractsByRoutes(this.activatedRoute);
      }
    }));

    isHideSelectContract(this.activatedRoute);
    // this.setAllowedContractsByRoutes(this.activatedRoute);
    this.urlContractId = findContractId(this.activatedRoute);
    this._subs.push(this.contractGlobalService.contractEvent.subscribe(contracts => {
      this.selectContracts = contracts;
    }));
    if (this.contractGlobalService.contracts != null) {
      this.selectContracts = this.contractGlobalService.contracts;
    } else if (this.contracts.length === 1) {
      this.setContracts(this.contracts.map(c => c.id));
    }
    this.breadcrumbs = this.getUrl(this.router.url);

    if (this.router.url === '/ecsSpy/filter/validate' || this.router.url === '/ecsSpy/filter/verify') {
      this.hideSelectContract = true;
    }
  }

  getUrl(url) {
    url = url.split('?')[0];
    const link =  url === '/' ? [] : url.split('/');
    if (link.length > 1) {
      link.shift();
    }
    return link.slice(0, 3);
  }

  setAllowedContractsByRoutes(route) {
    if (this.user.superUser === false) {
      const contractIds = getAllowedContracts(route, this.storageService);
      if (contractIds && contractIds.length > 0) {
        this.contracts = this.listContracts.filter(contract => contractIds.indexOf(contract.id) >= 0);
      }
    }
  }

  ngOnDestroy() {
    this._subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  logout() {
    const user = this.storageService.get(StorageKey.currentUser);
    if (user == null || user.id == null) {
      return;
    }
    const userId = user.id;
    this.violationService.unlock(userId, []);
    this.authService.logout();
  }

  toogleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  setContracts(contracts) {
    if (contracts.length === 0) {
      contracts = null;
    }
    this.contractGlobalService.contracts = contracts;
  }

  click(event) {
    if (this.isMenuOpen) {
      if (this.menuButton.nativeElement === event.target || this.menuButton.nativeElement.contains(event.target)) {
        return;
      }
      if (this.menu.nativeElement !== event.target && !this.menu.nativeElement.contains(event.target)) {
        this.isMenuOpen = false;
      }
    }
  }

  onlyDashboard() {
    return this.router.url === '/';
  }

  clickModalRelease() {
    this.storageService.set(StorageKey.lastReleaseNote, this.lastReleaseUpdated);
    this.lastReleaseUser = this.storageService.get(StorageKey.lastReleaseNote);
    this.noticeLastRelease = false;
    this.modalService.show(new ComponentModal(ReleaseNotesComponent))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }
}
