<div (click)='click($event)'>
  <header class="main-header">
    <div class="main-header__menu-handle" [ngClass]="{'main-header__menu-handle--show': isMenuOpen}" (click)="toogleMenu()" #menuButton>
      <i class="handle"></i>
    </div>

    <div class="main-header__logo" routerLink="/" [ngSwitch]="theme">
      <img *ngSwitchCase="'gtots'" src="/assets/images/logo-menu.png" alt="GTOTS">
      <img *ngSwitchDefault src="/assets/images/ecs.svg" alt="ECS">
    </div>

    <div class="main-header__release-notes-div" *ngIf="noticeLastRelease && onlyDashboard()">
      <i class="main-header__release-notes-icon material-icons ml-2">
        error
      </i>
      <span class="main-header__release-notes-text" (click)="clickModalRelease()" i18n>
        Nova versão {{lastReleaseUpdated}} disponível
      </span>
    </div>

    <app-breadcrumbs [levels]="breadcrumbs"></app-breadcrumbs>

    <div class="main-header__contract">
      <app-input i18n-placeholder="@@ContractLabel" class="select-contract" type="multiselect"
        [ngModel]="selectContracts" placeholder="Selecione o contrato" selectText="prettyName" selectValue="id"
        [list]="contracts" hasLabel="false" (ngModelChange)="setContracts($event)" selectShowLimit=1
        *ngIf="urlContractId===null && !hideSelectContract"></app-input>
    </div>

    <div class="main-header__account">
      <div class="dropdown">
        <button class="btn btn-link dropdown-toggle text-secondary user-header" type="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" i18n>
          Olá, {{user.name}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
          <a class="dropdown-item" [routerLink]="['/users/myProfile']" routerLinkActive="router-link-active" i18n>Meu
            perfil</a>
          <a class="dropdown-item" (click)="logout()">Sair</a>
        </div>
      </div>
      <div class="account__avatar">
        <app-avatar [user]='user' class="avatar-header"></app-avatar>
      </div>
    </div>
  </header>

  <app-menu [(isOpen)]="isMenuOpen" #menu></app-menu>

  <section class="dashboard">
    <router-outlet></router-outlet>
  </section>

</div>

<app-go-top></app-go-top>
