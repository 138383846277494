<div class="container mb-5">
  <div class="page-title">
    <p class="page-title__link-back" i18n><a routerLink="/pre-process">Pré-processamento</a></p>
    <h2 i18n>Selecione o contrato</h2>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <input type="text" class="form-control form-search" [(ngModel)]="searchValue"
    placeholder="Buscar registro" (ngModelChange)="searchKey($event)" autocomplete="off">
    </div>
  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-12 col-sm-4 col-md-3 mt-4" *ngFor="let contract of contracts.filter(hasPreProcessModule)">
      <div class="card card-link" (click)="selectedContract(contract.id)">
        <div class="card-body">
          <h3>{{contract.code}}</h3>
          <p class="text-secondary mb-0"><small>{{contract.name}}</small></p>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-page" *ngIf="loading">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</div>
