/* eslint-disable @typescript-eslint/naming-convention */
/*eslint no-import-assign: "error"*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from '../models';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StorageService, StorageKey } from './storage.service';
import { map } from 'rxjs/operators';
import { BaseApiService } from './baseApiService';
import pkg from '../../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUser: Observable<User>;
  private baseUrl = `${environment.apiUrl}`;
  private user: User;
  private versionApi = '1.0.10';

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private storageService: StorageService
  ) {
    this.user = User.create(this.storageService.get(StorageKey.currentUser));
  }

  public get currentUserValue(): User {
    return this.user;
  }

  public login(email: string, password: string, rememberMe: boolean = true) {
    const data = JSON.stringify({ email, password, rememberMe });

    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + '/login', data, { headers: header }).toPromise()
      .then((response: any) => {
        if (response) {
          const accessToken = response.accessToken;
          const refreshToken = response.refreshToken;
          const groupIdsPermission = response.permissions;
          const user = response.user;

          if (!user.superUser && groupIdsPermission.length <= 0 ) {
            throw new Error('Acesso negado');
          }
          const contracts = [];
          for (const group of groupIdsPermission) {
            contracts.push(group.contractId);
          }

          this.storage.set(StorageKey.currentUser, user);
          this.storage.set(StorageKey.accessToken, accessToken);
          this.storage.set(StorageKey.currentContracts, contracts);
          this.storage.set(StorageKey.currentPermissions, groupIdsPermission);
          if (refreshToken != null) {
            this.storage.set(StorageKey.refreshToken, refreshToken);
          } else {
            this.storage.remove(StorageKey.refreshToken);
          }
          this.checkVersion();
          return accessToken;
        }
        return null;
      });
  }

  public refreshToken() {
    const refreshToken = this.storage.get(StorageKey.refreshToken);
    if (refreshToken == null) {
      return throwError(new Error('No refresh token'));
    }
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${refreshToken}`
    });

    return this.http.get(this.baseUrl + '/accessToken', { headers: header })
      .pipe(map((response: any) => {
        this.storage.set(StorageKey.accessToken, response.accessToken);
        if (response.user != null) {
          this.storage.set(StorageKey.currentUser, response.user);
        }
        return response;
      }));
  }

  private get cacheKeep(): string[] {
    return [
      StorageKey.filterTarget,
      StorageKey.recordsNumberByListageCache
    ];
  }

  public async checkVersion() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this.versionApi = await this.http.get(this.baseUrl + '/visum/version', { headers: header }).toPromise()
    .then((data: any) => data[0].version).catch(err => { });

    if (pkg.version < this.versionApi) {
      BaseApiService.clearCaches();
      this.storage.set(StorageKey.lastReleaseNote, pkg.version);
    }
  }

  public logout(navigate = true) {
    const lastRelease = this.storage.get(StorageKey.lastReleaseNote);

    for (const key in StorageKey) {
      if (this.cacheKeep.indexOf(StorageKey[key]) < 0) {
        this.storage.remove(StorageKey[key]);
      }
    }
    // clear cache in memory for instanced services
    BaseApiService.clearCaches();

    this.storage.set(StorageKey.lastReleaseNote, lastRelease);

    if (navigate) {
      this.router.navigate(['login']);
    }
  }
}
