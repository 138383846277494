import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  private events = new Subject<User>();

  getEvents(): Observable<User> {
    return this.events.asObservable();
  }

  updateMyProfile(user: User) {
    this.events.next(user);
  }
}
