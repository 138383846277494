<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Detalhes do Veículo Isento</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body label">
      <div class="container">
        <div class="row">
          <div class="col-3 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" label="Contrato"
              [textValue]="contract && contract.code + ' - '  + contract.name">
            </app-input>
          </div>
          <div class="col-3 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" label="Placa" [uppercase]="true"
              [textValue]="vehicleWhiteList.plate || '-'">
            </app-input>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" label="Enquadramentos"
              [textValue]="vehicleWhiteList.regulationIds || '-'">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <fieldset>
              <legend i18n>Vigência</legend>
              <div class="row">
                <div class="col-6">
                  <label class="text-secondary">Início</label>
                  <p>{{ vehicleWhiteList.startsAt | moment: 'L'}} {{ vehicleWhiteList.startsAt | moment: 'LTS'}}</p>
                </div>
                <div class="col-6">
                  <label class="text-secondary">Fim</label>
                  <p>{{ vehicleWhiteList.endsAt | moment: 'L'}} {{ vehicleWhiteList.endsAt | moment: 'LTS'}}</p>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <app-input type="text" [isLabel]="true" label="Zona de Restrição"
              [textValue]="(restrictionZonesById && restrictionZonesById[vehicleWhiteList.restrictionZoneId]?.description) || '-'">
            </app-input>
          </div>
          <div class="col-6">
            <app-input type="text" [isLabel]="true" label="Modelo do Veículo"
            [textValue]="(modalityVehiclesById && modalityVehiclesById[vehicleWhiteList.modalityId]?.description) || '-'">
            </app-input>
          </div>
        </div>
      </div>
    </div>
  </div>
