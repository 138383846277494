/* eslint-disable no-shadow */
import { BaseApiModel } from './BaseApiModel';

export class Quarantine extends BaseApiModel {
  public code: string;
  public name: string;
  public clientCode: string;
  // eslint-disable-next-line id-blacklist
  public number: number;
  public equipmentIds: Array<string>;
  public contractId: string;
  public spotId: string;
  public direction: string;
  public modifiedAt: Date;
  public createdAt: Date;
  public operationMode: string;
  public isBetweenLane: boolean;
  public speeds: {
    regularVehicles: number;
    heavyVehicles: number;
    regulationLimits: Array<{
      regulationId: string;
      initialRegular: number;
      finalRegular: number;
      initialHeavy: number;
      finalHeavy: number;
    }>;
  };
  public enabledViolations: Array<{
    regulationId: string;
    classificationCode: string;
    requiredFiles: Array<string>;
    periods: Array<{
      startsAt: string;
      endsAt: string;
      startTime: string;
      endTime: string;
      weekdays: Array<number>;
      plateRegex: string;
    }>;
  }>;
  public extraData: any;
  public report: any;
}

export enum OperationModes {
  enabled = 'enabled',
  test = 'test',
  disabled = 'disabled'
}

export enum QuarantineStatus {
  disabledLane = 'disabledLane',
  testingLane = 'testingLane',
  wrongRegulation = 'wrongRegulation',
  calibrationFailure = 'calibrationFailure',
  laneRegulationNotEnabled = 'laneRegulationNotEnabled',
  waitingFiles = 'waitingFiles',
  exemptPeriod = 'exemptPeriod',
  whiteList = 'whiteList',
  invalidSpeed = 'invalidSpeed',
  oddSpeed = 'oddSpeed',
  disabledRegulation = 'disabledRegulation',
  invalidWeekDay = 'invalidWeekDay',
  invalidDayPeriod = 'invalidDayPeriod',
  invalidAppraisalDate = 'invalidAppraisalDate',
  expiredAppraisal = 'expiredAppraisal',
  missingAppraisalDocument = 'missingAppraisalDocument',
  invalidAppraisalNumber = 'invalidAppraisalNumber',
  invalidLaneNumber = 'invalidLaneNumber',
  invalidEquipmentSerialNumber = 'invalidEquipmentSerialNumber',
  laneFailure = 'laneFailure',
  invalidVehicleClassification = 'invalidVehicleClassification',
  invalidRegulationPeriod = 'invalidRegulationPeriod',
  violationLimit = 'violationLimit',
  spotMissing = 'spotMissing',
  disabledEquipment = 'disabledEquipment',
  invalidSerialNumber = 'invalidSerialNumber',
  equipmentFailure = 'equipmentFailure',
  speedFailure = 'speedFailure',
  missingTarge = 'missingTarge'
}
