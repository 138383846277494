import { NgModule, Sanitizer } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginLayoutComponent } from './layouts/login/login-layout/login-layout.component';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { MenuComponent } from './layouts/menu/menu.component';
import { DashboardProcessComponent } from './layouts/dashboard-process/dashboard-process.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    LoginLayoutComponent,
    DashboardComponent,
    MenuComponent,
    DashboardProcessComponent
  ],
  exports: [
    LoginLayoutComponent,
    DashboardComponent,
    MenuComponent,
    DashboardProcessComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule
  ]
})
export class CoreModule { }
