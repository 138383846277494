import { Component, OnInit } from '@angular/core';
import { Regulation, AlertItem, AlertType } from 'src/app/core/models';
import { RegulationService } from 'src/app/core/services/regulation.service';
import { ContractService, ModalService, AlertService } from 'src/app/core/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-triage-config-modal',
  templateUrl: './triage-config-modal.component.html',
  styleUrls: ['./triage-config-modal.component.sass']
})
export class TriageConfigModalComponent extends BaseModal implements OnInit {
  public regulations: Array<Regulation> = [];
  public selectedRegulationIds = [];
  public form: FormGroup;

  constructor(
    private regulationService: RegulationService,
    private contractService: ContractService,
    private formBuilder: FormBuilder,
    public modalService: ModalService,
    private alertService: AlertService
  ) {
    super(modalService);
  }

  ngOnInit() {
    if (this.initialState == null) {
      this.alertService.show(new AlertItem('GenericError', AlertType.danger));
    }
    const params = this.initialState || {};
    const contractId = params.contractId;
    this.selectedRegulationIds = params.regulationIds;

    this.contractService.getById(contractId).then(contract => {
      this.regulationService.getAll({ regionId: contract.regionId }).then(regulations => {
        this.regulations = regulations;
      });
    });
    this.form = this.createForm(this.selectedRegulationIds);
  }

  createForm(regulationIds = []) {
    const form = this.formBuilder.group({
      regulationIds: [regulationIds, Validators.required]
    });
    return form;
  }

  get isValid(): boolean {
    if (this.form != null) {
      return this.form.valid;
    } else {
      return false;
    }
  }

  get lastState(): any {
    return null;
  }

  async onAllSubmited(): Promise<any> {
    this.selectedRegulationIds = this.form.get('regulationIds').value;
  }

}
