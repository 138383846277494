import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertItem, AlertType } from 'src/app/core/models';
import { AlertService, AuthenticationService, PasswordService, TokenService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.sass']
})
export class VerifyUserComponent implements OnInit {

  changePasswordForm: FormGroup;
  loading = false;
  token = '';
  userId = '';
  public forgotPassword = false;
  public theme = environment.theme;
  type = 'verify';
  public isTokenValid = false;
  public isValid = false;
  public specialCharacter: boolean = false;
  public uppercaseCharacter: boolean = false;
  public minCharacterValid: boolean = false;
  public hasNumber: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private setPasswordService: PasswordService,
    private verifyTokenService: TokenService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.token = this.route.snapshot.queryParamMap.get('passwordToken') || this.route.snapshot.queryParamMap.get('verificationToken');
    this.forgotPassword = this.route.snapshot.queryParamMap.get('passwordToken') != null ? true : false;
    this.type = this.forgotPassword ? 'setPassword' : this.type;
    this.verifyToken();
    this.createForm();
    this.authenticationService.logout(false);
  }

  createForm(): void {
    this.changePasswordForm = this.formBuilder.group({
      name: [''],
      password: ['', [Validators.required]],
      passwordConfirmation: ['', [Validators.required]],
      privateTerm: ['']
    });
    this.changePasswordForm.get('password').valueChanges.subscribe(value => {
      const regexSpecialCharacter = /\W|_/;
      const regexUppercase = /[A-Z]/;
      const regexHasNumber = /[0-9]/;
      this.specialCharacter = regexSpecialCharacter.test(value);
      this.uppercaseCharacter = regexUppercase.test(value);
      this.hasNumber = regexHasNumber.test(value);
      if (value.length >= 6) {
        this.minCharacterValid = true;
      } else {
        this.minCharacterValid = false;
      }
    });
    if (!this.forgotPassword) {
      this.changePasswordForm.get('privateTerm').setValidators(Validators.required);
      this.changePasswordForm.get('name').setValidators([Validators.required, Validators.pattern('^([A-Za-zÀ-ÿ.]+( )?)+$')]);
    }
    this.changePasswordForm.valueChanges.subscribe(() => {
      if (!this.forgotPassword) {
        if (
          this.changePasswordForm.get('password').value === this.changePasswordForm.get('passwordConfirmation').value &&
          this.changePasswordForm.get('privateTerm').value && this.minCharacterValid && this.uppercaseCharacter&& this.specialCharacter && this.hasNumber
        ) {
          this.isValid = true;
        } else { this.isValid = false; }
      } else {
        if (
          this.changePasswordForm.get('password').value === this.changePasswordForm.get('passwordConfirmation').value &&
          this.minCharacterValid && this.uppercaseCharacter&& this.specialCharacter && this.hasNumber
        ) {
          this.isValid = true;
        } else { this.isValid = false; }
      }
    });
  }

  verifyToken(): void {
    if (this.token == null) {
      this.router.navigate(['invalidToken']);
    }
    this.verifyTokenService.verifyToken(this.token)
      .subscribe(
        res => {
          this.isTokenValid = true;
        },
        error => {
          this.router.navigate(['invalidToken']);
        }
      );
  }

  setPassword(): void {
    this.loading = true;
    const name = this.changePasswordForm.get('name').value;
    const password = this.changePasswordForm.get('password').value;
    const passwordConfirmation = this.changePasswordForm.get('passwordConfirmation').value;

    if (password !== passwordConfirmation || (!name && !this.forgotPassword)) {
      this.alertService.show(new AlertItem('PasswordMismatch', AlertType.danger));
      this.loading = false;
    } else {
      this.setPasswordService.setPassword(name, password, this.userId, this.token, this.type)
        .subscribe(
          res => {
            this.router.navigate(['']);
          },
          error => {
            this.loading = false;
            this.alertService.show(new AlertItem('PasswordSaveError', AlertType.danger, false, 3000));
          }
        );
    }
  }
}
