<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <div>
        <h5 class="mb-0 modal-title" i18n>Pesquisar o local</h5>
        <h6 class="text-secondary" i18n><small>Preencha o campo endereço ou selecione um ponto no mapa</small></h6>
      </div>

      <button type="button" (click)="onCancel()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>

    <div class="modal-body">
      <div>
        <form [formGroup]="addressForm">
          <div class="row">
            <div class="col-12">
              <div class="input">
                <app-input i18n-label="@@SpotMapAddressLabel" formControlName="street" type="text" label="Endereço"></app-input>
              </div>
            </div>
            <div class="col-6">
              <app-input i18n-label="@@SpotMapLatitudeLabel" formControlName="latitude" type="text" label="Latitude">
              </app-input>
            </div>
            <div class="col-6">
              <app-input i18n-label="@@SpotMapLongitudeLabel" formControlName="longitude" type="text" label="Longitude">
              </app-input>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-center">
          <div #map class="map" (click)="fillCoordinates()">
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button (click)="onCancel()" class="btn btn-outline-secondary">Cancelar</button>
      <button (click)="onSubmit()" class="btn btn-primary" [disabled]="!isValid">Confirmar</button>
    </div>
  </div>
