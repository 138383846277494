<app-enum-i18n [input]="resourcesToTranslate" (output)="i18nResources=$event"></app-enum-i18n>
<app-enum-i18n [input]="lotCloseConditions" (output)="lotCloseConditionsI18n=$event"></app-enum-i18n>
<app-enum-i18n [input]="cronIds" (output)="cronIdsI18n=$event"></app-enum-i18n>
<app-enum-i18n [input]="weekdays" (output)="weekdaysI18n=$event"></app-enum-i18n>

<div class="card-header">
  <div class="row d-flex justify-content-between">
    <div class="col-4">
      <p i18n class="my-2 font-weight-bold align-font-size">Exportação
        <i i18n-appTooltip class="material-icons icon-sm text-secondary" data-placement="top"
          appTooltip="Para adicionar uma configuração e/ou editar uma configuração existente é necessário 'PAUSAR' a atribuição automática.">help</i>
      </p>
    </div>

    <div class="col-auto d-flex justify-content-end">
      <div *ngIf="autoGenerationConfigs && autoGenerationConfigs.length > 0">
        <button class="btn btn-fade-success" *ngIf="stopAttribution === true" [ngClass]="{'disabled': !_isValid}"
              (click)="updateStopAttribution(false)">
            <app-loading [promise]="promise" text="Iniciar atribuição automática" i18n-text></app-loading>
        </button>
        <button class="btn btn-fade-danger" *ngIf="stopAttribution === false" [ngClass]="{'disabled': !_isValid}"
          (click)="updateStopAttribution(true)">
          <app-loading [promise]="promise" text="Pausar atribuição automática" i18n-text></app-loading>
        </button>
      </div>
      <button i18n class="btn btn-fade-primary ml-3"
        (click)="addNewConfig()" [ngClass]="{'disabled': !stopAttribution}">Nova Configuração</button>
    </div>
  </div>
</div>

<div class="col-12" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="autoGenerationConfigs">
  <div class="row align-items-center no-gutters mt-4" *ngFor="let config of autoGenerationConfigs; index as i" cdkDrag>
    <div class="col-auto">
      <button class="btn btn-icon-block d-block text-secondary" (click)="move(i, i - 1)">
        <i class="material-icons" i18n-appTooltip appTooltip="Mover para cima">north</i></button>
      <button class="btn btn-icon-block d-block text-secondary" (click)="move(i, i + 1)">
        <i class="material-icons" i18n-appTooltip appTooltip="Mover para baixo">south</i></button>
    </div>

    <div class="col">
      <div class="card card-bordered">
        <div class="card-header">
          <div class="row d-flex align-items-center justify-content-between">
            <div class="col">
              <h5 class="mb-0">{{config.namePattern}} - {{config.pathPattern}}</h5>
            </div>
            <div class="col-auto">
              <div cdkDragHandle i18n-title *ngIf="stopAttribution"
                class="btn btn-icon-block text-secondary btn-move"
                title="arrastar">
                <i class="material-icons" i18n-appTooltip appTooltip="Mover">drag_handle</i>
              </div>
              <div class="btn btn-icon-block text-secondary" (click)="removeConfig(i)">
                <i class="material-icons" i18n-appTooltip appTooltip="Remover">remove_circle</i>
              </div>
              <div class="btn btn-icon-block text-secondary" (click)="setSpecial(config)">
                <i
                  *ngIf="config.form.get('lotSpecial').value" class="material-icons isSpecial" i18n-appTooltip appTooltip="Lote Especial" md-36 orange600>star</i>
                <i *ngIf="!config.form.get('lotSpecial').value" class="material-icons" md-36 orange600>star_border</i>
              </div>
            </div>
            <div class="float-menu">
              <button
                type="button" class="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                [ngClass]="{disabled: !stopAttribution}">
                <i class="material-icons">more_vert</i>
              </button>
              <div class="dropdown-menu" aria-labelledby="btnGroupDrop4">
                <div>
                  <a class="dropdown-item pointer" (click)="removeLoteControlId(i)" i18n>
                    <app-loading [promise]="promise" text="Reiniciar o nº identificador do lote" i18n-text></app-loading>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <form [formGroup]="config.form">
            <div class="row">
              <div class="col-12">
                <app-input i18n-label i18n-toolTipMsg type="text" class="pattern-input"
                  [inputDisabled]="!stopAttribution"
                  formControlName="namePattern" label="Padrão de Nome" inputRequired="true"
                  toolTipMsg="Expressão regular que define o padrão do nome do lote">
                </app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <app-input i18n-label type="text" formControlName="maxAmountViolation"
                  [inputDisabled]="!stopAttribution"
                  label="Limite de infrações no lote"
                  inputRequired="true"
                  type="number"
                  toolTipMsg="A quantidade configurada determina o fechamento do lote, máximo 1000">
                </app-input>
              </div>
              <div class="col-12 col-sm-6">
                <app-input i18n-label type="text" formControlName="generationLimit"
                  [inputDisabled]="!stopAttribution"
                  type="number"
                  label="Limite de lotes a serem gerados">
                </app-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label i18n>Condição de fechamento do lote <small class="text-danger">*</small></label>
              </div>
              <div class="col-12 col-sm-3">
                <app-input i18n-placeholder type="select" [list]="lotCloseConditionsI18n" selectText="value"
                  [inputDisabled]="!stopAttribution"
                  formControlName="lotCloseCondition"
                  selectValue="id" placeholder="Selecione um período"
                  class="pattern-input" required>
                </app-input>
              </div>
              <div class="col-12 col-sm-9"
                [ngSwitch]="config.form.get('lotCloseCondition').value">
                <div class="row" *ngSwitchCase="'period'">
                  <div class="col-12 col-sm-9">
                    <mat-radio-group formControlName="lotCloseConditionCron" color="primary"
                      [inputDisabled]="!stopAttribution"
                      aria-label="Select an option" class="align-radio-groups mt-2">
                      <mat-radio-button *ngFor="let cronId of cronIdsI18n" [value]="cronId.id"
                      [disabled]="!stopAttribution" i18n>{{cronId.value}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div class="col-12 col-sm-3">
                    <div *ngIf="config.form.get('lotCloseConditionCron').value !== 'byWeek'; else week">
                      <app-input formControlName="lotCloseHour" i18n-leftUnit
                        [inputDisabled]="!stopAttribution"
                        leftUnit="às" format="dd:dd">
                      </app-input>
                    </div>
                    <ng-template #week>
                      <app-input formControlName="lotCloseWeek" [list]="weekdaysI18n" selectText="value"
                        [inputDisabled]="!stopAttribution"
                        i18n-placeholder type="select" selectValue="id" placeholder="Selecione o dia da Semana">
                      </app-input>
                    </ng-template>
                  </div>
                </div>
                <div class="row" *ngSwitchCase="'time'">
                  <div class="col-6 d-flex">
                    <app-input i18n-label i18n-rightUnit type="number" formControlName="period" class="period"
                      [inputDisabled]="!stopAttribution"
                      rightUnit="horas">
                    </app-input>
                    <i i18n-appTooltip class="material-icons icon-sm text-secondary ml-1 mt-2"
                      [inputDisabled]="!stopAttribution"
                      appTooltip="O lote também pode ser fechado de acordo com o limite de infrações configurado."
                      i18n>help</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <app-input class="input-multiselect" i18n-label type="multiselect" [list]="lanes"
                  [inputDisabled]="!stopAttribution"
                  selectText="prettyNames" selectValue="id" formControlName="laneIds" label="Faixas"
                  selectShowLimit="3">
                </app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <app-input i18n-label class="input-multiselect" type="multiselect" [list]="regulations"
                  [inputDisabled]="!stopAttribution"
                  selectText="prettyName" selectValue="id" formControlName="regulationIds" selectShowLimit="3"
                  label="Enquadramentos">
                </app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                 <app-input i18n-label class="input-multiselect" type="multiselect" [list]="equipmentModels"
                  [inputDisabled]="!stopAttribution"
                  formControlName="equipmentModelIds" label="Modelo do equipamento" selectText="pretty"
                  selectValue="id" selectShowLimit="1">
                </app-input>
              </div>

              <div class="col-12 col-sm-6">
                <app-input i18n-label class="input-multiselect" type="select" [list]="violationSource"
                  [inputDisabled]="!stopAttribution"
                  formControlName="violationSourceAbbreviation" label="Origem da Multa" selectText="prettyName"
                  selectValue="id">
                </app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <app-enum-i18n [input]="violationStatus" (output)="violationStatusI18n=$event"></app-enum-i18n>
                <app-input i18n-label class="input-multiselect" type="multiselect" [list]="violationStatusI18n"
                  [inputDisabled]="!stopAttribution"
                  formControlName="violationStatus" label="Situação da infração" selectText="value"
                  selectValue="id">
                </app-input>
              </div>

              <div class="col-12 col-sm-6">
                <app-input i18n-label class="input-multiselect" type="multiselect" [list]="reasons"
                  [inputDisabled]="disableViolationStatus(i) || !stopAttribution"
                  formControlName="reasonIds" selectText="prettyName" selectValue="id" label="Motivo de Invalidação"
                  selectShowLimit="3">
                </app-input>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-12">
                <app-input i18n-label i18n-toolTipMsg type="text" class="pattern-input"
                  formControlName="pathPattern" label="Padrão de Caminho" inputRequired="true"
                  [inputDisabled]="config.form.get('isNotProcessable').value || !stopAttribution" toolTipMsg="Expressão regular que define o padrão de caminho a serem disponibilizados os lotes gerados">
                </app-input>
              </div>
              <div class="col-12">
                <mat-checkbox color="primary"
                  [disabled]="!stopAttribution"
                  formControlName="isNotProcessable">
                  <span i18n>Não processar este lote:&nbsp;</span>
                </mat-checkbox>
                <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                  [inputDisabled]="!stopAttribution"
                  appTooltip=" Todos os lotes ficarão disponíveis para consulta na plataforma, inclusive os que não são processados">help</i>
              </div>
              <div class="col-12">
                <mat-checkbox color="primary" *ngIf="enableOddSpeed(config.form.get('regulationIds').value)"
                  [disabled]="!stopAttribution"
                  formControlName="oddSpeedParam">
                  <span i18n>Atribuir à este lote somente infrações com velocidade discrepante&nbsp;</span>
                </mat-checkbox>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
