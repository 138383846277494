import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AlertItem, AlertType, Contract, EquipmentModel, Manufacturer } from 'src/app/core/models';
import { AlertService, ContractService, EquipmentModelService, ModalService, StorageService,
   StorageKey, ManufacturerService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-equipment-model-panel',
  templateUrl: './equipment-model-panel.component.html',
  styleUrls: ['./equipment-model-panel.component.sass']
})
export class EquipmentModelPanelComponent extends BaseModal implements OnInit, OnDestroy {
  public contract: Contract;
  public equipmentModels: EquipmentModel[] = [];
  public contractCodeDescription = '';
  get isValid() {
    for (const form of this.forms) {
      if (!form.valid) {
        return false;
      }
    }
    return true;
  }
  lastState: any;
  public isLoading = true;
  public forms: FormGroup[] = [];
  public equipments: { [params: string]: Array<EquipmentModel> } = {};
  public manufacturers: Manufacturer[] = [];
  permissions = [];
  public user: any = {};
  public promise: Promise<any>;
  public manufacturerById: { [params: string]: Manufacturer } = {};

  private subscriptions = [];

  constructor(
    modalService: ModalService,
    private equipmentModelService: EquipmentModelService,
    private contractService: ContractService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private manufacturerService: ManufacturerService
  ) {
    super(modalService);
  }

  async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    this.contract.equipmentModelIds = this.equipmentModels.map(e => e.id);
    for (const form of this.forms) {
      const value = form.get('equipmentModelId').value;
      if (!this.contract.equipmentModelIds.includes(value)) {
        this.contract.equipmentModelIds.push(value);
      }
    }
    this.promise = this.contractService.update(this.contract);
    this.promise.then(res => {
      this.alertService.show(new AlertItem('ContractConfiged', AlertType.success));
      return res;
    }).catch(error => {
      this.alertService.show(new AlertItem('ContractConfigError', AlertType.danger));
      throw error;
    }).finally(() => {
      this.promise = null;
      window.location.reload();
    });
    return this.promise;
  }

  ngOnInit() {
    this.contract = this.initialState as Contract;
    this.user = this.storageService.get(StorageKey.currentUser);
    const permissionsContract = this.storageService.get(StorageKey.currentPermissions);
    this.permissions = permissionsContract && permissionsContract.filter(permission => permission.contractId === this.contract.code)
      .map(res => res.actionIds);
    this.contractCodeDescription = (this.contract && this.contract.code + ' - ' + this.contract.name);
    this.equipmentModelService.getAll().then(data => {
      this.equipments = {};
      for (const equip of data) {
        if (this.equipments[equip.manufacturerId] == null) {
          this.equipments[equip.manufacturerId] = [];
        }
        switch(equip.type){
          case 'fixed':
            equip.type = 'Fixo';
            break;
          case 'speedBump':
            equip.type = 'Lombada Eletrônica';
            break;
          case 'portable':
            equip.type = 'Portátil';
            break;
        }
        this.equipments[equip.manufacturerId].push(equip);
      }
      this.manufacturerService.getAll().then(res => {
        this.manufacturers = res;
        this.manufacturers.forEach(manufacturer => {
          this.manufacturerById[manufacturer.id] = manufacturer;
        });
      });
      if (this.contract != null && this.contract.equipmentModelIds != null) {
        const equipmentModelIds = this.contract.equipmentModelIds.sort();
        for (const modelId of equipmentModelIds) {
          this.equipmentModelService.getById(modelId).then(e => this.equipmentModels.push(e));
        }
      }
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  addNew() {
    const formAddNew = this.formBuilder.group({
      manufacturerId: ['', [Validators.required]],
      equipmentModelId: ['', [Validators.required, this.duplicateNameValidator()]],
      equipmentModelType: ['']
    });
    this.forms.push(formAddNew);

    let sub = formAddNew.get('manufacturerId').valueChanges
      .subscribe(_ => {
        formAddNew.patchValue({ equipmentModelId: '' });
      });
    this.subscriptions.push(sub);

    sub = formAddNew.get('equipmentModelId').valueChanges
       .subscribe(value => {
      if (this.equipmentModels.indexOf(value) >= 0) {
        formAddNew.patchValue({ equipmentModelId: '' });
      }
      if (formAddNew.get('equipmentModelId').value !== '') {
        formAddNew.get('equipmentModelType').patchValue(formAddNew.get('equipmentModelId').value);
      }
    });
    this.subscriptions.push(sub);
  }

  duplicateNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.equipmentModels.some(e => e.id === control.value)) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { DuplicateName: { value: control.value } };
      }
      return null;
    };
  }

  removeForm(form) {
    const index = this.forms.indexOf(form);
    if (index >= 0) {
      this.forms.splice(index, 1);
    }
  }

  removeModel(modelId) {
    const index = this.equipmentModels.findIndex(e => e.id === modelId);
    if (index >= 0) {
      this.equipmentModels.splice(index, 1);
    }
  }
}
