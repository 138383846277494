import { BaseApiModel } from './BaseApiModel';

export class ViolationStepReport extends BaseApiModel {
  public contractId: string;
  public regulationId: string;
  public triage: { [date: string]: string };
  public typing: { [date: string]: string };
  public doubleTyping: { [date: string]: string };
  public verifyValid: { [date: string]: string };
  public verifyInvalid: { [date: string]: string };
  public lotAttribution: { [date: string]: string };
  public done: { [date: string]: string };
}
