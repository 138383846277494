/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  private baseUrl = `${environment.apiUrl}/users`;

  constructor(private http: HttpClient ) {
  }

  private handleError(error: any): Observable<any> {
    console.log('Erro na requisição', error);
    return throwError(error);
  }

  setPassword(name: string, password: string, userId: string, token: string, path: string) {
    const url = `${this.baseUrl}/${userId}/${path}`;
    let data = JSON.stringify({password});
    if (name !== '') {
      data = JSON.stringify({password, name});
    }
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
    return this.http.post(url, data, { headers: header })
      .pipe(
      );
  }

  passwordForgot(email: string, passwordUrl: string, verificationUrl: string) {
    const url = `${this.baseUrl}/forgotPassword`;
    const data = JSON.stringify({email, passwordUrl, verificationUrl});
    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(url, data, { headers: header })
    .pipe(
    );


  }
}
