<ng-container *ngIf="loading; else loadingBlock">
  <div class="table-flex__item" *ngFor="let contract of violations; let i = index">
    <div class="table-flex__row bg-gray--250">
      <p class="w-25">
        <a class="btn btn-no-href p-0 d-flex align-items-center" (click)="redefineFilter()">
          <i class="material-icons mr-1">subdirectory_arrow_left</i>
          <span>{{ contractCode }} - {{ contractName }}</span>
        </a>
      </p>
      <p class="w-10">--</p>
      <p class="w-15">{{ showRow(contract, 'inProcess') }}</p>
      <p class="w-15">{{ showRow(contract, 'valid') }}</p>
      <p class="w-15">{{ showRow(contract, 'invalid') }}</p>
      <p class="w-10">{{ contract.total }}</p>
      <p class="w-10"></p>
    </div>

    <div class="table-flex__item">
      <div class="table-flex__row bg-gray--200" *ngIf="!hideSpot">
        <p i18n>Ponto: {{ spotInfo }}</p>
      </div>
      <div class="table-flex__row bg-gray--200 pl-3" *ngIf="!hideLane">
        <p i18n>{{ laneInfo }}</p>
      </div>
    </div>

    <!-- Dates -->
    <div class="table-flex__item" *ngFor="let date of contract.dates; let i = index" [class.collapsed]="date.collapse">
      <div class="table-flex__row bg-gray--150">
        <p class="w-25 w--push-3">
          {{ date.id | moment: 'DD/MM/YYYY' }}
          <span *ngIf="date.id === today" i18n-appTooltip appTooltip="  * Pode haver divergência entre o totalizador e a quantidade de
          infrações exibidas na listagem. Esta divergência ocorre em caso de novas infrações serem recebidas durante o acesso à tela.">
            <i class="material-icons icon-sm">help</i>
          </span>
        </p>
        <p class="w-10">Todos
          <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(date.regulations, 'reasons', date)" *ngIf="date.total > 0">
            <i class="material-icons" *ngIf="date.regulations.collapse">expand_more</i>
            <i class="material-icons" *ngIf="!date.regulations.collapse">expand_less</i>
          </button>
        </p>
        <p class="w-15">
          <ng-container *ngIf="getValueByType(date, 'inProcess') > 0">
            <a class="no-href" (click)="filterBy(date.id, 'inProcess')">
              {{ getValueByType(date, 'inProcess') }}/{{ showPercentage(date, 'inProcess') }}</a>
          </ng-container>
          <ng-container *ngIf="getValueByType(date, 'inProcess') === 0">0</ng-container>
        </p>
        <p class="w-15">
          <ng-container *ngIf="getValueByType(date, 'valid') > 0">
            <a class="no-href" (click)="filterBy(date.id, 'valid')">
              {{ getValueByType(date, 'valid') }}/{{ showPercentage(date, 'valid') }}</a>
          </ng-container>
          <ng-container *ngIf="getValueByType(date, 'valid') === 0">0</ng-container>
        </p>
        <p class="w-15">
          <ng-container *ngIf="getValueByType(date, 'invalid') > 0">
            <a class="no-href" (click)="filterBy(date.id, 'invalid')">
              {{ getValueByType(date, 'invalid') }}/{{ showPercentage(date, 'invalid') }}</a>
          </ng-container>
          <ng-container *ngIf="getValueByType(date, 'invalid') === 0">0</ng-container>

          <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(date.reasons, 'regulations', date)"
            *ngIf="haveInvalid(date.situations)">
            <i class="material-icons" *ngIf="date.reasons.collapse">expand_more</i>
            <i class="material-icons" *ngIf="!date.reasons.collapse">expand_less</i>
          </button>
        </p>
        <p class="w-10"><a class="btn btn-no-href" (click)="filterBy(date.id)">{{ date.total }}</a></p>
        <p class="w-10"></p>
      </div>

      <!-- Date Regulations -->
      <ng-container *ngIf="date.regulations.collapse">
        <div class="table-flex__item" *ngFor="let regulationDate of date.regulations.items; let i = index">
          <div class="table-flex__row">
            <p class="w-25"></p>
            <p class="w-10">
              <span i18n-appTooltip [appTooltip]="getRegulationInfo(regulationDate.id)"
                class="d-inline-flex align-items-center">
                <i class="material-icons text-light mr-2">info</i>
                {{ regulationDate.code }}
              </span>
            </p>
            <p class="w-15">
              <ng-container *ngIf="getValueByType(regulationDate, 'inProcess') > 0">
                <a class="no-href" (click)="filterBy(date.id, 'inProcess', regulationDate.id)">
                  {{ getValueByType(regulationDate, 'inProcess') }}/{{ showPercentage(date, 'inProcess') }}</a>
              </ng-container>
              <ng-container *ngIf="getValueByType(date, 'inProcess') === 0">0</ng-container>
            </p>
            <p class="w-15">
              <ng-container *ngIf="getValueByType(regulationDate, 'valid') > 0">
                <a class="no-href" (click)="filterBy(date.id, 'valid', regulationDate.id)">
                  {{ getValueByType(regulationDate, 'valid') }}/{{ showPercentage(date, 'valid') }}</a>
              </ng-container>
              <ng-container *ngIf="getValueByType(regulationDate, 'valid') === 0">0</ng-container>
            </p>
            <p class="w-15">
              <ng-container *ngIf="getValueByType(regulationDate, 'invalid') > 0">
                <a class="no-href" (click)="filterBy(date.id, 'invalid', regulationDate.id)">
                  {{ getValueByType(regulationDate, 'invalid') }}/{{ showPercentage(date, 'invalid') }}</a>
              </ng-container>
              <ng-container *ngIf="getValueByType(regulationDate, 'invalid') === 0">0</ng-container>

              <button class="btn btn-sm btn-icon-block" (click)="toggleCollapse(regulationDate.reasons)"
                *ngIf="haveInvalid(regulationDate.situations)">
                <i class="material-icons" *ngIf="regulationDate.reasons.collapse">expand_more</i>
                <i class="material-icons" *ngIf="!regulationDate.reasons.collapse">expand_less</i>
              </button>
            </p>
            <p class="w-10"><a class="btn btn-no-href" (click)="filterBy(date.id, '', regulationDate.id)">{{ regulationDate.total }}</a></p>
            <p class="w-10"></p>
          </div>

          <ng-container *ngIf="regulationDate.reasons && regulationDate.reasons.collapse">
            <div class="table-flex__row" *ngFor="let reasonDate of regulationDate.reasons.items; let i = index">
              <p class="w-25"></p>
              <p class="w-10"></p>
              <p class="w-15"></p>
              <p class="w-15"></p>
              <p class="w-15">{{ getReasonInfo(reasonDate.id) }}</p>
              <p class="w-10">{{ reasonDate.total }}</p>
              <div class="w-10 pr-3">
                <div><small>
                  {{ getPercentageReason(reasonDate.total, getValueByType(date, 'invalid')) + '%' }}
                </small></div>
                <div class="progress">
                  <div class="progress-bar bg-info" role="progressbar"
                    [style.width]="getPercentageReason(reasonDate.total, getValueByType(date, 'invalid')) + '%'"
                    [attr.aria-valuenow]="reasonDate.total" aria-valuemin="0" [attr.aria-valuemax]="getValueByType(date, 'invalid')">
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Date Reasons -->
      <ng-container *ngIf="date.reasons && date.reasons.collapse">
        <div class="table-flex__row" *ngFor="let reasonDate of date.reasons.items; let i = index">
          <p class="w-25"></p>
          <p class="w-10"></p>
          <p class="w-15"></p>
          <p class="w-15"></p>
          <p class="w-15">{{ getReasonInfo(reasonDate.id) }}</p>
          <p class="w-10">{{ reasonDate.total }}</p>
          <div class="w-10 pr-3">
            <div><small>
              {{ getPercentageReason(reasonDate.total, getValueByType(date, 'invalid')) + '%' }}
            </small></div>
            <div class="progress">
              <div class="progress-bar bg-info font-weight-bold" role="progressbar"
                [style.width]="getPercentageReason(reasonDate.total, getValueByType(date, 'invalid')) + '%'"
                [attr.aria-valuenow]="reasonDate.total" aria-valuemin="0"
                [attr.aria-valuemax]="date.situations.invalid">
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #loadingBlock>
  <div class="loading-page">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>
