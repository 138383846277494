/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import { BaseApiModel } from './BaseApiModel';
export class SerialNumberConfig extends BaseApiModel {
  public contractId: number;
  public namePattern: string;
  public identifier: string;
  public prefix: string;
  public verifyingDigit: string;
  public range: {
    start: number;
    end: number;
  };
  public sizePattern: number;
  public currentNumber: number;
  public equipmentModelIds: number[];
  public regulationIds: number[];
  public spotIds: string[];
  public laneIds: string[];
  public reasonIds: string[];
  public violationStatus: string[];
  public enabled: boolean;
  public modifiedAt: Date;
  public createdAt: Date;
}

export enum SerialNumberFields {
  identifier = 'identifier',
  prefix = 'prefix',
  verifyingDigit = 'verifyingDigit',
  currentNumber = 'currentNumber'
}
