import { Component, OnInit, Input } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService, StorageService, StorageKey, UserService } from 'src/app/core/services';
import { User, Violation } from 'src/app/core/models';
import moment from 'moment';

@Component({
  selector: 'app-history-panel',
  templateUrl: './history-panel.component.html',
  styleUrls: ['./history-panel.component.sass']
})
export class HistoryPanelComponent extends BaseModal implements OnInit {
  @Input() violation: Violation;
  public user: any = {};
  public userById: { [params: string]: User } = {};

  get isValid() {
    return false;
  }

  constructor(
    modalService: ModalService,
    private storageService: StorageService,
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.violation = this.initialState as Violation || new Violation();
  }

  onAllSubmited(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  get lastState(): any {
    return null;
  }
}
