<div class="page-title d-flex align-items-start">
  <a [routerLink]="routeLocale" class="btn btn-icon btn-fade-secondary mr-3">
    <i class="material-icons align-button-back">arrow_back</i>
    <span i18n>Voltar</span>
  </a>

  <div>
    <p class="page-title__link-back"><a routerLink="../" i18n>Todos os contratos</a></p>
    <h2 *ngIf="loading; else loadingBlock">{{ contract.id }} - {{ contract.name }}</h2>
    <ng-template #loadingBlock>
      <div class="loading-spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </ng-template>
  </div>
</div>

<app-production-report-filter></app-production-report-filter>

<router-outlet></router-outlet>
