<div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title container" i18n>Selecione um Nome</h5>
        <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="modal-body" #container>
        <form [formGroup]="form">
          <app-input formControlName="field"></app-input>
        </form>
      </div>
      <div class="modal-footer">
        <button (click)="onCancel()" class="btn btn-outline-secondary">Cancelar</button>
        <button (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">Confirmar</button>
      </div>
    </div>
  </div>
