import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PreProcessSettings, Steps, ComponentModal } from 'src/app/core/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as mermaid from 'mermaid';
import { TriageConfigModalComponent } from 'src/app/modals/triage-config-modal/triage-config-modal.component';
import { ModalService } from 'src/app/core/services';

@Component({
  selector: 'app-pre-process-config-violation-steps',
  templateUrl: './config-violation-steps.component.html',
  styleUrls: ['./config-violation-steps.component.sass']
})
export class ConfigViolationStepsComponent implements OnInit {
  @Input() preProcessSettings: PreProcessSettings = new PreProcessSettings();
  @Output() nextStepCommand = new EventEmitter();
  @Output() isValid = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  public forms: Array<FormGroup> = [];
  public violationStepsI18n = [];
  public violationSteps = Object.values(Steps);
  public options = ['accepted', 'refused', 'firstStep'];
  public optionsI18n = [];
  public contractId: string;
  public regulationIdsToBeFiltered = [];
  public i18nResources: {[key: string]: {id: string; value: string}} = {};
  public graphValue = {};
  public highlightGraph = [];

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService
  ) { }


  get _isValid() {
    let isValid = true;
    if (this.forms && this.forms.length > 0) {
      isValid = this.forms.every(form => {
        if (form == null) {
          return false;
        }
        const step = form.get('step');
        if (step != null && step.value === 'triage' && (this.regulationIdsToBeFiltered == null ||
            this.regulationIdsToBeFiltered.length === 0)) {
          return false;
        }
        return form.valid;
      });
    }
    return isValid;
  }

  ngOnInit() {
    this.contractId = this.activatedRoute.snapshot.params.contractId;
    this.regulationIdsToBeFiltered = this.getValue(this.preProcessSettings, 'violationSteps.triage.regulationIds') || [];
  }

  setViolationStepsI18n(violationStepsI18n) {
    violationStepsI18n.forEach(violationStep => this.i18nResources[violationStep.id] = violationStep);
    this.violationStepsI18n = violationStepsI18n;
    if (this.optionsI18n.length > 0) {
      this.initialFlow();
    }
  }

  setOptionsI18n(options) {
    options.forEach(option => this.i18nResources[option.id] = option);
    this.optionsI18n = options;
    if (this.violationStepsI18n.length > 0) {
      this.initialFlow();
    }
  }

  createForm(param = {step: '', accepted: '', refused: ''}) {
    const form = this.formBuilder.group({
      step: [param.step, [this.noRepeatStepValidator, Validators.required]],
      accepted: [param.accepted, Validators.required],
      refused: [param.refused]
    });
    form.get('step').valueChanges.subscribe(value => {
      if (value === 'validate') {
        form.get('refused').clearValidators();
      } else {
        form.patchValue({ refused: '' });
        form.get('refused').setValidators([Validators.required]);
        form.get('refused').setErrors(Validators.required);
      }
    });
    form.valueChanges.subscribe(() => {
      this.isValid.emit(this._isValid);
    });
    return form;
  }

  updateGraph(index, hasToUpdateView = true) {
    this.setFormSteps();
    this.updateView();
    this.outputValue.emit(this.getOutputValues());
  }

  setFormSteps() {
    const stepsUsed = [];
    const stepsPassed = [];
    let accepted = this.forms[0].get('accepted').value;
    let refused = this.forms[0].get('refused').value;
    if (accepted != null && accepted !== '' && accepted !== 'lotAttribution' && !stepsUsed.includes(accepted)) {
      stepsUsed.push(accepted);
    }
    if (refused != null && refused !== '' && refused !== 'lotAttribution' && !stepsUsed.includes(refused)) {
      stepsUsed.push(refused);
    }
    while (stepsUsed.length > stepsPassed.length) {
      const step = stepsUsed[stepsPassed.length];
      stepsPassed.push(step);
      const form = this.forms.find(f => f.get('step').value === step);
      if (form == null) {
        continue;
      }
      accepted = form.get('accepted').value;
      refused = form.get('refused').value;
      if (accepted != null && accepted !== '' && accepted !== 'lotAttribution' && !stepsUsed.includes(accepted)) {
        stepsUsed.push(accepted);
      }
      if (refused != null && refused !== '' && refused !== 'lotAttribution' && !stepsUsed.includes(refused)) {
        stepsUsed.push(refused);
      }
    }
    const extraSteps = [];
    for (let i = 0; i < this.forms.length; i++) {
      if (i !== 0 && !stepsUsed.includes(this.forms[i].get('step').value)) {
        extraSteps.push(i);
      }
    }
    for (let i = extraSteps.length - 1; i >= 0; i--) {
      this.removeStep(extraSteps[i]);
    }

    const stepsMissing = stepsUsed.filter(s => !this.forms.some(f => f.get('step').value === s));
    for (const step of stepsMissing) {
      this.addNewStep(step);
    }
  }

  addNewStep(step) {
    const form = this.createForm();
    if (step != null) {
      form.get('step').setValue(step);
    }
    this.forms.push(form);
    this.isValid.emit(this._isValid);
  }

  noRepeatStepValidator = (stepControl) => {
    const step = stepControl.value;
    if (step === '' || step === null) {
      return null;
    }

    for (const form of this.forms) {
      const control = form.get('step');
      if (control !== stepControl && form.get('step').value === step) {
        return { duplicatedStep: 'This step has already been selected' };
      }
    }
    return null;
  };

  updateView() {
    this.isValid.emit(this._isValid);
    if (this.regulationIdsToBeFiltered == null || this.regulationIdsToBeFiltered.length < 1) {
      this.highlightGraph = [this.i18nResources.triage && this.i18nResources.triage.value];
    } else {
      this.highlightGraph = [];
    }
    const graph = this.getOutputValues().violationSteps;
    graph.start = graph.firstStep;
    delete graph.firstStep;
    graph.done = 'lotAttribution';
    delete graph.lotAttribution;

    // translate the graph to be shown properly
    const graphI18n = {};
    const acceptedI18n = (this.i18nResources.accepted && this.i18nResources.accepted.value) || 'accepted';
    const refusedI18n = (this.i18nResources.refused && this.i18nResources.refused.value) || 'refused';
    for (const state in graph) {
      if (state === 'start' || state === 'done') {
        const value = graph[state];
        if (value != null && value !== '') {
          graphI18n[state] = (this.i18nResources[value] && this.i18nResources[value].value) || value;
        }
      } else if (state != null && graph[state] != null) {
        const stateI18n = (this.i18nResources[state] && this.i18nResources[state].value) || state;
        const node = graph[state];
        let nodeI18n = {};
        if (node.accepted != null && node.accepted !== '') {
          nodeI18n[acceptedI18n] = (this.i18nResources[node.accepted] && this.i18nResources[node.accepted].value) || node.accepted;
        }
        if (node.refused != null && node.refused !== '') {
          nodeI18n[refusedI18n] = (this.i18nResources[node.refused] && this.i18nResources[node.refused].value) || node.refused;
        }
        if (node.nextStep != null && node.nextStep !== '') {
          nodeI18n = (this.i18nResources[node.nextStep] && this.i18nResources[node.nextStep].value) || node.nextStep;
        }
        graphI18n[stateI18n] = nodeI18n;
      }
    }
    this.graphValue = graphI18n;
  }

  async openTriageConfigModal() {
    const steps = [];
    for (const form of this.forms) {
      const control = form.get('step');
      steps.push(form.get('step').value);
    }
    if (steps.includes('triage')) {
      await this.modalService.show(new ComponentModal(TriageConfigModalComponent,
        {regulationIds: this.regulationIdsToBeFiltered, contractId: this.contractId}))
        .catch(() => {})
        .then(data => {
          if (data == null) {
            return;
          }
          const modal = data as any;
          this.regulationIdsToBeFiltered = modal && modal.component && modal.component.instance && modal.component.instance &&
            modal.component.instance.selectedRegulationIds;
          this.updateView();
          if (this._isValid) {
            this.outputValue.emit(this.getOutputValues());
          }
        });
    }
  }

  removeStep(index) {
    this.forms.splice(index, 1);
    this.updateView();
    if (this._isValid) {
      this.outputValue.emit(this.getOutputValues());
    }
  }

  getValue(model, attr, value = '') {
    let arr = [];
    if (typeof attr === 'string') {
      arr = attr.split('.');
    } else {
      arr = attr;
    }
    if (arr.length > 0) {
      if (model[arr[0]] == null) {
        return value;
      } else {
        return this.getValue(model[arr[0]], arr.slice(1), value);
      }
    } else {
      return model;
    }
  }

  async initialFlow() {
    if (this == null) {
      return;
    }
    let hasFlow = false;
    const violationSteps = this.preProcessSettings && this.preProcessSettings.violationSteps;
    if (violationSteps != null) {
      for (const step of Object.keys(violationSteps)) {
        if (violationSteps[step].accepted != null || violationSteps[step].nextStep != null ||
          violationSteps.firstStep === 'lotAttribution') {

          hasFlow = true;
          break;
        }
      }
    }
    if (hasFlow === false) {
      this.forms.push(
        this.createForm({
          step: 'firstStep',
          accepted: 'triage',
          refused: ''
        })
      );
      this.forms.push(
        this.createForm({
          step: 'triage',
          accepted: 'typing',
          refused: 'lotAttribution'
        })
      );
      this.forms.push(
        this.createForm({
          step: 'typing',
          accepted: 'verifyValid',
          refused: 'verifyInvalid'
        })
      );
      this.forms.push(
        this.createForm({
          step: 'verifyValid',
          accepted: 'lotAttribution',
          refused: 'validate'
        })
      );
      this.forms.push(
        this.createForm({
          step: 'verifyInvalid',
          accepted: 'lotAttribution',
          refused: 'validate'
        })
      );
      this.forms.push(
        this.createForm({
          step: 'validate',
          accepted: 'lotAttribution',
          refused: ''
        })
      );
    } else {
      this.forms.push(
        this.createForm({
          step: 'firstStep',
          accepted: this.preProcessSettings.violationSteps.firstStep,
          refused: ''
        })
      );
      Object.keys(this.preProcessSettings.violationSteps).forEach(step => {
        const accepted = this.preProcessSettings.violationSteps[step].accepted
          || this.preProcessSettings.violationSteps[step].nextStep || '';
        const refused = this.preProcessSettings.violationSteps[step].refused || '';
        if (accepted === '' && refused === '') {
          return;
        }
        this.forms.push(
          this.createForm({
            step,
            accepted,
            refused
          })
        );
      });
    }
    if (violationSteps && violationSteps.firstStep === 'lotAttribution') {
      this.updateView();
    } else {
      Object.keys(this.forms).forEach(i => {
        this.updateGraph(i, false);
      });
      this.updateView();
    }
  }


  getOutputValues() {
    this.isValid.emit(this._isValid);
    const violationSteps: any = {};
    if (this.preProcessSettings && this.preProcessSettings.violationSteps &&
      this.preProcessSettings.violationSteps.lotAttribution) {
        violationSteps.lotAttribution = this.preProcessSettings.violationSteps.lotAttribution;
    }
    if (this.forms.length === 0) {
      violationSteps.firstStep = 'lotAttribution';
    } else {
      this.forms.forEach((form, index) => {
        const step = form.get('step').value;
        if (step === 'lotAttribution') {
          return;
        }
        const accepted = form.get('accepted').value;
        if (index === 0) {
          violationSteps.firstStep = accepted;
          return;
        }
        const refused = form.get('refused').value;
        violationSteps[step] = {};
        if (step === 'triage') {
          violationSteps[step].regulationIds = this.regulationIdsToBeFiltered;
        }
        if (step === 'validate') {
          violationSteps[step].nextStep = accepted;
        } else {
          violationSteps[step].accepted = accepted;
          violationSteps[step].refused = refused;
        }
      });
    }

    Object.values(Steps).forEach(step => {
      if (!violationSteps[step]) {
        violationSteps[step] = undefined;
      }
    });

    return {
      violationSteps
    };
  }

}
