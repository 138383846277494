import { Injectable, EventEmitter } from '@angular/core';
import { Contract } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ContractGlobalService {
  public contractEvent = new EventEmitter<Array<Contract>>();
  private _contracts = null;

  public set contracts(contracts) {
    this.contractEvent.next(contracts);
    this._contracts = contracts;
  }
  public get contracts() {
    return this._contracts;
  }
  constructor() { }
}
