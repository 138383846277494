<app-enum-i18n [input]="quarantineStatus" (output)="quarantineStatusI18n=$event"></app-enum-i18n>

<table class="table table-borderless table-block">
  <thead>
    <tr>
      <th class="text-center" *ngFor="let column of columns">
        {{getStatusQuarantine(column)}}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="text-center" *ngFor="let column of columns">
        <button class="btn btn-link" *ngIf="quarantine?.id" (click)="navigateQuarantineDetail(column)">
          {{ quarantine.report && quarantine.report[column] || 0 }}
        </button>
      </td>
    </tr>
  </tbody>
</table>
