/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import { BaseApiModel } from './BaseApiModel';

export class PreProcessSettings extends BaseApiModel {
  contractId: number;
  percentageOddSpeed: number;
  lotGeneration: {
    stopAttribution: boolean;
    namePattern: string;
    pathPattern: string;
    autoGeneration: Array<{
      lotControlId: string;
      generationLimit: number;
      namePattern: string;
      pathPattern: string;
      period: number;
      cron: string;
      maxAmountViolation: number;
      regulationIds: Array<string>;
      laneIds: Array<string>;
      reasonIds: Array<string>;
      equipmentModelIds: Array<string>;
      violationStatus: ViolationStatus[];
      isNotProcessable: boolean;
      isSpecial: boolean;
      oddSpeed: boolean;
    }>;
  };
  verifyNoMetrologicalCalibrationValidity: boolean;
  whitelistReasonId: number;
  requiredFields: string[];
  vehicleDataSearch: VehicleDataSearchTypes;
  quarantine: {
    maxPeriod: number;
    reasonId: number;
    refused: string;
    validateOddSpeed: boolean;
  };
  imageEdit: {
    hideImageEdit: boolean;
    blur: boolean;
    clip: boolean;
    clipPosition: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
  };
  violationLimit: Array<{
    regulationId: string;
    reasonId: string;
    classificationIds: number[];
    periods: Array<{
      time: number;
      startTime: string;
      endTime: string;
    }>;
  }>;
  violationSteps: {
    firstStep: string;
    triage: {
      regulationIds: Array<string>;
      accepted: string;
      refused: string;
    };
    typing: {
      ocrSkip: boolean;
      accepted: string;
      refused: string;
    };
    doubleTyping: {
      accepted: string;
      refused: string;
    };
    verifyValid: {
      accepted: string;
      refused: string;
      changesPermited: boolean;
    };
    verifyInvalid: {
      accepted: string;
      refused: string;
      changesPermited: boolean;
    };
    validate: {
      nextStep: string;
    };
    lotAttribution: {
      lotStep: string;
    };
  };
  violationStepOrder: string[];
  lotSteps: {
    internalAudit: {
      accepted: string;
      refused: string;
    };
    revision: {
      nextStep: string;
    };
    externalAudit: {
      nextStep: string;
    };
    waitReturn: {
      nextStep: string;
    };
    export: {
      nextStep: string;
    };
  };
  audit: {
    allowAuditInvalidateLot: boolean;
    enabledViolationDiscord: boolean;
    acceptableQualityLevel: number;
    requiredFieldsAudit: string[];
    fieldsToLotRefused: Array<{
      id: number;
      violationStatus: string;
      reasonIds: Array<number>;
      vehicleFields: Array<string>;
    }>;
  };
  lotStepOrder: string[];
  violationAgeAlert: number;
  lotAgeAlert: number;
  changeBrandModelForClientBrand: boolean;
  exportOriginalFiles: boolean;
  createdAt: Date;
  modifiedAt: Date;
  enabledRestrictionZone: boolean;
  enabledAlertOddSpeed: boolean;
  allowEditingVehicleData: boolean;
  allowInputNotFoundVehicle: boolean;
}

export enum VehicleDataSearchTypes {
  disabled = 'disabled',
  sppInternal = 'SPP-Base Interna',
  sppCompom = 'SPP-COPOM',
  sppAntt = 'SPP-ANTT',
  sppMjsp = 'SPP-MJSP',
  sppSerpro = 'SPP-SERPRO'
}

export enum ViolationStatus {
  valid = 'valid',
  invalid = 'invalid'
}

export enum VehicleRequiredFields {
  colorCode = 'colorCode',
  typeCode = 'typeCode',
  plate = 'plate',
  classificationCode = 'classificationCode',
  year = 'year',
  brandModelCode = 'brandModelCode',
  categoryCode = 'categoryCode',
}

export enum Steps {
  triage = 'triage',
  typing = 'typing',
  doubleTyping = 'doubleTyping',
  verifyValid = 'verifyValid',
  verifyInvalid = 'verifyInvalid',
  validate = 'validate',
  lotAttribution = 'lotAttribution'
}

export enum LotSteps {
  export = 'export',
  internalAudit = 'internalAudit',
  revision = 'revision',
  externalAudit = 'externalAudit',
  waitReturn = 'waitReturn',
  serialNumberGeneration = 'serialNumberGeneration',
  end = 'end'
}
