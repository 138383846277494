/* eslint-disable no-use-before-define */
import { Component, ElementRef, HostListener, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AlertService } from 'src/app/core/services';
import * as filesize from 'filesize';
import { AlertItem, AlertType } from 'src/app/core/models';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ],
  styleUrls: ['./file-upload.component.sass']
})
export class FileUploadComponent implements ControlValueAccessor {
  @ViewChild('output', { static: true, read: ElementRef }) output: ElementRef;

  @Output() outputFiles: EventEmitter<Array<any>> = new EventEmitter();
  @Input() accept = '';
  @Input() text = '';
  @Input() limit: any;

  // eslint-disable-next-line @typescript-eslint/ban-types
  public onChange: (_: any) => {};
  public _files;

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private alertService: AlertService
  ) { }

  @Input() set files(files) {
    this._files = files;
  }
  get files() {
    return this._files;
  }

  @HostListener('change', ['$event.target.files']) emitFiles(event: any) {
    if (event) {
      const promises = [];
      for (const row of event) {
        const data = {
          detail: row,
          fileSize: filesize(row.size),
          data: window.URL.createObjectURL(row),
          isNew: true
        };
        promises.push(data);
      }
      Promise.all(promises).then(result => {
        this.files = this.files.concat(result);
        if (this.files.length > this.limit) {
          this.alertService.show(new AlertItem('FileLimit', AlertType.danger));
          this.files.splice(this.limit, this.files.length);
        }
        this.onChange(this.files);
        setTimeout(() => this.outputFiles.emit(this.files), 1);
      });
    }
  }


  writeValue(value: null) {
    this.host.nativeElement.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
    this.outputFiles.emit(this.files);
  }

  openFile(index) {
    const src = this.files[index].data;
    const win = window.open(src, '_blank');
    win.focus();
  }
}
