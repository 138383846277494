import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { User, UserGroup } from 'src/app/core/models';
import { AlertItem, AlertType } from 'src/app/core/models/AlertItem';
import { AlertService, ModalService, UserGroupService, UserService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-user-select-modal',
  templateUrl: './user-select-modal.component.html',
  styleUrls: ['./user-select-modal.component.sass']
})
export class UserSelectModalComponent extends BaseModal implements OnInit {
  public userGroups: Array<UserGroup>;
  public userGroup: UserGroup;
  public isValid = false;
  public haveRole: boolean;
  public date: any;
  public hours: any;
  public initialState: any;
  modelChanged: Subject<string> = new Subject<string>();
  searchForm: FormGroup;
  checkedUsersForm: any;
  public params;
  public users = [];
  public groupId = null;
  public disableControl = true;
  public promise: Promise<any>;

  constructor(
    public userService: UserService,
    private formBuilder: FormBuilder,
    modalService: ModalService,
    public userGroupService: UserGroupService,
    private alertService: AlertService
  ) {
    super(modalService);
    this.modelChanged.pipe(debounceTime(500)).subscribe(() => {
      this.handleSearch();
    });
  }

  createSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      search: ['']
    });
  }

  handleSearch() {
    this.users = [];
    this.params = this.getFilterParams();
  }

  getFilterParams() {
    const search = this.searchForm.get('search').value;
    const filterObj: any  = {};
    if (search != null && search !== '') {
      filterObj['email[contains,or]'] = `${search}`;
      filterObj['name[contains,or]'] = `${search}`;
    }
    filterObj.contractsCompany = `[${this.userGroup?.contractId}]`;
    return filterObj;
  }

  ngOnInit() {
    this.createSearchForm();
    this.params = this.userGroupService.getById(this.initialState, true).then(res => {
      this.userGroup = res;
      return this.getFilterParams();
    });
    this.checkedUsers();
  }

  checkedUsers() {
    if (this.checkedUsersForm == null) {
      this.checkedUsersForm = new FormGroup({});
    }
    if (this.users != null) {
      this.users.forEach((user, i) => {
        if (this.checkedUsersForm[user.id] != null) {
          return;
        }
        const isUserCheked = user.groupIds.indexOf(this.initialState) >= 0;
        this.users[i].checked = isUserCheked;
        this.checkedUsersForm.addControl(
          user.id,
          new FormControl({ value: isUserCheked, disabled: isUserCheked })
        );
      });
      this.checkedUsersForm.valueChanges.subscribe(res => {
        this.isValid = this.checkedUsersForm.valid;
      });
    }
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  setUsers(users: Array<User>) {
    users.forEach(user => {
      if (!this.users.includes(user)) {
        this.users = this.users.sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1);
        this.users.push(user);
      }
    });
    this.checkedUsers();
  }

  get lastState() {
    return {};
  }

  public async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    this.users.forEach(user => {
      if (this.checkedUsersForm.controls[user.id].value) {
        if (!this.userGroup.userIds.includes(user.id)) {
          this.userGroup.userIds.push(user.id);
        }
      }
    });
    delete this.userGroup.users;
    this.promise = this.userGroupService
      .update(this.userGroup)
      .then(res => {
        this.alertService.show(new AlertItem('UserGroupSaved', AlertType.success));
        return res;
      })
      .catch(error => {
        this.alertService.show(new AlertItem('UserGroupSaveError', AlertType.danger));
        throw error;
      }).finally(() => {
        this.promise = null;
      });

    return this.promise;
  }
}
