import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterContentChecked } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-pagination-simple',
  templateUrl: './pagination-simple.component.html',
  styleUrls: ['./pagination-simple.component.sass']
})
export class PaginationSimpleComponent implements OnInit, AfterContentChecked {
  @Input() data: any;
  @Input() deleteEvent: EventEmitter<any>;
  @Output() items: EventEmitter<Array<any>> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();

  public selectedPage = 1;
  public pages = [];
  public allItems = [];
  public noRegister = false;
  public isLoading = false;

  _perPage = 5;
  get perPage() {
    return this._perPage;
  }
  @Input() set perPage(value) {
    this.selectedPage = Math.floor((this.selectedPage - 1) * this._perPage / value) + 1;
    this._perPage = value;
    this.setItems();
    this.setPagesArray();
  }

  private startPage = 1;
  private endPage = 1;


  constructor() { }

  ngOnInit() {
    this.setPagesArray();
    if (this.deleteEvent != null) {
      this.deleteEvent.subscribe(this.getDeleteEvent.bind(this));
    }
    if (this.data !== null) {
      this.allItems = this.data;
      this.setItems();
    }
  }

  ngAfterContentChecked() {
    this.setPagesArray();
    if (this.deleteEvent != null) {
      this.deleteEvent.subscribe(this.getDeleteEvent.bind(this));
    }
    if (this.data !== null) {
      this.allItems = this.data;
      this.setItems();
    }
  }

  get hasNext() {
    return this.selectedPage < this.endPage;
  }
  get hasPrevious() {
    return this.selectedPage > 1;
  }

  getDeleteEvent(event) {
    const index = this.allItems.findIndex(i => i.id === event);
    this.allItems.splice(index, 1);
    this.setItems();
  }

  setPagesArray() {
    this.loading.emit(true);
    if (this.allItems.length === 0) {
      this.isLoading = true;
      this.noRegister = true;
    } else {
      this.noRegister = false;
    }
    let maxPage = Number.MAX_SAFE_INTEGER;
    maxPage = Math.ceil(this.allItems.length / this.perPage);
    this.startPage = Math.max(this.selectedPage - 3, 1);
    this.endPage = Math.min(Math.max(this.selectedPage + 3, this.startPage + 5), maxPage);
    this.startPage = Math.min(this.startPage, Math.max(this.endPage - 5, 1));
    const newArray = [];
    for (let i = this.startPage; i <= this.endPage; i++) {
      newArray.push(i);
    }
    this.pages = newArray;
    this.isLoading = false;
    this.loading.emit(false);
  }

  setPage(n) {
    this.selectedPage = n;
    this.setItems();
    this.setPagesArray();
  }

  async setItems() {
    let start = this.perPage * (this.selectedPage - 1);
    let end = this.perPage * this.selectedPage;
    if (this.allItems.length < end) {
      if (this.allItems.length < start) {
        this.selectedPage = Math.ceil(this.allItems.length / this.perPage);
        start = this.perPage * (this.selectedPage - 1);
        end = this.perPage * this.selectedPage;
      }
    }
    this.items.emit(this.allItems.slice(start, end));
  }

  next() {
    if (this.selectedPage < this.endPage) {
      this.setPage(this.selectedPage + 1);
    }
  }

  previous() {
    if (this.selectedPage > 1) {
      this.setPage(this.selectedPage - 1);
    }
  }

}
