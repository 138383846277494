<div class="modal-dialog modal-dialog-centered modal-lg">
  <app-enum-i18n [input]="actions" (output)="actionsI18n=$event"></app-enum-i18n>
  <app-enum-i18n [input]="models" (output)="modelsI18n=$event"></app-enum-i18n>
  <div class="modal-content">
    <div class="modal-header">
      <span class="modal-title" *ngIf="activity && activity.actionId">
        <span>{{ user.name || activity.userId }}</span>
        <span class="ml-1" i18n>realizou</span>
        <span class="ml-1">{{ getActionOrModel(activity.actionId, 1) }}</span>
        <span class="ml-1">{{ getActionOrModel(activity.actionId, 2) }}</span>
        <span class="ml-1" *ngIf="activity.contractId" i18n>no contrato</span>
        <span class="ml-1" *ngIf="activity.contractId" i18n>{{ activity.contractId }}</span>
      </span>
      <button type="button" (click)="onCancel()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mb-4">
        <div class="col">
          <label class="text-secondary" i18n>Descrição:</label>
          <p>
            <label class="text-secondary" i18n>Id:</label>
            <span class="ml-5">{{ (activity && activity.resourceId) || ''}}</span>
          </p>

          <div *ngFor="let field of fields; index">
            <label class="text-secondary" i18n>Campo:</label>
            <span class="ml-4">{{ field }}</span>
            <div *ngIf="activity.content[field] && activity.content[field].new; else content">
              <label i18n>Novo:</label>
              <span class="ml-5">{{ activity && activity.content[field].new | json}}</span>
            </div>
            <div *ngIf="activity.content[field] && activity.content[field].old">
              <label i18n>Original:</label>
              <span class="ml-4">{{ activity && activity.content[field].old | json}}</span>
            </div>
            <ng-template #content>
              <div *ngIf="activity.content.items; else item">
                <div *ngFor="let item of activity.content.items">
                  <p class="ml-4">{{ item | json }}</p>
                </div>
              </div>
            </ng-template>
            <ng-template #item>
              <span class="ml-4">{{ activity && activity.content[field] | json }}</span>
            </ng-template>
          </div>
          <div class="mt-5">
            <label class="text-secondary" i18n>Data:</label>
            <span class="ml-5">{{ activity && activity.date | moment: 'L LTS' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
