/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-drag-resize',
  templateUrl: './drag-resize.component.html',
  styleUrls: ['./drag-resize.component.sass']
})
export class DragResizeComponent implements OnInit {
  @Input() operation = '';
  @Input() isSelected = false;
  @ViewChild('item', { static: true }) item: ElementRef;
  @Output() coordinatesChange = new EventEmitter();
  @Output() dragEnd = new EventEmitter();
  @Output() dragStart = new EventEmitter();

  public resizing: string = null;
  public element: HTMLElement;
  public elementResizer: any;
  public currentResizer;
  public _limits: { minX: number; minY: number; maxX: number; maxY: number } =
  { minX: Number.NEGATIVE_INFINITY, minY: Number.NEGATIVE_INFINITY, maxX: Number.POSITIVE_INFINITY, maxY: Number.POSITIVE_INFINITY };

  private offsetX = 0;
  private offsetY = 0;
  private initialOffsetX = 0;
  private initialOffsetY = 0;
  private initialWidth = 0;
  private initialHeight = 0;
  private initialCursorX = 0;
  private initialCursorY = 0;
  private width = 50;
  private height = 50;
  private isMouseDown = false;
  private oldValues: any = {};
  private _coordinate: {x: number; y: number; width: number; height: number};

  @Input() set limits({ minX = 0, minY = 0, maxX = 0, maxY = 0 }) {
    this._limits = { minX, minY, maxX, maxY };
  }

  get limits() {
    return this._limits;
  }

  get coordinates() {
    return this._coordinate;
  }

  @Input() set coordinates({x, y, width, height}) {
    const coordinates = {x, y, width, height};
    this.offsetY = y;
    this.offsetX = x;
    this.width = width;
    this.height = height;
    this.updateStyle();
    this.oldValues.x = this.offsetX;
    this.oldValues.y = this.offsetY;
    this.oldValues.width = this.width;
    this.oldValues.height = this.height;
    this._coordinate = {x: this.offsetX, y: this.offsetY, width: this.width, height: this.height};
    if (isEqual(this._coordinate, coordinates)) {
      this.coordinatesChange.emit(this._coordinate);
    }
  }

  constructor() { }

  ngOnInit() {
    this.element = (this.item.nativeElement as HTMLElement);
    this.elementResizer = (this.element.querySelectorAll('.resizer'));
    this.element.addEventListener('mousedown', this.mousedown.bind(this));
    this.element.style.left = this.offsetX + 'px';
    this.element.style.top = this.offsetY + 'px';
    for (const resizer of this.elementResizer) {
      resizer.addEventListener('mousedown', this.mousedown.bind(this));
    }
    this.updateStyle();
    this.oldValues.x = this.offsetX;
    this.oldValues.y = this.offsetY;
    this.oldValues.width = this.width;
    this.oldValues.height = this.height;
  }

  mousedown(event) {
    event.preventDefault();
    this.currentResizer = event.target;
    this.initialOffsetX = this.offsetX;
    this.initialOffsetY = this.offsetY;
    this.initialCursorX = event.clientX;
    this.initialCursorY = event.clientY;
    this.initialWidth = this.width;
    this.initialHeight = this.height;
    this.isMouseDown = true;
    this.resizing = null;

    this.dragStart.emit(this._coordinate);

    const expr = this.currentResizer.classList[1];
    switch (expr) {
      case 'handle-left':
        this.resizing = 'left';
        break;
      case 'handle-right':
        this.resizing = 'right';
        break;
      case 'handle-top':
        this.resizing = 'top';
        break;
      case 'handle-bottom':
        this.resizing = 'bottom';
        break;
      case 'handle-top-left':
        this.resizing = 'top-left';
        break;
      case 'handle-top-right':
        this.resizing = 'top-right';
        break;
      case 'handle-bottom-right':
        this.resizing = 'bottom-right';
        break;
      case 'handle-bottom-left':
        this.resizing = 'bottom-left';
        break;
      default:
        this.resizing = null;
    }
  }

  @HostListener('window:mousemove', ['$event'])
  mousemove(e) {
    if (!this.isMouseDown) {
      return;
    }
    let x = this.offsetX;
    let y = this.offsetY;
    let width = this.width;
    let height = this.height;
    if (this.resizing === null) {
      x = this.initialOffsetX + e.clientX - this.initialCursorX;
      y = this.initialOffsetY + e.clientY - this.initialCursorY;
    } else {
      switch (this.resizing) {
        case 'left':
          x = this.initialOffsetX + e.clientX - this.initialCursorX;
          width = this.initialWidth - e.clientX + this.initialCursorX;
          if (x <= this.limits.minX) {
            width = Math.min(width, this.width);
          }
          break;
        case 'right':
          width = this.initialWidth + e.clientX - this.initialCursorX;
          if (width + x >= this.limits.maxX) {
            width = Math.min(width, this.limits.maxX);
          }
          break;
        case 'top':
          y = this.initialOffsetY + e.clientY - this.initialCursorY;
          height = this.initialHeight - e.clientY + this.initialCursorY;
          if (y <= this.offsetY && y <= this.limits.minY) {
            y = Math.min(this.limits.minY, y);
            height = Math.min(height, this.height);
          }
          if (height <= 50) {
            y = Math.min(this.offsetY, y);
            height = Math.max(height, this.height);
          }
          break;
        case 'bottom':
          height = this.initialHeight + (e.clientY - this.initialCursorY);
          break;
        case 'top-left':
          y = this.initialOffsetY + e.clientY - this.initialCursorY;
          height = this.initialHeight - e.clientY + this.initialCursorY;
          x = this.initialOffsetX + e.clientX - this.initialCursorX;
          width = this.initialWidth - e.clientX + this.initialCursorX;
          if (x > this.oldValues.x + width - 50) {
            x = this.oldValues.x;
          }
          if (y > this.oldValues.y + height - 50) {
            y = this.oldValues.y;
          }
          if (x <= this.limits.minX) {
            width = this.oldValues.width;
          }
          if (y <= this.limits.minY) {
            height = this.oldValues.height;
          }
          break;
        case 'top-right':
          y = this.initialOffsetY + e.clientY - this.initialCursorY;
          height = this.initialHeight - e.clientY + this.initialCursorY;
          width = this.initialWidth + e.clientX - this.initialCursorX;
          if (y + this.oldValues.height > this.limits.maxY) {
            y = this.oldValues.y;
            height = 50;
          }
          break;
        case 'bottom-left':
          height = this.initialHeight + (e.clientY - this.initialCursorY);
          x = this.initialOffsetX + e.clientX - this.initialCursorX;
          width = this.initialWidth - e.clientX + this.initialCursorX;
          if (x > this.oldValues.x + width - 50) {
            x = this.oldValues.x;
          }
          if (x <= this.limits.minX) {
            width = this.oldValues.width;
          }
          break;
        case 'bottom-right':
          height = this.initialHeight + (e.clientY - this.initialCursorY);
          width = this.initialWidth + e.clientX - this.initialCursorX;
          break;
        default:
          this.resizing = null;
      }
    }
    this.coordinates = { width, height, x, y };
    this.coordinatesChange.emit(this._coordinate);
  }

  updateStyle() {
    if (this.element == null) {
      return;
    }
    let x = this.offsetX || 0;
    let y = this.offsetY || 0;
    let height = this.height || 0;
    let width = this.width || 0;

    // limits of position
    if (x > this.oldValues.x) {
      x = Math.min(x, this.limits.maxX - width);
    } else {
      x = Math.max(x, this.limits.minX);
    }
    if (y > this.oldValues.y) {
      y = Math.min(y, this.limits.maxY - height);
    } else {
      y = Math.max(y, this.limits.minY);
    }

    // limits of size
    if (width > this.oldValues.width) {
      if (x === this.limits.minX) {
        width = Math.min(width, this.limits.maxX);
      } else {
        width = Math.min(width + x, this.limits.maxX) - x;
      }
    } else {
      width = Math.max(width, 50);
    }
    if (height > this.oldValues.height) {
      height = Math.min(height + y, this.limits.maxY) - y;
      if (y === this.limits.minY) {
        height = Math.min(height, this.limits.maxY);
      }
    } else {
      height = Math.max(height, 50);
    }

    this.offsetX = x;
    this.offsetY = y;
    this.height = height;
    this.width = width;

    this.element.style.top = this.offsetY + 'px';
    this.element.style.left = this.offsetX + 'px';
    this.element.style.height = this.height + 'px';
    this.element.style.width = this.width + 'px';
  }

  @HostListener('window:mouseup', ['$event'])
  mouseup(c) {
    this.isMouseDown = false;
    this.resizing = null;
    this.dragEnd.emit(this._coordinate);
  }
}
