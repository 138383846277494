/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProductionContractReport } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProductionReportService {

  private contracts$: Subject<ProductionContractReport[]> = new BehaviorSubject<ProductionContractReport[]>([]);
  private dataProduction$: Subject<{}> = new BehaviorSubject<{}>({});
  private periodProduction$: Subject<{}> = new BehaviorSubject<{}>({});
  private filterProduction$: Subject<{}> = new BehaviorSubject<{}>({});

  emitContractsProduction(allData: ProductionContractReport[] = []) {
    this.contracts$.next(allData);
  }

  emitDataProduction(allData: {} = {}) {
    this.dataProduction$.next(allData);
  }

  emitPeriodProduction(period: {} = {}) {
    this.periodProduction$.next(period);
  }

  emitFilterProduction(filter: {} = {}) {
    this.filterProduction$.next(filter);
  }

  getContractsProduction() {
    return this.contracts$.asObservable();
  }

  getDataProduction() {
    return this.dataProduction$.asObservable();
  }

  getPeriodProduction() {
    return this.periodProduction$.asObservable();
  }

  getFilterProduction() {
    return this.filterProduction$.asObservable();
  }

}
