<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Vídeos</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col d-flex justify-content-center" *ngFor="let file of files">
          <div *ngIf="isVideoSupported(file); else noVideoSupported">
            <video id="modal-{{ file.type }}" width="100%" height="100%" controls autoplay loop muted>
              <source [src]="file.urlCurrent || file.urlDecrypted" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>

          <ng-template #noVideoSupported>
            <p class="text-secondary py-5" i18n>Formato de vídeo não suportado</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
