import { Component, OnInit } from '@angular/core';
import { Lot, StatusLot } from 'src/app/core/models';
import { ModalService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';


@Component({
  selector: 'app-lot-audit-details',
  templateUrl: './lot-audit-details.component.html',
  styleUrls: ['./lot-audit-details.component.sass']
})
export class LotAuditDetailsComponent extends BaseModal implements OnInit {
  public lot: Lot;
  public loading: boolean;
  public statusLot = Object.keys(StatusLot);
  public statusLotI18n = [];

  constructor(
    modalService: ModalService
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.lot = this.initialState || {};
    this.getStatusLot('');
  }

  get isValid(): boolean {
    throw new Error('Method not implemented.');
  }
  get lastState(): any {
    throw new Error('Method not implemented.');
  }
  onAllSubmited(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getStatusLot(statusLot) {
    const status = this.statusLotI18n.find(s => s.id === statusLot);
    if (status && status.id === 'done') {
      return 'Auditado';
    } else if (status && status.id === 'closed') {
      return 'Disponível';
    }
    return (status && status.value) || '';
  }
}
