import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AlertService, ProductionReportService, ViolationService } from 'src/app/core/services';
import { AlertItem, AlertType } from 'src/app/core/models';

@Component({
  selector: 'app-production-report-list',
  templateUrl: './list.component.html'
})
export class ProductionReportListComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public contractsList: any[] = [];
  public filteredContractsList: any[] = [];
  public contractProductionSub: Subscription;
  public searchValue = '';
  private searchChanged: Subject<string> = new Subject<string>();

  constructor(
    private productionReportService: ProductionReportService,
    private violationService: ViolationService,
    private alertService: AlertService
  ) {
    this.searchChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  ngOnInit() {
    this.contractProductionSub = this.productionReportService.getDataProduction()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((contracts: any) => {
        this.contractsList = contracts.contractsProduction;
        this.filteredContractsList = contracts.contractsProduction;
        this.loading = true;
      });
  }

  searchKey(text: string) {
    this.searchChanged.next(text);
  }

  handleSearch() {
    const search = this.searchValue;
    if (search != null && search !== '') {
      this.filteredContractsList = this.contractsList.filter(
        item => item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.id.includes(search)
      );
    } else {
      this.filteredContractsList = this.contractsList;
    }
  }

  setTime(time) {
    const miliseconds = parseInt(time, 10);
    return moment.duration(miliseconds, 'ms').format('hh:mm:ss', { trim: false });
  }

  async updateCounters(item) {
    const startDate = moment(new Date()).subtract(1, 'month').format('YYYY-MM');
    const endDate = moment(new Date()).format('YYYY-MM');
    const btn = document.querySelector(`#loadingRecount${item.id}`);
    btn.classList.add('rotating');

    await this.violationService.recountSession(item.id + '').then(res => {
      this.alertService.show(new AlertItem('QuarantineRecountSuccess', AlertType.success, null, 5000));
      setTimeout(() => {
        location.reload();
      }, 4800);
    }).catch(err => {
      this.alertService.show(new AlertItem('QuarantineRecountDanger', AlertType.danger));
    });
    btn.classList.remove('rotating');
  }

  ngOnDestroy() {
    if (this.contractProductionSub) {
      this.contractProductionSub.unsubscribe();
    }
  }
}
