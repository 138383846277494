<div class="card-header">
  <h5 i18n class="my-2 font-weight-bold">Fluxo das infrações</h5>
</div>

<div class="card-body">
  <div class="row mb-4">
    <div class="col d-flex align-items-center justify-content-center">
      <i class="material-icons">extension</i>
      <span i18n>Etapa</span>
    </div>
    <div class="col d-flex align-items-center justify-content-center">
      <i class="material-icons text-success">check_circle</i>
      <span i18n>Próxima Etapa</span>
    </div>
  </div>

  <!-- Bloco de steps -->
  <app-enum-i18n [input]="violationSteps" (output)="setViolationStepsI18n($event)"></app-enum-i18n>
  <app-enum-i18n [input]="options" (output)="setOptionsI18n($event)"></app-enum-i18n>

  <div *ngFor="let form of forms; let i = index">
    <form [formGroup]="form">
      <div class="row step-block mx-3">
        <div class="col-6">
          <h5 class="mt-2">{{i18nResources[form.get('step').value].value}}</h5>
        </div>
        <div class="col-6">
          <app-input i18n-placeholder type="select" selectText="value" selectValue="id" [list]="violationStepsI18n"
            placeholder="Se aceito" formControlName="accepted" (ngModelChange)="updateGraph(i)">
          </app-input>
        </div>
        <div class="col-6 offset-6" *ngIf="form.get('step').value !== 'firstStep' && form.get('step').value !== 'start' && form.get('step').value !== 'validate'">
          <app-enum-i18n [input]="violationSteps" (output)="violationStepsI18n=$event"></app-enum-i18n>
          <app-input i18n-placeholder type="select" selectText="value" selectValue="id" [list]="violationStepsI18n"
            placeholder="Se recusado" formControlName="refused" (ngModelChange)="updateGraph(i)">
          </app-input>
        </div>
      </div>
    </form>
  </div>

  <div class="card-divider"></div>

  <h6 i18n>Visualização das etapas</h6>
  <app-state-diagram [value]="graphValue" [clickables]="[i18nResources.triage?.value]"
  [highlight]="highlightGraph" (click)="openTriageConfigModal()"></app-state-diagram>
</div>
